import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, space, SpaceProps, variant } from 'styled-system';

type Props = {
  column?: 'column2';
  size?: 's';
};

const Head = styled('dt', {
  shouldForwardProp,
})<Props>(({ theme }) =>
  css({
    marginBottom: theme.space.s,
    fontSize: theme.fontSizes.s,
    color: theme.colors.text.entrytitle,
  }),
);

const Body = styled('dd', {
  shouldForwardProp,
})<Props>(
  () =>
    ({ theme }) =>
      compose(
        variant({
          prop: 'column',
          variants: {
            column2: css({
              display: 'flex',
              margin: `0 -${theme.space.m}`,
              ['label']: {
                margin: `0 ${theme.space.m}`,
                width: '100%',
              },
            }),
          },
        }),
      ),
);

const Root = styled('dl', {
  shouldForwardProp,
})<Props & SpaceProps>(
  ({ theme }) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      '& + &': {
        marginTop: theme.space.xl,
      },
    }),
  () => compose(space),
  ({ theme }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          s: css({
            '& + &': {
              marginTop: theme.space.s,
            },
            [`> ${Head}`]: {
              fontSize: theme.fontSizes.xxs,
              marginBottom: 0,
            },
            [`> ${Body}`]: {
              fontSize: theme.fontSizes.s,
            },
          }),
        },
      }),
    ),
);

const EntryList = Object.assign(Root, { Head, Body });

export { EntryList };
