import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, variant } from 'styled-system';

export const MenuItem = styled('div', {
  shouldForwardProp,
})<{ active?: boolean; attached?: 'bottom' }>(
  ({ theme, active }) =>
    css({
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: `${theme.space.s} ${theme.space.s}`,
      marginY: theme.space.l,
      fontSize: theme.fontSizes.s,
      cursor: 'pointer',
      transition: theme.transitions.default,
      userSelect: 'none',
      [`@media ${theme.mediaQueries.portrait}`]: css({
        margin: '0 auto',
        padding: theme.space.m,
        height: '100%',
        fontSize: theme.fontSizes.xs,
      }),
      '&:hover': css({
        color: theme.colors.text.primary,
        fill: theme.colors.text.primary,
      }),
      ...(active && {
        boxShadow: `4px 0 0 ${theme.colors.background.primary} inset`,
        [`@media ${theme.mediaQueries.portrait}`]: {
          boxShadow: `0 4px 0 ${theme.colors.background.primary} inset`,
          margin: '0 auto',
          padding: theme.space.m,
          height: '100%',
          fontSize: theme.fontSizes.xs,
        },
      }),
    }),
  () =>
    compose(
      variant({
        prop: 'attached',
        variants: {
          bottom: css({
            marginTop: 'auto',
          }),
        },
      }),
    ),
);
