import { useEffect } from 'react';

import {
  PatientProfileFooterAppointmentFragment,
  useGetPatientProfileFooterAppointmentLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';

export const useFetchAppointment = (
  appointmentId: string,
): PatientProfileFooterAppointmentFragment | undefined => {
  const [event, clear] = useSubscriptions('Appointment', ['updated']);

  const [getAppointment, { data }] = useGetPatientProfileFooterAppointmentLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (event) {
        clear();
      }
    },
  });

  const appointment = getNode(data, 'Appointment');

  useEffect(() => {
    if (appointmentId) {
      getAppointment({ variables: { appointmentId } });
    }
  }, [appointmentId, getAppointment]);

  useEffect(() => {
    if (event?.actionName === 'updated' && event.typeId === appointmentId) {
      getAppointment({ variables: { appointmentId } });
    }
  }, [appointmentId, event?.actionName, event?.typeId, getAppointment]);

  return appointment;
};
