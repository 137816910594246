import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Box, Button, Collapse, Icon, Loader } from '~/components/blocks';
import { openWithNoOpener } from '~/utils/window';

import { ImgViewer } from './ImgViewer';
import { useFetchInsuranceCards } from './use-fetch-insurance_cards';

const DEFAULT_HEIGHT = '130px';

type Props = {
  patientId?: string;
  isActivePatient?: boolean;
  onClickCertificate?: () => void;
};

const PreviewButton = styled(Button)(({ theme }) =>
  css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radii.circle,
    opacity: 0,
    transform: 'translate(-50%,-50%)',
    transition: `opacity linier ${theme.transitions.fast}`,
  }),
);

const Content = styled(Box)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    cursor: 'pointer',
    minHeight: DEFAULT_HEIGHT,
    position: 'relative',
    [`&:hover > ${PreviewButton}`]: {
      opacity: 1,
    },
  }),
);

export const InsurnceCardCollapse = React.memo((props: Props) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const { loading, timeout, hasCertificate, error, insuranceCards } = useFetchInsuranceCards(
    isOpen,
    !!props.onClickCertificate,
    props.patientId,
  );

  const handleTitleClick = useCallback(() => setIsOpen((_isOpen) => !_isOpen), []);
  const handleClick = useCallback(() => {
    if (props.patientId) {
      openWithNoOpener(`/insurance_card_preview/${props.patientId}`, {
        target: props.patientId,
        width: 640,
        height: 720,
        bgColor: '#525659',
      });
    }
  }, [props.patientId]);

  useEffect(() => {
    // 表示患者が変更された場合
    setIsOpen(false);
  }, [props.patientId]);

  return (
    <Collapse
      open={isOpen}
      label={
        <>
          <Icon size="xxl" icon="insurance" />
          保険証
        </>
      }
      onClick={handleTitleClick}
    >
      {timeout ? (
        <Alert marginTop={theme.space.m}>
          保険証の取得に時間がかかっています。
          <br />
          しばらく待ってから、再度アクセスしてください
        </Alert>
      ) : error ? (
        <Box padding={theme.space.m}>
          <Alert status="error">取得できませんでした</Alert>
        </Box>
      ) : !props.isActivePatient ? (
        <Box padding={theme.space.m}>
          <Alert status="info">退会済みのため表示できません</Alert>
        </Box>
      ) : loading ? (
        <Box
          backgroundColor={theme.colors.background.bg}
          height={DEFAULT_HEIGHT}
          position="relative"
        >
          <Loader open inside appearance="white" logoSize="half" />
        </Box>
      ) : insuranceCards.length > 0 ? (
        <Content onClick={handleClick}>
          {insuranceCards.map((card) => (
            <ImgViewer
              key={card.attachment.id}
              alt={card.attachment.title}
              src={card.attachment.url}
            />
          ))}
          <PreviewButton icon use="base">
            <Icon color="white" icon="blank" size="l" />
          </PreviewButton>
        </Content>
      ) : (
        <Box padding={theme.space.m}>
          <Alert status="info">登録されていません</Alert>
        </Box>
      )}
      {hasCertificate && props.onClickCertificate && (
        <Box
          textAlign="right"
          marginBottom={theme.space.xxl}
          backgroundColor={theme.colors.background.bg}
        >
          <Button use="white" size="m" onClick={props.onClickCertificate}>
            <Icon icon="document" size="xl" color="black" />
            公費受給者証を確認
          </Button>
        </Box>
      )}
    </Collapse>
  );
});

InsurnceCardCollapse.displayName = 'InsurnceCardCollapse';
