import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useMemo } from 'react';

import { Box, Flex, List, ScrollBox } from '~/components/blocks';
import { getMe } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';

import { Notification } from './Notification';
import { useFetchAnsweredQuestionnaireSheets } from './use-fetch-answered_questionnaire_sheets';

const ListRoot = styled(List)(() =>
  css({
    borderTop: 'none',
  }),
);

export const NotificationList = () => {
  const { data, loading, scrollRef } = useFetchAnsweredQuestionnaireSheets();
  const theme = useTheme();

  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const sheets = useMemo(
    () =>
      currentData
        ? getMe(currentData)?.organization.medicationFollowupQuestionnaireSheets.nodes || []
        : [],
    [currentData],
  );

  return !loading && sheets.length === 0 ? (
    <Flex justifyContent="center">
      <Box padding={theme.space.l}>服薬フォローアップ通知はありません</Box>
    </Flex>
  ) : (
    <Box overflow="auto" height="100%">
      <ScrollBox ref={scrollRef} loading={loading}>
        <ListRoot selectable>
          {sheets.map((sheet, idx) => (
            <Notification key={idx} sheet={sheet} />
          ))}
        </ListRoot>
      </ScrollBox>
    </Box>
  );
};
