import {
  GetCompanyFeatureFlagsQuery,
  GetFeatureFlagsQuery,
  useGetCompanyFeatureFlagsQuery,
  useGetFeatureFlagsQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';

// スペルミスしないようにfeature定義
// リリースされてフラグ削除するときはここも忘れず削除すること
type FeatureFlag = 'AuditEventCsv' | 'Issue7579-enable-agent-setting';

const getFeatureFlags = (data?: GetFeatureFlagsQuery) => {
  if (!data) return [];

  return getMe(data)?.featureNames || [];
};

const getCompanyFeatureFlags = (data?: GetCompanyFeatureFlagsQuery) => {
  if (!data) return [];

  return getMe(data)?.organization.company.featureNames || [];
};

export const useFeatureFlag = (feature: FeatureFlag) => {
  const { data } = useGetFeatureFlagsQuery({
    nextFetchPolicy: 'cache-first',
  });
  const featureFlags = getFeatureFlags(data);

  return featureFlags.includes(feature);
};

export const useCompanyFeatureFlag = (feature: FeatureFlag) => {
  const { data, loading } = useGetCompanyFeatureFlagsQuery({
    nextFetchPolicy: 'cache-first',
  });
  const featureFlags = getCompanyFeatureFlags(data);

  return { isEnabled: featureFlags.includes(feature), loading };
};
