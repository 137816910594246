import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';

import { FieldError, Flex, Icon } from '~/components/blocks';
import { AddNewItemButton } from '~/components/partials';

import { MedicineNameInput } from './MedicineNameInput';

type Props = {
  values: string[];
  disabled: boolean;
  errors: string[] | string | undefined;
  onChange: (values: string[]) => void;
};

const ButtonDelete = styled('button')(({ theme }) =>
  css({
    display: 'flex',
    width: '30px',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.background.bg,
    border: theme.borders.transparent,
    borderRadius: theme.radii.circle,
    marginLeft: theme.space.l,
    outline: 'none',
    transitionDuration: theme.transitions.fast,
    '&:hover': {
      background: theme.colors.background.default,
      border: theme.borders.default,
      cursor: 'pointer',
    },
    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
);

export const MedicineNameForm = (props: Props) => {
  const theme = useTheme();
  const { disabled, values, errors, onChange } = props;

  const handleMedicineNameChange = useCallback(
    (value: string, idx: number) => {
      const newValues = values ? values.slice() : [];

      if (newValues[idx] == undefined) {
        newValues.push(value);
      } else {
        newValues[idx] = value;
      }

      onChange(newValues);
    },
    [values, onChange],
  );

  const handleNewItemButtonClick = useCallback(() => {
    onChange(values.concat(['']));
  }, [values, onChange]);

  const handleDelete = useCallback(
    (idx: number) => {
      const newValues = values.filter((_, i) => idx !== i);
      onChange(newValues);
    },
    [values, onChange],
  );

  return (
    <>
      {values.length > 0 &&
        values.map((v, idx) => {
          return (
            <Flex
              key={idx}
              alignItems="center"
              marginTop={theme.space.m}
              marginRight={theme.space.m}
            >
              <MedicineNameInput
                key={idx}
                disabled={disabled}
                errors={Array.isArray(errors) ? errors[idx] : undefined}
                value={values[idx] ?? ''}
                onChange={(value) => handleMedicineNameChange(value, idx)}
              />
              <ButtonDelete disabled={disabled} onClick={() => handleDelete(idx)}>
                <Icon icon="close2" size="xl" />
              </ButtonDelete>
            </Flex>
          );
        })}
      {typeof errors === 'string' && <FieldError error={errors} />}
      <AddNewItemButton
        label="追加"
        onClick={() => {
          handleNewItemButtonClick();
        }}
      />
    </>
  );
};
