import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Grid, Paper } from '~/components/blocks';

import { RootMenu } from './RootMenu';

type Props = {
  children: React.ReactNode;
};

const Root = styled(Paper)(({ theme }) =>
  css({
    margin: theme.space.xl,
    padding: theme.space.xl,
    [`@media ${theme.mediaQueries.tablet}`]: {
      margin: theme.space.l,
      padding: theme.space.l,
    },
  }),
);

export const CompanyOrganizationsUsages: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Root>
      <Grid height="100%" overflow="hidden" gridTemplateRows="min-content 1fr">
        <Box marginX={theme.space.m}>
          <RootMenu />
        </Box>
        <Box pt={theme.space.xl} px={[0, theme.space.m]} overflow="auto" height="100%">
          {props.children}
        </Box>
      </Grid>
    </Root>
  );
};
