import { useApolloClient } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';

import { Alert, Box, Button, Flex, Radio, RadioGroup, Text } from '~/components/blocks';
import {
  MedicationFollowupSettingFragment,
  MedicationFollowupSettingFragmentDoc,
  useUpdateMedicationFollowupStatusSettingMutation,
} from '~/graphql';
import {
  editMedicationFollowupStatusDialogState,
  medicationFollowupSettingIdState,
} from '~/state/settings_messages/atoms';

import { SettingPopup } from './SettingPoppup';
import { Fields } from './types';
import { validationSchema } from './validation';

const makeInitialValue = (
  medicationFollowupStatusSetting: MedicationFollowupSettingFragment | null,
): Fields => {
  if (!medicationFollowupStatusSetting) {
    return { statusManagement: false };
  }

  return {
    statusManagement: medicationFollowupStatusSetting.statusManagement,
  };
};

export const MedicationFollowupStatusEditDrawer = () => {
  const theme = useTheme();
  const apolloClient = useApolloClient();

  const [error, setError] = useState<string | null>(null);
  const [ankerElement, setAnkerElement] = useState<HTMLElement | null>(null);

  const { className } = useRecoilValue(editMedicationFollowupStatusDialogState);
  const close = useResetRecoilState(editMedicationFollowupStatusDialogState);
  const medicationFollowupSettingId = useRecoilValue(medicationFollowupSettingIdState);
  const isOpen = !!className;

  const [udpateMedicationFollowupStatusSetting, { loading: update }] =
    useUpdateMedicationFollowupStatusSettingMutation();

  const medicationFollowupStatusSetting =
    apolloClient.readFragment<MedicationFollowupSettingFragment>({
      id: `MedicationFollowupSetting:${medicationFollowupSettingId}`,
      fragment: MedicationFollowupSettingFragmentDoc,
    });

  const initialValues = useMemo(
    () => makeInitialValue(medicationFollowupStatusSetting),
    [medicationFollowupStatusSetting],
  );

  const handleSubmit = useCallback(
    async (values: Fields) => {
      setError(null);

      try {
        await udpateMedicationFollowupStatusSetting({
          variables: {
            input: {
              statusManagement: values.statusManagement,
            },
          },
        });
        close();
      } catch (error) {
        setError(error?.message || 'エラーが発生しました');
      }
    },
    [close, udpateMedicationFollowupStatusSetting],
  );

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(editMedicationFollowupStatusDialogState);
      },
    [],
  );

  useEffect(() => {
    if (isOpen) {
      setError(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (className) {
      const elem = document.querySelector<HTMLElement>(`.${className}`);

      elem && setAnkerElement(elem);
    }
  }, [className]);

  return (
    <SettingPopup
      open={isOpen}
      ankerRef={ankerElement}
      title="フォローアップ管理"
      onClose={handleClose}
    >
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <>
              {error && (
                <Alert status="error" mb={theme.space.m}>
                  <Text size="s">{error}</Text>
                </Alert>
              )}
              <Box>
                <Text size="s">
                  設定を有効にすると、フォローアップをして
                  <br />
                  いる患者に「対応中」ラベルを表示します。
                </Text>
                <RadioGroup mt={theme.space.m}>
                  <Radio
                    label="有効"
                    disabled={update}
                    checked={formik.values.statusManagement}
                    onChange={() => formik.setFieldValue('statusManagement', true)}
                  />
                  <Radio
                    label="無効"
                    disabled={update}
                    checked={!formik.values.statusManagement}
                    onChange={() => formik.setFieldValue('statusManagement', false)}
                  />
                </RadioGroup>
              </Box>
              <Flex justifyContent="flex-end" mt={theme.space.m}>
                <Button
                  use="base"
                  loading={update}
                  disabled={!formik.dirty}
                  onClick={formik.submitForm}
                >
                  保存
                </Button>
              </Flex>
            </>
          );
        }}
      </Formik>
    </SettingPopup>
  );
};
