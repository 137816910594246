import { LatLngLiteral } from '@googlemaps/google-maps-services-js';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Loader } from '~/components/blocks';
import { geocodingState } from '~/state/settings_organization_base/atoms';

import { Marker } from './Marker';

const DEFAULT_CENTER = {
  lat: 35.662725,
  lng: 139.731217,
} as const;
const ZOOM = 16;

type Props = {
  static?: boolean;
  width: number | string;
  height: number | string;
  latitude?: number;
  longitude?: number;
  onChange?: (value: LatLngLiteral) => void;
};

export const Map = React.memo((props: Props) => {
  const { latitude, longitude, onChange } = props;
  const [geocoding, setGeocoding] = useRecoilState(geocodingState);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const center = useMemo(() => {
    const _center =
      latitude != null && longitude != null ? { lat: latitude, lng: longitude } : undefined;

    return _center || props.static ? _center : DEFAULT_CENTER;
  }, [latitude, longitude, props.static]);

  const [markerPosition, setMarkerPosition] = useState(center);

  const handleOnLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
      if (!geocoding.geocoder) {
        const geocoder = new google.maps.Geocoder();
        // Geocodingで利用する
        setGeocoding({ geocoder: geocoder });
      }
    },
    [geocoding.geocoder, setGeocoding],
  );

  const handleCenterChanged = useCallback(() => {
    const _center = map?.getCenter();
    if (_center) {
      const lat = _center.lat();
      const lng = _center.lng();
      setMarkerPosition({
        lat: lat,
        lng: lng,
      });

      onChange && onChange({ lat: lat, lng: lng });
    }
  }, [map, onChange]);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.googleMapsApiKey,
    language: 'ja',
    region: 'JP',
    mapIds: [process.env.googleMapId],
  });

  const staticOption: google.maps.MapOptions | undefined = useMemo(
    () =>
      props.static
        ? {
            gestureHandling: 'none',
            fullscreenControl: false,
            zoomControl: false,
            disableDefaultUI: true,
            mapId: process.env.googleMapId,
          }
        : {
            mapTypeControl: false,
            streetViewControl: false,
            mapId: process.env.googleMapId,
          },
    [props.static],
  );

  const containerStyle = {
    height: props.height,
  };

  useEffect(() => {
    if (center) {
      setMarkerPosition(center);
    }
  }, [center]);

  if (!center) return null;

  if (loadError) {
    return (
      <Alert status="error">
        地図の読み込みに失敗しました。
        <br />
        しばらく待ってから、再度アクセスしてください
      </Alert>
    );
  }

  return (
    <Box width={props.width} height={props.height}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          clickableIcons={false}
          options={staticOption}
          center={center}
          zoom={ZOOM}
          onCenterChanged={handleCenterChanged}
          onLoad={handleOnLoad}
        >
          {markerPosition && map && <Marker position={markerPosition} map={map} />}
        </GoogleMap>
      ) : (
        <Loader open inside />
      )}
    </Box>
  );
});

Map.displayName = 'Map';
