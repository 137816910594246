import { openWithNoOpener } from './window';

export const openPreviewPage = (appointmentId: string, isPrintable?: boolean) => {
  const baseUrl = `/prescription_preview/${appointmentId}`;

  openWithNoOpener(`${baseUrl}${isPrintable ? '?print=true' : ''}`, {
    target: appointmentId,
    width: 640,
    height: 720,
    bgColor: '#525659',
  });
};
