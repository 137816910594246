import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import React from 'react';

import { Box, EntryList, Flex, Text } from '~/components/blocks';
import { FollowupIcon } from '~/components/partials';
import { FollowupMessageEventFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { FollowupMessageTag } from '../FollowupMessageTag';

type Props = {
  className?: string;
  followupMessage: FollowupMessageEventFragment;
  children?: React.ReactNode;
  onClick?: () => void;
};

const Root = styled(Box)<{ error?: boolean }>(({ theme, error, onClick }) =>
  css({
    border: theme.borders.default,
    background: theme.colors.background.default,
    borderRadius: '6px',
    boxShadow: `2px 4px 8px ${rgba(theme.colors.background.black, 0.05)}`,
    padding: theme.space.m,
    transitionDuration: theme.transitions.default,
    '& + &': {
      marginTop: theme.space.l,
    },
    '&:hover': {
      cursor: 'default',
    },
    ...(error && {
      border: theme.borders.secondary,
      background: theme.colors.background.alert,
    }),
    ...(onClick && {
      border: theme.borders.default,
      '&:hover': {
        cursor: 'pointer',
      },
    }),
  }),
);

const TagsWrapper = styled(Flex)(() =>
  css({
    flexWrap: 'wrap',
  }),
);

export const FollowupMessageEvent: React.FC<Props> = (props) => {
  const { followupMessage } = props;
  const theme = useTheme();

  const deliverResult = followupMessage.messageDeliverResult;
  const isError = deliverResult?.status === 'failed';
  const isScheduled = followupMessage.status === 'scheduled';

  return (
    <Root className={props.className} error={isError} onClick={props.onClick}>
      <Flex alignItems="center">
        <FollowupIcon size="l" color={isError ? 'pink' : undefined} />
        <EntryList size="s" marginLeft={theme.space.m}>
          <EntryList.Head>
            {isScheduled ? (
              <Text color="green" fontWeight="bold">
                送信予定
              </Text>
            ) : isError ? (
              <Text fontWeight="bold" color="pink">
                送信エラー
              </Text>
            ) : (
              '送信日時'
            )}
          </EntryList.Head>
          <EntryList.Body>
            <Text fontWeight="bold" whiteSpace="nowrap">
              {deliverResult
                ? `${Label.YYYYMMDDja(deliverResult.updatedAt)} ${Label.HHMM(
                    deliverResult.updatedAt,
                  )}`
                : `${Label.YYYYMMDDja(followupMessage.sendAt)} ${Label.HHMM(
                    followupMessage.sendAt,
                  )}`}
            </Text>
          </EntryList.Body>
        </EntryList>
      </Flex>
      <Box marginTop={theme.space.m}>
        <Text size="s" wordBreak="break-word" whiteSpace="pre-line">
          {followupMessage.message}
        </Text>
      </Box>
      <TagsWrapper marginTop={theme.space.m}>
        {followupMessage.medicationFollowupQuestionnaireRevision && (
          <FollowupMessageTag>
            <Text size="xs" color="navy">
              {`質問票（${followupMessage.medicationFollowupQuestionnaireRevision.medicationFollowupQuestionnaire.revision.subject}）`}
            </Text>
          </FollowupMessageTag>
        )}
        {followupMessage.autoReplySetting && (
          <FollowupMessageTag>
            <Text size="xs" color="navy">
              自動返信有効
            </Text>
          </FollowupMessageTag>
        )}
      </TagsWrapper>
      {props.children}
    </Root>
  );
};

FollowupMessageEvent.displayName = 'FollowupMessageEvent';
