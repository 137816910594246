import { useEffect } from 'react';

import { useGetUberOrganizationSettingLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useUberOrganizationSetting = () => {
  const [getUberOrgSetting, { data, loading, called }] = useGetUberOrganizationSettingLazyQuery({
    fetchPolicy: 'network-only',
  });
  const enable = data ? getMe(data)?.organization.uberOrganizationSetting?.enable : false;

  useEffect(() => {
    getUberOrgSetting();
  }, [getUberOrgSetting]);

  return {
    loading: loading || !called,
    enable: !!enable,
  };
};
