import { useCallback, useEffect, useState } from 'react';

import { useGetTracingReportUrlLazyQuery, useRecreateTracingReportMutation } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { ChannelEvent, useChannel } from '~/hooks/use-subscriptions';
import { openWithNoOpener } from '~/utils/window';

export const useRecreateTracingReport = () => {
  const [isRecreating, setIsRecreating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recreateReport] = useRecreateTracingReportMutation();
  const { subscribe, unsubscribe } = useChannel();

  const [getUrl] = useGetTracingReportUrlLazyQuery({
    onCompleted: (_data) => {
      const info = getNode(_data, 'TracingReport');

      if (info?.status === 'succeeded' && info.file?.url) {
        openWithNoOpener(
          `/tracing_report_preview?report=${encodeURIComponent(info.file.url)}&jobInformationId=${
            info.id
          }`,
          {
            width: 820,
            height: 900,
            bgColor: '#525659',
          },
        );
        close();
      } else {
        setError('トレーシングレポート作成に失敗しました。再度お試しください');
      }
    },
  });

  const handleEvent = useCallback(
    (_event: ChannelEvent) => {
      if (_event.typeName === 'GenerateTracingReport' && _event.actionName === 'updated') {
        try {
          getUrl({
            variables: {
              id: _event.typeId,
            },
          });
        } catch (ex) {
          setError(ex?.message || 'エラーが発生しました');
        }
        setIsRecreating(false);
      }
    },
    [getUrl],
  );

  const recreate = useCallback(
    async (tracingReportId: string) => {
      if (!tracingReportId) return;

      setIsRecreating(true);
      setError(null);

      try {
        subscribe(handleEvent);
        await recreateReport({
          variables: {
            input: {
              tracingReportId: tracingReportId,
            },
          },
        });
      } catch (ex) {
        setError(ex?.message || 'エラーが発生しました');
        setIsRecreating(false);
      }
    },
    [recreateReport, subscribe, handleEvent],
  );

  useEffect(() => {
    if (!isRecreating) unsubscribe(handleEvent);
  }, [isRecreating, handleEvent, unsubscribe]);

  return {
    isRecreating,
    error,
    recreate,
  };
};
