import { useEffect } from 'react';

import { useGetUnreadTemporaryPermissionsLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';

export const useFetchUnreadTemporaryPermissions = () => {
  const [event] = useSubscriptions('HiccupTemporaryPermission', ['created']);

  // CLINICSアプリから調剤薬局を検索し、「開示許可」した未読の通知
  const [getUnreadTemporaryPermissions, { data }] = useGetUnreadTemporaryPermissionsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const unreadCount = data
    ? getMe(data)?.organization.hiccupTemporaryPermissions.nodesCount || 0
    : 0;

  // マウント時に件数取得
  useEffect(() => {
    getUnreadTemporaryPermissions();
  }, [getUnreadTemporaryPermissions]);

  useEffect(() => {
    if (event) {
      getUnreadTemporaryPermissions();
    }
  }, [event, getUnreadTemporaryPermissions]);

  return {
    unreadCount,
  };
};
