import { useTheme } from '@emotion/react';
import { endOfMonth, isValid, parseISO } from 'date-fns';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { toWareki } from '~/utils/date';

import { Box, FieldError, Flex, Select } from '..';

const MAX_YEAR = new Date().getFullYear();
const MIN_YEAR = 1903; // 2021年1月時点で世界最高齢（日本人）の方が1903年生まれのため

type Props = {
  error?: string;
  disabled?: boolean;
  value: string;
  onChange: (date: string) => void;
};

export type InputBirthdateAttr = {
  reset: () => void;
};

type Option = {
  label: string;
  value: number;
};

const MONTHS = Array.from({ length: 12 }).map<Option>((_, idx) => ({
  label: `${idx + 1}月`,
  value: idx + 1,
}));

const makeYearOptions = (maxYear: number, minYear: number) => {
  let year = maxYear;
  const options: Option[] = [];
  for (; year >= minYear; year--) {
    options.push({
      label: `${toWareki(year)}(${year})`,
      value: year,
    });
  }
  return options;
};

/**
 * year/monthが不正な値だった場合は31を返す
 * @param year
 * @param month
 */
const buildDayOptions = (year: '' | number, month: '' | number) => {
  let lastDay = 31;

  if (year !== '' && month !== '') {
    const date = `${year}-${`${month}`.padStart(2, '0')}-01`;
    lastDay = isValid(parseISO(date)) ? endOfMonth(new Date(date)).getDate() : 31;
  }

  return Array.from({ length: lastDay }).map<Option>((_, idx) => ({
    label: `${idx + 1}日`,
    value: idx + 1,
  }));
};

export const InputBirthdate = forwardRef<InputBirthdateAttr, Props>((props: Props, ref) => {
  const { disabled, error, onChange } = props;

  const theme = useTheme();

  const [year, setYear] = useState<'' | number>('');
  const [month, setMonth] = useState<'' | number>('');
  const [day, setDay] = useState<'' | number>('');
  const yearOptions = useMemo(() => makeYearOptions(MAX_YEAR, MIN_YEAR), []);
  const [dayOptions, setDayOptions] = useState<Option[]>(buildDayOptions(year, month));

  const handleYearChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => setYear(+e.target.value),
    [],
  );
  const handleMonthChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => setMonth(+e.target.value),
    [],
  );
  const handleDayChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => setDay(+e.target.value),
    [],
  );

  useImperativeHandle(ref, () => ({
    reset: () => {
      setDay('');
      setMonth('');
      setYear('');
    },
  }));

  useEffect(() => {
    const _dayOptions = buildDayOptions(year, month);
    const lastDay = _dayOptions[_dayOptions.length - 1].value;
    const numberDay = day || 1;
    const date = `${`${year}`.padStart(4, '0')}-${`${month}`.padStart(2, '0')}-${`${
      numberDay < lastDay ? numberDay : lastDay
    }`.padStart(2, '0')}`;

    setDayOptions(_dayOptions);

    if (isValid(parseISO(date))) {
      onChange(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month, year]);

  useEffect(() => {
    if (isValid(parseISO(props.value))) {
      const _date = new Date(props.value);
      setYear(_date.getFullYear());
      setMonth(_date.getMonth() + 1);
      setDay(_date.getDate());
    }
  }, [props.value]);

  useEffect(() => {
    if (props.value.length === 0) {
      setDay('');
      setMonth('');
      setYear('');
    }
  }, [props.value.length]);

  return (
    <Box>
      <Box marginRight={theme.space.xs}>
        <Select fill error={error} disabled={disabled} value={year} onChange={handleYearChange}>
          <option disabled hidden value="">
            ー
          </option>
          {yearOptions.map((y) => (
            <option key={y.value} value={y.value}>
              {y.label}
            </option>
          ))}
        </Select>
      </Box>
      <Flex marginTop={theme.space.m} width="100%">
        <Select fill error={error} disabled={disabled} value={month} onChange={handleMonthChange}>
          <option disabled hidden value="">
            ー
          </option>
          {MONTHS.map((m) => (
            <option key={m.value} value={m.value}>
              {m.label}
            </option>
          ))}
        </Select>
        <Select
          fill
          error={error}
          marginLeft={theme.space.m}
          disabled={disabled}
          value={day}
          onChange={handleDayChange}
        >
          <option disabled hidden value="">
            ー
          </option>
          {dayOptions.map((d) => (
            <option key={d.value} value={d.value}>
              {d.label}
            </option>
          ))}
        </Select>
      </Flex>
      {error && <FieldError error={error} />}
    </Box>
  );
});

InputBirthdate.displayName = 'InputBirthdate';
