import { useCallback, useState } from 'react';

import { useUpdateKarteAppointmentDescriptionMutation } from '~/graphql';

export const useUpdateMemo = (appointmentId?: string) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [latestMemo, setLatestMemo] = useState({ memo: '', mutationCalled: false });
  const [updateMemo] = useUpdateKarteAppointmentDescriptionMutation({
    onCompleted: (_data) => {
      // Apollo or useMemo のキャッシュまわりの不整合が発生するので、メモ更新後は Mutation の返り値を使う
      // FYI https://github.com/medley-inc/pharms/issues/1852
      const latestDescription = _data?.updateAppointmentDescription?.appointment?.description || '';

      // updateDescriptionData はリロード走るまで値を保持し続けるので、別の受付ドロワーを開いたタイミングでリセットできるようにステート管理する
      setLatestMemo({ memo: latestDescription, mutationCalled: true });
    },
  });
  const update = useCallback(
    async (memo: string) => {
      if (appointmentId) {
        setIsUpdating(true);
        try {
          await updateMemo({
            variables: {
              input: {
                appointmentId,
                description: memo,
              },
            },
          });
        } catch (_error) {
          throw new Error(_error.message);
        }
        setIsUpdating(false);
      }
    },
    [appointmentId, updateMemo],
  );

  return {
    isUpdating,
    latestMemo,
    update,
  };
};
