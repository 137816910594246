import type Firebase from 'firebase/compat';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { OnlineEncouterFragment, useGetOnlineEncouterLazyQuery } from '~/graphql';
import { useFirebase } from '~/hooks/use-firebase';

type Props = {
  encounterId?: string;
};

export const useWatchTelemedicine = (props: Props) => {
  const { encounterId } = props;

  const [inProgress, setInProgress] = useState(false);
  const [getOnlineEncounter, { data }] = useGetOnlineEncouterLazyQuery();

  const { db, auth } = useFirebase();
  const participantsRef = useMemo(
    () =>
      data ? db.ref(`/sessions/${(data.node as OnlineEncouterFragment).rawId}/participants`) : null,
    [db, data],
  );
  const selfUid = useMemo(() => auth.currentUser?.uid, [auth.currentUser?.uid]);

  // 自分以外のオンライン服薬指導中の人がいるか
  const handleParticipantsValue = useCallback(
    (snapshot: Firebase.database.DataSnapshot) => {
      const participants = snapshot.exists() ? Object.values<string>(snapshot.val()) : [];
      const isExisting = participants.some((uid) => {
        const participantType = uid.split(':')[0];
        return uid !== selfUid && participantType === 'practitioner';
      });

      setInProgress(isExisting);
    },
    [selfUid],
  );

  useEffect(() => {
    if (encounterId) {
      getOnlineEncounter({
        variables: {
          encounterId,
        },
      });
    }
  }, [encounterId, getOnlineEncounter]);

  useEffect(() => {
    if (participantsRef) {
      participantsRef.on('value', handleParticipantsValue);
    }
    return () => {
      if (participantsRef) {
        participantsRef.off('value', handleParticipantsValue);
      }
    };
  }, [handleParticipantsValue, participantsRef]);

  return inProgress;
};
