import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Icon } from '~/components/blocks';
import { AppHeaderBadge } from '~/components/partials';
import { medicineNoteNotificationPanelState } from '~/state/partials/medicine_note_notification_panel/atoms';

import { useFetchUnreadTemporaryPermissions } from './use-fetch-unread_temporary_permissions';

type Props = {
  onClick?: () => void;
};

export const MedicineNoteNotificationBadge = React.memo((props: Props) => {
  const { unreadCount } = useFetchUnreadTemporaryPermissions();

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(medicineNoteNotificationPanelState, (_state) => ({ ..._state, isOpen: true }));
        props.onClick && props.onClick();
      },
    [props],
  );

  return (
    <AppHeaderBadge count={unreadCount} onClick={handleClick}>
      <Icon size="xl" color="navy" icon="medicalnote-notification" />
    </AppHeaderBadge>
  );
});

MedicineNoteNotificationBadge.displayName = 'MedicineNoteNotificationBadge';
