import { atom } from 'recoil';

import { MedixsReceptionIdentificationModalState } from './types';

export const medixsReceptionIdentificationModalState =
  atom<MedixsReceptionIdentificationModalState>({
    key: 'reception.medixsReceptionIdentificationModal',
    default: {
      isOpen: false,
      error: null,
      patientId: null,
      appointmentId: null,
      medixsSettingId: null,
      selectedMedixsReception: null,
      selectedDate: new Date(),
    },
  });
