import React, { ComponentProps } from 'react';

import { Text } from '../Text';

type Props = {
  value: string;
  max: number;
};

export const Counter = React.memo((props: Props) => {
  const count = props.value.length;
  const color: ComponentProps<typeof Text>['color'] = count > props.max ? 'pink' : 'grey01';

  return (
    <Text block textAlign="right" color={color}>
      {count}/{props.max}
    </Text>
  );
});

Counter.displayName = 'Counter';
