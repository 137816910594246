import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { HrefProps } from '~/components/layouts/CompanyOrganizationsCommonSettings/types';

const Tab = styled('button', { shouldForwardProp })<{
  isActive: boolean;
}>(({ theme, isActive }) =>
  css({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.space.s} ${theme.space.l}`,
    background: isActive ? theme.colors.background.primary : theme.colors.background.default,
    color: isActive ? theme.colors.text.white : theme.colors.text.default,
    border: isActive ? 'none' : theme.borders.default,
    borderRight: 'none',
    outline: 0,
    fontSize: theme.fontSizes.s,
    fontWeight: theme.fontWeights.bold,
    transition: theme.transitions.default,
    whiteSpace: 'nowrap',
    userSelect: 'none',
    cursor: 'pointer',
    '&:first-of-type': {
      borderRadius: `${theme.space.s} 0 0 ${theme.space.s}`,
    },
    '&:last-of-type': {
      borderRadius: `0 ${theme.space.s} ${theme.space.s} 0`,
      borderRight: isActive ? 'none' : theme.borders.default,
    },
  }),
);

type Props = {
  href: [HrefProps, ...HrefProps[]];
  children: React.ReactNode;
};

export const MenuLink = (props: Props) => {
  const router = useRouter();
  const isActive = props.href.some((href) =>
    href.exact ? router.pathname === href.path : router.pathname.indexOf(href.path) === 0,
  );
  const handleClick = useCallback(() => {
    router.push(props.href[0].path);
  }, [router, props.href]);

  return (
    <Tab isActive={isActive} onClick={handleClick}>
      {props.children}
    </Tab>
  );
};
