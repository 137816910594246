const KEY = 'client_cert_ignore_period';

type ClientCertIgnorePeriodHash = { [key: string]: string };

const getClientCertIgnorePeriods = () => {
  const json = window.localStorage.getItem(KEY);
  const result: ClientCertIgnorePeriodHash = json ? JSON.parse(json) : {};
  return result;
};

const setClientCertIgnorePeriods = (ClientCertIgnorePeriods: ClientCertIgnorePeriodHash) => {
  window.localStorage.setItem(KEY, JSON.stringify(ClientCertIgnorePeriods));
};

export const addClientCertIgnorePeriod = (practitionerId: string, days: number) => {
  const periods = getClientCertIgnorePeriods();
  const newPeriod = new Date();
  newPeriod.setDate(newPeriod.getDate() + days);

  periods[practitionerId] = newPeriod.toISOString();
  setClientCertIgnorePeriods(periods);
};

export const removeClientCertIgnorePeriod = (practitionerId: string) => {
  const periods = getClientCertIgnorePeriods();
  delete periods[practitionerId];

  setClientCertIgnorePeriods(periods);
};

export const getClientCertIgnorePeriod = (practitionerId: string) => {
  const periods = getClientCertIgnorePeriods();
  return periods[practitionerId];
};
