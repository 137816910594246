const KEY = 'medicine_frequency';

type MedicineFrequencyHash = { [key: string]: number };

const getMedicineFrequencies = () => {
  const json = window.localStorage.getItem(KEY);
  const result: MedicineFrequencyHash = json ? JSON.parse(json) : {};
  return result;
};

const setMedicineFrequencies = (medicineFrequencies: MedicineFrequencyHash) => {
  window.localStorage.setItem(KEY, JSON.stringify(medicineFrequencies));
};

export const addMedicineFrequency = (medicine: string) => {
  const medicineFrequencies = getMedicineFrequencies();
  const frequency: number | undefined = medicineFrequencies[medicine];
  medicineFrequencies[medicine] = frequency ? frequency + 1 : 1;

  setMedicineFrequencies(medicineFrequencies);
};

export const getMedicineFrequency = (medicine: string) => {
  const medicineFrequencies = getMedicineFrequencies();
  return medicineFrequencies[medicine];
};
