import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, space, SpaceProps, variant } from 'styled-system';

const Root = styled('table', {
  shouldForwardProp,
})<SpaceProps & { appearance?: 'detail' }>(
  ({ theme }) =>
    css({
      fontSize: theme.fontSizes.s,
      width: '100%',
    }),
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'appearance',
        variants: {
          detail: css({
            padding: theme.space.m,
            border: `3px solid ${theme.colors.colorPallete.grey03}`,
            fontSize: theme.fontSizes.xs,
          }),
        },
      }),
    ),
);

const Tbody = styled('tbody')();

const Tr = styled('tr')();

const Th = styled('th')<{ top?: boolean }>(({ top }) =>
  css({
    textAlign: 'left',
    verticalAlign: top ? 'top' : 'middle',
    whiteSpace: 'nowrap',
    width: 'auto',
  }),
);

const Td = styled('td')(({ theme }) =>
  css({
    paddingLeft: theme.space.l,
  }),
);

export const MedicineNoteTable = Object.assign(Root, { Tbody, Tr, Th, Td });
