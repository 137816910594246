import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useState } from 'react';
import { useCollapse } from 'react-collapsed';

import { BasePatientProfileFragment } from '~/graphql';

import { ProfileDetail } from './ProfileDetail';
import { ProfileDetailTabs } from './ProfileDetailTabs';
import { useFetchAppPatientProfile } from './ProfileDetailTabs/use-fetch-app_patient';

type Props = {
  patient?: BasePatientProfileFragment | null;
};

const Root = styled('div')(({ theme }) =>
  css({
    padding: `${theme.space.l} ${theme.space.l}`,
  }),
);

export const Profile = React.memo((props: Props) => {
  const { patient } = props;

  const [isExpanded, setExpanded] = useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({ duration: 100, isExpanded });

  const { loading, appPatient } = useFetchAppPatientProfile(isExpanded, patient?.id);

  const handleClick = useCallback(() => setExpanded((_prev) => !_prev), []);

  return (
    <Root>
      <ProfileDetail
        expanded={isExpanded}
        patient={patient}
        onGetToggleProps={() => getToggleProps({ onClick: handleClick })}
      />
      <div {...getCollapseProps()}>
        <ProfileDetailTabs loading={loading} patientId={patient?.id} appPatient={appPatient} />
      </div>
    </Root>
  );
});

Profile.displayName = 'Profile';
