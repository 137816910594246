import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { rgba } from 'polished';
import React from 'react';
import {
  compose,
  flexbox,
  FlexboxProps,
  height,
  HeightProps,
  padding,
  PaddingProps,
} from 'styled-system';

type Props = {
  className?: string;
  children?: React.ReactNode;
} & FlexboxProps &
  HeightProps &
  PaddingProps;

const Root = styled('div', {
  shouldForwardProp,
})<HeightProps & FlexboxProps & PaddingProps>(
  ({ theme }) =>
    css({
      display: 'flex',
      backgroundColor: rgba(theme.colors.background.black, 0.9),
      bottom: '0px',
      position: 'fixed',
      right: '0px',
      width: '100%',
      // safariの場合にdisableの指定がないと、FAX送信モーダル上から開ける印刷プレビューが真っ白になる
      '@media print': {
        '&': {
          display: 'none',
        },
      },
    }),
  () => compose(height, flexbox, padding),
);

export const AppBar: React.FC<Props> = (props) => {
  const { children, ...styles } = props;

  return <Root {...styles}>{children}</Root>;
};
