import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, variant } from 'styled-system';

type Props = {
  use?: 'user' | 'pharmacist';
};

const Comment = styled('div', {
  shouldForwardProp,
})<Props>(
  ({ theme }) =>
    css({
      backgroundColor: theme.colors.background.primary,
      padding: `${theme.space.m} ${theme.space.m}`,
      color: theme.colors.text.white,
      borderRadius: '8px 0 8px 8px',
      width: '70%',
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'use',
        variants: {
          user: css({
            marginLeft: 'auto',
            fontWeight: 'bold',
          }),
          pharmacist: css({
            backgroundColor: theme.colors.background.default,
            color: theme.colors.text.default,
            borderRadius: '0 8px 8px 8px',
          }),
        },
      }),
    ),
);

const Item = styled('li', {
  shouldForwardProp,
})<Props>(({ theme }) =>
  css({
    padding: theme.space.m,
    display: 'flex',
  }),
);

const Root = styled('ul', {
  shouldForwardProp,
})<Props>(({ theme }) =>
  css({
    padding: theme.space.m,
  }),
);

const Chat = Object.assign(Root, { Item, Comment });

export { Chat };
