import { useRouter } from 'next/router';
import { useRecoilCallback } from 'recoil';

import { intercomUnreadCountState } from '~/state/layouts/OrganizationAppShell/atoms';

const IGNORE_PATHNAME = [
  '/signup',
  '/signin',
  '/client_certs/[clientCertId]/download',
  '/access_blocked',
  '/already_signed',
  '/404',
  '/422',
  '/500',
  '/login',
  '/proxy',
  '/checkin_poster',
  '/checkin/[checkinOrganizationId]/entry',
  '/checkin/[checkinOrganizationId]/result',
  '/checkin/404',
  '/materials/bookmarked_manual',
  '/materials/basic_operation_manual',
  '/materials/docomo_app_campaign_manual',
  '/materials/404',
  '/followup_messages/[publicFollowupMessageId]/questionnaire_sheet',
  '/followup_messages/404',
  '/company',
  '/online/[sessionId]',
  '/dispensing_request_preview/[nodeId]',
  '/prescription_preview/[appointmentId]',
  '/medicinenote/patients/[patientId]',
  '/medicinenote/onetime_codes/[onetimeCode]',
  '/insurance_card_preview/[patientId]',
  '/tracing_report_preview',
  '/uber_signature_preview',
];

export const useSetupIntercom = () => {
  const { pathname } = useRouter();
  const ignore = IGNORE_PATHNAME.includes(pathname);
  const handleUnreadCountChange = useRecoilCallback(
    ({ set }) =>
      (unreadCount: number) => {
        set(intercomUnreadCountState, { unreadCount });
      },
    [],
  );

  return {
    handleUnreadCountChange,
    ignore,
  };
};
