import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import { Box, Button, Flex, Icon, List, Tag, Text } from '~/components/blocks';
import { DEFAULT_WINDOW } from '~/components/partials';
import {
  GetUnreadTemporaryPermissionsDocument,
  useMarkTemporaryPermissionMutation,
} from '~/graphql';
import { theme } from '~/styles/theme';
import { openWithNoOpener } from '~/utils/window';

import { Notification } from './types';

type Props = {
  notification: Notification;
};

const New = styled(Tag)(({ theme }) =>
  css({
    color: theme.colors.text.white,
    background: theme.colors.background.secondary,
    border: theme.borders.error,
    padding: `${theme.space.xs} ${theme.space.s}`,
    fontSize: theme.fontSizes.xs,
    lineHeight: 1,
  }),
);

const ListItem = styled(List.Item)(({ theme, onClick }) =>
  css({
    transitionDuration: theme.transitions.default,
    borderTop: theme.borders.default,
    '&:first-of-type': {
      borderTop: '0',
    },
    '&:hover': {
      background: theme.colors.background.bg,
      cursor: onClick ? 'pointer' : 'default',
    },
  }),
);

const PatientName = styled('dl')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.m,
    fontWeight: theme.fontWeights.bold,
  }),
);

const ExpirationDate = styled('dl')(({ theme }) =>
  css({
    display: 'flex',
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    marginTop: theme.space.s,
    '& > dt': {
      color: theme.colors.colorPallete.grey01,
    },
  }),
);

export const MedicineNoteNotification = React.memo((props: Props) => {
  const { notification } = props;

  const [markTemporaryPermission, { loading }] = useMarkTemporaryPermissionMutation({
    refetchQueries: [{ query: GetUnreadTemporaryPermissionsDocument }],
  });

  const handleClickItem = useCallback(() => {
    if (notification.type === 'TemporaryPermission' && !notification.isRead) {
      markTemporaryPermission({
        variables: {
          input: {
            hiccupTemporaryPermissionId: notification.temporaryPermissionId,
          },
        },
      });
    }
  }, [markTemporaryPermission, notification]);
  const handleClickButton = useCallback(() => {
    if (notification.type === 'OnetimeApiCache') {
      openWithNoOpener(`/medicinenote/onetime_codes/${notification.onetimeCode}`, {
        target: notification.onetimeCode,
        width: DEFAULT_WINDOW.width,
        height: DEFAULT_WINDOW.height,
      });
    }
    if (notification.type === 'TemporaryPermission') {
      openWithNoOpener(`/medicinenote/patients/${notification.patientId}`, {
        target: notification.patientId,
        width: DEFAULT_WINDOW.width,
        height: DEFAULT_WINDOW.height,
      });
    }
  }, [notification]);

  return (
    <ListItem
      height="75px"
      onClick={notification.type === 'TemporaryPermission' ? handleClickItem : undefined}
    >
      <Flex justifyContent="space-between" height="100%">
        <Box>
          <PatientName>
            <dt>{notification.patientProfile.name}</dt>
          </PatientName>
          <ExpirationDate>
            <dt>有効期限：</dt>
            <dd>{notification.expiresAt}</dd>
          </ExpirationDate>
        </Box>
        <Flex
          height="100%"
          alignSelf="flex-end"
          justifyContent="space-between"
          flexDirection="column"
        >
          {notification.type === 'TemporaryPermission' && !notification.isRead && (
            <Flex alignItems="flex-start" justifyContent="flex-end" marginBottom={theme.space.xs}>
              <New size="s">NEW</New>
            </Flex>
          )}
          <Button size="xs" loading={loading} onClick={handleClickButton}>
            <Icon size="l" icon="medicalnote" />
            <Text size="xs">表示</Text>
          </Button>
        </Flex>
      </Flex>
    </ListItem>
  );
});

MedicineNoteNotification.displayName = 'MedicineNoteNotification';
