import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { useEffect } from 'react';
import { useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';

import {
  Alert,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Pagination,
  ScrollBox,
  Select as DefaultSelect,
  Text,
  TextField as DefaultTextField,
  Tooltip,
} from '~/components/blocks';
import { CsClinic } from '~/components/partials';
import { PREFECTURES } from '~/constants/prefectures';
import { CsClinicItemFragment } from '~/graphql';
import { searchCsClinicsPageInfoState } from '~/state/partials/search_cs_clinics/atoms';

import { useFetchCsCategories } from './use-fetch-cs_categories';
import { useFetchCsCities } from './use-fetch-cs_cities';
import { useFetchCsClinics } from './use-fetch-cs_clinics';

type Props = {
  onSelect: (csClinic: CsClinicItemFragment) => void;
};

const Freeword = styled(Flex, {
  shouldForwardProp,
})(({ theme }) =>
  css({
    marginTop: theme.space.s,
    fontWeight: theme.fontWeights.default,
    backgroundColor: theme.colors.background.default,
    alignItems: 'center',
    borderRadius: theme.radii.default,
    paddingLeft: theme.space.m,
    border: theme.borders.default,
    transitionDuration: theme.transitions.default,
    pointerEvents: 'none',
    '&:hover': {
      borderColor: theme.colors.border.primary,
      backgroundColor: theme.colors.background.default,
    },
    svg: {
      marginRight: theme.space.m,
    },
    input: {
      pointerEvents: 'auto',
      borderRadius: `0 8px 8px 0`,
      paddingLeft: 0,
      padding: theme.space.m,
      '&:hover': {
        borderColor: 'transparent',
        backgroundColor: theme.colors.background.default,
      },
      '&:focus': {
        borderColor: 'transparent',
      },
    },
  }),
);

const SearchField = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.bg,
    border: theme.borders.default,
    borderRadius: theme.radii.default,
    padding: theme.space.m,
  }),
);

const Select = styled(DefaultSelect)(() =>
  css({
    margin: 0,
  }),
);

const SelectGroup = styled(Flex)(({ theme }) =>
  css({
    gap: theme.space.s,
    marginTop: theme.space.m,
  }),
);

const SearchResult = styled('div')(({ theme }) =>
  css({
    height: '100%',
    maxHeight: '270px',
    marginTop: theme.space.m,
  }),
);

const TextField = styled(DefaultTextField)(({ theme }) =>
  css({
    background: theme.colors.background.default,
  }),
);

const Footer = styled(Flex)(({ theme }) =>
  css({
    background: theme.colors.background.default,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    bottom: -1,
    paddingTop: theme.space.m,
    paddingBottom: theme.space.xs,
  }),
);

const prefectures = Object.entries(PREFECTURES).sort();

export const SearchCsClinics = React.memo((props: Props) => {
  const theme = useTheme();
  const { prefectureCode, page, totalPage } = useRecoilValue(searchCsClinicsPageInfoState);
  const resetPageInfo = useResetRecoilState(searchCsClinicsPageInfoState);

  const { loading: isCategoriesLoading, csCategories } = useFetchCsCategories();
  const { loading: isCitiesLoading, csCities } = useFetchCsCities();
  const {
    loading: isClnicsLoading,
    error,
    initialQueried,
    scrollRef,
    csClinics,
    handleClick,
    handleChangePage,
  } = useFetchCsClinics();

  const handleChangeCity = useRecoilCallback(
    ({ set }) =>
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newCity = e.currentTarget.value;
        set(searchCsClinicsPageInfoState, (_state) => ({
          ..._state,
          cityCode: newCity.length > 0 ? newCity : null,
        }));
      },
    [],
  );
  const handleChangeCategory = useRecoilCallback(
    ({ set }) =>
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newCategory = e.currentTarget.value;
        set(searchCsClinicsPageInfoState, (_state) => ({
          ..._state,
          categoryKey: newCategory.length > 0 ? newCategory : null,
        }));
      },
    [],
  );
  const handleChangePrefectureCode = useRecoilCallback(
    ({ set }) =>
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newCode = e.currentTarget.value;
        set(searchCsClinicsPageInfoState, (_state) => ({
          ..._state,
          prefectureCode: newCode.length > 0 ? newCode : null,
          cityCode: null,
        }));
      },
    [],
  );
  const handleChangeQuery = useRecoilCallback(
    ({ set }) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.currentTarget.value;
        set(searchCsClinicsPageInfoState, (_state) => ({
          ..._state,
          query: newQuery.length > 0 ? newQuery : null,
        }));
      },
    [],
  );

  useEffect(() => {
    resetPageInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr min-content">
      <SearchField>
        <Text block size="m" fontWeight="bold">
          紹介する病院・診療所を検索
        </Text>
        <Text block size="xs">
          CLINICSに掲載されている病院・診療所を紹介することができます。
        </Text>
        {error && (
          <Alert status="error" marginTop={theme.space.s}>
            {error}
          </Alert>
        )}
        <SelectGroup>
          <Select
            fill
            disabled={isCitiesLoading || isClnicsLoading}
            onChange={handleChangePrefectureCode}
          >
            <option value="">都道府県を選択</option>
            {prefectures.map(([code, name]) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </Select>
          {!prefectureCode || (!isCitiesLoading && csCities.length === 0) ? (
            <Tooltip
              content={
                !prefectureCode ? '都道府県を選択してください' : '選択可能な市区町村がありません'
              }
            >
              <Box width="100%">
                <Select fill disabled marginLeft={theme.space.s}>
                  <option hidden>市区町村を選択</option>
                </Select>
              </Box>
            </Tooltip>
          ) : (
            <Select
              fill
              disabled={isCitiesLoading || !prefectureCode || isClnicsLoading}
              marginLeft={theme.space.s}
              onChange={handleChangeCity}
            >
              <option value="">市区町村を選択</option>
              {csCities.map(({ code, name }) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </Select>
          )}
        </SelectGroup>
        <Box marginTop={theme.space.s}>
          <Select
            fill
            disabled={isCategoriesLoading || isClnicsLoading}
            onChange={handleChangeCategory}
          >
            <option value="">診療科を選択</option>
            {csCategories.map(({ id, key, name }) => (
              <option key={id} value={key}>
                {name}
              </option>
            ))}
          </Select>
        </Box>
        <Freeword>
          <Icon icon="search" size="l" />
          <TextField
            withIcon="left"
            placeholder="病院・診療所名で検索"
            disabled={isClnicsLoading}
            onChange={handleChangeQuery}
          />
        </Freeword>
        <Flex justifyContent="center" marginTop={theme.space.m}>
          <Button use="base" wide="half" disabled={isClnicsLoading} onClick={handleClick}>
            検索
          </Button>
        </Flex>
      </SearchField>
      <SearchResult>
        {initialQueried && !isClnicsLoading && csClinics.length === 0 ? (
          <Alert status="info" margin={theme.space.xs}>
            条件に一致する病院・診療所は見つかりませんでした
          </Alert>
        ) : (
          <ScrollBox ref={scrollRef} loading={isClnicsLoading}>
            {csClinics.map((clinic, idx) => (
              <CsClinic key={idx} clinic={clinic} onSelect={() => props.onSelect(clinic)} />
            ))}
          </ScrollBox>
        )}
      </SearchResult>
      <Footer>
        {csClinics.length > 0 && (
          <Pagination currentPage={page} totalPage={totalPage} onChange={handleChangePage} />
        )}
      </Footer>
    </Grid>
  );
});

SearchCsClinics.displayName = 'SearchCsClinics';
