import { useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { ScrollBox } from '~/components/blocks';
import { useGetKarteHistoriesLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { historyPageInfoState } from '~/state/partials/telemedicine_patient_profile/atoms';

export const useFetchHistories = (patientId?: string | null) => {
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const [pageInfo, setPageInfo] = useRecoilState(historyPageInfoState);
  const [getHistories, { called, loading, data }] = useGetKarteHistoriesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      const patient = getNode(_data, 'Patient');

      setPageInfo((_state) => ({ ..._state, totalPage: patient?.appointments.pagesCount ?? 1 }));
      scrollRef.current?.toTop();
    },
  });

  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const histories = useMemo(
    () => (currentData ? getNode(data, 'Patient')?.appointments.nodes || [] : []),
    [currentData, data],
  );

  useEffect(() => {
    if (patientId) {
      getHistories({
        variables: {
          id: patientId,
          page: pageInfo.page,
          perPage: pageInfo.perPage,
        },
      });
    }
  }, [getHistories, pageInfo.page, pageInfo.perPage, patientId]);

  return {
    loading: !called || loading,
    scrollRef,
    histories,
  };
};
