import { Appointment, DraftAppointment, PfDispensingRequestFragment } from '~/graphql';
import { getPfTel } from '~/utils/pf_contact_point';

import { openWithNoOpener } from './window';

type NodeId = Appointment['id'] | DraftAppointment['id'];

const BASE_URL = '/dispensing_request_preview/';
export const GET_DISPENSING_REQUEST_INTERVAL_MINUTES = 1000 * 60 * 60;

export const openPreviewPage = (nodeId: NodeId, isPrintable?: boolean) => {
  const baseUrl = `${BASE_URL}${nodeId}`;

  openWithNoOpener(`${baseUrl}${isPrintable ? '?print=true' : ''}`, {
    target: nodeId,
    width: 640,
    height: 720,
    bgColor: '#525659',
  });
};

export const openPreviewPartnerPage = (appointmentId: string) => {
  openWithNoOpener(`${BASE_URL}${appointmentId}?partner=true`, {
    target: appointmentId,
    width: 640,
    height: 720,
    bgColor: '#525659',
  });
};

export const formatMedicalInstitution = (request: PfDispensingRequestFragment | undefined) => {
  if (!request || !request.medicalInstitution) {
    return null;
  }

  const data = request.medicalInstitution;
  const medicalInstitution = data.medicalInstitution;
  const address = medicalInstitution.addresses.length > 0 ? medicalInstitution.addresses[0] : null;
  const physician = data.physician;

  return {
    name: medicalInstitution.name,
    address: address && `${address.prefecture}${address.city}${address.line}`,
    tel: getPfTel(medicalInstitution.telecoms),
    staffName: `${physician.familyName}${physician.givenName}`,
  };
};
