import { useEffect } from 'react';

import { useGetUberDeliveryLazyQuery } from '~/graphql';

export const useFetchUberCourier = (uberDeliveryId: string) => {
  const [getCourier, { called, loading, data }] = useGetUberDeliveryLazyQuery();
  const courier = data?.node?.__typename === 'UberDelivery' ? data.node.courier : null;

  useEffect(() => {
    getCourier({ variables: { uberDeliveryId } });
  }, [getCourier, uberDeliveryId]);

  return { courier, loading: !called || loading };
};
