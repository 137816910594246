import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import { Icon, Text } from '~/components/blocks';
import { OrganizationNotificationOnAgentNotificationsFragment } from '~/graphql';

import { ActionSnackbar } from '../ActionSnackbar';

type Props = {
  notification: OrganizationNotificationOnAgentNotificationsFragment;
  onClose: (notificationId: string) => void;
};

export const AgentNotification = React.memo((props: Props) => {
  const { notification, onClose } = props;
  const theme = useTheme();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openAgentSettingPage = useCallback(() => {
    if (router.pathname !== '/settings/agent') {
      router.push('/settings/agent');
      // unmountが完了する前にページ遷移が完了すると通知が再表示されてしまう
      // 通知を管理してる大元のリストから削除し、通知が再表示されないようにする
      onClose(notification.id);
    } else {
      handleClose();
    }
  }, [onClose, handleClose, notification, router]);

  const handleClick = useCallback(() => {
    openAgentSettingPage();
  }, [openAgentSettingPage]);

  const handleExited = useCallback(() => {
    onClose(notification.id);
  }, [notification.id, onClose]);

  //スナックバーが画面横から出てくるアニメーションを適応させるためuseEffectで通知を開く
  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <ActionSnackbar
      open={isOpen}
      onExited={handleExited}
      isNotification={false}
      onClick={handleClick}
      onClose={handleClose}
    >
      <Icon color="white" icon="alert" size="xxl" />
      <Text
        color="white"
        size="xs"
        fontWeight="bold"
        marginLeft={theme.space.m}
        whiteSpace="pre-wrap"
      >
        {notification.content}
      </Text>
    </ActionSnackbar>
  );
});

AgentNotification.displayName = 'AgentNotification';
