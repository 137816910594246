import { useTheme } from '@emotion/react';
import React, { ComponentProps } from 'react';

import { Chip } from '~/components/blocks';

type Props = {
  status: string;
};

export const MessageStatus = React.memo((props: Props) => {
  const theme = useTheme();
  const chipProps: {
    color: ComponentProps<typeof Chip>['color'];
    textColor: ComponentProps<typeof Chip>['textColor'];
    label: string;
  } = {
    color: 'green',
    label: '送信予定',
    textColor: 'white',
  };

  switch (props.status) {
    case 'processing':
      break;
    case 'sending':
      break;
    case 'successed':
      chipProps.label = '送信完了';
      chipProps.color = 'grey03';
      chipProps.textColor = 'black';
      break;
    case 'failed':
      chipProps.label = '送信エラー';
      chipProps.color = 'pink';
      break;
  }

  return (
    <Chip
      size="fixed"
      color={chipProps.color}
      textColor={chipProps.textColor}
      marginLeft={theme.space.m}
    >
      {chipProps.label}
    </Chip>
  );
});

MessageStatus.displayName = 'MessageStatus';
