import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useMarkOrganizationNotificationMutation } from '~/graphql';
import { followupMessageErrorState } from '~/state/layouts/SharedAppShell/atoms';
import { messageModalState } from '~/state/partials/message_modal/atoms';

export const useMarkNotification = () => {
  const { selectedMessageId } = useRecoilValue(messageModalState);
  const [markNotification] = useMarkOrganizationNotificationMutation();
  const { notifications } = useRecoilValue(followupMessageErrorState);

  useEffect(() => {
    const notification = notifications.find((n) => n.followupMessage.id === selectedMessageId);
    if (notification) {
      markNotification({
        variables: {
          input: {
            organizationNotificationId: notification.organizationNotificationId,
          },
        },
      });
    }
  }, [selectedMessageId, markNotification, notifications]);
};
