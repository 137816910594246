import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Flex, Icon, Text, Tooltip } from '~/components/blocks';
import { FollowupMessageEventPatientDetailFragment } from '~/graphql';
import {
  patientDetailDialogMessageState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';
import { Label } from '~/utils/label';

import { MessageIcon } from '../MessageIcon';
import { FollowupMessageTag } from './FollowupMessageTag';

type Props = {
  editing?: boolean;
  className?: string;
  readonly?: boolean;
  followupMessage: FollowupMessageEventPatientDetailFragment;
};

const Edit = styled(Box)(({ theme }) =>
  css({
    margin: `${theme.space.m} 0 0`,
    padding: `${theme.space.s} ${theme.space.m}`,
    fontSize: theme.fontSizes.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      color: theme.colors.text.primary,
    },
  }),
);

const Event = styled(Box)<{ error?: boolean; scheduled?: boolean; clicked?: boolean }>(
  ({ theme, error, scheduled, clicked }) =>
    css({
      border: `2px solid ${theme.colors.colorPallete.grey03}`,
      background: theme.colors.background.default,
      borderRadius: '0px 6px 6px 6px',
      boxShadow: `2px 4px 8px ${rgba(theme.colors.background.black, 0.05)}`,
      padding: theme.space.m,
      transitionDuration: theme.transitions.default,
      '& + &': {
        marginTop: theme.space.l,
      },
      '&:hover': {
        cursor: 'default',
      },
      ...(error && {
        background: theme.colors.background.alert,
      }),
      ...(clicked && {
        '&:hover': {
          cursor: 'pointer',
        },
        ...(error && {
          border: `2px solid ${theme.colors.border.secondary}`,
        }),
        ...(scheduled && {
          border: `2px solid ${theme.colors.border.primary}`,
          background: theme.colors.colorPallete.lightGreen,
        }),
      }),
    }),
);

const TagsWrapper = styled(Flex)(() =>
  css({
    flexWrap: 'wrap',
  }),
);

export const FollowupMessageEvent: React.FC<Props> = (props) => {
  const { editing, followupMessage } = props;
  const theme = useTheme();
  const [isClicked, setIsClicked] = useState(editing);
  const { selectedMessageId } = useRecoilValue(patientDetailDialogMessageState);
  const { type } = useRecoilValue(patientDetailDialogState);

  const deliverResult = followupMessage.messageDeliverResult;
  const isError = deliverResult?.status === 'failed';
  const isScheduled = followupMessage.status === 'scheduled';
  const isMessageTab = type === 'message';
  const isClickable = !props.readonly && isMessageTab && (isScheduled || isError);
  const isEditable = isScheduled && isClickable;

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        if (!isClickable) return;
        if (selectedMessageId && selectedMessageId !== followupMessage.id) return;

        setIsClicked(true);
        set(patientDetailDialogMessageState, (_state) => ({
          ..._state,
          selectedMessageId: followupMessage.id,
        }));
      },
    [followupMessage.id, isClickable, selectedMessageId],
  );

  useEffect(() => {
    if (!selectedMessageId && isClicked) {
      setIsClicked(false);
    }
  }, [selectedMessageId, isClicked]);

  return (
    <Flex alignItems="top">
      <Tooltip
        content={
          <Text color="white" size="xs" fontWeight="bold">
            患者に送信
          </Text>
        }
      >
        <Flex marginLeft={theme.space.xs}>
          <MessageIcon color="white" />
        </Flex>
      </Tooltip>
      <Flex flexDirection="column" width="100%" marginLeft={theme.space.m}>
        <Text fontWeight="bold" whiteSpace="nowrap" size="xs">
          {deliverResult
            ? `${Label.YYYYMMDDja(deliverResult.updatedAt)} ${Label.HHMM(deliverResult.updatedAt)}`
            : `${Label.YYYYMMDDja(followupMessage.sendAt)} ${Label.HHMM(followupMessage.sendAt)}`}
          &nbsp;|&nbsp;
          {followupMessage.practitioner.familyName}&nbsp;{followupMessage.practitioner.givenName}
        </Text>
        {isError ? (
          <Flex justifyContent="flex-start" alignItems="center">
            <Icon icon="attention" size="s" />
            <Text fontWeight="bold" color="pink" size="xs">
              送信エラー
            </Text>
          </Flex>
        ) : isScheduled ? (
          <Flex justifyContent="flex-start" alignItems="center">
            <Icon icon="info" color="blue" size="s" />
            <Text fontWeight="bold" color="blue" size="xs">
              送信予定
            </Text>
          </Flex>
        ) : null}
        <Event
          className={props.className}
          error={isError}
          scheduled={isScheduled}
          clicked={isClicked}
          onClick={handleClick}
        >
          <Box marginTop={theme.space.m}>
            <Text size="s" wordBreak="break-word" whiteSpace="pre-line">
              {followupMessage.message}
            </Text>
          </Box>
          <TagsWrapper marginTop={theme.space.m}>
            {followupMessage.medicationFollowupQuestionnaireRevision && (
              <FollowupMessageTag>
                <Text size="xs" color="navy">
                  {`質問票（${followupMessage.medicationFollowupQuestionnaireRevision.medicationFollowupQuestionnaire.revision.subject}）`}
                </Text>
              </FollowupMessageTag>
            )}
            {followupMessage.autoReplySetting && (
              <FollowupMessageTag>
                <Text size="xs" color="navy">
                  自動返信有効
                </Text>
              </FollowupMessageTag>
            )}
          </TagsWrapper>
          {isEditable ? (
            <Edit>
              <Icon icon="edit" size="s" color={isClicked ? 'green' : 'grey'} />
              <Text marginLeft={theme.space.s} color={isClicked ? 'green' : 'grey'}>
                {isClicked ? '編集中' : '編集'}
              </Text>
            </Edit>
          ) : null}
          {isError ? (
            <Edit>
              <Icon icon="edit" size="s" color="pink" />
              <Text marginLeft={theme.space.s} color="pink">
                {isClicked ? '編集中' : '編集'}
              </Text>
            </Edit>
          ) : null}
        </Event>
      </Flex>
    </Flex>
  );
};

FollowupMessageEvent.displayName = 'FollowupMessageEvent';
