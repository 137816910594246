import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Formik, FormikProps } from 'formik';
import React, { forwardRef, useCallback } from 'react';

import { Box, EntryList, Textarea, Tooltip } from '~/components/blocks';

import { Fields } from './types';
import { validationSchema } from './validation';

type Props = {
  initialValues: Fields;
  disabled: boolean;
  hasMessages: boolean;
  onSubmit: (values: Fields) => void;
  setComment?: React.Dispatch<React.SetStateAction<string>>;
};

const CommentBox = styled(Box)<{ hasErrors: boolean }>(({ theme, hasErrors }) =>
  css({
    height: hasErrors ? '139px' : '109px',
    width: '100%',
    [`@media ${theme.mediaQueries.tablet}`]: {
      height: hasErrors ? '130px' : '100px',
    },
  }),
);

const CONTENT = (
  <>
    メッセージを送信してフォローアップを開始すると、
    <br />
    メモを利用できます
  </>
);

export const FollowupCommentForm = forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const theme = useTheme();

  const handleChange = useCallback(
    (formik: FormikProps<Fields>, e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (props.setComment) {
        props.setComment(e.target.value);
      }
      formik.handleChange(e);
    },
    [props],
  );

  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
    >
      {(formik) => (
        <CommentBox hasErrors={!!formik.errors.comment}>
          <EntryList marginTop={theme.space.m}>
            <EntryList.Body>
              {!props.hasMessages ? (
                <Tooltip placement="top-start" content={CONTENT}>
                  <Textarea
                    minHeight="84px"
                    disabled={true}
                    name="comment"
                    error={formik.errors.comment}
                    value={formik.values.comment}
                    onChange={(e) => handleChange(formik, e)}
                    placeholder="フォローアップメモ入力"
                  />
                </Tooltip>
              ) : (
                <Textarea
                  minHeight="84px"
                  disabled={props.disabled}
                  name="comment"
                  error={formik.errors.comment}
                  value={formik.values.comment}
                  onChange={(e) => handleChange(formik, e)}
                  placeholder="フォローアップメモ入力"
                />
              )}
            </EntryList.Body>
          </EntryList>
        </CommentBox>
      )}
    </Formik>
  );
});

FollowupCommentForm.displayName = 'FollowupCommentForm';
