import QRCode from 'qrcode';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { useCreateOtpSecretMutation } from '~/graphql';
import { twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';

export const useCreateQrCode = () => {
  const [error, setError] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [qrCodeData, setQrCodeData] = useState<string | null>(null);

  const [createOtpSecret] = useCreateOtpSecretMutation({
    // 戻り値のprovisioningUriをキャッシュしないようにするため
    fetchPolicy: 'no-cache',
  });

  const create = useRecoilCallback(
    ({ set }) =>
      async () => {
        setIsCreating(true);
        setError(null);

        try {
          const result = await createOtpSecret({
            variables: {
              input: {},
            },
          });
          const otpSecret = result.data?.createOtpSecret?.practitionerOtpSecret;

          if (otpSecret) {
            QRCode.toDataURL(otpSecret.provisioningUri, { width: 142, margin: 4 }, (error, url) => {
              if (error) {
                setError('QRコードの作成に失敗しました。ウィンドウを閉じて再度お試しください');
              } else {
                setQrCodeData(url);
                set(twoFactorAuthModalState, (_state) => ({
                  ..._state,
                  otpSecretId: otpSecret.id,
                  qrCodeData: url,
                }));
              }
            });
          } else {
            setError('エラーが発生しました。ウィンドウを閉じて再度お試しください');
          }
        } catch (_error) {
          setError(_error?.message || 'エラーが発生しました');
        }
        setIsCreating(false);
      },
    [createOtpSecret],
  );

  return { error, creating: isCreating, qrCodeData, create };
};
