import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { DEFAULT_AUTO_REPLY_MESSAGE } from '~/constants/medication_followup_message_template';
import {
  MessageDeliverResultStatus,
  useGetFollowupDraftMessageByCurrentPractitionerLazyQuery,
  useGetFollowupMessageLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { useMedicationFollowupQuestionnaires } from '~/hooks/use-medication-followup-questionnaires';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { messagePaneState } from '~/state/partner_medicationfollowupmessage/atoms';
import { removeLastUrl } from '~/utils/medication_followup_message';

import { Fields } from './InputPane/types';
import { Patient } from './types';

export const useDefaultValues = (
  selectMessageId: string | null,
  patient: Patient,
  isEditable: boolean,
) => {
  const [getMessage, { loading: loadingMessage, data: messageData }] =
    useGetFollowupMessageLazyQuery();
  const { questionnaires } = useMedicationFollowupQuestionnaires();
  const message = getNode(messageData, 'MedicationFollowupMessage');
  const isFailed = message?.messageDeliverResult?.status === MessageDeliverResultStatus.Failed;

  const setMessageModalState = useSetRecoilState(messageModalState);
  const setMessagePaneState = useSetRecoilState(messagePaneState);
  const [isLoadedDraftMessage, setIsLoadedDraftMessage] = useState(false);
  const [
    getFollowupDraftMessageByCurrentPractitioner,
    { loading: loadingDraftMessage, data: draftMessageData },
  ] = useGetFollowupDraftMessageByCurrentPractitionerLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      const node =
        getNode(result, 'Patient') ||
        getNode(result, 'WebVisitor') ||
        getNode(result, 'DirectVisitor');

      setIsLoadedDraftMessage(true);
      setMessageModalState((state) => ({
        ...state,
        draftMessageId: node?.medicationFollowupDraftMessages.nodes[0]?.id || null,
      }));
      setMessagePaneState((state) => ({
        ...state,
        draftMessageId: node?.medicationFollowupDraftMessages.nodes[0]?.id || null,
      }));
    },
  });
  const draftMessage = useMemo(() => {
    const node =
      getNode(draftMessageData, 'Patient') ||
      getNode(draftMessageData, 'WebVisitor') ||
      getNode(draftMessageData, 'DirectVisitor');

    return node?.medicationFollowupDraftMessages.nodes[0] || null;
  }, [draftMessageData]);

  const defaultClinic = useMemo(() => {
    if (isEditable) {
      return message?.csClinic;
    } else {
      return draftMessage?.data.csClinic;
    }
  }, [draftMessage?.data.csClinic, isEditable, message?.csClinic]);
  const defaultQuestionnaire = useMemo(() => {
    const firstQuestionnaire = questionnaires ? questionnaires[0] : null;
    return (
      message?.medicationFollowupQuestionnaireRevision?.medicationFollowupQuestionnaire ??
      firstQuestionnaire
    );
  }, [
    message?.medicationFollowupQuestionnaireRevision?.medicationFollowupQuestionnaire,
    questionnaires,
  ]);
  const defaultQuestionnaireId = defaultQuestionnaire?.id || '';
  const initialValues: Fields = {
    attachment: 'questionnaire_sheet',
    csClinicId: '',
    medicationFollowupQuestionnaireId: defaultQuestionnaireId,
    fixed: true,
    sendDate: null,
    sendTime: '',
    message: '',
    isEnabledAutoReplySetting: false,
    autoReplyMessage: DEFAULT_AUTO_REPLY_MESSAGE,
    withAutoReplyQuestionnaire: false,
  };

  const defaultValues: Fields = useMemo(() => {
    if (selectMessageId && message) {
      const sendDate = new Date(message.sendAt);
      const sendTime = format(sendDate, 'HH');

      return {
        attachment: message.medicationFollowupQuestionnaireRevision
          ? 'questionnaire_sheet'
          : message.csClinic
            ? 'clinic'
            : '',
        csClinicId: message.csClinic?.id ?? '',
        medicationFollowupQuestionnaireId:
          message.medicationFollowupQuestionnaireRevision?.medicationFollowupQuestionnaire.id ||
          defaultQuestionnaireId,
        sendDate,
        sendTime,
        fixed: true,
        message: message.csClinic ? removeLastUrl(message.message) : message.message,
        isEnabledAutoReplySetting: !!message.autoReplySetting,
        autoReplyMessage: message.autoReplySetting?.message || DEFAULT_AUTO_REPLY_MESSAGE,
        withAutoReplyQuestionnaire: !!message.autoReplySetting?.questionnaireRevision,
      };
    }

    if (isLoadedDraftMessage && draftMessage) {
      const data = draftMessage.data;
      const attachment = data.medicationFollowupQuestionnaire?.id
        ? 'questionnaire_sheet'
        : data.checkboxRecommendation
          ? 'clinic'
          : '';

      return {
        attachment,
        csClinicId: data.csClinic?.id || '',
        medicationFollowupQuestionnaireId:
          data.medicationFollowupQuestionnaire?.id || defaultQuestionnaireId,
        fixed: data.fixedDate,
        sendDate: data.sendDate ? new Date(data.sendDate) : null,
        sendTime: data.sendTime || '',
        message: data.message || '',
        isEnabledAutoReplySetting: data.checkboxAutoReplySetting,
        autoReplyMessage: data.autoReplySettingMessage || '',
        withAutoReplyQuestionnaire: data.checkboxAutoReplySettingQuestionnaire,
      };
    }

    return initialValues;

    // despにdraftMessageを入れると、自動保存APIリクエストの度にこのuseMemoが動いてしまい、メッセージ入力がスムーズにできなくなるので入れない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultQuestionnaire?.id, isLoadedDraftMessage, message, selectMessageId]);

  useEffect(() => {
    if (!isEditable) return;

    if (selectMessageId) {
      getMessage({
        variables: {
          id: selectMessageId,
        },
      });
    }
  }, [getMessage, isEditable, selectMessageId]);

  useEffect(() => {
    if (isEditable) return;

    if (patient.id) {
      getFollowupDraftMessageByCurrentPractitioner({
        variables: {
          id: patient.id,
        },
      });
    }
  }, [getFollowupDraftMessageByCurrentPractitioner, isEditable, patient.id]);

  return {
    failed: isFailed,
    loadingMessage,
    loadingDraftMessage,
    defaultClinic,
    defaultQuestionnaire,
    defaultValues,
    initialValues,
  };
};
