import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { Formik, FormikProps } from 'formik';
import React, { forwardRef } from 'react';
import { useRecoilState } from 'recoil';

import {
  Alert,
  Box,
  EntryList,
  MAX_FILE_SIZE,
  Radio,
  RadioGroup,
  Text,
  TextField,
  Uploader,
} from '~/components/blocks';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import { draftAppointmentPatientProfileDrawerState } from '~/state/reception/atoms';
import { getExtensions } from '~/utils/file';

import { Fields } from './types';
import { validationSchema } from './validation';

export const DEFAULT_NAME = {
  recept: '領収書',
  drugInformation: '薬剤情報',
} as const;

type Props = {
  disabled: boolean;
  initialValue: Fields;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (value: Fields) => void;
};

const OuterAlert = styled('div')(({ theme }) =>
  css({
    padding: `${theme.space.m} ${theme.space.l}`,
    borderRadius: theme.radii.default,
    backgroundColor: theme.colors.background.warning,
    border: theme.borders.warning,
    marginBottom: `${theme.space.l}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.s,
    },
  }),
);

const InnerAlert = styled(Alert)(({ theme }) =>
  css({
    border: 'none',
    padding: `${theme.space.s}`,
  }),
);

export const FileForm = forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const theme = useTheme();
  const { disabled, setIsValid } = props;
  const [{ isOpen: isPatientDrawerOpen }] = useRecoilState(profileDrawerState);
  const [{ isOpen: isDraftAppointmentPatientDrawerOpen }] = useRecoilState(
    draftAppointmentPatientProfileDrawerState,
  );

  const isOpen = isPatientDrawerOpen || isDraftAppointmentPatientDrawerOpen;

  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      initialValues={props.initialValue}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
    >
      {(formik) => {
        // https://stackoverflow.com/questions/61031464/setstate-called-in-render-prop-is-causing-a-react-warning
        setTimeout(() => {
          setIsValid(formik.dirty && formik.isValid);
        }, 0);

        return (
          <>
            <OuterAlert>
              <InnerAlert status="warning">
                <Text>
                  患者に共有されます
                  <br />
                  内容に間違いがないか確認の上、
                  <br />
                  送信してください
                </Text>
              </InnerAlert>
            </OuterAlert>
            <EntryList>
              <EntryList.Head>ファイル名</EntryList.Head>
              <EntryList.Body>
                <RadioGroup>
                  <Radio
                    label="領収書"
                    name="name"
                    value={DEFAULT_NAME.recept}
                    checked={formik.values.name === DEFAULT_NAME.recept}
                    onChange={formik.handleChange}
                  />
                  <Radio
                    label="薬剤情報"
                    name="name"
                    value={DEFAULT_NAME.drugInformation}
                    checked={formik.values.name === DEFAULT_NAME.drugInformation}
                    onChange={formik.handleChange}
                  />
                  <Radio
                    label="その他"
                    name="name"
                    value=""
                    checked={
                      formik.values.name !== DEFAULT_NAME.recept &&
                      formik.values.name !== DEFAULT_NAME.drugInformation
                    }
                    onChange={formik.handleChange}
                  />
                </RadioGroup>
                {formik.values.name !== DEFAULT_NAME.recept &&
                  formik.values.name !== DEFAULT_NAME.drugInformation && (
                    <Box marginTop={theme.space.m}>
                      <TextField
                        name="name"
                        disabled={disabled}
                        error={formik.errors.name}
                        onChange={formik.handleChange}
                      />
                    </Box>
                  )}
              </EntryList.Body>
            </EntryList>
            <EntryList>
              <EntryList.Head>ファイル</EntryList.Head>
              <EntryList.Body>
                {/* isOpenの判定は、ドロワーの開閉によってUploaderを再レンダリングさせてUploader内のステートをクリアするために入れてる */}
                {isOpen && (
                  <Uploader
                    disabled={disabled}
                    label={`患者に送るファイルを\nドラッグ＆ドロップ、または`}
                    accepts={['application/pdf', 'image/jpeg', 'image/png']}
                    placefolder={`${getExtensions([
                      'application/pdf',
                      'image/jpeg',
                      'image/png',
                    ]).join(',')}でファイルサイズは「${MAX_FILE_SIZE / 1024 / 1024}MB以下」`}
                    value={formik.values.file}
                    onChange={(file) => formik.setFieldValue('file', file)}
                  />
                )}
              </EntryList.Body>
            </EntryList>
          </>
        );
      }}
    </Formik>
  );
});

FileForm.displayName = 'FileForm';
