export const usernameRegexp = /^[A-Za-z0-9]+$/;
export const onetimeCodeRegexp = usernameRegexp;
export const twoFactorOnetimeCodeRegexp = /^[0-9]*$/;
export const katakanaRegexp = /^[ァ-ンーヴヷヺ]+$/;
export const passwordRegexp = /^[A-Za-z0-9!"#$%&'()*+\-.,/:;<=>?@[\\\]^_`{|}~]+$/;
export const postalCodeRegexp = /^([0-9]{3}-[0-9]{4}|[0-9]{7})$/;
export const latitudeRegexp = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
export const longitudeRegexp = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
export const mobilePhoneRegexp = /^0[789]0[0-9]{8}$|^(0[789]0)-[0-9]{4}-[0-9]{4}$/;
export const bankAccountHolderNameRegexp = /^[ァ-ヴＡ-Ｚ０-９．（）ー‐－\u3000]+$/;
export const bankAccountHolderNameSymbolsRegexp = /^[．（）ー‐－\u3000]+$/;
export const numberRegexp = /^[0-9０-９]+$/;
export const halfWideNumberRegexp = /^[0-9]+$/;
export const numberOrHyphenRegexp = /^[0-9０-９-‐−ー―ｰ－]+$/;
export const timeRegexp = /^(([01][0-9]|2[0-3]):[03]0|24:00)$/;
export const certNameRegexp = usernameRegexp;
export const loginPageNoindexRegexp = /login\?next/;
export const loginPageRegexp = /login/;
export const corporateNumberRegexp = /^[0-9０-９]{13}$/;
