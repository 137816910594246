import { atom } from 'recoil';

import { EditBaseDrawerState, GeocodingState } from './types';

export const editBaseDrawerState = atom<EditBaseDrawerState>({
  key: 'settings.organizationBase.component.editBase',
  default: {
    isOpen: false,
  },
});

export const geocodingState = atom<GeocodingState>({
  key: 'settings.organizationBase.component.geocoding',
  default: {
    geocoder: null,
  },
});
