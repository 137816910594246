import { useTheme } from '@emotion/react';
import React, { useCallback, useRef } from 'react';
import { useRecoilCallback } from 'recoil';

import { Alert, Box, Button, Drawer, Grid, Icon, Text } from '~/components/blocks';
import { GuestPatientForm } from '~/components/partials/GuestPatientForm';
import { Fields } from '~/components/partials/GuestPatientForm/types';
import { DirectVisitorDetailDialogProfileFragment } from '~/graphql';
import { patientDetailDialogProfileState } from '~/state/partials/patient_detail_dialog/atoms';

import { useDefaultValues } from './use-default-values';
import { useUpdateGuestPatient } from './use-update-guest_patient';

type Props = {
  guestPatient: DirectVisitorDetailDialogProfileFragment;
};

export const EditPatientProfile = React.memo((props: Props) => {
  const theme = useTheme();
  const formRef = useRef<RefAttributeType<typeof GuestPatientForm>>(null);

  const defaultValues = useDefaultValues(props.guestPatient);
  const { error, isUpdating, containerRef, update } = useUpdateGuestPatient();

  const handleSend = useCallback(() => {
    if (!formRef.current) return;
    formRef.current.submitForm();
  }, []);
  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        await update(values, props.guestPatient.id);
      } catch {
        // 何もしない
      }
    },
    [update, props.guestPatient.id],
  );
  const handleBack = useRecoilCallback(
    ({ set }) =>
      () =>
        set(patientDetailDialogProfileState, (_state) => ({ ..._state, type: null })),
    [],
  );

  return (
    <Grid height="100%" overflow="auto" gridTemplateRows="1fr min-content">
      <Box height="100%" overflow="auto" ref={containerRef}>
        {error && (
          <Alert status="error" marginBottom={theme.space.l} whiteSpace="pre-wrap">
            {error}
          </Alert>
        )}

        <Text fontWeight="bold" size="l">
          患者情報編集
        </Text>
        <GuestPatientForm
          ref={formRef}
          disabled={isUpdating}
          initialValues={defaultValues}
          onSubmit={handleSubmit}
        />
        <Button
          size="xxs"
          use="white"
          disabled={isUpdating}
          marginTop={theme.space.s}
          onClick={handleBack}
        >
          <Icon icon="back-line" size="m" />
          <Text size="xs">戻る</Text>
        </Button>
      </Box>
      <Drawer.Footer>
        <Button use="base" wide="fill" size="l" loading={isUpdating} onClick={handleSend}>
          保存
        </Button>
      </Drawer.Footer>
    </Grid>
  );
});

EditPatientProfile.displayName = 'EditPatientProfile';
