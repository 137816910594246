import { useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { useDeactivateOtpSecretsMutation } from '~/graphql';
import { twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';

export const useDeactivateOtpSecrets = () => {
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [deactivate] = useDeactivateOtpSecretsMutation();

  const handleSubmit = useRecoilCallback(
    ({ reset }) =>
      async () => {
        setIsDeactivating(true);
        setError(null);

        try {
          await deactivate({
            variables: {
              input: {},
            },
          });
          reset(twoFactorAuthModalState);
        } catch (_error) {
          setError(_error?.message || 'エラーが発生しました');
        }
        setIsDeactivating(false);
      },
    [deactivate],
  );

  return {
    deactivating: isDeactivating,
    error,
    handleSubmit,
  };
};
