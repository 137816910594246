import { pdfjs } from 'react-pdf';

import { publicPath } from './path';

/**
 * import x from 'pdfjs-dist/webpack' とかうまく動かんので workerSrc を使う
 *
 * pdfjs-distを更新したら↓のコマンドも実行する
 * ```bash
 * cp node_modules/pdfjs-dist/build/pdf.worker.min.js public/
 * ```
 */
pdfjs.GlobalWorkerOptions.workerSrc = publicPath('/pdf.worker.min.js');
