import { useCallback, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { useDeactivateFollowupMessageMutation } from '~/graphql';
import {
  deleteMedicationFollowupMessageModalState,
  eventPageInfoState,
  patientDetailDialogMessageState,
} from '~/state/partials/patient_detail_dialog/atoms';

import { useBuildRefetchQueries } from '../NewMedicationFollowupMessagePane/InputPanel/use-build-refetch_queries';

export const useDeactivateFollowupMessage = () => {
  const refetchQueries = useBuildRefetchQueries();
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const reset = useResetRecoilState(deleteMedicationFollowupMessageModalState);
  const setEventInfoState = useSetRecoilState(eventPageInfoState);
  const setPatientDetailDialogMessageState = useSetRecoilState(patientDetailDialogMessageState);

  const [deactivateMessage] = useDeactivateFollowupMessageMutation({
    onCompleted: () => {
      setEventInfoState((_stagte) => ({
        ..._stagte,
        isResetEvent: true,
        page: 1,
        perPage: 20,
      }));
      setPatientDetailDialogMessageState((_state) => ({
        ..._state,
        selectedMessageId: null,
      }));
    },
    refetchQueries,
  });

  const deactivate = useCallback(
    async (messageId: string) => {
      setIsDeactivating(true);
      setError(null);

      try {
        await deactivateMessage({
          variables: {
            input: {
              medicationFollowupMessageId: messageId,
            },
          },
        });

        reset();
      } catch (error) {
        setError(error?.message || 'エラーが発生しました');
      }
      setIsDeactivating(false);
    },
    [deactivateMessage, reset],
  );

  return {
    isDeactivating,
    error,
    deactivate,
  };
};
