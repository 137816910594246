import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Flex, List } from '~/components/blocks';

import { Notification } from './types';

type Props = {
  notification: Notification;
};

const ListItem = styled(List.Item)(({ theme }) =>
  css({
    borderTop: theme.borders.default,
    '&:first-of-type': {
      borderTop: '0',
    },
    opacity: 0.5,
    '&:hover': {
      background: 'none',
    },
  }),
);

const PatientName = styled('dl')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.m,
    fontWeight: theme.fontWeights.bold,
  }),
);

const ExpirationDate = styled('dl')(({ theme }) =>
  css({
    display: 'flex',
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    marginTop: theme.space.s,
    '& > dt': {
      color: theme.colors.colorPallete.grey01,
    },
  }),
);

export const ExpiredMedicineNoteNotification = React.memo((props: Props) => {
  const { notification } = props;

  return (
    <ListItem height="75px">
      <Flex justifyContent="space-between" height="100%">
        <Box>
          <PatientName>
            <dt>{notification.patientProfile.name}</dt>
          </PatientName>
          <ExpirationDate>
            <dt>有効期限：</dt>
            <dd>{`${notification.expiresAt} (期限切れ)`}</dd>
          </ExpirationDate>
        </Box>
      </Flex>
    </ListItem>
  );
});

ExpiredMedicineNoteNotification.displayName = 'ExpiredMedicineNoteNotification';
