import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, variant } from 'styled-system';

type Props = {
  position?: 'right';
  appearance?: 'sort';
};

const Root = styled('div', {
  shouldForwardProp,
})<Props>(
  () =>
    css({
      display: 'flex',
      alignItems: 'center',
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'appearance',
        variants: {
          sort: css({
            paddingBottom: theme.space.m,
            borderBottom: theme.borders.default,
          }),
        },
      }),
    ),
);

const Item = styled('div', {
  shouldForwardProp,
})<Props>(
  ({ theme }) =>
    css({
      whiteSpace: 'nowrap',
      padding: `0 ${theme.space.m}`,
    }),
  () =>
    compose(
      variant({
        prop: 'position',
        variants: {
          right: css({
            marginLeft: 'auto',
          }),
        },
      }),
    ),
);

export const ToolBar = Object.assign(Root, { Item });
