import { Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { AppointmentDeliveryMethod, ChargeAppointmentFragment } from '~/graphql';
import { useB2cloud } from '~/hooks/use-b2cloud';
import { useSystemAmount } from '~/hooks/use-system_amount';
import { chargeModalState } from '~/state/partials/charge_modal/atoms';

import { ConfirmPanel } from './ConfirmPanel';
import { InputPanel } from './InputPanel';
import { Fields } from './types';
import { useCreateInitialValues } from './use-create_initialvalues';
import { useFetchFeeSetting } from './use-fetch-fee_setting';
import { validationSchema } from './validation';

type Props = {
  appointment: ChargeAppointmentFragment;
};

export const ChargePanel = React.memo((props: Props) => {
  const { appointment } = props;
  const feeSettings = useFetchFeeSetting();
  const systemAmount = useSystemAmount(appointment.id);
  const { enabledB2cloud } = useB2cloud();
  const [isConfirm, setIsConfirm] = useState(false);

  const formikRef = useRef<FormikProps<Fields>>(null);

  const initialValues = useCreateInitialValues(appointment, feeSettings, systemAmount);
  // 当日配達・配達の時は表示しない
  const needsDeliveryMethod =
    !!appointment?.telemedicine &&
    !!enabledB2cloud &&
    appointment.deliveryMethod !== AppointmentDeliveryMethod.SameDayDelivery &&
    appointment?.deliveryMethod !== AppointmentDeliveryMethod.Hand;

  const state = useRecoilValue(chargeModalState);
  const resetState = useResetRecoilState(chargeModalState);
  const handleConfirm = useCallback(() => setIsConfirm(true), []);
  const handleBack = useCallback(() => setIsConfirm(false), []);

  useEffect(() => {
    if (!state.isOpen) {
      formikRef.current?.resetForm();
      setIsConfirm(false);
      resetState();
    }
  }, [resetState, state.isOpen]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleConfirm}
    >
      {(formik) =>
        !isConfirm ? (
          <InputPanel
            formik={formik}
            appointment={appointment}
            needsDeliveryMethod={needsDeliveryMethod}
            feeSettings={feeSettings}
            systemAmount={systemAmount}
            onConfirm={handleConfirm}
          />
        ) : (
          <ConfirmPanel
            appointment={appointment}
            needsDeliveryMethod={needsDeliveryMethod}
            values={formik.values}
            onBack={handleBack}
          />
        )
      }
    </Formik>
  );
});

ChargePanel.displayName = 'ChargePanel';
