import React from 'react';

import { Box, Drawer, Grid, Loader } from '~/components/blocks';
import {
  DirectVisitorDetailDialogProfileFragment,
  PatientDetailDialogProfileFragment,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';

import { DirectVisitorKarte } from './DirectVisitorKarte';
import { PatientButton } from './PatientButton';
import { PatientKarte } from './PatientKarte';
import { WebVisitorKarte } from './WebVisitorKarte';

type Props = {
  patient?:
    | DirectVisitorDetailDialogProfileFragment
    | WebVisitorDetailDialogProfileFragment
    | PatientDetailDialogProfileFragment;
  isOpen: boolean;
  onClick: () => void;
  width?: string;
  isPharmacist: boolean;
};

export const PatientDrawer = React.memo((props: Props) => {
  return (
    <Grid height="100%" overflow="auto" gridTemplateRows="1fr min-content">
      <Drawer fullHeight open={props.isOpen} width={props.width} variant="persistent">
        {props.patient?.__typename === 'DirectVisitor' ? (
          <DirectVisitorKarte visitor={props.patient} />
        ) : props.patient?.__typename === 'Patient' ? (
          <PatientKarte patient={props.patient} />
        ) : props.patient?.__typename === 'WebVisitor' ? (
          <WebVisitorKarte visitor={props.patient} />
        ) : (
          <Loader open inside />
        )}
      </Drawer>
      {props.isPharmacist && (
        <Box>
          <PatientButton isOpen={props.isOpen} onClick={props.onClick} />
        </Box>
      )}
    </Grid>
  );
});

PatientDrawer.displayName = 'PatientDrawer';
