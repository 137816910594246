import { useGetClientAuthenticationStatusQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useClientAuthSetting = () => {
  const { data, loading } = useGetClientAuthenticationStatusQuery({ fetchPolicy: 'cache-first' });
  const organization = data ? getMe(data)?.organization : null;
  const company = organization?.company;

  const clientAuth = company?.clientAuthentication;
  const enable = clientAuth?.enable || false;
  const startAt = clientAuth?.startAt ? new Date(clientAuth.startAt) : null;

  const waitingCount = organization?.waitingCount.nodesCount || 0;
  const downloadedCount = organization?.downloadedCount.nodesCount || 0;
  const validCount = organization?.validCount.nodesCount || 0;

  const hasClientCerts = waitingCount + downloadedCount + validCount > 0;
  const allInstalled = waitingCount + downloadedCount === 0 && validCount > 0;

  return { enable, startAt: startAt || null, hasClientCerts, allInstalled, loading };
};
