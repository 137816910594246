import { useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  useGetCurrentPractitionerQuery,
  useGetTracingReportDispensingRequestLazyQuery,
  useGetTracingReportDraftByCurrentPractitionerLazyQuery,
  useGetTracingReportSheetLazyQuery,
} from '~/graphql';
import { getMe, getNode } from '~/graphql/utility';
import { tracingReportPaneState } from '~/state/partials/tracingreport_panel/atoms';
import { Label } from '~/utils/label';

import { buildText } from '../../sheet';
import { Fields } from './types';

export const useDefaultValues = (
  selectedSheetId: string | null,
  patientId: string | null,
  guestPatientId: string | null,
) => {
  const { data } = useGetCurrentPractitionerQuery();
  const [getSheet, { data: sheetData }] = useGetTracingReportSheetLazyQuery();
  const [getDispensingRequest, { data: requestData }] =
    useGetTracingReportDispensingRequestLazyQuery();

  const currentPractitioner = data ? getMe(data) : undefined;
  const sheet = getNode(sheetData, 'MedicationFollowupQuestionnaireSheet');
  const answerdAt = sheet ? Label.YYYYMMDDja(sheet.createdAt) : undefined;
  const sheetForReport = answerdAt && sheet ? buildText(answerdAt, sheet.answers) : undefined;
  const medicalInstitution = getNode(requestData, 'Patient')?.appointments.nodes[0]
    .pfDispensingRequests[0]?.medicalInstitution;

  const setTracingReportPaneState = useSetRecoilState(tracingReportPaneState);
  const [isLoadedTracingReportDraft, setIsLoadedTracingReportDraft] = useState(false);
  const [
    getTracingReportDraftByCurrentPractitioner,
    { loading: loadingTracingReportDraft, data: tracingReportDraftData },
  ] = useGetTracingReportDraftByCurrentPractitionerLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      const node =
        getNode(result, 'Patient') ||
        getNode(result, 'WebVisitor') ||
        getNode(result, 'DirectVisitor');

      setIsLoadedTracingReportDraft(true);
      setTracingReportPaneState((state) => ({
        ...state,
        tracingReportDraftId: node?.tracingReportDrafts.nodes[0]?.id || null,
      }));
    },
  });
  const tracingReportDraft = useMemo(() => {
    const node =
      getNode(tracingReportDraftData, 'Patient') ||
      getNode(tracingReportDraftData, 'WebVisitor') ||
      getNode(tracingReportDraftData, 'DirectVisitor');

    return node?.tracingReportDrafts.nodes[0] || null;
  }, [tracingReportDraftData]);

  const initialValues: Fields = {
    clinicName: '',
    clinicalDepartment: '',
    doctorName: '',
    prescriptionIssueDate: new Date(),
    dispensingDate: new Date(),
    pharmacistName: '',
    prescriptionDrugs: [''],
    report: '',
    suggestion: '',
  };

  const defaultValuesWithoutDraft: Fields = useMemo(() => {
    if (currentPractitioner) {
      initialValues.pharmacistName = `${currentPractitioner.familyName}${currentPractitioner.givenName}`;
    }

    if (sheetForReport) {
      initialValues.report = sheetForReport;
    }

    if (medicalInstitution) {
      initialValues.clinicName = medicalInstitution.medicalInstitution.name || '';
      initialValues.clinicalDepartment = medicalInstitution.consultation.departmentName || '';
      initialValues.prescriptionIssueDate = new Date(medicalInstitution.prescription.issuedDate);
    }

    return initialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPractitioner, sheetForReport, medicalInstitution]);

  const defaultValues: Fields = useMemo(() => {
    if (isLoadedTracingReportDraft && tracingReportDraft) {
      const data = tracingReportDraft.data;
      const prescriptionDrugs = data.prescriptionDrugs?.map((value) => {
        if (value.drugName) {
          return value.drugName;
        } else {
          return '';
        }
      });
      return {
        clinicName: data.clinicName || '',
        clinicalDepartment: data.clinicalDepartment || '',
        doctorName: data.doctorName || '',
        prescriptionIssueDate: data.prescriptionIssueDate
          ? new Date(data.prescriptionIssueDate)
          : null,
        dispensingDate: data.dispensingDate ? new Date(data.dispensingDate) : null,
        pharmacistName: data.pharmacistName || '',
        prescriptionDrugs: prescriptionDrugs || [],
        report: data.report || '',
        suggestion: data.suggestion || '',
      };
    }
    if (currentPractitioner) {
      initialValues.pharmacistName = `${currentPractitioner.familyName}${currentPractitioner.givenName}`;
    }

    if (sheetForReport) {
      initialValues.report = sheetForReport;
    }

    if (medicalInstitution) {
      initialValues.clinicName = medicalInstitution.medicalInstitution.name || '';
      initialValues.clinicalDepartment = medicalInstitution.consultation.departmentName || '';
      initialValues.prescriptionIssueDate = new Date(medicalInstitution.prescription.issuedDate);
    }

    return initialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPractitioner, sheetForReport, medicalInstitution, isLoadedTracingReportDraft]);

  useEffect(() => {
    if (selectedSheetId) {
      getSheet({
        variables: {
          sheetId: selectedSheetId,
        },
      });
    }
  }, [getSheet, selectedSheetId]);

  useEffect(() => {
    if (patientId) {
      getDispensingRequest({
        variables: {
          patientId,
        },
      });
    }
  }, [getDispensingRequest, patientId]);

  useEffect(() => {
    if (patientId) {
      getTracingReportDraftByCurrentPractitioner({
        variables: {
          id: patientId,
        },
      });
    }
    if (guestPatientId) {
      getTracingReportDraftByCurrentPractitioner({
        variables: {
          id: guestPatientId,
        },
      });
    }
  }, [getTracingReportDraftByCurrentPractitioner, patientId, guestPatientId]);

  return {
    defaultValuesWithoutDraft,
    defaultValues,
    tracingReportDraft,
    loadingTracingReportDraft,
  };
};
