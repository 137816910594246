import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { compose, space, variant } from 'styled-system';

import { Box, Button, Flex, Text } from '~/components/blocks';

type Color = 'grey' | 'grey05' | 'cyan';

type Props = {
  color: Color;
  text: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

const Root = styled('div')<{ color?: Color }>(({ theme }) =>
  css({
    borderRadius: theme.radii.default,
    background: theme.colors.background.bg,
    marginTop: theme.space.m,
    marginBottom: theme.space.m,
  }),
);

const MainFlex = styled(Flex)<{ color: Color }>(
  ({ theme }) =>
    css({
      alignItems: 'center',
      padding: theme.space.m,
      justifyContent: 'space-between',
      '& ul': {
        listStyleType: 'none',
      },
      '& ul, li': {
        listStyle: 'none',
        paddingLeft: theme.space.s,
        'li::before': {
          display: 'inline-block',
          content: '""',
          marginRight: theme.space.s,
          width: '10px',
          height: '10px',
          borderRadius: '50%',
        },
      },
    }),
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'color',
        variants: {
          grey: {
            'li::before': {
              backgroundColor: theme.colors.colorPallete.grey,
            },
          },
          grey05: {
            'li::before': {
              backgroundColor: theme.colors.colorPallete.grey05,
            },
          },
          cyan: {
            'li::before': {
              backgroundColor: theme.colors.colorPallete.cyan,
            },
          },
        },
      }),
    ),
);

export const ReceiveOption = (props: Props) => {
  const { children, color, onClick, text } = props;

  return (
    <Root>
      <MainFlex color={color}>
        <Flex alignItems="center">
          <Box>
            <Text size="xs" color="grey01" textAlign="center">
              受取オプション
            </Text>
          </Box>
          <ul>
            <li>
              <Text size="s" fontWeight="bold" textAlign="center">
                {text}
              </Text>
            </li>
          </ul>
        </Flex>
        {onClick && (
          <Button use="default" size="s" onClick={onClick} marginLeft="auto">
            変更
          </Button>
        )}
      </MainFlex>
      {children}
    </Root>
  );
};
