import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Box, Button, Collapse, Icon, Loader } from '~/components/blocks';
import { PrescriptionCollapseImgViewer } from '~/components/partials';
import { AppointmentStatus } from '~/graphql';
import { useOrganizationElectronicPrescription } from '~/hooks/use-organization-electronic-prescription';
import { openPreviewPage } from '~/utils/prescription';

import { useFetchAppointment } from './use-fetch-appointment';

type Props = {
  printable?: boolean;
  appointmentId: string;
};

const PreviewButton = styled(Button)(({ theme }) =>
  css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radii.circle,
    opacity: 0,
    transform: 'translate(-50%,-50%)',
    transition: `opacity linier ${theme.transitions.fast}`,
  }),
);

const Content = styled(Box)(({ theme }) =>
  css({
    position: 'relative',
    background: theme.colors.background.bg,
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.background.grey,
      opacity: 1,
      [`& > ${PreviewButton}`]: {
        opacity: 1,
      },
    },
  }),
);

export const PrescriptionCollapse = React.memo((props: Props) => {
  const { printable = false } = props;
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const { loading, error, appointment } = useFetchAppointment(isOpen, props.appointmentId);
  const { electronicPrescription } = useOrganizationElectronicPrescription();

  const isPrintable = printable && appointment?.status !== AppointmentStatus.Pending;
  const prescriptions = appointment?.prescriptions || [];

  const handleTitleClick = useCallback(() => setIsOpen((_isOpen) => !_isOpen), []);
  const handleClick = useCallback(
    () => openPreviewPage(props.appointmentId, printable),
    [printable, props.appointmentId],
  );

  useEffect(() => {
    // 表示患者が変更された場合
    setIsOpen(false);
  }, [props.appointmentId]);

  return (
    <Collapse
      open={isOpen}
      onClick={handleTitleClick}
      label={
        <>
          <Icon size="xxl" icon="drug" />
          {electronicPrescription ? '処方箋または処方内容（控え）' : '処方箋'}
        </>
      }
    >
      {error ? (
        <Box padding={theme.space.m}>
          <Alert status="error">取得できませんでした</Alert>
        </Box>
      ) : loading ? (
        <Box backgroundColor={theme.colors.background.bg} height="200px">
          <Loader open inside appearance="white" logoSize="half" />
        </Box>
      ) : (
        <Box>
          <Content onClick={handleClick}>
            {prescriptions.map((prescription) => (
              <PrescriptionCollapseImgViewer
                key={prescription.signedId}
                src={prescription.url}
                alt="処方箋"
              />
            ))}
            <PreviewButton icon use="base">
              {isPrintable ? (
                <Icon color="white" icon="print" size="l" />
              ) : (
                <Icon color="white" icon="blank" size="l" />
              )}
            </PreviewButton>
          </Content>
        </Box>
      )}
    </Collapse>
  );
});

PrescriptionCollapse.displayName = 'PrescriptionCollapse';
