import { useTheme } from '@emotion/react';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Flex, Grid, Icon, Text } from '~/components/blocks';
import { SearchCsClinics } from '~/components/partials/SearchCsClinics';
import { CsClinicItemFragment } from '~/graphql';

import { Fields } from '../InputPane/types';

type Props = {
  editable: boolean;
  formik: FormikProps<Fields>;
  onBack: () => void;
  onSelectClinic: (csClinic: CsClinicItemFragment) => void;
  onConfirm: (values: Fields) => void;
  onAutoSave: (values: Fields) => void;
};

export const RecommendationPane = React.memo((props: Props) => {
  const { editable, formik, onSelectClinic, onConfirm, onAutoSave } = props;
  const theme = useTheme();

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleSelect = useCallback(
    (csClinic: CsClinicItemFragment) => {
      formik.setFieldValue('csClinicId', csClinic.id);
      onSelectClinic(csClinic);
      setIsSelected(true);
    },
    [formik, onSelectClinic],
  );

  useEffect(() => {
    if (!isSelected) return;

    if (!editable) onAutoSave(formik.values);
    onConfirm(formik.values);
  }, [isSelected, editable, formik.values, onAutoSave, onConfirm]);

  return (
    <Grid gridTemplateRows="min-content 1fr">
      <Box marginTop={theme.space.s}>
        <SearchCsClinics onSelect={handleSelect} />
      </Box>
      <Flex>
        <Button size="xs" use="white" onClick={props.onBack}>
          <Icon icon="back-line" size="l" />
          <Text>戻る</Text>
        </Button>
      </Flex>
    </Grid>
  );
});

RecommendationPane.displayName = 'RecommendationPane';
