import 'firebase/compat/auth';
import 'firebase/compat/database';

import firebase from 'firebase/compat/app';

const FIREBASE_CONFIG = {
  apiKey: process.env.firebaseApiKey,
  databaseURL: process.env.firebaseRealtimeDbUrl,
};
const FIREBASE_APPNAME = 'pf';

const app =
  firebase.apps.length === 0
    ? firebase.initializeApp(FIREBASE_CONFIG, FIREBASE_APPNAME)
    : firebase.app(FIREBASE_APPNAME);

export { app, firebase };
