import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { Icon } from '~/components/blocks';
import { PlanFeatureCode } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';

import { MenuItem } from './MenuItem';
import { MenuLink } from './MenuLink';
import { HrefProps } from './types';
import { useOperateIntercom } from './use-operate-intercom';

const MENU_ROUTES: { [key: string]: [HrefProps, ...HrefProps[]] } = {
  reception: [{ path: '/reception' }, { path: '/', exact: true }],
  billing: [{ path: '/billing' }],
  patient: [{ path: '/patient' }],
  fax: [{ path: '/fax' }],
  settings: [{ path: '/settings' }],
};

const Root = styled('nav', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    width: '76px',
    height: '100%',
    backgroundColor: theme.colors.background.default,
    [`@media ${theme.mediaQueries.portrait}`]: {
      width: '100%',
      height: theme.layouts.responsiveMenu.height,
      flexDirection: 'row',
      justifyContent: 'space-around',
      borderTop: theme.borders.default,
    },
  }),
);

const MenuHelp = styled(MenuItem, {
  shouldForwardProp,
})(({ theme }) =>
  css({
    marginTop: 'auto',
    [`@media ${theme.mediaQueries.portrait}`]: {
      width: '100%',
      marginTop: 0,
      padding: theme.space.xs,
    },
  }),
);

const Badge = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.secondary,
    borderRadius: '50%',
    color: theme.colors.text.white,
    fontSize: theme.fontSizes.xs,
    width: theme.space.l,
    height: theme.space.l,
    marginBottom: -10,
    zIndex: 1,
    marginLeft: theme.space.xl,
    lineHeight: theme.space.l,
    textAlign: 'center',
  }),
);

export const RootMenu = () => {
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);
  const { hasEnabledFeature: hasEnabledTracingReport } = usePlanFeature(
    PlanFeatureCode.TracingReport,
  );

  const { unreadCount, openIntercom } = useOperateIntercom();

  return (
    <Root>
      <MenuLink href={MENU_ROUTES.reception}>
        <Icon size="xxl" icon="reception" />
        受付
      </MenuLink>
      <MenuLink href={MENU_ROUTES.billing}>
        <Icon size="xxl" icon="credit" />
        決済
      </MenuLink>
      {hasEnabledFollowup && (
        <MenuLink href={MENU_ROUTES.patient}>
          <Icon size="xxl" icon="patient" />
          患者
        </MenuLink>
      )}
      {hasEnabledTracingReport && (
        <MenuLink href={MENU_ROUTES.fax}>
          <Icon size="xxl" icon="fax" />
          FAX
        </MenuLink>
      )}
      <MenuLink href={MENU_ROUTES.settings}>
        <Icon size="xxl" icon="settings" />
        設定
      </MenuLink>
      <MenuHelp onClick={openIntercom}>
        {unreadCount > 0 && <Badge>{unreadCount}</Badge>}
        <Icon size="xxl" icon="help" />
        ヘルプ
      </MenuHelp>
    </Root>
  );
};
