import IsEmail from 'isemail';
import { object, ObjectSchema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  email: string()
    .label('メールアドレス')
    .required(FormErrorMessage.required)
    .test('email', FormErrorMessage.invalid, (email) => {
      if (!email) return false;
      return IsEmail.validate(email);
    }),
});
