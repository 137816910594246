import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';

import { Alert, Button, Checkbox, Modal } from '~/components/blocks';
import {
  addClientCertIgnorePeriod,
  removeClientCertIgnorePeriod,
} from '~/localstrage/client_cert_ignore_period';

type Props = {
  onClosed: () => void;
  isCompanyAdmin: boolean;
  practitionerId: string;
};

const ignoreDays = 30;

export const AdminPanel = (props: Props) => {
  const theme = useTheme();
  const router = useRouter();
  const { isCompanyAdmin, onClosed, practitionerId } = props;
  const [checked, setChecked] = useState(false);

  const handleClick = useCallback(async () => {
    onClosed();
    const url = isCompanyAdmin
      ? '/company/security/client_certs'
      : '/settings/organization/client_certs';
    await router.push(url);
  }, [isCompanyAdmin, onClosed, router]);

  const handleCheck = useCallback(() => {
    if (!checked) {
      addClientCertIgnorePeriod(practitionerId, ignoreDays);
    } else {
      removeClientCertIgnorePeriod(practitionerId);
    }

    setChecked(!checked);
  }, [checked, practitionerId]);

  return (
    <>
      <Modal.Body>
        <Alert status="warning" mb={theme.space.m}>
          一部のクライアント証明書が60日以内に期限切れとなるため再発行してください。
          <br />
          期限を過ぎると証明書が失効し、該当店舗のアカウントにログインできなくなります
        </Alert>
        <Checkbox
          label={`${ignoreDays}日間この通知を表示しない`}
          checked={checked}
          onChange={handleCheck}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" onClick={handleClick}>
          クライアント証明書を発行する
        </Button>
      </Modal.Footer>
    </>
  );
};
