import React from 'react';

import { Box, Flex, Name, Skeleton } from '~/components/blocks';

import { Detail } from './Detail';

export const DetailSkeleton = () => {
  return (
    <>
      <Flex justifyContent="space-between">
        <Name
          familyName={<Skeleton inline width="60px" />}
          givenName={<Skeleton inline width="60px" />}
          phoneticFamilyName={<Skeleton inline width="60px" />}
          phoneticGivenName={<Skeleton inline width="60px" />}
          size="l"
        />
      </Flex>
      <Box>
        <Detail>
          <Detail.Item>
            <Skeleton width="80px" />
          </Detail.Item>
          <Detail.Item>
            <Skeleton width="30px" />
          </Detail.Item>
          <Detail.Item>
            <Skeleton width="60px" height="28px" />
          </Detail.Item>
        </Detail>
      </Box>
    </>
  );
};
