import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Box, Button, EntryList, Flex, Icon, Text } from '~/components/blocks';
import { ClipButton } from '~/components/partials';

import { PrintPage } from './PrintPage';
import { Organization, Practitoner } from './types';

type Props = {
  disabled: boolean;
  privilege?: boolean;
  organization: Organization;
  practitioner: Practitoner;
  practitionerCode: string;
};

const Info = styled(EntryList)(({ theme }) =>
  css({
    '& + &': {
      marginTop: `calc(${theme.space.m} + ${theme.space.s})`,
    },
  }),
);

const buildText = (
  target: string,
  organization: Organization,
  practitioner: Practitoner,
  practitionerCode: string,
) => {
  return `【店舗名】
${organization.name}
【${target}名】
${practitioner.familyName}${practitioner.givenName}
【店舗コード】
${organization.slug}
【ユーザーID】
${practitioner.username}
【仮パスワード】
${practitionerCode}`;
};

export const PractitionerAuthenticationInformation = React.memo((props: Props) => {
  const { disabled, privilege = false, organization, practitioner, practitionerCode } = props;

  const [isPrintEnabled, setIsPrintEnabled] = useState(false);

  const target = privilege ? '店舗管理者' : 'スタッフ';
  const clipboardText = buildText(target, organization, practitioner, practitionerCode);

  const handlePrint = useCallback(() => {
    setIsPrintEnabled(true);
    // 印刷用のデータが有効になるのを待つ
    setTimeout(() => {
      window.print();
    }, 100);
  }, []);

  useEffect(() => {
    return () => {
      setIsPrintEnabled(false);
    };
  }, []);

  return (
    <>
      <Flex marginBottom="l">
        <ClipButton iconSize="l" disabled={disabled} marginRight="m" text={clipboardText} />
        <Button wide="fill" disabled={disabled} onClick={handlePrint}>
          <Icon icon="print" size="l" />
          印刷
        </Button>
      </Flex>
      <Box>
        <EntryList>
          <EntryList.Head>ログイン情報</EntryList.Head>
          <EntryList.Body>
            <Alert status="info" withIcon={false}>
              <Info>
                <EntryList.Head>店舗名</EntryList.Head>
                <EntryList.Body>{organization.name}</EntryList.Body>
              </Info>
              <Info>
                <EntryList.Head>{target}名</EntryList.Head>
                <EntryList.Body>{`${practitioner.familyName}${practitioner.givenName}`}</EntryList.Body>
              </Info>
              <Info>
                <EntryList.Head>店舗コード</EntryList.Head>
                <EntryList.Body>{organization.slug}</EntryList.Body>
              </Info>
              <Info>
                <EntryList.Head>ユーザーID</EntryList.Head>
                <EntryList.Body>{practitioner.username}</EntryList.Body>
              </Info>
              <Info>
                <EntryList.Head>仮パスワード</EntryList.Head>
                <EntryList.Body>
                  <Text fontWeight="bold">{practitionerCode}</Text>
                  <Text color="pink" fontWeight="bold" size="xs" marginTop="m">
                    仮パスワードは再表示できませんので、ログイン情報をコピーまたは印刷をして、
                    {target}に共有してください。
                    <br />
                    送付先メールアドレス宛にログイン情報を送付すると便利です。
                  </Text>
                </EntryList.Body>
              </Info>
            </Alert>
          </EntryList.Body>
        </EntryList>
      </Box>
      <PrintPage
        privilege={privilege}
        enabled={isPrintEnabled}
        organization={organization}
        practitioner={practitioner}
        practitionerCode={practitionerCode}
      />
    </>
  );
});

PractitionerAuthenticationInformation.displayName = 'PractitionerAuthenticationInformation';
