import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useMemo } from 'react';

import { Flex, Icon, Logo } from '~/components/blocks';
import { OrganizationNameFragment, useGetOrganizationNameQuery } from '~/graphql';

import { EmergencyAlert } from './EmergencyAlert';
import { ProxyLogin } from './ProxyLogin';

type Props = {
  action?: React.ReactNode;
};

const StyledHeader = styled('header')(() => css({}));

const Wrapper = styled('header')(({ theme }) =>
  css({
    width: '100%',
    height: theme.layouts.appHeader.height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.space.m} ${theme.space.l}`,
    backgroundColor: theme.colors.background.default,
    borderBottom: `1px solid ${theme.colors.border.default}`,
    boxShadow: `0 2px 8px ${theme.colors.background.shadowgrey}`,
  }),
);

const OrganizationName = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    fontSize: theme.fontSizes.m,
    fontWeight: theme.fontWeights.bold,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.s,
    },
  }),
);

const Action = styled('div')();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isOrganizationNameFragment = (node: any): node is OrganizationNameFragment => {
  return node?.__typename === 'Practitioner';
};

export const AppHeader = (props: Props) => {
  const { data } = useGetOrganizationNameQuery();
  const organizationName = useMemo(() => {
    const me = data?.me;
    if (!isOrganizationNameFragment(me)) return '';

    return `${me.organization.company.name}｜${me.organization.name}`;
  }, [data?.me]);

  return (
    <StyledHeader>
      <Wrapper>
        <OrganizationName>
          <Logo>
            <Icon size="xxxl" icon="logo" />
          </Logo>
          {organizationName}
          <ProxyLogin />
        </OrganizationName>
        {props.action && <Action>{props.action}</Action>}
      </Wrapper>
      <EmergencyAlert />
    </StyledHeader>
  );
};
