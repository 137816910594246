import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { Icon, Text } from '~/components/blocks';
import { DirectVisitor, ErrorMedicationFollowupMessageFragment } from '~/graphql';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';

import { ActionSnackbar } from '../ActionSnackbar';

type Props = {
  followupMessage: ErrorMedicationFollowupMessageFragment;
  onClose: (followupMessageId: string) => void;
};

export const FollowupMessageNotification = React.memo((props: Props) => {
  const { followupMessage, onClose } = props;
  const theme = useTheme();
  const router = useRouter();
  const messageDeliverGuestPatient =
    followupMessage.messageDeliverResult?.guestPatient?.__typename === 'DirectVisitor'
      ? (followupMessage.messageDeliverResult.guestPatient as DirectVisitor)
      : null;

  const patientReceiver =
    followupMessage.appointment?.patient ||
    followupMessage.visit?.directVisitor ||
    followupMessage.appointment?.webVisitor ||
    messageDeliverGuestPatient;

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openFollowupMessageEditDrawer = useRecoilCallback(
    ({ set }) =>
      () => {
        if (router.pathname !== '/patient') {
          router.push('/patient');
          // unmountが完了する前にページ遷移が完了すると通知が再表示されてしまう
          // 通知を管理してる大元のリストから削除し、通知が再表示されないようにする
          onClose(followupMessage.id);
        } else {
          handleClose();
        }
        if (patientReceiver) {
          set(patientDetailDialogState, (_state) => ({
            ..._state,
            isOpen: true,
            patientId: patientReceiver.id,
            type: 'message' as TabType,
          }));
          onClose(followupMessage.id);
        }
      },
    [followupMessage.id, handleClose, onClose, patientReceiver, router],
  );

  const handleClick = useCallback(() => {
    openFollowupMessageEditDrawer();
  }, [openFollowupMessageEditDrawer]);

  const handleExited = useCallback(() => {
    onClose(followupMessage.id);
  }, [followupMessage, onClose]);

  //スナックバーが画面横から出てくるアニメーションを適応させるためuseEffectで通知を開く
  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <ActionSnackbar
      open={isOpen}
      onExited={handleExited}
      isNotification={false}
      onClick={handleClick}
      onClose={handleClose}
    >
      <Icon color="white" icon="send" size="xxl" />
      <Text color="white" size="xs" fontWeight="bold" marginLeft={theme.space.m}>
        メッセージの送信に失敗しました。
        <br />
        通信環境をお確かめの上もう一度お試し
        <br />
        ください。
      </Text>
    </ActionSnackbar>
  );
});

FollowupMessageNotification.displayName = 'FollowupMessageNotification';
