import { useEffect } from 'react';

import { useGetPatientProfileLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchPatientProfile = (patientId: string | null) => {
  const [getPatient, { data, loading }] = useGetPatientProfileLazyQuery();

  const patient = getNode(data, 'Patient');

  useEffect(() => {
    if (patientId) {
      getPatient({ variables: { patientId } });
    }
  }, [patientId, getPatient]);

  return { loading, patient };
};
