import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { ja, Locale } from 'date-fns/locale';
import React, { useCallback, useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { Flex, Icon } from '..';

const customLocale: Locale = { ...ja, options: { weekStartsOn: 0, firstWeekContainsDate: 1 } };
registerLocale('ja', customLocale);

const Root = styled(Flex)(() =>
  css({
    alignItems: 'center',
    whiteSpace: 'nowrap',
  }),
);

const CalendarIcon = styled('div')(() =>
  css({
    cursor: 'pointer',
    height: '100%',
    lineHeight: '100%',
  }),
);

type Props = {
  disabled?: boolean;
  calendarIcon?: boolean;
  clearable?: boolean;
  customInput?: React.ReactNode;
  value: Date | null;
  maxDate?: Date | null;
  minDate?: Date | null;
  onChange: (date: Date | null) => void;
};

export const InputMonth = React.memo((props: Props) => {
  const {
    calendarIcon,
    clearable = true,
    customInput,
    disabled,
    value,
    onChange,
    maxDate,
    minDate,
  } = props;

  const pickerRef = useRef<DatePicker | null>(null);
  const handleClick = useCallback(() => pickerRef.current?.setOpen(true), []);

  return (
    <Root>
      {calendarIcon && (
        <CalendarIcon onClick={handleClick}>
          <Icon size="l" icon="calender" />
        </CalendarIcon>
      )}
      <DatePicker
        ref={pickerRef}
        dateFormat="yyyy年MM月"
        dateFormatCalendar="yyyy年MM月"
        disabled={disabled}
        selected={value}
        onChange={onChange}
        customInput={customInput}
        isClearable={clearable}
        locale="ja"
        todayButton="今月"
        placeholderText="指定なし"
        previousYearButtonLabel=""
        nextYearButtonLabel=""
        maxDate={maxDate}
        minDate={minDate}
        showMonthYearPicker
      />
    </Root>
  );
});

InputMonth.displayName = 'InputMonth';
