import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  GetPatientProfileMedixsPatientDocument,
  GetReceptionPaneAppointmentDocument,
  useIdentifyMedixsPatientMutation,
} from '~/graphql';
import { medixsReceptionIdentificationModalState } from '~/state/partials/medixs_reception_identification_modal/atoms';

export const useIdentifyMedixsPatient = () => {
  const [{ patientId, appointmentId, selectedMedixsReception }, setState] = useRecoilState(
    medixsReceptionIdentificationModalState,
  );
  const resetState = useResetRecoilState(medixsReceptionIdentificationModalState);

  const [identifyMedixsPatient, { loading }] = useIdentifyMedixsPatientMutation({
    refetchQueries: [
      {
        query: GetReceptionPaneAppointmentDocument,
        variables: { appointmentId },
      },
      { query: GetPatientProfileMedixsPatientDocument, variables: { patientId } },
    ],
    onCompleted: () => {
      resetState();
    },
    onError: (e) => {
      setState((state) => ({
        ...state,
        error: e.message,
      }));
    },
  });
  const identify = useCallback(() => {
    if (patientId && appointmentId && selectedMedixsReception) {
      identifyMedixsPatient({
        variables: {
          input: {
            patientId,
            appointmentId,
            medixsPatientId: selectedMedixsReception.medixsPatient.id,
            medixsReceptionId: selectedMedixsReception.id,
          },
        },
      });
    }
  }, [patientId, appointmentId, selectedMedixsReception, identifyMedixsPatient]);

  return { identifying: loading, identify };
};
