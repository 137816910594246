import { css } from '@emotion/react';

export const styles = css({
  '@page': {
    size: 'A4',
    /* 印刷ヘッダーと印刷フッターを非表示するcss hack */
    /* https://qiita.com/cognitom/items/d39d5f19054c8c8fd592#%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B6%E3%81%8C%E5%87%BA%E5%8A%9B%E3%81%99%E3%82%8B%E4%BD%99%E8%A8%88%E3%81%AA%E3%83%98%E3%83%83%E3%83%80%E3%83%95%E3%83%83%E3%82%BF */
    margin: 0,
    padding: '2mm',
  },
  '@media print': {
    body: {
      width: '210mm', // Chromeのバグで文字サイズが巨大化してしまう対策
      padding: 0,
    },
    '#__next': {
      height: '100%',
      overflow: 'visible',
    },
  },
});
