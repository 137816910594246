import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, variant } from 'styled-system';

type Props = {
  orientation?: 'vertical' | 'holizonal';
};

const Day = styled('dt', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    fontSize: theme.fontSizes.l,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.s,
    },
  }),
);

const Time = styled('dd', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    fontSize: theme.fontSizes.xs,
  }),
);

const Root = styled('dl', {
  shouldForwardProp,
})<Props>(
  ({ theme }) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      fontWeight: theme.fontWeights.bold,
      paddingLeft: theme.space.m,
      [`@media ${theme.mediaQueries.tablet}`]: {
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        paddingLeft: 0,
      },
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'margin',
        variants: {
          none: css({
            marginBottom: 0,
          }),
        },
      }),
      variant({
        prop: 'orientation',
        variants: {
          holizonal: css({
            flexDirection: 'row',
            alignItems: 'center',
            [`& > ${Time} + ${Day}, & > ${Day} + ${Time}`]: {
              marginLeft: theme.space.m,
            },
            [`@media ${theme.mediaQueries.tablet}`]: {
              [`& > ${Time} + ${Day}, & > ${Day} + ${Time}`]: {
                marginLeft: 0,
              },
            },
          }),
          vertical: css({
            flexDirection: 'column',
            alignItems: 'flex-start',
            [`& > ${Time}`]: {
              fontSize: theme.fontSizes.m,
              lineHeight: theme.lineHeights.s,
            },
            [`& > ${Day}`]: {
              fontSize: theme.fontSizes.s,
            },
          }),
        },
      }),
    ),
);

Root.defaultProps = {
  orientation: 'holizonal',
};

export const DateLabel = Object.assign(Root, { Day, Time });
