import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Button, Flex, Modal, Text } from '~/components/blocks';
import { twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';
import { publicPath } from '~/utils/path';

import { AppDownload } from './AppDownload';

const Merits = styled('dl')(({ theme }) =>
  css({
    marginLeft: theme.space.l,
    maxWidth: '440px',
  }),
);

const TwoFactorAuthMerit = styled('dt')(({ theme }) =>
  css({
    color: theme.colors.colorPallete.grey01,
    fontWeight: theme.fontWeights.bold,
  }),
);

const Merit = styled('dd')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    fontWeight: theme.fontWeights.bold,
    marginTop: theme.space.m,
  }),
);

const MeritDetail = styled('dd')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    marginTop: theme.space.s,
  }),
);

export const IntroductionPanel = () => {
  const theme = useTheme();

  const { isReset } = useRecoilValue(twoFactorAuthModalState);

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () =>
        set(twoFactorAuthModalState, (_state) => ({ ..._state, currentPanel: 'scan' })),
    [],
  );

  return (
    <>
      <Modal.Body>
        {isReset && (
          <Alert status="info" marginBottom={theme.space.l}>
            パスワード再設定で二要素認証が無効化されました。
            <br />
            ログインを保護にするには、二要素認証を再設定してください。
          </Alert>
        )}
        <Text size="m">Pharmsへのログインを二要素認証で保護できます</Text>
        <AppDownload />
        <Flex alignItems="center" marginTop={theme.space.xxl} paddingX={theme.space.l}>
          <img src={publicPath('/img/img-authenticator.svg')} alt="二要素認証" />
          <Merits>
            <TwoFactorAuthMerit>二要素認証のメリット</TwoFactorAuthMerit>
            <Merit>セキュリティをさらに強化</Merit>
            <MeritDetail>
              ログイン時にパスワードと、「<strong>Google Authenticator</strong>
              」に表示された確認コードの入力が必要になります。
            </MeritDetail>
            <Merit>不正使用を防止する</Merit>
            <MeritDetail>
              第三者はパスワードを入手しただけでは、あなたのアカウントにログインすることはできません。
            </MeritDetail>
          </Merits>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" onClick={handleClick}>
          設定開始
        </Button>
      </Modal.Footer>
    </>
  );
};
