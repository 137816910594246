import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Item = styled('li')();

const Root = styled('ul')<{ split?: boolean }>(({ split = true }) =>
  css({
    display: 'flex',
    ...(split && {
      [` > ${Item} + ${Item}`]: {
        '&::before': {
          content: '"｜"',
        },
      },
    }),
  }),
);

export const List = Object.assign(Root, { Item });
