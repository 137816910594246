import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Flex, Text } from '~/components/blocks';
import { QuestionMarkIcon } from '~/components/partials';
import { MedicationFollowupQuestionnairesSelectFragment } from '~/graphql';

type Props = {
  questionnaire: MedicationFollowupQuestionnairesSelectFragment | null;
};

const Root = styled('div')(({ theme }) =>
  css({
    borderRadius: theme.radii.default,
    padding: theme.space.s,
  }),
);

const Questions = styled('div')(({ theme }) =>
  css({
    width: '300px',
    background: theme.colors.background.default,
    borderRadius: theme.radii.default,
    marginTop: theme.space.m,
    padding: theme.space.m,
  }),
);

export const AutoReplyFollowupQuestionnairePreview = React.memo((props: Props) => {
  const theme = useTheme();
  const { questionnaire } = props;

  return (
    <Root>
      <Text size="xs" fontWeight="bold" color="white">
        下記内容を記載した質問票を添付し、
        <br />
        患者から連絡を受けることができます。
      </Text>
      <Questions>
        {questionnaire?.revision.formData.sections[0].questions
          .filter((question) => question.conditions?.length === 0)
          .map((question, idx) => {
            return (
              <Flex alignItems="center" paddingY={theme.space.s} key={idx}>
                <QuestionMarkIcon withBorder />
                <Text fontWeight="bold" size="xs" lineHeight="1.5">
                  {question.title}
                </Text>
              </Flex>
            );
          })}
      </Questions>
    </Root>
  );
});

AutoReplyFollowupQuestionnairePreview.displayName = 'AutoReplyFollowupQuestionnairePreview';
