import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';

import { Alert, Button, Icon, Modal, Text } from '~/components/blocks';
import {
  DirectVisitorDetailDialogProfileFragment,
  PatientDetailDialogProfileFragment,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';
import { patientDetailDialogPatientCommentState } from '~/state/partials/patient_detail_dialog/atoms';

import { useDeletePatientComment } from './use-delete-patient-comment';

type Props = {
  patient?:
    | DirectVisitorDetailDialogProfileFragment
    | WebVisitorDetailDialogProfileFragment
    | PatientDetailDialogProfileFragment;
};
export const DeleteFollowupCommentModal = (props: Props) => {
  const theme = useTheme();
  const { patient } = props;
  const [patientCommentState, setPatientCommentState] = useRecoilState(
    patientDetailDialogPatientCommentState,
  );
  const { deleteComment, error: deleteError } = useDeletePatientComment(patient?.__typename);

  const handleDeleteComment = useCallback(async () => {
    try {
      await deleteComment();
      setPatientCommentState((_state) => ({
        ..._state,
        selectedCommentId: null,
        isDeleteModalOpen: false,
      }));
    } catch {
      // 何もしない
    }
  }, [deleteComment, setPatientCommentState]);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(patientDetailDialogPatientCommentState),
    [],
  );

  if (patient === undefined) return null;

  return (
    <Modal size="s" open={patientCommentState.isDeleteModalOpen} onClose={handleClose}>
      <Modal.Body>
        {deleteError && (
          <Alert status="error" whiteSpace="pre-wrap">
            {deleteError}
          </Alert>
        )}
        <Text size="l">
          {patient.familyName}
          {patient.givenName}様のフォローアップメモを削除
          <br />
          しますか？
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" onClick={handleDeleteComment}>
          <Icon icon="trash" size="s" color="white" />
          <Text marginLeft={theme.space.s} color="white">
            削除
          </Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
