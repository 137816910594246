import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { ERROR_BASE_DATE } from '~/constants/organization_notification';
import {
  useGetUnreadAgentNotificationsLazyQuery,
  useMarkOrganizationNotificationMutation,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { agentNotificationsState } from '~/state/layouts/SharedAppShell/atoms';

export const useSubscriptionAgentNotifications = () => {
  const [{ notifications, loaded }, setState] = useRecoilState(agentNotificationsState);
  const [notificationCreatedEvent, clear] = useSubscriptions('OrganizationNotification', [
    'created',
  ]);
  const [markNotification] = useMarkOrganizationNotificationMutation();
  const [getNotifications] = useGetUnreadAgentNotificationsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const notifications = data
        ? getMe(data)?.organization.unreadOrganizationNotifications.nodes || []
        : [];

      setState((_state) => ({
        ..._state,
        notifications,
        loaded: true,
      }));
    },
  });

  const handleClose = useCallback(
    async (notificationId: string) => {
      const newNotifications = notifications.filter(
        (notification) => notification.id !== notificationId,
      );
      setState((_state) => ({ ..._state, notifications: newNotifications }));

      const notification = notifications.find((n) => n.id === notificationId);

      if (notification) {
        await markNotification({
          variables: {
            input: {
              organizationNotificationId: notification.id,
            },
          },
        });
      }
    },
    [markNotification, notifications, setState],
  );

  useEffect(() => {
    if (notificationCreatedEvent) {
      getNotifications({ variables: { from: ERROR_BASE_DATE } });
      clear();
    }
  }, [clear, notificationCreatedEvent, getNotifications]);

  useEffect(() => {
    // ログイン時に通知を取得する
    if (!loaded) {
      getNotifications({ variables: { from: ERROR_BASE_DATE } });
    }
  }, [getNotifications, loaded]);

  return {
    handleClose,
  };
};
