import React from 'react';

import { Box, Flex, Name, Skeleton } from '~/components/blocks';

import { ProfileDetail } from './ProfileDetail';

export const PatientProfileSkeleton = () => {
  return (
    <>
      <Flex justifyContent="space-between">
        <Name
          familyName={<Skeleton inline width="60px" />}
          givenName={<Skeleton inline width="60px" />}
          phoneticFamilyName={<Skeleton inline width="60px" />}
          phoneticGivenName={<Skeleton inline width="60px" />}
          size="l"
        />
      </Flex>
      <Box>
        <ProfileDetail>
          <ProfileDetail.Item>
            <Skeleton width="80px" />
          </ProfileDetail.Item>
          <ProfileDetail.Item>
            <Skeleton width="30px" />
          </ProfileDetail.Item>
          <ProfileDetail.Item></ProfileDetail.Item>
        </ProfileDetail>
      </Box>
    </>
  );
};
