import { useMemo } from 'react';

import { Fields } from '~/components/partials/GuestPatientForm/types';
import { DirectVisitorDetailDialogProfileFragment } from '~/graphql';

export const useDefaultValues = (guestPatient: DirectVisitorDetailDialogProfileFragment) => {
  const defaultValues: Fields = useMemo(() => {
    return {
      familyName: guestPatient.familyName,
      givenName: guestPatient.givenName,
      phoneticFamilyName: guestPatient.phoneticFamilyName,
      phoneticGivenName: guestPatient.phoneticGivenName,
      birthDate: guestPatient.birthDate,
      sex: guestPatient.sex,
      mobilePhone: guestPatient.mobilePhone,
    };
  }, [guestPatient]);

  return defaultValues;
};
