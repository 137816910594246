import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, variant } from 'styled-system';

type HeadProps = {
  disabled?: boolean;
};

type ArrowProps = {
  status?: 'opened' | 'closed';
};

export const Tooltip = styled('div')(({ theme }) =>
  css({
    display: 'none',
    position: 'absolute',
    top: '-8px',
    right: '0',
    background: theme.colors.background.black,
    color: theme.colors.text.white,
    lineHeight: theme.lineHeights.m,
    fontSize: theme.fontSizes.xs,
    padding: `${theme.space.m} ${theme.space.l}`,
    borderRadius: theme.radii.default,
    '&:before': {
      content: "''",
      position: 'absolute',
      top: '100%',
      left: '50%',
      border: `${theme.space.s} solid transparent`,
      borderTop: `${theme.space.s} solid ${theme.colors.background.black}`,
    },
  }),
);

export const Head = styled('dt', { shouldForwardProp })<HeadProps>(
  ({ theme }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      borderBottom: theme.borders.default,
      fontWeight: theme.fontWeights.bold,
      lineHeight: theme.lineHeights.s,
      padding: `${theme.space.m} ${theme.space.s}`,
      outline: 0,
      cursor: 'pointer',
      transitionDuration: theme.transitions.fast,
      ['& svg:first-of-type']: {
        marginRight: theme.space.xs,
      },
      ['&:hover']: {
        backgroundColor: theme.colors.background.bg,
      },
      [`@media ${theme.mediaQueries.tablet}`]: {
        padding: theme.space.s,
      },
    }),
  ({ disabled, theme }) =>
    disabled &&
    css({
      position: 'relative',
      color: theme.colors.colorPallete.grey03,
      cursor: 'not-allowed',
      ['&:hover']: {
        background: theme.colors.background.default,
      },
      [`&:hover > ${Tooltip}`]: {
        display: `block`,
      },
    }),
);

export const HeadContent = styled('div')(({ theme }) =>
  css({
    padding: `${theme.space.s} 0`,
    display: 'flex',
    alignItems: 'center',
  }),
);

export const Body = styled('dd')();

export const Arrow = styled('i', {
  shouldForwardProp,
})<ArrowProps>(
  ({ theme }) =>
    css({
      display: 'block',
      width: '8px',
      height: '8px',
      marginLeft: 'auto',
      border: `6px solid transparent`,
      borderLeft: `4px solid ${theme.colors.background.black}`,
      transition: `transform ${theme.transitions.default} ease-out`,
    }),
  compose(
    variant({
      prop: 'status',
      variants: {
        closed: css({
          transform: 'rotate(0deg)',
        }),
        opened: css({
          transform: 'rotate(90deg) translate(6px, 4px)',
        }),
      },
    }),
  ),
);

export const Wrapper = styled('dl')(({ theme }) =>
  css({
    background: theme.colors.background.default,
  }),
);
