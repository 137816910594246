import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { GetMessagePatientProfileQuery, useGetMessagePatientProfileLazyQuery } from '~/graphql';
import { Profile } from '~/state/partials/message_modal/types';
import {
  patientDetailDialogMessageState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';

const toPatientProfile = (data?: GetMessagePatientProfileQuery): Profile | null => {
  if (!data?.node) return null;
  if (data.node.__typename === 'DirectVisitor') {
    const visitor = data.node;
    const visit = visitor.visits.nodes[0];

    return {
      type: visitor.__typename,
      receptionId: visit.id,
      familyName: visitor.familyName,
      givenName: visitor.givenName,
      phoneticFamilyName: visitor.phoneticFamilyName,
      phoneticGivenName: visitor.phoneticGivenName,
      visitDay: visit.createdAt,
    };
  }

  if (data.node.__typename === 'WebVisitor') {
    const visitor = data.node;
    const appointment = visitor.appointment;

    return {
      type: visitor.__typename,
      receptionId: appointment.id,
      familyName: visitor.familyName,
      givenName: visitor.givenName,
      phoneticFamilyName: visitor.phoneticFamilyName,
      phoneticGivenName: visitor.phoneticGivenName,
      visitDay: appointment.start || undefined,
    };
  }

  if (data.node.__typename === 'Patient') {
    const patient = data.node;
    const appointment = patient.appointments.nodes[0];

    return {
      type: patient.__typename,
      receptionId: appointment.id,
      familyName: patient.familyName,
      givenName: patient.givenName,
      phoneticFamilyName: patient.phoneticFamilyName,
      phoneticGivenName: patient.phoneticGivenName,
      visitDay: appointment.start || undefined,
    };
  }

  return null;
};

export const useFetchPatientProfile = () => {
  const { patientId } = useRecoilValue(patientDetailDialogState);
  const setState = useSetRecoilState(patientDetailDialogMessageState);
  const [getPatient, { called, loading, data }] = useGetMessagePatientProfileLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const profile = useMemo(() => toPatientProfile(data), [data]);

  useEffect(() => {
    if (patientId) {
      getPatient({
        variables: {
          id: patientId,
        },
      });
    }
  }, [getPatient, patientId]);

  useEffect(() => {
    if (profile) {
      setState((_state) => ({ ..._state, profile }));
    }
  }, [profile, setState]);

  return {
    loading: !called || loading,
    profile,
  };
};
