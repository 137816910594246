import { atom } from 'recoil';

import { ChangeDeliveryMethodModal } from './types';

export const changeDeliveryMethodState = atom<ChangeDeliveryMethodModal>({
  key: 'partials.changeDeliveryMethod.modal',
  default: {
    isOpen: false,
    appointmentId: null,
  },
});
