import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Flex, Text } from '~/components/blocks';

type Props = {
  label: string;
  count: number;
};

const Root = styled(Flex)(() =>
  css({
    alignItems: 'center',
    justifyContent: 'center',
    width: '103px',
  }),
);

const Badge = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.secondary,
    borderRadius: '50%',
    color: theme.colors.text.white,
    fontSize: theme.fontSizes.xs,
    width: theme.space.l,
    height: theme.space.l,
    lineHeight: theme.space.l,
    textAlign: 'center',
  }),
);

export const TabLabel = (props: Props) => {
  const theme = useTheme();
  return (
    <Root>
      <Text mr={theme.space.s}>{props.label}</Text>
      {props.count > 0 && <Badge>{props.count}</Badge>}
    </Root>
  );
};
