import { atom } from 'recoil';

import { AnnouncementModal } from './types';

export const announcementModalState = atom<AnnouncementModal>({
  key: 'partials.announcementModal',
  default: {
    isOpen: false,
    announcementId: null,
  },
});
