import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Box, Button, EntryList, Modal, Text } from '~/components/blocks';

type Props = {
  privilege?: boolean;
  resetting: boolean;
  error: string | null;
  organizationName?: string;
  username: string;
  familyName: string;
  givenName: string;
  onReset: () => void;
};

export const PractitionerResetPasswordPanel = React.memo((props: Props) => {
  const theme = useTheme();
  const { error, privilege = false, organizationName } = props;

  const target = privilege ? '店舗管理者' : 'スタッフ';

  return (
    <>
      <Modal.Header>パスワード再設定</Modal.Header>
      <Modal.Body>
        {error && (
          <Alert status="error" marginBottom={theme.space.l}>
            {error}
          </Alert>
        )}
        <Alert status="warning">
          <Text size="s">
            これまでのパスワードではログインできなくなります。
            <br />
            {target}のパスワードが紛失した際にご利用ください。
          </Text>
        </Alert>
        <Box mt={theme.space.l} p={theme.space.l} backgroundColor={theme.colors.background.bg}>
          {organizationName && (
            <EntryList>
              <EntryList.Head>店舗名</EntryList.Head>
              <EntryList.Body>{organizationName}</EntryList.Body>
            </EntryList>
          )}
          <EntryList>
            <EntryList.Head>ユーザーID</EntryList.Head>
            <EntryList.Body>{props.username}</EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>{target}名</EntryList.Head>
            <EntryList.Body>{`${props.familyName}${props.givenName}`}</EntryList.Body>
          </EntryList>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" loading={props.resetting} onClick={props.onReset}>
          再設定
        </Button>
      </Modal.Footer>
    </>
  );
});

PractitionerResetPasswordPanel.displayName = 'PractitionerResetPasswordPanel';
