import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetPatientDetailDialogLazyQuery } from '~/graphql';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';

export const useFetchPatient = () => {
  const { patientId } = useRecoilValue(patientDetailDialogState);

  const [getProfile, { data, loading }] = useGetPatientDetailDialogLazyQuery();

  const patient = useMemo(() => {
    const _patient = data?.node?.__typename === 'Patient' ? data.node : undefined;
    const _webVisitor = data?.node?.__typename === 'WebVisitor' ? data.node : undefined;
    const _directVisitor = data?.node?.__typename === 'DirectVisitor' ? data.node : undefined;

    return _patient || _webVisitor || _directVisitor;
  }, [data?.node]);

  useEffect(() => {
    if (patientId) {
      getProfile({
        variables: {
          id: patientId,
        },
      });
    }
  }, [getProfile, patientId]);

  return { patient, loading };
};
