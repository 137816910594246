import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

type Props = {
  src: string;
  alt: string;
};

const Wrapper = styled('div')(({ theme }) =>
  css({
    width: '100%',
    marginY: theme.space.m,
    background: theme.colors.background.bg,
    padding: theme.space.m,
    transitionDuration: theme.transitions.default,
    '& + &': {
      marginTop: theme.space.m,
    },
    '&:hover': {
      background: theme.colors.background.grey,
    },
  }),
);

const Image = styled('img')(() =>
  css({
    display: 'block',
    maxWidth: '100%',
    maxHeight: '200px',
    margin: '0 auto',
  }),
);

export const ImgViewer = (props: Props) => {
  return (
    <Wrapper>
      <Image alt={props.alt} src={props.src} />
    </Wrapper>
  );
};
