import { atom } from 'recoil';

import { MessagePageInfo } from './types';

export const messagePageInfoState = atom<MessagePageInfo>({
  key: 'message.pageInfo',
  default: {
    page: 1,
    totalPage: 1,
    totalCount: 0,
    perPage: 25,
    answered: null,
    attachment: null,
    date: new Date(),
    patientName: null,
    practitionerId: null,
  },
});
