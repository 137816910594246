import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { PopperChildrenProps } from 'react-popper';
import { compose, margin, MarginProps } from 'styled-system';

import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

type Props = MarginProps & {
  label?: string;
  placement?: PopperChildrenProps['placement'];
  className?: string;
  children: React.ReactNode;
};

const ShortHint = styled('p')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    lineHeight: theme.fontSizes.s,
    marginLeft: theme.space.xs,
    [`@media ${theme.mediaQueries.tablet}`]: {
      display: 'none',
      marginLeft: 0,
    },
  }),
);

export const Hint = styled((props: Props) => {
  return (
    <Tooltip placement={props.placement || 'auto'} content={props.children}>
      <div className={props.className}>
        <Icon size="l" color="black" icon="hint" />
        {props.label && <ShortHint>{props.label}</ShortHint>}
      </div>
    </Tooltip>
  );
})(
  ({ theme }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      height: theme.fontSizes.l,
    }),
  () => compose(margin),
);
