import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  GetPatientFollowupCommentsDocument,
  useDeleteGuestPatientFollowupCommentMutation,
  useDeletePatientFollowupCommentMutation,
} from '~/graphql';
import {
  eventPageInfoState,
  patientDetailDialogPatientCommentState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';

export const useDeletePatientComment = (
  patientType: 'Patient' | 'DirectVisitor' | 'WebVisitor' | undefined,
) => {
  const [error, setError] = useState<string | null>(null);
  const patientDetailCommentState = useRecoilValue(patientDetailDialogPatientCommentState);
  const patientDetailState = useRecoilValue(patientDetailDialogState);

  const setEventInfoState = useSetRecoilState(eventPageInfoState);
  const [deletePatientComment, { loading, called }] = useDeletePatientFollowupCommentMutation({
    refetchQueries: [
      {
        query: GetPatientFollowupCommentsDocument,
        variables: {
          id: patientDetailState.patientId,
        },
      },
    ],
    onCompleted: () => {
      setEventInfoState((_state) => ({ ..._state, isResetEvent: true, page: 1, perPage: 20 }));
    },
  });
  const [deleteGuestPatientComment, { loading: guestLoading, called: guestCalled }] =
    useDeleteGuestPatientFollowupCommentMutation({
      refetchQueries: [
        {
          query: GetPatientFollowupCommentsDocument,
          variables: {
            id: patientDetailState.patientId,
          },
        },
      ],
      onCompleted: () => {
        setEventInfoState((_state) => ({ ..._state, isResetEvent: true, page: 1, perPage: 20 }));
      },
    });

  const deleteComment = useCallback(async () => {
    if (patientDetailCommentState.selectedCommentId === null) return;
    if (patientType === undefined) return;

    try {
      if (patientType === 'Patient') {
        await deletePatientComment({
          variables: {
            input: {
              patientMedicationFollowupCommentId: patientDetailCommentState.selectedCommentId,
            },
          },
        });
      } else {
        await deleteGuestPatientComment({
          variables: {
            input: {
              guestPatientMedicationFollowupCommentId: patientDetailCommentState.selectedCommentId,
            },
          },
        });
      }
    } catch (_error) {
      setError(_error?.message || 'エラーが発生しました');
      throw new Error();
    }
  }, [
    deleteGuestPatientComment,
    deletePatientComment,
    patientDetailCommentState.selectedCommentId,
    patientType,
  ]);

  return {
    deleteComment,
    error,
    isDeleting: (loading && called) || (guestLoading && guestCalled),
  };
};
