import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { compose, variant } from 'styled-system';

import { Icon } from '~/components/blocks';

type Props = {
  size?: 'xl' | 'xxl' | 'xxxl';
};

const Root = styled('div', {
  shouldForwardProp,
})<Props>(
  ({ theme }) =>
    css({
      background: '#E3F3D9',
      border: theme.borders.primary,
      borderRadius: theme.radii.circle,
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          xl: css({
            padding: theme.space.xs,
          }),
          xxl: css({
            padding: theme.space.s,
          }),
          xxxl: css({
            borderWidth: '2px',
            padding: `calc(${theme.space.m} + ${theme.space.xs})`,
          }),
        },
      }),
    ),
);

export const CheckinIcon = React.memo((props: Props) => {
  const { size = 'xxl' } = props;

  return (
    <Root size={size}>
      <Icon color="green" icon="checkin" size={size} />
    </Root>
  );
});

CheckinIcon.displayName = 'CheckinIcon';
