import React from 'react';

type CustomInputProps = {
  value?: string;
  onClick?: () => void;
  placeholder?: string;
};

export const CustomInputMonth = React.forwardRef(
  ({ value, onClick, placeholder }: CustomInputProps, ref: React.Ref<HTMLInputElement>) => {
    return <input ref={ref} readOnly value={value} onClick={onClick} placeholder={placeholder} />;
  },
);

CustomInputMonth.displayName = 'CustomInputMonth';
