import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { List } from '~/components/blocks';

import { HrefProps } from '../types';
import { MenuLink } from './MenuLink';

const Root = styled(List)(() =>
  css({
    borderTop: 'none',
  }),
);

const MENU_ROUTES: { [key: string]: [HrefProps, ...HrefProps[]] } = {
  logs: [{ path: '/company/security/logs' }, { path: '/company/security', exact: true }],
  client_certs: [{ path: '/company/security/client_certs' }],
  system_settings: [{ path: '/company/security/system_settings' }],
};

const RootMenu = () => {
  return (
    <Root>
      <MenuLink href={MENU_ROUTES.logs}>ログイン履歴</MenuLink>
      <MenuLink href={MENU_ROUTES.client_certs}>クライアント証明書</MenuLink>
      <MenuLink href={MENU_ROUTES.system_settings}>その他設定</MenuLink>
    </Root>
  );
};

export { RootMenu };
