import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { Button } from '~/components/blocks';
import { geocodingState } from '~/state/settings_organization_base/atoms';

type Fields = {
  lat: number;
  lng: number;
};

type Props = {
  prefecture: string;
  city: string;
  line: string;
  onError: (error: string | null) => void;
  onChange: (values: Fields) => void;
};

export const Geocoding = React.memo((props: Props) => {
  const { prefecture, city, line, onChange, onError } = props;
  const geocoding = useRecoilValue(geocodingState);

  const address = `${prefecture}${city}${line}`;
  const disabled = prefecture.length === 0 || city.length === 0 || line.length === 0;

  const handleClick = useCallback(() => {
    if (geocoding.geocoder) {
      geocoding.geocoder.geocode({ address, region: 'JP' }, (result, status) => {
        if (status === 'OK' && result) {
          onChange({
            lat: result[0].geometry.location.lat(),
            lng: result[0].geometry.location.lng(),
          });
          onError(null);
        } else {
          // https://developers.google.com/maps/documentation/geocoding/overview#StatusCodes
          onError('地図に反映できませんでした\n住所に間違いがないか確認してください');
        }
      });
    }
  }, [geocoding.geocoder, address, onChange, onError]);

  return (
    <Button use="base" disabled={disabled} onClick={handleClick}>
      住所を地図に反映
    </Button>
  );
});

Geocoding.displayName = 'Geocoding';
