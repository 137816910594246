import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, HeightProps, width, WidthProps } from 'styled-system';

const Item = styled('li', {
  shouldForwardProp,
})<HeightProps>(({ theme }) =>
  css({
    padding: `${theme.space.m}`,
    '& + &': {
      borderTop: theme.borders.grey,
    },
  }),
);

const Root = styled('ul', {
  shouldForwardProp,
})<WidthProps & { selectable?: boolean }>(
  ({ theme, selectable }) =>
    css({
      borderTop: theme.borders.grey,
      borderBottom: theme.borders.grey,
      ...(selectable && {
        [`& > ${Item}`]: {
          transitionDuration: theme.transitions.default,
          '&:hover': {
            background: theme.colors.background.bg,
            cursor: 'pointer',
          },
        },
      }),
    }),
  () => compose(width),
);

export const List = Object.assign(Root, { Item });
