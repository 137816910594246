import { useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Alert, Button, Modal, Text } from '~/components/blocks';
import { twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';

import { useDeactivateOtpSecrets } from './use-deactivate-otp_secrets';

export const ActivatingPanel = () => {
  const theme = useTheme();

  const { deactivating, error, handleSubmit } = useDeactivateOtpSecrets();

  const handleClickSetting = useRecoilCallback(
    ({ set }) =>
      () =>
        set(twoFactorAuthModalState, (_state) => ({ ..._state, currentPanel: 'scan' })),
    [],
  );

  return (
    <>
      <Modal.Body>
        {error && <Alert marginBottom={theme.space.l}>{error}</Alert>}
        <Text>
          現在、<strong>二要素認証が有効</strong>です。
        </Text>
        <Text block>再設定すると、以前の二要素認証は無効になります</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={deactivating} onClick={handleClickSetting}>
          再設定
        </Button>
        <Button
          use="secondary"
          marginLeft={theme.space.l}
          loading={deactivating}
          onClick={handleSubmit}
        >
          無効
        </Button>
      </Modal.Footer>
    </>
  );
};
