import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Name } from '~/components/blocks';

import { Profile } from './Profile';

export const EmptyProfile = () => {
  const theme = useTheme();

  return (
    <Box>
      <Name size="xl" name="氏名：---" />
      <Box marginTop={theme.space.m}>
        <Profile>
          <Profile.Item>生年月日：---</Profile.Item>
        </Profile>
      </Box>
    </Box>
  );
};
