import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { Flex, Icon, Text, Tooltip } from '~/components/blocks';
import { LineItemCode } from '~/graphql';

type OptionalLineItem = {
  feeSettingId: string;
  code: LineItemCode | '';
  subject: string;
  amount: number | string;
};

type Props = {
  lineItem: OptionalLineItem;
};

const Root = styled('div')(() =>
  css({
    width: '400px',
    height: '50px',
  }),
);

const Field = styled(Flex, { shouldForwardProp })(({ theme }) =>
  css({
    position: 'relative',
    width: '100%',
    height: '100%',
    border: theme.borders.default,
    borderRadius: theme.radii.default,
    color: theme.colors.text.default,
    overflow: 'hidden',
    justifyContent: 'space-between',
  }),
);

const LineItemName = styled(Flex)(({ theme }) =>
  css({
    width: '200px',
    height: '100%',
    background: theme.colors.background.default,
    paddingLeft: theme.space.m,
    alignItems: 'center',
  }),
);

const UberDeliveryFee = styled(Flex)(({ theme }) =>
  css({
    background: theme.colors.background.bg,
    paddingRight: theme.space.m,
    width: '200px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'right',
  }),
);

export const UberDeliveryLineItem = React.memo((props: Props) => {
  const { lineItem } = props;
  const theme = useTheme();

  return (
    <Root>
      <Tooltip
        content={
          <Flex alignItems="center">
            <Icon icon="attention" size="l" marginRight={theme.space.s} />
            <Text color="white">
              薬局と患者自宅の距離をもとに算出しています。
              <br />
              金額の変更はできません
            </Text>
          </Flex>
        }
        placement="top-end"
      >
        <Field>
          <LineItemName>当日配達利用料</LineItemName>
          <UberDeliveryFee>
            <Text size="l" fontWeight="bold" marginRight={theme.space.m}>
              {lineItem.amount}
            </Text>
            <Text size="s" paddingTop={theme.space.m}>
              円
            </Text>
          </UberDeliveryFee>
        </Field>
      </Tooltip>
    </Root>
  );
});

UberDeliveryLineItem.displayName = 'UberDeliveryLineItem';
