import React, { useEffect, useState } from 'react';
import { useCollapse } from 'react-collapsed';

import { Arrow, Body, Head, HeadContent, Tooltip, Wrapper } from './styled';

type Props = {
  className?: string;
  isInitialExpanded?: boolean;
  open?: boolean;
  label: React.ReactNode;
  disabled?: boolean;
  disabledMessage?: string;
  onOpen?: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const Collapse: React.FC<Props> = (props) => {
  const { onOpen, onClick } = props;
  const [isExpanded, setExpanded] = useState(!!props.isInitialExpanded || !!props.open);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded, duration: 100 });
  const arrowStatus = isExpanded ? 'opened' : 'closed';

  if (!((props.open != null && onClick) || (props.open == null && !onClick))) {
    console.warn('[Collapse]openまたはonClickを利用する場合は両方設定してください');
  }

  useEffect(() => {
    if (isExpanded) {
      onOpen && onOpen();
    }
  }, [isExpanded, onOpen]);

  useEffect(() => {
    setExpanded(!!props.open);
  }, [props.open]);

  return (
    <Wrapper className={props.className}>
      <Head
        {...getToggleProps({
          disabled: props.disabled,
          onClick: () => {
            if (onClick) {
              onClick();
            } else {
              setExpanded((prevExpanded) => !prevExpanded);
            }
          },
        })}
      >
        <HeadContent>{props.label}</HeadContent>
        {!props.disabled && <Arrow status={arrowStatus}></Arrow>}
        {props.disabled && props.disabledMessage && <Tooltip>{props.disabledMessage}</Tooltip>}
      </Head>
      <Body {...getCollapseProps()}>{props.children}</Body>
    </Wrapper>
  );
};
