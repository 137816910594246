import { isBefore } from 'date-fns';
import React, { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { Modal } from '~/components/blocks';
import { GetAccessPermissionQuery, useGetAccessPermissionLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { permissionModalState } from '~/state/layouts/OrganizationAppShell/atoms';

import { AllowPane } from './AllowPane';
import { ForbidPane } from './ForbidPane';

const getExpiredAt = (data?: GetAccessPermissionQuery) => {
  if (!data) return null;

  const expiredAt = getMe(data)?.operatorAccessPermission?.expiresAt;

  if (!expiredAt) return null;

  const today = new Date();
  const expiredDate = new Date(expiredAt);

  return isBefore(today, expiredDate) ? expiredAt : null;
};

export const AccessPermissionModal = () => {
  const [state, setState] = useRecoilState(permissionModalState);
  const [getAccessPermission, { data, loading }] = useGetAccessPermissionLazyQuery({
    fetchPolicy: 'network-only',
  });
  const expiredAt = getExpiredAt(data);
  const handleClose = useCallback(() => setState(() => ({ isOpen: false })), [setState]);

  useEffect(() => {
    if (state.isOpen) {
      getAccessPermission();
    }
  }, [getAccessPermission, state.isOpen]);

  return (
    <Modal size="s" open={state.isOpen} onClose={handleClose}>
      <Modal.Header>アクセス許可</Modal.Header>
      {loading ? null : expiredAt ? <ForbidPane expiredAt={expiredAt} /> : <AllowPane />}
    </Modal>
  );
};
