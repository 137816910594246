import { Howl } from 'howler';

import { publicPath } from '~/utils/path';

export const SOUNDS = {
  sound1: {
    label: 'サウンド１',
    sound: new Howl({
      src: [publicPath('/notification/sound1.mp3')],
    }),
  },
  sound2: {
    label: 'サウンド２',
    sound: new Howl({
      src: [publicPath('/notification/sound2.mp3')],
    }),
  },
  sound3: {
    label: 'サウンド３',
    sound: new Howl({
      src: [publicPath('/notification/sound3.mp3')],
    }),
  },
} as const;
