import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  EntryList,
  Flex,
  Grid,
  Header,
  Icon,
  Text,
} from '~/components/blocks';
import { CsClinic } from '~/components/partials';
import { CsClinicItemFragment, MedicationFollowupQuestionnairesSelectFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { FollowupMessageTag } from '../../FollowupMessageTag';
import { Fields } from '../InputPane/types';
import { Warning } from './Warning';

type Props = {
  sending: boolean;
  formik: FormikProps<Fields>;
  clinic: CsClinicItemFragment | null;
  questionnaire: MedicationFollowupQuestionnairesSelectFragment | null;
  onBack: () => void;
  onSubmit: (values: Fields) => void;
};

const ConfirmationList = styled(EntryList)(({ theme }) =>
  css({
    '& + &': {
      marginTop: theme.space.m,
      borderTop: theme.borders.default,
      paddingTop: theme.space.m,
    },
  }),
);

const FollowupBox = styled(Box)(({ theme }) =>
  css({
    borderTop: theme.borders.default,
    marginTop: theme.space.m,
    paddingTop: theme.space.l,
  }),
);

export const ConfirmPane = React.memo((props: Props) => {
  const { sending, formik, questionnaire } = props;
  const theme = useTheme();

  const [isConsented, setIsConsented] = useState(false);

  const values = formik.values;

  const handleClick = useCallback(() => setIsConsented((_state) => !_state), []);
  const handleSubmit = useCallback(() => props.onSubmit(formik.values), [formik.values, props]);

  return (
    <Grid
      height="100%"
      overflow="hidden"
      gridTemplateRows="min-content min-content 1fr min-content"
    >
      <Flex>
        <Button size="xs" use="white" disabled={sending} onClick={props.onBack}>
          <Icon icon="back-line" size="l" />
        </Button>
        <Header>メッセージ確認</Header>
      </Flex>
      <Warning />
      <Box height="100%" overflow="auto">
        <ConfirmationList size="s">
          <EntryList.Head>送信日時</EntryList.Head>
          <EntryList.Body>
            <Text fontWeight="bold">
              {values.fixed
                ? values.sendDate
                  ? `${Label.YYYYMMDDja(values.sendDate)} ${values.sendTime}:00`
                  : '送信日時を選択してください'
                : '即時配信'}
            </Text>
          </EntryList.Body>
        </ConfirmationList>
        <ConfirmationList size="s">
          <EntryList.Head>送信内容</EntryList.Head>
          <EntryList.Body>
            <Text size="s" whiteSpace="pre-line">
              {values.message}
            </Text>
          </EntryList.Body>
        </ConfirmationList>
        {values.attachment === 'clinic' ? (
          <ConfirmationList size="s">
            <EntryList.Head>案内する医療機関</EntryList.Head>
            <EntryList.Body>
              {props.clinic && (
                <Box marginTop={theme.space.m}>
                  <CsClinic clinic={props.clinic} />
                </Box>
              )}
            </EntryList.Body>
          </ConfirmationList>
        ) : (
          <FollowupBox>
            {values.attachment === 'questionnaire_sheet' && (
              <Flex alignItems="center">
                <FollowupMessageTag>
                  <Text size="xs" color="navy">
                    {`質問票（${questionnaire?.revision.subject}）`}
                  </Text>
                </FollowupMessageTag>
                {formik.values.isEnabledAutoReplySetting && (
                  <FollowupMessageTag>
                    <Text size="xs" color="navy">
                      自動返信有効
                    </Text>
                  </FollowupMessageTag>
                )}
              </Flex>
            )}
          </FollowupBox>
        )}
      </Box>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        paddingTop={`calc(${theme.space.m} + ${theme.space.xs})`}
        position="sticky"
        style={{ background: 'white' }}
      >
        <Checkbox
          disabled={sending}
          label="患者からメッセージ送信を同意済"
          checked={isConsented}
          onChange={handleClick}
        />
        <Button
          use="secondary"
          disabled={!isConsented}
          loading={sending}
          marginLeft={theme.space.l}
          onClick={handleSubmit}
        >
          送信
        </Button>
      </Flex>
    </Grid>
  );
});

ConfirmPane.displayName = 'ConfirmPane';
