import { AppointmentCancellationReason, AppointmentStatus } from '~/graphql';

export const translateReason = (
  reason: AppointmentCancellationReason,
  status?: AppointmentStatus,
  hasWaitingForChargeHistory?: boolean,
) => {
  switch (reason) {
    case AppointmentCancellationReason.Pat:
      if (status === AppointmentStatus.Noshow) {
        return hasWaitingForChargeHistory
          ? '患者都合でのキャンセル'
          : '患者が来店せず、連絡もとれない';
      }
      return '有効期限内に原本が届かなかった';
    case AppointmentCancellationReason.PatCpp:
      return '患者からキャンセルされた';
    case AppointmentCancellationReason.Prov:
      return '在庫がなく、他薬局で処方を行う';
    case AppointmentCancellationReason.Other:
      return 'その他';
    default:
      return '不明';
  }
};
