import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Text } from '~/components/blocks';

const MenuItem = styled(Box)(({ theme }) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.background.default,
    borderRadius: '8px',
    marginTop: theme.space.l,
    padding: theme.space.l,
  }),
);

const MenuTitle = styled(Text)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.navy,
    fontSize: theme.fontSizes.s,
    fontWeight: 'bold',
    marginBottom: theme.space.l,
  }),
);

const FakeLink = styled(Text)(({ theme }) =>
  css({
    padding: theme.space.m,
    borderRadius: theme.radii.default,
    display: 'inline-block',
    color: theme.colors.colorPallete.white,
    backgroundColor: theme.colors.background.secondary,
    marginTop: theme.space.l,
    fontWeight: theme.fontWeights.bold,
    textAlign: 'center',
    marginLeft: 'auto',
  }),
);

const PreviewText = styled(Text)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.navy,
  }),
);

type Props = {
  facetoface?: boolean;
  telemedicine?: boolean;
};

export const Menu = React.memo((props: Props) => {
  const theme = useTheme();

  return (
    <Box padding={theme.space.l}>
      <PreviewText fontWeight="bold">対応メニュー</PreviewText>
      {props.telemedicine && (
        <MenuItem>
          <MenuTitle>オンライン服薬指導</MenuTitle>
          <PreviewText>
            アプリから処方箋を送信し、アプリ上で服薬指導をして薬を配送してもらうサービスです。
          </PreviewText>
          <FakeLink>申し込み</FakeLink>
        </MenuItem>
      )}
      {props.facetoface && (
        <MenuItem>
          <MenuTitle>処方箋ネット受付</MenuTitle>
          <PreviewText>
            処方箋をアプリから薬局へ送信するサービスです。
            <br />
            処方箋送信後は希望した日時に薬局へ向かっていただき、対面で服薬指導を受け現地で会計・薬の受け取りをしていただきます。
          </PreviewText>
          <FakeLink>申し込み</FakeLink>
        </MenuItem>
      )}
      {!props.telemedicine && !props.facetoface && (
        <MenuItem>
          <PreviewText>
            オンライン服薬指導または、処方箋ネット受付を有効にすると表示されます
          </PreviewText>
        </MenuItem>
      )}
    </Box>
  );
});

Menu.displayName = 'Menu';
