import React, { useMemo } from 'react';

import { Select } from '~/components/blocks';
import { useGetPharmacistsQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

type Props = {
  disabled: boolean;
  error?: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectPharmacist = React.memo((props: Props) => {
  const { data, loading } = useGetPharmacistsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const pharmacists = useMemo(() => {
    return data ? getMe(data)?.organization.pharmacistPractitioners || [] : [];
  }, [data]);

  return (
    <Select
      fill
      name={props.name}
      disabled={loading || props.disabled}
      error={props.error}
      value={props.value}
      onChange={props.onChange}
    >
      <option hidden value="">
        薬剤師を選択
      </option>
      {pharmacists.map(({ familyName, givenName }, idx) => {
        const fullName = `${familyName}${givenName}`;

        return (
          <option key={idx} value={fullName}>
            {fullName}
          </option>
        );
      })}
    </Select>
  );
});

SelectPharmacist.displayName = 'SelectPharmacist';
