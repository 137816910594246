import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import React, { useCallback, useState } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';

import { Alert, Box, Button, Flex, Icon, Text, Tooltip } from '~/components/blocks';
import {
  PatientMedicationFollowupCommentFragment,
  useGetCurrentPractitionerQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { patientDetailDialogPatientCommentState } from '~/state/partials/patient_detail_dialog/atoms';
import { Label } from '~/utils/label';

import { FollowupCommentForm } from '../../FollowupCommentForm';
import { Fields } from '../../FollowupCommentForm/types';
import { useUpdatePatientComment } from '../../FollowupCommentForm/use-update-patient-comment';
import { CommentIcon } from '../CommentIcon';

type Props = {
  editing?: boolean;
  className?: string;
  readonly?: boolean;
  comment: PatientMedicationFollowupCommentFragment;
};

const Edit = styled(Box)(({ theme }) =>
  css({
    margin: `${theme.space.m} 0 0`,
    padding: `${theme.space.s} ${theme.space.m}`,
    fontSize: theme.fontSizes.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      color: theme.colors.text.primary,
    },
  }),
);

const Event = styled(Box)<{ clicked?: boolean }>(({ theme, clicked }) =>
  css({
    border: `2px solid ${theme.colors.colorPallete.grey03}`,
    background: theme.colors.background.default,
    borderRadius: '0px 6px 6px 6px',
    boxShadow: `2px 4px 8px ${rgba(theme.colors.background.black, 0.05)}`,
    padding: theme.space.m,
    '& + &': {
      marginTop: theme.space.l,
    },
    '&:hover': {
      cursor: 'default',
    },
    ...(clicked && {
      border: `2px solid ${theme.colors.border.primary}`,
      '&:hover': {
        cursor: 'pointer',
      },
    }),
  }),
);

export const PatientMedicationFollowupComment: React.FC<Props> = (props) => {
  const { editing, comment } = props;
  const theme = useTheme();
  const [isClicked, setIsClicked] = useState(editing);
  // TODO: 更新時にイベントのリフェッチを走らせずに画面上に反映させるために、別途で変数を用意しているのできれいにする
  const [updatedComment, setUpdatedComment] = useState<string | undefined>(undefined);
  const [patientCommentState, setPatientCommentState] = useRecoilState(
    patientDetailDialogPatientCommentState,
  );

  const { data } = useGetCurrentPractitionerQuery();
  const currentPractitioner = data ? getMe(data) : undefined;

  const isEditable = currentPractitioner && comment.practitioner.id === currentPractitioner.id;
  const isClickable = !props.readonly && isEditable;

  const { update, formRef, error, isUpdating } = useUpdatePatientComment();

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        if (!isClickable) return;
        if (
          patientCommentState.selectedCommentId &&
          patientCommentState.selectedCommentId !== comment.id
        )
          return;

        setIsClicked(true);
        set(patientDetailDialogPatientCommentState, (_state) => ({
          ..._state,
          selectedCommentId: comment.id,
        }));
      },
    [isClickable, comment, patientCommentState.selectedCommentId],
  );
  const handleBack = useRecoilCallback(
    ({ set }) =>
      () => {
        setIsClicked(false);
        set(patientDetailDialogPatientCommentState, (_state) => ({
          ..._state,
          selectedCommentId: null,
        }));
      },
    [],
  );
  const handleDeleteModalOpen = useRecoilCallback(
    ({ set }) =>
      () => {
        set(patientDetailDialogPatientCommentState, (_state) => ({
          ..._state,
          isDeleteModalOpen: true,
        }));
      },
    [],
  );
  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        const comment = await update(values);
        setIsClicked(false);

        if (comment?.__typename === 'PatientMedicationFollowupComment') {
          setUpdatedComment(comment.comment);
        }

        setPatientCommentState((_state) => ({
          ..._state,
          selectedCommentId: null,
        }));
        formRef.current?.resetForm();
      } catch {
        // 何もしない
      }
    },
    [formRef, update, setPatientCommentState],
  );
  const handleSend = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  return (
    <Flex alignItems="top">
      <Tooltip
        content={
          <Text color="white" size="xs" fontWeight="bold">
            フォローアップメモ
            <br />
            （店舗内共有）
          </Text>
        }
      >
        <Flex marginLeft={theme.space.xs}>
          <CommentIcon />
        </Flex>
      </Tooltip>
      <Flex flexDirection="column" width="100%" marginLeft={theme.space.m}>
        <Text fontWeight="bold" whiteSpace="nowrap" size="xs">
          {Label.YYYYMMDDja(comment.createdAt)} {Label.HHMM(comment.createdAt)}
          &nbsp;|&nbsp;
          {comment.practitioner.familyName}&nbsp;{comment.practitioner.givenName}
        </Text>
        <Event className={props.className} clicked={isClicked}>
          {error && <Alert marginBottom={theme.space.l}>{error}</Alert>}
          <Box marginTop={theme.space.s}>
            {isClicked ? (
              <FollowupCommentForm
                ref={formRef}
                hasMessages
                disabled={isUpdating}
                initialValues={{ comment: updatedComment ? updatedComment : comment.comment }}
                onSubmit={handleSubmit}
              />
            ) : (
              <Text size="s" wordBreak="break-word" whiteSpace="pre-line">
                {updatedComment ? updatedComment : comment.comment}
              </Text>
            )}
          </Box>
          {isEditable ? (
            isClicked ? (
              <Flex justifyContent="space-between">
                <Button size="s" use="white" onClick={handleDeleteModalOpen}>
                  <Icon icon="trash" size="s" />
                  <Text marginLeft={theme.space.s}>削除</Text>
                </Button>
                <Flex justifyContent="flex-end">
                  <Button size="s" use="white" onClick={handleBack}>
                    キャンセル
                  </Button>
                  <Button size="s" use="base" marginLeft={theme.space.m} onClick={handleSend}>
                    保存
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Edit onClick={handleClick}>
                <Icon icon="edit" size="s" color="grey" />
                <Text marginLeft={theme.space.s} color="grey">
                  編集
                </Text>
              </Edit>
            )
          ) : null}
        </Event>
      </Flex>
    </Flex>
  );
};

PatientMedicationFollowupComment.displayName = 'PatientMedicationFollowupComment';
