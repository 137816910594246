import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Flex, List, Tag, Text } from '~/components/blocks';
import { AnsweredQuestionnaireSheetFragment, DirectVisitor } from '~/graphql';
import { notificationPanelState } from '~/state/layouts/SharedAppShell/atoms';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';
import { Label } from '~/utils/label';

type Props = {
  sheet: AnsweredQuestionnaireSheetFragment;
};

const Unread = styled(Tag)(({ theme }) =>
  css({
    color: theme.colors.text.white,
    background: theme.colors.background.secondary,
    border: theme.borders.error,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    padding: `${theme.space.xs} ${theme.space.s}`,
    lineHeight: 1,
  }),
);

export const Notification = React.memo((props: Props) => {
  const { sheet } = props;

  const theme = useTheme();

  const patient = useMemo(() => {
    const messageDeliverGuestPatient =
      sheet.medicationFollowupMessage.messageDeliverResult?.guestPatient?.__typename ===
      'DirectVisitor'
        ? (sheet.medicationFollowupMessage.messageDeliverResult.guestPatient as DirectVisitor)
        : null;
    return (
      sheet.medicationFollowupMessage.appointment?.patient ||
      sheet.medicationFollowupMessage.appointment?.webVisitor ||
      sheet.medicationFollowupMessage.visit?.directVisitor ||
      messageDeliverGuestPatient
    );
  }, [
    sheet.medicationFollowupMessage.appointment?.patient,
    sheet.medicationFollowupMessage.appointment?.webVisitor,
    sheet.medicationFollowupMessage.messageDeliverResult?.guestPatient,
    sheet.medicationFollowupMessage.visit?.directVisitor,
  ]);
  const patientName = patient ? `${patient.familyName}${patient.givenName}` : 'ー';
  const questionnaireSubject =
    sheet.medicationFollowupMessage.medicationFollowupQuestionnaireRevision?.subject;

  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        if (!patient) return;

        set(patientDetailDialogState, (_state) => ({
          ..._state,
          isOpen: true,
          patientId: patient.id,
          type: 'message' as TabType,
        }));

        // すぐ閉じるとReactのWarningが出るため、一定時間経過後に閉じる
        setTimeout(() => {
          reset(notificationPanelState);
        }, 200);
      },
    [patient],
  );

  return (
    <List.Item onClick={handleClick}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text size="xs" color="grey01">
          {`${Label.YYYYMMDDja(sheet.createdAt)} ${Label.HHMM(sheet.createdAt)}`}
        </Text>
        <Flex alignItems="center">
          <Box marginLeft={theme.space.s}>
            {!sheet.medicationFollowupMessage.isRead && <Unread size="s">未読</Unread>}
          </Box>
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontWeight="bold" size="s">
          {`${patientName}様から質問票（${questionnaireSubject}）の回答がありました`}
        </Text>
      </Flex>
    </List.Item>
  );
});

Notification.displayName = 'Notification';
