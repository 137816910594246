import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ScrollBox } from '~/components/blocks';
import {
  useCreateTracingReportMutation,
  useGetPatientEventsLazyQuery,
  useGetTracingReportUrlLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import {
  eventPageInfoState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';
import { formatISOString } from '~/utils/date';
import { openWithNoOpener } from '~/utils/window';

import { TracingReportForm } from './TracingReportForm';
import { Fields } from './types';

export const useCreateTracingReport = (patientType?: string) => {
  const formRef = useRef<RefAttributeType<typeof TracingReportForm> | null>(null);
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const { page, perPage } = useRecoilValue(eventPageInfoState);
  const [event] = useSubscriptions('GenerateTracingReport', ['updated']);
  const [getEvents] = useGetPatientEventsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const state = useRecoilValue(patientDetailDialogState);
  const patientId = patientType === 'Patient' ? state.patientId : undefined;
  const guestPatientId = patientType !== 'Patient' ? state.patientId : undefined;

  const setEventInfoState = useSetRecoilState(eventPageInfoState);
  const [createReport] = useCreateTracingReportMutation();
  const [getUrl] = useGetTracingReportUrlLazyQuery({
    onCompleted: (_data) => {
      const info = getNode(_data, 'TracingReport');

      if (info?.status === 'succeeded' && info.file?.url) {
        openWithNoOpener(
          `/tracing_report_preview?report=${encodeURIComponent(info.file.url)}&jobInformationId=${
            info.id
          }`,
          {
            width: 820,
            height: 900,
            bgColor: '#525659',
          },
        );
        formRef.current?.resetForm();
      } else {
        setError('トレーシングレポート作成に失敗しました。再度お試しください');
      }
    },
  });

  const create = useCallback(
    async (values: Fields) => {
      const prescriptionIssueDate = formatISOString(values.prescriptionIssueDate);
      const dispensingDate = formatISOString(values.dispensingDate);

      if (!prescriptionIssueDate || !dispensingDate) return;

      setIsCreating(true);
      setError(null);

      try {
        await createReport({
          variables: {
            input: {
              clinicName: values.clinicName,
              pharmacistName: values.pharmacistName,
              report: values.report,
              guestPatientId,
              patientId,
              dispensingDate,
              prescriptionIssueDate,
              doctorName: values.doctorName || '',
              clinicalDepartment: values.clinicalDepartment || '',
              prescriptionDrug: values.prescriptionDrugs?.filter((p) => p).join('、') || '',
              suggestion: values.suggestion || '',
            },
          },
        });
      } catch (error) {
        setError(error?.message || 'エラーが発生しました');
        setIsCreating(false);
      }
    },
    [createReport, guestPatientId, patientId],
  );

  useEffect(() => {
    if (event) {
      try {
        // GenerateTracingReportされたタイミングでイベントの一覧を再取得する
        setEventInfoState((_state) => ({ ..._state, isResetEvent: true, page: 1, perPage: 20 }));
        getUrl({
          variables: {
            id: event.typeId,
          },
        });
      } catch (error) {
        setError(error?.message || 'エラーが発生しました');
      }
      setIsCreating(false);
    }
  }, [event, getUrl, setEventInfoState]);

  useEffect(() => {
    if (event) {
      getEvents({
        variables: {
          guestPatientId,
          patientId,
          page,
          perPage,
        },
      });
    }
  }, [getEvents, guestPatientId, page, patientId, perPage, event]);

  return {
    formRef,
    scrollRef,
    isCreating,
    error,
    create,
  };
};
