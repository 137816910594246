import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Head from 'next/head';
import React, { HTMLAttributes } from 'react';

import { Box, Text } from '~/components/blocks';
type Props = {
  header?: boolean;
} & React.HTMLProps<HTMLAttributes<HTMLDivElement>>;

const Header = styled('h1')(({ theme }) =>
  css({
    display: 'block',
    width: '100%',
    height: '34px',
    margin: '0 auto',
    borderRadius: '17px',
    fontSize: theme.fontSizes.m,
    fontWeight: theme.fontWeights.bold,
    lineHeight: '34px',
    color: theme.colors.text.white,
    backgroundColor: theme.colors.colorPallete.green,
    textAlign: 'center',
  }),
);

const Main = styled('div')(() =>
  css({
    width: '100%',
    maxWidth: '640px',
    margin: '0 auto',
    borderRadius: '8px',
  }),
);

const MainHeader = styled('div')(() =>
  css({
    width: '100%',
    maxWidth: '640px',
    margin: '0 auto',
  }),
);

const Root = styled('div')(({ theme }) =>
  css({
    width: '100%',
    height: '100%',
    overflow: 'auto',
    margin: 0,
    padding: theme.space.l,
    backgroundColor: theme.colors.background.bg,
  }),
);

export const QuestionnaireSheet = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const theme = useTheme();

  const { header = true } = props;

  return (
    <>
      <Head>
        <meta name="robots" content="noindex,nofollow" />
        <title>調剤薬局服薬フォローアップサービス</title>
      </Head>
      <Root ref={ref}>
        <MainHeader>{header && <Header>服薬フォローアップ質問票</Header>}</MainHeader>
        <Main>{props.children}</Main>
        <Box textAlign="center" marginTop={theme.space.xl}>
          <Text size="xs">&copy;2020 MEDLEY, INC.</Text>
        </Box>
      </Root>
    </>
  );
});

QuestionnaireSheet.displayName = 'QuestionnaireSheet';
