import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Flex, Icon, Text } from '~/components/blocks';
import { WEEK } from '~/constants/week';
import { Label } from '~/utils/label';

type Props = {
  date?: string | null;
};

const Root = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    background: theme.colors.background.bg,
    border: `${theme.colors.colorPallete.grey03} 1px solid`,
    padding: theme.space.m,
    position: 'sticky',
    top: 0,
  }),
);

const toDate = (date: string) => {
  const wDay = new Date(date).getDay();
  const weekDay = WEEK.find((day) => day[2] === wDay)?.[0];

  return `${Label.YYYYMMDDja(date)}${weekDay ? `（${weekDay}）` : ''}`;
};

export const DispensingDate: React.FC<Props> = (props) => {
  const theme = useTheme();

  const { date } = props;

  return (
    <Root>
      <Icon size="l" icon="calender" color="black" />
      <Text size="m" marginLeft={theme.space.m} fontWeight={theme.fontWeights.bold} color="black">
        {date ? toDate(date) : 'ー'}
      </Text>
    </Root>
  );
};
