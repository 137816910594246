import { css, Global } from '@emotion/react';
import Head from 'next/head';
import React from 'react';

import { Policy } from './Policy';

const globalStyles = css`
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

const Setting = () => {
  return (
    <>
      <Global styles={globalStyles} />
      <Head>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.reCAPTCHASiteKey}`}
        ></script>
      </Head>
    </>
  );
};

export const GoogleReCapture = Object.assign(Setting, { Policy });
