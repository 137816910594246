import { array, date, object, ObjectSchema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';

import { Fields } from './types';

export const MAX_REPORT_LENGTH = 630;
export const MAX_SUGGESTION_LENGTH = 270;
const MAX_PRESCRIPTION_DRUG_NAME_LENGTH = 50;
const MAX_PRESCRIPTION_DRUG_COUNT = 7;

const MAX_REPORT_LINES = 14;
const MAX_SUGGESTION_LINES = 6;

const calcLineCounts = (text: string) => {
  return (text.match(/\r\n|\n/g)?.length ?? 1) + 1;
};

export const validationSchema: ObjectSchema<Fields> = object({
  clinicName: string().label('医療機関名').required(FormErrorMessage.required).trim(),
  clinicalDepartment: string().label('診療科').trim(),
  doctorName: string().label('担当医師名').trim(),
  prescriptionIssueDate: date().label('処方箋発行日').required(FormErrorMessage.required),
  dispensingDate: date().label('調剤日').required(FormErrorMessage.required),
  pharmacistName: string().label('担当薬剤師').required(FormErrorMessage.required),
  prescriptionDrugs: array()
    .compact()
    .of(
      string()
        .label('対象薬剤')
        .max(MAX_PRESCRIPTION_DRUG_NAME_LENGTH, FormErrorMessage.maxLengthRequired)
        .required(),
    )
    .label('対象薬剤')
    .max(MAX_PRESCRIPTION_DRUG_COUNT, FormErrorMessage.maxCountRequired)
    .default([]),
  report: string()
    .label('報告事項')
    .required(FormErrorMessage.required)
    .trim()
    .max(MAX_REPORT_LENGTH, FormErrorMessage.maxLengthRequired)
    .test('report', `${MAX_REPORT_LINES}行以内で入力してください`, (report) => {
      if (!report) return false;

      return calcLineCounts(report) <= MAX_REPORT_LINES;
    }),
  suggestion: string()
    .label('提案事項')
    .max(MAX_SUGGESTION_LENGTH, FormErrorMessage.maxLengthRequired)
    .test('suggestion', `${MAX_SUGGESTION_LINES}行以内で入力してください`, (suggestion) => {
      if (!suggestion) return true;

      return calcLineCounts(suggestion) <= MAX_SUGGESTION_LINES;
    }),
});
