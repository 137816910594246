import React, { useMemo } from 'react';

import { ReceiveOption } from '~/components/partials/ReceiveOption';
import { DraftAppointmentDeliveryMethod } from '~/graphql';

type Props = {
  deliveryMethod: DraftAppointmentDeliveryMethod;
};

export const ReceiveOptionBlock = (props: Props) => {
  const { deliveryMethod } = props;

  const color = useMemo(() => {
    if (deliveryMethod === DraftAppointmentDeliveryMethod.SameDayDelivery) {
      return 'cyan';
    } else {
      return 'grey';
    }
  }, [deliveryMethod]);

  const text = useMemo(() => {
    // 当日配達希望（オンラインが前提）
    if (deliveryMethod === DraftAppointmentDeliveryMethod.SameDayDelivery) {
      return '当日配達を希望';
    } else {
      return '配達';
    }
  }, [deliveryMethod]);

  return <ReceiveOption color={color} text={text} />;
};
