import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Button, Icon } from '~/components/blocks';

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

const Root = styled(Button)<{ isOpen: boolean }>(({ isOpen }) =>
  css({
    backgroundColor: isOpen ? 'none' : undefined,
  }),
);

export const PatientButton = React.memo((props: Props) => {
  return (
    <Root size="xs" use="white" isOpen={props.isOpen} onClick={props.onClick}>
      <Icon size="l" icon={props.isOpen ? 'forward-line' : 'back-line'} />
    </Root>
  );
});

PatientButton.displayName = 'PatientButton';
