import React from 'react';

import { Tabs } from '~/components/blocks';
import { useClientAuthSetting } from '~/hooks/use-client-auth-setting';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';

import { HrefProps } from '../types';
import { MenuLink } from './MenuLink';

const MENU_ROUTES: { [key: string]: [HrefProps, ...HrefProps[]] } = {
  organization: [
    { path: '/settings/organization', exact: true },
    { path: '/settings', exact: true },
  ],
  webBookingSettings: [{ path: '/settings/organization/web_booking_settings' }],
  businessHour: [
    { path: '/settings/organization/business_hour', exact: true },
    { path: '/settings/organization/web_bookings_limit', exact: true },
  ],
  bankAccount: [{ path: '/settings/organization/bank_account' }],
  clientCerts: [{ path: '/settings/organization/client_certs' }],
};

export const RootMenu = () => {
  const { verifyRole } = usePractitionerRole();
  const hasViewingBankAccountAuthority = verifyRole(['company_admin', 'admin']);
  const { enable } = useClientAuthSetting();

  return (
    <Tabs>
      <MenuLink href={MENU_ROUTES.organization}>基本情報</MenuLink>
      <MenuLink href={MENU_ROUTES.businessHour}>受付設定</MenuLink>
      <MenuLink href={MENU_ROUTES.webBookingSettings}>公開情報</MenuLink>
      {hasViewingBankAccountAuthority && (
        <MenuLink href={MENU_ROUTES.bankAccount}>口座情報</MenuLink>
      )}
      {enable && <MenuLink href={MENU_ROUTES.clientCerts}>クライアント証明書</MenuLink>}
    </Tabs>
  );
};
