import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useContext } from 'react';

import { DialogContext } from './DialogContext';

type Props = {
  children?: React.ReactNode;
};

const Root = styled('div')<{ both?: boolean; invert?: boolean }>(({ theme, both, invert }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: both ? 'space-between' : 'flex-end',
    color: theme.colors.text.white,
    marginTop: theme.space.l,
    ...(invert && {
      color: theme.colors.text.white,
      borderBottom: 'none',
    }),
  }),
);

export const Footer: React.FC<Props> = (props) => {
  const { invert } = useContext(DialogContext);

  return <Root invert={invert}>{props.children}</Root>;
};
