import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetCsCitiesLazyQuery } from '~/graphql';
import { searchCsClinicsPageInfoState } from '~/state/partials/search_cs_clinics/atoms';

export const useFetchCsCities = () => {
  const { prefectureCode } = useRecoilValue(searchCsClinicsPageInfoState);
  const [getCsCities, { loading, data }] = useGetCsCitiesLazyQuery();

  useEffect(() => {
    if (prefectureCode) {
      getCsCities({
        variables: {
          prefectureCode,
        },
      });
    }
  }, [getCsCities, prefectureCode]);

  return {
    loading,
    csCities: data?.csCities || [],
  };
};
