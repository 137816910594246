import { useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { Alert, Box, Collapse, Grid, Icon, Text } from '~/components/blocks';
import { DirectVisitorDetailDialogProfileFragment } from '~/graphql';
import { patientDetailDialogProfileState } from '~/state/partials/patient_detail_dialog/atoms';

import { PatientProfile } from './PatientProfile';
import { EditPatientProfile } from './PatientProfile/EditPatientProfile';

type Props = {
  visitor: DirectVisitorDetailDialogProfileFragment;
};

export const DirectVisitorKarte = React.memo((props: Props) => {
  const theme = useTheme();
  const { type } = useRecoilValue(patientDetailDialogProfileState);
  const visitDay = props.visitor.visits.nodes[0].createdAt;

  return (
    <Grid height="100%" overflow="auto" gridTemplateRows="min-content min-content 1fr">
      {type === 'edit' ? (
        <EditPatientProfile guestPatient={props.visitor} />
      ) : (
        <>
          <PatientProfile patient={props.visitor} visitedAt={visitDay} />
          <Box marginTop={theme.space.m}>
            <Collapse
              label={
                <>
                  <Icon size="xxl" icon="drug" />
                  処方箋
                </>
              }
            >
              <Box padding={theme.space.m}>
                <Alert status="info">患者の持参した処方箋をご確認ください</Alert>
              </Box>
            </Collapse>
          </Box>
          <Box marginTop={theme.space.l}>
            <Text fontWeight="bold" marginBottom={theme.space.m}>
              問診
            </Text>
            <Alert status="info">登録患者のため、問診票はありません</Alert>
          </Box>
        </>
      )}
    </Grid>
  );
});

DirectVisitorKarte.displayName = 'DirectVisitorKarte';
