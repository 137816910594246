import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { useSendDispensingPreparedNotificationMutation, useSkipEncounterMutation } from '~/graphql';
import { notificationConfirmModalState } from '~/state/partials/patient_profile_drawer/atoms';

export const useSendMessage = () => {
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { isOpen, appointmentId } = useRecoilValue(notificationConfirmModalState);

  const [skipEncounter] = useSkipEncounterMutation();
  const [sendDispensingPreparedNotification] = useSendDispensingPreparedNotificationMutation();

  const handleSend = useRecoilCallback(
    ({ reset }) =>
      async () => {
        if (!appointmentId) return;

        setIsSending(true);

        try {
          await sendDispensingPreparedNotification({
            variables: {
              input: {
                appointmentId,
              },
            },
          });

          // 指導待ち -> 未会計に変更
          await skipEncounter({
            variables: {
              input: {
                appointmentId,
              },
            },
          });

          reset(notificationConfirmModalState);
        } catch (_error) {
          setError(_error?.message || 'エラーが発生しました');
        } finally {
          setIsSending(false);
        }
      },
    [appointmentId, sendDispensingPreparedNotification, skipEncounter],
  );

  useEffect(() => {
    if (isOpen) {
      setError(null);
    }
  }, [isOpen]);

  return { sending: isSending, error, handleSend };
};
