import { differenceInMinutes } from 'date-fns';
import { useEffect, useState } from 'react';

import { useGetDispensingRequestsFromNodeLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import {
  formatMedicalInstitution,
  GET_DISPENSING_REQUEST_INTERVAL_MINUTES,
} from '~/utils/pf_dispensing_request';

const ONE_MINUTE = 60 * 1000;

export const useFetchDispensingRequests = (isOpen: boolean, appointmentId: string) => {
  const [error, setError] = useState<Error | null>(null);
  const [loadedDate, setLoadedDate] = useState<Date | null>(null);
  const [abortedTimerId, setAbortedTimerId] = useState<number | null>(null);
  const [isTimeout, setIsTimeout] = useState(false);
  const [controller] = useState(new AbortController());

  const [getDispensingRequests, { called, loading, data }] =
    useGetDispensingRequestsFromNodeLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      context: { fetchOptions: { signal: controller.signal } },
      onCompleted: () => {
        setLoadedDate(new Date());

        if (abortedTimerId !== null) {
          clearTimeout(abortedTimerId);
        }
      },
      onError: (_error) => setError(_error),
    });
  const appointment = getNode(data, 'Appointment');
  const dispensingRequests = appointment?.pfDispensingRequests || [];
  const dispensingRequestCount = appointment?.pfDispensingRequestCount ?? 0;
  const medicalInstitution = formatMedicalInstitution(dispensingRequests[0]);

  useEffect(() => {
    if (isOpen && appointmentId) {
      setError(null);

      getDispensingRequests({ variables: { appointmentId } });
    }
  }, [getDispensingRequests, appointmentId, error, isOpen]);

  useEffect(() => {
    if (isOpen && appointmentId) {
      setError(null);

      // 処方箋URLに有効期限（１日）があるので念のため初回ロードからINTERVAL_MINUTESたってたら再取得する
      if (
        !loadedDate ||
        differenceInMinutes(new Date(), loadedDate) > GET_DISPENSING_REQUEST_INTERVAL_MINUTES
      ) {
        getDispensingRequests({ variables: { appointmentId } });
      }
    }
  }, [getDispensingRequests, loadedDate, appointmentId, isOpen]);

  useEffect(() => {
    const _timerId = window.setTimeout(() => {
      controller.abort();
      setIsTimeout(true);
    }, ONE_MINUTE);
    setAbortedTimerId(_timerId);

    return () => {
      if (abortedTimerId !== null) {
        clearTimeout(abortedTimerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: !called || loading,
    timeout: isTimeout,
    error,
    appointmentStatus: appointment?.status,
    telemedicine: appointment?.telemedicine,
    dispensingRequestCount,
    dispensingRequests,
    medicalInstitution,
    pfDispensingRequestUploadType: appointment?.pfDispensingRequestUploadType,
  };
};
