import ClipboardJS from 'clipboard';
import React, { useEffect, useRef, useState } from 'react';

import { Button, Icon, Text } from '~/components/blocks';

type Props = {
  marginTop?: 's' | 'm' | 'l';
  marginRight?: 's' | 'm' | 'l';
  marginBottom?: 's' | 'm' | 'l';
  textSize?: 'xs' | 'xxs';
  size?: 's' | 'm';
  iconSize?: 'm' | 'l';
  wide?: 'half' | 'fill';
  disabled?: boolean;
  text: string;
};

export const ClipButton = React.memo((props: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isClipping, setIsClipping] = useState(false);

  useEffect(() => {
    if (!buttonRef.current) return;

    const buttonElement = buttonRef.current;
    const clipboard = new ClipboardJS(buttonElement);
    let timerId: number | undefined = undefined;

    clipboard.on('success', () => {
      clearTimeout(timerId);
      setIsClipping(true);
      // コピーされたことが視覚的にわかるように一定時間おく
      timerId = window.setTimeout(() => {
        setIsClipping(false);
      }, 250);
    });

    return () => {
      clearTimeout(timerId);
      clipboard.destroy();
    };
  }, []);

  return (
    <Button
      ref={buttonRef}
      loading={isClipping}
      marginTop={props.marginTop}
      marginRight={props.marginRight}
      marginBottom={props.marginBottom}
      size={props.size}
      wide={props.wide}
      disabled={props.disabled}
      data-clipboard-text={props.text}
    >
      <Icon icon="copy" size={props.iconSize} />
      <Text size={props.textSize}>クリップボードにコピー</Text>
    </Button>
  );
});

ClipButton.displayName = 'ClipButton';
