import React from 'react';

import { Modal, Text } from '~/components/blocks';

export const ActivationCompletedPanel = () => {
  return (
    <Modal.Body>
      <Text>
        次回ログイン時より「<strong>Google Authenticator</strong>」の
        <br />
        コード入力が必要になります
      </Text>
    </Modal.Body>
  );
};
