import { theme } from '~/styles/theme';

import { publicPath } from './path';

type WindowProps = {
  width?: number;
  height?: number;
};

type OpenOptions = WindowProps & {
  target?: string;
  bgColor?: string;
};

const DEFAULT_FEATURES = 'resizable,scrollbars,status,toolbar' as string;
const DEFAULT_HEIGHT = 720;
const DEFAULT_WIDTH = 640;

const getWindowProps = (windowProps: WindowProps = {}) => {
  const props: string[] = [DEFAULT_FEATURES];

  const height = windowProps.height || DEFAULT_HEIGHT;
  const width = windowProps.width || DEFAULT_WIDTH;

  props.push(`height=${height},width=${width}`);

  return props.join(',');
};

/**
 * noopenerとウィンドウサイズを指定指定場合に使う<br>
 * https://stackoverflow.com/questions/58495758/window-open-ignoring-height-and-width-when-noopener-is-included-in-the-option
 * @param url
 * @param options
 */
export const openWithNoOpener = (url: string, options: OpenOptions = {}) => {
  const { bgColor = theme.colors.background.default } = options;
  const windowProsp = getWindowProps(options);
  const childWindow = window.open('', options?.target, windowProsp);

  if (childWindow) {
    childWindow.document.write(`
    <html>
      <head>
        <title>かかりつけ薬局支援システム Pharms（ファームス）</title>
        <link rel="icon" href={${publicPath('/favicon.ico')}}></link>
        <style type="text/css">
          html {
            background: ${bgColor};
          }
        </style>
      </head>
      <body>
      </body>
    </html>`);
    childWindow.opener = null;
    childWindow.location.href = url;
  }
};
