import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Box, Chip, Flex, Header, Icon, Name, Tabs } from '~/components/blocks';
import { Tab } from '~/components/blocks/Tabs/Tab';
import {
  DirectVisitorDetailDialogProfileFragment,
  PatientDetailDialogProfileFragment,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import {
  patientDetailConfirmBreakawayState,
  patientDetailDialogMessageState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';
import {
  PatientDetailDiaLogActionType,
  TabType,
} from '~/state/partials/patient_detail_dialog/types';

type Props = {
  patient?:
    | DirectVisitorDetailDialogProfileFragment
    | WebVisitorDetailDialogProfileFragment
    | PatientDetailDialogProfileFragment;
  statusManagement: boolean;
};

const PatientName = styled(Flex)<{ isPharmacist: boolean }>(({ theme, isPharmacist }) =>
  css({
    width: isPharmacist ? '373px' : 'auto',
    height: '59px',
    justifyContent: 'start',
    alignItems: 'center',
    [`@media ${theme.mediaQueries.tablet}`]: {
      width: isPharmacist ? '272px' : 'auto',
    },
  }),
);

const CustomHeader = styled(Header)(({ theme }) =>
  css({
    borderBottom: theme.borders.default,
    height: '58px',
    paddingLeft: '48px',
  }),
);

const InfoAlert = styled(Alert)(({ theme }) =>
  css({
    height: '100%',
    textAlign: 'initial',
    margin: theme.space.l,
    padding: theme.space.l,
    fontSize: theme.fontSizes.s,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.xs,
      margin: theme.space.l,
      padding: theme.space.m,
    },
  }),
);

export const DialogHeder = React.memo((props: Props) => {
  const theme = useTheme();
  const { patient, statusManagement } = props;
  const { selectedMessageId } = useRecoilValue(patientDetailDialogMessageState);
  const { type } = useRecoilValue(patientDetailDialogState);
  const { verifyRole } = usePractitionerRole();
  const isPharmacist = verifyRole('pharmacist');

  const followupStatus = patient?.medicationFollowupStatus;

  const handleCreateTracingReport = useRecoilCallback(
    ({ set }) =>
      () => {
        if (selectedMessageId) {
          set(patientDetailConfirmBreakawayState, (_state) => ({
            ..._state,
            isOpen: true,
            action: 'toTracingReport' as PatientDetailDiaLogActionType,
          }));
        } else {
          if (patient?.id) {
            set(patientDetailDialogState, (_state) => ({
              ..._state,
              patientId: patient?.id,
              isOpen: true,
              type: 'tracingreport' as TabType,
            }));
            set(patientDetailDialogMessageState, (_state) => ({
              ..._state,
              messageInfoStatus: 'new' as const,
              selectedMessageId: null,
            }));
          }
        }
      },
    [patient, selectedMessageId],
  );

  const handleCreateMedicationFollowupMessage = useRecoilCallback(
    ({ set }) =>
      () => {
        if (patient?.id) {
          set(patientDetailDialogMessageState, (_state) => ({
            ..._state,
            selectedMessageId: null,
          }));
          set(patientDetailDialogState, (_state) => ({
            ..._state,
            patientId: patient?.id,
            isOpen: true,
            type: 'message' as TabType,
          }));
        }
      },
    [patient],
  );

  if (!patient) return null;

  return (
    <CustomHeader>
      <Flex>
        <PatientName isPharmacist={isPharmacist}>
          {patient.__typename === 'Patient' ? (
            <Icon icon="app" size="xxl" mr={theme.space.m} />
          ) : (
            <Icon icon="sms" color="black" size="xxl" mr={theme.space.m} />
          )}
          <Name
            familyName={patient.familyName}
            givenName={patient.givenName}
            phoneticFamilyName={patient.phoneticFamilyName}
            phoneticGivenName={patient.phoneticGivenName}
          />
          {statusManagement && followupStatus === 'in_progress' && (
            <Chip size="fixed" color="blue" marginLeft={theme.space.m}>
              対応中
            </Chip>
          )}
        </PatientName>
      </Flex>
      <Flex
        height="58px"
        alignItems={`${isPharmacist ? 'flex-end' : 'center'}`}
        padding={`${!isPharmacist && theme.space.m}`}
      >
        {isPharmacist ? (
          <Box>
            <Tabs>
              <Tab active={type === 'message'} onClick={handleCreateMedicationFollowupMessage}>
                メッセージ
              </Tab>
              <Tab active={type === 'tracingreport'} onClick={handleCreateTracingReport}>
                トレーシングレポート
              </Tab>
            </Tabs>
          </Box>
        ) : (
          <InfoAlert status="info">
            服薬指導権限がないため、メッセージ作成・トレーシングレポートの作成ができません。
          </InfoAlert>
        )}
      </Flex>
    </CustomHeader>
  );
});

DialogHeder.displayName = 'DialogHeder';
