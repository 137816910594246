import React from 'react';

import { Modal } from '~/components/blocks';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';

import { AdminPanel } from './AdminPanel';
import { GeneralPanel } from './GeneralPanel';

type Props = {
  startAt: Date;
  onClosed: () => void;
};

export const IssuePanel = (props: Props) => {
  const { startAt, onClosed } = props;
  const { verifyRole } = usePractitionerRole();
  const hasClientCertAuthority = verifyRole(['company_admin', 'admin']);

  return (
    <>
      <Modal.Header>クライアント証明書を発行してください</Modal.Header>
      {hasClientCertAuthority ? (
        <AdminPanel startAt={startAt} onClosed={onClosed} />
      ) : (
        <GeneralPanel startAt={startAt} />
      )}
    </>
  );
};
