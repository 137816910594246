import React from 'react';

import { Box, Flex, Name } from '~/components/blocks';
import { WebVisitorFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { ProfileDetail } from './ProfileDetail';
import { ProfileSkeleton } from './ProfileSkeleton';

type Props = {
  visitor?: WebVisitorFragment | null;
};

export const SimpleWebVisitorProfile = React.memo((props: Props) => {
  const { visitor } = props;

  return visitor ? (
    <Box>
      <Flex justifyContent="space-between">
        <Name
          familyName={visitor.familyName || ''}
          givenName={visitor.givenName || ''}
          phoneticFamilyName={visitor.phoneticFamilyName || ''}
          phoneticGivenName={visitor.phoneticGivenName || ''}
          size="l"
        />
      </Flex>
      <ProfileDetail>
        <ProfileDetail.Item>
          <ProfileDetail.Item>{Label.age(visitor.birthDate)}</ProfileDetail.Item>
          <ProfileDetail.Item>（{Label.sex(visitor.sex)}）</ProfileDetail.Item>
        </ProfileDetail.Item>
      </ProfileDetail>
    </Box>
  ) : (
    <ProfileSkeleton />
  );
});

SimpleWebVisitorProfile.displayName = 'SimpleWebVisitorProfile';
