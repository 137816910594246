import styled from '@emotion/styled';
import { ComputedPlacement } from '@popperjs/core';
import { css } from '@styled-system/css';
import { ja, Locale } from 'date-fns/locale';
import React, { useCallback, useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { Flex, Icon } from '..';

const customLocale: Locale = { ...ja, options: { weekStartsOn: 0, firstWeekContainsDate: 1 } };
registerLocale('ja', customLocale);

const Root = styled(Flex)(() =>
  css({
    alignItems: 'center',
    whiteSpace: 'nowrap',
  }),
);

const CalendarIcon = styled('div')(() =>
  css({
    cursor: 'pointer',
    height: '100%',
    lineHeight: '100%',
  }),
);

type Props = {
  calendarIcon?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  value: Date | null;
  onChange: (date: Date | null) => void;
  filterDate?: (date: Date) => boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  placement?: ComputedPlacement;
};

export const InputDate = React.memo((props: Props) => {
  const {
    calendarIcon,
    clearable = true,
    disabled,
    value,
    onChange,
    filterDate,
    minDate,
    maxDate,
    placement,
  } = props;

  const pickerRef = useRef<DatePicker | null>(null);
  const handleClick = useCallback(() => pickerRef.current?.setOpen(true), []);

  return (
    <Root>
      {calendarIcon && (
        <CalendarIcon onClick={handleClick}>
          <Icon size="l" icon="calender" />
        </CalendarIcon>
      )}
      <DatePicker
        ref={pickerRef}
        disabled={disabled}
        dateFormat="yyyy年MM月dd日"
        dateFormatCalendar="yyyy年MM月"
        selected={value}
        onChange={onChange}
        isClearable={clearable}
        locale="ja"
        todayButton="今日"
        placeholderText="日付指定なし"
        filterDate={filterDate}
        minDate={minDate}
        maxDate={maxDate}
        previousMonthButtonLabel=""
        nextMonthButtonLabel=""
        popperPlacement={placement}
      />
    </Root>
  );
});

InputDate.displayName = 'InputDate';

export default InputDate;
