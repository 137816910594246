import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Loader, Modal } from '~/components/blocks';
import { twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';

import { ActivatingPanel } from './ActivatingPanel';
import { ActivationCompletedPanel } from './ActivationCompletedPanel';
import { IntroductionPanel } from './IntroductionPanel';
import { ScanQrCodePanel } from './ScanQrCodePanel';
import { SendCodePanel } from './SendCodePanel';
import { useFetchOtpSecret } from './use-fetch-otp_secret';

export const TwoFactorAuthenticationModal = () => {
  const { loading } = useFetchOtpSecret();

  const { isOpen, currentPanel } = useRecoilValue(twoFactorAuthModalState);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(twoFactorAuthModalState),
    [],
  );

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Header>二要素認証設定{currentPanel === 'completed' && '完了'}</Modal.Header>
      {loading && (
        <Modal.Body>
          <Box height="250px" width="350px">
            <Loader open inside />
          </Box>
        </Modal.Body>
      )}
      {!loading && currentPanel === 'introduction' && <IntroductionPanel />}
      {currentPanel === 'activating' && <ActivatingPanel />}
      {currentPanel === 'scan' && <ScanQrCodePanel />}
      {currentPanel === 'send' && <SendCodePanel />}
      {currentPanel === 'completed' && <ActivationCompletedPanel />}
    </Modal>
  );
};
