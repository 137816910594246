export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE = 10;

export const Panel = {
  initial: 'initial',
  input: 'input',
} as const;

export type PanelType = (typeof Panel)[keyof typeof Panel];

export type Profile = {
  type?: 'DirectVisitor' | 'Patient' | 'WebVisitor';
  receptionId: string;
  familyName: string;
  givenName: string;
  phoneticFamilyName: string;
  phoneticGivenName: string;
  visitDay?: string;
};

export type MessageModal = {
  isOpen: boolean;
  currentPanel: PanelType;
  patientId: string | null;
  profile: Profile | null;
  selectedMessageId: string | null;
  draftMessageId: string | null;
  page: number;
  perPage: number;
  totalPage: number;
};
