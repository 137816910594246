export const isWindows = () => {
  return navigator.userAgent.indexOf('Windows') != -1;
};

export const isIpad = () => {
  // iPadの「デスクトップ用Webサイトを表示」機能では、Mac * Safariと同じUserAgentになるため、
  // standaloneを使って判定する
  return (
    navigator.userAgent.indexOf('iPad') > -1 ||
    ('standalone' in navigator && navigator['standalone'] !== undefined)
  );
};

export const isSafari = () => {
  if (typeof window === 'undefined') return false;

  const userAgent = navigator.userAgent.toLowerCase();

  // Edge/ChromeはUserAgentの中に「safari」を含んでいるので、先に取り除く
  if (userAgent.indexOf('edge') !== -1 || userAgent.indexOf('chrome') !== -1) {
    return false;
  }

  return userAgent.indexOf('safari') !== -1;
};
