import { css, Global, SerializedStyles } from '@emotion/react';
import { normalize } from 'polished';
import React from 'react';

import { theme } from '~/styles/theme';
// import { useAppTheme } from '../themes';
import { ThemeType } from '~/styles/types';

const styles = (theme: ThemeType): SerializedStyles => css`
  ${normalize()}

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    height: 100%;
  }

  body {
    background-color: ${theme.colors.background.bg};
    color: ${theme.colors.text.default};
    font-family: ${theme.fonts.default};
    font-size: ${theme.fontSizes.m};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;
    height: 100%;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  figure,
  figcaption,
  blockquote,
  dl,
  dd,
  dialog {
    margin: 0;
  }

  img {
    border: 0;
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
  }

  input,
  button,
  select,
  textarea {
    font: inherit;
    outline: 0;
  }

  button,
  html [type='button'],
  [type='submit'] {
    appearance: none;
  }
  input {
    -webkit-appearance: none;
  }
  input[type='date']::-webkit-clear-button,
  input[type='month']::-webkit-clear-button,
  input[type='datetime-local']::-webkit-clear-button,
  input[type='time']::-webkit-clear-button,
  input[type='week']::-webkit-clear-button {
    -webkit-appearance: radio;
  }

  select {
    background-color: ${theme.colors.background.default};
  }

  #__next {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
`;

export const GlobalStyle: React.FC = () => {
  // const theme = useAppTheme();

  return <Global styles={styles(theme)} />;
};
