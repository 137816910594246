import { atom } from 'recoil';

import { FollowupMessageSettingModal } from './types';

export const followupMessageSettingModalState = atom<FollowupMessageSettingModal>({
  key: 'partials.followupMessageSetting.modal',
  default: {
    isOpen: false,
    patientId: null,
    guestPatientId: null,
  },
});
