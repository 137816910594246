import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Property } from 'node_modules/csstype';
import { compose, variant } from 'styled-system';

import { Color } from '~/styles/types';

type Props = {
  size?: 'm' | 's';
  use?: Color;
  status?: 'edit';
  whiteSpace?: Property.WhiteSpace;
};

export const Tag = styled('div', {
  shouldForwardProp,
})<Props>(
  ({ theme, whiteSpace }) =>
    css({
      whiteSpace,
      display: 'inline-block',
      borderRadius: theme.radii.default,
      transitionDuration: theme.transitions.fast,
      margin: theme.space.xs,
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          s: css({
            padding: `${theme.space.xs} ${theme.space.s}`,
            fontSize: theme.fontSizes.xs,
          }),
          m: css({
            padding: `${theme.space.xs} ${theme.space.m}`,
            fontSize: theme.fontSizes.m,
            [`@media ${theme.mediaQueries.tablet}`]: {
              fontSize: theme.fontSizes.xs,
            },
          }),
        },
      }),
      variant({
        prop: 'use',
        variants: {
          default: css({
            color: theme.colors.text.default,
            background: theme.colors.background.default,
            border: theme.borders.default,
          }),
          primary: css({
            color: theme.colors.text.secondary,
            background: theme.colors.background.default,
            border: theme.borders.secondary,
          }),
        },
      }),
      variant({
        prop: 'status',
        variants: {
          edit: css({
            cursor: 'pointer',
            '&:hover': css({
              backgroundColor: theme.colors.background.bg,
            }),
            '&:after': css({
              content: '"x"',
              paddingLeft: theme.space.xl,
            }),
            '&:after:hover': css({
              opacity: 0.5,
            }),
          }),
        },
      }),
    ),
);

Tag.defaultProps = {
  size: 'm',
  use: 'default',
};

export const TagGroup = styled('div', {
  shouldForwardProp,
})<{ orientation?: 'vertical' | 'holizonal' }>(
  () =>
    css({
      display: 'flex',
      alignItems: 'start',
      flexWrap: 'wrap',
    }),
  () =>
    compose(
      variant({
        prop: 'orientation',
        variants: {
          holizonal: css({
            flexDirection: 'row',
          }),
          vertical: css({
            flexDirection: 'column',
          }),
        },
      }),
    ),
);

TagGroup.defaultProps = {
  orientation: 'holizonal',
};
