import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';

const Root = styled('dl', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.background.bg,
    padding: theme.space.m,
    borderRadius: theme.radii.default,
    whiteSpace: 'nowrap',
  }),
);

const Left = styled('dt', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    paddingLeft: theme.space.m,
  }),
);

const Right = styled('dd', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    padding: `0 ${theme.space.l}`,
    [`& > label`]: {
      marginRight: theme.space.m,
    },
  }),
);

const Title = styled('p', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    marginBottom: theme.space.s,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
  }),
);

export const SettingList = Object.assign(Root, { Left, Right, Title });
