import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type Props = { children?: React.ReactNode };

export const Portal: React.FC<Props> = (props) => {
  const [bodyElem, setBodyElem] = useState<HTMLBodyElement | null>(null);

  /**
   * devモード起動中はSSRで動くので`Expected server HTML to contain a matching <div> in <div>.`のwarningが
   * でてしまうので、マウント時に`body`があるか確認するようにした
   */
  useEffect(() => {
    const _bodyElem = document.querySelector('body');
    setBodyElem(_bodyElem);

    return () => {
      setBodyElem(null);
    };
  }, []);

  return bodyElem ? createPortal(props.children, bodyElem) : null;
};
