import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { useRouter } from 'next/router';
import { darken } from 'polished';
import React from 'react';

import { Link } from '~/components/blocks';

import { HrefProps } from '../types';

/**
 * [0]はルーティング文字列、以降はオプショナルでメニューをどの時アクティブにするかの文字列
 */
type Props = {
  href: [HrefProps, ...HrefProps[]];
  className?: string;
  children?: React.ReactNode;
};

const Root = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    width: '100%',
    '&:hover': {
      backgroundColor: darken(0.04, theme.colors.background.primary),
    },
  }),
);

const MenuItem = styled('div', {
  shouldForwardProp,
})<{ active?: boolean }>(({ theme, active }) =>
  css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.space.xl} ${theme.space.s}`,
    color: theme.colors.text.white,
    fontSize: theme.fontSizes.m,
    fontWeight: theme.fontWeights.bold,
    cursor: 'pointer',
    transition: theme.transitions.default,
    userSelect: 'none',
    ...(active && {
      backgroundColor: darken(0.1, theme.colors.background.primary),
    }),
    [`@media ${theme.mediaQueries.portrait}`]: {
      padding: `${theme.space.s} ${theme.space.xl}`,
    },
  }),
);

export const MenuLink: React.FC<Props> = (props) => {
  const router = useRouter();
  const isActive = props.href.some((href) =>
    href.exact ? router.pathname === href.path : router.pathname.indexOf(href.path) === 0,
  );

  return (
    <Root className={props.className}>
      <Link href={props.href[0].path}>
        <MenuItem active={isActive}>{props.children}</MenuItem>
      </Link>
    </Root>
  );
};
