import { atom } from 'recoil';

import { TelemedicinePanel } from './types';

export const telemedicinePanelState = atom<TelemedicinePanel>({
  key: 'partials.telemedicine.telemedicinePanel',
  default: {
    isSent: false,
    isUnread: false,
    isOpenChat: false,
    isOpenProfile: true,
  },
});
