import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Flex, Icon, Text } from '~/components/blocks';
import { Map } from '~/components/partials';

import { WIDTH } from '.';

const Root = styled(Flex)(() =>
  css({
    borderRadius: `0 0 20px 20px`,
    flexDirection: 'column',
    justifyContent: 'center',
  }),
);

const PreviewText = styled(Text)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.navy,
    wordBreak: 'break-all',
  }),
);

const Annotation = styled(PreviewText)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.navy,
    fontSize: theme.fontSizes.xxs,
    marginTop: theme.space.m,
  }),
);

const Card = styled(Box)(({ theme }) =>
  css({
    background: theme.colors.background.default,
    padding: theme.space.l,
    borderRadius: theme.radii.default,
  }),
);

type Props = {
  address: string | null;
  phoneNumber: string | null;
  openingHours?: string | null;
  website?: string | null;
  geocoding: {
    lat: number;
    lng: number;
  } | null;
};

export const Footer = React.memo((props: Props) => {
  const theme = useTheme();

  return (
    <Root>
      <Box marginBottom={theme.space.xl} padding={theme.space.l}>
        <PreviewText fontWeight="bold" size="s" marginBottom={theme.space.m}>
          営業時間
        </PreviewText>
        <Card>
          <PreviewText whiteSpace="break-spaces">{props.openingHours || '未設定'}</PreviewText>
        </Card>
        <Annotation>
          ※営業時間は店舗の開店時間であり、服薬指導が可能な日時とは異なる場合がありますのでご了承ください。
        </Annotation>
      </Box>
      <Box>
        <PreviewText
          fontWeight="bold"
          size="s"
          marginBottom={theme.space.m}
          paddingLeft={theme.space.l}
        >
          アクセス
        </PreviewText>
        <Map
          static
          width={WIDTH}
          height={180}
          latitude={props.geocoding?.lat}
          longitude={props.geocoding?.lng}
        />
        <Box padding={theme.space.l}>
          <Flex alignItems="center">
            <Icon icon="map" size="s" color="navy" />
            <PreviewText marginLeft={theme.space.s}>{props.address || '未設定'}</PreviewText>
          </Flex>
          <Flex alignItems="center">
            <Icon icon="tel" size="s" color="navy" />
            <PreviewText marginLeft={theme.space.s}>{props.phoneNumber || '未設定'}</PreviewText>
          </Flex>
          <Flex alignItems="center">
            <Icon icon="url" size="s" color="navy" />
            <PreviewText marginLeft={theme.space.s}>{props.website || '未設定'}</PreviewText>
          </Flex>
        </Box>
      </Box>
    </Root>
  );
});

Footer.displayName = 'Footer';
