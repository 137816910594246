import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Flex, Name, Skeleton } from '~/components/blocks';

export const ProfileSkeleton = () => {
  const theme = useTheme();

  return (
    <>
      <Flex alignItems="center">
        <Skeleton width="24px" />
        <Box marginLeft={theme.space.m}>
          <Name
            size="l"
            familyName={<Skeleton inline width="60px" />}
            givenName={<Skeleton inline width="60px" />}
            phoneticFamilyName={<Skeleton inline width="60px" />}
            phoneticGivenName={<Skeleton inline width="60px" />}
          />
        </Box>
      </Flex>
      <Box marginTop={theme.space.xxs} marginLeft={theme.space.xxl}>
        <Skeleton inline width="60px" height="10px" />
      </Box>
    </>
  );
};
