import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Alert, Button, Flex, Grid, Icon, Loader, ScrollBox, Text } from '~/components/blocks';
import {
  patientDetailDialogState,
  patientDetailDialogTracingreportState,
} from '~/state/partials/patient_detail_dialog/atoms';
import { tracingReportPaneState } from '~/state/partials/tracingreport_panel/atoms';

import { TracingReportForm } from './TracingReportForm';
import { TracingReportRecreateModal } from './TracingReportRecreateModal';
import { Fields } from './types';
import { useCreateTracingReport } from './use-create-tracing-report';
import { useDefaultValues } from './use-default-values';
import { useDeleteTracingReportDraft } from './use-delete-tracing_report_draft';
import { useUpsertTracingReportDraft } from './use-upsert-tracing_report_draft';

type Props = {
  patientType?: string;
};

const Wrap = styled(Grid)(({ theme }) =>
  css({
    height: '100%',
    overflow: 'auto',
    gridTemplateRows: '1fr min-content',
    padding: `${theme.space.l} ${theme.space.l}`,
    borderLeft: theme.borders.default,
  }),
);

const AutoSaveStatusText = styled(Text)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    marginLeft: theme.space.s,
    verticalAlign: 'middle',
  }),
);

export const InputPane = React.memo((props: Props) => {
  const theme = useTheme();

  const [isInfoModal, setIsInfoModal] = useState(true);
  const [isAutoSaved, setIsAutoSaved] = useState(false);
  const [isClearForm, setIsClearForm] = useState(false);
  const [isConfirmationModalState, setIsConfirmationModalState] = useState(false);
  const patientDetailTracingreportState = useRecoilValue(patientDetailDialogTracingreportState);
  const patientDetailState = useRecoilValue(patientDetailDialogState);
  const [{ tracingReportDraftId }, setTracingReportDraftId] =
    useRecoilState(tracingReportPaneState);
  const patientId = props.patientType === 'Patient' ? patientDetailState.patientId : null;
  const guestPatientId =
    props.patientType === 'DirectVisitor' || props.patientType === 'WebVisitor'
      ? patientDetailState.patientId
      : null;
  const {
    defaultValues,
    defaultValuesWithoutDraft,
    tracingReportDraft,
    loadingTracingReportDraft,
  } = useDefaultValues(patientDetailTracingreportState.selectedSheetId, patientId, guestPatientId);
  const isTracingReportDraft = !!tracingReportDraft;

  const { formRef, scrollRef, isCreating, error, create } = useCreateTracingReport(
    props.patientType,
  );
  const {
    upserting,
    error: upsertError,
    upsert,
  } = useUpsertTracingReportDraft(patientId, guestPatientId);
  const {
    error: deleteError,
    setError: setDeleteError,
    deleteDraft,
  } = useDeleteTracingReportDraft(patientId, guestPatientId);

  const handleClickDefault = useCallback(() => {
    formRef.current?.resetForm({ values: defaultValuesWithoutDraft });
    setTracingReportDraftId((_state) => ({ ..._state, tracingReportDraftId: null }));
  }, [defaultValuesWithoutDraft, formRef, setTracingReportDraftId]);
  const handleSend = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);
  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        await create(values);
      } catch {
        // 何もしない
      }
    },
    [create],
  );
  const handleAutoSave = useCallback(
    async (values: Fields) => {
      try {
        await upsert(values, tracingReportDraftId);
      } catch {
        // 何もしない
      }
    },
    [tracingReportDraftId, upsert],
  );
  const deleteAndNew = useCallback(async () => {
    try {
      setIsClearForm(true);
      await deleteDraft();
      handleClickDefault();
      setIsConfirmationModalState(false);
      setIsInfoModal(false);
    } catch {
      // 何もしない
    } finally {
      setIsClearForm(false);
      setIsAutoSaved(false);
    }
  }, [deleteDraft, handleClickDefault]);

  const openNewConfirmation = () => {
    setIsConfirmationModalState(true);
  };
  const closeNewConfirmation = () => {
    setIsConfirmationModalState(false);
    setDeleteError(null);
  };

  return (
    <>
      <Loader open={isCreating || loadingTracingReportDraft} inside appearance="white" />
      <Wrap>
        <ScrollBox ref={scrollRef}>
          {error && <Alert marginBottom={theme.space.l}>{error}</Alert>}
          {isInfoModal && isTracingReportDraft && (
            <Alert status="info" marginBottom="20px" contentWidth="100%">
              <Flex justifyContent="space-between">
                <Text>
                  自動保存したトレーシングレポートを復元しました。
                  <br />
                  新規レポートを作成する場合は「新規作成」ボタンを押してください
                </Text>
                <Button use="default" onClick={openNewConfirmation}>
                  新規作成
                </Button>
              </Flex>
            </Alert>
          )}
          <TracingReportForm
            ref={formRef}
            disabled={isCreating}
            isClearForm={isClearForm}
            initialValues={defaultValues}
            setIsAutoSaved={setIsAutoSaved}
            onSubmit={handleSubmit}
            onAutoSave={handleAutoSave}
          />
          <TracingReportRecreateModal
            isOpen={isConfirmationModalState}
            deleteError={deleteError}
            onClick={deleteAndNew}
            onClose={closeNewConfirmation}
          />
        </ScrollBox>
        <Flex marginTop={theme.space.l} justifyContent="flex-end">
          {isAutoSaved && !upserting && !upsertError ? (
            <Flex alignItems="center" marginRight={theme.space.l}>
              <Icon color="green" size="l" icon="check" />
              <AutoSaveStatusText>変更を保存しました</AutoSaveStatusText>
            </Flex>
          ) : null}
          <Button use="base" onClick={handleSend}>
            <Icon icon="print" size="l" color="white" />
            作成
          </Button>
        </Flex>
      </Wrap>
    </>
  );
});

InputPane.displayName = 'InputPane';
