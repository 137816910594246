import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { Box, Grid } from '~/components/blocks';

type Props = { children: React.ReactNode };

const Content = styled(Box)(() =>
  css({
    overflow: 'auto',
  }),
);

const Footer = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    padding: theme.space.l,
    borderTop: theme.borders.grey,
    boxShadow: `0 0 8px ${theme.colors.background.bg}`,
  }),
);

const Component: React.FC<Props> = (props) => {
  return (
    <Grid height="100%" overflow="auto" gridTemplateRows="1fr min-content">
      {props.children}
    </Grid>
  );
};

export const NotificationPanelBase = Object.assign(Component, { Content, Footer });
