import { useTheme } from '@emotion/react';
import React from 'react';

import { Icon, List, Text } from '~/components/blocks';
import { Label } from '~/utils/label';

type Props = {
  sendAt: string;
  onClick?: () => void;
};

export const Message = React.memo((props: Props) => {
  const theme = useTheme();

  return (
    <List.Item onClick={props.onClick}>
      <Icon icon="comment" size="l" />
      <Text size="s" marginLeft={theme.space.s}>
        {`${Label.YYYYMMDDja(props.sendAt)} ${Label.HHMM(props.sendAt)}`}
      </Text>
    </List.Item>
  );
});

Message.displayName = 'Message';
