import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Alert, Box, Text } from '~/components/blocks';
import { PREFECTURES } from '~/constants/prefectures';
import { DispensingPharmacyFragment } from '~/graphql';

import { List } from './List';

type Props = {
  pharmacy?: DispensingPharmacyFragment | null;
};

const Address = styled('ul')(({ theme }) =>
  css({
    display: 'flex',
    '& > li + li ': {
      marginLeft: theme.space.s,
    },
  }),
);

const Profile = styled('ul')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
  }),
);

const ProfileItem = styled('li')(({ theme }) =>
  css({
    '& + & ': {
      marginTop: theme.space.s,
    },
  }),
);

const StaffLabel = styled('dt')(({ theme }) =>
  css({
    fontWeight: theme.fontWeights.bold,
  }),
);

const StaffItem = styled('dd')(({ theme }) =>
  css({
    marginLeft: theme.space.m,
  }),
);

const Staff = styled('dl')(({ theme }) =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontSize: theme.fontSizes.s,
    marginTop: theme.space.s,
    [`& > ${StaffItem} + ${StaffLabel}`]: {
      marginLeft: theme.space.m,
    },
  }),
);

export const DispensingPharmacy = React.memo((props: Props) => {
  const theme = useTheme();
  const pharmacy = props.pharmacy?.pharmacyRecord;
  const pharmacist = props.pharmacy?.pharmacistRecord;

  return (
    <Box>
      <Text size="s" fontWeight="bold">
        【調剤医療機関】
      </Text>
      {pharmacy ? (
        <Box marginTop={theme.space.s} paddingLeft={theme.space.m}>
          <Profile>
            {pharmacy.name && (
              <ProfileItem>
                <List>
                  <List.Item>{pharmacy.name}</List.Item>
                  {pharmacy.prefecture && <List.Item>{PREFECTURES[pharmacy.prefecture]}</List.Item>}
                </List>
              </ProfileItem>
            )}
            {pharmacy?.postalCode && pharmacy.address && (
              <ProfileItem>
                <Address>
                  <li>{`〒${pharmacy.postalCode}`}</li>
                  <li>{pharmacy.address}</li>
                </Address>
              </ProfileItem>
            )}
            {pharmacy.tel && <ProfileItem>{pharmacy.tel}</ProfileItem>}
          </Profile>
          <Staff>
            {pharmacist?.name && (
              <>
                <StaffLabel>担当医師・薬剤師</StaffLabel>
                <StaffItem>{pharmacist.name}</StaffItem>
              </>
            )}
            {pharmacist?.contact && (
              <>
                <StaffLabel>担当連絡先</StaffLabel>
                <StaffItem>{pharmacist.contact}</StaffItem>
              </>
            )}
          </Staff>
        </Box>
      ) : (
        <Alert status="info" marginTop={theme.space.m}>
          調剤医療機関情報がありません
        </Alert>
      )}
    </Box>
  );
});

DispensingPharmacy.displayName = 'DispensingPharmacy';
