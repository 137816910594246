import React, { memo } from 'react';

import { Box, Text } from '~/components/blocks';
import {
  PrecautionRecordFragment,
  PrescriptionMemoRecordFragment,
  RemainingRecordFragment,
  RemarkRecordFragment,
  WarningRecordFragment,
} from '~/graphql';
import { Label } from '~/utils/label';

import { ONLY_PERSON, toCreator } from '../creator';
import { MedicineNoteTable } from '../MedicineNoteTable';

type Props = {
  precautions?: PrecautionRecordFragment[];
  warings?: WarningRecordFragment[];
  remainings?: RemainingRecordFragment[];
  remarks?: RemarkRecordFragment[];
  memos?: PrescriptionMemoRecordFragment[];
};

const toWarningType = (type: number) => {
  switch (type) {
    case 30:
      return '入院中に副作用が発現した薬剤に関する情報';
    case 31:
      return '退院後の療養を担う保険医療機関での投薬又は保険薬局での調剤に必要な服薬の状況及び投薬上の工夫に関する情報';
    case 99:
      return 'その他';
    default:
      return '不明';
  }
};

export const SpecialNote = React.memo((props: Props) => {
  const { precautions, warings, remainings, remarks, memos } = props;

  return (
    <Box>
      <Text size="m" fontWeight="bold">
        【特記事項】
      </Text>
      <MedicineNoteTable appearance="detail">
        <MedicineNoteTable.Tbody>
          {precautions && precautions.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top={precautions.length > 1}>服用注意</MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {precautions.map((precaution, idx) => (
                    <li key={idx}>
                      {precaution.content}
                      {precaution.creator &&
                        ONLY_PERSON.includes(precaution.creator) &&
                        `（${toCreator(precaution.creator)}）`}
                    </li>
                  ))}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
          {warings && warings.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top>医療機関等提供情報</MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {warings.map((warning, idx) => (
                    <li key={idx}>
                      {warning.content}
                      {warning.type && warning.type !== 99 && `（${toWarningType(warning.type)}）`}
                    </li>
                  ))}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
          {remainings && remainings.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top={remainings.length > 1}>残薬確認</MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {remainings.map((remaining, idx) => (
                    <li key={idx}>
                      {remaining.content}
                      {remaining.creator &&
                        ONLY_PERSON.includes(remaining.creator) &&
                        `（${toCreator(remaining.creator)}）`}
                    </li>
                  ))}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
          {remarks && remarks.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top={remarks.length > 1}>備考</MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {remarks.map((remark, idx) => (
                    <li key={idx}>
                      {remark.content}
                      {remark.creator && ONLY_PERSON.includes(remark.creator)
                        ? `（${toCreator(remark.creator)}）`
                        : ''}
                    </li>
                  ))}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
          {memos && memos.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top={memo.length > 1}>患者記入</MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {memos.map((memo, idx) => (
                    <ul key={idx}>
                      {memo.content}
                      {memo.enteredAt && `（${Label.YYYYMMDDja(memo.enteredAt)})`}
                    </ul>
                  ))}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
        </MedicineNoteTable.Tbody>
      </MedicineNoteTable>
    </Box>
  );
});

SpecialNote.displayName = 'SpecialNote';
