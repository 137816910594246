import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { ComponentProps } from 'react';
import { compose, space, SpaceProps, variant, WidthProps } from 'styled-system';

import { FieldError } from '~/components/blocks';

const TextFieldWrap = styled('div')(() =>
  css({
    width: '100%',
  }),
);

const InputField = styled('input', {
  shouldForwardProp,
})<
  WidthProps &
    SpaceProps & {
      appearance?: 'default' | 'search';
      error?: string | boolean;
      withIcon?: 'general' | 'left';
    }
>(
  ({ theme }) =>
    css({
      width: '100%',
      fontSize: theme.fontSizes.m,
      padding: `${theme.space.m} ${theme.space.l}`,
      border: theme.borders.transparent,
      borderRadius: theme.radii.default,
      backgroundColor: theme.colors.background.bg,
      color: theme.colors.text.default,
      transition: theme.transitions.default,
      [`@media ${theme.mediaQueries.tablet}`]: {
        fontSize: theme.fontSizes.s,
      },
      outline: 0,
      '&::placeholder': {
        color: theme.colors.text.placeholder,
      },
      '&:hover': {
        boxShadow: 0,
        border: theme.borders.primary,
      },
      '&:focus': {
        boxShadow: 0,
        border: theme.borders.primary,
        backgroundColor: theme.colors.background.default,
      },
      '&:disabled': {
        border: theme.borders.transparent,
        opacity: 0.6,
        cursor: 'not-allowed',
      },
    }),
  ({ theme, error }) =>
    error &&
    css({
      backgroundColor: theme.colors.background.alert,
      color: theme.colors.text.error,
      '&::placeholder': {
        color: theme.colors.text.placeholder,
      },
      '&:hover': {
        boxShadow: 0,
        border: theme.borders.error,
      },
      '&:focus': {
        boxShadow: 0,
        border: theme.borders.error,
        backgroundColor: theme.colors.background.default,
      },
      ':disabled': {
        border: theme.borders.transparent,
        opacity: 0.6,
        cursor: 'not-allowed',
      },
    }),
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'appearance',
        variants: {
          search: css({
            padding: `${theme.space.s} ${theme.space.s}`,
            width: 'auto',
            backgroundColor: theme.colors.background.transparent,
            '&:hover': {
              border: theme.borders.transparent,
            },
            '&:focus': {
              border: theme.borders.transparent,
            },
          }),
        },
      }),
      variant({
        prop: 'width',
        variants: {
          s: css({
            width: '8rem',
          }),
        },
      }),
    ),
);

export const TextField = React.forwardRef(
  (props: ComponentProps<typeof InputField>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <TextFieldWrap>
        <InputField {...props} ref={ref} />
        {typeof props.error === 'string' && <FieldError error={props.error} />}
      </TextFieldWrap>
    );
  },
);

TextField.displayName = 'TextField';

export const TextFieldGroup = styled('div', {
  shouldForwardProp,
})<{ orientation?: 'vertical' | 'holizonal' }>(
  () =>
    css({
      display: 'flex',
      alignItems: 'start',
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'orientation',
        variants: {
          holizonal: css({
            flexDirection: 'row',
            [`& > ${TextFieldWrap} + ${TextFieldWrap}`]: {
              marginLeft: theme.space.m,
            },
          }),
          vertical: css({
            flexDirection: 'column',
            [`& > ${TextFieldWrap} + ${TextFieldWrap}`]: {
              marginTop: theme.space.m,
            },
          }),
        },
      }),
    ),
);

TextFieldGroup.defaultProps = {
  orientation: 'holizonal',
};
