import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Text } from '~/components/blocks';
import { Label } from '~/utils/label';

const Root = styled(Box)(({ theme }) =>
  css({
    marginTop: theme.space.m,
    padding: theme.space.m,
    background: theme.colors.background.default,
    borderRadius: theme.radii.default,
  }),
);

type Props = {
  deliveredAt: string;
};

export const DeliveredTextBox = React.memo((props: Props) => {
  const { deliveredAt } = props;
  return (
    <Root color="white">
      <Text size="s">
        <b>配達完了</b>： {`${Label.YYYYMMDDja(deliveredAt)} ${Label.HHMM(deliveredAt)}`}
      </Text>
    </Root>
  );
});

DeliveredTextBox.displayName = 'DeliveredTextBox';
