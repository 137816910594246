import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Icon } from '~/components/blocks';

export const AddNewItemButton = styled(
  ({
    className,
    disabled,
    onClick,
    label,
  }: {
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    label: string;
  }) => (
    <button className={className} disabled={disabled} onClick={onClick}>
      <Icon icon="plus" size="l" />
      {label}
    </button>
  ),
)(({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.background.default,
    border: `1px dashed ${theme.colors.border.default}`,
    padding: `${theme.space.m} ${theme.space.l}`,
    borderRadius: theme.radii.default,
    color: theme.colors.text.default,
    lineHeight: theme.lineHeights.s,
    outline: 'none',
    marginTop: theme.space.l,
    transitionDuration: theme.transitions.fast,
    '&:hover': {
      background: theme.colors.background.bg,
      cursor: 'pointer',
    },
    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
);
