import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Flex, InputMonth } from '~/components/blocks';

import { CustomInputMonth } from './CustomInputMonth';

type Props = {
  disabled?: boolean;
  clearable?: boolean;
  value: Date | null;
  maxDate?: Date | null;
  minDate?: Date | null;
  onChange: (date: Date | null) => void;
};

const Root = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    borderRadius: theme.radii.default,
    paddingLeft: theme.space.m,
    border: theme.borders.default,
    fontWeight: theme.fontWeights.bold,
    transitionDuration: theme.transitions.default,
    '&:focus-within': {
      backgroundColor: theme.colors.background.default,
      borderColor: theme.colors.border.primary,
    },
    '& .react-datepicker__input-container input': {
      border: 'none',
      backgroundColor: 'transparent',
      maxWidth: '130px',
      lineHeight: '30px',
    },
  }),
);

export const SelectMonth = React.memo((props: Props) => {
  const { clearable = false, value, onChange, disabled, maxDate, minDate } = props;

  return (
    <Root>
      <InputMonth
        calendarIcon
        clearable={clearable}
        disabled={disabled}
        customInput={<CustomInputMonth />}
        value={value}
        onChange={onChange}
        maxDate={maxDate}
        minDate={minDate}
      />
    </Root>
  );
});

SelectMonth.displayName = 'SelectMonth';
