import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { Text } from '../Text';

type Props = {
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Wrapper = styled('label', {
  shouldForwardProp,
})(() =>
  css({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: 'pointer',
  }),
);

const Slider = styled('div')(({ theme }) =>
  css({
    position: 'relative',
    display: 'block',
    width: '60px',
    height: '32px',
    backgroundColor: theme.colors.background.disabled,
    transition: theme.transitions.default,
    borderRadius: theme.radii.half,
    '&:before': {
      position: 'absolute',
      left: '4px',
      bottom: '3px',
      content: '""',
      height: '26px',
      width: '26px',
      backgroundColor: theme.colors.background.default,
      transition: theme.transitions.default,
      borderRadius: theme.radii.circle,
    },
  }),
);

const Input = styled('input')(({ theme }) =>
  css({
    display: 'none',
    [`:checked + ${Slider}`]: {
      backgroundColor: theme.colors.background.primary,
    },
    [`:focus + ${Slider}`]: {
      border: `1px solid theme.colors.primary`,
    },
    [`:checked + ${Slider}:before`]: {
      transform: 'translateX(26px)',
    },
    [`:disabled + ${Slider}`]: {
      cursor: 'not-allowed',
      '&:before': {
        opacity: 0.5,
      },
    },
  }),
);

export const Toggle = (props: Props) => {
  const theme = useTheme();

  return (
    <Wrapper>
      <Input
        type="checkbox"
        disabled={props.disabled}
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
      />
      <Slider />
      {props.label && <Text ml={theme.space.s}>{props.label}</Text>}
    </Wrapper>
  );
};
