import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';

import { Box, Icon, Text } from '~/components/blocks';
import { AppPatientProfileFragment } from '~/graphql';
import { Label } from '~/utils/label';
import { getPfEmail, getPfTel } from '~/utils/pf_contact_point';

import { ContactList } from './ContactList';
import { ProfileDetailAppSkeleton } from './ProfileDetailAppSkeleton';

type Props = {
  loading: boolean;
  appPatient?: AppPatientProfileFragment | null;
};

const getFamily = (pfPatient: AppPatientProfileFragment) => {
  const parent = pfPatient.parent;
  const family = pfPatient.family;

  if (!parent) {
    return family;
  }

  return [parent, ...family].filter(({ id }) => id !== pfPatient.id);
};

export const ProfileDetailApp = React.memo((props: Props) => {
  const { loading, appPatient } = props;
  const theme = useTheme();

  const address = appPatient?.addresses[0];
  const tel = useMemo(() => (appPatient ? getPfTel(appPatient.telecoms) : '未登録'), [appPatient]);
  const email = useMemo(
    () => (appPatient ? getPfEmail(appPatient.telecoms) : '未登録'),
    [appPatient],
  );
  const family = useMemo(() => (appPatient ? getFamily(appPatient) : []), [appPatient]);

  return (
    <Box
      backgroundColor={theme.colors.background.bg}
      padding={`${theme.space.m} ${theme.space.l}`}
      marginBottom={theme.space.l}
    >
      {loading || !appPatient ? (
        <ProfileDetailAppSkeleton />
      ) : (
        <>
          <ContactList>
            <ContactList.Item>
              <Icon size="m" icon="tel" />
              {tel || '未登録'}
            </ContactList.Item>
            <ContactList.Item>
              <Icon size="m" icon="mail" />
              {email || '未登録'}
            </ContactList.Item>
            <ContactList.Item>
              <Icon size="m" icon="address" />
              {address ? (
                <Text>
                  {address.postalCode}
                  <br />
                  {`${address.prefecture}${address.city}${address.line}`}
                </Text>
              ) : (
                '未登録'
              )}
            </ContactList.Item>
            <ContactList.Item>
              <Icon size="m" icon="people" />
              {appPatient.birthDate ? Label.warekiBirthDate(appPatient.birthDate) : '未登録'}
            </ContactList.Item>
          </ContactList>
          {family && family.length > 0 && (
            <Box bg={theme.colors.background.bg} padding={theme.space.m} mb={theme.space.l}>
              <Text size="xs">家族アカウント</Text>
              <ul>
                {family.map((f) => (
                  <li key={f.id}>
                    <Text size="s" fontWeight="bold">
                      ・{`${f.familyName}${f.givenName}`}
                    </Text>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </>
      )}
    </Box>
  );
});

ProfileDetailApp.displayName = 'ProfileDetailApp';
