import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { compose, space, SpaceProps, variant } from 'styled-system';

import { Icon } from '~/components/blocks';

type Props = SpaceProps & {
  size?: 'l' | 'xl';
  color?: 'black' | 'pink';
};

const Root = styled('div', {
  shouldForwardProp,
})<Props>(
  ({ theme, color }) =>
    css({
      alignItems: 'center',
      border: `1px ${
        color === 'pink' ? theme.colors.colorPallete.pink : theme.colors.colorPallete.grey03
      } solid`,
      background: theme.colors.background.default,
      borderRadius: theme.radii.circle,
      display: 'flex',
      justifyContent: 'center',
      minWidth: '38px',
      height: '38px',
      width: '38px',
    }),

  () =>
    compose(
      space,
      variant({
        prop: 'size',
        variants: {
          l: css({
            minWidth: '30px',
            height: '30px',
            width: '30px',
          }),
        },
      }),
    ),
);

export const MessageIcon = React.memo((props: Props) => {
  return (
    <Root {...props}>
      <Icon icon="comment" size={props.size || 'xl'} color={props.color || 'black'} />
    </Root>
  );
});

MessageIcon.displayName = 'MessageIcon';
