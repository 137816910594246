import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import Div100vh from 'react-div-100vh';

import { SnackbarGroup } from '~/components/blocks';
import { ClientAuthenticationModal } from '~/components/layouts/SharedAppShell/ClientAuthenticationModal';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';

import { AccessPermissionModal } from './AccessPermissionModal';
import { AgentNotifications } from './AgentNotifications';
import { AnnouncementModal } from './AnnouncementModal';
import { FollowupMessageNotifications } from './FollowupMessageNotifications';
import { Header } from './Header';
import { RenewClientCertModal } from './RenewClientCertModal';
import { SendFaxNotifications } from './SendFaxNotifications';
import { TelemedicineNotifications } from './TelemedicineNotifications';
import { TwoFactorAuthenticationModal } from './TwoFactorAuthenticationModal';
import { UberDeliveryNotifications } from './UberDeliveryNotifications';

type Props = { children?: React.ReactNode };

const Root = styled(Div100vh)(() =>
  css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'auto',
  }),
);

const Main = styled('div')(({ theme }) =>
  css({
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `
      "menu content"
    `,
    overflow: 'auto',
    [`@media ${theme.mediaQueries.portrait}`]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr min-content',
      gridTemplateAreas: `
      "content"
      "menu"
    `,
    },
  }),
);

const SideMenu = styled('div')(() =>
  css({
    gridArea: 'menu',
    overflow: 'auto',
  }),
);

const Content = styled('div')(({ theme }) =>
  css({
    position: 'relative',
    gridArea: 'content',
    padding: `${theme.space.l} ${theme.space.xl}`,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: theme.space.l,
    },
    [`@media ${theme.mediaQueries.portrait}`]: {
      padding: theme.space.m,
    },
  }),
);

const AppShell: React.FC<Props> = ({ children }) => {
  const { loadingRole, verifyRole } = usePractitionerRole();
  const isVerified = verifyRole('pharmacist');

  return (
    <>
      <Root>
        <Header />
        <Main>{children}</Main>
      </Root>
      <AnnouncementModal />
      <AccessPermissionModal />
      <TwoFactorAuthenticationModal />
      <SnackbarGroup>
        <TelemedicineNotifications />
        {isVerified && !loadingRole && <SendFaxNotifications />}
        {isVerified && !loadingRole && <FollowupMessageNotifications />}
        <UberDeliveryNotifications />
        <AgentNotifications />
      </SnackbarGroup>
      <ClientAuthenticationModal />
      <RenewClientCertModal />
    </>
  );
};

const SharedAppShell = Object.assign(AppShell, { SideMenu, Content });

export { SharedAppShell };
