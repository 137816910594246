import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { compose, variant } from 'styled-system';

import { ThemeProps } from '~/styles/types';

type Props = {
  familyName?: React.ReactNode;
  givenName?: React.ReactNode;
  phoneticFamilyName?: React.ReactNode;
  phoneticGivenName?: React.ReactNode;
  name?: React.ReactNode;
  phoneticName?: React.ReactNode;
  size?: 's' | 'm' | 'l' | 'xl';
};

const Kana = styled('div')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.default,
    lineHeight: theme.lineHeights.s,
    marginBottom: theme.space.s,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.xxs,
    },
  }),
);

const Kanji = styled('div')<ThemeProps>(({ theme }) =>
  css({
    fontSize: '1.8rem',
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.s,
    },
  }),
);

const Root = styled('div', {
  shouldForwardProp,
})<ThemeProps & Props>(
  ({ theme }) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      fontWeight: theme.fontWeights.bold,
      lineHeight: theme.lineHeights.s,
      minWidth: '10rem',
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          s: css({
            [`& > ${Kana}`]: {
              fontSize: theme.fontSizes.xxs,
            },
            [`& > ${Kanji}`]: {
              fontSize: theme.fontSizes.m,
              [`@media ${theme.mediaQueries.tablet}`]: {
                fontSize: theme.fontSizes.m,
              },
            },
          }),
          m: css({
            [`& > ${Kanji}`]: {
              fontSize: theme.fontSizes.m,
              [`@media ${theme.mediaQueries.tablet}`]: {
                fontSize: theme.fontSizes.m,
              },
              fontWeight: theme.fontWeights.default,
            },
          }),
          l: css({
            [`& > ${Kanji}`]: {
              fontSize: theme.fontSizes.l,
              [`@media ${theme.mediaQueries.tablet}`]: {
                fontSize: '1.8rem',
              },
            },
          }),
          xl: css({
            [`& > ${Kanji}`]: {
              fontSize: theme.fontSizes.xl,
              [`@media ${theme.mediaQueries.tablet}`]: {
                fontSize: theme.fontSizes.l,
              },
            },
          }),
        },
      }),
    ),
);

export const Name = React.memo((props: Props) => {
  return (
    <Root {...props}>
      {props.phoneticName ? (
        <Kana>{props.phoneticName}</Kana>
      ) : (
        <Kana>
          {props.phoneticFamilyName}&nbsp;{props.phoneticGivenName}
        </Kana>
      )}
      {props.name ? (
        <Kanji>{props.name}</Kanji>
      ) : (
        <Kanji>
          {props.familyName}&nbsp;{props.givenName}
        </Kanji>
      )}
    </Root>
  );
});

Name.displayName = 'Name';
