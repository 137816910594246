import React from 'react';

import { Button, Icon, Text } from '~/components/blocks';

type Props = {
  handleUnidentify: () => void;
  rescinded: boolean;
  unidentifying: boolean;
};

export const UnidentifyButton = React.memo((props: Props) => {
  const { handleUnidentify, rescinded, unidentifying } = props;

  return (
    <Button
      size="s"
      use="white"
      marginLeft="s"
      onClick={handleUnidentify}
      disabled={!rescinded || unidentifying}
    >
      <Icon size="m" icon="not-sync" color="blue" />
      <Text>解除</Text>
    </Button>
  );
});

UnidentifyButton.displayName = 'UnidentifyButton';
