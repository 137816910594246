import { useEffect, useMemo } from 'react';

import {
  ReceptionPaneAppointmentFragment,
  useGetReceptionPaneAppointmentLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { useSubscriptions } from '~/hooks/use-subscriptions';

type Appointment = {
  loading: boolean;
  appointment?: ReceptionPaneAppointmentFragment;
};

export const useFetchAppointment = (appointmentId: string): Appointment => {
  const [event, clear] = useSubscriptions('Appointment', ['updated']);

  const [getAppointment, { loading, data }] = useGetReceptionPaneAppointmentLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (event) {
        clear();
      }
    },
  });

  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const appointment = useMemo(() => {
    const _appointment = getNode(currentData, 'Appointment');

    if (_appointment?.id === appointmentId) {
      return _appointment;
    }
  }, [appointmentId, currentData]);

  useEffect(() => {
    if (appointmentId) {
      getAppointment({ variables: { appointmentId } });
    }
  }, [appointmentId, getAppointment]);

  useEffect(() => {
    if (event?.actionName === 'updated' && event.typeId === appointmentId) {
      getAppointment({ variables: { appointmentId } });
    }
  }, [appointmentId, event?.actionName, event?.typeId, getAppointment]);

  return { loading, appointment };
};
