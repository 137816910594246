import { object, ObjectSchema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';
import { onetimeCodeRegexp } from '~/constants/regexp';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  code: string()
    .label('ワンタイムコード')
    .matches(onetimeCodeRegexp, FormErrorMessage.alpha)
    .max(200, FormErrorMessage.maxLengthRequired)
    .required(FormErrorMessage.required),
});
