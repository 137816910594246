import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { Fields } from '~/components/partials/GuestPatientForm/types';
import { Sex, useEditDirectVisitorMutation } from '~/graphql';
import { useScrollToTop } from '~/hooks/use-scroll-to-top';
import { patientDetailDialogProfileState } from '~/state/partials/patient_detail_dialog/atoms';

export const useUpdateGuestPatient = () => {
  const [containerRef, scrollToTop] = useScrollToTop<HTMLDivElement>();

  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const setState = useSetRecoilState(patientDetailDialogProfileState);

  const [updateGuestPatient] = useEditDirectVisitorMutation();

  const update = useCallback(
    async (values: Fields, guestPatientId: string) => {
      setIsUpdating(true);
      setError(null);

      const sex =
        values.sex === 'male' ? Sex.Male : values.sex === 'female' ? Sex.Female : Sex.Unknown;

      try {
        await updateGuestPatient({
          variables: {
            input: {
              ...values,
              directVisitorId: guestPatientId,
              sex,
            },
          },
        });
        setState((_state) => ({ ..._state, type: null }));
      } catch (error) {
        setError(error?.message || 'エラーが発生しました');
        scrollToTop();
      }
      setIsUpdating(false);
    },
    [updateGuestPatient, scrollToTop, setState],
  );

  return {
    isUpdating,
    error,
    containerRef,
    update,
  };
};
