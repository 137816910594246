import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Item = styled('li')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.m,
    fontWeight: theme.fontWeights.bold,
  }),
);

const Root = styled('ul')(({ theme }) =>
  css({
    display: 'flex',
    [`& > ${Item} + ${Item}`]: {
      marginLeft: theme.space.m,
    },
    [`& > ${Item}:last-child`]: {
      marginLeft: 0,
    },
  }),
);

export const Profile = Object.assign(Root, { Item });
