import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { CSSProperties, useMemo } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

import { CloseBtn } from '../CloseBtn';
import { Overlay } from '../Overlay';
import { Body } from './Body';
import { DialogContext } from './DialogContext';
import { Footer } from './Footer';
import { Header } from './Header';

type Props = {
  open: boolean;
  invert?: boolean;
  children: React.ReactNode;
  onClose: () => void;
};

const Close = styled(CloseBtn)<{ invert?: boolean }>(({ theme, invert }) =>
  css({
    position: 'absolute',
    top: `calc(${theme.space.m} + ${theme.space.s} + ${theme.space.xs})`,
    left: theme.space.l,
    border: 'none',
    ...(invert && {
      backgroundColor: theme.colors.background.black,
      '&:before': css({
        backgroundColor: theme.colors.text.white,
      }),
      '&:after': css({
        backgroundColor: theme.colors.text.white,
      }),
      '&:hover': css({
        backgroundColor: theme.colors.background.black,
      }),
    }),
  }),
);

const Root = styled('div', {
  shouldForwardProp,
})<{ invert?: boolean }>(({ theme, invert }) =>
  css({
    margin: '0 auto',
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100vw',
    flexDirection: 'column',
    backgroundColor: invert ? theme.colors.colorPallete.black : theme.colors.background.default,
    boxShadow: theme.shadows.default,
    transition: `opacity ${theme.transitions.fast} ease-in-out`,
    opacity: 0,
    overflow: 'auto',
  }),
);

const Wrapper = styled('div')(() =>
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};

const RootComponent: React.FC<Props> = (props) => {
  const { onClose } = props;

  const dialogContext = useMemo(
    () => ({
      invert: props.invert,
    }),
    [props.invert],
  );

  return (
    <DialogContext.Provider value={dialogContext}>
      <Transition in={props.open} timeout={0}>
        {(state) => (
          <Overlay open={props.open}>
            <Wrapper>
              <Root
                invert={props.invert}
                style={{
                  ...transitionStyles[state],
                }}
              >
                {props.children}
                {onClose && <Close invert={props.invert} onClick={onClose} />}
              </Root>
            </Wrapper>
          </Overlay>
        )}
      </Transition>
    </DialogContext.Provider>
  );
};

export const Dialog = Object.assign(RootComponent, { Header, Body, Footer });
