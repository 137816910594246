import React, { useCallback } from 'react';

import { Button, Icon, Tooltip } from '~/components/blocks';
import { DEFAULT_WINDOW } from '~/components/partials/MedicineNotesContainer';
import { AppointmentStatus, ReceptionPaneHiccupAppointmentPermissionFragment } from '~/graphql';
import { openWithNoOpener } from '~/utils/window';

type Props = {
  patientId: string;
  permission?: ReceptionPaneHiccupAppointmentPermissionFragment;
  status: AppointmentStatus;
  isActivePatient: boolean;
};

export const MedicineNoteButton = React.memo((props: Props) => {
  const { patientId, isActivePatient, permission } = props;

  const handleClick = useCallback(() => {
    openWithNoOpener(`/medicinenote/patients/${patientId}`, {
      target: patientId,
      width: DEFAULT_WINDOW.width,
      height: DEFAULT_WINDOW.height,
    });
  }, [patientId]);

  if (!isActivePatient) {
    return (
      <Tooltip
        content={
          <>
            退会済のため、
            <br />
            お薬手帳の閲覧はできません
          </>
        }
      >
        <div>
          <Button wide="fill" disabled>
            <Icon icon="medicalnote" size="xxl" />
            お薬手帳
          </Button>
        </div>
      </Tooltip>
    );
  }

  return permission ? (
    <Tooltip
      content={
        <>
          会計完了または2週間経過した
          <br />
          お薬手帳は閲覧できません
        </>
      }
    >
      <div>
        <Button
          wide="fill"
          disabled={!permission.active}
          onClick={permission.active ? handleClick : undefined}
        >
          <Icon icon="medicalnote" size="xxl" />
          お薬手帳
        </Button>
      </div>
    </Tooltip>
  ) : (
    <Tooltip
      content={
        <>
          患者のCLINICSアプリにて、
          <br />
          お薬手帳の閲覧が許可されていないため
          <br />
          表示できません
        </>
      }
    >
      <div>
        <Button wide="fill" disabled>
          <Icon icon="medicalnote" size="xxl" />
          お薬手帳
        </Button>
      </div>
    </Tooltip>
  );
});

MedicineNoteButton.displayName = 'MedicineNoteButton';
