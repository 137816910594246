import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { useClientCertVerification } from '~/components/partials/ClientCertVertification/use-client_cert_verification';
import { ClientCertVerificationStatus, useGetClientCertRenewableStatusLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import { getClientCertIgnorePeriod } from '~/localstrage/client_cert_ignore_period';
import { renewClientCertModalState } from '~/state/layouts/SharedAppShell/atoms';
import { RenewableTarget } from '~/state/layouts/SharedAppShell/types';

export const useFetchRenewableClientCert = () => {
  const { verifyRole } = usePractitionerRole();
  const isCompanyAdmin = verifyRole(['company_admin']);
  const isAdmin = verifyRole(['admin']);

  const [{ isClosed }, setState] = useRecoilState(renewClientCertModalState);
  const { pathname } = useRouter();
  const { status, clientCert } = useClientCertVerification(pathname);
  const [fetch, { data }] = useGetClientCertRenewableStatusLazyQuery({
    fetchPolicy: 'network-only',
  });
  const organization = data ? getMe(data)?.organization : null;
  const practitionerId = data ? getMe(data)?.id || '' : '';
  const renewableOrganization = !!organization?.clientCerts.nodesCount;
  const renewableCompany = !!organization?.company.clientCertSummaries.nodesCount;
  const ignore = useMemo(() => {
    const period = getClientCertIgnorePeriod(practitionerId);
    const periodAt = period ? new Date(period) : null;
    return (periodAt && periodAt > new Date()) || false;
  }, [practitionerId]);

  useEffect(() => {
    if (status == ClientCertVerificationStatus.Ok && !isClosed) {
      fetch();
    }
  }, [fetch, isClosed, status]);

  useEffect(() => {
    // 以下の場合は、何も表示しない
    // ・クライアント証明書が有効でない
    // ・モーダルが閉じられている場合は何も表示しない
    // ・「30日間この通知を表示しない」にチェックしている
    if (status != ClientCertVerificationStatus.Ok || isClosed || ignore) {
      return;
    }

    // ログインユーザのクライアント証明書が要更新の場合
    if (!!clientCert?.renewable && !isClosed) {
      setState((_state) => ({
        ..._state,
        isOpen: true,
        isAdmin,
        isCompanyAdmin,
        renewableTarget: RenewableTarget.practitioner,
        clientCert: clientCert,
        practitionerId,
      }));
      return;
    }

    // 店舗内に要更新のクライアント証明書が存在する場合
    if (isAdmin && renewableOrganization) {
      setState((_state) => ({
        ..._state,
        isOpen: true,
        isAdmin,
        isCompanyAdmin,
        renewableTarget: RenewableTarget.organization,
        clientCert: null,
        practitionerId,
      }));
      return;
    }

    // 法人内に要更新のクライアント証明書が存在する場合
    if (isCompanyAdmin && renewableCompany) {
      setState((_state) => ({
        ..._state,
        isOpen: true,
        isAdmin,
        isCompanyAdmin,
        renewableTarget: RenewableTarget.company,
        clientCert: null,
        practitionerId,
      }));
    }
  }, [
    status,
    clientCert,
    setState,
    isClosed,
    isAdmin,
    isCompanyAdmin,
    renewableOrganization,
    renewableCompany,
    practitionerId,
    ignore,
  ]);
};
