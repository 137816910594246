import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';

export const Item = styled('li', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    color: theme.colors.text.default,
    transition: theme.transitions.default,
    display: 'block',
    m: 0,
    p: 0,
    listStyle: 'none',
    cursor: 'pointer',
    borderBottom: theme.borders.default,
    padding: theme.space.l,
    fontSize: theme.fontSizes.m,
    background: theme.colors.background.default,
    whiteSpace: 'nowrap',
    userSelect: 'none',
    '&:hover': {
      background: theme.colors.background.bg,
    },
  }),
);
