import { useCallback } from 'react';

import {
  AppointmentStatus,
  PreparedTelemedicineAppointmentFragment,
  useBookPreparedAppointmentMutation,
} from '~/graphql';

export const useOpenOnlineWindow = () => {
  const openWindow = useCallback((encounterId: string) => {
    window.open(`/online/${encounterId}`, encounterId, 'resizable,scrollbars,status,noopener');
  }, []);
  const [bookAppointment, { loading }] = useBookPreparedAppointmentMutation({
    onCompleted: (_result) => {
      const encounterId = _result.bookAppointment?.encounter.id;
      if (encounterId) {
        openWindow(encounterId);
      }
    },
  });

  const book = useCallback(
    async (appointmentId: string) => {
      await bookAppointment({
        variables: {
          input: {
            appointmentId,
          },
        },
      });
    },
    [bookAppointment],
  );
  const handleOpenOnlineWindow = useCallback(
    async (appointment: PreparedTelemedicineAppointmentFragment) => {
      if (appointment.status === AppointmentStatus.Pending) {
        await book(appointment.id);
      } else {
        if (appointment.encounter?.id) {
          openWindow(appointment.encounter.id);
        }
      }
    },
    [book, openWindow],
  );

  return { loading, handleOpenOnlineWindow };
};
