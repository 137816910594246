import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, layout, LayoutProps, space, SpaceProps } from 'styled-system';

import { StyledHeader } from '../Header';

type Props = SpaceProps & LayoutProps;

export const Paper = styled('div', {
  shouldForwardProp,
})<Props>(
  ({ theme }) =>
    css({
      backgroundColor: theme.colors.background.default,
      overflow: 'auto',
      [`& > ${StyledHeader}`]: {
        padding: `${theme.space.l} 0`,
        borderBottom: 0,
      },
    }),
  () => compose(layout, space),
);
