import { isBefore } from 'date-fns';
import { useRouter } from 'next/router';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Modal } from '~/components/blocks';
import { useClientAuthSetting } from '~/hooks/use-client-auth-setting';
import { clientAuthenticationModalState } from '~/state/layouts/SharedAppShell/atoms';

import { InstallPanel } from './InstallPanel';
import { IssuePanel } from './IssuePanel';

const IGNORE_PATHNAME = ['/settings/organization/client_certs'];

export const ClientAuthenticationModal = () => {
  const { enable, startAt, hasClientCerts, allInstalled } = useClientAuthSetting();
  const { isClosed } = useRecoilValue(clientAuthenticationModalState);
  const { pathname } = useRouter();
  const isIgnored = IGNORE_PATHNAME.includes(pathname);
  const isClientAuthDomain = location.origin === process.env.pharmsClientAuthUrl;
  const isTransitionPeriod = enable && isBefore(new Date(), startAt || new Date());
  const installCondition = hasClientCerts ? !allInstalled : true;
  // 移行期間、且つ通常ドメインの場合のみ表示する
  // また、店舗に「ダウンロード待ち」「ダウンロード済み」の証明書が存在しない場合は、モーダルを表示しない
  const isOpen = isTransitionPeriod && installCondition && !isClientAuthDomain;
  const _startAt = startAt || new Date();

  const handleClose = useRecoilCallback(
    ({ set }) =>
      () => {
        set(clientAuthenticationModalState, (_state) => ({
          ..._state,
          isClosed: true,
        }));
      },
    [],
  );

  return (
    <Modal open={isOpen && !isClosed && !isIgnored} onClose={handleClose}>
      {hasClientCerts ? (
        <InstallPanel startAt={_startAt} onClosed={handleClose} />
      ) : (
        <IssuePanel startAt={_startAt} onClosed={handleClose} />
      )}
    </Modal>
  );
};
