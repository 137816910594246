import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Chip, Flex, Icon, Name } from '~/components/blocks';

import { PatientProfileSkeleton } from './PatientProfileSkeleton';
import { ProfileDetail } from './ProfileDetail';
import { useFetchPatientProfile } from './use-fetch-patient_profile';

type Props = {
  patientId: string | null;
};

const InactiveChip = styled(Chip)(({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    border: theme.borders.secondary,
    color: theme.colors.text.secondary,
    fontSize: theme.fontSizes.s,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.xs,
    },
  }),
);

const ShadedWrapper = styled('div')(() =>
  css({
    display: 'inline-block',
    position: 'relative',
  }),
);

const Shaded = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.secondary,
    top: `calc(${theme.space.l} - ${theme.space.s})`,
    width: theme.space.l,
    height: theme.space.xs,
    position: 'absolute',
    transform: `rotate(-45deg)`,
  }),
);

export const SimplePatientProfile = (props: Props) => {
  const { loading, profile } = useFetchPatientProfile(props.patientId);

  return (
    <Box>
      {loading || !profile ? (
        <PatientProfileSkeleton />
      ) : (
        <>
          <Flex justifyContent="space-between">
            <Name
              familyName={profile.familyName || ''}
              givenName={profile.givenName || ''}
              phoneticFamilyName={profile.phoneticFamilyName || ''}
              phoneticGivenName={profile.phoneticGivenName || ''}
              size="l"
            />
          </Flex>
          <Box>
            <ProfileDetail>
              <ProfileDetail.Item>{profile.age}</ProfileDetail.Item>
              <ProfileDetail.Item>{profile.sex}</ProfileDetail.Item>
              <ProfileDetail.Item>
                {!loading && !profile.active && (
                  <InactiveChip color="lightPink">
                    <ShadedWrapper>
                      <Icon size="m" icon="smartphone" color="pink" />
                      <Shaded />
                    </ShadedWrapper>
                    退会済み
                  </InactiveChip>
                )}
              </ProfileDetail.Item>
            </ProfileDetail>
          </Box>
        </>
      )}
    </Box>
  );
};
