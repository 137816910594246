import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { ScrollBoxAttr } from '~/components/blocks';
import { HistoryAppointmentFragment, useGetPatientAppointmentsLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { historyPageInfoState } from '~/state/partials/patient_karte/atoms';

type Appointments = {
  loading: boolean;
  appointments: HistoryAppointmentFragment[];
};

export const useFetchAppointments = (
  scrollRef: React.MutableRefObject<ScrollBoxAttr | null>,
): Appointments => {
  const [{ patientId, page, perPage }, setPageInfo] = useRecoilState(historyPageInfoState);

  const [getPatientAppointments, { data, loading }] = useGetPatientAppointmentsLazyQuery({
    onCompleted: (_result) => {
      const totalPage = getNode(_result, 'Patient')?.appointments.pagesCount ?? 1;
      setPageInfo((_state) => ({ ..._state, totalPage }));

      scrollRef.current?.toTop();
    },
  });

  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const appointments = useMemo(
    () => getNode(currentData, 'Patient')?.appointments.nodes || [],
    [currentData],
  );

  useEffect(() => {
    if (patientId) {
      getPatientAppointments({
        variables: {
          patientId,
          page,
          perPage,
        },
      });
    }
  }, [getPatientAppointments, page, patientId, perPage]);

  return { loading, appointments };
};
