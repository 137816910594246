import React, { useCallback } from 'react';

import { Button, Flex, Icon, Name } from '~/components/blocks';
import { openWithNoOpener } from '~/utils/window';

import { useFetchPatient } from '../use-fetch-patient';
import { ProfileDetail } from './ProfileDetail';
import { ProfileSkeleton } from './ProfileSkeleton';

type Props = {
  patientId: string | null;
};

export const PatientProfile = (props: Props) => {
  const { loading, profile } = useFetchPatient(props.patientId);

  const handleClick = useCallback(() => {
    if (props.patientId) {
      openWithNoOpener(`/insurance_card_preview/${props.patientId}`, {
        target: props.patientId,
        width: 640,
        height: 720,
        bgColor: '#525659',
      });
    }
  }, [props.patientId]);

  if (!profile) {
    return null;
  }
  return loading || !profile ? (
    <ProfileSkeleton />
  ) : (
    <>
      <Flex alignItems="center">
        <Name
          familyName={profile.familyName}
          givenName={profile.givenName}
          phoneticFamilyName={profile.phoneticFamilyName}
          phoneticGivenName={profile.phoneticGivenName}
          size="l"
        />
      </Flex>
      <ProfileDetail>
        <ProfileDetail.Item>{profile.age}</ProfileDetail.Item>
        <ProfileDetail.Item>({profile.sex})</ProfileDetail.Item>
        <ProfileDetail.Item>{profile.birthDate}</ProfileDetail.Item>
        <ProfileDetail.Item>
          <Button size="ms" use="white" onClick={handleClick}>
            <Icon size="xxl" icon="insurance" />
            保険証確認
          </Button>
        </ProfileDetail.Item>
      </ProfileDetail>
    </>
  );
};
