import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import {
  Box,
  Button,
  EntryList,
  Flex,
  Loader,
  Modal,
  Name,
  Radio,
  RadioGroup,
  Text,
} from '~/components/blocks';
import { AppointmentDeliveryMethod, UberDeliveryStatus } from '~/graphql';
import { changeDeliveryMethodState } from '~/state/partials/change_delivery_method_modal/atoms';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';
import { Label } from '~/utils/label';

import { useUberOrganizationSetting } from '../../../hooks/use-uber_organization_setting';
import { ParcelBox } from './ParcelBox';
import { Fields } from './types';
import { useChangeDeliveryMethod } from './use-change_delivery_method';
import { useFetchAppointment } from './use-fetch_appointment';
import { validationSchema } from './validation';

const Main = styled(Box)(({ theme }) =>
  css({
    padding: theme.space.m,
    minHeight: '400px',
  }),
);

type Props = {
  isOnline: boolean;
};

export const ChangeDeliveryMethodModal = (props: Props) => {
  const { isOnline } = props;
  const theme = useTheme();
  const state = useRecoilValue(changeDeliveryMethodState);
  const resetState = useResetRecoilState(changeDeliveryMethodState);
  const resetRequestModalState = useResetRecoilState(requestUberDeliveryModalState);

  const { appointment, loading: appointmentLoading } = useFetchAppointment();
  const { enable: uberOrgSettingEnable, loading: settingLoading } = useUberOrganizationSetting();
  const loading = appointmentLoading || settingLoading;

  const { changeDeliveryMethod, loading: updating } = useChangeDeliveryMethod();

  const patient = appointment?.patient ?? null;
  const availableSameDayDelivery =
    (appointment?.quote.availableSameDayDelivery && uberOrgSettingEnable) || false;

  const initialValues: Fields = {
    deliveryMethod:
      appointment?.deliveryMethod === AppointmentDeliveryMethod.Hand ? 'hand' : 'parcel',
    isSameDayDelivery:
      (appointment?.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery &&
        availableSameDayDelivery) ??
      false,
  };

  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        const deliveryMethod =
          values.deliveryMethod === 'hand'
            ? AppointmentDeliveryMethod.Hand
            : values.isSameDayDelivery
              ? AppointmentDeliveryMethod.SameDayDelivery
              : AppointmentDeliveryMethod.Parcel;
        if (appointment) {
          await changeDeliveryMethod({
            variables: {
              input: {
                appointmentId: appointment.id,
                deliveryMethod,
              },
            },
          });
        }
      } catch (error) {
        // 何もしない
      }
    },
    [appointment, changeDeliveryMethod],
  );

  const handleClose = useCallback(() => {
    resetState();
    resetRequestModalState();
  }, [resetRequestModalState, resetState]);

  useEffect(() => {
    if (!state.isOpen) {
      resetState();
    }
  }, [resetState, state.isOpen]);

  if (!appointment || !patient) return null;

  return (
    <Modal open={state.isOpen} size="m" onClose={handleClose}>
      <Modal.Header>受取方法の変更</Modal.Header>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <>
            <Modal.Body>
              {loading ? (
                <Loader open inside />
              ) : (
                <Main>
                  <Flex position="relative" alignItems="flex-end">
                    <Name
                      familyName={patient.familyName}
                      givenName={patient.givenName}
                      phoneticFamilyName={patient.phoneticFamilyName}
                      phoneticGivenName={patient.phoneticGivenName}
                      size="xl"
                    />
                    <Text size="l" marginLeft={theme.space.m}>
                      {Label.age(patient.birthDate)}({Label.sex(patient.sex)})
                    </Text>
                  </Flex>
                  <Box marginTop={theme.space.l}>
                    <EntryList>
                      <EntryList.Head>受取方法</EntryList.Head>
                      <EntryList.Body>
                        <RadioGroup>
                          <Radio
                            name="deliveryMethod"
                            label="配達で受け取る"
                            value="parcel"
                            checked={formik.values.deliveryMethod === 'parcel'}
                            onChange={formik.handleChange}
                          />
                          <Radio
                            name="deliveryMethod"
                            label="薬局に取りに来る"
                            value="hand"
                            checked={formik.values.deliveryMethod === 'hand'}
                            onChange={formik.handleChange}
                          />
                        </RadioGroup>
                      </EntryList.Body>
                    </EntryList>
                  </Box>
                  {formik.values.deliveryMethod === 'parcel' && (
                    <ParcelBox
                      isPending={appointment.uberDelivery?.status === UberDeliveryStatus.Pending}
                      isOnline={isOnline}
                      deliveryMethod={appointment.deliveryMethod}
                      availableSameDayDelivery={availableSameDayDelivery}
                      fee={appointment.quote.fee}
                      appointmentId={appointment.id}
                      formik={formik}
                      patient={patient}
                    />
                  )}
                </Main>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button use="base" loading={loading} onClick={formik.submitForm} disabled={updating}>
                保存
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
