import { atom } from 'recoil';

import { DEFAULT_PAGE, DEFAULT_PER_PAGE, MessageModal } from './types';

export const messageModalState = atom<MessageModal>({
  key: 'partials.messageModal',
  default: {
    isOpen: false,
    currentPanel: 'initial',
    patientId: null,
    profile: null,
    selectedMessageId: null,
    draftMessageId: null,
    page: DEFAULT_PAGE,
    perPage: DEFAULT_PER_PAGE,
    totalPage: 1,
  },
});
