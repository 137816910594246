import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Icon, Tag, Text, Tooltip } from '~/components/blocks';

type Props = {
  onClick?: () => void;
  isBookmarked: boolean;
};

const MimicCheckbox = styled('div')(({ theme }) =>
  css({
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.fontSizes.xl,
    height: theme.fontSizes.xl,
    border: theme.borders.default,
    borderRadius: theme.radii.default,
    flexShrink: 0,
  }),
);

const CustomTag = styled(Tag)<{ isBookmarked: boolean }>(({ theme, isBookmarked }) =>
  css({
    textAlign: 'start',
    alignItems: 'center',
    display: 'inline-flex',
    fontWeight: 'bold',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
    margin: '0px',
    borderColor: isBookmarked ? 'pink' : 'grey',
    borderStyle: isBookmarked ? 'solid' : 'dashed',
    paddingTop: theme.space.s,
    paddingBottom: theme.space.s,
    ...(isBookmarked && {
      [`&:hover > ${MimicCheckbox}`]: {
        border: theme.borders.primary,
      },
      [`&:focus > ${MimicCheckbox}`]: {
        border: theme.borders.default,
      },
      color: 'pink',
    }),
  }),
);

export const BookmarkTag = (props: Props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery({ query: theme.mediaQueries.tablet });
  const { isBookmarked } = props;
  const label = isBookmarked ? 'お気に入り薬局' : 'お気に入り未登録';
  const hintText = isBookmarked ? (
    <>
      CLINICSアプリで当薬局を
      <br />
      お気に入り登録に登録しています
    </>
  ) : (
    <>
      お気に入り薬局登録済みの患者は <br />
      リピート率が高い傾向があります。 <br />
      当薬局のお気に入り薬局登録をご案内 <br />
      してみませんか？
    </>
  );

  return (
    <>
      <Tooltip placement="left" content={hintText}>
        <CustomTag isBookmarked={isBookmarked}>
          <Icon
            icon={isBookmarked ? 'favorite-fill' : 'favorite'}
            color={isBookmarked ? 'pink' : 'grey'}
            size="m"
          />
          <Text
            size={isTablet ? 'xs' : 's'}
            color={isBookmarked ? 'pink' : 'grey'}
            lineHeight="1"
            padding={`${theme.space.s} 0`}
          >
            {label}
          </Text>
        </CustomTag>
      </Tooltip>
    </>
  );
};
