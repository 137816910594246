import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { Alert, Box, Checkbox, CheckboxGroup, Grid, ScrollBox, Text } from '~/components/blocks';
import { filePageInfoState } from '~/state/partials/telemedicine_patient_profile/atoms';

import { File } from './File';
import { FilePagination } from './FilePagination';
import { useFetchFiles } from './use-fetch-files';

type Props = {
  patientId?: string;
};

const Group = styled(CheckboxGroup)(({ theme }) =>
  css({
    paddingTop: theme.space.s,
    paddingRight: theme.space.xxxl,
  }),
);

export const FilePane = React.memo((props: Props) => {
  const theme = useTheme();

  const { scrollRef, loading, patientName, files, handleChange } = useFetchFiles(props.patientId);
  const { filter } = useRecoilValue(filePageInfoState);

  return (
    <Grid
      height="100%"
      gridTemplateRows="min-content 1fr min-content"
      gridTemplateColumns="1fr"
      padding={theme.space.l}
    >
      <Box paddingY={theme.space.m} paddingX={theme.space.s}>
        <Text size="s" color="grey01" fontWeight="bold">
          表示ファイル
        </Text>
        <Group orientation="holizonal">
          <Checkbox
            label="患者へ送信"
            value="sent"
            checked={filter.isSent}
            onChange={handleChange}
          />
          <Checkbox
            label="患者から受信"
            value="received"
            checked={filter.isReceived}
            onChange={handleChange}
          />
        </Group>
      </Box>
      <ScrollBox loading={loading} ref={scrollRef}>
        {files.length > 0 ? (
          <Box marginTop={theme.space.l}>
            {files.map((file) => (
              <File key={file.id} patientName={patientName} file={file} />
            ))}
          </Box>
        ) : (
          <Alert status="info" mt={theme.space.l}>
            ファイルはありません
          </Alert>
        )}
      </ScrollBox>
      {files.length > 0 && <FilePagination />}
    </Grid>
  );
});

FilePane.displayName = 'FilePane';
