import { OrganizationContactPoint } from '~/graphql';

export const getWorkFax = (contactPoints: OrganizationContactPoint[]): string | null => {
  const contactPoint = contactPoints.find(
    ({ contactPoint }) => contactPoint.use === 'work' && contactPoint.system === 'fax',
  );

  if (!contactPoint) return null;

  return contactPoint.contactPoint.formattedValue || null;
};

export const getWorkPhone = (contactPoints: OrganizationContactPoint[]): string | null => {
  const contactPoint = contactPoints.find(
    ({ contactPoint }) => contactPoint.use === 'work' && contactPoint.system === 'phone',
  );

  if (!contactPoint) return null;

  return contactPoint.contactPoint.formattedValue || null;
};
