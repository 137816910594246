import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Flex, Icon, Text } from '~/components/blocks';

const Root = styled(Flex)(({ theme }) =>
  css({
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.background.black,
  }),
);

export const PermissionError = () => {
  const theme = useTheme();

  return (
    <Root>
      <Flex alignItems="center" justifyContent="center">
        <Icon size="xl" icon="attention" />
        <Text color="white" size="m" fontWeight="bolder" marginLeft={theme.space.m}>
          服薬指導の権限がありません
        </Text>
      </Flex>
    </Root>
  );
};
