import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, variant } from 'styled-system';

import { Color, Size } from '~/styles/types';

type Props = {
  size?: Size;
  use?: Color;
};

export const Badge = styled('div', {
  shouldForwardProp,
})<Props>(
  ({ theme }) =>
    css({
      border: theme.borders.default,
      display: 'inline-block',
      fontWeight: theme.fontWeights.bold,
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          s: css({
            padding: `${theme.space.xs} ${theme.space.m}`,
            fontSize: theme.fontSizes.xs,
          }),
          m: css({
            padding: `${theme.space.s} ${theme.space.l}`,
            fontSize: theme.fontSizes.xs,
          }),
        },
      }),
      variant({
        prop: 'use',
        variants: {
          default: css({
            color: theme.colors.text.primary,
            background: theme.colors.background.default,
            border: theme.borders.primary,
          }),
          primary: css({
            color: theme.colors.text.white,
            background: theme.colors.background.secondary,
            border: theme.borders.transparent,
          }),
          secondary: css({
            color: theme.colors.text.secondary,
            background: theme.colors.background.default,
            border: theme.borders.secondary,
          }),
        },
      }),
    ),
);

Badge.defaultProps = {
  size: 'm',
  use: 'default',
};
