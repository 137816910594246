import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useState } from 'react';

import { Box, Button, Collapse, EntryList, Flex, Icon } from '~/components/blocks';
import { KarteFileFragment } from '~/graphql';
import { isPdf } from '~/utils/file';
import { Label } from '~/utils/label';
import { openWithNoOpener } from '~/utils/window';

import { CollapseLabel } from './CollapseLabel';
import { ImgViewer } from './ImgViewer';
import { PdfViewer } from './PdfViewer';

export type AttachmentFile = {
  id: string;
  date: string;
  name: string;
  contentType: string;
  url: string;
  received: boolean;
};

type Props = {
  file: KarteFileFragment;
  patientName: string;
};

const PreviewButton = styled(Button)(({ theme }) =>
  css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radii.circle,
    opacity: 0,
    transform: 'translate(-50%,-50%)',
    transition: `opacity linier ${theme.transitions.fast}`,
  }),
);

const Content = styled(Box)(({ theme, onClick }) =>
  css({
    marginTop: theme.space.m,
    position: 'relative',
    ...(onClick && {
      background: theme.colors.background.bg,
      cursor: 'pointer',
      '&:hover': {
        background: theme.colors.background.grey,
        opacity: 1,
        [`& > ${PreviewButton}`]: {
          opacity: 1,
        },
      },
    }),
  }),
);

export const File = React.memo((props: Props) => {
  const theme = useTheme();

  const { file } = props;

  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [hasPdfError, setHasPdfError] = useState(false);

  const contentType = file.file.contentType;
  const url = file.file.url;
  const creationDate = `${Label.YYYYMMDDja(file.createdAt)} ${Label.HHMM(file.createdAt)}`;

  const handleClickImage = useCallback(() => {
    openWithNoOpener(url, {
      target: file.name,
    });
  }, [file.name, url]);
  const handlePdfError = useCallback(() => setHasPdfError(true), []);
  const handlePdfLoad = useCallback(() => setIsPdfLoading(false), []);

  return (
    <Collapse
      label={<CollapseLabel received={file.patientFrom} date={creationDate} name={file.name} />}
    >
      <Box marginY={theme.space.xs}>
        <EntryList>
          <EntryList.Body>
            <Content
              onClick={
                isPdf(contentType) && (hasPdfError || isPdfLoading) ? undefined : handleClickImage
              }
            >
              {isPdf(contentType) ? (
                <PdfViewer src={url} onError={handlePdfError} onLoad={handlePdfLoad} />
              ) : (
                <ImgViewer src={url} alt={file.name} />
              )}
              <PreviewButton icon use="base">
                <Icon color="white" icon="blank" size="l" />
              </PreviewButton>
            </Content>
          </EntryList.Body>
        </EntryList>
        <Flex justifyContent="space-between" alignItems="flex-end">
          <EntryList marginTop={theme.space.m}>
            <EntryList.Head>ファイル名</EntryList.Head>
            <EntryList.Body>
              <strong>{file.name}</strong>
            </EntryList.Body>
          </EntryList>
        </Flex>
        {file.patientFrom && (
          <EntryList marginTop={theme.space.m} marginBottom={theme.space.m}>
            <EntryList.Head>送信者</EntryList.Head>
            <EntryList.Body>{props.patientName}</EntryList.Body>
          </EntryList>
        )}
      </Box>
    </Collapse>
  );
});

File.displayName = 'File';
