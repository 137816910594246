import { PureQueryOptions } from '@apollo/client';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';

import { Box, Collapse, Flex, Tag, Text } from '~/components/blocks';
import { QuestionMarkIcon } from '~/components/partials';
import { FollowupQuestionnaireSheetEventFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { useViewSheet } from './use-view-sheet';

type Props = {
  defaultOpen?: boolean;
  sheet: FollowupQuestionnaireSheetEventFragment;
  refetchQueries?: PureQueryOptions[];
  children?: React.ReactNode;
};

const Root = styled(Collapse)(({ theme }) =>
  css({
    background: theme.colors.background.bg,
    border: theme.borders.transparent,
    borderRadius: '6px',
    padding: theme.space.m,
    transitionDuration: theme.transitions.default,
    '& + &': {
      marginTop: theme.space.l,
    },
    '&:hover': {
      background: theme.colors.background.default,
      border: theme.borders.default,
      boxShadow: theme.shadows.default,
      '& > dt': {
        [`${Text}`]: {
          color: theme.colors.text.primary,
        },
        '& > i': {
          borderLeftColor: theme.colors.text.primary,
        },
      },
    },
    '& > dt': {
      borderBottom: 'none',
      '&:hover': {
        background: theme.colors.background.transparent,
      },
    },
  }),
);

const Unread = styled(Tag)(({ theme }) =>
  css({
    color: theme.colors.text.white,
    background: theme.colors.background.secondary,
    border: theme.borders.transparent,
    borderRadius: theme.radii.half,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    padding: `${theme.space.xs} ${theme.space.s}`,
    lineHeight: 1,
  }),
);

const AnswerBox = styled(Box)(({ theme }) =>
  css({
    '&:last-child': {
      marginBottom: theme.space.m,
    },
  }),
);

export const FollowupQuestionnaireSheetEvent: React.FC<Props> = (props: Props) => {
  const { sheet } = props;

  const theme = useTheme();
  const { isViewing, view } = useViewSheet(props.refetchQueries);

  const [isOpen, setIsOpen] = useState(props.defaultOpen ?? false);

  const answerdAt = `${Label.YYYYMMDDja(sheet.createdAt)} ${Label.HHMM(sheet.createdAt)}`;
  const isUnread = !sheet.medicationFollowupMessage.isRead;
  const subject = sheet.medicationFollowupMessage.medicationFollowupQuestionnaireRevision?.subject;

  const handleClick = useCallback(async () => {
    if (isViewing) return;

    setIsOpen((_state) => !_state);

    if (!isUnread) return;

    try {
      await view(sheet.id);
    } catch {
      // 何もしない
    }
  }, [isUnread, isViewing, sheet.id, view]);

  return (
    <Root
      open={isOpen}
      label={
        <Flex flexDirection="column">
          <Flex alignItems="center">
            <Text size="xs">{answerdAt}</Text>
            {isUnread && (
              <Box marginLeft={theme.space.s}>
                {!sheet.medicationFollowupMessage.isRead && <Unread size="s">未読</Unread>}
              </Box>
            )}
          </Flex>
          <Text block size="s" marginRight={theme.space.s} lineHeight={1.3}>
            質問票{subject && `（${subject}）`}の回答が届きました
          </Text>
        </Flex>
      }
      onClick={handleClick}
    >
      {sheet.answers.map(({ title, value }, idx) => {
        return (
          <AnswerBox key={idx} margin={`0 ${theme.space.s} ${theme.space.xxl}`}>
            <Flex marginTop={theme.space.m}>
              <QuestionMarkIcon />
              <Text size="s">{title}</Text>
            </Flex>
            {value.map((v, idx) => {
              return (
                <Text
                  paddingLeft={theme.space.xl}
                  key={idx}
                  block
                  fontWeight="bold"
                  size="s"
                  marginLeft={theme.space.s}
                  marginTop={theme.space.s}
                >
                  {v}
                </Text>
              );
            })}
          </AnswerBox>
        );
      })}
      {props.children}
    </Root>
  );
};

FollowupQuestionnaireSheetEvent.displayName = 'FollowupQuestionnaireSheetEvent';
