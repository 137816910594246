import { atom } from 'recoil';

import { TracingReportPageInfo, TracingReportPane } from './types';

export const tracingReportPageInfoState = atom<TracingReportPageInfo>({
  key: 'partials.tracingReportPageInfo',
  default: {
    target: null,
    targetId: null,
    page: 1,
    totalPage: 1,
    perPage: 10,
  },
});

export const tracingReportPaneState = atom<TracingReportPane>({
  key: 'partials.tracingReport.tracingReportPane',
  default: {
    tracingReportDraftId: null,
    patient: null,
  },
});
