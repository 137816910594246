import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { Grid } from '~/components/blocks';
import { publicPath } from '~/utils/path';

type Props = { children?: React.ReactNode };

const Main = styled('main', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '540px',
    margin: `0 auto`,
    backgroundColor: theme.colors.background.default,
    boxShadow: theme.shadows.default,
    padding: theme.space.xxxl,
    textAlign: 'center',
    borderRadius: theme.radii.default,
  }),
);

const Header = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    margin: '120px 0 30px',
    padding: `${theme.space.xl}`,
    textAlign: 'center',
  }),
);

const Logo = styled('img', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    marginBottom: theme.space.xl,
  }),
);

const Title = styled('h1', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    fontSize: theme.fontSizes.l,
    fontWeight: theme.fontWeights.default,
  }),
);

const Notice: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  return (
    <Grid p={theme.space.xl}>
      <Header>
        <Logo width="355" height="100" src={publicPath('/logo.svg')} />
        <Title>かかりつけ薬局支援システム</Title>
      </Header>
      <Main>{children}</Main>
    </Grid>
  );
};

export { Notice };
