import { useRecoilCallback, useRecoilValue } from 'recoil';

import {
  GetPatientProfileFooterAppointmentDocument,
  useSendCongestionNotificationMutation,
} from '~/graphql';
import { notificationCongestionModalState } from '~/state/partials/patient_profile_drawer/atoms';

export const useSendCongestionNotification = () => {
  const { isOpen, appointmentId } = useRecoilValue(notificationCongestionModalState);

  const [sendNotification, { loading, error }] = useSendCongestionNotificationMutation({
    refetchQueries: [
      { query: GetPatientProfileFooterAppointmentDocument, variables: { appointmentId } },
    ],
  });

  const handleSend = useRecoilCallback(
    ({ reset }) =>
      async () => {
        if (!appointmentId || !isOpen) return;

        try {
          await sendNotification({
            variables: {
              input: {
                appointmentId,
              },
            },
          });

          reset(notificationCongestionModalState);
        } catch (_error) {
          // 何もしない
        }
      },
    [appointmentId, isOpen, sendNotification],
  );

  return { sending: loading, error, handleSend };
};
