import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Checkbox, CheckboxGroup, ScrollBox, Text } from '~/components/blocks';
import {
  GetPatientAttachmentsQuery,
  PatientAttachmentsFragment,
  useGetPatientAttachmentsLazyQuery,
} from '~/graphql';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { filePageInfoState } from '~/state/partials/patient_karte/atoms';
import { Label } from '~/utils/label';

import { AttachmentFile, File } from './File';
import { FilePagination } from './FilePagination';

const Group = styled(CheckboxGroup)(({ theme }) =>
  css({
    paddingTop: theme.space.s,
    paddingRight: theme.space.xxxl,
  }),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPatientAttachmentsFragment = (node: any): node is PatientAttachmentsFragment => {
  return node && node.__typename === 'Patient';
};

const formatFiles = (data: GetPatientAttachmentsQuery) => {
  if (!isPatientAttachmentsFragment(data.node)) return [];
  const files: AttachmentFile[] = [];

  for (const file of data.node.patientAttachments.nodes) {
    if (!file) continue;

    files.push({
      id: file.id,
      contentType: file.file.contentType,
      url: file.file.url,
      name: Label.fileName(file.name),
      date: `${Label.YYYYMMDDja(file.createdAt)} ${Label.HHMM(file.createdAt)}`,
      received: file.patientFrom,
    });
  }

  return files;
};

const getPatientName = (data: GetPatientAttachmentsQuery) => {
  if (!isPatientAttachmentsFragment(data.node)) return undefined;

  return `${data.node.familyName}${data.node.givenName}`;
};

const getTotalPage = (data: GetPatientAttachmentsQuery) => {
  if (!isPatientAttachmentsFragment(data.node)) return 1;

  return data.node.patientAttachments.pagesCount;
};

export const toPatientFrom = (sent: boolean, received: boolean) => {
  if (sent && received) {
    return null;
  } else if (sent) {
    return false;
  } else if (received) {
    return true;
  }

  return null;
};

export const FileList = () => {
  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const [pageInfo, setPageInfo] = useRecoilState(filePageInfoState);

  const [getAttachments, { data, loading }] = useGetPatientAttachmentsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      setPageInfo((_state) => ({ ..._state, totalPage: getTotalPage(result) }));
      scrollRef.current?.toTop();
    },
  });
  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const files = useMemo(() => {
    // チェックボックスが選択されていないときは、何も表示しない
    if (!pageInfo.filter.sent && !pageInfo.filter.received) return [];
    return currentData ? formatFiles(currentData) : [];
  }, [currentData, pageInfo.filter.received, pageInfo.filter.sent]);
  const patientName = useMemo(() => data && getPatientName(data), [data]);

  const handleChangeFilter = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const target = e.currentTarget.value;
      const sent = target === 'sent' ? !pageInfo.filter.sent : pageInfo.filter.sent;
      const received = target === 'received' ? !pageInfo.filter.received : pageInfo.filter.received;

      setPageInfo((_state) => ({
        ..._state,
        filter: { sent, received },
      }));
    },
    [pageInfo.filter.received, pageInfo.filter.sent, setPageInfo],
  );

  useEffect(() => {
    const filter = pageInfo.filter;
    if (filter.received || filter.sent) {
      if (pageInfo.patientId) {
        getAttachments({
          variables: {
            patientId: pageInfo.patientId,
            patientFrom: toPatientFrom(filter.sent, filter.received),
            page: pageInfo.page,
            perPage: pageInfo.perPage,
          },
        });
      }
    }
  }, [getAttachments, pageInfo.filter, pageInfo.page, pageInfo.patientId, pageInfo.perPage]);

  return (
    <>
      <Box paddingY={theme.space.m} paddingX={theme.space.s}>
        <Text size="s" color="grey01" fontWeight="bold">
          表示ファイル
        </Text>
        <Group orientation="holizonal">
          <Checkbox
            label="患者へ送信"
            value="sent"
            checked={pageInfo.filter.sent}
            onChange={handleChangeFilter}
          />
          <Checkbox
            label="患者から受信"
            value="received"
            checked={pageInfo.filter.received}
            onChange={handleChangeFilter}
          />
        </Group>
      </Box>
      {loading ? (
        <ScrollBox loading={true} />
      ) : files.length > 0 ? (
        <>
          <ScrollBox ref={scrollRef}>
            <Box marginTop={theme.space.l}>
              {files.map((file) => (
                <File key={file.id} file={file} patientName={patientName} />
              ))}
            </Box>
          </ScrollBox>
          <FilePagination />
        </>
      ) : (
        <Box>
          <Alert status="info" mt={theme.space.l}>
            ファイルはありません
          </Alert>
        </Box>
      )}
    </>
  );
};
