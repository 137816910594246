import React from 'react';

import { ClientCert, ClientCertInfoFragment, OrganizationClientCertInfoFragment } from '~/graphql';
import { toHHMMSS, toYYYYMMDDja } from '~/utils/date';

import { ClipButton } from '../ClipButton';

type Props = {
  clientCertInfo: ClientCertInfoFragment | OrganizationClientCertInfoFragment;
  excludeDownloadLink?: boolean;
  size?: 's' | 'm';
  wide?: 'half' | 'fill';
};

const buildText = (
  clientCertInfo: ClientCertInfoFragment | OrganizationClientCertInfoFragment,
  excludeDownloadLink: boolean,
) => {
  const organization = (clientCertInfo as ClientCert).organization;
  const startDate = clientCertInfo.notBefore
    ? `${toYYYYMMDDja(clientCertInfo.notBefore)} ${toHHMMSS(clientCertInfo.notBefore)}`
    : null;
  const endDate = clientCertInfo.notAfter
    ? `${toYYYYMMDDja(clientCertInfo.notAfter)} ${toHHMMSS(clientCertInfo.notAfter)}`
    : null;

  const organizationInfoText = organization
    ? `【店舗コード】
${organization.slug}
【発行店舗】
${organization.name}`
    : null;

  let clientCertInfoText = `【証明書名】
${clientCertInfo.name}
【説明】
${clientCertInfo.description}
【有効期限】
${startDate && endDate ? `${startDate} 〜 ${endDate}` : 'ー'}
【パスワード】
${clientCertInfo.password}`;

  const downloadLinkText = `【ダウンロードリンク】
${clientCertInfo.downloadUrl}`;

  if (!excludeDownloadLink) {
    clientCertInfoText = `${clientCertInfoText}
${downloadLinkText}`;
  }

  return organizationInfoText
    ? `${organizationInfoText}
${clientCertInfoText}`
    : clientCertInfoText;
};

export const ClientCertClipButton = (props: Props) => {
  const clipboardText = buildText(props.clientCertInfo, !!props.excludeDownloadLink);
  return <ClipButton iconSize="l" size={props.size} wide={props.wide} text={clipboardText} />;
};
