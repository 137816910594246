import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { ScrollBox } from '~/components/blocks';
import { useGetKarteFilesLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { filePageInfoState } from '~/state/partials/telemedicine_patient_profile/atoms';

const toPatientFrom = (sent: boolean, received: boolean) => {
  if (sent && received) {
    return null;
  } else if (sent) {
    return false;
  } else if (received) {
    return true;
  }

  return null;
};

export const useFetchFiles = (patientId?: string) => {
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const [pageInfo, setPageInfo] = useRecoilState(filePageInfoState);

  const [getFiles, { called, loading, data }] = useGetKarteFilesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      setPageInfo((_state) => ({
        ..._state,
        totalPage: getNode(_data, 'Patient')?.patientAttachments.pagesCount ?? 1,
      }));
      scrollRef.current?.toTop();
    },
  });

  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const patientName = useMemo(() => {
    const patient = getNode(currentData, 'Patient');

    return patient ? `${patient.familyName}${patient.givenName}` : 'ー';
  }, [currentData]);
  const files = useMemo(() => {
    // チェックボックスが選択されていないときは、何も表示しない
    if (!pageInfo.filter.isSent && !pageInfo.filter.isReceived) return [];

    return currentData ? getNode(currentData, 'Patient')?.patientAttachments.nodes || [] : [];
  }, [currentData, pageInfo.filter.isReceived, pageInfo.filter.isSent]);

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const target = e.currentTarget.value;
      const isSent = target === 'sent' ? !pageInfo.filter.isSent : pageInfo.filter.isSent;
      const isReceived =
        target === 'received' ? !pageInfo.filter.isReceived : pageInfo.filter.isReceived;

      setPageInfo((_state) => ({
        ..._state,
        filter: { isSent, isReceived },
      }));
    },
    [pageInfo.filter.isReceived, pageInfo.filter.isSent, setPageInfo],
  );

  useEffect(() => {
    if (patientId) {
      const patientFrom = toPatientFrom(pageInfo.filter.isSent, pageInfo.filter.isReceived);

      getFiles({
        variables: {
          id: patientId,
          page: pageInfo.page,
          perPage: pageInfo.perPage,
          patientFrom,
        },
      });
    }
  }, [
    getFiles,
    pageInfo.filter.isReceived,
    pageInfo.filter.isSent,
    pageInfo.page,
    pageInfo.perPage,
    patientId,
  ]);

  return {
    loading: !called || loading,
    scrollRef,
    patientName,
    files,
    handleChange,
  };
};
