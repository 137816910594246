import React, { ComponentProps, useCallback, useRef, useState } from 'react';

import { Button } from '../Button';
import { Icon, IconName } from '../Icon';
import { Overlay } from '../Overlay';
import { Popover } from '../Popover';

type Props = {
  text?: React.ReactNode;
  icon?: IconName;
  placement?: ComponentProps<typeof Popover>['placement'];
  children?: React.ReactNode;
};

export const Dropdown: React.FC<Props> = React.memo((props) => {
  const [isOpen, setIsOpen] = useState(false);
  const anckerRef = useRef<HTMLButtonElement>(null);
  const handleClick = useCallback(() => {
    setIsOpen((_isOpen) => !_isOpen);
  }, []);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div>
      <Button type="button" use="white" ref={anckerRef} onClick={handleClick}>
        {props.text}
        <Icon size="m" icon={props.icon || 'arrow-down'} />
      </Button>
      {anckerRef.current && (
        <Overlay transparent open={isOpen} onClick={handleClose}>
          <Popover
            inside
            open={isOpen}
            placement={props.placement || 'bottom'}
            anckerEl={anckerRef.current}
          >
            {props.children}
          </Popover>
        </Overlay>
      )}
    </div>
  );
});

Dropdown.displayName = 'Dropdown';
