import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Flex, Text } from '~/components/blocks';

const Logo = styled(Box)(({ theme }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: theme.borders.default,
    borderRadius: theme.radii.default,
    backgroundColor: theme.colors.background.default,
    textAlign: 'center',
    width: '50px',
    height: '50px',
  }),
);

const LogoImage = styled('img')(() =>
  css({
    height: 'auto',
    width: 'auto',
    maxHeight: '48px',
    maxWidth: '48px',
  }),
);

const Description = styled(Box)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.default,
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  }),
);

const Contents = styled(Flex)(({ theme }) =>
  css({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.space.m,
  }),
);

const PreviewText = styled(Text)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.navy,
    wordBreak: 'break-all',
  }),
);

const Container = styled(Flex)(({ theme }) =>
  css({
    background: theme.colors.background.default,
    borderRadius: '8px',
    border: `4px solid ${theme.colors.border.default}`,
    padding: theme.space.l,
    gap: theme.space.l,
  }),
);

const Root = styled('div')(({ theme }) =>
  css({
    padding: theme.space.l,
  }),
);

type Props = {
  address: string | null;
  name: string | null;
  description?: string | null;
  logo?: string;
};

export const OrganizationDetail = React.memo((props: Props) => {
  return (
    <Root>
      <Container>
        <Logo>
          {props.logo ? (
            <LogoImage src={props.logo} />
          ) : (
            <LogoImage src={process.env.defaultLogoPath} />
          )}
        </Logo>
        <Contents>
          <PreviewText size="s">{props.name}</PreviewText>
          <PreviewText>{props.address || '未設定'}</PreviewText>
          <Description>{props.description || '未設定'}</Description>
        </Contents>
      </Container>
    </Root>
  );
});

OrganizationDetail.displayName = 'OrganizationDetail';
