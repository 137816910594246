import { useTheme } from '@emotion/react';
import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Grid, ScrollBox } from '~/components/blocks';
import { historyPageInfoState } from '~/state/partials/patient_karte/atoms';

import { History } from './History';
import { HistoryPagination } from './HistoryPagination';
import { useFetchAppointments } from './use-fetch-appointments';

type Props = {
  patientId: string;
};

export const HistoryPane = React.memo((props: Props) => {
  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const { loading, appointments } = useFetchAppointments(scrollRef);
  const hasAppointments = appointments.length > 0;

  const [pageInfo, setPageInfo] = useRecoilState(historyPageInfoState);

  useEffect(() => {
    if (props.patientId !== pageInfo.patientId) {
      setPageInfo((_state) => ({ ..._state, patientId: props.patientId, page: 1, totalPage: 1 }));
    }
  }, [pageInfo.patientId, props.patientId, setPageInfo]);

  return (
    <>
      {hasAppointments && (
        <Grid
          height="100%"
          gridTemplateRows="1fr min-content"
          gridTemplateColumns="1fr"
          padding={theme.space.l}
        >
          <>
            <ScrollBox loading={loading} ref={scrollRef}>
              <Box>
                {appointments.map((appointment) => (
                  <History key={appointment.id} appointment={appointment} />
                ))}
              </Box>
            </ScrollBox>
            <HistoryPagination />
          </>
        </Grid>
      )}
      {!loading && !hasAppointments && (
        <Box padding={`${theme.space.m} ${theme.space.l}`}>
          <Alert status="info" marginTop={theme.space.l}>
            履歴はありません
          </Alert>
        </Box>
      )}
    </>
  );
});

HistoryPane.displayName = 'HistoryPane';
