import React from 'react';

import { Flex, Select } from '~/components/blocks';

// アクリートのSMS配信時間と併せて7~22時
const hourOptions = [...Array(16).keys()].map((hour) => `${hour + 7}`.padStart(2, '0'));

type Props = {
  disabled?: boolean;
  name?: string;
  error?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectTime = React.memo((props: Props) => {
  return (
    <Flex alignItems="center">
      <Select
        name={props.name}
        disabled={props.disabled}
        error={props.error}
        value={props.value}
        onChange={props.onChange}
      >
        <option hidden value="">
          --
        </option>
        {hourOptions.map((hour, idx) => {
          return <option key={idx}>{hour}</option>;
        })}
      </Select>
      時
    </Flex>
  );
});

SelectTime.displayName = 'SelectTime';
