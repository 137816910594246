import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetPatientDraftMessagesLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { messageModalState } from '~/state/partials/message_modal/atoms';

export const useFetchOthersDraftMessages = () => {
  const { patientId } = useRecoilValue(messageModalState);
  const [getDraftMessages, { called, loading, data }] = useGetPatientDraftMessagesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const othersDraftMessages = useMemo(() => {
    const practitionerId = data ? getMe(data)?.id : null;
    const node = data?.node;
    const draftMessages =
      node?.__typename === 'Patient' ||
      node?.__typename === 'DirectVisitor' ||
      node?.__typename === 'WebVisitor'
        ? node.medicationFollowupDraftMessages.nodes
        : [];
    return draftMessages.filter((m) => m.practitioner.id != practitionerId);
  }, [data]);

  useEffect(() => {
    if (!patientId) return;
    getDraftMessages({ variables: { id: patientId } });
  }, [getDraftMessages, patientId]);

  return {
    loading: !called || loading,
    othersDraftMessages,
  };
};
