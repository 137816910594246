import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { Alert, Button, Modal } from '~/components/blocks';
import { ClientCertDetailFragment } from '~/graphql';
import { toYYYYMMDDja } from '~/utils/date';

type Props = {
  onClosed: () => void;
  isAdmin: boolean;
  isCompanyAdmin: boolean;
  clientCert: ClientCertDetailFragment;
};

export const PractitionerPanel = (props: Props) => {
  const router = useRouter();
  const { clientCert, onClosed, isAdmin, isCompanyAdmin } = props;
  const notAfter = clientCert?.notAfter;

  const handleClick = useCallback(async () => {
    if (!isAdmin && !isCompanyAdmin) {
      return; // 店舗管理者でも本部管理者でもない場合は何もしない
    }

    const url = isCompanyAdmin
      ? '/company/security/client_certs'
      : '/settings/organization/client_certs';
    await router.push(url);
    onClosed();
  }, [isAdmin, isCompanyAdmin, onClosed, router]);

  return (
    <>
      <Modal.Body>
        <Alert status="warning">
          お使いのクライアント証明書「{clientCert?.name}」は、
          <br />
          {notAfter && toYYYYMMDDja(notAfter)}以降利用できなくなります。
          <br />
          {isAdmin
            ? '期限までに証明書を再発行してください'
            : '店舗管理者にクライアント証明書の再発行をご依頼ください'}
        </Alert>
      </Modal.Body>
      {(isAdmin || isCompanyAdmin) && (
        <Modal.Footer>
          <Button use="base" onClick={handleClick}>
            クライアント証明書を発行する
          </Button>
        </Modal.Footer>
      )}
    </>
  );
};
