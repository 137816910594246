import React from 'react';
import { IntercomProvider as ReactIntercomProvider } from 'react-use-intercom';

import { useSetupIntercom } from './use-setup-intercom';

type Props = {
  children: React.ReactNode;
};

export const IntercomProvider: React.FC<Props> = (props: Props) => {
  const { handleUnreadCountChange, ignore } = useSetupIntercom();

  if (ignore) {
    return <>{props.children}</>;
  }

  return (
    <ReactIntercomProvider
      autoBoot
      appId={process.env.intercomAppId}
      onUnreadCountChange={handleUnreadCountChange}
    >
      {props.children}
    </ReactIntercomProvider>
  );
};
