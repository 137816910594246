import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { rgba } from 'polished';
import React, { DetailedHTMLProps } from 'react';

import { Portal } from '../Portal';

type StyleProps = {
  inside?: boolean;
  transparent?: boolean;
};

type ComponentProps = {
  open?: boolean;
};

type Props = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  StyleProps &
  ComponentProps;

export const Overlay = styled(
  (props: Props) => {
    const { className, children, open = false, inside = false, ...divProps } = props;

    if (!open) return null;

    if (inside) {
      return (
        <div {...divProps} className={className}>
          {children}
        </div>
      );
    } else {
      return (
        <Portal>
          <div {...divProps} className={className}>
            {children}
          </div>
        </Portal>
      );
    }
  },
  {
    shouldForwardProp: (propName: string) =>
      shouldForwardProp(propName) || ['inside', 'open'].includes(propName),
  },
)<StyleProps>(({ theme, transparent = false, inside = false }) =>
  css({
    display: 'block',
    position: inside ? 'absolute' : 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: transparent ? 'transparent' : rgba(theme.colors.background.default, 0.7),
    zIndex: theme.zIndices.modalOverlay,
  }),
);
