import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Box } from '~/components/blocks';
import {
  DosageCautionRecordFragment,
  DosagePlusRecordFragment,
  DosageRecordFragment,
  MedicineFragment,
} from '~/graphql';
import { medicineNotesContainerState } from '~/state/partials/medicine_notes_container/atoms';
import { theme } from '~/styles/theme';

import { MedicineNoteTable } from '../MedicineNoteTable';
import { FORM_CODES } from './form_codes';
import { List } from './List';

type Props = {
  dosage?: DosageRecordFragment;
  dosagePluses: DosagePlusRecordFragment[];
  dosageCautions: DosageCautionRecordFragment[];
  medicines: MedicineFragment[];
};

const Root = styled(Box)(({ theme }) =>
  css({
    borderLeft: `4px solid ${theme.colors.border.black}`,
    paddingLeft: theme.space.l,
    marginBottom: theme.space.xl,
  }),
);

const MedicineInfoWrapper = styled(Box)<{ isDetailView: boolean }>(({ theme, isDetailView }) =>
  css({
    borderBottom: isDetailView ? `1px dotted ${theme.colors.colorPallete.grey03}` : undefined,
  }),
);

const MedicineInfo = styled(Box)<{ isDetailView: boolean }>(({ theme, isDetailView }) =>
  css({
    borderBottom: isDetailView ? 'none' : `1px dotted ${theme.colors.colorPallete.grey03}`,
    padding: `${theme.space.m} ${theme.space.s}`,
    transitionDuration: theme.transitions.fast,

    '&:hover': {
      background: theme.colors.background.bg,
    },
  }),
);

const Medicine = styled('dl')(() =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

const MedicineName = styled('dt')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.m,
    fontWeight: theme.fontWeights.bold,
  }),
);

const GenericName = styled('dt')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
  }),
);

const MedicineAmount = styled('dd')();

const DosageList = styled(List)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    padding: `${theme.space.m} 0`,
  }),
);

const MedicineAmountList = styled(List)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
  }),
);

export const RecipeInformation = React.memo((props: Props) => {
  const { dosage, dosagePluses, dosageCautions } = props;

  const { isDetailView } = useRecoilValue(medicineNotesContainerState);

  const hasDosagePluses = dosagePluses.length > 0;
  const hasDosageCautions = dosageCautions.length > 0;

  const medicines = useMemo(() => {
    return props.medicines.map(
      ({ medicineRecord, medicineCautionRecords, medicinePlusRecords }) => {
        const medicineCautions = medicineCautionRecords || [];
        const medicinePluses = medicinePlusRecords || [];

        const hasMedicineCautions = medicineCautions.length > 0;
        const hasMedicinePluses = medicinePluses.length > 0;

        return {
          hasMedicineCautions,
          hasMedicinePluses,
          medicineCautions,
          medicinePluses,
          medicine: medicineRecord,
        };
      },
    );
  }, [props.medicines]);

  return (
    <Root>
      <Box>
        <MedicineInfoWrapper isDetailView={isDetailView}>
          {medicines.map(
            (
              {
                medicine,
                medicinePluses,
                medicineCautions,
                hasMedicineCautions,
                hasMedicinePluses,
              },
              idx,
            ) => {
              return (
                <MedicineInfo key={idx} isDetailView={isDetailView}>
                  <Medicine>
                    <Box>
                      <MedicineName>{medicine?.name || 'ー'}</MedicineName>
                      {medicine?.genericName && <GenericName>{medicine.genericName}</GenericName>}
                    </Box>
                    <MedicineAmount>
                      <MedicineAmountList split={false}>
                        <List.Item>{medicine?.amount || 'ー'}</List.Item>
                        {medicine?.unitName && <List.Item>{medicine.unitName}</List.Item>}
                      </MedicineAmountList>
                    </MedicineAmount>
                  </Medicine>
                  {isDetailView && (hasMedicineCautions || hasMedicinePluses) && (
                    <Box marginTop={theme.space.m}>
                      <MedicineNoteTable appearance="detail">
                        <MedicineNoteTable.Tbody>
                          {hasMedicinePluses && (
                            <MedicineNoteTable.Tr>
                              <MedicineNoteTable.Th top={medicinePluses.length > 1}>
                                薬品補足情報
                              </MedicineNoteTable.Th>
                              <MedicineNoteTable.Td>
                                <ul>
                                  {medicinePluses.map((plus, idx) => (
                                    <li key={idx}>{plus.content}</li>
                                  ))}
                                </ul>
                              </MedicineNoteTable.Td>
                            </MedicineNoteTable.Tr>
                          )}
                          {hasMedicineCautions && (
                            <MedicineNoteTable.Tr>
                              <MedicineNoteTable.Th top={medicineCautions.length > 1}>
                                薬品服用注意
                              </MedicineNoteTable.Th>
                              <MedicineNoteTable.Td>
                                <ul>
                                  {medicineCautions.map((caution, idx) => (
                                    <li key={idx}>{caution.content}</li>
                                  ))}
                                </ul>
                              </MedicineNoteTable.Td>
                            </MedicineNoteTable.Tr>
                          )}
                        </MedicineNoteTable.Tbody>
                      </MedicineNoteTable>
                    </Box>
                  )}
                </MedicineInfo>
              );
            },
          )}
        </MedicineInfoWrapper>
        <DosageList>
          {dosage?.name && <List.Item>{dosage.name}</List.Item>}
          {dosage?.amount && <List.Item>{`${dosage.amount}${dosage?.unit || ''}`}</List.Item>}
          {dosage?.formCode && <List.Item>{FORM_CODES[dosage.formCode]}</List.Item>}
        </DosageList>
        {isDetailView && (hasDosagePluses || hasDosageCautions) && (
          <MedicineNoteTable appearance="detail">
            <MedicineNoteTable.Tbody>
              {hasDosagePluses && (
                <MedicineNoteTable.Tr>
                  <MedicineNoteTable.Th top={dosagePluses.length > 0}>
                    用法補足
                  </MedicineNoteTable.Th>
                  <MedicineNoteTable.Td>
                    <ul>
                      {dosagePluses.map((plus, idx) => (
                        <li key={idx}>{plus.content}</li>
                      ))}
                    </ul>
                  </MedicineNoteTable.Td>
                </MedicineNoteTable.Tr>
              )}
              {hasDosageCautions && (
                <MedicineNoteTable.Tr>
                  <MedicineNoteTable.Th top={dosageCautions.length > 0}>
                    服用注意
                  </MedicineNoteTable.Th>
                  <MedicineNoteTable.Td>
                    <ul>
                      {dosageCautions.map((caution, idx) => (
                        <li key={idx}>{caution.content}</li>
                      ))}
                    </ul>
                  </MedicineNoteTable.Td>
                </MedicineNoteTable.Tr>
              )}
            </MedicineNoteTable.Tbody>
          </MedicineNoteTable>
        )}
      </Box>
    </Root>
  );
});

RecipeInformation.displayName = 'RecipeInformation';
