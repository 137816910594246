import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Name } from '~/components/blocks';
import { Label } from '~/utils/label';

import { Patient } from '../types';
import { EmptyProfile } from './EmptyProfile';
import { Profile } from './Profile';
import { ProfileDetail } from './ProfileDetail';

type Props = {
  patient?: Patient;
};

const Address = styled('ul')(() =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
  }),
);

const AddressItem = styled('li')(({ theme }) =>
  css({
    '& + &': {
      marginLeft: theme.space.m,
    },
  }),
);

export const PatientProfile = React.memo((props: Props) => {
  const theme = useTheme();
  const { patient } = props;

  // TODO: 折り返し単位をPatientDetailごとにしたい
  return (
    <Box maxWidth="620px">
      {patient ? (
        <Box>
          <Name size="xl" name={patient.name} phoneticName={patient.kana} />
          <Box marginTop={theme.space.m}>
            <Profile>
              <Profile.Item>
                {patient.birthDate ? Label.YYYYMMDDja(patient.birthDate) : '氏名: 未登録'}
              </Profile.Item>
              <Profile.Item>
                {patient.birthDate ? Label.age(patient.birthDate) : '生年月日: 未登録'}
              </Profile.Item>
              <Profile.Item>{`｜${patient.sex}`}</Profile.Item>
            </Profile>
          </Box>
          <Box marginTop={theme.space.m}>
            {(patient.postalCode || patient.address) && (
              <ProfileDetail>
                <ProfileDetail.Label>住所</ProfileDetail.Label>
                <ProfileDetail.Item>
                  <Address>
                    {patient.postalCode && <AddressItem>{`〒${patient.postalCode}`}</AddressItem>}
                    {patient.address && <AddressItem>{patient.address}</AddressItem>}
                  </Address>
                </ProfileDetail.Item>
              </ProfileDetail>
            )}
            <ProfileDetail>
              {patient.tel && (
                <>
                  <ProfileDetail.Label>連絡先</ProfileDetail.Label>
                  <ProfileDetail.Item>{patient.tel}</ProfileDetail.Item>
                </>
              )}
              {
                // 緊急連絡氏名、緊急連絡先住所、緊急連絡先電話番号等が表示
                patient.emergency && (
                  <>
                    <ProfileDetail.Label>緊急連絡先</ProfileDetail.Label>
                    <ProfileDetail.Item>{patient.emergency}</ProfileDetail.Item>
                  </>
                )
              }
            </ProfileDetail>
            <ProfileDetail>
              {patient.bloodType && (
                <>
                  <ProfileDetail.Label>血液型</ProfileDetail.Label>
                  <ProfileDetail.Item>{patient.bloodType}</ProfileDetail.Item>
                </>
              )}
              {patient.weight && (
                <>
                  <ProfileDetail.Label>体重</ProfileDetail.Label>
                  <ProfileDetail.Item>{`${patient.weight}kg`}</ProfileDetail.Item>
                </>
              )}
            </ProfileDetail>
          </Box>
        </Box>
      ) : (
        <EmptyProfile />
      )}
    </Box>
  );
});

PatientProfile.displayName = 'PatientProfile';
