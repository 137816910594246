import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';

import { Alert, Button, Modal } from '~/components/blocks';
import { toYYYYMMDDja } from '~/utils/date';

type Props = {
  startAt: Date;
  onClosed: () => void;
};

const HelpLink = styled('a')(({ theme }) =>
  css({
    color: theme.colors.text.link,
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

export const InstallPanel = (props: Props) => {
  const theme = useTheme();
  const router = useRouter();
  const { startAt, onClosed } = props;
  const [accessTransitioning, setAccessTransitioning] = useState(false);

  const handleClickAccess = useCallback(async () => {
    setAccessTransitioning(true);
    await router.push(`${process.env.pharmsClientAuthUrl}`);
    onClosed();
  }, [onClosed, router]);

  return (
    <>
      <Modal.Header>認証ページでクライアント認証の設定を完了させてください</Modal.Header>
      <Modal.Body>
        <Alert status="info">
          {toYYYYMMDDja(startAt)}以降Pharmsをご利用いただくには、クライアント証明書が
          <br />
          必要です。認証ページにアクセスし設定を完了させてください。
          <br />
          発行された証明書のインストールがお済みでない方は、ご利用の端末にイン
          <br />
          ストールをしてください。
          <br />
          インストール方法は
          <HelpLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://intercom.help/pharms/ja/articles/6151561-%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E8%AA%8D%E8%A8%BC%E3%82%92%E6%9C%89%E5%8A%B9%E5%8C%96%E3%81%99%E3%82%8B"
          >
            こちら
          </HelpLink>
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button
          use="base"
          marginLeft={theme.space.l}
          onClick={handleClickAccess}
          loading={accessTransitioning}
          disabled={accessTransitioning}
        >
          認証ページにアクセス
        </Button>
      </Modal.Footer>
    </>
  );
};
