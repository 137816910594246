import React, { useMemo } from 'react';
import { useRecoilCallback } from 'recoil';

import { ReceiveOption } from '~/components/partials/ReceiveOption';
import {
  AppointmentDeliveryMethod,
  AppointmentStatus,
  KarteAppointmentFragment,
  ReceptionPaneAppointmentFragment,
  UberDeliveryStatus,
} from '~/graphql';
import { changeDeliveryMethodState } from '~/state/partials/change_delivery_method_modal/atoms';
import { ChangeDeliveryMethodModal } from '~/state/partials/change_delivery_method_modal/types';

type Props = {
  appointment: KarteAppointmentFragment | ReceptionPaneAppointmentFragment;
  children?: React.ReactNode;
  isOnline: boolean;
};

export const ReceiveOptionBlock = (props: Props) => {
  const { appointment, children, isOnline } = props;
  const deliveryMethod = appointment.deliveryMethod;
  const appointmentStatus = appointment.status;

  const onClick = useRecoilCallback(
    ({ set }) =>
      () =>
        set(changeDeliveryMethodState, (_state: ChangeDeliveryMethodModal) => ({
          ..._state,
          isOpen: true,
          appointmentId: appointment.id,
        })),
    [appointment],
  );

  const color = useMemo(() => {
    if (deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery) {
      return 'cyan';
    } else if (deliveryMethod === AppointmentDeliveryMethod.Hand) {
      return 'grey05';
    } else {
      return 'grey';
    }
  }, [deliveryMethod]);

  const text = useMemo(() => {
    // 当日配達希望（オンラインが前提）
    if (deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery) {
      return '当日配達を希望';
    }

    // 当日配達以外のオンライン服薬指導
    if (deliveryMethod === AppointmentDeliveryMethod.Hand) {
      // 途中で変更がされたということ
      return '薬局に取りに来る';
    } else {
      return '配達';
    }
  }, [deliveryMethod]);

  const handleClick = useMemo(() => {
    if (
      appointmentStatus === AppointmentStatus.Finished ||
      appointmentStatus === AppointmentStatus.Cancelled ||
      appointmentStatus === AppointmentStatus.Noshow ||
      appointmentStatus === AppointmentStatus.Charged ||
      appointmentStatus === AppointmentStatus.Pending
    ) {
      return undefined;
    }

    if (!isOnline && appointmentStatus === AppointmentStatus.Booked) {
      return undefined;
    }

    const uberDelivery = appointment.uberDelivery;
    if (
      uberDelivery &&
      (uberDelivery.status === UberDeliveryStatus.Pickup ||
        uberDelivery.status === UberDeliveryStatus.Dropoff ||
        uberDelivery.status === UberDeliveryStatus.Delivered ||
        uberDelivery.status === UberDeliveryStatus.Canceled)
    ) {
      return undefined;
    }

    return onClick;
  }, [appointment.uberDelivery, appointmentStatus, isOnline, onClick]);

  return (
    <ReceiveOption color={color} text={text} onClick={handleClick}>
      {children}
    </ReceiveOption>
  );
};
