import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Flex, Pagination } from '~/components/blocks';
import { medicationFollowupNotificationPanelState } from '~/state/layouts/SharedAppShell/atoms';

import { NotificationPanelBase } from '../NotificationPanelBase';
import { NotificationList } from './NotificationList';

export const MedicationFollowupNotificationPanel = () => {
  const [state, setState] = useRecoilState(medicationFollowupNotificationPanelState);

  const handleChange = useCallback(
    (page: number) => setState((_state) => ({ ..._state, page })),
    [setState],
  );

  return (
    <NotificationPanelBase>
      <NotificationPanelBase.Content>
        <NotificationList />
      </NotificationPanelBase.Content>
      {state.totalCount > 0 && (
        <NotificationPanelBase.Footer>
          <Flex justifyContent="center">
            <Pagination
              currentPage={state.page}
              totalPage={state.totalPage}
              onChange={handleChange}
            />
          </Flex>
        </NotificationPanelBase.Footer>
      )}
    </NotificationPanelBase>
  );
};
