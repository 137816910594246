import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Skeleton } from '~/components/blocks';

import { ContactList } from './ContactList';

const Root = styled('div')(({ theme }) =>
  css({
    [`${ContactList.Item} + ${ContactList.Item}`]: {
      marginTop: theme.space.m,
    },
  }),
);

export const ProfileDetailAppSkeleton = () => {
  return (
    <Root>
      <ContactList>
        <ContactList.Item>
          <Skeleton width="60px" />
        </ContactList.Item>
        <ContactList.Item>
          <Skeleton width="80px" />
        </ContactList.Item>
        <ContactList.Item>
          <Box>
            <Skeleton width="40px" />
            <Skeleton width="80px" />
          </Box>
        </ContactList.Item>
        <ContactList.Item>
          <Skeleton width="60px" />
        </ContactList.Item>
      </ContactList>
    </Root>
  );
};
