import { atom } from 'recoil';

import { SentFaxDetailDrawer, SentFaxPageInfo } from './types';

export const sentFaxPageInfoState = atom<SentFaxPageInfo>({
  key: 'fax.sentFaxPageInfo',
  default: {
    page: 1,
    totalPage: 1,
    totalCount: 0,
    perPage: 25,
    date: new Date(),
    searchWord: null,
  },
});

export const sentFaxDeatilDrawerState = atom<SentFaxDetailDrawer>({
  key: 'fax.sentFaxDetailDrawer',
  default: {
    isOpen: false,
    faximoSendFaxId: null,
  },
});
