import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Grid } from '~/components/blocks';
import { RootMenu } from '~/components/layouts/CompanySecurity/RootMenu';

type Props = {
  children: React.ReactNode;
};

const Menu = styled(Box)(({ theme }) =>
  css({
    background: theme.colors.background.default,
    borderRight: `1px solid ${theme.colors.border.grey}`,
    boxShadow: `0 2px 8px ${theme.colors.background.shadowgrey}`,
    height: '100%',
    width: '200px',
    maxWidth: '336px',
    overflow: 'auto',
    [`@media ${theme.mediaQueries.tablet}`]: {
      maxWidth: '246px',
    },
  }),
);

export const CompanySecurity: React.FC<Props> = (props) => {
  return (
    <Grid
      position="absolute"
      height="100%"
      width="100%"
      gridTemplateRows="1fr"
      gridTemplateColumns="min-content 1fr"
      top="0"
      left="0"
    >
      <Menu>
        <RootMenu />
      </Menu>
      {props.children}
    </Grid>
  );
};
