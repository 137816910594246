import { atom } from 'recoil';

import { SearchCsClinicPageInfo } from './types';

export const searchCsClinicsPageInfoState = atom<SearchCsClinicPageInfo>({
  key: 'partials.searchCsClinics.pageInfo',
  default: {
    categoryKey: null,
    cityCode: null,
    prefectureCode: null,
    query: null,
    page: 1,
    perPage: 4,
    totalPage: 1,
  },
});
