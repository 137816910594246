import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { FormikErrors } from 'formik';
import React, { useCallback } from 'react';

import { FieldError } from '~/components/blocks';
import { numberRegexp } from '~/constants/regexp';
import {
  LineItemCode,
  OptionalLineCompanyFeeSettingFragment,
  OptionalLineFeeSettingFragment,
  OptionalLineOrganizationCompanyFeeSettingFragment,
} from '~/graphql';
import { toHalfNumber } from '~/utils/convert';
import { publicPath } from '~/utils/path';

export type OptionalLineItem = {
  feeSettingId: string;
  code: LineItemCode | '';
  subject: string;
  amount: number | string;
};

type Props = {
  disabled?: boolean;
  feeSettings: (
    | OptionalLineFeeSettingFragment
    | OptionalLineCompanyFeeSettingFragment
    | OptionalLineOrganizationCompanyFeeSettingFragment
  )[];
  values: OptionalLineItem;
  errors?: FormikErrors<OptionalLineItem>;
  onChange: (values: OptionalLineItem) => void;
};

const Select = styled('select', { shouldForwardProp })<{ error: boolean }>(
  ({ theme }) =>
    css({
      padding: `${theme.space.m} ${theme.space.xl} ${theme.space.m} ${theme.space.m}`,
      width: '200px',
      border: 'none',
      borderRadius: `${theme.radii.default} 0 0 ${theme.radii.default}`,
      verticalAlign: 'middle',
      transitionDuration: theme.transitions.fast,
      cursor: 'pointer',
      appearance: 'none',
      backgroundImage: `url(${publicPath('/icon-arrow-down.svg')})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '100%',
      backgroundSize: '16px',
      color: theme.colors.text.default,
      '&:hover': {
        boxShadow: `0 0 0 1px ${theme.colors.colorPallete.green} inset`,
      },
    }),
  ({ theme, error }) =>
    error &&
    css({
      '&:hover': {
        boxShadow: `0 0 0 1px ${theme.colors.colorPallete.pink} inset`,
      },
      '&:focus': {
        boxShadow: `0 0 0 1px ${theme.colors.colorPallete.pink} inset`,
      },
    }),
);

const TextField = styled('input', { shouldForwardProp })<{ error: boolean }>(
  ({ theme }) =>
    css({
      flex: 1,
      width: '100%',
      fontSize: theme.fontSizes.l,
      fontWeight: theme.fontWeights.bold,
      padding: `${theme.space.m} ${theme.space.xxl} ${theme.space.m} ${theme.space.l}`,
      border: 'none',
      borderRadius: `$0 {theme.radii.default} ${theme.radii.default} 0`,
      backgroundColor: theme.colors.background.bg,
      color: theme.colors.text.default,
      transition: theme.transitions.default,
      outline: 0,
      textAlign: 'right',
      '&::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '&::placeholder': {
        color: theme.colors.text.placeholder,
      },
      '&:hover': {
        boxShadow: `0 0 0 1px ${theme.colors.colorPallete.green} inset`,
      },
      '&:focus': {
        boxShadow: `0 0 0 1px ${theme.colors.colorPallete.green} inset`,
        backgroundColor: theme.colors.background.default,
      },
    }),
  ({ theme, error }) =>
    error &&
    css({
      backgroundColor: theme.colors.background.alert,
      '&:hover': {
        boxShadow: `none`,
      },
      '&:focus': {
        boxShadow: `1px 0 0 0 ${theme.colors.colorPallete.pink} inset`,
      },
    }),
);

const Field = styled('div', { shouldForwardProp })<{ error: boolean }>(
  ({ theme }) =>
    css({
      position: 'relative',
      display: 'flex',
      width: '100%',
      border: theme.borders.default,
      borderRadius: theme.radii.default,
      color: theme.colors.text.default,
      overflow: 'hidden',
      '&::after': {
        content: '"円"',
        position: 'absolute',
        right: theme.space.m,
        bottom: theme.space.m,
        fontSize: theme.fontSizes.s,
      },
    }),
  ({ theme, error }) =>
    error &&
    css({
      borderColor: theme.colors.colorPallete.pink,
    }),
);

const Root = styled('div')(({ theme }) =>
  css({
    width: '400px',
    '& + &': {
      marginTop: theme.space.m,
    },
  }),
);

export const OptionalLineItemField = React.memo((props: Props) => {
  const { onChange } = props;
  const handleChangeCode = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const feeSettingId = e.currentTarget.value;
      const feeSetting = props.feeSettings.find(({ id }) => id === feeSettingId);

      if (feeSetting) {
        onChange({
          feeSettingId,
          code: feeSetting.code,
          subject: feeSetting.subject,
          amount: feeSetting.defaultAmount ?? props.values.amount,
        });
      } else {
        onChange({
          feeSettingId: '',
          code: '',
          subject: '',
          amount: '',
        });
      }
    },
    [onChange, props.feeSettings, props.values.amount],
  );
  const handleChangeAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const amount = e.currentTarget.value;
      onChange({
        ...props.values,
        amount: amount ?? '',
      });
    },
    [onChange, props.values],
  );
  const handleBlurAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!numberRegexp.test(e.currentTarget.value)) return;
      const amount = toHalfNumber(e.currentTarget.value);
      onChange({
        ...props.values,
        amount: amount ?? '',
      });
    },
    [onChange, props.values],
  );

  return (
    <Root>
      <Field error={!!props.errors}>
        <Select
          error={!!props.errors}
          disabled={props.disabled}
          value={props.values.feeSettingId}
          onChange={handleChangeCode}
        >
          <option hidden value="">
            選択してください
          </option>
          {props.feeSettings.map((feeSetting) => (
            <option key={feeSetting.id} value={feeSetting.id}>
              {feeSetting.subject}
            </option>
          ))}
        </Select>
        <TextField
          error={!!props.errors}
          disabled={props.disabled}
          inputMode="numeric"
          value={props.values.amount}
          onChange={handleChangeAmount}
          onBlur={handleBlurAmount}
        />
      </Field>
      {!!props.errors && <FieldError error="金額を入力してください" />}
    </Root>
  );
});

OptionalLineItemField.displayName = 'OptionalLineItemField';
