import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '~/components/blocks';
import { openPreviewPage } from '~/utils/pf_dispensing_request';

type Props = {
  appointmentId: string;
  patientId: string;
};

export const PendingButtons = React.memo((props: Props) => {
  const { appointmentId } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(() => {
    setIsLoading(true);

    openPreviewPage(appointmentId, true);
  }, [appointmentId]);

  useEffect(() => {
    let timerId: number | undefined = undefined;

    if (isLoading) {
      // ウィンドウ起動中であることが視覚的にわかるように一定時間おく
      timerId = window.setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [isLoading]);

  return (
    <Button use="base" wide="fill" size="l" loading={isLoading} onClick={handleClick}>
      処方箋確認
    </Button>
  );
});

PendingButtons.displayName = 'PendingButtons';
