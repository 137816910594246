import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

type BannerPath = {
  smartphone: string;
  tablet: string;
};

type Props = {
  bannerPath: BannerPath;
  downloadLink: string;
};

const Content = styled('div')(({ theme }) =>
  css({
    position: 'relative',
    display: 'block',
    marginTop: `${theme.space.l}`,
    width: '100%',
  }),
);

const AppBnrSp = styled('img')(({ theme }) =>
  css({
    display: 'none',
    [`@media ${theme.mediaQueries.sp}`]: {
      display: 'block',
      margin: '0 auto',
      width: '100%',
    },
    borderRadius: '8px',
    boxShadow: theme.shadows.default,
  }),
);

const AppBnrTb = styled('img')(({ theme }) =>
  css({
    display: 'block',
    [`@media ${theme.mediaQueries.sp}`]: {
      display: 'none',
    },
    borderRadius: '8px',
    boxShadow: theme.shadows.default,
  }),
);

export const AppPromotion = React.memo((props: Props) => {
  return (
    <Content>
      <a target="_blank" rel="noreferrer noopener" href={props.downloadLink}>
        <AppBnrSp src={props.bannerPath.smartphone} />
        <AppBnrTb src={props.bannerPath.tablet} />
      </a>
    </Content>
  );
});

AppPromotion.displayName = 'AppPromotion';
