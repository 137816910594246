import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';

import { Box, Loader } from '~/components/blocks';
import {
  DirectVisitorDetailDialogProfileFragment,
  PatientDetailDialogProfileFragment,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';
import { isIpad } from '~/utils/user_agent';

import { PatientDrawer } from './PatientDrawer';

type Props = {
  patient?:
    | DirectVisitorDetailDialogProfileFragment
    | WebVisitorDetailDialogProfileFragment
    | PatientDetailDialogProfileFragment;
  width?: string;
  isPharmacist: boolean;
};

const Patient = styled(Box)<{ isOpen: boolean; isPharmacist: boolean }>(
  ({ theme, isOpen, isPharmacist }) =>
    css({
      borderLeft: theme.borders.default,
      height: '100%',
      overflow: 'auto',
      padding: !isPharmacist
        ? `${theme.space.xl} ${theme.space.l}`
        : isPharmacist && isOpen
          ? `${theme.space.xl} ${theme.space.l} 0 ${theme.space.l}`
          : 0,
    }),
);

export const PatientPane = React.memo((props: Props) => {
  const { patient } = props;
  const [isOpen, setIsOpen] = useState(!isIpad());

  const handleClick = useCallback(() => setIsOpen((_state) => !_state), []);

  if (!patient) {
    return <Loader open inside />;
  }

  return (
    <Patient isOpen={isOpen} isPharmacist={props.isPharmacist}>
      <PatientDrawer
        isOpen={!isOpen && !props.isPharmacist ? true : isOpen}
        patient={patient}
        onClick={handleClick}
        width={props.width}
        isPharmacist={props.isPharmacist}
      />
    </Patient>
  );
});

PatientPane.displayName = 'PatientPane';
