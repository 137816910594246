import { atom } from 'recoil';

import { IntercomUnreadCount, PermissionModal } from './types';

export const permissionModalState = atom<PermissionModal>({
  key: 'layout.permissionModal',
  default: {
    isOpen: false,
  },
});

export const intercomUnreadCountState = atom<IntercomUnreadCount>({
  key: 'layout.intercomUnreadCountState',
  default: {
    unreadCount: 0,
  },
});
