import { useEffect } from 'react';

import { useGetPatientAppointmentCountLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useExistsAppointment = (patientId: string) => {
  const [getAppointments, { data, loading }] = useGetPatientAppointmentCountLazyQuery();
  const exists = (getNode(data, 'Patient')?.appointments.nodesCount || 0) > 0;

  useEffect(() => {
    if (patientId) {
      getAppointments({ variables: { patientId } });
    }
  }, [getAppointments, patientId]);

  return { exists, loading };
};
