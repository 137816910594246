import React from 'react';

import { Box, Text } from '~/components/blocks';
import { ScheduledDirectVisitorMessageFragment, ScheduledPatientMessageFragment } from '~/graphql';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import { PanelType } from '~/state/partials/message_modal/types';

import { ScheduledMessage } from './ScheduledMessage';

type Props = {
  scheduledMessages: ScheduledDirectVisitorMessageFragment[] | ScheduledPatientMessageFragment[];
  onClick?: (panel: PanelType, messageId: string) => void;
};

export const ScheduledMessageList = React.memo((props: Props) => {
  const { verifyRole } = usePractitionerRole();
  const isVerified = verifyRole('pharmacist');

  return (
    <Box>
      <Text block color="green" fontWeight="bold" size="s">
        送信予定
      </Text>
      {props.scheduledMessages.map((message, idx) => {
        return (
          <ScheduledMessage
            key={idx}
            clickable={isVerified && !!props.onClick}
            message={message}
            onClick={props.onClick}
          />
        );
      })}
    </Box>
  );
});

ScheduledMessageList.displayName = 'ScheduledMessageList';
