import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import {
  PatientProfileFollowupPatientFragment,
  useGetPatientProfileFollowupAppointmentLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { medicationFollowupModalState } from '~/state/partials/patient_profile_drawer/atoms';

type Patient = {
  loading: boolean;
  patient?: PatientProfileFollowupPatientFragment;
};

export const useFetchPatient = (): Patient => {
  const { isOpen, appointmentId } = useRecoilValue(medicationFollowupModalState);

  const [getAppointment, { loading, data }] = useGetPatientProfileFollowupAppointmentLazyQuery();
  const patient = getNode(data, 'Appointment')?.patient || undefined;

  useEffect(() => {
    if (isOpen && appointmentId) {
      getAppointment({ variables: { appointmentId } });
    }
  }, [appointmentId, getAppointment, isOpen]);

  return { loading, patient };
};
