import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, space, SpaceProps, variant } from 'styled-system';

import { ColorPalette, MonoColor } from '~/styles/theme';
import { Size } from '~/styles/types';

type Props = SpaceProps & {
  size?: Size | 'fixed';
  use?: 'reception';
  color?: keyof typeof ColorPalette | keyof typeof MonoColor;
  textColor?: keyof typeof MonoColor | keyof typeof MonoColor;
  width?: string;
};

export const Chip = styled('div', {
  shouldForwardProp,
})<Props>(
  ({ color, textColor, width }) =>
    css({
      borderRadius: '100px',
      color: textColor
        ? ColorPalette[textColor as keyof typeof ColorPalette] ||
          MonoColor[textColor as keyof typeof MonoColor]
        : MonoColor.white,
      fontWeight: 600,
      display: 'inline-block',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      background: color
        ? ColorPalette[color as keyof typeof ColorPalette] ||
          MonoColor[color as keyof typeof MonoColor]
        : MonoColor.grey05,
      width: width,
    }),
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'size',
        variants: {
          s: css({
            padding: `${theme.space.xs} ${theme.space.m}`,
            fontSize: theme.fontSizes.xs,
          }),
          m: css({
            padding: `${theme.space.s} ${theme.space.l}`,
            fontSize: theme.fontSizes.m,
            lineHeight: theme.lineHeights.s,
          }),
          l: css({
            padding: `${theme.space.s} ${theme.space.xl}`,
            fontSize: theme.fontSizes.l,
          }),
          fixed: css({
            padding: `${theme.space.xs} ${theme.space.m}`,
            fontSize: theme.fontSizes.s,
            minWidth: '90px',
            [`@media ${theme.mediaQueries.tablet}`]: {
              padding: `${theme.space.xs} ${theme.space.s}`,
              fontSize: theme.fontSizes.xs,
              minWidth: '80px',
            },
          }),
        },
      }),
    ),
);

Chip.defaultProps = {
  size: 'm',
  color: 'green',
};
