import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { PatientProfileTabMap } from '~/constants/tab';
import {
  BookmarkFragment,
  PatientProfilePfPatientFragment,
  useGetPatientProfilePfPatientLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { profileDetailTabState } from '~/state/partials/patient_profile_drawer/atoms';

type PfPatient = {
  loading: boolean;
  pfPatient?: PatientProfilePfPatientFragment | null;
  bookmark?: BookmarkFragment | null;
};

export const useFetchPfPatient = (patientId: string | null): PfPatient => {
  const [getPfPatient, { loading, data }] = useGetPatientProfilePfPatientLazyQuery();

  const { isExpanded, tab } = useRecoilValue(profileDetailTabState);

  const isOpen = isExpanded && tab === PatientProfileTabMap.app;
  const pfPatient = getNode(data, 'Patient')?.pfPatient;
  const bookmark = getNode(data, 'Patient')?.bookmark;

  useEffect(() => {
    if (isOpen && patientId) {
      getPfPatient({
        variables: {
          patientId,
        },
      });
    }
  }, [getPfPatient, isOpen, patientId]);

  return { loading, pfPatient, bookmark };
};
