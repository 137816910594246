import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Box, Loader, Text } from '~/components/blocks';
import { ClipButton } from '~/components/partials';
import { QuestionnaireSheetAnswerFragment, QuestionnaireSheetFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { Interview } from '../Interview';

type Props = {
  copyable?: boolean;
  loading: boolean;
  sheet?: QuestionnaireSheetFragment;
};

const buildText = (updatedAt: string | null, answers: QuestionnaireSheetAnswerFragment[]) => {
  const updatedDate = updatedAt ? `最終更新日: ${Label.YYYYMMDDja(updatedAt)}` : null;
  const answersText = answers.map(({ title, value }) => {
    return `Q：${title}
${value.join('\n')}`;
  });
  return `${updatedDate}
${answersText.join('\n\n')}`;
};

export const QuestionnaireSheet = React.memo((props: Props) => {
  const { copyable, sheet } = props;
  const theme = useTheme();

  const updatedAt = sheet ? `最終更新日: ${Label.YYYYMMDDja(sheet.updatedAt)}` : null;

  return (
    <Box>
      {updatedAt && (
        <Text color="grey01" size="s" mb={theme.space.m}>
          {updatedAt}
        </Text>
      )}
      {props.loading ? (
        <Loader open inside appearance="white" />
      ) : sheet ? (
        <>
          {sheet.answers.map((q, i) => (
            <Interview key={i}>
              <Interview.Title>
                <Interview.Icon>Q</Interview.Icon>
                {q.title}
              </Interview.Title>
              <Interview.Contents>
                {q.value.map((v, l) => (
                  <Text block key={l}>
                    {v}
                  </Text>
                ))}
              </Interview.Contents>
            </Interview>
          ))}
          {copyable && (
            <ClipButton
              iconSize="m"
              size="s"
              wide="fill"
              marginTop="l"
              text={buildText(sheet.updatedAt, sheet.answers)}
            />
          )}
        </>
      ) : (
        <Alert status="info" mt={theme.space.m}>
          問診票はありません
        </Alert>
      )}
    </Box>
  );
});

QuestionnaireSheet.displayName = 'QuestionnaireSheet';
