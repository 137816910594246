import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Property } from 'node_modules/csstype';
import { rgba } from 'polished';
import {
  compose,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  variant,
  width,
  WidthProps,
} from 'styled-system';

type RootProps = SpaceProps & {
  tableLayout?: 'auto' | 'fixed';
  appearance?: 'noline';
  whiteSpace?: Property.WhiteSpace;
};

type TrProps = {
  disabled?: boolean;
  onClick?: () => void;
};

type TdProps = {
  nowrap?: boolean;
};

const Th = styled('th', {
  shouldForwardProp,
})<TextAlignProps & WidthProps & TdProps>(
  width,
  ({ theme, nowrap }) =>
    css({
      padding: `${theme.space.s} ${theme.space.m}`,
      fontSize: theme.fontSizes.xs,
      fontWeight: theme.fontWeights.bold,
      textAlign: 'left',
      [`@media ${theme.mediaQueries.tablet}`]: {
        fontSize: theme.fontSizes.xxs,
      },
      ...(nowrap && {
        whiteSpace: 'nowrap',
      }),
    }),
  () =>
    compose(
      textAlign,
      variant({
        prop: 'align',
        variants: {
          right: css({
            textAlign: 'right',
          }),
        },
      }),
    ),
);

const Td = styled('td', {
  shouldForwardProp,
})<TextAlignProps & TdProps>(
  ({ theme, nowrap }) =>
    css({
      padding: theme.space.m,
      borderBottom: theme.borders.default,
      fontSize: theme.fontSizes.m,
      wordBreak: 'break-all',
      [`@media ${theme.mediaQueries.tablet}`]: {
        fontSize: theme.fontSizes.s,
      },
      ...(nowrap && {
        whiteSpace: 'nowrap',
      }),
    }),
  () => compose(textAlign),
);

const Tr = styled('tr', {
  shouldForwardProp,
})<TrProps>(({ theme, disabled, onClick }) =>
  css({
    backgroundColor: theme.colors.background.default,
    ...(onClick && {
      cursor: 'pointer',
    }),
    ...(disabled && {
      [`> ${Td}`]: {
        backgroundColor: theme.colors.background.bg,
      },
    }),
  }),
);

const Thead = styled('thead')(({ theme }) =>
  css({
    borderBottom: theme.borders.default,
    [`& > ${Tr}`]: css({
      backgroundColor: 'transparent',
    }),
  }),
);

const Tbody = styled('tbody')(({ theme }) =>
  css({
    [`& > ${Tr}`]: css({
      transitionDuration: theme.transitions.default,
    }),
    [`& > ${Tr}:hover`]: css({
      backgroundColor: theme.colors.background.bg,
    }),
  }),
);
const Tfoot = styled('tfoot')(() => css({}));

const Root = styled('table', {
  shouldForwardProp,
})<RootProps>(
  ({ tableLayout = 'auto', whiteSpace }) =>
    css({
      width: '100%',
      tableLayout,
      borderCollapse: 'collapse',
      borderSpacing: 0,
      whiteSpace,
    }),
  () => compose(space),
  ({ theme }) =>
    compose(
      variant({
        prop: 'appearance',
        variants: {
          noline: css({
            [`${Td}`]: {
              borderBottom: theme.borders.tableNoline,
              padding: `${theme.space.l} ${theme.space.m}`,
              [`@media ${theme.mediaQueries.tablet}`]: {
                padding: `${theme.space.s} ${theme.space.m}`,
              },
            },
            [`${Thead}`]: {
              borderBottom: theme.borders.tableNoline,
            },
            [`${Tbody} > ${Tr}:hover`]: css({
              backgroundColor: rgba(theme.colors.background.default, 0.5),
              boxShadow: `inset 3px 0 0 ${theme.colors.background.primary}`,
            }),
          }),
        },
      }),
    ),
);

export const Table = Object.assign(Root, { Thead, Tbody, Tfoot, Th, Td, Tr });
