import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ComponentProps } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Box, Chip, Flex, Icon, Text } from '~/components/blocks';
import { DraftAppointmentStatus } from '~/graphql';

type Props = {
  size?: ComponentProps<typeof Chip>['size'];
  status: DraftAppointmentStatus;
};

const DetailedStatus = styled(Box)(() =>
  css({
    position: 'relative',
    top: '3px',
  }),
);

export const DraftAppointmentStatusLabel = (props: Props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery({ query: theme.mediaQueries.tablet });
  const { status, ...componentProps } = props;

  if (
    status === DraftAppointmentStatus.Cancelled ||
    status === DraftAppointmentStatus.NotPrescribed
  ) {
    return (
      <Box>
        <Chip size="fixed" {...componentProps} color="yellow" textColor="black">
          キャンセル
        </Chip>
      </Box>
    );
  }

  if (status === DraftAppointmentStatus.Available) {
    return (
      <Flex flexDirection="column" alignContent="center">
        <Box>
          <Chip size="fixed" {...componentProps} color="green" textColor="white">
            未受付
          </Chip>
        </Box>
        <DetailedStatus>
          <Icon icon="drug" size={isTablet ? 's' : 'm'} color="pink" />
          <Text color="pink" fontWeight="bold" size={isTablet ? 'xs' : 's'}>
            処方箋待ち
          </Text>
        </DetailedStatus>
      </Flex>
    );
  }

  if (status === DraftAppointmentStatus.WaitForBooking) {
    return (
      <Flex flexDirection="column" alignContent="center">
        <Box>
          <Chip size="fixed" {...componentProps} color="green" textColor="white">
            未受付
          </Chip>
        </Box>
        <DetailedStatus>
          <Icon icon="calender" size={isTablet ? 's' : 'm'} color="pink" />
          <Text color="pink" fontWeight="bold" size={isTablet ? 'xs' : 's'}>
            日時指定待ち
          </Text>
        </DetailedStatus>
      </Flex>
    );
  }

  return (
    <Chip size="fixed" {...componentProps} color="grey03" textColor="black">
      完了
    </Chip>
  );
};
