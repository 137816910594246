import type Firebase from 'firebase/compat';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useFirebase } from '~/hooks/use-firebase';

export type Status = 'loading' | 'locking' | 'finished';

type Props = {
  patientId: string | null;
};

/**
 * メッセージ作成時の排他ロック用Hook
 * @param props
 */
export const useExclusiveLock = (props: Props) => {
  const { patientId } = props;
  const { auth, db } = useFirebase();
  const [status, setStatus] = useState<Status>('loading');
  const [error, setError] = useState<Error | null>(null);
  const firebaseUid = auth.currentUser?.uid;
  const medicationFollowupMessageRef = useMemo(
    () => db.ref(`/medicationFollowupMessages/${patientId}/${firebaseUid}`),
    [db, firebaseUid, patientId],
  );
  const lockRef = useRef<Firebase.database.Reference>();

  /**
   * 排他ロック用リファレンスからロック開始時間を削除
   */
  const unregisterLock = useCallback(async () => {
    if (lockRef.current) {
      lockRef.current.onDisconnect().cancel();
      return await medicationFollowupMessageRef.remove();
    } else {
      return null;
    }
  }, [medicationFollowupMessageRef]);

  /**
   * 排他ロック用リファレンスにロック開始時間を登録
   */
  const registerLock = useCallback(async () => {
    unregisterLock();

    const ref = medicationFollowupMessageRef.child(`/time`);
    lockRef.current = ref;
    // https://firebase.google.com/docs/reference/node/firebase.database.OnDisconnect
    // ブラウザの終了等で削除する
    ref.onDisconnect().remove();

    await ref.set(Date.now());
  }, [medicationFollowupMessageRef, unregisterLock]);

  /**
   * 排他ロック開始
   */
  const lock = useCallback(async () => {
    if (!firebaseUid) {
      const error = new Error('エラーが発生しました');
      error.name = 'AuthError';
      setError(error);
      return;
    }
    if (!patientId) {
      const error = new Error('エラーが発生しました');
      error.name = 'PatientError';
      setError(error);
      return;
    }

    const snapshot = await medicationFollowupMessageRef.once('value');
    if (snapshot.exists()) {
      const error = new Error('メッセージの同時編集はできません');
      error.name = 'ExclusiveLockError';
      setError(error);
      return;
    }

    registerLock();

    setStatus('locking');
  }, [firebaseUid, medicationFollowupMessageRef, patientId, registerLock]);

  /**
   * 排他ロック終了
   */
  const unlock = useCallback(() => {
    unregisterLock();

    setStatus('finished');
  }, [unregisterLock]);

  return {
    lock,
    unlock,
    status,
    error,
    setError,
  };
};
