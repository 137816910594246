import { PureQueryOptions } from '@apollo/client';
import { useCallback, useState } from 'react';

import { BASE_DATE } from '~/constants/organization_notification';
import {
  GetUnreadMedicationFollowupQuestionnaireSheetsDocument,
  useViewedQuestionnaireSheetMutation,
} from '~/graphql';

export const useViewSheet = (refetchQueries?: PureQueryOptions[]) => {
  const [isViewing, setIsViewing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [viewSheet] = useViewedQuestionnaireSheetMutation();

  const view = useCallback(
    async (sheetId: string) => {
      setIsViewing(true);
      setError(null);

      const queries: PureQueryOptions[] = (refetchQueries || []).concat([
        {
          query: GetUnreadMedicationFollowupQuestionnaireSheetsDocument,
          variables: { from: BASE_DATE },
        },
      ]);
      try {
        await viewSheet({
          variables: {
            input: {
              medicationFollowupQuestionnaireSheetId: sheetId,
            },
          },
          refetchQueries: queries,
        });
      } catch (error) {
        setError(error?.message || 'エラーが発生しました');
      }
      setIsViewing(false);
    },
    [refetchQueries, viewSheet],
  );

  return {
    isViewing,
    error,
    view,
  };
};
