import { QuestionnaireSheetAnswer } from '~/graphql';

export const buildText = (answerdAt: string, answers: QuestionnaireSheetAnswer[]) => {
  const answerDate = `【回答日】${answerdAt}`;
  const answersText = answers.map(({ title, value }) => {
    return `Q：${title}
${value.join('｜')}`;
  });
  return `${answerDate}
${answersText.join('\n')}`;
};
