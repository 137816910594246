import { Formik, FormikProps } from 'formik';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { Loader } from '~/components/blocks';
import { CsClinicItemFragment, MedicationFollowupQuestionnairesSelectFragment } from '~/graphql';

import { ConfirmPane } from './ConfirmPane';
import { InputPane } from './InputPane';
import { Fields } from './InputPane/types';
import { validationSchema } from './InputPane/validation';
import { RecommendationPane } from './RecommendationPane';
import { PaneType, Patient } from './types';
import { useDefaultValues } from './use-default_values';
import { useMarkNotification } from './use-mark-notification';

export const MAX_MESSAGE_LENGTH = 300;
export const MAX_SMS_MESSAGE_LENGTH = 100;

type Props = {
  disabled: boolean;
  error: string | null;
  upsertError: string | null;
  upserting: boolean;
  selectMessageId: string | null;
  patient: Patient;
  textRows?: number;
  currentPane: PaneType;
  onSubmit: (values: Fields, isFailed: boolean) => void;
  onBackInitial: () => void;
  onAutoSave: (values: Fields) => void;
  setCurrentPane: React.Dispatch<React.SetStateAction<PaneType>>;
};

export const MessageForm = forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const { selectMessageId, patient, onSubmit, currentPane, setCurrentPane } = props;
  const isEditable = !!selectMessageId;

  const {
    failed,
    loadingMessage,
    loadingDraftMessage,
    defaultClinic,
    defaultQuestionnaire,
    defaultValues,
    initialValues,
  } = useDefaultValues(selectMessageId, patient, isEditable);

  const [csClinic, setCsClinic] = useState<CsClinicItemFragment | null>(defaultClinic ?? null);
  const [questionnaire, setQuestionnaire] =
    useState<MedicationFollowupQuestionnairesSelectFragment | null>(defaultQuestionnaire ?? null);

  const maxMessageLength =
    props.patient.type === 'Patient' ? MAX_MESSAGE_LENGTH : MAX_SMS_MESSAGE_LENGTH;

  const schema = useMemo(() => validationSchema(maxMessageLength), [maxMessageLength]);

  const handleRecommendClinic = useCallback(() => {
    setCurrentPane('recommendation');
  }, [setCurrentPane]);
  const handleConfirm = useCallback(() => {
    setCurrentPane('confirm');
  }, [setCurrentPane]);
  const handleRecommendOrConfirm = useCallback(
    (values: Fields) => {
      if (values.attachment === 'clinic' && !csClinic) {
        setCurrentPane('recommendation');
      } else {
        setCurrentPane('confirm');
      }
    },
    [csClinic, setCurrentPane],
  );
  const handleBackInput = useCallback(() => setCurrentPane('input'), [setCurrentPane]);
  const handleRemoveClinic = useCallback(() => {
    setCsClinic(null);
  }, []);
  const handleSelectClinic = useCallback((_csClinic: CsClinicItemFragment) => {
    setCsClinic(_csClinic);
  }, []);
  const handleSubmit = useCallback(
    (values: Fields) => {
      onSubmit(values, failed);
      setCurrentPane('input');
    },
    [failed, onSubmit, setCurrentPane],
  );
  const handleSelectQuestionnaire = useCallback(
    (questionnaire: MedicationFollowupQuestionnairesSelectFragment) => {
      setQuestionnaire(questionnaire);
    },
    [],
  );
  const handleClickClear = useCallback(
    (formik: FormikProps<Fields>) => {
      formik.resetForm({ values: initialValues });
    },
    [initialValues],
  );

  useEffect(() => {
    if (props.error) {
      setCurrentPane('input');
    }
  }, [props.error, setCurrentPane]);

  useEffect(() => {
    setCsClinic(defaultClinic ?? null);
  }, [defaultClinic]);

  useEffect(() => {
    setQuestionnaire(defaultQuestionnaire ?? null);
  }, [defaultQuestionnaire]);
  useMarkNotification();

  return (
    <>
      {!loadingMessage && !loadingDraftMessage ? (
        <Formik
          enableReinitialize
          innerRef={ref}
          initialValues={defaultValues}
          validationSchema={schema}
          onSubmit={handleRecommendOrConfirm}
        >
          {(formik) => {
            return currentPane === 'input' ? (
              <InputPane
                editable={isEditable}
                disabled={loadingMessage}
                failed={failed}
                error={props.error}
                upsertError={props.upsertError}
                upserting={props.upserting}
                loadingDraftMessage={loadingDraftMessage}
                formik={formik}
                clinic={csClinic}
                maxLength={maxMessageLength}
                textRows={props.textRows}
                patientName={patient.name}
                onBack={props.onBackInitial}
                onRemoveClinic={handleRemoveClinic}
                onRecommendClinic={handleRecommendClinic}
                onSelectClinic={handleSelectClinic}
                onSelectQuestionnaire={handleSelectQuestionnaire}
                onAutoSave={props.onAutoSave}
                onClickClear={handleClickClear}
              />
            ) : currentPane === 'recommendation' ? (
              <RecommendationPane
                editable={isEditable}
                formik={formik}
                onBack={handleBackInput}
                onSelectClinic={handleSelectClinic}
                onConfirm={handleConfirm}
                onAutoSave={props.onAutoSave}
              />
            ) : currentPane === 'confirm' ? (
              <ConfirmPane
                sending={props.disabled}
                formik={formik}
                clinic={csClinic}
                questionnaire={questionnaire}
                onBack={handleBackInput}
                onSubmit={handleSubmit}
              />
            ) : null;
          }}
        </Formik>
      ) : (
        <Loader open inside appearance="white" />
      )}
    </>
  );
});

MessageForm.displayName = 'MessageForm';
