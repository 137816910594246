import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  position,
  PositionProps,
} from 'styled-system';

export const Flex = styled('div', {
  shouldForwardProp,
})<
  FlexboxProps &
    MarginProps &
    PaddingProps &
    PositionProps &
    LayoutProps & {
      as?: React.ElementType;
    }
>(flexbox, margin, padding, position, layout, ({ display = 'flex' }) =>
  css({
    display,
  }),
);
