import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Alert, Flex, Icon, Loader, Text, Tooltip } from '~/components/blocks';
import { ReceptionUberDeliveryFragment, UberDeliveryStatus } from '~/graphql';
import { Label } from '~/utils/label';

import { useFetchUberCourier } from './use-fetch_uber_courier';

type Props = {
  uberDelivery: ReceptionUberDeliveryFragment;
};

const Root = styled('div')(({ theme }) =>
  css({
    marginTop: theme.space.m,
    padding: theme.space.m,
    backgroundColor: 'white',
    borderRadius: theme.radii.default,
  }),
);

export const CourierInfo = React.memo((props: Props) => {
  const theme = useTheme();
  const { uberDelivery } = props;
  const uberDeliveryId = uberDelivery.id;
  const uberDeliveryStatus = uberDelivery.status;

  const { courier, loading } = useFetchUberCourier(uberDeliveryId);

  if (loading) {
    return (
      <Root>
        <Loader open inside appearance="white" logoSize="half" />
      </Root>
    );
  }

  if (uberDeliveryStatus === UberDeliveryStatus.Canceled) {
    return null;
  }

  return (
    <Root>
      {!courier ? (
        <Alert status="error" marginX={theme.space.m} padding="0">
          配達員の取得に失敗しました。
          <br />
          しばらく待ってから、再度アクセスしてください
        </Alert>
      ) : (
        <>
          <Flex alignItems="center">
            <Text size="s" fontWeight="bold">
              {uberDeliveryStatus === UberDeliveryStatus.Pickup &&
                courier.pickupScheduledAt &&
                `${courier.name}さんが${Label.HHMM(courier.pickupScheduledAt)}頃到着予定です`}
              {(uberDeliveryStatus === UberDeliveryStatus.Dropoff ||
                uberDelivery.status === UberDeliveryStatus.Canceled) &&
                `${courier.name}さんがお薬を配達中です`}
            </Text>
            <Tooltip
              placement="left"
              content={
                <>
                  必要に応じて配達員に
                  <br />
                  直接連絡してください
                </>
              }
            >
              <Flex marginLeft={theme.space.xs}>
                <Icon icon="hint" size="m" color="navy" />
              </Flex>
            </Tooltip>
          </Flex>
          <Text size="xs" color="grey">
            {courier.phoneNumber}
          </Text>
        </>
      )}
    </Root>
  );
});

CourierInfo.displayName = 'CourierInfo';
