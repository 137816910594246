import { PfContactPointFragment, PfContactPointSystem } from '~/graphql';

const filterTelecoms = (
  telecoms: PfContactPointFragment[],
  system: (typeof PfContactPointSystem)[keyof typeof PfContactPointSystem],
) => {
  const pfTels = telecoms
    .filter((telecom) => telecom.system === system)
    .sort((tel1, tel2) => tel1.rank - tel2.rank)
    .map((tel) => tel.formattedValue || tel.value);

  return [...new Set(pfTels)];
};

export const getPfTel = (telecoms: PfContactPointFragment[]) => {
  const phones = filterTelecoms(telecoms, PfContactPointSystem.SystemPhone);
  return phones[0] || null;
};

export const getPfEmail = (telecoms: PfContactPointFragment[]) => {
  const emails = filterTelecoms(telecoms, PfContactPointSystem.SystemEmail);
  return emails[0] || null;
};
