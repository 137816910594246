import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Box, Flex, List, ScrollBox } from '~/components/blocks';
import { BASE_DATE } from '~/constants/organization_notification';
import {
  GetAppointmentsNotificationListQuery,
  OrganizationNotificationFragment,
  useGetAppointmentsNotificationListLazyQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { notificationState } from '~/state/layouts/SharedAppShell/atoms';
import { organizationNotificationDrawerState } from '~/state/partials/organization_notification_drawer/atoms';

import { OrganizationNotification } from './OrganizationNotification';

const formatOrganizationNotifications = (data: GetAppointmentsNotificationListQuery) => {
  const me = getMe(data);

  return me
    ? me.organization.organizationNotifications.nodes.map(
        (node) => node as OrganizationNotificationFragment,
      )
    : [];
};

const getTotalPages = (data: GetAppointmentsNotificationListQuery) => {
  return getMe(data)?.organization.organizationNotifications.pagesCount || 1;
};

const ListRoot = styled(List)(() =>
  css({
    borderTop: 'none',
  }),
);

export const OrganizationNotificationList = () => {
  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const { receptionCount } = useRecoilValue(notificationState);
  const [state, setState] = useRecoilState(organizationNotificationDrawerState);
  const [getNotificationList, { loading, data }] = useGetAppointmentsNotificationListLazyQuery({
    onCompleted: (_result) => {
      setState((_state) => ({
        ..._state,
        totalPage: getTotalPages(_result),
        totalCount: getMe(_result)?.organization.organizationNotifications.nodesCount ?? 0,
      }));
      scrollRef.current?.toTop();
    },
    fetchPolicy: 'network-only',
  });
  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const notifications = useMemo(
    () => (currentData ? formatOrganizationNotifications(currentData) : []),
    [currentData],
  );

  useEffect(() => {
    getNotificationList({
      variables: {
        page: state.page,
        perPage: state.perPage,
        from: BASE_DATE,
      },
    });
  }, [getNotificationList, state.page, state.perPage, receptionCount]);

  return !loading && notifications.length === 0 ? (
    <Flex justifyContent="center">
      <Box padding={theme.space.l}>受付通知はありません</Box>
    </Flex>
  ) : (
    <Box overflow="auto" height="100%">
      <ScrollBox ref={scrollRef} loading={loading}>
        <ListRoot selectable>
          {notifications.map((notification) => {
            return <OrganizationNotification key={notification.id} notification={notification} />;
          })}
        </ListRoot>
      </ScrollBox>
    </Box>
  );
};
