import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { Flex, Text } from '~/components/blocks';
import { publicPath } from '~/utils/path';

type Props = { children?: React.ReactNode };

const Main = styled('main', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.colors.background.default,
    boxShadow: theme.shadows.default,
    padding: theme.space.xl,
    overflow: 'auto',
    [`@media ${theme.mediaQueries.sp}`]: {
      padding: theme.space.l,
    },
  }),
);

const Content = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    marginBottom: theme.space.s,
    fontSize: theme.fontSizes.s,
    padding: `${theme.space.xl}`,
    overflow: 'auto',
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: theme.space.m,
    },
  }),
);

const Logo = styled('img', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    marginBottom: theme.space.xxxl,
    maxWidth: '355px',
    height: 'auto',
    [`@media ${theme.mediaQueries.tablet}`]: {
      maxWidth: '60%',
      width: '100%',
    },
  }),
);

const Wrapper = styled('div')(({ theme }) =>
  css({
    display: 'grid',
    gridTemplateColumns: '1fr 35%',
    height: '100%',
    overflow: 'auto',
    [`@media ${theme.mediaQueries.tablet}`]: {
      gridTemplateColumns: '1fr 45%',
    },
  }),
);

const Setup: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Logo src={publicPath('/logo.svg')} />
        <Text size="l" as="h1">
          かかりつけ薬局支援システム
        </Text>
      </Flex>
      <Main>
        <Content>{children}</Content>
      </Main>
    </Wrapper>
  );
};

export { Setup };
