import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Box, Button, Icon, Loader, Modal, Text } from '~/components/blocks';
import { useGetDeleteFollowupPatientProfileLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { deleteMedicationFollowupMessageModalState } from '~/state/partials/patient_detail_dialog/atoms';

import { useDeactivateFollowupMessage } from './use-deactivate-followup_message';

export const DeleteMedicationFollowupMessageModal = () => {
  const theme = useTheme();

  const { isOpen, medicationFollowupMessageId } = useRecoilValue(
    deleteMedicationFollowupMessageModalState,
  );
  const { isDeactivating, error, deactivate } = useDeactivateFollowupMessage();

  const [getPatientProfile, { data }] = useGetDeleteFollowupPatientProfileLazyQuery();

  const fullName = useMemo(() => {
    const message = getNode(data, 'MedicationFollowupMessage');
    if (message?.appointment) {
      const patient = message.appointment.patient || message.appointment.webVisitor;

      return patient ? `${patient.familyName}${patient.givenName}` : '';
    }
    if (message?.visit) {
      const guestPatient = message.visit.directVisitor;

      return `${guestPatient.familyName}${guestPatient.givenName}`;
    }
  }, [data]);

  const handleSubmit = useCallback(async () => {
    try {
      if (medicationFollowupMessageId) {
        await deactivate(medicationFollowupMessageId);
      }
    } catch {
      // 何もしない
    }
  }, [deactivate, medicationFollowupMessageId]);
  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(deleteMedicationFollowupMessageModalState),
    [],
  );

  useEffect(() => {
    if (isOpen && medicationFollowupMessageId) {
      getPatientProfile({
        variables: {
          id: medicationFollowupMessageId,
        },
      });
    }
  }, [getPatientProfile, isOpen, medicationFollowupMessageId]);

  return (
    // TODO: 表示部分を共通化しておく（web/pharmacy/components/partials/DeleteMedicationFollowupMessageModal/index.tsx）
    <Modal open={isOpen} size="xs" onClose={handleClose}>
      {error && (
        <Alert status="error" mb={theme.space.l}>
          {error}
        </Alert>
      )}
      {medicationFollowupMessageId ? (
        <Modal.Header>
          <Text size="m" marginRight={theme.space.xxl}>
            {fullName || 'ー'}様の服薬フォローアップ設定を削除しますか？
          </Text>
        </Modal.Header>
      ) : (
        <Modal.Body>
          <Box position="relative" height="240px">
            <Loader open inside />
          </Box>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button use="secondary" size="m" loading={isDeactivating} onClick={handleSubmit}>
          <Icon icon="trash" size="m" color="white" />
          削除
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
