import {
  Connection,
  ExtractFragment,
  ExtractNode,
  ExtractTypeName,
  OperatorOrPractitioner,
  PractitionerType,
  Query,
} from './types';

export const toArrayFromConnection = <C extends Connection>(connection: C) => {
  if (!connection.edges) {
    return [];
  }

  return connection.edges.map((a) => a?.node as ExtractFragment<C>).filter(Boolean);
};

export const getMe = <Q extends OperatorOrPractitioner>(data: Q) => {
  return data.me as PractitionerType<Q> | undefined;
};

export const getNode = <Q extends Query, T extends ExtractTypeName<Q>>(
  data: Q | undefined,
  type: T,
) => {
  if (!data || data.node?.__typename !== type) {
    return undefined;
  }

  return (data as ExtractNode<Q, T>).node;
};
