import { useEffect, useState } from 'react';

import { ClientCertVerificationStatus, useGetClientCertVerificationLazyQuery } from '~/graphql';

const IGNORE_PATHNAME = [
  '/signup',
  '/signin',
  '/client_certs/[clientCertId]/download',
  '/access_blocked',
  '/already_signed',
  '/404',
  '/422',
  '/500',
  '/login',
  '/proxy',
  '/checkin/[checkinOrganizationId]/entry',
  '/checkin/[checkinOrganizationId]/result',
  '/checkin/404',
  '/followup_messages/[publicFollowupMessageId]/questionnaire_sheet',
  '/followup_messages/404',
];

export const useClientCertVerification = (pathname: string) => {
  const [status, setStatus] = useState<ClientCertVerificationStatus>(
    ClientCertVerificationStatus.None,
  );
  const [loaded, setLoaded] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [getClientCertVerification, { data }] = useGetClientCertVerificationLazyQuery({
    fetchPolicy: 'network-only',
  });
  const clientCert = data?.clientCertVerification?.clientCert;

  useEffect(() => {
    setLoaded(false);

    if (!IGNORE_PATHNAME.includes(pathname)) {
      // 証明書検証結果を取得
      getClientCertVerification();
    } else {
      setLoaded(true);
    }
  }, [getClientCertVerification, pathname]);

  useEffect(() => {
    if (data?.clientCertVerification?.status) {
      const status = data.clientCertVerification.status;
      setStatus(status);

      const url = data.clientCertVerification.url ? new URL(data.clientCertVerification.url) : null;
      if (url) {
        const nextUrl = `${url.origin}${pathname}`;
        setRedirectUrl(`${url}?next=${encodeURIComponent(nextUrl)}`);
      }

      setLoaded(true);
    }
  }, [
    data?.clientCertVerification?.clientCert,
    data?.clientCertVerification?.status,
    data?.clientCertVerification?.url,
    pathname,
  ]);

  return { status, redirectUrl, loaded, clientCert };
};
