import React from 'react';
import { useRecoilValue } from 'recoil';

import { Box, Drawer } from '~/components/blocks';
import { TelemedicinePatientProfile } from '~/components/partials';
import { BasePatientProfileFragment, KarteAppointmentFragment } from '~/graphql';
import { telemedicinePanelState } from '~/state/partials/telemedicine/atoms';

type Props = {
  patient?: BasePatientProfileFragment | null;
  appointment?: KarteAppointmentFragment | null;
};

export const PatientProfilePanel = (props: Props) => {
  const { isOpenProfile } = useRecoilValue(telemedicinePanelState);

  return (
    <Box position="relative" height="100%">
      <Drawer open={isOpenProfile} variant="persistent">
        <TelemedicinePatientProfile patient={props.patient} appointment={props.appointment} />
      </Drawer>
    </Box>
  );
};
