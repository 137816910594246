import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Alert, Button, Modal, Text } from '~/components/blocks';
import { GetAccessPermissionDocument, useForbidAccessPermissionMutation } from '~/graphql';
import { permissionModalState } from '~/state/layouts/OrganizationAppShell/atoms';
import { Label } from '~/utils/label';

type Props = {
  expiredAt: string;
};

export const ForbidPane = (props: Props) => {
  const setState = useSetRecoilState(permissionModalState);
  const [forbidAccessPermission, { error, loading }] = useForbidAccessPermissionMutation({
    onCompleted: () => {
      setState(() => ({ isOpen: false }));
    },
    refetchQueries: [{ query: GetAccessPermissionDocument }],
  });
  const handleClick = useCallback(() => {
    forbidAccessPermission({ variables: { input: {} } });
  }, [forbidAccessPermission]);

  return (
    <>
      <Modal.Body>
        {error && <Alert status="error">エラーが発生しました</Alert>}
        <Text>{Label.YYYYMMDDja(props.expiredAt)}までアクセスを許可</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" loading={loading} onClick={handleClick}>
          取り消す
        </Button>
      </Modal.Footer>
    </>
  );
};
