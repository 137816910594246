import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Chip, Flex, Text } from '~/components/blocks';

const ReceivedChip = styled(Chip)(({ theme }) =>
  css({
    border: `1px solid ${theme.colors.colorPallete.blue}`,
    backgroundColor: theme.colors.colorPallete.lightBlue,
    color: theme.colors.colorPallete.blue,
    padding: `${theme.space.s} ${theme.space.m}`,
  }),
);

const SentChip = styled(Chip)(({ theme }) =>
  css({
    border: `1px solid ${theme.colors.colorPallete.green}`,
    backgroundColor: theme.colors.colorPallete.lightGreen,
    color: theme.colors.colorPallete.green,
    padding: `${theme.space.s} ${theme.space.m}`,
  }),
);

const FileLabel = styled(Box)(({ theme }) =>
  css({
    marginTop: `-${theme.space.m}`,
    marginBottom: `-${theme.space.s}`,
  }),
);

type Props = {
  received: boolean;
  date: string;
  name: string;
};

export const CollapseLabel = React.memo((props: Props) => {
  const theme = useTheme();

  return (
    <Flex>
      <Box marginRight={theme.space.m}>
        {props.received ? (
          <ReceivedChip size="s">受信</ReceivedChip>
        ) : (
          <SentChip size="s">送信</SentChip>
        )}
      </Box>
      <FileLabel>
        <Box marginBottom={theme.space.s}>
          <Text size="s" color="grey01">
            {props.date}
          </Text>
        </Box>
        <Box>
          <Text size="s">{props.name}</Text>
        </Box>
      </FileLabel>
    </Flex>
  );
});

CollapseLabel.displayName = 'CollapseLabel';
