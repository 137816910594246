import { atom } from 'recoil';

import { PatientProfileTabMap } from '~/constants/tab';

import {
  FinishConfirmModalState,
  MedicationFollowupModal,
  NotificationConfirmModalState,
  NotificationCongestionModalState,
  ProfileDetailTabState,
  ProfileDrawerState,
} from './types';

export const profileDrawerState = atom<ProfileDrawerState>({
  key: 'reception.profileDrawer',
  default: {
    isOpen: false,
    error: null,
    patientId: null,
    appointmentId: null,
  },
});

export const profileDetailTabState = atom<ProfileDetailTabState>({
  key: 'partials.patient_profile_drawer',
  default: {
    isExpanded: false,
    tab: PatientProfileTabMap.app,
  },
});

export const notificationConfirmModalState = atom<NotificationConfirmModalState>({
  key: 'reception.notificationConfirmModal',
  default: {
    isOpen: false,
    appointmentId: null,
    patientId: null,
    immediate: false,
  },
});

export const notificationCongestionModalState = atom<NotificationCongestionModalState>({
  key: 'reception.notificationCongestionModal',
  default: {
    isOpen: false,
    appointmentId: null,
    patientId: null,
  },
});

export const finishConfirmModalState = atom<FinishConfirmModalState>({
  key: 'reception.finishConfirmModal',
  default: {
    isOpen: false,
    encounterId: null,
    patientId: null,
    error: null,
  },
});

export const medicationFollowupModalState = atom<MedicationFollowupModal>({
  key: 'reception.medicationFollowupModal',
  default: {
    isOpen: false,
    appointmentId: null,
  },
});
