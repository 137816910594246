import React from 'react';

import { Grid } from '~/components/blocks';

import { MessageEventList } from '../MessageEventList';
import { PatientProfile } from '../PatientProfile';

export const ReadOnlyPanel = () => {
  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr">
      <PatientProfile />
      <MessageEventList readonly />
    </Grid>
  );
};
