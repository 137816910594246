import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

type Props = {
  src: string;
  alt: string;
};

const Wrapper = styled('div')(({ theme }) =>
  css({
    width: '100%',
    padding: theme.space.m,
    '& + &': {
      marginTop: theme.space.m,
    },
  }),
);

const Image = styled('img')(() =>
  css({
    display: 'block',
    maxWidth: '310px',
    maxHeight: '240px',
    height: 'auto',
    margin: '0 auto',
  }),
);

export const ImgViewer = (props: Props) => {
  return (
    <Wrapper>
      <Image alt={props.alt} src={props.src} />
    </Wrapper>
  );
};
