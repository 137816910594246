import { atom } from 'recoil';

import { MedicineNoteNotificationPanelState, QrCodeReaderDialog } from './types';

export const medicineNoteNotificationPanelState = atom<MedicineNoteNotificationPanelState>({
  key: 'partials.medicineNoteNotificationPanelState',
  default: {
    isOpen: false,
    page: 1,
    perPage: 6,
    totalPage: 1,
    totalCount: 0,
  },
});

export const qrCodeReaderDialogState = atom<QrCodeReaderDialog>({
  key: 'partials.medicineNoteNotificationPanel.qrCodeReaderDialog',
  default: {
    isOpen: false,
  },
});
