import { addDays, endOfDay } from 'date-fns';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Alert, Button, Modal, Select, Text } from '~/components/blocks';
import { GetAccessPermissionDocument, useAllowAccessPermissionMutation } from '~/graphql';
import { permissionModalState } from '~/state/layouts/OrganizationAppShell/atoms';

import { AllowAccessPermissionForm } from './types';
import { validationSchema } from './validation';

const initalValue: AllowAccessPermissionForm = {
  afterDays: 0,
};

export const AllowPane = () => {
  const setState = useSetRecoilState(permissionModalState);
  const [allowAccessPermission, { error, loading }] = useAllowAccessPermissionMutation({
    onCompleted: () => {
      setState(() => ({ isOpen: false }));
    },
    refetchQueries: [{ query: GetAccessPermissionDocument }],
  });
  const handleSubmit = useCallback(
    (values: AllowAccessPermissionForm) => {
      const afterDays = +values.afterDays;
      const afterDate = addDays(new Date(), afterDays);
      const expireDate = endOfDay(afterDate);

      allowAccessPermission({ variables: { input: { expiresAt: expireDate.toISOString() } } });
    },
    [allowAccessPermission],
  );

  return (
    <Formik initialValues={initalValue} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formik) => (
        <>
          <Modal.Body>
            {error && <Alert status="error">エラーが発生しました</Alert>}
            <Text>
              Pharmsサポートスタッフに一時的にアクセス権を付与します。アクセスできる期間を選択してください。
            </Text>
            <Select name="afterDays" onChange={formik.handleChange}>
              <option value="0">本日のみ許可する</option>
              <option value="1">明日まで許可する</option>
              <option value="3">3日後まで許可する</option>
              <option value="7">1週間後まで許可する</option>
            </Select>
          </Modal.Body>
          <Modal.Footer>
            <Button use="base" loading={loading} onClick={formik.submitForm}>
              許可
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
