import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';

import { Box, Select, Tooltip } from '~/components/blocks';
import { TemplateFragment } from '~/graphql';

import { useFetchTemplates } from './use-fetch-templates';

type Props = {
  disabled?: boolean;
  patientName: string;
  onChange: (replacedMessage: string, template?: TemplateFragment) => void;
  selectedTemplateId: string;
};

const SelectWrapper = styled(Box)(() =>
  css({
    width: '100%',
  }),
);

/**
 * template中の `{{patientName}}` と `{{pharmacyName}}` を置換する
 *
 * variablesには patientName と pharmacyName というキーを持ったオブジェクトを渡す
 */
const replaceTemplateVariables = (tempalte: string, variables: Record<string, string>) => {
  return tempalte.replace(/\{\{(?:patientName|pharmacyName)\}\}/g, (match) => {
    const name = match.substr(2, match.length - 4);
    return variables[name] || match;
  });
};

export const SelectMessageTemplate = React.memo((props: Props) => {
  const { patientName, onChange, selectedTemplateId } = props;

  const { templates, organizationName } = useFetchTemplates();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (!organizationName) return;

      const selectedTemplateId = e.currentTarget.value;
      const template = templates.find((template) => template.id === selectedTemplateId);

      if (template) {
        const message = replaceTemplateVariables(template.message, {
          patientName,
          pharmacyName: organizationName,
        });

        onChange(message, template);
      }
    },
    [onChange, organizationName, patientName, templates],
  );

  return templates.length > 0 ? (
    <Select fill disabled={props.disabled} onChange={handleChange} value={selectedTemplateId}>
      <option hidden value="">
        テンプレートを選択
      </option>
      {templates.map(({ id, subject }) => (
        <option key={id} value={id}>
          {subject}
        </option>
      ))}
    </Select>
  ) : (
    <Tooltip placement="bottom" content="テンプレートが登録されていないため追加できません">
      <SelectWrapper>
        <Select fill disabled>
          <option hidden value="">
            テンプレートを選択
          </option>
        </Select>
      </SelectWrapper>
    </Tooltip>
  );
});

SelectMessageTemplate.displayName = 'SelectMessageTemplate';
