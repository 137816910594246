import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Property } from 'node_modules/csstype';
import React from 'react';
import { SpaceProps } from 'styled-system';

import { Icon as IconComponent } from '../Icon';

type RootProps = SpaceProps & {
  className?: string;
  whiteSpace?: Property.WhiteSpace;
  children?: React.ReactNode;
};

type Props = RootProps;

const Root = styled('div', {
  shouldForwardProp,
})<RootProps>(({ theme, whiteSpace }) =>
  css({
    whiteSpace,
    display: 'flex',
    justifyContent: 'flex-start',
    padding: `${theme.space.m} ${theme.space.l}`,
    borderRadius: theme.radii.default,
    backgroundColor: theme.colors.background.warning,
    border: theme.borders.warning,
    position: 'relative',
    marginBottom: theme.space.xl,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.s,
    },
    [`&:after, &:before`]: {
      top: '100%',
      left: '50%',
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: theme.colors.background.transparent,
    },
    '&:after': {
      borderTopColor: theme.colors.background.warning,
      borderWidth: '12px',
      marginLeft: '-12px',
    },
    '&:before': {
      borderTopColor: theme.colors.colorPallete.yellow,
      borderWidth: '13px',
      marginLeft: '-13px',
    },
  }),
);

const Icon = styled(IconComponent)(({ theme }) =>
  css({
    marginTop: theme.space.s,
    marginRight: theme.space.m,
  }),
);

const Content = styled('div')();

export const Balloon: React.FC<Props> = (props) => {
  const { children, ...componentProps } = props;

  return (
    <Root {...componentProps}>
      <Icon size="l" icon="attention" />
      <Content>{children}</Content>
    </Root>
  );
};
