import React, { useCallback, useEffect, useMemo } from 'react';
import { InputActionMeta } from 'react-select';

import { Box, EditableSelect, FieldError } from '~/components/blocks';
import { OptionType } from '~/components/pages/Company_Security_Logs/OrganizationFilter';
import { useSearchMedicinesLazyQuery } from '~/graphql';
import { addMedicineFrequency, getMedicineFrequency } from '~/localstrage/medicine_frequency';

type Props = {
  disabled: boolean;
  errors?: string;
  value?: string;
  onChange: (value: string) => void;
};

export const MedicineNameInput = (props: Props) => {
  const { disabled, errors, value, onChange } = props;
  const [searchMedicines, { data, loading }] = useSearchMedicinesLazyQuery();

  const options = useMemo(() => {
    return data
      ? data.medicines.nodes
          .map((n) => {
            return {
              value: n.productName,
              label: n.productName,
              frequency: getMedicineFrequency(n.productName) ?? 0,
            };
          })
          .sort((a, b) => b.frequency - a.frequency)
          .slice(0, 14) // 上位15件まで表示する
      : [];
  }, [data]);

  const onInputChange = useCallback(
    (inputValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta?.action == 'input-change') onChange(inputValue);
    },
    [onChange],
  );

  const handleChange = useCallback(
    (option: OptionType) => {
      const optionValue = option ? option.value : '';
      onChange(optionValue);
      if (options.some((o) => o.value == optionValue)) {
        addMedicineFrequency(optionValue);
      }
    },
    [onChange, options],
  );

  useEffect(() => {
    // 上限100件まで薬剤名を取得する
    searchMedicines({ variables: { query: value ?? '', page: 1, perPage: 100 } });
  }, [searchMedicines, value]);

  return (
    <Box width="100%">
      <EditableSelect
        name="prescriptionDrug"
        placeholder="例：ロキソニン錠 60mg"
        menuPlacement="bottom"
        noOptionsMessage={() => '選択できる項目がありません'}
        formatCreateLabel={(newValue: string) => newValue}
        isDisabled={disabled}
        value={value ? [{ label: value, value: value }] : null}
        inputValue={value}
        options={options}
        onChange={(option: OptionType) => handleChange(option)}
        onInputChange={onInputChange}
        components={{
          DropdownIndicator: null, // Dropdownの矢印マークを消す
        }}
        isLoading={loading}
      />
      {errors && <FieldError error={errors} />}
    </Box>
  );
};
