import { differenceInMinutes } from 'date-fns';
import { useEffect, useState } from 'react';

import { useGetDispensingRequestOnDraftAppointmentLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import {
  formatMedicalInstitution,
  GET_DISPENSING_REQUEST_INTERVAL_MINUTES,
} from '~/utils/pf_dispensing_request';

const ONE_MINUTE = 60 * 1000;

export const useFetchDispensingRequest = (isOpen: boolean, draftAppointmentId: string) => {
  const [error, setError] = useState<Error | null>(null);
  const [loadedDate, setLoadedDate] = useState<Date | null>(null);
  const [abortedTimerId, setAbortedTimerId] = useState<number | null>(null);
  const [isTimeout, setIsTimeout] = useState(false);
  const [controller] = useState(new AbortController());

  const [getDispensingRequest, { called, loading, data }] =
    useGetDispensingRequestOnDraftAppointmentLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      context: { fetchOptions: { signal: controller.signal } },
      onCompleted: () => {
        setLoadedDate(new Date());

        if (abortedTimerId !== null) {
          clearTimeout(abortedTimerId);
        }
      },
      onError: (_error) => setError(_error),
    });
  const draftAppointment = getNode(data, 'DraftAppointment');
  const dispensingRequest = draftAppointment?.pfDispensingRequest;
  const medicalInstitution = formatMedicalInstitution(dispensingRequest);

  const draftAppointmentStatus = draftAppointment?.status;
  const previousDraftAppointmentStatus = draftAppointment?.previousStatus;
  const willReceivePrescription = draftAppointmentStatus === 'available';
  const hasReceivedPrescription =
    draftAppointmentStatus === 'finished' ||
    draftAppointmentStatus === 'wait_for_booking' ||
    (draftAppointmentStatus === 'cancelled' &&
      previousDraftAppointmentStatus === 'wait_for_booking');

  useEffect(() => {
    if (isOpen && draftAppointmentId) {
      setError(null);

      getDispensingRequest({ variables: { draftAppointmentId } });
    }
  }, [getDispensingRequest, draftAppointmentId, error, isOpen]);

  useEffect(() => {
    if (isOpen && draftAppointmentId) {
      setError(null);

      // ref. pharmacy/components/partials/DispensingRequestCollapse/use-fetch-dispensing_requests.ts
      if (
        !loadedDate ||
        differenceInMinutes(new Date(), loadedDate) > GET_DISPENSING_REQUEST_INTERVAL_MINUTES
      ) {
        getDispensingRequest({ variables: { draftAppointmentId } });
      }
    }
  }, [getDispensingRequest, loadedDate, draftAppointmentId, isOpen]);

  useEffect(() => {
    const _timerId = window.setTimeout(() => {
      controller.abort();
      setIsTimeout(true);
    }, ONE_MINUTE);
    setAbortedTimerId(_timerId);

    return () => {
      if (abortedTimerId !== null) {
        clearTimeout(abortedTimerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: !called || loading,
    timeout: isTimeout,
    error,
    willReceivePrescription,
    hasReceivedPrescription,
    dispensingRequest,
    medicalInstitution,
  };
};
