import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Button, Flex, Modal, Text } from '~/components/blocks';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { Panel } from '~/state/partials/message_modal/types';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';
import { medicationFollowupModalState } from '~/state/partials/patient_profile_drawer/atoms';
import { theme } from '~/styles/theme';

import { useFetchPatient } from './use-fetch-patient';

const Wrapper = styled(Flex)(() =>
  css({
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }),
);

export const MedicationFollowupModal = () => {
  const { isOpen } = useRecoilValue(medicationFollowupModalState);

  const { loading, patient } = useFetchPatient();

  const close = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(medicationFollowupModalState),
    [],
  );

  const handleMedicationFollowup = useRecoilCallback(
    ({ set }) =>
      () => {
        close();

        const patientId = patient?.id;
        if (!patientId) return;

        // TODO: 旧モーダルをパートナー内に移動する時に対応
        // ここでメッセージ送信モーダルを開くがパートナーと店舗で表示するモーダルが異なるstateにsetしているが、店舗の方の画面には表示されないので良しとしている
        set(messageModalState, (_state) => ({
          ..._state,
          isOpen: true,
          currentPanel: Panel.input,
          patientId,
        }));
        set(patientDetailDialogState, (_state) => ({
          ..._state,
          isOpen: true,
          type: 'message' as TabType,
          patientId: patientId,
        }));
      },
    [close, patient?.id],
  );

  return (
    <Modal open={isOpen} size="xs" onClose={close}>
      <Modal.Body>
        <Text size="l" fontWeight="bold">
          {patient ? patient.familyName : null}
          &nbsp;{patient ? patient.givenName : null}
          <Text size="m" fontWeight="bold" ml="s">
            様に
          </Text>
          <Text size="m" fontWeight="bold" mb="l">
            服薬フォローアップの設定を行いますか？
          </Text>
        </Text>
        <Text size="s">服薬フォローアップが必要な患者に、メッセージや質問票を送信できます。</Text>
      </Modal.Body>
      <Modal.Footer>
        <Flex flexDirection="column" justifyContent="center">
          <Wrapper>
            <Button
              use="base"
              disabled={loading}
              ml={theme.space.m}
              onClick={handleMedicationFollowup}
            >
              設定
            </Button>
          </Wrapper>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
