import React from 'react';

import { Appointment } from './Appointment';
import { DraftAppointment } from './DraftAppointment';

type Props = {
  appointmentId?: string | null;
  draftAppointmentId?: string | null;
};

export const ReceptionPane = React.memo((props: Props) => {
  if (props.draftAppointmentId) {
    return <DraftAppointment draftAppointmentId={props.draftAppointmentId} />;
  }

  if (props.appointmentId) {
    return <Appointment appointmentId={props.appointmentId} />;
  }

  return null;
});

ReceptionPane.displayName = 'ReceptionPane';
