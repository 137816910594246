import { useTheme } from '@emotion/react';
import React from 'react';

import { Box } from '~/components/blocks';
import { QuestionnaireSheet } from '~/components/partials';

import { useFetchQuestionnaireSheet } from './use-fetch-questionnaire_sheet';

type Props = {
  patientId?: string | null;
};

export const QuestionnairePane = React.memo((props: Props) => {
  const theme = useTheme();
  const { loading, sheet } = useFetchQuestionnaireSheet(props.patientId);

  return (
    <Box padding={theme.space.l}>
      <QuestionnaireSheet loading={loading} sheet={sheet} />
    </Box>
  );
});

QuestionnairePane.displayName = 'QuestionnairePane';
