// FIXME: 全体的に型が複雑で適切に定義できなかったためanyにしているが、可能であれば修正したい
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ActionMeta,
  components,
  GroupBase,
  InputActionMeta,
  InputProps,
  StylesConfig,
  Theme as SelectTheme,
} from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';

import { FieldError } from '~/components/blocks';
import { AppTheme } from '~/styles/types';

type Props = CreatableProps<any, boolean, GroupBase<any>> & {
  errors?: string;
};

const generateSelectStyles = (theme: AppTheme, selectTheme: SelectTheme): SelectTheme => ({
  ...selectTheme,
  colors: {
    ...selectTheme.colors,
    primary: theme.colors.border.primary,
  },
});

const Input = (props: InputProps) => <components.Input {...props} isHidden={false} />;

export const EditableSelect = React.memo((props: Props) => {
  const { value, onChange, onInputChange } = props;
  const [optionValue, setOptionValue] = useState();
  const [inputValue, setInputValue] = useState('');
  const selectRef = useRef<any>();
  const theme = useTheme();
  const selectStyles = useCallback(
    (selectTheme: SelectTheme) => generateSelectStyles(theme, selectTheme),
    [theme],
  );

  const colorStyles: StylesConfig<any, boolean, GroupBase<any>> = {
    control: (styles, { isFocused }) => {
      const backgroundColor = props.errors
        ? theme.colors.background.alert
        : isFocused
          ? theme.colors.background.default
          : theme.colors.background.bg;

      const border = props.errors
        ? theme.borders.error
        : isFocused
          ? theme.borders.primary
          : theme.borders.transparent;

      return {
        ...styles,
        color: props.errors ? theme.colors.text.error : theme.colors.background.alert,
        cursor: 'text',
        transitionDuration: theme.transitions.fast,
        backgroundColor: backgroundColor,
        boxShadow: 'none',
        border: border,
        ':hover': {
          border: props.errors ? theme.borders.error : theme.borders.primary,
        },
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
      };
    },
    indicatorsContainer: () => {
      return {
        display: 'flex',
      };
    },
    indicatorSeparator: () => {
      return {
        backgroundColor: 'none',
      };
    },
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? theme.colors.background.bg : null,
        color: isSelected && theme.colors.text.default,
        cursor: 'pointer',
        transitionDuration: theme.transitions.fast,
        ':active': {
          backgroundColor: theme.colors.background.default,
        },
      } as any;
    },
    menu: (styles) => {
      return {
        ...styles,
        boxShadow: '0 4px 11px hsla(0, 0%, 0%, 0.1)',
        border: theme.borders.default,
      };
    },
  };

  const handleInputChange = useCallback(
    (inputValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta.action === 'input-change') {
        setInputValue(inputValue);

        if (inputValue) {
          setOptionValue(undefined);
        }
      }

      if (onInputChange) onInputChange(inputValue, actionMeta);
    },
    [onInputChange],
  );

  const handleChange = useCallback(
    (option: any, actionMeta: ActionMeta<any>) => {
      const word = option ? option.value : '';
      setInputValue(word);
      setOptionValue(option);
      if (onChange) onChange(option, actionMeta);
    },
    [onChange],
  );

  useEffect(() => {
    const inputTextValue = value && value.length > 0 ? value[0].value : '';
    setInputValue(inputTextValue);
    setOptionValue(value);
  }, [value]);

  return (
    <>
      <Creatable
        theme={selectStyles}
        name={props.name}
        styles={colorStyles}
        menuPlacement={props.menuPlacement}
        defaultInputValue={props.defaultInputValue}
        noOptionsMessage={props.noOptionsMessage}
        value={optionValue}
        inputValue={inputValue}
        options={props.options}
        formatCreateLabel={props.formatCreateLabel}
        placeholder={props.placeholder}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={props.onBlur}
        isLoading={props.isLoading}
        isDisabled={props.isDisabled}
        isClearable={props.isClearable}
        controlShouldRenderValue={false}
        components={{
          ...props.components,
          Input,
        }}
        ref={selectRef}
      />
      {props.errors && <FieldError error={props.errors} />}
    </>
  );
});

EditableSelect.displayName = 'EditableSelect';
