import React from 'react';

import { Hint } from '~/components/blocks';

export const RecommendationHint = () => {
  return (
    <Hint>
      受診が必要な患者に病院・診療所の
      <br />
      予約ページリンクを添付できます
    </Hint>
  );
};
