import React, { useRef } from 'react';

import { Box, Grid, ScrollBox, Text } from '~/components/blocks';
import { QuestionnaireSheet } from '~/components/partials';
import { PrescriptionCollapse } from '~/components/partials/PrescriptionCollapse';
import { WebVisitorDetailDialogProfileFragment } from '~/graphql';
import { theme } from '~/styles/theme';

import { PatientProfile } from './PatientProfile';

type Props = {
  visitor: WebVisitorDetailDialogProfileFragment;
};

export const WebVisitorKarte = React.memo((props: Props) => {
  const { visitor } = props;
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  return (
    <Grid height="100%" overflow="auto" gridTemplateRows="min-content min-content 1fr">
      <PatientProfile patient={visitor} visitedAt={visitor.appointment.start || undefined} />
      <Box marginTop={theme.space.m}>
        <PrescriptionCollapse appointmentId={visitor.appointment.id} />
      </Box>
      <ScrollBox ref={scrollRef}>
        <Box marginTop={theme.space.l}>
          <Text fontWeight="bold" marginBottom={theme.space.m}>
            問診
          </Text>
          <QuestionnaireSheet loading={false} sheet={visitor.webVisitorSheet} />
        </Box>
      </ScrollBox>
    </Grid>
  );
});

WebVisitorKarte.displayName = 'WebVisitorKarte';
