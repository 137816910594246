import { PatientDetailDiaLogTabMap } from '~/constants/tab';

export type TabType = (typeof PatientDetailDiaLogTabMap)[keyof typeof PatientDetailDiaLogTabMap];

export type PatientDetailDialog = {
  isOpen: boolean;
  patientId: string | null;
  type: TabType;
};

export type patientDetailDialogTracingreport = {
  selectedSheetId: string | null;
};

export type patientDetailDialogPatientComment = {
  selectedCommentId: string | null;
  isDeleteModalOpen: boolean;
};

export type PatientDetailDialogMessage = {
  profile: Profile | null;
  selectedMessageId: string | null;
  draftMessageId: string | null;
  messageInfoStatus: 'created' | 'new' | 'sendAt' | null;
  messageInfoIsOpen: boolean;
};

export type EventPageInfo = {
  isResetEvent: boolean;
  page: number;
  perPage: number;
};

export type Answer = {
  answer: string | null;
};

export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE = 20;

export type Profile = {
  type?: 'DirectVisitor' | 'Patient' | 'WebVisitor';
  receptionId: string;
  familyName: string;
  givenName: string;
  phoneticFamilyName: string;
  phoneticGivenName: string;
  visitDay?: string;
};

export type PatientDetailDialogProfile = {
  type: 'edit' | null;
};

const PatientDetailDiaLogActionMap = {
  close: 'close',
  message: 'toTracingReport',
} as const;

export type PatientDetailDiaLogActionType =
  (typeof PatientDetailDiaLogActionMap)[keyof typeof PatientDetailDiaLogActionMap];

export type PatientDetailConfirmBreakaway = {
  isOpen: boolean;
  action: PatientDetailDiaLogActionType | null;
};

export type DeleteMedicationFollowupMessageModal = {
  isOpen: boolean;
  medicationFollowupMessageId: string | null;
};
