import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Tabs } from '~/components/blocks';
import { ProfileDetailApp } from '~/components/partials/PatientProfile/ProfileDetailApp';
import { ProfileDetailMedixs } from '~/components/partials/PatientProfile/ProfileDetailMedixs';
import { PatientProfileTabMap } from '~/constants/tab';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';
import { profileDetailTabState } from '~/state/partials/patient_profile_drawer/atoms';

type Props = {
  appointmentId: string | null;
  patientId: string | null;
};

type TabProps = {
  active?: boolean;
};

const Tab = styled(Tabs, { shouldForwardProp })(() =>
  css({
    display: 'inline-flex',
  }),
);

const Items = styled(Tabs.Tab, { shouldForwardProp })<TabProps>(({ theme, active }) =>
  css({
    backgroundColor: theme.colors.background.default,
    borderTop: 'none',
    borderBottom: `2px solid ${theme.colors.border.default}`,
    padding: `${theme.space.m} ${theme.space.xxl}`,
    '&:hover': {
      borderTop: 'none',
      backgroundColor: theme.colors.background.bg,
      borderBottom: `2px solid ${theme.colors.border.default}`,
    },
    ...(active && {
      borderTop: 'none',
      borderBottom: `2px solid ${theme.colors.border.blue}`,
    }),
  }),
);

export const ProfileDetailTabs = React.memo((props: Props) => {
  const { appointmentId, patientId } = props;

  const { tab } = useRecoilValue(profileDetailTabState);

  const handleChangeTab = useRecoilCallback(
    ({ set }) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const element = e.target as HTMLButtonElement;
        const newTab =
          (element.dataset['tab'] as keyof typeof PatientProfileTabMap) || PatientProfileTabMap.app;

        set(profileDetailTabState, (_state) => ({ ..._state, tab: newTab }));
      },
    [],
  );
  const { enabledMedixs } = useMedixsSetting();

  return enabledMedixs ? (
    <>
      <Tab>
        <Items
          attached
          active={tab === PatientProfileTabMap.app}
          data-tab={PatientProfileTabMap.app}
          onClick={handleChangeTab}
          size="xs"
        >
          アプリ
        </Items>
        <Items
          attached
          active={tab === PatientProfileTabMap.medixs}
          data-tab={PatientProfileTabMap.medixs}
          onClick={handleChangeTab}
          size="xs"
        >
          連携元
        </Items>
      </Tab>
      {tab === PatientProfileTabMap.app && <ProfileDetailApp patientId={patientId} />}
      {tab === PatientProfileTabMap.medixs && (
        <ProfileDetailMedixs appointmentId={appointmentId} patientId={patientId} />
      )}
    </>
  ) : (
    <ProfileDetailApp patientId={patientId} />
  );
});

ProfileDetailTabs.displayName = 'ProfileDetailTabs';
