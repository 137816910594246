import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Flex, Grid, Header } from '~/components/blocks';
import { SETTING_TITLES } from '~/components/layouts/Settings/RootMenu';
import { AppPreview } from '~/components/partials';

import { Preview } from './Preview';
import { RootMenu } from './RootMenu';

type Props = { children?: React.ReactNode };

export const OrganizationSettings: React.FC<Props> = ({ children }) => {
  const theme = useTheme();

  return (
    <>
      <Grid height="100%" overflow="hidden" gridTemplateRows="min-content min-content 1fr">
        <Flex>
          <Header>{SETTING_TITLES.organization}</Header>
          <Preview />
        </Flex>
        <Box mt={theme.space.s}>
          <RootMenu />
        </Box>
        <Box pt={theme.space.xl} px={[0, theme.space.m]} overflow="auto" height="100%">
          {children}
        </Box>
      </Grid>
      <AppPreview />
    </>
  );
};
