import { atom } from 'recoil';

import { MedicineNotesContainerState } from './types';

export const medicineNotesContainerState = atom<MedicineNotesContainerState>({
  key: 'partilas.medicineNoteContailerState',
  default: {
    isDetailView: false,
  },
});
