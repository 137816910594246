import { useEffect } from 'react';

import { useGetKarteQuestionnaireSheetLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchQuestionnaireSheet = (patientId?: string | null) => {
  const [getSheet, { called, loading, data }] = useGetKarteQuestionnaireSheetLazyQuery();

  const sheet = getNode(data, 'Patient')?.questionnaireSheet || undefined;

  useEffect(() => {
    if (patientId) {
      getSheet({
        variables: {
          id: patientId,
        },
      });
    }
  }, [getSheet, patientId]);

  return {
    loading: !called || loading,
    sheet,
  };
};
