import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { rgba } from 'polished';
import { compose, variant } from 'styled-system';

type Props = {
  size?: 'xs' | 's' | 'm' | 'l';
  active?: boolean;
  attached?: boolean;
  orientation?: 'vertical' | 'holizonal';
};

export const Tab = styled('button', {
  shouldForwardProp,
})<Props>(
  ({ theme }) =>
    css({
      color: theme.colors.text.default,
      transition: theme.transitions.default,
      display: 'block',
      m: 0,
      p: 0,
      background: 'transparent',
      listStyle: 'none',
      border: 0,
      cursor: 'pointer',
      userSelect: 'none',
    }),
  ({ theme, active }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          xs: css({
            padding: `${theme.space.m}`,
            fontSize: theme.fontSizes.xs,
            [`@media ${theme.mediaQueries.tablet}`]: {
              padding: `${theme.space.m}`,
              fontSize: theme.fontSizes.xs,
              lineHeight: theme.lineHeights.m,
            },
          }),
          s: css({
            padding: `${theme.space.l}`,
            fontSize: theme.fontSizes.s,
            [`@media ${theme.mediaQueries.tablet}`]: {
              padding: `${theme.space.m}`,
              fontSize: theme.fontSizes.xs,
              lineHeight: theme.lineHeights.l,
            },
          }),
          m: css({
            padding: `${theme.space.l}`,
            fontSize: theme.fontSizes.m,
            [`@media ${theme.mediaQueries.tablet}`]: {
              fontSize: theme.fontSizes.s,
            },
            [`@media ${theme.mediaQueries.portrait}`]: {
              fontSize: theme.fontSizes.xs,
            },
          }),
          l: css({
            width: '',
          }),
        },
      }),
      variant({
        prop: 'orientation',
        variants: {
          holizonal: css({
            borderBottom: theme.borders.default,
            fontWeight: theme.fontWeights.bold,
            borderWidth: '1px',
            '&:hover': {
              boxShadow: `inset 0 -2px 0 ${theme.colors.border.primary}`,
              color: theme.colors.text.default,
            },
            ...(active && {
              boxShadow: `inset 0 -2px 0 ${theme.colors.border.primary}`,
            }),
          }),
          vertical: css({
            width: '100%',
            textAlign: 'left',
            borderBottom: theme.borders.default,
            fontWeight: theme.fontWeights.bold,
            '&:hover': {
              background: rgba(theme.colors.background.primary, 0.15),
            },
            ...(active && {
              background: theme.colors.background.primary,
              color: theme.colors.text.white,
              '&:hover': {
                background: theme.colors.background.primary,
              },
            }),
          }),
        },
      }),
    ),
  ({ theme, active, attached }) =>
    attached &&
    css({
      borderTop: `2px solid ${theme.colors.border.default}`,
      fontWeight: theme.fontWeights.bold,
      background: theme.colors.background.bg,
      flex: 1,
      textAlign: 'center',
      borderBottom: 'none',
      '&:hover': {
        borderTop: `2px solid ${theme.colors.border.primary}`,
        background: theme.colors.background.default,
        borderBottom: 'none',
        boxShadow: 'none',
      },
      ...(active && {
        borderTop: `2px solid ${theme.colors.border.primary}`,
        background: theme.colors.background.default,
        borderBottom: 'none',
        boxShadow: 'none',
      }),
    }),
);

Tab.defaultProps = {
  size: 'm',
  orientation: 'holizonal',
};
