import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { pageStyles } from './shared_styles';

type Props = {
  src: string;
  title: string;
};

const Page = styled('div', { shouldForwardProp })(({ theme }) =>
  css({
    ...pageStyles(theme),
    flexShrink: 0,
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }),
);

const Image = styled('img')(() =>
  css({
    maxWidth: '100%',
    maxHeight: '100%',
  }),
);

export const PrescriptionImgPreview = React.memo((props: Props) => {
  return (
    <Page>
      <Image title={props.title} alt={props.title} src={props.src} />
    </Page>
  );
});

PrescriptionImgPreview.displayName = 'PrescriptionImgPreview';
