import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { Loader } from '~/components/blocks';
import {
  DirectVisitorDetailDialogProfileFragment,
  PatientDetailDialogProfileFragment,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';

import { ExclusiveLockError } from './ExclusiveLockError';
import { InputPanel } from './InputPanel';
import { useExclusiveLock } from './use-exclusive_lock';
import { useFetchPatientProfile } from './use-fetch-patient';

type Props = {
  patient?:
    | DirectVisitorDetailDialogProfileFragment
    | WebVisitorDetailDialogProfileFragment
    | PatientDetailDialogProfileFragment;
};

export const NewMedicationFollowupMessagePane = (props: Props) => {
  const { isOpen, patientId, type } = useRecoilValue(patientDetailDialogState);
  const { loading } = useFetchPatientProfile();

  const {
    lock,
    unlock,
    status: exclusiveLockStatus,
    error: exclusiveLockError,
    setError: setExclusiveLockError,
  } = useExclusiveLock({
    patientId: patientId,
  });

  useEffect(() => {
    if (isOpen && patientId) {
      lock();
    }
    return () => {
      setExclusiveLockError(null);
      if (exclusiveLockStatus === 'locking') {
        unlock();
      }
    };
  }, [exclusiveLockStatus, isOpen, lock, patientId, unlock, setExclusiveLockError]);

  if (type === 'tracingreport') return null;

  return (
    <>
      {type === 'message' && !loading && (
        <>
          {!exclusiveLockError ? (
            <Loader open inside appearance="white" />
          ) : exclusiveLockStatus === 'locking' ? (
            <InputPanel patient={props.patient} />
          ) : (
            exclusiveLockError && <ExclusiveLockError error={exclusiveLockError} />
          )}
        </>
      )}
    </>
  );
};
