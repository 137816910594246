import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import { CSSObjectWithLabel, GroupBase, StylesConfig, Theme as SelectTheme } from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';

import { FieldError } from '~/components/blocks';
import { OptionType } from '~/components/pages/Company_Security_Logs/OrganizationFilter';
import { AppTheme } from '~/styles/types';

type IndicatorProps = {
  hideIndicator?: boolean;
};

export type Props = CreatableProps<OptionType, boolean, GroupBase<OptionType>> & {
  errors?: string;
} & IndicatorProps;

const generateSelectStyles = (theme: AppTheme, selectTheme: SelectTheme): SelectTheme => ({
  ...selectTheme,
  colors: {
    ...selectTheme.colors,
    primary: theme.colors.border.primary,
  },
});

export const CreatableSelect = React.memo((props: Props) => {
  const theme = useTheme();
  const selectStyles = useCallback(
    (selectTheme: SelectTheme) => generateSelectStyles(theme, selectTheme),
    [theme],
  );

  const colorStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
    control: (styles, { isFocused }) => {
      const backgroundColor = props.errors
        ? theme.colors.background.alert
        : isFocused
          ? theme.colors.background.default
          : theme.colors.background.bg;

      const border = props.errors
        ? theme.borders.error
        : isFocused
          ? theme.borders.primary
          : theme.borders.transparent;

      return {
        ...styles,
        color: props.errors ? theme.colors.text.error : theme.colors.background.alert,
        cursor: 'text',
        transitionDuration: theme.transitions.fast,
        backgroundColor: backgroundColor,
        boxShadow: 'none',
        border: border,
        ':hover': {
          border: props.errors ? theme.borders.error : theme.borders.primary,
        },
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
      };
    },
    indicatorsContainer: () => {
      return {
        display: 'flex',
      };
    },
    indicatorSeparator: () => {
      return {
        backgroundColor: 'none',
      };
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? theme.colors.background.bg : null,
        cursor: 'pointer',
        transitionDuration: theme.transitions.fast,
        ':active': {
          backgroundColor: theme.colors.background.default,
        },
      } as CSSObjectWithLabel;
    },
    menu: (styles) => {
      return {
        ...styles,
        boxShadow: '0 4px 11px hsla(0, 0%, 0%, 0.1)',
        border: theme.borders.default,
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        display: props.hideIndicator ? 'none' : 'inline',
      };
    },
  };

  return (
    <>
      <Creatable
        theme={selectStyles}
        name={props.name}
        styles={colorStyles}
        menuPlacement={props.menuPlacement}
        defaultInputValue={props.defaultInputValue}
        noOptionsMessage={props.noOptionsMessage}
        value={props.value}
        formatCreateLabel={props.formatCreateLabel}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        options={props.options}
        isLoading={props.isLoading}
        isDisabled={props.isDisabled}
        isClearable={props.isClearable}
        onBlur={props.onBlur}
      />
      {props.errors && <FieldError error={props.errors} />}
    </>
  );
});

CreatableSelect.displayName = 'CreatableSelect';
