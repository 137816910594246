import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Box, Tabs } from '~/components/blocks';
import { MedicationFollowupNotificationPanel } from '~/components/layouts/SharedAppShell/MedicationFollowupNotificationPanel';
import { MedicineNoteNotificationPanel } from '~/components/layouts/SharedAppShell/MedicineNoteNotificationPanel';
import { TabLabel } from '~/components/layouts/SharedAppShell/NotificationPanel/TabLabel';
import { OrganizationNotificationPanel } from '~/components/layouts/SharedAppShell/OrganizationNotificationPanel';
import { PlanFeatureCode } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { notificationState } from '~/state/layouts/SharedAppShell/atoms';

const Root = styled('div')(({ theme }) =>
  css({
    padding: theme.space.m,
    width: '421px',
    height: `calc(80vh - ${theme.layouts.appHeader.height})`,
    maxHeight: '660px',
  }),
);

const Content = styled(Box)(() =>
  css({
    height: 'calc(100% - 46px)',
    overflow: 'auto',
  }),
);

export const NotificationPanel = () => {
  const { receptionCount, followupCount, medicineNoteCount } = useRecoilValue(notificationState);
  const [tab, setTab] = useState(() => {
    if (receptionCount > 0) return 'Reception';
    if (followupCount > 0) return 'MedicationFollowup';
    if (medicineNoteCount > 0) return 'MedicineNote';
    return 'Reception';
  });
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);

  const handleChangeTab = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.currentTarget as HTMLButtonElement;
      const newTab = element.dataset['tab'] as string;

      setTab(newTab);
    },
    [setTab],
  );

  return (
    <Root>
      <Tabs>
        <Tabs.Tab active={tab === 'Reception'} data-tab={'Reception'} onClick={handleChangeTab}>
          <TabLabel label={'受付'} count={receptionCount} />
        </Tabs.Tab>
        {hasEnabledFollowup && (
          <Tabs.Tab
            active={tab === 'MedicationFollowup'}
            data-tab={'MedicationFollowup'}
            onClick={handleChangeTab}
          >
            <TabLabel label={'質問票回答'} count={followupCount} />
          </Tabs.Tab>
        )}
        <Tabs.Tab
          active={tab === 'MedicineNote'}
          data-tab={'MedicineNote'}
          onClick={handleChangeTab}
        >
          <TabLabel label={'お薬手帳'} count={medicineNoteCount} />
        </Tabs.Tab>
      </Tabs>
      <Content>
        {tab === 'Reception' && <OrganizationNotificationPanel />}
        {tab === 'MedicationFollowup' && <MedicationFollowupNotificationPanel />}
        {tab === 'MedicineNote' && <MedicineNoteNotificationPanel />}
      </Content>
    </Root>
  );
};
