import { differenceInMinutes } from 'date-fns';
import { useEffect, useState } from 'react';

import { useGetInsuranceCardsLazyQuery, useGetPatientCertificateLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

const FILE_NAME = '公費受給者証';

const ONE_MINUTE = 60 * 1000;
const INTERVAL_MINUTES = ONE_MINUTE * 60;

export const useFetchInsuranceCards = (
  isOpen: boolean,
  isFetchCertificate: boolean,
  patientId?: string,
) => {
  const [error, setError] = useState<Error | null>(null);
  const [loadedDate, setLoadedDate] = useState<Date | null>(null);
  const [abortedTimerId, setAbortedTimerId] = useState<number | null>(null);
  const [isTimeout, setIsTimeout] = useState(false);
  const [controller] = useState(new AbortController());

  const [getInsuranceCards, { called, loading, data }] = useGetInsuranceCardsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    context: { fetchOptions: { signal: controller.signal } },
    onCompleted: () => {
      setLoadedDate(new Date());

      if (abortedTimerId !== null) {
        clearTimeout(abortedTimerId);
      }
    },
    onError: (_error) => setError(_error),
  });
  const [getCertificate, { data: certificateData }] = useGetPatientCertificateLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const insuranceCards = getNode(data, 'Patient')?.pfPatient?.insuranceCards?.insuranceCards || [];
  const hasCertificate =
    (getNode(certificateData, 'Patient')?.patientAttachments.nodes || []).length > 0;

  useEffect(() => {
    if (isOpen && patientId) {
      setError(null);

      getInsuranceCards({ variables: { patientId } });

      if (isFetchCertificate) {
        getCertificate({
          variables: {
            patientId,
            name: FILE_NAME,
          },
        });
      }
    }
  }, [getCertificate, getInsuranceCards, isFetchCertificate, isOpen, patientId]);

  useEffect(() => {
    if (isOpen && patientId) {
      // 保険証URLに有効期限（１日）があるので念のため初回ロードからINTERVAL_MINUTESたってたら再取得する
      if (!loadedDate || differenceInMinutes(new Date(), loadedDate) > INTERVAL_MINUTES) {
        setError(null);
        getInsuranceCards({ variables: { patientId } });
      }
    }
  }, [getInsuranceCards, isOpen, loadedDate, patientId]);

  useEffect(() => {
    const _timerId = window.setTimeout(() => {
      controller.abort();
      setIsTimeout(true);
    }, ONE_MINUTE);
    setAbortedTimerId(_timerId);

    return () => {
      if (abortedTimerId !== null) {
        clearTimeout(abortedTimerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: !called || loading,
    timeout: isTimeout,
    hasCertificate,
    error,
    insuranceCards,
  };
};
