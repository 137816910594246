import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { Box, Icon, Text } from '~/components/blocks';
import { GetAnnouncementListQuery, useGetAnnouncementListLazyQuery } from '~/graphql';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { announcementModalState } from '~/state/layouts/announcement_modal/atoms';

const AlertText = styled(Text)(({ theme }) =>
  css({
    color: theme.colors.text.white,
  }),
);

const Alert = styled(Box)(({ theme }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    marginRight: theme.space.m,
    padding: `${theme.space.m} ${theme.space.l}`,
    backgroundColor: theme.colors.colorPallete.pink,
    borderBottom: `1px solid ${theme.colors.colorPallete.pink}`,
    color: theme.colors.text.white,
    transitionDuration: theme.transitions.default,
    zIndex: theme.zIndices.header,
    '&:hover': {
      backgroundColor: theme.colors.background.alert,
      [`& > ${AlertText}`]: {
        color: theme.colors.text.error,
      },
    },
  }),
);

const findEmergencyAlert = (data: GetAnnouncementListQuery) => {
  const alert = data.announcements.nodes.find(({ emergency }) => emergency);

  return alert ? { id: alert.id, subject: alert.subject } : null;
};

export const EmergencyAlert = () => {
  const setAnnouncementModalState = useSetRecoilState(announcementModalState);
  const [event] = useSubscriptions('Announcement', ['created', 'deleted', 'updated']);
  const [getAnnouncementListLazy, { data }] = useGetAnnouncementListLazyQuery({
    fetchPolicy: 'network-only',
  });
  const alert = useMemo(() => {
    return data ? findEmergencyAlert(data) : null;
  }, [data]);
  const handleClick = useCallback(() => {
    if (alert) {
      setAnnouncementModalState((state) => ({ ...state, isOpen: true, announcementId: alert.id }));
    }
  }, [alert, setAnnouncementModalState]);

  // マウント時に件数取得
  useEffect(() => {
    getAnnouncementListLazy();
  }, [getAnnouncementListLazy]);

  useEffect(() => {
    if (event) {
      getAnnouncementListLazy();
    }
  }, [event, getAnnouncementListLazy]);

  return alert ? (
    <Alert onClick={handleClick}>
      <Icon size="xl" icon="attention" />
      <AlertText fontWeight="bolder" size="xs">
        {alert.subject}
      </AlertText>
    </Alert>
  ) : null;
};
