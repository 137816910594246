import { mixed, object, ObjectSchema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  name: string().label('ファイル名').required(FormErrorMessage.required),
  file: mixed().label('ファイル').required(FormErrorMessage.required),
});
