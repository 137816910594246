import { useTheme } from '@emotion/react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Alert, Box, Button, Icon } from '~/components/blocks';
import { GetPatientAttachmentsDocument, useAddPatientAttachmentMutation } from '~/graphql';
import { filePageInfoState } from '~/state/partials/patient_karte/atoms';

import { toPatientFrom } from '../FileList';
import { DEFAULT_NAME, FileForm } from './FileForm';
import { Fields } from './types';

type Props = {
  onGoBack: () => void;
};

export const UploadFile = React.memo((props: Props) => {
  const theme = useTheme();
  const { onGoBack } = props;
  const formRef = useRef<RefAttributeType<typeof FileForm>>(null);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const { patientId, filter, page, perPage } = useRecoilValue(filePageInfoState);
  const handleError = () => {
    setError('アップロードに失敗しました');
    setIsUploading(false);
  };

  const [addAtachment] = useAddPatientAttachmentMutation({
    onCompleted: () => {
      setError('');
      setIsUploading(false);
      onGoBack();
    },
    onError: () => {
      handleError();
    },
    refetchQueries: [
      {
        query: GetPatientAttachmentsDocument,
        variables: {
          patientId,
          patientFrom: toPatientFrom(filter.sent, filter.received),
          page,
          perPage,
        },
      },
    ],
  });

  const initialValue = useMemo<Fields>(
    () => ({
      name: DEFAULT_NAME.recept,
      file: undefined,
    }),
    [],
  );

  const handleSend = useCallback(() => {
    if (!formRef.current) return;
    // S3へのアップロード処理に少し時間が掛かりボタン連打->重複アップロードが容易に成功するのでuseMutationのloadingではなくisUploadingを使う
    setIsUploading(true);
    formRef.current.submitForm();
  }, []);
  const handleSubmit = useCallback(
    (value: Fields) => {
      if (!patientId) return;

      import('~/utils/upload').then((result) => {
        result
          .upload(value.file)
          .then((result) => {
            addAtachment({
              variables: {
                input: {
                  patientId,
                  file: result.signedId,
                  name: value.name,
                },
              },
            });
          })
          .catch(() => handleError());
      });
    },
    [addAtachment, patientId],
  );

  return (
    <Box>
      {error && (
        <Alert status="error" mb={`${theme.space.m}`} whiteSpace="pre-wrap">
          {error}
        </Alert>
      )}
      <FileForm
        ref={formRef}
        disabled={isUploading}
        initialValue={initialValue}
        setIsValid={setIsValid}
        onSubmit={handleSubmit}
      />
      <Box padding={theme.space.m} marginBottom={theme.space.l}>
        <Button
          wide="fill"
          use="base"
          disabled={!isValid}
          loading={isUploading}
          onClick={handleSend}
        >
          <Icon size="m" icon="upload" color="white" />
          アップロード
        </Button>
      </Box>
      <Button use="white" icon={true} onClick={onGoBack}>
        <Icon icon="back-line" size="s" />
        戻る
      </Button>
    </Box>
  );
});

UploadFile.displayName = 'UploadFile.';
