import '~/utils/pdfjs';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useState } from 'react';
import { Document, Page } from 'react-pdf';

import { Alert, Box } from '~/components/blocks';
import { isIpad } from '~/utils/user_agent';

import { DispensingRequestLoader } from './DispensingRequestLoader';

type Props = {
  src: string;
  onError: (error: Error) => void;
  onLoad: () => void;
};

// react-pdfのスタイル指定が数値のみだったりで直接指定した
// 無理矢理感が否めないのでいい方法があれば書き換え
const Wrapper = styled('div')(({ theme }) =>
  css({
    marginY: theme.space.m,
    '& .react-pdf__Document': {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(50%, 1fr))`,
    },
    '& .dispensing_request': {
      width: '100%',
      marginY: theme.space.m,
      padding: theme.space.m,
      overflow: 'hidden',
      transitionDuration: theme.transitions.default,
      '& + &': {
        marginTop: theme.space.m,
      },
      '& > .react-pdf__Page__canvas': {
        width: '75% !important',
        height: 'auto !important',
        margin: '0 auto',
        '& > canvas': {
          width: '100% !important',
          height: 'auto !important',
        },
      },
    },
  }),
);

interface PDFDocumentProxy {
  numPages: number;
}

export const PdfViewer = (props: Props) => {
  const { onLoad } = props;

  const theme = useTheme();

  const [numPages, setNumPages] = useState<number>(0);
  const handleLoaded = useCallback(
    (pdf: PDFDocumentProxy) => {
      onLoad();

      // TODO: メモリリーク対策
      setNumPages(pdf.numPages);
    },
    [onLoad],
  );

  return (
    <Wrapper>
      <Document
        loading={<DispensingRequestLoader />}
        file={props.src}
        renderMode="canvas"
        error={
          <Box padding={theme.space.m}>
            <Alert>
              処方箋の取得中にエラーが発生しました。
              <br />
              しばらく待ってから、再度アクセスしてください
            </Alert>
          </Box>
        }
        onLoadError={props.onError}
        onLoadSuccess={handleLoaded}
        onSourceError={props.onError}
      >
        {Array.from({ length: numPages }, (_, i) => {
          return (
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              className="dispensing_request"
              key={i}
              pageNumber={i + 1}
              scale={2}
              // iPadの場合はdevicePixelRatioを1にする
              // ref. https://github.com/wojtekmaj/react-pdf/issues/1149#issuecomment-1323541137
              devicePixelRatio={isIpad() ? 1 : window.devicePixelRatio}
            />
          );
        })}
      </Document>
    </Wrapper>
  );
};
