import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { CSSProperties } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

import { CloseBtn } from '../CloseBtn';
import { Portal } from '../Portal';

type Props = {
  className?: string;
  open: boolean;
  onClose?: () => void;
  onExited?: () => void;
  onTransitionEnd?: () => void;
  children?: React.ReactNode;
};

const Close = styled(CloseBtn, {
  shouldForwardProp,
})(({ theme }) =>
  css({
    position: 'absolute',
    top: '50%',
    right: theme.space.l,
    transform: 'translateY(-50%) rotate(45deg)',
  }),
);

const Root = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    position: 'fixed',
    bottom: 0,
    width: '88%',
    left: '6%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.text.white,
    backgroundColor: theme.colors.background.black,
    borderRadius: `${theme.radii.default} ${theme.radii.default} 0 0`,
    padding: theme.space.l,
    transform: 'translateY(calc(100% + 8px))',
    transition: `transform ${theme.transitions.fast} ease-out`,
    zIndex: theme.zIndices.toast,
  }),
);

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: {
    transform: 'translateY(0)',
  },
  entered: {
    transform: 'translateY(0)',
  },
  exiting: {
    transform: 'translateY(calc(100% + 8px))',
  },
  exited: {
    transform: 'translateY(calc(100% + 8px))',
  },
};

export const Toast: React.FC<Props> = (props) => {
  const { onClose, onExited, onTransitionEnd } = props;

  return (
    <Portal>
      <Transition in={props.open} timeout={0} onExited={onExited}>
        {(state) => (
          <Root
            className={props.className}
            onTransitionEnd={onTransitionEnd}
            style={{
              ...transitionStyles[state],
            }}
          >
            {props.children}
            {onClose && <Close onClick={onClose} />}
          </Root>
        )}
      </Transition>
    </Portal>
  );
};
