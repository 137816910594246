import { addDays, formatISO } from 'date-fns';

/**
 * @param time HH:mm
 */
const splitTimeToNumber = (time: string) => {
  const result = time.split(':');
  return [+result[0], +result[1]];
};

/**
 * safariでInvalid time valueになるので読み込めるフォーマットにする
 * 24:00は翌日00:00になるので注意
 * @param time HH:mm
 */
export const formatTime = (time: string) => {
  const [nHour, nMinutes] = splitTimeToNumber(time);
  const overDay = Math.floor(nHour / 24);
  const date = addDays(new Date(), overDay);
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  const hour = `${nHour - 24 * overDay}`.padStart(2, '0');
  const minutes = `${nMinutes}`.padStart(2, '0');

  return formatISO(new Date(`${year}/${month}/${day} ${hour}:${minutes}`));
};

export const getTimeArray = (timeIntervals: number, without24Hour?: boolean) => {
  const times: string[] = [];

  for (let hour = 0; hour <= 24; ++hour) {
    let minutes = 0;

    if (hour === 24) {
      !without24Hour && times.push('24:00');
    } else {
      for (; minutes < 60; minutes = minutes + timeIntervals) {
        times.push(`${`${hour}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}`);
      }
    }
  }

  return times;
};

export const getNextSlot = (endTime: string, timeIntervals: number) => {
  const times = getTimeArray(timeIntervals);
  let nextSlot = {
    start: '00:00',
    end: `00:${timeIntervals}`,
  };

  for (let t = 0, len = times.length; t < len; ++t) {
    if (endTime < times[t] && times[t + 1]) {
      nextSlot = {
        start: times[t],
        end: times[t + 1],
      };
      break;
    }
  }

  return nextSlot;
};
