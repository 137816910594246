import { addDays, differenceInHours, format, isSameDay, startOfDay } from 'date-fns';
import { ja } from 'date-fns/locale';

export const formatISOString = (date: Date | string | number | null) => {
  const formatString = "yyyy-MM-dd'T'HH:mm:ssxx";

  if (typeof date === 'string') {
    return format(new Date(date.replace(/-/g, '/')), formatString, { locale: ja });
  } else if (date === null) {
    return null;
  } else {
    return format(date, formatString, { locale: ja });
  }
};

export const toYYYYMMDDhyphen = (date: string | Date) => {
  return format(new Date(date), 'yyyy-MM-dd');
};

export const toYYYYMMDDja = (date: string | Date) => {
  return format(new Date(date), 'yyyy年MM月dd日');
};

export const toYYYYMMja = (date: string | Date) => {
  return format(new Date(date), 'yyyy年MM月');
};

export const toMMDDja = (date: string | Date) => {
  return format(new Date(date), 'MM月dd日');
};

/**
 * @param date
 * @param anchorDate 24時以降を表示する場合に基準日を設定
 */
export const toHHMM = (date: string | Date, anchorDate?: string | Date) => {
  if (!anchorDate) {
    return format(new Date(date), 'HH:mm');
  }

  const dDate = new Date(date);
  const dAnchorDate = new Date(anchorDate);

  if (isSameDay(dDate, dAnchorDate)) {
    return format(new Date(date), 'HH:mm');
  }

  const dNextDate = startOfDay(addDays(dAnchorDate, 1));
  const diffHours = differenceInHours(dDate, dNextDate);

  return `${24 + diffHours}:${`${dDate.getMinutes()}`.padStart(2, '0')}`;
};

export const toHHMMSS = (date: string | Date) => {
  return format(new Date(date), 'HH:mm:ss');
};

const REIWA = 2019;
const HEISEI = 1989;
const SHOWA = 1926;
const TAISHO = 1912;
const MEIJI = 1868;

export const toWareki = (year: number) => {
  if (year > REIWA) {
    return `令和${year - REIWA + 1}年`;
  } else if (year === REIWA) {
    return '平成31年/令和元年';
  } else if (year > HEISEI) {
    return `平成${year - HEISEI + 1}年`;
  } else if (year === HEISEI) {
    return '昭和64年/平成元年';
  } else if (year > SHOWA) {
    return `昭和${year - SHOWA + 1}年`;
  } else if (year === SHOWA) {
    return '大正15年/昭和元年';
  } else if (year > TAISHO) {
    return `大正${year - TAISHO + 1}年`;
  } else if (year === TAISHO) {
    return '明治45年/大正元年';
  } else if (year > MEIJI) {
    return `明治${year - MEIJI + 1}年`;
  } else if (year === MEIJI) {
    return '慶応4年/明治元年';
  } else {
    return `${year}年`;
  }
};
