import {
  DraftAppointment,
  DraftAppointmentCancellationReason,
  DraftAppointmentStatus,
} from '~/graphql';

export const translateReason = (
  draftAppointment: Pick<DraftAppointment, 'status' | 'cancellationReason'>,
) => {
  if (draftAppointment.status === DraftAppointmentStatus.NotPrescribed) {
    return '今回の処方では、処方箋が発行されませんでした';
  }

  switch (draftAppointment.cancellationReason) {
    case DraftAppointmentCancellationReason.Prov:
      return '在庫がなく、他薬局で処方を行う';
    case DraftAppointmentCancellationReason.Pat:
      return '患者と連絡が取れない';
    case DraftAppointmentCancellationReason.OthMu:
      return '医療機関が処方箋を発行しなかった';
    case DraftAppointmentCancellationReason.Other:
      return 'その他';
    default:
      return '不明';
  }
};

export const cancellationReasons = {
  [DraftAppointmentStatus.Available]: [
    DraftAppointmentCancellationReason.OthMu,
    DraftAppointmentCancellationReason.Prov,
    DraftAppointmentCancellationReason.Other,
  ],
  [DraftAppointmentStatus.WaitForBooking]: [
    DraftAppointmentCancellationReason.Pat,
    DraftAppointmentCancellationReason.Prov,
    DraftAppointmentCancellationReason.Other,
  ],
};
