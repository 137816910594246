import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { Tabs } from '~/components/blocks';

import { HrefProps } from '../types';

type Props = {
  href: [HrefProps, ...HrefProps[]];
  children?: React.ReactNode;
};

export const MenuLink: React.FC<Props> = (props) => {
  const router = useRouter();
  const isActive = props.href.some((href) =>
    href.exact ? router.pathname === href.path : router.pathname.indexOf(href.path) === 0,
  );
  const handleClick = useCallback(() => {
    router.push(props.href[0].path);
  }, [router, props.href]);

  return (
    <Tabs.Tab active={isActive} onClick={handleClick}>
      {props.children}
    </Tabs.Tab>
  );
};
