import { useCallback, useMemo } from 'react';

import { PlanFeatureBillingMethod, PlanFeatureCode, useGetContractPlanQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useContractPlan = () => {
  const { loading, data } = useGetContractPlanQuery();

  const plan = useMemo(() => {
    const company = data ? getMe(data)?.organization.company : undefined;
    const isLightPlan = company?.contractLightPlan ?? false;
    const plan =
      company?.contract?.contractPlans && company.contract.contractPlans.length > 0
        ? company.contract.contractPlans[0].plan
        : undefined;
    const name = plan?.name || 'ベーシック';

    return { isLightPlan, name, features: plan?.features };
  }, [data]);

  const verifyPlanFeature = useCallback(
    (featureCode: PlanFeatureCode, billingMethod: PlanFeatureBillingMethod) => {
      if (!plan.features) return false;

      return plan.features.some(
        (feature) => feature.code === featureCode && feature.billingMethod === billingMethod,
      );
    },
    [plan.features],
  );

  return {
    loading,
    activeBasicPlan: plan.name === 'ベーシック',
    activelightPlan: plan.isLightPlan,
    name: plan.name,
    verifyPlanFeature,
  };
};
