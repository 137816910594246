import { useGetOrganizationElectronicPrescriptionQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useOrganizationElectronicPrescription = () => {
  const { data, loading, error } = useGetOrganizationElectronicPrescriptionQuery();
  const me = data ? getMe(data) : null;
  const electronicPrescription = !!me?.organization.webBookingsSetting?.electronicPrescription;

  return {
    loading,
    error,
    electronicPrescription,
  };
};
