import { useCallback, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  GetPatientDetailDialogDocument,
  GetPatientFollowupCommentsDocument,
  useCreateGuestPatientFollowupCommentMutation,
  useCreatePatientFollowupCommentMutation,
  useGetCurrentPractitionerQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import {
  eventPageInfoState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';

import { FollowupCommentForm } from '.';
import { Fields } from './types';

export const useCreateComment = (
  patientType: 'Patient' | 'DirectVisitor' | 'WebVisitor' | undefined,
) => {
  const formRef = useRef<RefAttributeType<typeof FollowupCommentForm> | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const patientDetailState = useRecoilValue(patientDetailDialogState);
  const { data } = useGetCurrentPractitionerQuery();
  const currentPractitioner = data ? getMe(data) : undefined;

  const patientId = patientType === 'Patient' ? patientDetailState.patientId : null;
  const guestPatientId =
    patientType === 'DirectVisitor' || patientType === 'WebVisitor'
      ? patientDetailState.patientId
      : null;

  const setEventInfoState = useSetRecoilState(eventPageInfoState);
  const [createPatientComment] = useCreatePatientFollowupCommentMutation({
    refetchQueries: [
      {
        query: GetPatientDetailDialogDocument,
        variables: {
          id: patientId,
        },
      },
      {
        query: GetPatientFollowupCommentsDocument,
        variables: {
          id: patientId,
        },
      },
    ],
    onCompleted: () => {
      setEventInfoState((_state) => ({ ..._state, isResetEvent: true, page: 1, perPage: 20 }));
    },
  });
  const [createGuestPatientComment] = useCreateGuestPatientFollowupCommentMutation({
    refetchQueries: [
      {
        query: GetPatientDetailDialogDocument,
        variables: {
          id: guestPatientId,
        },
      },
      {
        query: GetPatientFollowupCommentsDocument,
        variables: {
          id: guestPatientId,
        },
      },
    ],
    onCompleted: () => {
      setEventInfoState((_state) => ({ ..._state, isResetEvent: true, page: 1, perPage: 20 }));
    },
  });

  const create = useCallback(
    async (values: Fields, status: null | 'in_progress' | 'finished') => {
      if (!currentPractitioner) {
        return;
      }
      setIsCreating(true);

      try {
        if (patientId) {
          await createPatientComment({
            variables: {
              input: {
                patientId,
                practitionerId: currentPractitioner.id,
                comment: values.comment,
                medicationFollowupStatus: status,
              },
            },
          });
        } else if (guestPatientId) {
          await createGuestPatientComment({
            variables: {
              input: {
                guestPatientId,
                practitionerId: currentPractitioner.id,
                comment: values.comment,
                medicationFollowupStatus: status,
              },
            },
          });
        }
        setError(null);
        setIsCreating(false);
      } catch (_error) {
        setError(_error?.message || 'エラーが発生しました');
        setIsCreating(false);
        throw new Error();
      }
    },
    [
      currentPractitioner,
      patientId,
      guestPatientId,
      createPatientComment,
      createGuestPatientComment,
    ],
  );

  return {
    create,
    formRef,
    error,
    isCreating,
  };
};
