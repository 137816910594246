import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Formik, FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';

import {
  Box,
  Button,
  FieldError,
  Flex,
  Icon,
  Text,
  TextField as DefaultTextField,
} from '~/components/blocks';
import { DEFAULT_WINDOW } from '~/components/partials';
import { onetimeCodeRegexp } from '~/constants/regexp';
import { qrCodeReaderDialogState } from '~/state/partials/medicine_note_notification_panel/atoms';
import { toHalfNumberAndString } from '~/utils/convert';
import { publicPath } from '~/utils/path';
import { openWithNoOpener } from '~/utils/window';

import { Fields } from './types';
import { validationSchema } from './validation';

const Root = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'flex-start',
    background: theme.colors.background.bg,
    border: `${theme.colors.colorPallete.grey03} 1px solid`,
    borderRadius: '8px',
    margin: `${theme.space.m} 0`,
    padding: theme.space.m,
  }),
);

const TextField = styled(DefaultTextField)(({ theme }) =>
  css({
    width: '100%',
    background: theme.colors.background.default,
    border: `${theme.colors.colorPallete.grey03} 1px solid`,
  }),
);

const Label = styled(Text)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.grey01,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    verticalAlign: 'top',
  }),
);

export const OnetimeCode = () => {
  const theme = useTheme();
  const initialValues: Fields = { code: '' };

  const handleBlur = useCallback(
    (formik: FormikProps<Fields>, event: React.FocusEvent<HTMLInputElement>) => {
      if (onetimeCodeRegexp.test(event.currentTarget.value)) return;

      formik.setFieldValue(
        event.currentTarget.name,
        toHalfNumberAndString(event.currentTarget.value),
      );
    },
    [],
  );
  const handleClick = useRecoilCallback(
    ({ set }) =>
      () =>
        set(qrCodeReaderDialogState, { isOpen: true }),
    [],
  );
  const handleSubmit = useCallback((values: Fields) => {
    openWithNoOpener(`/medicinenote/onetime_codes/${values.code}`, {
      target: values.code,
      width: DEFAULT_WINDOW.width,
      height: DEFAULT_WINDOW.height,
    });
  }, []);

  return (
    <Root>
      <img height="70" src={publicPath('/img/img-medicalnote-app.svg')} />
      <Box marginLeft={theme.space.l} width="100%">
        <Label>患者から提示されたワンタイムコードの入力</Label>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Flex width="100%">
                <Box width="100%">
                  <TextField
                    name="code"
                    placeholder="ワンタイムコードの入力"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    onBlur={(e) => handleBlur(formik, e)}
                  />
                </Box>
                <Box>
                  <Button type="submit" use="base" marginLeft={theme.space.m}>
                    表示
                  </Button>
                </Box>
              </Flex>
              {formik.errors.code && <FieldError error={formik.errors.code} />}
            </form>
          )}
        </Formik>
        <Button size="s" wide="fill" marginTop={theme.space.m} onClick={handleClick}>
          <Icon icon="qrcode" size="xl" />
          QRコード読み取り
        </Button>
      </Box>
    </Root>
  );
};
