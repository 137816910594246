import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { useGetEventsLazyQuery } from '~/graphql';
import { messageModalState } from '~/state/partials/message_modal/atoms';

export const useFetchMessageEvents = () => {
  const [{ patientId, profile, page, perPage }, setState] = useRecoilState(messageModalState);
  const [getEvents, { called, loading, data }] = useGetEventsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (_result) => {
      setState((_state) => ({ ..._state, totalPage: _result.events?.pagesCount ?? 1 }));
    },
  });

  const events = useMemo(() => (data ? data?.events?.nodes || [] : []), [data]);

  useEffect(() => {
    if (patientId && profile) {
      getEvents({
        variables: {
          page,
          perPage,
          patientId: profile.type === 'Patient' ? patientId : undefined,
          guestPatientId: profile.type !== 'Patient' ? patientId : undefined,
        },
      });
    }
  }, [getEvents, page, patientId, perPage, profile]);

  return {
    loading: !called || loading,
    events,
  };
};
