import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';

import { Item } from './Item';

const Menu = styled('ul', {
  shouldForwardProp,
})(() =>
  css({
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    [`& > ${Item}:first-of-type`]: css({
      borderRadius: '4px 4px 0 0',
    }),
    [`& > ${Item}:last-of-type`]: css({
      borderRadius: '0 0 4px 4px',
    }),
  }),
);

export { Menu };
