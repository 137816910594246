import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import NextLink from 'next/link';
import React, { ComponentProps } from 'react';

type Props = ComponentProps<typeof NextLink> & {
  className?: string;
};

const MenuLink = styled('a', {
  shouldForwardProp,
})(() =>
  css({
    cursor: 'pointer',
    textDecoration: 'none',
  }),
);

export const Link: React.FC<Props> = (props) => {
  const { className, children, ...nextLinkProps } = props;
  return (
    <NextLink legacyBehavior {...nextLinkProps}>
      <MenuLink className={className}>{children}</MenuLink>
    </NextLink>
  );
};
