import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';

const Title = styled('dt', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: theme.fontSizes.s,
    margin: `0 ${theme.space.s} ${theme.space.m} 0`,
    wordBreak: 'break-word',
  }),
);

const Icon = styled('i', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    whiteSpace: 'nowrap',
    padding: 0,
    marginRight: theme.space.s,
    color: theme.colors.text.primary,
    fontWeight: theme.fontWeights.bold,
    background: theme.colors.background.default,
    border: theme.borders.default,
    fontSize: theme.fontSizes.s,
    fontStyle: 'normal',
    borderRadius: theme.radii.circle,
  }),
);

const Contents = styled('dd', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    margin: 0,
    paddingLeft: '20px',
    fontSize: theme.fontSizes.s,
    fontWeight: theme.fontWeights.bold,
    wordBreak: 'break-word',
  }),
);

const Root = styled('dl', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.fontSizes.s,
    '& + &': {
      marginTop: theme.space.l,
    },
  }),
);

export const Interview = Object.assign(Root, { Title, Contents, Icon });
