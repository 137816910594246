import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { PREFECTURES } from '~/constants/prefectures';
import { ClinicFragment } from '~/graphql';

import { List } from './List';

type Props = {
  clinic: ClinicFragment;
};

const Root = styled('dl')(({ theme }) =>
  css({
    display: 'flex',
    fontSize: theme.fontSizes.s,
  }),
);

const Label = styled('dt')(({ theme }) =>
  css({
    fontWeight: theme.fontWeights.bold,
  }),
);

const ClinicName = styled('dd')(({ theme }) =>
  css({
    marginLeft: theme.space.m,
  }),
);

export const ClinicProfile = React.memo((props: Props) => {
  const { clinic } = props;

  return (
    <Root>
      <Label>医療機関</Label>
      <ClinicName>
        <List>
          <List.Item>{clinic.name}</List.Item>
          {clinic.prefecture && <List.Item>{PREFECTURES[clinic.prefecture]}</List.Item>}
        </List>
      </ClinicName>
    </Root>
  );
});

ClinicProfile.displayName = 'CliincProfile';
