import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { Box, Grid, Header as DefaultHeader, Portal } from '~/components/blocks';
import { CloseBtn } from '~/components/blocks/CloseBtn';

type Props = {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

const Close = styled(CloseBtn, {
  shouldForwardProp,
})(({ theme }) =>
  css({
    position: 'absolute',
    top: theme.space.l,
    right: theme.space.l,
  }),
);

const Root = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    position: 'fixed',
    top: `calc(${theme.layouts.appHeader.height} - 8px)`,
    right: '16px',
    display: 'block',
    height: `calc(80% - ${theme.layouts.appHeader.height})`,
    maxHeight: '670px',
    width: '430px',
    backgroundColor: theme.colors.background.default,
    zIndex: theme.zIndices.drawer,
    boxShadow: `2px 6px 16px ${theme.colors.background.shadow}, -2px 2px 1px ${theme.colors.background.bg}`,
    borderRadius: theme.radii.modal,
    overflow: 'auto',
  }),
);

const Inner = styled('div')(() =>
  css({
    position: 'absolute',
    top: 0,
    display: 'block',
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  }),
);

const Content = styled(Box)(() =>
  css({
    height: '100%',
    overflow: 'auto',
  }),
);

const Header = styled(DefaultHeader)(({ theme }) =>
  css({
    padding: `${theme.space.l} 64px ${theme.space.l} ${theme.space.l}`,
  }),
);

const Footer = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    padding: theme.space.l,
    borderTop: theme.borders.grey,
    boxShadow: `0 0 8px ${theme.colors.background.bg}`,
  }),
);

const Component: React.FC<Props> = (props) => {
  const { onClose } = props;

  if (!props.open) {
    return null;
  }

  return (
    <Portal>
      <Root>
        <Inner>
          <Grid height="100%" overflow="auto" gridTemplateRows="min-content 1fr min-content">
            {props.children}
          </Grid>
        </Inner>
        {onClose && <Close onClick={onClose} />}
      </Root>
    </Portal>
  );
};

export const NotificationPanel = Object.assign(Component, { Header, Content, Footer });
