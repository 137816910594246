import { ThemeProvider } from '@emotion/react';
import React from 'react';

import { theme } from '~/styles/theme';

type Props = {
  children: React.ReactNode;
};

export const AppThemeProvider: React.FC<Props> = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
