import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Flex, Pagination } from '~/components/blocks';
import { organizationNotificationDrawerState } from '~/state/partials/organization_notification_drawer/atoms';

import { NotificationPanelBase } from '../NotificationPanelBase';
import { OrganizationNotificationList } from './OrganizationNotificationList';

export const OrganizationNotificationPanel = () => {
  const [state, setState] = useRecoilState(organizationNotificationDrawerState);
  const handleChangePage = useCallback(
    (page: number) => setState((state) => ({ ...state, page })),
    [setState],
  );

  return (
    <NotificationPanelBase>
      <NotificationPanelBase.Content>
        <OrganizationNotificationList />
      </NotificationPanelBase.Content>
      {state.totalCount > 0 && (
        <NotificationPanelBase.Footer>
          <Flex justifyContent="center">
            <Pagination
              currentPage={state.page}
              totalPage={state.totalPage}
              onChange={handleChangePage}
            />
          </Flex>
        </NotificationPanelBase.Footer>
      )}
    </NotificationPanelBase>
  );
};
