import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Flex, Icon, Name, Text } from '~/components/blocks';
import { Label } from '~/utils/label';

import { ProfileSkeleton } from './ProfileSkeleton';
import { useFetchPatientProfile } from './use-fetch-patient';

export const PatientProfile = React.memo(() => {
  const theme = useTheme();

  const { loading, profile } = useFetchPatientProfile();

  return (
    <Box>
      {!loading && profile ? (
        <>
          <Flex alignItems="center">
            <Box paddingRight={theme.space.s}>
              <Icon
                icon={profile.type !== 'Patient' ? 'patient' : 'smartphone'}
                color="black"
                size="xxl"
              />
            </Box>
            <Box>
              <Name
                size="l"
                familyName={profile.familyName}
                givenName={profile.givenName}
                phoneticFamilyName={profile.phoneticFamilyName}
                phoneticGivenName={profile.phoneticGivenName}
              />
              <Text marginTop={theme.space.s} size="xs">
                最終来局日：{profile.visitDay ? Label.YYYYMMDDja(profile.visitDay) : 'ー'}
              </Text>
            </Box>
          </Flex>
        </>
      ) : (
        <ProfileSkeleton />
      )}
    </Box>
  );
});

PatientProfile.displayName = 'PatientProfile';
