import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Grid } from '~/components/blocks';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { Panel } from '~/state/partials/message_modal/types';

import { MessageEventList } from '../MessageEventList';
import { PatientProfile } from '../PatientProfile';
import { CreateButton } from './CreateButton';

const CreateButtons = styled(Box)(({ theme }) =>
  css({
    borderLeft: theme.borders.grey,
    height: '100%',
    marginLeft: theme.space.l,
    paddingTop: theme.space.m,
    paddingLeft: theme.space.l,
  }),
);

export const InitialPanel = React.memo(() => {
  const theme = useTheme();

  const handleClickMessage = useRecoilCallback(
    ({ set }) =>
      () =>
        set(messageModalState, (_state) => ({ ..._state, currentPanel: Panel.input })),
    [],
  );

  return (
    <Grid height="100%" width="100%" gridTemplateRows="min-content 1fr">
      <PatientProfile />
      <Grid
        paddingTop={theme.space.m}
        height="100%"
        width="100%"
        overflowY="auto"
        gridTemplateColumns="1fr min-content"
      >
        <MessageEventList />
        <CreateButtons>
          <CreateButton
            description={
              <>
                患者に服薬フォローアップや、
                <br />
                メッセージを送信できます
              </>
            }
            onClick={handleClickMessage}
          >
            新規メッセージ作成
          </CreateButton>
        </CreateButtons>
      </Grid>
    </Grid>
  );
});

InitialPanel.displayName = 'InitialPanel';
