import React from 'react';

import { Box, Modal } from '~/components/blocks';

export const CompletePractitionerCodeMailPanel = () => {
  return (
    <>
      <Modal.Header>送信完了</Modal.Header>
      <Modal.Body>
        <Box>
          送付先メールアドレス宛にログイン情報を送信しました。
          <br />
          メールを確認してください。
        </Box>
      </Modal.Body>
    </>
  );
};
