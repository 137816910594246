import { FormikProps } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Counter, Textarea } from '~/components/blocks';
import { answerState } from '~/state/partials/patient_detail_dialog/atoms';

import { Fields } from './types';
import { MAX_REPORT_LENGTH } from './validation';

type Props = {
  disabled: boolean;
  value: string;
  error?: string;
  formik: FormikProps<Fields>;
};

export const ReportField = React.memo((props: Props) => {
  const { formik, disabled } = props;
  const ref = useRef<RefAttributeType<typeof Textarea>>(null);

  const { answer } = useRecoilValue(answerState);
  const resetAnswerState = useResetRecoilState(answerState);

  useEffect(() => {
    if (!ref.current) return;

    if (answer) {
      const insertIndex = ref.current.selectionStart;
      const textArray = Array.from(formik.values.report);

      textArray.splice(insertIndex, 0, answer);
      formik.setFieldValue('report', textArray.join(''));
      resetAnswerState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, resetAnswerState]);

  return (
    <>
      <Textarea
        ref={ref}
        rows={7}
        name="report"
        disabled={disabled}
        error={props.error}
        value={props.value}
        onChange={formik.handleChange}
      />
      <Counter value={props.value} max={MAX_REPORT_LENGTH} />
    </>
  );
});

ReportField.displayName = 'ReportField';
