import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

type Props = {
  count: number;
  onClick: () => void;

  children?: React.ReactNode;
};

const Badge = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.secondary,
    borderRadius: '50%',
    boxShadow: `0 0 6px ${theme.colors.background.secondary}`,
    color: theme.colors.text.white,
    display: 'inline-block',
    fontSize: theme.fontSizes.xs,
    position: 'absolute',
    width: theme.space.l,
    height: theme.space.l,
    lineHeight: theme.space.l,
    left: theme.space.l,
    top: `-${theme.space.s}`,
    textAlign: 'center',
  }),
);

const Root = styled('div')(({ theme }) =>
  css({
    position: 'relative',
    cursor: 'pointer',
    margin: theme.space.m,
  }),
);

export const AppHeaderBadge: React.FC<Props> = (props) => {
  const { count } = props;

  return (
    <Root onClick={props.onClick}>
      {count > 0 ? <Badge>{count}</Badge> : null}
      {props.children}
    </Root>
  );
};

AppHeaderBadge.displayName = 'AppHeaderBadge';
