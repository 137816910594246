import { useGetUberQuoteFeeLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchUberQuoteFee = () => {
  const [getQuote, { loading, error, data }] = useGetUberQuoteFeeLazyQuery({
    fetchPolicy: 'network-only',
  });

  const uberQuote = data ? getNode(data, 'Appointment')?.uberQuote : null;
  const uberQuoteDeliveryFee = uberQuote?.fee ?? null;
  const uberQuoteDeliveryErrorCode = uberQuote?.errorCode ?? null;

  return { getQuote, loading, error, uberQuoteDeliveryFee, uberQuoteDeliveryErrorCode };
};
