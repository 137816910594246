import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Flex, Grid } from '~/components/blocks';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { Panel } from '~/state/partials/message_modal/types';

import { MessageForm } from '../..';
import { Fields } from '../../MessageForm/InputPane/types';
import { Patient } from '../../MessageForm/types';
import { MessageEventList } from '../MessageEventList';
import { PatientProfile } from '../PatientProfile';
import { useDeleteDraftMessage } from './use-delete-draft_message';
import { useSendMessage } from './use-send-message';
import { useUpsertDraftMessage } from './use-upsert-draft_message';

const MessageList = styled(Grid)(() =>
  css({
    wordBreak: 'break-word',
  }),
);

export const InputPanel = () => {
  const { selectedMessageId, draftMessageId, patientId, profile } =
    useRecoilValue(messageModalState);

  const { sending, error, send } = useSendMessage();
  const { upserting, error: upsertError, upsert } = useUpsertDraftMessage();
  const { deleteDraft, deleting } = useDeleteDraftMessage();
  const clearable = !!draftMessageId;

  const patient: Patient = useMemo(
    () => ({
      type: profile?.type,
      visitDay: profile?.visitDay,
      name: profile ? `${profile.familyName}${profile.givenName}` : '',
      id: patientId,
    }),
    [patientId, profile],
  );

  const handleBackInitial = useRecoilCallback(
    ({ set }) =>
      () =>
        set(messageModalState, (_state) => ({
          ..._state,
          currentPanel: Panel.initial,
          selectedMessageId: null,
          draftMessageId: null,
        })),
    [],
  );
  const handleSubmit = useCallback(
    async (values: Fields, isFailed: boolean) => {
      try {
        await send(values, selectedMessageId, isFailed);
      } catch {
        // 何もしない
      }
    },
    [selectedMessageId, send],
  );
  const handleAutoSave = useCallback(
    async (values: Fields) => {
      try {
        await upsert(values, draftMessageId);
      } catch {
        // 何もしない
      }
    },
    [draftMessageId, upsert],
  );

  const handleClear = useCallback(async () => {
    if (draftMessageId) {
      await deleteDraft();
    }
  }, [draftMessageId, deleteDraft]);

  return (
    <Flex height="100%">
      <MessageList gridTemplateRows="min-content 1fr">
        <PatientProfile />
        <MessageEventList />
      </MessageList>
      <Box width="100%">
        <MessageForm
          disabled={sending}
          error={error}
          upsertError={upsertError}
          upserting={upserting}
          deleting={deleting}
          selectMessageId={selectedMessageId}
          patient={patient}
          onBackInitial={handleBackInitial}
          onSubmit={handleSubmit}
          onAutoSave={handleAutoSave}
          onClear={handleClear}
          clearable={clearable}
        />
      </Box>
    </Flex>
  );
};
