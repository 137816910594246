import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Formik, FormikProps } from 'formik';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Flex, Loader } from '~/components/blocks';
import { CsClinicItemFragment, MedicationFollowupQuestionnairesSelectFragment } from '~/graphql';

import { ConfirmPane } from './ConfirmPane';
import { ExclusiveLockError } from './ExclusiveLockError';
import { InputPane } from './InputPane';
import { Fields } from './InputPane/types';
import { validationSchema } from './InputPane/validation';
import { RecommendationPane } from './RecommendationPane';
import { PaneType, Patient } from './types';
import { useDefaultValues } from './use-default_values';
import { useExclusiveLock } from './use-exclusive_lock';
import { useMarkNotification } from './use-mark-notification';

export const MAX_MESSAGE_LENGTH = 300;
export const MAX_SMS_MESSAGE_LENGTH = 100;

type Props = {
  disabled: boolean;
  deleting: boolean;
  error: string | null;
  upsertError: string | null;
  upserting: boolean;
  selectMessageId: string | null;
  patient: Patient;
  textRows?: number;
  onSubmit: (values: Fields, isFailed: boolean) => void;
  onBackInitial: () => void;
  onAutoSave: (values: Fields) => void;
  onClear: () => void;
  clearable: boolean;
};

const InputMessageBox = styled(Box)(({ theme }) =>
  css({
    borderLeft: theme.borders.grey,
    marginLeft: `calc(${theme.space.m} + ${theme.space.s})`,
    padding: `0 0 0 calc(${theme.space.m} + ${theme.space.s})`,
    minWidth: '440px',
  }),
);

export const MessageForm = forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const { selectMessageId, patient, onSubmit, onClear, clearable } = props;
  const isEditable = !!selectMessageId;

  const {
    failed,
    loadingMessage,
    loadingDraftMessage,
    defaultClinic,
    defaultQuestionnaire,
    defaultValues,
    initialValues,
  } = useDefaultValues(selectMessageId, patient, isEditable);

  const {
    lock,
    unlock,
    status: exclusiveLockStatus,
    error: exclusiveLockError,
  } = useExclusiveLock({
    patientId: patient.id,
  });

  const [currentPane, setCurrentPane] = useState<PaneType>('input');
  const [csClinic, setCsClinic] = useState<CsClinicItemFragment | null>(defaultClinic ?? null);
  const [questionnaire, setQuestionnaire] =
    useState<MedicationFollowupQuestionnairesSelectFragment | null>(defaultQuestionnaire ?? null);

  const maxMessageLength =
    props.patient.type === 'Patient' ? MAX_MESSAGE_LENGTH : MAX_SMS_MESSAGE_LENGTH;

  const schema = useMemo(() => validationSchema(maxMessageLength), [maxMessageLength]);

  const handleRecommendClinic = useCallback(() => {
    setCurrentPane('recommendation');
  }, []);
  const handleConfirm = useCallback(() => {
    setCurrentPane('confirm');
  }, []);
  const handleRecommendOrConfirm = useCallback(
    (values: Fields) => {
      if (values.attachment === 'clinic' && !csClinic) {
        setCurrentPane('recommendation');
      } else {
        setCurrentPane('confirm');
      }
    },
    [csClinic],
  );
  const handleBackInput = useCallback(() => setCurrentPane('input'), []);
  const handleRemoveClinic = useCallback(() => {
    setCsClinic(null);
  }, []);
  const handleSelectClinic = useCallback((_csClinic: CsClinicItemFragment) => {
    setCsClinic(_csClinic);
  }, []);
  const handleSubmit = useCallback(
    (values: Fields) => {
      onSubmit(values, failed);
    },
    [failed, onSubmit],
  );
  const handleSelectQuestionnaire = useCallback(
    (questionnaire: MedicationFollowupQuestionnairesSelectFragment) => {
      setQuestionnaire(questionnaire);
    },
    [],
  );
  const handleClickClear = useCallback(
    (formik: FormikProps<Fields>) => {
      formik.resetForm({ values: initialValues });
      onClear();
    },
    [initialValues, onClear],
  );

  useEffect(() => {
    if (props.error) {
      setCurrentPane('input');
    }
  }, [props.error]);

  useEffect(() => {
    setCsClinic(defaultClinic ?? null);
  }, [defaultClinic]);

  useEffect(() => {
    setQuestionnaire(defaultQuestionnaire ?? null);
  }, [defaultQuestionnaire]);
  useMarkNotification();

  useEffect(() => {
    if (isEditable) {
      // 送信予約、または送信エラーメッセージを編集する場合は排他ロックを解除する
      unlock();
    } else {
      lock();
    }

    return () => {
      unlock();
    };
  }, [isEditable, lock, unlock]);

  return (
    <Flex height="100%">
      <InputMessageBox>
        {isEditable || exclusiveLockStatus === 'locking' ? (
          <Formik
            enableReinitialize
            innerRef={ref}
            initialValues={defaultValues}
            validationSchema={schema}
            onSubmit={handleRecommendOrConfirm}
          >
            {(formik) => {
              return currentPane === 'input' ? (
                <InputPane
                  editable={isEditable}
                  clearable={clearable}
                  deleting={props.deleting}
                  disabled={loadingMessage}
                  failed={failed}
                  error={props.error}
                  upsertError={props.upsertError}
                  upserting={props.upserting}
                  loadingDraftMessage={loadingDraftMessage}
                  formik={formik}
                  clinic={csClinic}
                  maxLength={maxMessageLength}
                  textRows={props.textRows}
                  patientName={patient.name}
                  encounteredAt={patient.visitDay}
                  onBack={props.onBackInitial}
                  onRemoveClinic={handleRemoveClinic}
                  onRecommendClinic={handleRecommendClinic}
                  onSelectClinic={handleSelectClinic}
                  onSelectQuestionnaire={handleSelectQuestionnaire}
                  onAutoSave={props.onAutoSave}
                  onClickClear={handleClickClear}
                />
              ) : currentPane === 'recommendation' ? (
                <RecommendationPane
                  editable={isEditable}
                  formik={formik}
                  onBack={handleBackInput}
                  onSelectClinic={handleSelectClinic}
                  onConfirm={handleConfirm}
                  onAutoSave={props.onAutoSave}
                />
              ) : currentPane === 'confirm' ? (
                <ConfirmPane
                  sending={props.disabled}
                  formik={formik}
                  clinic={csClinic}
                  questionnaire={questionnaire}
                  onBack={handleBackInput}
                  onSubmit={handleSubmit}
                />
              ) : null;
            }}
          </Formik>
        ) : exclusiveLockError ? (
          <ExclusiveLockError onBack={props.onBackInitial} error={exclusiveLockError} />
        ) : (
          <Loader open inside appearance="white" />
        )}
      </InputMessageBox>
    </Flex>
  );
});

MessageForm.displayName = 'MessageForm';
