import { useGetMedixsSettingQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useMedixsSetting = () => {
  const { data } = useGetMedixsSettingQuery();
  const medixsSetting = data ? getMe(data)?.organization.medixsSetting : null;
  const enabledMedixs = !!medixsSetting && medixsSetting.active;

  return {
    medixsSetting,
    enabledMedixs,
  };
};
