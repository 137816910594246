import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { rgba } from 'polished';
import React, { useCallback, useEffect } from 'react';
import { useRecoilCallback, useResetRecoilState } from 'recoil';

import { Button, Flex, Icon } from '~/components/blocks';
import { CancelPane } from '~/components/partials';
import { EncounterStatus } from '~/graphql';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import { cancelPane } from '~/state/partials/cancel_pane/atoms';
import { chargeModalState } from '~/state/partials/charge_modal/atoms';
import {
  finishConfirmModalState,
  notificationConfirmModalState,
  notificationCongestionModalState,
} from '~/state/partials/patient_profile_drawer/atoms';

import { useCancelAppointment } from './use-cancel-appointment';
import { useWatchTelemedicine } from './use-watch-telemedicine';

type Encounter = {
  id: string;
  status: EncounterStatus;
};

type Props = {
  appointmentId: string;
  isSentCongestionNotification: boolean;
  patientId: string;
  telemedicine: boolean;
  immediate: boolean;
  encounter?: Encounter;
};

const Wrapper = styled(Flex)(() =>
  css({
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }),
);

const DisabledReason = styled('div')(({ theme }) =>
  css({
    backgroundColor: rgba(theme.colors.background.black, 0.85),
    borderRadius: theme.radii.default,
    color: theme.colors.text.white,
    fontSize: theme.fontSizes.s,
    fontWeight: theme.fontWeights.bold,
    padding: `${theme.space.m} ${theme.space.l}`,
    position: 'absolute',
    textAlign: 'center',
    zIndex: 1,
    top: `-${theme.space.xl}`,
  }),
);

const FinishButton = styled(Button)<{ isSentCongestionNotification: boolean }>(
  ({ isSentCongestionNotification }) =>
    css({
      flexBasis: isSentCongestionNotification ? '30%' : '50%',
    }),
);

const CongestionButton = styled(Button)(() =>
  css({
    flexBasis: '50%',
  }),
);

const makeDisabledReason = (isPharmacist: boolean, inProgress: boolean) => {
  if (inProgress) return 'オンライン服薬指導中です';
  if (!isPharmacist) return '服薬指導の権限がありません';

  return null;
};

export const BookedButtons = (props: Props) => {
  const {
    appointmentId,
    isSentCongestionNotification,
    patientId,
    telemedicine,
    immediate,
    encounter,
  } = props;

  const theme = useTheme();

  const { canceling, handleCancel } = useCancelAppointment(appointmentId);

  const encounterId = encounter?.id;

  // オンライン服薬指導中の人がいるか
  const inProgress = useWatchTelemedicine({
    encounterId,
  });

  const { verifyRole } = usePractitionerRole();

  const resetCancelPane = useResetRecoilState(cancelPane);

  // 指導待ち -> 未会計 （telemedicine: true）
  const openFinishConfirmModal = useRecoilCallback(
    ({ set }) =>
      () => {
        if (encounterId && encounter?.status === 'in_progress') {
          set(finishConfirmModalState, {
            encounterId,
            patientId,
            isOpen: true,
            error: null,
          });
        }
      },
    [encounter?.status, encounterId, patientId],
  );

  // 準備できたよ通知
  // モーダルで準備完了通知送信を確定したあと、指導待ち -> 未会計 （telemedicine: false）
  const handleSendNotification = useRecoilCallback(
    ({ set }) =>
      () => {
        set(notificationConfirmModalState, {
          appointmentId,
          patientId,
          isOpen: true,
          immediate,
        });
      },
    [appointmentId, immediate, patientId],
  );

  const handleCongestionClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(notificationCongestionModalState, {
          isOpen: true,
          appointmentId: props.appointmentId,
          patientId: props.patientId,
        });
      },
    [props.appointmentId, props.patientId],
  );

  // 会計モーダルで確定後
  // - 未会計 -> 会計済(配送待ち) （telemedicine: true）
  // - 指導待ち -> 未会計 -> 完了 （telemedicine: false）
  const openChargeModal = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chargeModalState, {
          appointmentId,
          isOpen: true,
        });
      },
    [appointmentId],
  );

  // キャンセル
  const handleClickCancel = useRecoilCallback(
    ({ set }) =>
      () => {
        set(cancelPane, {
          isOpen: true,
        });
      },
    [],
  );

  // オンライン服薬指導
  const handleClickTelemedicine = useCallback(() => {
    if (encounterId) {
      window.open(`/online/${encounterId}`, encounterId, 'resizable,scrollbars,status,noopener');
    }
  }, [encounterId]);

  const disabledReason = makeDisabledReason(verifyRole('pharmacist'), inProgress);
  const disabled = !!disabledReason;

  useEffect(() => {
    resetCancelPane();
  }, [appointmentId, resetCancelPane]);

  return (
    <>
      {isSentCongestionNotification ? (
        telemedicine ? (
          <Flex flexDirection="column" justifyContent="center">
            <Wrapper>
              {disabled && (
                <DisabledReason>
                  <Icon size="l" icon="attention" />
                  {disabledReason}
                </DisabledReason>
              )}
              <Button
                use="primary"
                wide="fill"
                size="l"
                disabled={disabled}
                onClick={handleClickTelemedicine}
              >
                <Icon color="white" size="l" icon="online" />
                服薬指導
              </Button>
              <FinishButton
                isSentCongestionNotification={isSentCongestionNotification}
                use="base"
                wide="fill"
                size="l"
                ml={theme.space.m}
                disabled={disabled}
                onClick={openFinishConfirmModal}
              >
                完了
              </FinishButton>
            </Wrapper>
            <Button use="white" disabled={inProgress} onClick={handleClickCancel}>
              キャンセル
            </Button>
          </Flex>
        ) : (
          <Flex flexDirection="column" justifyContent="center">
            <Wrapper>
              <Button use="primary" wide="fill" size="l" onClick={handleSendNotification}>
                準備完了通知
              </Button>
              <FinishButton
                isSentCongestionNotification={isSentCongestionNotification}
                use="secondary"
                wide="fill"
                size="l"
                ml={theme.space.m}
                onClick={openChargeModal}
              >
                会計
              </FinishButton>
            </Wrapper>
            <Button use="white" onClick={handleClickCancel}>
              キャンセル
            </Button>
          </Flex>
        )
      ) : telemedicine ? (
        <Flex flexDirection="column" justifyContent="center">
          <Wrapper>
            {disabled && (
              <DisabledReason>
                <Icon size="l" icon="attention" />
                {disabledReason}
              </DisabledReason>
            )}
          </Wrapper>
          <Button
            use="primary"
            wide="fill"
            size="l"
            mb={theme.space.m}
            disabled={disabled}
            onClick={handleClickTelemedicine}
          >
            <Icon color="white" size="l" icon="online" />
            服薬指導
          </Button>
          <Wrapper>
            <CongestionButton
              use="default"
              wide="fill"
              size="l"
              disabled={disabled}
              onClick={handleCongestionClick}
            >
              混雑時通知
            </CongestionButton>
            <FinishButton
              isSentCongestionNotification={isSentCongestionNotification}
              use="base"
              wide="fill"
              size="l"
              ml={theme.space.m}
              disabled={disabled}
              onClick={openFinishConfirmModal}
            >
              完了
            </FinishButton>
          </Wrapper>
          <Button use="white" disabled={inProgress} onClick={handleClickCancel}>
            キャンセル
          </Button>
        </Flex>
      ) : (
        <Flex flexDirection="column" justifyContent="center">
          <Button
            use="primary"
            wide="fill"
            mb={theme.space.m}
            size="l"
            onClick={handleSendNotification}
          >
            準備完了通知
          </Button>
          <Wrapper>
            <CongestionButton use="default" wide="fill" size="l" onClick={handleCongestionClick}>
              混雑時通知
            </CongestionButton>
            <FinishButton
              isSentCongestionNotification={isSentCongestionNotification}
              use="secondary"
              wide="fill"
              size="l"
              ml={theme.space.m}
              onClick={openChargeModal}
            >
              会計
            </FinishButton>
          </Wrapper>
          <Button use="white" onClick={handleClickCancel}>
            キャンセル
          </Button>
        </Flex>
      )}
      <CancelPane align="center" canceling={canceling} onCancel={handleCancel} />
    </>
  );
};
