const KATAKANA_MAP = {
  ｶﾞ: 'ガ',
  ｷﾞ: 'ギ',
  ｸﾞ: 'グ',
  ｹﾞ: 'ゲ',
  ｺﾞ: 'ゴ',
  ｻﾞ: 'ザ',
  ｼﾞ: 'ジ',
  ｽﾞ: 'ズ',
  ｾﾞ: 'ゼ',
  ｿﾞ: 'ゾ',
  ﾀﾞ: 'ダ',
  ﾁﾞ: 'ヂ',
  ﾂﾞ: 'ヅ',
  ﾃﾞ: 'デ',
  ﾄﾞ: 'ド',
  ﾊﾞ: 'バ',
  ﾋﾞ: 'ビ',
  ﾌﾞ: 'ブ',
  ﾍﾞ: 'ベ',
  ﾎﾞ: 'ボ',
  ﾊﾟ: 'パ',
  ﾋﾟ: 'ピ',
  ﾌﾟ: 'プ',
  ﾍﾟ: 'ペ',
  ﾎﾟ: 'ポ',
  ｳﾞ: 'ヴ',
  ﾜﾞ: 'ヷ',
  ｦﾞ: 'ヺ',
  ｱ: 'ア',
  ｲ: 'イ',
  ｳ: 'ウ',
  ｴ: 'エ',
  ｵ: 'オ',
  ｶ: 'カ',
  ｷ: 'キ',
  ｸ: 'ク',
  ｹ: 'ケ',
  ｺ: 'コ',
  ｻ: 'サ',
  ｼ: 'シ',
  ｽ: 'ス',
  ｾ: 'セ',
  ｿ: 'ソ',
  ﾀ: 'タ',
  ﾁ: 'チ',
  ﾂ: 'ツ',
  ﾃ: 'テ',
  ﾄ: 'ト',
  ﾅ: 'ナ',
  ﾆ: 'ニ',
  ﾇ: 'ヌ',
  ﾈ: 'ネ',
  ﾉ: 'ノ',
  ﾊ: 'ハ',
  ﾋ: 'ヒ',
  ﾌ: 'フ',
  ﾍ: 'ヘ',
  ﾎ: 'ホ',
  ﾏ: 'マ',
  ﾐ: 'ミ',
  ﾑ: 'ム',
  ﾒ: 'メ',
  ﾓ: 'モ',
  ﾔ: 'ヤ',
  ﾕ: 'ユ',
  ﾖ: 'ヨ',
  ﾗ: 'ラ',
  ﾘ: 'リ',
  ﾙ: 'ル',
  ﾚ: 'レ',
  ﾛ: 'ロ',
  ﾜ: 'ワ',
  ｦ: 'ヲ',
  ﾝ: 'ン',
  ｧ: 'ァ',
  ｨ: 'ィ',
  ｩ: 'ゥ',
  ｪ: 'ェ',
  ｫ: 'ォ',
  ｯ: 'ッ',
  ｬ: 'ャ',
  ｭ: 'ュ',
  ｮ: 'ョ',
  '｡': '。',
  '､': '、',
  ｰ: 'ー',
  '｢': '「',
  '｣': '」',
  '･': '・',
} as const;
const HALF_KATAKANA_REG = new RegExp(`(${Object.keys(KATAKANA_MAP).join('|')})`, 'g');

/**
 * 半角カタカナを全角カタカナに変換
 * @param str
 */
export const toFullKatakana = (text: string) =>
  text
    .replace(HALF_KATAKANA_REG, (match) => KATAKANA_MAP[match as keyof typeof KATAKANA_MAP])
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜');

/**
 * 全/半角数字以外を除去して全角数字を半角数字文字列に変換
 * @param number
 */
export const toStringHalfNumber = (number: string): string => {
  const newNumber = number.replace(/[^0-9０-９]/g, '');

  return newNumber.replace(/[０-９]/g, (str) => {
    return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
  });
};

/**
 * 全/半角数字以外を除去して全角数字を半角数字に変換
 * @param number
 */
export const toHalfNumber = (number: string | number) => {
  if (typeof number === 'number') {
    return number;
  }

  const newNumber = toStringHalfNumber(number);

  return newNumber === '' ? null : +newNumber;
};

/**
 * 全/半角数字とハイフン系以外を除去し、半角数字と半角ハイフンに変換
 * @param number
 */
export const toHalfNumberWithHyphen = (number: string) => {
  return number.replace(/[^0-9０-９-‐−ー―ｰ－]/g, '').replace(/([０-９]|[-‐−ー―ｰ－])/g, (str) => {
    if (/[-‐−ー―ｰ－]/.test(str)) {
      return '-';
    } else {
      const dst = String.fromCharCode(str.charCodeAt(0) - 0xfee0);
      return dst;
    }
  });
};

/**
 * 全/半角英数字を半角英数字に変換
 * @param string
 */
export const toHalfNumberAndString = (string: string) => {
  return string.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (str) => {
    return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
  });
};
