type FormCode = {
  [key: string]: string;
};

export const FORM_CODES: FormCode = {
  '1': '内服',
  '2': '内滴',
  '3': '屯服',
  '4': '注射',
  '5': '外用',
  '6': '浸煎',
  '7': '湯',
  '9': '材料',
  '10': 'その他',
} as const;
