import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Icon, Text } from '~/components/blocks';

import { HrefProps } from '../types';
import { MenuLink } from './MenuLink';

const Root = styled(MenuLink)(() =>
  css({
    marginTop: 'auto',
  }),
);

const Message = styled(Text)(({ theme }) =>
  css({
    [`@media ${theme.mediaQueries.portrait}`]: {
      display: 'inline-block',
    },
  }),
);

/**
 * [0]はルーティング文字列、以降はオプショナルでメニューをどの時アクティブにするかの文字列
 */
type Props = {
  href: [HrefProps, ...HrefProps[]];
  className?: string;
};

export const MenuBack = React.memo((props: Props) => {
  return (
    <Root href={props.href}>
      <Icon color="white" size="xxxl" icon="back" />
      <Message color="white" size="xs">
        店舗へ
      </Message>
    </Root>
  );
});

MenuBack.displayName = 'MenuBack';
