import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';

import { ScrollBox } from '~/components/blocks';
import { CsClinicItemFragment, useGetCsClinicsLazyQuery } from '~/graphql';
import { searchCsClinicsPageInfoState } from '~/state/partials/search_cs_clinics/atoms';

type Conditions = {
  categoryKey: string | null;
  cityCode: string | null;
  prefectureCode: string | null;
  query: string | null;
  page: number;
};

export const useFetchCsClinics = () => {
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const [isInitialQueried, setIsInitialQueried] = useState(false);
  const [latestConditions, setLatestConditions] = useState<Conditions | null>(null);
  const [{ categoryKey, cityCode, prefectureCode, query, page, perPage }, setState] =
    useRecoilState(searchCsClinicsPageInfoState);
  const [error, setError] = useState<string | null>(null);
  const [csClinics, setCsClinics] = useState<CsClinicItemFragment[]>([]);
  const [getCsClinics, { loading, data }] = useGetCsClinicsLazyQuery({
    onCompleted: (_result) => {
      setState((_state) => ({ ..._state, totalPage: _result.csClinics.pagesCount }));
    },
  });

  const isConditionChanged = useMemo(() => {
    return (
      latestConditions?.categoryKey !== categoryKey ||
      latestConditions?.cityCode !== cityCode ||
      latestConditions?.prefectureCode !== prefectureCode ||
      latestConditions?.query !== query
    );
  }, [
    categoryKey,
    cityCode,
    latestConditions?.categoryKey,
    latestConditions?.cityCode,
    latestConditions?.prefectureCode,
    latestConditions?.query,
    prefectureCode,
    query,
  ]);

  const fetchCsClinic = useCallback(
    (_page?: number) => {
      let requestPage = _page || page;
      if (isConditionChanged) {
        requestPage = 1;
        setState((_state) => ({ ..._state, page: requestPage }));
      }

      getCsClinics({
        variables: {
          categoryKey,
          cityCode,
          prefectureCode,
          query,
          page: requestPage,
          perPage,
        },
      });
      setLatestConditions({ categoryKey, cityCode, prefectureCode, query, page: requestPage });
      if (!isInitialQueried) {
        setIsInitialQueried(true);
      }
    },
    [
      categoryKey,
      cityCode,
      getCsClinics,
      isConditionChanged,
      isInitialQueried,
      page,
      perPage,
      prefectureCode,
      query,
      setState,
    ],
  );
  const handleClick = useCallback(() => {
    if (!categoryKey && !cityCode && !prefectureCode && !query) {
      setError('条件の選択または、病院・診療所名を入力してください');
    } else {
      setError(null);
      fetchCsClinic();
    }
  }, [categoryKey, cityCode, fetchCsClinic, prefectureCode, query]);
  const handleChangePage = useRecoilCallback(
    ({ set }) =>
      (_page: number) => {
        set(searchCsClinicsPageInfoState, (_state) => ({ ..._state, page: _page }));
        fetchCsClinic(_page);
      },
    [fetchCsClinic],
  );

  useEffect(() => {
    setCsClinics(data?.csClinics.nodes || []);
    if (data?.csClinics) {
      setState((_state) => ({ ..._state, totalPage: data.csClinics.pagesCount }));
    }
    scrollRef.current?.toTop();
  }, [data?.csClinics, setState]);

  return {
    scrollRef,
    error,
    loading,
    initialQueried: isInitialQueried,
    csClinics,
    handleClick,
    handleChangePage,
  };
};
