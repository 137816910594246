import React from 'react';
import { useRecoilValue } from 'recoil';

import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import { telemedicineAppointmentsState } from '~/state/layouts/SharedAppShell/atoms';

import { TelemedicineNotification } from './TelemedicineNotification';
import { useSubscriptionTelemedicineAppointment } from './use-subscription-telemedicine_appointment';

export const TelemedicineNotifications = () => {
  const { appointments } = useRecoilValue(telemedicineAppointmentsState);
  const { handleDelete } = useSubscriptionTelemedicineAppointment();
  const { verifyRole } = usePractitionerRole();

  const isPharmacist = verifyRole(['pharmacist']);

  return (
    <>
      {appointments.map((appointment) => (
        <TelemedicineNotification
          key={appointment.id}
          pharmacist={isPharmacist}
          appointment={appointment}
          onClose={handleDelete}
        />
      ))}
    </>
  );
};
