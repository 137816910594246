import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { FormikErrors } from 'formik';
import React, { useCallback } from 'react';

import { FieldError, Flex, Icon, InputTime, Text } from '~/components/blocks';

import { Time } from '../BusinessHourField/types';

type Props = {
  disabled?: boolean;
  enableDelete?: boolean;
  errors?: FormikErrors<Time>;
  timeIntervals: number;
  values: Time;
  onChange: (values: Time) => void;
  onDelete: () => void;
};

const ButtonDelete = styled('button')(({ theme }) =>
  css({
    display: 'flex',
    width: '30px',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.background.bg,
    border: theme.borders.transparent,
    borderRadius: theme.radii.circle,
    marginLeft: theme.space.l,
    outline: 'none',
    transitionDuration: theme.transitions.fast,
    '&:hover': {
      background: theme.colors.background.default,
      border: theme.borders.default,
      cursor: 'pointer',
    },
    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
);

const Root = styled('div')(({ theme }) =>
  css({
    '& + &': {
      marginTop: theme.space.m,
    },
  }),
);

export const BusinessHourField = React.memo((props: Props) => {
  const { onChange } = props;
  const theme = useTheme();
  const handleChangeStart = useCallback(
    (time: string) => onChange({ ...props.values, start: time }),
    [onChange, props.values],
  );
  const handleChangeEnd = useCallback(
    (time: string) => onChange({ ...props.values, end: time }),
    [onChange, props.values],
  );

  return (
    <Root>
      <Flex alignItems="center">
        <InputTime
          without24Hour
          timeIntervals={props.timeIntervals}
          error={props.errors?.start}
          disabled={props.disabled}
          value={props.values.start}
          onChange={handleChangeStart}
        ></InputTime>
        <Text ml={theme.space.s}>〜</Text>
        <InputTime
          timeIntervals={props.timeIntervals}
          ml={theme.space.s}
          error={props.errors?.end}
          disabled={props.disabled}
          value={props.values.end}
          onChange={handleChangeEnd}
        ></InputTime>
        {props.enableDelete && (
          <ButtonDelete disabled={props.disabled} onClick={props.onDelete}>
            <Icon icon="close2" size="xl" />
          </ButtonDelete>
        )}
      </Flex>
      {props.errors ? (
        props.errors.start && props.errors.end && props.errors.start === props.errors.end ? (
          <FieldError error={props.errors.start || props.errors.end} />
        ) : (
          <>
            {props.errors?.start && <FieldError error={props.errors?.start} />}
            {props.errors?.end && <FieldError error={props.errors?.end} />}
          </>
        )
      ) : null}
    </Root>
  );
});

BusinessHourField.displayName = 'BusinessHourField';
