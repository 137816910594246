import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Loader, Paper } from '~/components/blocks';
import { SharedAppShell } from '~/components/layouts/SharedAppShell';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';

import { RootMenu } from './RootMenu';

type Props = {
  children: React.ReactNode;
};

const CompanyAppShell: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const { loadingRole, verifyRole } = usePractitionerRole();
  const isVerified = verifyRole('company_admin');

  if (loadingRole) {
    return <Loader open />;
  }

  return (
    <SharedAppShell>
      <SharedAppShell.SideMenu>
        <RootMenu />
      </SharedAppShell.SideMenu>
      {isVerified ? (
        <SharedAppShell.Content>{props.children}</SharedAppShell.Content>
      ) : (
        <Paper margin={theme.space.xl} padding={theme.space.xl}>
          <Alert status="error">参照権限がありません</Alert>
        </Paper>
      )}
    </SharedAppShell>
  );
};

export { CompanyAppShell };
