import { useEffect } from 'react';

import {
  useGetAppointmentUnreadOrganizationNotificationLazyQuery,
  useMarkOrganizationNotificationMutation,
} from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useMarkNotification = (appointmentId?: string | null) => {
  const [markNotification] = useMarkOrganizationNotificationMutation();
  const [getNotifications] = useGetAppointmentUnreadOrganizationNotificationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      const notifications = getNode(_data, 'Appointment')?.unreadOrganizationNotifications || [];
      for (const notification of notifications) {
        markNotification({
          variables: {
            input: {
              organizationNotificationId: notification.id,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (appointmentId) {
      getNotifications({
        variables: {
          appointmentId,
        },
      });
    }
  }, [appointmentId, getNotifications]);
};
