import { useGetB2cloudSettingQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useB2cloud = () => {
  const { data, loading } = useGetB2cloudSettingQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const me = data ? getMe(data) : null;
  const practitioner = me?.__typename === 'Practitioner' ? me : null;
  const enabledB2cloud = practitioner?.organization.b2cloudSetting?.data.enable;
  const defaultInvoiceType = practitioner?.organization.b2cloudSetting?.data.defaultInvoiceType;

  return {
    loading,
    enabledB2cloud,
    defaultInvoiceType,
  };
};
