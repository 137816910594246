import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { compose, variant } from 'styled-system';

type Props = {
  inline?: boolean;
  type?: 'rect' | 'circle';
  height?: string; // type: rect
  width?: string; // type: rect
  diameter?: string; // type: circle
};

const animation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const Component = styled('div', {
  shouldForwardProp: (propName: string) =>
    shouldForwardProp(propName) && !['inline', 'type', 'width', 'height'].includes(propName),
})<Props>(
  ({ theme, inline, width = '100%', height = theme.fontSizes.m }) =>
    css({
      position: 'relative',
      display: inline ? 'inline-block' : 'block',
      width: width,
      height: height,
      background: 'transparent',
      overflow: 'hidden',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(95deg, transparent, rgba(68, 85, 102, 0.2), transparent)',
        transform: 'translateX(-100%)',
        animation: `${animation} 2s linear infinite`,
      },
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'type',
        variants: {
          rect: css({
            // 設定するプロパティなし
          }),
          circle: css({
            borderRadius: theme.radii.circle,
          }),
        },
      }),
    ),
);

Component.defaultProps = {
  type: 'rect',
};

export const Skeleton = React.memo((props: ComponentProps<typeof Component>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [styles, setStyles] = useState<{ width: string | undefined; height: string | undefined }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (!ref.current) return;

    const parent = ref.current.parentElement;

    if (!parent) return;

    const cssStyleDeclaration = getComputedStyle(parent);
    const fontSize = cssStyleDeclaration.getPropertyValue('font-size');

    if (props.type === 'circle') {
      setStyles({
        width: props.diameter || fontSize,
        height: props.diameter || fontSize,
      });
    } else {
      setStyles({
        width: props.width || '100%',
        height: props.height || fontSize,
      });
    }
  }, [props.height, props.diameter, props.type, props.width]);

  return React.createElement(Component, { ...props, ...styles, ref });
});

Skeleton.displayName = 'Skeleton';
