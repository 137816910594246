import React from 'react';

import { SharedAppShell } from '~/components/layouts/SharedAppShell';
import { PatientDetailDialog } from '~/components/partials';

import { RootMenu } from './RootMenu';

type Props = { children?: React.ReactNode };

const OrganizationAppShell: React.FC<Props> = ({ children }) => {
  return (
    <SharedAppShell>
      <SharedAppShell.SideMenu>
        <RootMenu />
      </SharedAppShell.SideMenu>
      <SharedAppShell.Content>
        {children}
        <PatientDetailDialog />
      </SharedAppShell.Content>
    </SharedAppShell>
  );
};

export { OrganizationAppShell };
