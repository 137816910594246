import { LatLngLiteral } from '@googlemaps/google-maps-services-js';
import React, { useCallback, useEffect, useState } from 'react';

export const Marker = React.memo(
  ({ position, map }: { position: LatLngLiteral; map: google.maps.Map }) => {
    const [instance, setInstance] = useState<google.maps.marker.AdvancedMarkerElement | null>(null);

    const initMarker = useCallback(async () => {
      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        'marker',
      )) as google.maps.MarkerLibrary;

      const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        gmpDraggable: false,
      });

      setInstance(marker);
    }, [map, position]);

    useEffect(() => {
      if (!instance) {
        initMarker();
      } else {
        instance.position = position;
      }
    }, [initMarker, instance, position]);

    return <></>;
  },
);
Marker.displayName = 'Marker';
