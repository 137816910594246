import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { compose, space, SpaceProps, variant } from 'styled-system';

import { Text } from '../Text';

type Props = {
  className?: string;
  label?: string;
  name?: string;
  value?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const MimicRadio = styled('div')<{ disabled?: boolean }>(({ theme }) =>
  css({
    position: 'relative',
    width: theme.fontSizes.xl,
    height: theme.fontSizes.xl,
    background: theme.colors.background.default,
    border: theme.borders.default,
    borderRadius: '50%',
    transitionDuration: theme.transitions.default,
  }),
);

const Root = styled('label', {
  shouldForwardProp,
})<{ disabled?: boolean }>(({ theme, disabled }) =>
  css({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: 'pointer',
    ...(!disabled && {
      [`&:hover > ${MimicRadio}`]: {
        border: theme.borders.primary,
      },
      [`&:focus > ${MimicRadio}`]: {
        border: theme.borders.default,
      },
    }),
    ...(disabled && {
      opacity: 0.6,
      cursor: 'not-allowed',
    }),
  }),
);

const Input = styled('input', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    opacity: 0,
    position: 'absolute',
    [`:checked + ${MimicRadio}::after`]: {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      display: 'block',
      width: theme.fontSizes.m,
      height: theme.fontSizes.m,
      marginTop: '-0.8rem',
      marginLeft: '-0.8rem',
      backgroundColor: theme.colors.background.primary,
      borderRadius: '50%',
      transition: theme.transitions.default,
    },
    [`:focus-visible + ${MimicRadio}`]: {
      border: theme.borders.primary,
    },
  }),
);

export const Radio = (props: Props) => {
  const theme = useTheme();
  const { onChange } = props;

  return (
    <Root disabled={props.disabled} className={props.className}>
      <Input
        type="radio"
        name={props.name}
        value={props.value}
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        disabled={props.disabled}
        onChange={onChange}
      />
      <MimicRadio disabled={props.disabled} />
      {props.label && <Text ml={theme.space.s}>{props.label}</Text>}
    </Root>
  );
};

export const RadioGroup = styled('div', {
  shouldForwardProp,
})<SpaceProps & { orientation?: 'vertical' | 'holizonal' }>(
  () =>
    css({
      display: 'flex',
      alignItems: 'start',
    }),
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'orientation',
        variants: {
          holizonal: css({
            flexDirection: 'row',
            [`& > ${Root} + ${Root}`]: {
              marginLeft: theme.space.m,
            },
          }),
          vertical: css({
            flexDirection: 'column',
            [`& > ${Root} + ${Root}`]: {
              marginTop: theme.space.m,
            },
          }),
        },
      }),
    ),
);

RadioGroup.defaultProps = {
  orientation: 'holizonal',
};
