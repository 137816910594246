import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { DoctorFragment } from '~/graphql';

import { List } from './List';

type Props = {
  doctor: DoctorFragment;
};

const Root = styled('dl')(({ theme }) =>
  css({
    display: 'flex',
    fontSize: theme.fontSizes.s,
  }),
);

const Label = styled('dt')(({ theme }) =>
  css({
    fontWeight: theme.fontWeights.bold,
  }),
);

const DoctorName = styled('dd')(({ theme }) =>
  css({
    marginLeft: theme.space.m,
  }),
);

export const DoctorProfile = React.memo((props: Props) => {
  const { doctor } = props;

  return (
    <Root>
      <Label>医師</Label>
      <DoctorName>
        <List>
          <List.Item>{doctor.name}</List.Item>
          {doctor.departmentName && <List.Item>{doctor.departmentName}</List.Item>}
        </List>
      </DoctorName>
    </Root>
  );
});

DoctorProfile.displayName = 'DoctorProfile';
