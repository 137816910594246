import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Flex, Name, Skeleton } from '~/components/blocks';

import { ProfileDetail } from './ProfileDetail';

export const ProfileSkeleton = () => {
  const theme = useTheme();

  return (
    <Box>
      <Flex alignItems="center">
        <Box marginLeft={theme.space.m}>
          <Name
            familyName={<Skeleton inline width="60px" />}
            givenName={<Skeleton inline width="60px" />}
            phoneticFamilyName={<Skeleton inline width="60px" />}
            phoneticGivenName={<Skeleton inline width="60px" />}
            size="l"
          />
        </Box>
      </Flex>
      <ProfileDetail>
        <ProfileDetail.Item>
          <Skeleton width="80px" />
        </ProfileDetail.Item>
        <ProfileDetail.Item>
          <Skeleton width="30px" />
        </ProfileDetail.Item>
      </ProfileDetail>
    </Box>
  );
};
