import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { CSSProperties } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';

type Props = {
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  open: boolean;
  timeout?: number;
  children: React.ReactNode;
  onExited?: () => void;
};

const Root = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.black,
    borderRadius: theme.radii.default,
    color: theme.colors.text.white,
    maxWidth: '380px',
    opacity: 0.95,
    position: 'fixed',
    top: '10px',
    transition: `right ${theme.transitions.default} ease-out`,
    width: 'fit-content',
    zIndex: theme.zIndices.snackbar,
  }),
);

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: { right: '10px' },
  entered: { right: '10px' },
  exiting: { right: 'calc(-380px - 10px)' },
  exited: { right: 'calc(-380px - 10px)' },
};

export const Snackbar: React.FC<Props> = (props) => {
  return (
    <Transition
      in={props.open}
      timeout={props.timeout ?? 0}
      unmountOnExit={props.unmountOnExit}
      mountOnEnter={props.unmountOnExit}
      onExited={props.onExited}
    >
      {(state) => (
        <Root
          style={{
            ...transitionStyles[state],
          }}
        >
          {props.children}
        </Root>
      )}
    </Transition>
  );
};

export const SnackbarGroup = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: theme.zIndices.snackbar,
    [`> ${Root}`]: {
      position: 'relative',
    },
    [`${Root} + ${Root}`]: {
      marginTop: '10px',
    },
  }),
);
