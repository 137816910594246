import { useCallback, useEffect, useState } from 'react';

import { useUpdateDescriptionMutation } from '~/graphql';

export const useUpdateDescription = (appointmentId: string) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [latestMemo, setLatestMemo] = useState({ memo: '', mutationCalled: false });
  const [updateMemo] = useUpdateDescriptionMutation({
    onCompleted: (_data) => {
      // Apollo or useMemo のキャッシュまわりの不整合が発生するので、メモ更新後は Mutation の返り値を使う
      // FYI https://github.com/medley-inc/pharms/issues/1852
      const latestDescription = _data?.updateAppointmentDescription?.appointment?.description || '';

      // updateDescriptionData はリロード走るまで値を保持し続けるので、別の受付ドロワーを開いたタイミングでリセットできるようにステート管理する
      setLatestMemo({ memo: latestDescription, mutationCalled: true });
    },
  });

  const handleChangeMemo = useCallback(
    async (memo: string) => {
      if (appointmentId) {
        setIsUpdating(true);
        await updateMemo({
          variables: {
            input: {
              appointmentId,
              description: memo,
            },
          },
        });
        setIsUpdating(false);
      }
    },
    [appointmentId, updateMemo],
  );

  useEffect(() => {
    // 受付ドロワーを開いたタイミングでリセットする
    setLatestMemo({ memo: '', mutationCalled: false });
  }, [appointmentId]);

  return { updating: isUpdating, latestMemo, handleChangeMemo };
};
