import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Button, EntryList, Modal, Text } from '~/components/blocks';
import { useOrganizationElectronicPrescription } from '~/hooks/use-organization-electronic-prescription';
import { notificationConfirmModalState } from '~/state/partials/patient_profile_drawer/atoms';

import { useFetchDispensingRequestUploadTypeWithDraftAppointment } from './use-fetch-dispensing_request_upload_type_with_draft_appointment';
import { useFetchPatientName } from './use-fetch-patient_name';
import { useSendMessage } from './use-send-message';

type Props = {
  appointmentId: string;
};

const Message = styled('header')(({ theme }) =>
  css({
    whiteSpace: 'pre-wrap',
    background: theme.colors.background.bg,
    padding: theme.space.l,
  }),
);

export const NotificationConfirmModal = (props: Props) => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(notificationConfirmModalState);

  const { loading, patientName } = useFetchPatientName();
  const { sending, error, handleSend } = useSendMessage();
  const { electronicPrescription } = useOrganizationElectronicPrescription();

  const { pfDispensingRequestUploadType, existsDraftAppointment } =
    useFetchDispensingRequestUploadTypeWithDraftAppointment(props.appointmentId);

  const content = useMemo(() => {
    if (pfDispensingRequestUploadType === 'medical_institution' || existsDraftAppointment) {
      return 'お薬の受け渡し準備ができましたので、薬局にお越しください。';
    } else if (electronicPrescription) {
      return 'お薬の受け渡し準備ができました。処方箋の原本または処方内容（控え）をお持ちのうえ薬局にお越しください。';
    } else {
      return 'お薬の受け渡し準備ができました。処方箋の原本をお持ちのうえ薬局にお越しください。';
    }
  }, [electronicPrescription, existsDraftAppointment, pfDispensingRequestUploadType]);

  const handleClose = useCallback(
    () => setState((_state) => ({ ..._state, isOpen: false, patientId: null })),
    [setState],
  );

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>準備完了通知を送信</Modal.Header>
      <Modal.Body>
        {error && <Alert mb={theme.space.l}>エラーが発生したため送信できませんでした</Alert>}
        <Text>メッセージ内容を確認のうえ、「送信」ボタンをクリックしてください。</Text>
        <EntryList mt={theme.space.m}>
          <EntryList.Head>送信メッセージ</EntryList.Head>
          <EntryList.Body>
            <Message>
              {patientName}様
              <br />
              <br />
              {content}
            </Message>
          </EntryList.Body>
        </EntryList>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" disabled={loading} loading={sending} onClick={() => handleSend()}>
          送信
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
