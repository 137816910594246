import { useState } from 'react';
import { useRecoilCallback } from 'recoil';

import {
  GetUnreadAppointmentsNotificationsDocument,
  useFinishAppointmentMutation,
} from '~/graphql';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';

export const useFinishAppointment = (appointmentId: string) => {
  const [isFinishing, setIsFinishing] = useState(false);

  // 会計済(配送待ち) -> 完了
  const [finishAppointment] = useFinishAppointmentMutation({
    refetchQueries: [{ query: GetUnreadAppointmentsNotificationsDocument }],
  });
  const handleFinish = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          setIsFinishing(true);

          await finishAppointment({
            variables: {
              input: {
                appointmentId,
              },
            },
          });
          set(profileDrawerState, (_state) => ({
            ..._state,
            error: null,
          }));
        } catch (_error) {
          set(profileDrawerState, (_state) => ({
            ..._state,
            error: _error?.message || 'エラーが発生しました',
          }));
        } finally {
          setIsFinishing(false);
        }
      },
    [appointmentId, finishAppointment],
  );

  return { finishing: isFinishing, handleFinish };
};
