import { atom } from 'recoil';

import { DeleteFileModal } from './types';

export const deleteFileModalState = atom<DeleteFileModal>({
  key: 'partials.deleteFileModal',
  default: {
    isOpen: false,
    fileId: null,
    fileName: null,
  },
});
