import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Icon, Text } from '~/components/blocks';
import { MessageEvent } from '~/components/partials/MessageEvent';
import { MessageEventFragment } from '~/graphql';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { Panel } from '~/state/partner_medicationfollowupmessage/types';

type Props = {
  editing?: boolean;
  readonly?: boolean;
  message: MessageEventFragment;
};

const Edit = styled(Box)(({ theme }) =>
  css({
    margin: `${theme.space.m} 0 0`,
    padding: `${theme.space.s} ${theme.space.m}`,
    fontSize: theme.fontSizes.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      color: theme.colors.text.primary,
    },
  }),
);

const Root = styled(MessageEvent)<{ clicked?: boolean; error?: boolean }>(
  ({ theme, clicked, error }) =>
    css({
      ...(error && {
        border: theme.borders.transparent,
        transitionDuration: theme.transitions.default,
        '&:hover': {
          border: theme.borders.secondary,
        },
      }),
      ...(clicked && {
        border: theme.borders.primary,
        background: rgba(theme.colors.background.primary, 0.1),

        ...(error && {
          border: `2px solid ${theme.colors.border.secondary}`,
          background: theme.colors.background.alert,
        }),
        [`& > ${Edit}`]: {
          border: theme.borders.transparent,
          color: theme.colors.text.primary,
          textAlign: 'right',
        },
      }),
    }),
);

export const Message = React.memo((props: Props) => {
  const { editing, message } = props;
  const theme = useTheme();
  const [isClicked, setIsClicked] = useState(editing);

  const deliverResult = message.messageDeliverResult;
  const isError = deliverResult?.status === 'failed';
  const isScheduled = message.status === 'scheduled';
  const isClickable = !props.readonly && (isScheduled || isError);
  const isEditable = isScheduled && isClickable;

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        setIsClicked(true);
        set(messageModalState, (_state) => ({
          ..._state,
          currentPanel: Panel.input,
          selectedMessageId: message.id,
        }));
      },
    [message.id],
  );

  useEffect(() => {
    setIsClicked(editing);
  }, [editing]);

  return (
    <Root
      clicked={isClicked}
      error={isError}
      message={message}
      onClick={isClickable ? handleClick : undefined}
    >
      {isEditable ? (
        <Edit>
          <Icon icon="edit" size="s" color={isClicked ? 'green' : 'grey'} />
          <Text marginLeft={theme.space.s} color={isClicked ? 'green' : 'grey'}>
            {isClicked ? '編集中' : '編集'}
          </Text>
        </Edit>
      ) : null}
      {isError ? (
        <Edit>
          <Icon icon="edit" size="s" color="pink" />
          <Text marginLeft={theme.space.s} color="pink">
            {isClicked ? '編集中' : '編集'}
          </Text>
        </Edit>
      ) : null}
    </Root>
  );
});

Message.displayName = 'Message';
