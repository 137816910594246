import { LineItem, OptionalLineItem } from '~/components/partials';
import {
  ChargeAppointmentFragment,
  ChargeCompanyFeeSettingFragment,
  ChargeFeeSettingFragment,
  ChargeOrganizationCompanyFeeSettingFragment,
  LineItemCode,
} from '~/graphql';
import { SystemAmount } from '~/hooks/use-system_amount';

import { Fields } from './types';
import { useDefaultDelivery } from './use-default_delivery';

export const useCreateInitialValues = (
  appointment: ChargeAppointmentFragment | null,
  feeSettings: (
    | ChargeFeeSettingFragment
    | ChargeCompanyFeeSettingFragment
    | ChargeOrganizationCompanyFeeSettingFragment
  )[],
  systemAmount: SystemAmount,
): Fields => {
  const defaultDelivery = useDefaultDelivery(appointment);
  const requiredUberDeliveryFee = appointment?.requiredUberDeliveryFee || false;
  const fixedSetting = feeSettings.find(({ fixed }) => fixed);
  const lineItem: LineItem = {
    code: fixedSetting?.code || 'billing_amount',
    subject: fixedSetting?.subject || '患者負担金',
    amount: systemAmount['medixs'] || fixedSetting?.defaultAmount || '',
  };
  const optionalLineItems: OptionalLineItem[] = feeSettings
    .filter((feeSetting) => feeSetting.defaultField && !feeSetting.fixed)
    .map((feeSetting) => ({
      feeSettingId: feeSetting.id,
      code: feeSetting.code,
      subject: feeSetting.subject,
      amount: feeSetting.defaultAmount || '',
    }));

  const sameDayDeliveryOptionalLineItems = optionalLineItems.filter(
    (feeSetting) => feeSetting.code !== LineItemCode.ShippingFee, // 配送料以外の項目を抽出
  );
  if (requiredUberDeliveryFee && appointment?.uberDelivery) {
    const uberDeliveryFee = appointment.uberDelivery.fee;
    sameDayDeliveryOptionalLineItems.push({
      feeSettingId: 'uber_delivery_fee_dummy_id', // 使用しないのでダミーのIDを設定
      code: LineItemCode.UberDeliveryFee,
      subject: '当日配達利用料',
      amount: uberDeliveryFee,
    });
  }
  const paymentMethod = appointment?.telemedicine ? 'app' : 'cash';

  return {
    paymentMethod: paymentMethod,
    lineItem,
    optionalLineItems: requiredUberDeliveryFee
      ? sameDayDeliveryOptionalLineItems
      : optionalLineItems,
    deliveryMethod: defaultDelivery,
  };
};
