import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Property } from 'node_modules/csstype';
import React, { ComponentProps } from 'react';
import { compose, space, SpaceProps, variant } from 'styled-system';

import { Icon as IconComponent, IconName } from '../Icon';

type Status = 'error' | 'warning' | 'info' | 'success';

type RootProps = SpaceProps & {
  status?: Status;
  className?: string;
  whiteSpace?: Property.WhiteSpace;
  contentWidth?: Property.Width | 'auto';
  withIcon?: boolean;
  children?: React.ReactNode;
};

type Props = RootProps;

const Root = styled('div', {
  shouldForwardProp,
})<RootProps>(
  ({ theme, whiteSpace }) =>
    css({
      whiteSpace,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: `${theme.space.m} ${theme.space.l}`,
      borderRadius: theme.radii.default,
      [`@media ${theme.mediaQueries.tablet}`]: {
        fontSize: theme.fontSizes.s,
      },
    }),
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'status',
        variants: {
          error: css({
            backgroundColor: theme.colors.background.alert,
            border: theme.borders.alert,
          }),
          warning: css({
            backgroundColor: theme.colors.background.warning,
            border: theme.borders.warning,
          }),
          info: css({
            backgroundColor: theme.colors.background.info,
            border: theme.borders.info,
          }),
          success: css({
            backgroundColor: theme.colors.background.success,
            border: theme.borders.success,
          }),
        },
      }),
    ),
);

const Icon = styled(IconComponent)(({ theme }) =>
  css({
    marginRight: theme.space.m,
  }),
);

const Content = styled('div', {
  shouldForwardProp,
})<RootProps>(({ contentWidth }) =>
  css({
    width: contentWidth,
  }),
);

const getIconName = (status: Status): IconName => {
  switch (status) {
    case 'info':
      return 'info';
    case 'success':
      return 'check';
    case 'warning':
      return 'alert';
    case 'error':
    default:
      return 'alert';
  }
};

const getIconColor = (status: Status): ComponentProps<typeof Icon>['color'] => {
  switch (status) {
    case 'info':
      return 'blue';
    case 'success':
      return 'green';
    case 'warning':
      return 'yellow';
    case 'error':
    default:
      return 'pink';
  }
};

export const Alert: React.FC<Props> = (props) => {
  const { children, status = 'error', withIcon = true, contentWidth, ...componentProps } = props;
  const iconName = getIconName(status);
  const iconColor = getIconColor(status);

  return (
    <Root {...componentProps} status={status}>
      {withIcon && <Icon size="l" icon={iconName} color={iconColor} />}
      <Content contentWidth={contentWidth}>{children}</Content>
    </Root>
  );
};
