import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  alignContent,
  alignItems,
  alignSelf,
  FlexboxProps,
  grid,
  gridGap,
  GridGapProps,
  GridProps,
  justifyContent,
  justifyItems,
  justifySelf,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from 'styled-system';

type Props = GridProps &
  LayoutProps &
  SpaceProps &
  PositionProps &
  GridGapProps &
  Pick<
    FlexboxProps,
    'justifyContent' | 'alignContent' | 'justifyItems' | 'alignItems' | 'justifySelf' | 'alignSelf'
  >;

export const Grid = styled('div', {
  shouldForwardProp,
})<Props>(
  grid,
  justifyContent,
  justifyItems,
  alignItems,
  alignContent,
  justifySelf,
  alignSelf,
  space,
  layout,
  position,
  gridGap,
  ({ display = 'grid' }) =>
    css({
      display,
    }),
);
