import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Alert, Button, Grid, Icon } from '~/components/blocks';
import { FileList } from '~/components/partials/PatientKarte/FilePane/FileList';
import { UploadFile } from '~/components/partials/PatientKarte/FilePane/UploadFile';

type Props = {
  upload: boolean;
  handleClick: () => void;
  isActivePatient: boolean;
};

const ButtonWrapper = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    borderRadius: theme.radii.default,
    padding: theme.space.l,
    margin: theme.space.xs,
  }),
);
export const PatientFile = React.memo((props: Props) => {
  const { upload, handleClick, isActivePatient } = props;
  const theme = useTheme();

  return upload ? (
    <UploadFile onGoBack={handleClick} />
  ) : (
    <Grid
      height="100%"
      gridTemplateRows={
        isActivePatient ? 'min-content min-content 1fr' : 'min-content min-content min-content 1fr'
      }
      gridTemplateColumns="1fr"
    >
      {!isActivePatient && (
        <Alert marginBottom={theme.space.l}>退会済みの患者です。ファイルを送信できません</Alert>
      )}
      <ButtonWrapper>
        <Button wide="fill" use="base" onClick={handleClick} disabled={!isActivePatient}>
          <Icon icon="upload" size="m" color="white" />
          患者にファイル送信
        </Button>
      </ButtonWrapper>
      <FileList />
    </Grid>
  );
});

PatientFile.displayName = 'PatientFile';
