import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Text } from '~/components/blocks';
import { PrimaryPharmacistRecordFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  pharmacist: PrimaryPharmacistRecordFragment;
};

const Root = styled('dl')(({ theme }) =>
  css({
    '& + &': {
      marginTop: theme.space.s,
    },
  }),
);

const ItemLabel = styled('dt')(({ theme }) =>
  css({
    fontWeight: theme.fontWeights.bold,
    float: 'left',
    marginRight: theme.space.m,
  }),
);

const Item = styled('dd')(() =>
  css({
    width: '100%',
  }),
);

const AssignmentPeriod = styled('dl')(({ theme }) =>
  css({
    display: 'flex',
    fontSize: theme.fontSizes.s,
  }),
);

const DateLabel = styled('dt')(({ theme }) =>
  css({
    fontWeight: theme.fontWeights.bold,
    '&::after': {
      content: '"："',
    },
  }),
);

const Date = styled('dd')();

const Spliter = styled(Text)(({ theme }) =>
  css({
    margin: `auto ${theme.space.s}`,
    '&::after': {
      content: '"〜"',
    },
  }),
);

export const PrimaryPharmacist = React.memo((props: Props) => {
  const { pharmacist } = props;

  return (
    <Root>
      <ItemLabel>氏名</ItemLabel>
      <Item>{pharmacist?.name || 'ー'}</Item>
      {pharmacist.pharmacyName && (
        <>
          <ItemLabel>勤務先</ItemLabel>
          <Item>{pharmacist.pharmacyName}</Item>
        </>
      )}
      {pharmacist.contact && (
        <>
          <ItemLabel>連絡先</ItemLabel>
          <Item>{pharmacist.contact}</Item>
        </>
      )}
      {(pharmacist.start || pharmacist.end) && (
        <AssignmentPeriod>
          <DateLabel>担当開始日</DateLabel>
          <Date>{pharmacist.start ? Label.YYYYMMDDja(pharmacist.start) : '不明'}</Date>
          <Spliter />
          {pharmacist.end && (
            <>
              <DateLabel>担当終了日</DateLabel>
              <Date>{Label.YYYYMMDDja(pharmacist.end)}</Date>
            </>
          )}
        </AssignmentPeriod>
      )}
    </Root>
  );
});

PrimaryPharmacist.displayName = 'PrimaryPharmacist';
