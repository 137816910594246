import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { SpaceProps } from 'styled-system';

import { Icon, IconName } from '~/components/blocks';

type Props = SpaceProps & {
  size?: 'l' | 'xl' | 'xxl';
  color?: 'black' | 'white';
  icon?: IconName;
};

const Root = styled('div', {
  shouldForwardProp,
})<Props>(({ theme }) =>
  css({
    alignItems: 'center',
    background: theme.colors.background.black,
    borderRadius: theme.radii.circle,
    display: 'flex',
    justifyContent: 'center',
    minWidth: '32px',
    height: '32px',
    width: '32px',
  }),
);

export const CommentIcon = React.memo((props: Props) => {
  return (
    <Root {...props}>
      <Icon icon={props.icon || 'edit'} size={props.size || 'xl'} color={props.color || 'white'} />
    </Root>
  );
});

CommentIcon.displayName = 'CommentIcon';
