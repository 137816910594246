import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { compose, margin, MarginProps } from 'styled-system';

type Props = MarginProps & {
  width?: string;
  percent: number;
};

const Root = styled('div', {
  shouldForwardProp: (propName: string) => shouldForwardProp(propName) || propName !== 'width',
})<{ width?: string }>(
  ({ theme, width = '100%' }) =>
    css({
      position: 'relative',
      width,
      height: theme.fontSizes.l,
      overflow: 'hidden',
      border: theme.borders.default,
      borderRadius: '1rem',
      boxSizing: 'border-box',
    }),
  () => compose(margin),
);

const animation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const ProgressBar = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'percent',
})<{ percent: number }>(({ theme, percent }) =>
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${percent}%`,
    height: theme.fontSizes.l,
    backgroundColor: theme.colors.colorPallete.green,
    overflow: 'hidden',
    transition: 'width 0.2s ease-in',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(95deg, transparent, rgba(255, 255, 255, 0.5), transparent)',
      transform: 'translateX(-100%)',
      animation: `${animation} 2s linear infinite`,
    },
  }),
);

const Label = styled('span')(({ theme }) =>
  css({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.fontSizes.m,
    fontWeight: 'bold',
    color: theme.colors.text.white,
    textShadow: `0 0 1px ${theme.colors.colorPallete.black}`,
  }),
);

export const Progress = React.memo((props: Props) => {
  const { percent, ...rootProps } = props;
  const barPercent = percent > 100 ? 100 : Math.floor(percent);

  return (
    <Root {...rootProps}>
      <ProgressBar percent={barPercent} />
      <Label>{barPercent}%</Label>
    </Root>
  );
});

Progress.displayName = 'Progress';
