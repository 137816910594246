import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Tag } from '~/components/blocks';

type Props = React.PropsWithChildren<{ size?: 's' | 'm' }>;

const CustomTag = styled(Tag)(({ theme }) =>
  css({
    border: theme.borders.transparent,
    background: theme.colors.background.bg,
    whiteSpace: 'pre-line',
  }),
);

export const FollowupMessageTag = React.memo(({ children, size = 's' }: Props) => {
  return <CustomTag size={size}>{children}</CustomTag>;
});

FollowupMessageTag.displayName = 'FollowupMessageSettingTag';
