import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import { Box, Button, EntryList, Flex, Icon, Text, Tooltip } from '~/components/blocks';
import { useRecreateTracingReport } from '~/components/partials/TracingReportPanel/use-recreate-tracing-report';
import { TracingReportPatientDetailFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { TracingreportIcon } from '../TracingreportIcon';
import { MessageStatus } from './MessageStatus';

type Props = {
  className?: string;
  tracingreport: TracingReportPatientDetailFragment;
};

const Event = styled('div')(({ theme }) =>
  css({
    border: `2px solid ${theme.colors.colorPallete.grey03}`,
    borderRadius: '0px 6px 6px 6px',
    background: theme.colors.background.default,
    padding: theme.space.m,
  }),
);

export const TracingreportEvent: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { tracingreport } = props;

  const pharmacistName = tracingreport.data.pharmacistName;

  const { isRecreating, recreate } = useRecreateTracingReport();

  const handleClick = useCallback(async () => {
    try {
      await recreate(tracingreport.id);
    } catch {
      // 何もしない
    }
  }, [recreate, tracingreport.id]);

  return (
    <Flex alignItems="top">
      <Tooltip
        content={
          <Text color="white" size="xs" fontWeight="bold">
            トレーシングレポート
            <br />
            （店舗内共有）
          </Text>
        }
      >
        <Flex marginLeft={theme.space.xs}>
          <TracingreportIcon />
        </Flex>
      </Tooltip>
      <Flex flexDirection="column" width="100%" marginLeft={theme.space.m}>
        <Text fontWeight="bold" whiteSpace="nowrap" size="xs">
          {Label.YYYYMMDDja(tracingreport.createdAt)} {Label.HHMM(tracingreport.createdAt)}
          &nbsp;|&nbsp;
          {pharmacistName}
        </Text>
        <Event>
          <Box marginTop={theme.space.s}>
            <EntryList.Head>医療機関名</EntryList.Head>
            <EntryList.Body>
              <Text size="m" wordBreak="break-word" whiteSpace="pre-line">
                {tracingreport.data.clinicName}
              </Text>
            </EntryList.Body>
          </Box>

          {tracingreport.latestSentFaxByEvent && (
            <Box marginTop={theme.space.s}>
              <EntryList.Head>FAX番号</EntryList.Head>
              <EntryList.Body>
                <Flex>
                  <Text size="m" wordBreak="break-word" whiteSpace="pre-line">
                    {tracingreport.latestSentFaxByEvent.faxNumber}
                  </Text>
                  <MessageStatus status={tracingreport.latestSentFaxByEvent.status} />
                </Flex>
              </EntryList.Body>
            </Box>
          )}
          <Box marginTop={theme.space.m}>
            <Button wide="fill" onClick={handleClick} disabled={isRecreating}>
              <Text>ファイルを確認</Text>
              <Icon icon="blank" size="s" marginLeft={theme.space.s} />
            </Button>
          </Box>
        </Event>
      </Flex>
    </Flex>
  );
};

TracingreportEvent.displayName = 'TracingreportEvent';
