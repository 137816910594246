import { atom } from 'recoil';

import { AppPreview } from './types';

export const appPreviewState = atom<AppPreview>({
  key: 'partials.appPreview',
  default: {
    isOpen: false,
  },
});
