import { atom } from 'recoil';

import { MessageEventPageInfo, MessagePane, SearchPageInfo } from './types';

export const messageEventPageInfoState = atom<MessageEventPageInfo>({
  key: 'partner.medicationFollowupMessage.messageEvent.pageInfo',
  default: {
    page: 1,
    perPage: 10,
    totalPage: 1,
  },
});

export const messagePaneState = atom<MessagePane>({
  key: 'partner.medicationFollowupMessage.messagePane',
  default: {
    currentPanel: 'initial',
    messageId: null,
    draftMessageId: null,
    patient: null,
    webPatient: null,
  },
});

export const searchPageInfoState = atom<SearchPageInfo>({
  key: 'partials.medicationFollowupMessage.search.pageInfo',
  default: {
    word: null,
    page: 1,
    totalPage: 1,
    perPage: 6,
  },
});
