import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Label = styled('dt')(({ theme }) =>
  css({
    fontWeight: theme.fontWeights.bold,
    '&::after': {
      content: '"："',
    },
  }),
);

const Item = styled('dd')();

const Root = styled('dl')(({ theme }) =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: theme.fontSizes.s,
    [`& > ${Item} + ${Label}`]: {
      marginLeft: theme.space.l,
    },
    '& + &': {
      marginTop: theme.space.s,
    },
  }),
);

export const ProfileDetail = Object.assign(Root, { Label, Item });
