import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Alert } from '~/components/blocks';
import { useGetPractitionerNameQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

const Root = styled(Alert)(({ theme }) =>
  css({
    marginLeft: theme.space.m,
    borderRadius: theme.space.xxxl,
    fontSize: theme.fontSizes.s,
  }),
);

export const ProxyLogin = () => {
  const { data } = useGetPractitionerNameQuery();
  const isProxyLogin = (data && getMe(data)?.isProxy) || false;

  return isProxyLogin ? <Root status="warning">代理ログイン中</Root> : null;
};
