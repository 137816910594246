import { useRef } from 'react';

/**
 * 前回の値を返してくれるフック
 * @param value 今回の値
 * @param skipUndefinedOrNull valueがundefinedまたはnullの場合はセットしない
 */
export const usePreviousValue = <T>(value: T, skipUndefinedOrNull = false) => {
  const ref = useRef<T>();
  const prevValue = ref.current;

  if (!skipUndefinedOrNull || (value !== null && value !== undefined)) {
    ref.current = value;
  }

  return prevValue;
};
