import { atom } from 'recoil';

import {
  Answer,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  DeleteMedicationFollowupMessageModal,
  EventPageInfo,
  PatientDetailConfirmBreakaway,
  PatientDetailDialog,
  PatientDetailDialogMessage,
  patientDetailDialogPatientComment,
  PatientDetailDialogProfile,
  patientDetailDialogTracingreport,
} from './types';

export const patientDetailDialogState = atom<PatientDetailDialog>({
  key: 'partials.patientDetailDialog',
  default: {
    isOpen: false,
    patientId: null,
    type: 'message',
  },
});

export const patientDetailDialogTracingreportState = atom<patientDetailDialogTracingreport>({
  key: 'partials.patientDetailDialogTracingreport',
  default: {
    selectedSheetId: null,
  },
});

export const patientDetailDialogPatientCommentState = atom<patientDetailDialogPatientComment>({
  key: 'partials.patientDetailDialogPatientComment',
  default: {
    selectedCommentId: null,
    isDeleteModalOpen: false,
  },
});

export const patientDetailDialogMessageState = atom<PatientDetailDialogMessage>({
  key: 'partials.patientDetailDialogMessage',
  default: {
    profile: null,
    selectedMessageId: null,
    draftMessageId: null,
    messageInfoStatus: 'new',
    messageInfoIsOpen: true,
  },
});

export const eventPageInfoState = atom<EventPageInfo>({
  key: 'partials.patientDetailEventPageInfo',
  default: {
    isResetEvent: false,
    page: DEFAULT_PAGE,
    perPage: DEFAULT_PER_PAGE,
  },
});

export const answerState = atom<Answer>({
  key: 'partials.patientDetailAnswer',
  default: {
    answer: null,
  },
});

export const patientDetailDialogProfileState = atom<PatientDetailDialogProfile>({
  key: 'partials.patientDetailDialogProfile',
  default: {
    type: null,
  },
});

export const patientDetailConfirmBreakawayState = atom<PatientDetailConfirmBreakaway>({
  key: 'partials.patientDetailConfirmBreakaway',
  default: {
    isOpen: false,
    action: null,
  },
});

export const deleteMedicationFollowupMessageModalState = atom<DeleteMedicationFollowupMessageModal>(
  {
    key: 'partials.patientDetailDeleteMedicationFollowupMessageModal2',
    default: {
      isOpen: false,
      medicationFollowupMessageId: null,
    },
  },
);
