import React from 'react';

import { Hint } from '~/components/blocks';

export const AutoReplySettingHint = () => {
  return (
    <Hint>
      患者が質問票に回答したタイミングで、
      <br />
      自動返信メッセージを送信します。
    </Hint>
  );
};
