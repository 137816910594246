import { useEffect, useMemo } from 'react';

import {
  MedixsModalPatientProfileFragment,
  useGetMedixsModalPatientProfileLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { Label } from '~/utils/label';

const toProfile = (patient: MedixsModalPatientProfileFragment) => {
  return {
    familyName: patient.familyName,
    givenName: patient.givenName,
    phoneticFamilyName: patient.phoneticFamilyName,
    phoneticGivenName: patient.phoneticGivenName,
    age: Label.age(patient.birthDate),
    sex: Label.sex(patient.sex),
    birthDate: Label.YYYYMMDDja(patient.birthDate),
  };
};

export const useFetchPatient = (patientId: string | null) => {
  const [getPatientProfile, { loading, data }] = useGetMedixsModalPatientProfileLazyQuery();

  const profile = useMemo(() => {
    const patient = getNode(data, 'Patient');

    return patient ? toProfile(patient) : null;
  }, [data]);
  useEffect(() => {
    if (patientId) {
      getPatientProfile({ variables: { patientId } });
    }
  }, [getPatientProfile, patientId]);

  return { loading, profile };
};
