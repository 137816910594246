import { PureQueryOptions } from '@apollo/client';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  GetDirectVisitorMessagesDocument,
  GetPatientDetailDialogDocument,
  GetPatientMessagesDocument,
  GetWebVisitorMessagesDocument,
} from '~/graphql';
import {
  patientDetailDialogMessageState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';

export const useBuildRefetchQueries = () => {
  const patientDetailState = useRecoilValue(patientDetailDialogState);
  const { profile } = useRecoilValue(patientDetailDialogMessageState);

  const refetchQueries: PureQueryOptions[] = useMemo(() => {
    const queries: PureQueryOptions[] = [
      {
        query: GetPatientDetailDialogDocument,
        variables: {
          id: patientDetailState.patientId,
        },
      },
    ];

    if (profile?.type === 'DirectVisitor') {
      queries.push({
        query: GetDirectVisitorMessagesDocument,
        variables: { id: patientDetailState.patientId },
      });
    }

    if (profile?.type === 'WebVisitor') {
      queries.push({
        query: GetWebVisitorMessagesDocument,
        variables: { id: patientDetailState.patientId },
      });
    }

    if (profile?.type === 'Patient') {
      queries.push({
        query: GetPatientMessagesDocument,
        variables: { id: patientDetailState.patientId },
      });
    }

    return queries;
  }, [patientDetailState.patientId, profile?.type]);

  return refetchQueries;
};
