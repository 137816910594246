import { AppointmentDeliveryMethod } from '~/graphql';

export const DeliveryMethodLabel = {
  [AppointmentDeliveryMethod.B2cloudCashOnDelivery]: 'B2クラウド_着払い',
  [AppointmentDeliveryMethod.B2cloudCollect]: 'B2クラウド_コレクト',
  [AppointmentDeliveryMethod.B2cloudCompact]: 'B2クラウド_宅急便コンパクト',
  [AppointmentDeliveryMethod.B2cloudCompactCollect]: 'B2クラウド_宅急便コンパクトコレクト',
  [AppointmentDeliveryMethod.B2cloudDm]: 'B2クラウド_ＤＭ便',
  [AppointmentDeliveryMethod.B2cloudNekopos]: 'B2クラウド_ネコポス',
  [AppointmentDeliveryMethod.B2cloudPrepayment]: 'B2クラウド_発払い',
  [AppointmentDeliveryMethod.B2cloudTime]: 'B2クラウド_タイム',
  [AppointmentDeliveryMethod.Parcel]: '宅配便',
  [AppointmentDeliveryMethod.Hand]: '直接受取',
  [AppointmentDeliveryMethod.SameDayDelivery]: '当日受取配達',
  [AppointmentDeliveryMethod.Other]: 'その他',
};
