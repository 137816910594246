import { useTheme } from '@emotion/react';
import React from 'react';
import { useCollapse } from 'react-collapsed';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Button, Flex, Icon, Tooltip } from '~/components/blocks';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';
import { medixsReceptionIdentificationModalState } from '~/state/partials/medixs_reception_identification_modal/atoms';
import { profileDetailTabState } from '~/state/partials/patient_profile_drawer/atoms';

import { LinkPatientButton } from '../LinkPatientButton';
import { PatientProfileSkeleton } from './PatientProfileSkeleton';
import { Profile } from './Profile';
import { ProfileDetailTabs } from './ProfileDetailTabs';
import { useFetchPatientProfile } from './use-fetch-patient_profile';

type Props = {
  patientId: string | null;
  appointmentId: string | null;
};

export const PatientProfile = React.memo((props: Props) => {
  const theme = useTheme();
  const { medixsSetting, enabledMedixs } = useMedixsSetting();
  const { loading, patient } = useFetchPatientProfile(props.patientId);

  const { isExpanded } = useRecoilValue(profileDetailTabState);

  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
    duration: 100,
  });

  const handleExpand = useRecoilCallback(
    ({ set }) =>
      () => {
        set(profileDetailTabState, (_state) => ({ ..._state, isExpanded: !_state.isExpanded }));
      },
    [],
  );
  const openMedixsReceptionIdentificationModal = useRecoilCallback(
    ({ set }) =>
      () => {
        set(medixsReceptionIdentificationModalState, {
          isOpen: true,
          error: null,
          patientId: props.patientId,
          appointmentId: props.appointmentId,
          medixsSettingId: medixsSetting?.id || null,
          selectedMedixsReception: null,
          selectedDate: null,
        });
      },
    [props.patientId, props.appointmentId, medixsSetting?.id],
  );
  const isLinkPatientButtonVisible =
    enabledMedixs && !patient?.medixsPatient && props.appointmentId;

  return (
    <Box>
      {loading || !patient ? (
        <PatientProfileSkeleton />
      ) : (
        <>
          <Profile patient={patient}>
            {patient.active && (
              <Flex>
                {isLinkPatientButtonVisible ? (
                  <LinkPatientButton onClick={openMedixsReceptionIdentificationModal} />
                ) : enabledMedixs && !patient?.medixsPatient ? (
                  <Tooltip content="希望日時確定後に連携できます">
                    <div>
                      <LinkPatientButton disabled />
                    </div>
                  </Tooltip>
                ) : enabledMedixs && patient.medixsPatient ? (
                  <Tooltip content="この患者は連携済みです">
                    <div>
                      <LinkPatientButton disabled />
                    </div>
                  </Tooltip>
                ) : null}
                <Button
                  size="ms"
                  padding={`${theme.space.s} ${theme.space.m}`}
                  {...getToggleProps({
                    onClick: handleExpand,
                  })}
                >
                  <Icon size="s" icon="people" />
                  {isExpanded ? '閉じる' : '詳細'}
                </Button>
              </Flex>
            )}
          </Profile>
          <div {...getCollapseProps()}>
            <ProfileDetailTabs appointmentId={props.appointmentId} patientId={patient.id} />
          </div>
        </>
      )}
    </Box>
  );
});

PatientProfile.displayName = 'PatientProfile';
