import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  Alert,
  Box,
  Button,
  Flex,
  Icon,
  InputDate,
  Loader,
  Modal,
  Table,
  Text,
} from '~/components/blocks';
import { medixsReceptionIdentificationModalState } from '~/state/partials/medixs_reception_identification_modal/atoms';

import { MedixsReception as MedixsReceptionItem } from './MedixsReception';
import { PatientProfile } from './PatientProfile';
import { useFetchMedixsReceptions } from './use-fetch-medixs_receptions';
import { useIdentifyMedixsPatient } from './use-identify-medixs_patient';

export const MedixsReceptionIdentificationModal = () => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(medixsReceptionIdentificationModalState);
  const resetState = useResetRecoilState(medixsReceptionIdentificationModalState);

  const { loading, medixsReceptions } = useFetchMedixsReceptions();
  const { identifying, identify } = useIdentifyMedixsPatient();

  const handleChangeDate = useCallback(
    (date: Date | null) => {
      setState(() => ({
        ...state,
        selectedDate: date,
      }));
    },
    [setState, state],
  );

  return (
    <Modal open={state.isOpen} size="xl" onClose={resetState}>
      <Modal.Header>
        <Icon size="xl" icon="sync" color="blue" />
        <Text size="m">患者情報を「MEDIXS」と連携します</Text>
      </Modal.Header>
      <Modal.Body>
        {state.error && <Alert mb={theme.space.l}>{state.error}</Alert>}
        <PatientProfile patientId={state.patientId} />
        <Box backgroundColor={theme.colors.background.bg} padding="l" minHeight="300px">
          <Flex alignItems="center" justifyContent="start">
            <Text>MEDIXSの受付日: </Text>
            <Icon size="l" icon="calender" />
            <InputDate
              placement="right-start"
              value={state.selectedDate}
              onChange={(date) => handleChangeDate(date)}
            />
          </Flex>
          {loading ? (
            <Loader open inside logoSize="half" appearance="white" />
          ) : medixsReceptions.length > 0 ? (
            <Table appearance="noline">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th></Table.Th>
                  <Table.Th>受付ID</Table.Th>
                  <Table.Th>患者名</Table.Th>
                  <Table.Th>患者名（カナ）</Table.Th>
                  <Table.Th>性別</Table.Th>
                  <Table.Th>生年月日</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {medixsReceptions.map((medixsReception) => {
                  return (
                    <MedixsReceptionItem
                      key={medixsReception.receptionId}
                      medixsReception={medixsReception}
                    />
                  );
                })}
              </Table.Tbody>
            </Table>
          ) : (
            <Alert status="info" mt={theme.space.l}>
              類似する患者情報がありませんでした。
              <br />
              連携システム側の受付日を変更して再検索、
              または連携システム側に患者情報が登録されているか確認してください。
            </Alert>
          )}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          use="base"
          disabled={!state.selectedMedixsReception}
          loading={identifying}
          onClick={identify}
          color="secondary"
        >
          連携
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
