import { css } from '@emotion/react';

export const styles = css({
  '.print': {
    display: 'none',
  },
  '@page': {
    size: 'A4 portrait',
    /* 印刷ヘッダーと印刷フッターを非表示するcss hack */
    /* https://qiita.com/cognitom/items/d39d5f19054c8c8fd592#%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B6%E3%81%8C%E5%87%BA%E5%8A%9B%E3%81%99%E3%82%8B%E4%BD%99%E8%A8%88%E3%81%AA%E3%83%98%E3%83%83%E3%83%80%E3%83%95%E3%83%83%E3%82%BF */
    margin: 0,
    padding: 0,
  },
  '@media print': {
    body: {
      /* A4は210mm x 297mm */
      height: '296.5mm' /* 高さには0.5mm余裕をもたせる */,
      width: '210mm',

      margin: '16px 24px',
      paddingRight: '16px',

      h1: {
        fontSize: '22px',
        marginBottom: '10px',
      },
      dd: {
        fontWeight: 'bold',
      },
      '.print': {
        display: 'block',
      },
      '*:not(.print):not(dl):not(dt):not(dd):not(h1)': {
        display: 'none',
      },
    },
  },
});
