import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, EntryList, Name, Skeleton } from '~/components/blocks';

export const ProfileSkeleton = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box>
        <Name
          familyName={<Skeleton inline width="60px" />}
          givenName={<Skeleton inline width="60px" />}
          phoneticFamilyName={<Skeleton inline width="60px" />}
          phoneticGivenName={<Skeleton inline width="60px" />}
          size="l"
        />
      </Box>
      <Box paddingY={theme.space.m}>
        <Skeleton inline width="125px" />
      </Box>
      <EntryList size="s">
        <EntryList.Head>
          <Skeleton inline width="60px" />
        </EntryList.Head>
        <EntryList.Body>
          <Skeleton inline width="100px" />
        </EntryList.Body>
      </EntryList>
    </Box>
  );
};
