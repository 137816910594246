import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';

import { Box } from '~/components/blocks';
import {
  NotebookMemoRecordFragment,
  PatientReportRecordFragment,
  PrimaryPharmacistRecordFragment,
} from '~/graphql';
import { Label } from '~/utils/label';

import { ONLY_PERSON, toCreator } from '../creator';
import { MedicineNoteTable } from '../MedicineNoteTable';
import { PrimaryPharmacist } from './PrimaryPharmacist';

const REPORT_TYPE = {
  Allergy: 1,
  SideEffect: 2, // 副作用
  MedicalHistory: 3,
  Other: 9,
} as const;

type Props = {
  memos: NotebookMemoRecordFragment[];
  patientReports: PatientReportRecordFragment[];
  primaryPharmacists: PrimaryPharmacistRecordFragment[];
};

const Root = styled(Box)(({ theme }) =>
  css({
    border: `3px solid ${theme.colors.colorPallete.grey03} `,
    padding: theme.space.m,
  }),
);

export const PatientDetailProfile = React.memo((props: Props) => {
  const { memos, patientReports, primaryPharmacists } = props;

  const history = useMemo(() => {
    const allergies: string[] = [];
    const sideEffects: string[] = [];
    const medicalHistories: string[] = [];
    const others: string[] = [];

    for (const report of patientReports) {
      if (report.type === REPORT_TYPE.Allergy && report.content) {
        allergies.push(report.content);
      }
      if (report.type === REPORT_TYPE.Other && report.content) {
        others.push(report.content);
      }
      if (report.type === REPORT_TYPE.SideEffect && report.content) {
        sideEffects.push(report.content);
      }
      if (report.type === REPORT_TYPE.MedicalHistory && report.content) {
        medicalHistories.push(report.content);
      }
    }

    return { allergies, sideEffects, medicalHistories, others };
  }, [patientReports]);
  const hasHistory =
    history.allergies.length > 0 ||
    history.sideEffects.length > 0 ||
    history.medicalHistories.length > 0 ||
    history.others.length > 0;

  if (!hasHistory && memos.length === 0 && primaryPharmacists.length === 0) {
    return null;
  }

  return (
    <Root>
      <MedicineNoteTable>
        <MedicineNoteTable.Tbody>
          {history.allergies.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top={history.allergies.length > 1}>
                アレルギー歴
              </MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {history.allergies.map((allergy, idx) => (
                    <li key={idx}>{allergy}</li>
                  ))}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
          {history.sideEffects.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top={history.sideEffects.length > 1}>
                副作用歴
              </MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {history.sideEffects.map((sideEffect, idx) => (
                    <li key={idx}>{sideEffect}</li>
                  ))}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
          {history.medicalHistories.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top={history.medicalHistories.length > 1}>
                既往歴
              </MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {history.medicalHistories.map((medicalHistory, idx) => (
                    <li key={idx}>{medicalHistory}</li>
                  ))}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
          {history.others.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top={history.others.length > 1}>その他</MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {history.others.map((other, idx) => (
                    <li key={idx}>{other}</li>
                  ))}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
          {memos.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top={memos.length > 1}>お薬手帳メモ</MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                <ul>
                  {memos.map((memo, idx) => {
                    if (memo.content) {
                      return (
                        <li key={idx}>{`${memo.content}（${
                          memo.enteredAt ? Label.YYYYMMDDja(memo.enteredAt) : '不明'
                        }${
                          memo.creator && ONLY_PERSON.includes(memo.creator)
                            ? `｜${toCreator(memo.creator)}`
                            : ''
                        }）`}</li>
                      );
                    }
                  })}
                </ul>
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
          {primaryPharmacists.length > 0 && (
            <MedicineNoteTable.Tr>
              <MedicineNoteTable.Th top>かかりつけ薬剤師</MedicineNoteTable.Th>
              <MedicineNoteTable.Td>
                {primaryPharmacists.map((pharmacist, idx) => (
                  <PrimaryPharmacist key={idx} pharmacist={pharmacist} />
                ))}
              </MedicineNoteTable.Td>
            </MedicineNoteTable.Tr>
          )}
        </MedicineNoteTable.Tbody>
      </MedicineNoteTable>
    </Root>
  );
});

PatientDetailProfile.displayName = 'PatientDetailProfile';
