import styled from '@emotion/styled';
import React, { useContext } from 'react';

import { DialogContext } from './DialogContext';

type Props = {
  children?: React.ReactNode;
};

const Root = styled('header')<{ invert?: boolean }>(({ theme, invert }) => ({
  fontWeight: theme.fontWeights.bold,
  padding: theme.space.l,
  paddingLeft: '48px',
  borderBottom: theme.borders.default,
  boxShadow: theme.shadows.default,
  ...(invert && {
    color: theme.colors.text.white,
    borderBottom: 'none',
  }),
}));

export const Header: React.FC<Props> = (props) => {
  const { invert } = useContext(DialogContext);

  return <Root invert={invert}>{props.children}</Root>;
};
