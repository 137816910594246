import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { useDeleteTracingReportDraftMutation } from '~/graphql';
import { tracingReportPaneState } from '~/state/partials/tracingreport_panel/atoms';

export const useDeleteTracingReportDraft = (
  patientId?: string | null,
  guestPatientId?: string | null,
) => {
  const [error, setError] = useState<string | null>(null);
  const setTracingReportPaneState = useSetRecoilState(tracingReportPaneState);
  const [deleteTracingReportDraft] = useDeleteTracingReportDraftMutation({
    onCompleted: () => {
      setTracingReportPaneState((state) => ({
        ...state,
        tracingReportDraftId: null,
      }));
    },
  });

  const deleteDraft = useCallback(async () => {
    setError(null);

    try {
      await deleteTracingReportDraft({
        variables: {
          input: {
            patientId: patientId || null,
            guestPatientId: guestPatientId || null,
          },
        },
      });
    } catch (_error) {
      setError(_error?.message || 'エラーが発生しました');
      throw new Error();
    }
  }, [patientId, guestPatientId, deleteTracingReportDraft]);

  return {
    error,
    setError,
    deleteDraft,
  };
};
