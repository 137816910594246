import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Text } from '~/components/blocks';
import { ReceptionUberDeliveryFragment, UberDeliveryStatus } from '~/graphql';

import { CourierInfo } from './CourierInfo';
import { DeliveredTextBox } from './DeliveredTextBox';

type Props = {
  uberDelivery: ReceptionUberDeliveryFragment;
};

const Root = styled('div')(({ theme }) =>
  css({
    padding: theme.space.m,
    paddingTop: '0',
  }),
);

const ExternalBox = styled(Box)(({ theme }) =>
  css({
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: ' 100%',
    textAlign: 'center',
    borderRadius: theme.radii.default,
    border: `1px solid ${theme.colors.colorPallete.grey03}`,
  }),
);

export const ReceiveOptionSubContent = React.memo((props: Props) => {
  const { uberDelivery } = props;
  const uberDeliveryOrderNumber = uberDelivery.orderNumber;

  if (!uberDeliveryOrderNumber) {
    return null;
  }

  return (
    <Root>
      {uberDelivery.status !== UberDeliveryStatus.Suspended && (
        <ExternalBox>
          <Text size="l" fontWeight="bold" color="cyan">
            注文番号：{uberDeliveryOrderNumber}
          </Text>
        </ExternalBox>
      )}
      {(uberDelivery.status === UberDeliveryStatus.Pickup ||
        uberDelivery.status === UberDeliveryStatus.Dropoff ||
        uberDelivery.status === UberDeliveryStatus.Canceled) && (
        <CourierInfo uberDelivery={uberDelivery} />
      )}
      {uberDelivery?.status === UberDeliveryStatus.Delivered && uberDelivery.deliveredAt && (
        <DeliveredTextBox deliveredAt={uberDelivery.deliveredAt} />
      )}
    </Root>
  );
});

ReceiveOptionSubContent.displayName = 'ReceiveOptionSubContent';
