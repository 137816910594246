import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Box, Grid, Tabs } from '~/components/blocks';
import { TracingReport } from '~/components/partials/PatientKarte/FilePane/TracingReport';
import { PatientFileTabMap } from '~/constants/tab';
import { PlanFeatureCode } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { filePageInfoState } from '~/state/partials/patient_karte/atoms';
import { tracingReportPageInfoState } from '~/state/partials/tracingreport_panel/atoms';

import { PatientFile } from './PatientFile';

type Props = {
  patientId: string;
  online?: boolean;
  isActivePatient: boolean;
};

export const FilePane = React.memo((props: Props) => {
  const theme = useTheme();
  const { patientId, online, isActivePatient } = props;
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);
  const { hasEnabledFeature: hasEnabledTracingReport } = usePlanFeature(
    PlanFeatureCode.TracingReport,
  );

  const [isUpload, setIsUpload] = useState(false);
  const [pageInfo, setPageInfo] = useRecoilState(filePageInfoState);
  const setTracingReportPageInfo = useSetRecoilState(tracingReportPageInfoState);

  const handleClick = useCallback(() => setIsUpload(!isUpload), [isUpload]);

  const [tab, setTab] = useState<string>(PatientFileTabMap.patient);
  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.target as HTMLButtonElement;
      const newTab = element.dataset['tab'] as string;
      setTab(newTab);
    },
    [setTab],
  );

  useEffect(() => {
    if (patientId !== pageInfo.patientId) {
      setPageInfo((_state) => ({
        ..._state,
        patientId,
        page: 1,
        totalPage: 1,
        filter: { sent: true, received: true },
      }));

      setTracingReportPageInfo((_state) => ({
        ..._state,
        target: 'Patient',
        targetId: patientId,
        page: 1,
        totalPage: 1,
      }));
    }
  }, [pageInfo.patientId, patientId, setPageInfo, setTracingReportPageInfo]);

  return !hasEnabledFollowup ? (
    <Box padding={theme.space.l}>
      <PatientFile upload={isUpload} handleClick={handleClick} isActivePatient={isActivePatient} />
    </Box>
  ) : (
    <Grid
      height="100%"
      gridTemplateRows="min-content 1fr"
      gridAutoColumns="1fr"
      paddingTop={theme.space.s}
      paddingBottom={theme.space.l}
      paddingX={theme.space.l}
    >
      {hasEnabledTracingReport && (
        <Tabs>
          <Tabs.Tab
            size="xs"
            active={tab === PatientFileTabMap.patient}
            data-tab={PatientFileTabMap.patient}
            onClick={handleChange}
          >
            患者共有
          </Tabs.Tab>
          <Tabs.Tab
            size="xs"
            active={tab === PatientFileTabMap.medicalInstitution}
            data-tab={PatientFileTabMap.medicalInstitution}
            onClick={handleChange}
          >
            店舗内共有
          </Tabs.Tab>
        </Tabs>
      )}
      <Box paddingY={theme.space.l} overflow="auto">
        {tab === PatientFileTabMap.patient && (
          <PatientFile
            upload={isUpload}
            handleClick={handleClick}
            isActivePatient={isActivePatient}
          />
        )}
        {tab === PatientFileTabMap.medicalInstitution && (
          <TracingReport patientId={patientId} online={online} />
        )}
      </Box>
    </Grid>
  );
});

FilePane.displayName = 'FilePane';
