import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';

import { useCreateFollowupMessageMutation, useUpdateFollowupMessageMutation } from '~/graphql';
import { useMedicationFollowupQuestionnaires } from '~/hooks/use-medication-followup-questionnaires';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { Panel } from '~/state/partials/message_modal/types';
import { formatISOString, toYYYYMMDDhyphen } from '~/utils/date';

import { Fields } from '../../MessageForm/InputPane/types';
import { useBuildRefetchQueries } from './use-build-refetch_queries';

export const useSendMessage = () => {
  const refetchQueries = useBuildRefetchQueries();
  const { questionnaires } = useMedicationFollowupQuestionnaires();
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [{ profile }, setState] = useRecoilState(messageModalState);

  const appointmentId = profile?.type !== 'DirectVisitor' ? profile?.receptionId : undefined;
  const visitId = profile?.type === 'DirectVisitor' ? profile.receptionId : undefined;

  const [create] = useCreateFollowupMessageMutation();
  const [update] = useUpdateFollowupMessageMutation();

  const send = useCallback(
    async (values: Fields, messageId: string | null, isSendingFailed?: boolean) => {
      if (!appointmentId && !visitId) return;
      if (values.attachment === 'clinic' && values.csClinicId.length === 0) {
        setError('病院または、診療所を選択してください');
        return;
      }

      setIsSending(true);
      setError(null);

      const sendAt =
        values.fixed && values.sendDate
          ? formatISOString(`${toYYYYMMDDhyphen(values.sendDate)} ${values.sendTime}:00`)
          : null;

      const medicationFollowupQuestionnaireId = values.medicationFollowupQuestionnaireId;
      const medicationFollowupQuestionnaireRevisionId =
        values.attachment === 'questionnaire_sheet' && medicationFollowupQuestionnaireId
          ? questionnaires?.find((q) => q.id === medicationFollowupQuestionnaireId)?.revision.id
          : null;
      const hasQuestionnaireSheetWithAutoReplySetting =
        values.attachment === 'questionnaire_sheet' && values.isEnabledAutoReplySetting;

      try {
        if (messageId && !isSendingFailed) {
          await update({
            variables: {
              input: {
                sendAt,
                medicationFollowupMessageId: messageId,
                message: values.message,
                csClinicId: values.attachment === 'clinic' ? values.csClinicId : null,
                medicationFollowupQuestionnaireRevisionId:
                  medicationFollowupQuestionnaireRevisionId,
                autoReplyMessage: hasQuestionnaireSheetWithAutoReplySetting
                  ? values.autoReplyMessage
                  : null,
                withAutoReplyQuestionnaire: hasQuestionnaireSheetWithAutoReplySetting
                  ? values.withAutoReplyQuestionnaire
                  : null,
              },
            },
            refetchQueries,
          });
        } else {
          await create({
            variables: {
              input: {
                appointmentId,
                visitId,
                sendAt,
                message: values.message,
                csClinicId: values.attachment === 'clinic' ? values.csClinicId : null,
                medicationFollowupQuestionnaireRevisionId:
                  medicationFollowupQuestionnaireRevisionId,
                autoReplyMessage: hasQuestionnaireSheetWithAutoReplySetting
                  ? values.autoReplyMessage
                  : null,
                withAutoReplyQuestionnaire: hasQuestionnaireSheetWithAutoReplySetting
                  ? values.withAutoReplyQuestionnaire
                  : null,
              },
            },
            refetchQueries,
          });
        }
        setIsSending(false);
        setState((_state) => ({
          ..._state,
          selectedMessageId: null,
          currentPanel: Panel.initial,
        }));
      } catch (_error) {
        setError(_error?.message || 'エラーが発生しました');
        setIsSending(false);
        throw new Error();
      }
    },
    [appointmentId, create, questionnaires, refetchQueries, setState, update, visitId],
  );

  return {
    sending: isSending,
    error,
    send,
  };
};
