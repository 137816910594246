import { atom } from 'recoil';

import {
  AgentNotifications,
  ClientAuthenticationModal,
  FaximoSendFaxErrors,
  FollowupMessageErrors,
  Logout,
  MedicationFollowupNotificationPanelState,
  Notification,
  NotificationPanelState,
  NotificationSettings,
  RenewClientCertModal,
  TelemedicineAppointments,
  TwoFactorAuthenticationModal,
  UberDeliveryNotifications,
} from './types';

export const medicationFollowupNotificationPanelState =
  atom<MedicationFollowupNotificationPanelState>({
    key: 'layout.sharedAppShell.medicationFollowupNotificationPanelState',
    default: {
      page: 1,
      perPage: 6,
      totalPage: 1,
      totalCount: 0,
    },
  });

export const notificationPanelState = atom<NotificationPanelState>({
  key: 'layout.sharedAppShell.notificationPanelState',
  default: {
    isOpen: false,
  },
});

export const logoutState = atom<Logout>({
  key: 'layout.sharedAppShell.logout',
  default: {
    isLogout: false,
  },
});

export const notificationState = atom<Notification>({
  key: 'layout.sharedAppShell.notification',
  default: {
    followupCount: 0,
    receptionCount: 0,
    medicineNoteCount: 0,
  },
});

export const notificationSettingsState = atom<NotificationSettings>({
  key: 'layout.sharedAppShell.notificationSettings',
  default: {
    isLightPlan: false,
    isFollowupEnabled: false,
    isOnlineEnabled: false,
  },
});

export const telemedicineAppointmentsState = atom<TelemedicineAppointments>({
  key: 'layout.sharedAppShell.telemedicineAppointments',
  default: {
    isEnabled: true,
    appointments: [],
  },
});

export const twoFactorAuthModalState = atom<TwoFactorAuthenticationModal>({
  key: 'layout.shardAppShell.twoFactorAuthenticationModal',
  default: {
    isOpen: false,
    isReset: false,
    currentPanel: 'introduction',
    otpSecretId: null,
    qrCodeData: null,
  },
});

export const faximoSendFaxErrorState = atom<FaximoSendFaxErrors>({
  key: 'layout.sharedAppShell.faximoSendfaxErrors',
  default: {
    loaded: false,
    notifications: [],
  },
});

export const clientAuthenticationModalState = atom<ClientAuthenticationModal>({
  key: 'layout.shardAppShell.clientAuthenticationModal',
  default: {
    isClosed: false,
  },
});

export const followupMessageErrorState = atom<FollowupMessageErrors>({
  key: 'layout.sharedAppShell.followupMessageErrors',
  default: {
    loaded: false,
    notifications: [],
  },
});

export const uberDeliveryNotificationsState = atom<UberDeliveryNotifications>({
  key: 'layout.sharedAppShell.uberDeliveryNotifications',
  default: {
    loaded: false,
    notifications: [],
    needPlaySoundNotificationCount: 0,
  },
});

export const agentNotificationsState = atom<AgentNotifications>({
  key: 'layout.sharedAppShell.agentNotifications',
  default: {
    loaded: false,
    notifications: [],
  },
});

export const renewClientCertModalState = atom<RenewClientCertModal>({
  key: 'layout.shardAppShell.renewClientCertModal',
  default: {
    isOpen: false,
    isClosed: false,
    isAdmin: false,
    isCompanyAdmin: false,
    renewableTarget: null,
    clientCert: null,
    practitionerId: '',
  },
});
