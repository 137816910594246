import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { TracingReportDraftPrescriptionDrug, useUpsertTracingReportDraftMutation } from '~/graphql';
import { tracingReportPaneState } from '~/state/partials/tracingreport_panel/atoms';
import { formatISOString, toYYYYMMDDhyphen } from '~/utils/date';

import { Fields } from './types';

export const useUpsertTracingReportDraft = (
  patientId?: string | null,
  guestPatientId?: string | null,
) => {
  const [isUpserting, setIsUpserting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const setTracingReportPaneState = useSetRecoilState(tracingReportPaneState);
  const [upsertTracingReportDraft] = useUpsertTracingReportDraftMutation({
    onCompleted: (result) => {
      setTracingReportPaneState((state) => ({
        ...state,
        tracingReportDraftId: result.upsertTracingReportDraft?.tracingReportDraft.id || null,
      }));
    },
  });

  const upsert = useCallback(
    async (values: Fields, tracingReportDraftId: string | null) => {
      setIsUpserting(true);
      setError(null);

      const prescriptionIssueDate =
        values.prescriptionIssueDate && values.prescriptionIssueDate
          ? formatISOString(`${toYYYYMMDDhyphen(values.prescriptionIssueDate)}`)
          : null;
      const dispensingDate =
        values.dispensingDate && values.dispensingDate
          ? formatISOString(`${toYYYYMMDDhyphen(values.dispensingDate)}`)
          : null;
      const prescriptionDrugs = values.prescriptionDrugs.map(
        (drugName): TracingReportDraftPrescriptionDrug => {
          return { drugName };
        },
      );

      try {
        await upsertTracingReportDraft({
          variables: {
            input: {
              tracingReportDraftId: tracingReportDraftId,
              patientId: patientId || null,
              guestPatientId: guestPatientId || null,
              data: {
                clinicName: values.clinicName || null,
                clinicalDepartment: values.clinicalDepartment || null,
                doctorName: values.doctorName || null,
                prescriptionIssueDate,
                dispensingDate,
                pharmacistName: values.pharmacistName || null,
                prescriptionDrugs: prescriptionDrugs || [],
                report: values.report || null,
                suggestion: values.suggestion || null,
              },
            },
          },
        });
        setIsUpserting(false);
      } catch (_error) {
        setError(_error?.message || 'エラーが発生しました');
        setIsUpserting(false);
        throw new Error();
      }
    },
    [patientId, guestPatientId, upsertTracingReportDraft],
  );

  return {
    upserting: isUpserting,
    error,
    upsert,
  };
};
