import { object, ObjectSchema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  comment: string()
    .label('フォローアップメモ')
    .trim()
    .required(FormErrorMessage.required)
    .max(50, FormErrorMessage.maxLengthRequired),
});
