import React, { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Alert, Box, Loader, Modal } from '~/components/blocks';
import { AppointmentDeliveryMethod, UberDeliveryStatus } from '~/graphql';
import { chargeModalState } from '~/state/partials/charge_modal/atoms';

import { ChargePanel } from '..';
import { useFetchAppointment } from './use-fetch-appointment';

export const ChargeModal = () => {
  const state = useRecoilValue(chargeModalState);
  const appointment = useFetchAppointment(state.appointmentId);
  const uberDeliveryStatus = appointment?.uberDelivery?.status;

  const resetState = useResetRecoilState(chargeModalState);

  useEffect(() => {
    if (!state.isOpen) {
      resetState();
    }
  }, [resetState, state.isOpen]);

  // 当日配達の時は、集荷が完了していなければ会計できないように別のモーダルを表示する
  if (
    appointment?.deliveryMethod == AppointmentDeliveryMethod.SameDayDelivery &&
    uberDeliveryStatus !== UberDeliveryStatus.Dropoff &&
    uberDeliveryStatus !== UberDeliveryStatus.Delivered &&
    uberDeliveryStatus !== UberDeliveryStatus.Canceled
  ) {
    return (
      <Modal open={state.isOpen} size="s" onClose={resetState}>
        <Modal.Header>会計</Modal.Header>
        <Modal.Body>
          <Box position="relative">
            <Alert>当日配達利用料が確定していないため、会計できません</Alert>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal open={state.isOpen} size="s" onClose={resetState}>
      <Modal.Header>会計</Modal.Header>
      {appointment ? (
        <ChargePanel appointment={appointment} />
      ) : (
        <Modal.Body>
          <Box position="relative" height="240px">
            <Loader open inside />
          </Box>
        </Modal.Body>
      )}
    </Modal>
  );
};
