import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { Flex } from '~/components/blocks';
import { ClipButton } from '~/components/partials';
import {
  FollowupQuestionnaireSheetEventFragment,
  QuestionnaireSheetAnswerFragment,
} from '~/graphql';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { Panel } from '~/state/partials/message_modal/types';
import { Label } from '~/utils/label';

import { FollowupQuestionnaireSheetEvent } from '../..';

type Props = {
  sheet: FollowupQuestionnaireSheetEventFragment;
};

const buildText = (createdAt: string | null, answers: QuestionnaireSheetAnswerFragment[]) => {
  const createdDate = `【回答日時】${createdAt}`;
  const answersText = answers.map(({ title, value }) => {
    return `Q：${title}
${value.join('\n')}`;
  });
  return `${createdDate}
${answersText.join('\n\n')}`;
};

const ButtonBox = styled(Flex)(({ theme }) =>
  css({
    margin: `${theme.space.m} 0`,
    justifyContent: 'space-around',
    [`@media ${theme.mediaQueries.tablet}`]: {
      display: 'block',
      margin: `0 ${theme.space.l}`,
    },
  }),
);

export const QuestionnaireSheet = React.memo((props: Props) => {
  const { sheet } = props;

  const { currentPanel } = useRecoilValue(messageModalState);

  const clipboardText = buildText(
    `${Label.YYYYMMDDja(sheet.createdAt)} ${Label.HHMM(sheet.createdAt)}`,
    sheet.answers,
  );

  return (
    <FollowupQuestionnaireSheetEvent sheet={sheet}>
      {currentPanel === Panel.initial && (
        <ButtonBox>
          <ClipButton
            iconSize="m"
            textSize="xxs"
            wide="fill"
            marginRight="m"
            marginBottom="s"
            text={clipboardText}
          />
        </ButtonBox>
      )}
    </FollowupQuestionnaireSheetEvent>
  );
});

QuestionnaireSheet.displayName = 'QuestionnaireSheet';
