import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useMemo } from 'react';
import { useRecoilCallback } from 'recoil';

import { Alert, Box, Button, Grid, Icon } from '~/components/blocks';
import { ScheduledMessageList } from '~/components/partials';
import {
  GetPatientMessagesQuery,
  PatientFollowupMessageFragment,
  PatientMessagesFragment,
  ScheduledPatientMessageFragment,
  ScheduledPatientMessagesFragment,
  useGetPatientMessagesLazyQuery,
} from '~/graphql';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import { Panel, PanelType } from '~/state/partials/message_modal/types';
import {
  patientDetailDialogMessageState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';

import { MessageList } from '../../MessageList';
import { useExistsAppointment } from '../use-exists-appointment';

type Props = {
  online?: boolean;
  patientId: string;
  isActivePatient: boolean;
};

const ButtonWrapper = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    borderRadius: theme.radii.default,
    padding: theme.space.l,
    margin: theme.space.xs,
  }),
);

const isPatientMessages = (alias: 'all' | 'scheduled', data?: GetPatientMessagesQuery) => {
  if (!data || data?.all?.__typename !== 'Patient') {
    return undefined;
  }

  if (alias === 'scheduled') {
    return data.scheduled as ScheduledPatientMessagesFragment;
  } else {
    return data.all as PatientMessagesFragment;
  }
};

export const MessagePane = React.memo((props: Props) => {
  const { online, patientId, isActivePatient } = props;

  const theme = useTheme();
  const { verifyRole } = usePractitionerRole();
  const isVerified = verifyRole('pharmacist');

  const { exists, loading: loadingCount } = useExistsAppointment(patientId);
  const isCreatable = isVerified && !online && exists && !loadingCount;

  const [getMessages, { data, loading }] = useGetPatientMessagesLazyQuery();

  const messages = useMemo(() => {
    return (
      isPatientMessages('all', data)?.medicationFollowupMessages.nodes.map(
        (node) => node as PatientFollowupMessageFragment,
      ) || []
    );
  }, [data]);
  const scheduledMessages = useMemo(() => {
    return (
      isPatientMessages('scheduled', data)?.medicationFollowupMessages.nodes.map(
        (node) => node as ScheduledPatientMessageFragment,
      ) || []
    );
  }, [data]);

  const handleClick = useRecoilCallback(
    ({ set }) =>
      (panel: PanelType, messageId?: string) => {
        set(patientDetailDialogMessageState, (_state) => ({
          ..._state,
          selectedMessageId: messageId || null,
        }));
        set(patientDetailDialogState, (_state) => ({
          ..._state,
          isOpen: true,
          type: 'message' as TabType,
          patientId: patientId,
        }));
      },
    [patientId],
  );

  useEffect(() => {
    getMessages({
      variables: {
        id: patientId,
      },
    });
  }, [getMessages, patientId]);

  return (
    <Grid
      height="100%"
      gridTemplateRows={
        isCreatable
          ? isActivePatient
            ? 'min-content min-content 1fr'
            : 'min-content min-content min-content 1fr'
          : 'min-content 1fr'
      }
      gridTemplateColumns="1fr"
      padding={theme.space.l}
    >
      {isCreatable && (
        <>
          {!isActivePatient && (
            <Alert marginBottom={theme.space.l}>
              退会済みの患者です。メッセージを送信できません
            </Alert>
          )}
          <ButtonWrapper>
            <Button
              wide="fill"
              use="base"
              onClick={() => handleClick(Panel.initial)}
              disabled={!isActivePatient}
            >
              <Icon icon="comment" color="white" size="l" />
              患者にメッセージ送信
            </Button>
          </ButtonWrapper>
        </>
      )}
      <Box marginTop={isCreatable ? theme.space.l : undefined}>
        {scheduledMessages.length > 0 && (
          <ScheduledMessageList
            scheduledMessages={scheduledMessages}
            onClick={online ? undefined : handleClick}
          />
        )}
      </Box>
      <Box marginTop={theme.space.l} height="100%" overflow="auto">
        <MessageList
          loading={loading}
          messages={messages}
          onClick={online ? undefined : handleClick}
        />
      </Box>
    </Grid>
  );
});

MessagePane.displayName = 'MessagePane';
