import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Chip, Flex, Icon, Name } from '~/components/blocks';
import { PatientProfilePatientFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { BookmarkTag } from '../BookmarkTag';
import { ProfileDetail } from './ProfileDetail';

type Props = {
  patient: PatientProfilePatientFragment;
  children: React.ReactNode;
};

const InactiveChip = styled(Chip)(({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: theme.radii.default,
    border: theme.borders.secondary,
    color: theme.colors.text.secondary,
    fontSize: theme.fontSizes.s,
    padding: `${theme.space.s} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.xs,
    },
  }),
);

const ShadedWrapper = styled('div')(() =>
  css({
    display: 'inline-block',
    position: 'relative',
  }),
);

const Shaded = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.secondary,
    top: `calc(${theme.space.l} - ${theme.space.m})`,
    width: theme.space.l,
    height: theme.space.xs,
    position: 'absolute',
    transform: `rotate(-45deg)`,
  }),
);

export const Profile: React.FC<Props> = (props) => {
  const { patient } = props;
  const isBookmarked = !!patient.bookmark;

  return (
    <>
      <Flex justifyContent="space-between">
        <Name
          familyName={patient.familyName || ''}
          givenName={patient.givenName || ''}
          phoneticFamilyName={patient.phoneticFamilyName || ''}
          phoneticGivenName={patient.phoneticGivenName || ''}
          size="l"
        />
      </Flex>
      <Box>
        <ProfileDetail>
          <ProfileDetail.Item>{Label.age(patient.birthDate)}</ProfileDetail.Item>
          <ProfileDetail.Item>（{Label.sex(patient.sex)}）</ProfileDetail.Item>
          <ProfileDetail.Item>
            {!patient.active && (
              <InactiveChip color="lightPink">
                <ShadedWrapper>
                  <Icon size="m" icon="smartphone" color="pink" />
                  <Shaded />
                </ShadedWrapper>
                退会済み
              </InactiveChip>
            )}
          </ProfileDetail.Item>
        </ProfileDetail>
        <ProfileDetail>
          {patient.active && (
            <ProfileDetail.Item>
              <BookmarkTag isBookmarked={isBookmarked} />
            </ProfileDetail.Item>
          )}
          <ProfileDetail.Item>{props.children}</ProfileDetail.Item>
        </ProfileDetail>
      </Box>
    </>
  );
};
