import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { UBER_DELIVERY_DATE } from '~/constants/organization_notification';
import {
  OrganizationNotificationFragment,
  OrganizationNotificationType,
  useGetUnreadUberDeliveryNotificationsLazyQuery,
  useMarkOrganizationNotificationMutation,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { uberDeliveryNotificationsState } from '~/state/layouts/SharedAppShell/atoms';

export const useSubscriptionUberDeliveryNotifications = () => {
  const [{ notifications, loaded }, setState] = useRecoilState(uberDeliveryNotificationsState);
  const [event, clear] = useSubscriptions('OrganizationNotification', ['created', 'updated']);
  const [markNotification] = useMarkOrganizationNotificationMutation();
  const [getNotifications] = useGetUnreadUberDeliveryNotificationsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const organizationNotifications = data
        ? getMe(data)?.organization.unreadOrganizationNotifications.nodes || []
        : [];
      const notifications = organizationNotifications.filter(
        (n) => !!n.appointment?.uberDelivery,
      ) as OrganizationNotificationFragment[];

      const needPlaySoundNotificationCount = notifications.filter(
        (n) =>
          n.type === OrganizationNotificationType.TimeElapsedUberDelivery ||
          n.type === OrganizationNotificationType.UnfulfilledUberDelivery,
      ).length;

      setState((_state) => ({
        ..._state,
        notifications,
        needPlaySoundNotificationCount,
        loaded: true,
      }));
    },
  });

  const handleClose = useCallback(
    async (notificationId: string) => {
      const newNotifications = notifications.filter(
        (notification) => notification.id !== notificationId,
      );
      setState((_state) => ({ ..._state, notifications: newNotifications }));

      const notification = notifications.find((n) => n.id === notificationId);

      if (notification) {
        await markNotification({
          variables: {
            input: {
              organizationNotificationId: notification.id,
            },
          },
        });
      }
    },
    [markNotification, notifications, setState],
  );

  useEffect(() => {
    if (event) {
      getNotifications({ variables: { from: UBER_DELIVERY_DATE } });
      clear();
    }
  }, [clear, event, getNotifications]);

  useEffect(() => {
    // ログイン時に通知を取得する
    if (!loaded) {
      getNotifications({ variables: { from: UBER_DELIVERY_DATE } });
    }
  }, [getNotifications, loaded]);

  return {
    handleClose,
  };
};
