import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { CloseBtn, Flex, Icon, Text } from '~/components/blocks';
import { appPreviewState } from '~/state/partials/app_preview/atoms';

const Root = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.space.l,
    backgroundColor: theme.colors.background.default,
    borderRadius: `20px 20px 0 0`,
    position: 'sticky',
    top: 0,
    width: '100%',
  }),
);

const Back = styled(Flex)(() =>
  css({
    alignItems: 'flex-end',
  }),
);

const PreviewText = styled(Text)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.navy,
    width: '200px',
    textAlign: 'center',
  }),
);

type Props = {
  name: string | null;
};

export const Header = React.memo((props: Props) => {
  const setAppPreview = useSetRecoilState(appPreviewState);
  const handleClose = useCallback(() => setAppPreview({ isOpen: false }), [setAppPreview]);

  return (
    <Root>
      <Back>
        <Icon size="l" icon="back-line" />
        戻る
      </Back>
      <PreviewText>{props.name}</PreviewText>
      <CloseBtn onClick={handleClose} />
    </Root>
  );
});

Header.displayName = 'Header';
