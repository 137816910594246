import { useEffect, useState } from 'react';

import {
  PrescriptionAppointmentFromNodeFragment,
  useGetPrescriptionFromNodeLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';

type Appointment = {
  loading: boolean;
  error: Error | null;
  appointment?: PrescriptionAppointmentFromNodeFragment;
};

export const useFetchAppointment = (isOpen: boolean, appointmentId: string): Appointment => {
  const [error, setError] = useState<Error | null>(null);

  const [getAppointment, { called, loading, data }] = useGetPrescriptionFromNodeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (_error) => setError(_error),
  });

  const appointment = getNode(data, 'Appointment');

  useEffect(() => {
    if (isOpen && appointmentId) {
      getAppointment({ variables: { appointmentId } });
    }
  }, [appointmentId, isOpen, getAppointment]);

  return { loading: !called || loading, error, appointment };
};
