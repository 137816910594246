const KEY = 'jwt';

export const setJWT = (jwt: string) => {
  localStorage.setItem(KEY, jwt);
};

export const getJWT = () => {
  return localStorage.getItem(KEY);
};

export const clearJWT = () => {
  localStorage.removeItem(KEY);
};
