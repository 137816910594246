import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Modal } from '~/components/blocks';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import { messageModalState } from '~/state/partials/message_modal/atoms';

import { InitialPanel } from './InitialPanel';
import { InputPanel } from './InputPanel';
import { ReadOnlyPanel } from './ReadOnlyPanel';

export const MessageModal = React.memo(() => {
  const { verifyRole } = usePractitionerRole();
  const isVerified = verifyRole('pharmacist');

  const { isOpen, currentPanel } = useRecoilValue(messageModalState);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(messageModalState),
    [],
  );
  return (
    <Modal open={isOpen} size="m" onClose={handleClose}>
      <Modal.Body>
        <Box height="625px">
          {isVerified && currentPanel === 'input' && <InputPanel />}
          {isVerified && currentPanel === 'initial' && <InitialPanel />}
          {!isVerified && <ReadOnlyPanel />}
        </Box>
      </Modal.Body>
    </Modal>
  );
});

MessageModal.displayName = 'MessageModal';
