import { useGetCsClinicCategoriesQuery } from '~/graphql';

export const useFetchCsCategories = () => {
  const { called, loading, data } = useGetCsClinicCategoriesQuery();

  return {
    loading: !called || loading,
    csCategories: data?.csClinicCategories || [],
  };
};
