import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { addDays, isSameDay, subYears } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import { Box, Select } from '~/components/blocks';
import { Period } from '~/components/partials/MedicineNotesContainer/types';

type Props = {
  period: Period;
  onChange: (to: Date, from?: Date) => void;
};

const TODAY = new Date();
const createPeriod = (value: number, ignoreFrom = false) => {
  const period: Period = {
    from: ignoreFrom ? undefined : subYears(addDays(TODAY, 1), value + 1),
    to: subYears(TODAY, value),
  };
  return period;
};

const PeriodOptions = [
  { value: 0, label: '直近１年', period: createPeriod(0) },
  { value: 1, label: '１〜２年前', period: createPeriod(1) },
  { value: 2, label: '２〜３年前', period: createPeriod(2) },
  { value: 3, label: '３年前〜', period: createPeriod(3, true) },
];

const Root = styled(Box)(() => css({ width: '200px', textAlign: 'left' }));

export const SelectPeriod = React.memo((props: Props) => {
  const { onChange } = props;

  const selectedValue = useMemo(() => {
    const value = PeriodOptions.find(({ period }) => {
      const isSameTo = isSameDay(period.to, props.period.to);

      if (!period.from) {
        return isSameTo;
      }

      return props.period.from && isSameDay(period.from, props.period.from) && isSameTo;
    })?.value;

    return value ?? 0;
  }, [props]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = Number.parseInt(event.currentTarget.value);
      const option = PeriodOptions.find(({ value }) => value == newValue);
      const period = option ? option.period : PeriodOptions[0].period;

      onChange(period.to, period.from);
    },
    [onChange],
  );

  return (
    <Root>
      <Select fill value={selectedValue} onChange={handleChange}>
        {PeriodOptions.map(({ label, value }, idx) => {
          return (
            <option key={idx} value={value}>
              {label}
            </option>
          );
        })}
      </Select>
    </Root>
  );
});

SelectPeriod.displayName = 'SelectPeriod';
