import { useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useUpsertFollowupDraftMessageMutation } from '~/graphql';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { formatISOString, toYYYYMMDDhyphen } from '~/utils/date';

import { Fields } from '../../MessageForm/InputPane/types';

export const useUpsertDraftMessage = () => {
  const [isUpserting, setIsUpserting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [{ profile, patientId }] = useRecoilState(messageModalState);
  const setMessageModalState = useSetRecoilState(messageModalState);
  const [upsertDraftMessage] = useUpsertFollowupDraftMessageMutation({
    onCompleted: (result) => {
      setMessageModalState((state) => ({
        ...state,
        draftMessageId:
          result.upsertMedicationFollowupDraftMessage?.medicationFollowupDraftMessage.id || null,
      }));
    },
  });

  const upsert = useCallback(
    async (values: Fields, draftMessageId: string | null) => {
      setIsUpserting(true);
      setError(null);

      const sendDate =
        values.fixed && values.sendDate
          ? formatISOString(`${toYYYYMMDDhyphen(values.sendDate)}`)
          : null;
      const sendTime = values.fixed && values.sendTime ? values.sendTime : null;
      const medicationFollowupQuestionnaireId =
        values.attachment === 'questionnaire_sheet'
          ? values.medicationFollowupQuestionnaireId || null
          : null;

      try {
        await upsertDraftMessage({
          variables: {
            input: {
              medicationFollowupDraftMessageId: draftMessageId,
              patientId: profile?.type === 'Patient' ? patientId : null,
              guestPatientId:
                profile?.type === 'DirectVisitor' || profile?.type === 'WebVisitor'
                  ? patientId
                  : null,
              data: {
                sendDate,
                sendTime,
                message: values.message,
                medicationFollowupQuestionnaireId,
                csClinicId: values.attachment === 'clinic' ? values.csClinicId : null,
                fixedDate: values.fixed,
                checkboxRecommendation: values.attachment === 'clinic',
                checkboxAutoReplySetting: values.isEnabledAutoReplySetting,
                checkboxAutoReplySettingQuestionnaire: values.withAutoReplyQuestionnaire,
                autoReplySettingMessage: values.autoReplyMessage || null,
              },
            },
          },
        });
        setIsUpserting(false);
      } catch (_error) {
        setError(_error?.message || 'エラーが発生しました');
        setIsUpserting(false);
        throw new Error();
      }
    },
    [patientId, profile?.type, upsertDraftMessage],
  );

  return {
    upserting: isUpserting,
    error,
    upsert,
  };
};
