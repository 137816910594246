import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetAppointmentForChangeDeliveryMethodLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { changeDeliveryMethodState } from '~/state/partials/change_delivery_method_modal/atoms';

export const useFetchAppointment = () => {
  const { appointmentId, isOpen } = useRecoilValue(changeDeliveryMethodState);
  const [getAppointment, { data, loading, called }] =
    useGetAppointmentForChangeDeliveryMethodLazyQuery({
      fetchPolicy: 'network-only',
    });
  const appointment = getNode(data, 'Appointment');

  useEffect(() => {
    if (appointmentId) {
      getAppointment({ variables: { id: appointmentId } });
    }
  }, [getAppointment, appointmentId, isOpen]);

  return {
    loading: loading || !called,
    appointment,
  };
};
