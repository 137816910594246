import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { ComponentProps } from 'react';
import { compose, layout, LayoutProps, space, SpaceProps } from 'styled-system';

import { FieldError } from '~/components/blocks';

const InputTextarea = styled('textarea', {
  shouldForwardProp,
})<{ error?: string } & LayoutProps & SpaceProps>(
  ({ theme }) =>
    () =>
      css({
        width: '100%',
        padding: theme.space.l,
        backgroundColor: theme.colors.background.bg,
        color: theme.colors.text.default,
        borderRadius: theme.radii.default,
        border: theme.borders.transparent,
        fontSize: theme.fontSizes.m,
        transition: theme.transitions.default,
        resize: 'vertical',
        minHeight: `calc(${theme.fontSizes.m} * 10)`,
        height: 'inherit',
        outline: 0,
        '::placeholder': {
          color: theme.colors.text.placeholder,
        },
        ':hover': {
          border: theme.borders.primary,
        },
        ':focus': {
          backgroundColor: theme.colors.background.default,
          border: theme.borders.primary,
        },
        ':disabled': {
          border: theme.borders.transparent,
          opacity: 0.6,
          cursor: 'not-allowed',
        },
      }),
  ({ theme, error }) =>
    error &&
    css({
      backgroundColor: theme.colors.background.alert,
      color: theme.colors.text.error,
      '&::placeholder': {
        color: theme.colors.text.placeholder,
      },
      '&:hover': {
        boxShadow: 0,
        border: theme.borders.error,
      },
      '&:focus': {
        boxShadow: 0,
        border: theme.borders.error,
        backgroundColor: theme.colors.background.default,
      },
      ':disabled': {
        border: theme.borders.transparent,
        opacity: 0.6,
        cursor: 'not-allowed',
      },
    }),
  () => compose(space, layout),
);

const TextAreaWarp = styled('div', {
  shouldForwardProp,
})();

export const Textarea = React.forwardRef(
  (props: ComponentProps<typeof InputTextarea>, ref: React.Ref<HTMLTextAreaElement>) => {
    return (
      <TextAreaWarp>
        <InputTextarea {...props} ref={ref} />
        {typeof props.error === 'string' && <FieldError error={props.error} />}
      </TextAreaWarp>
    );
  },
);

Textarea.displayName = 'Textarea';
