import { useEffect, useMemo } from 'react';

import { useGetSystemAmountLazyQuery } from '~/graphql';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';

export type SystemAmount = {
  medixs: number | null;
};

export const useSystemAmount = (appointmentId: string | null) => {
  const { enabledMedixs } = useMedixsSetting();
  const [getSystemAmount, { data }] = useGetSystemAmountLazyQuery();
  const appointment = data?.node?.__typename === 'Appointment' ? data.node : null;
  const systemAmount = useMemo<SystemAmount>(
    () => ({
      // medixs_settingが有効な場合、medixs_dispensing_recordから請求金額を取得し補完する
      medixs: enabledMedixs
        ? // 「合計請求額 or 一部負担金」を請求額とする
          appointment?.medixsReception?.medixsDispensingRecord?.totalAmount ||
          appointment?.medixsReception?.medixsDispensingRecord?.contributionsAmount ||
          null
        : null,
    }),
    [
      enabledMedixs,
      appointment?.medixsReception?.medixsDispensingRecord?.contributionsAmount,
      appointment?.medixsReception?.medixsDispensingRecord?.totalAmount,
    ],
  );

  useEffect(() => {
    if (appointmentId && enabledMedixs) {
      getSystemAmount({
        variables: { appointmentId },
      });
    }
  }, [appointmentId, enabledMedixs, getSystemAmount]);

  return systemAmount;
};
