import { useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useDeleteFollowupDraftMessageMutation } from '~/graphql';
import {
  patientDetailDialogMessageState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';

export const useDeleteDraftMessage = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [{ patientId }] = useRecoilState(patientDetailDialogState);
  const [{ profile }] = useRecoilState(patientDetailDialogMessageState);
  const setPatientDetailDialogMessageState = useSetRecoilState(patientDetailDialogMessageState);
  const [deleteDraftMessage] = useDeleteFollowupDraftMessageMutation({
    onCompleted: () => {
      setPatientDetailDialogMessageState((state) => ({
        ...state,
        draftMessageId: null,
      }));
    },
  });

  const deleteDraft = useCallback(async () => {
    setIsDeleting(true);
    setError(null);

    try {
      await deleteDraftMessage({
        variables: {
          input: {
            patientId: profile?.type === 'Patient' ? patientId : null,
            guestPatientId:
              profile?.type === 'DirectVisitor' || profile?.type === 'WebVisitor'
                ? patientId
                : null,
          },
        },
      });
      setIsDeleting(false);
    } catch (_error) {
      setError(_error?.message || 'エラーが発生しました');
      setIsDeleting(false);
      throw new Error();
    }
  }, [patientId, profile?.type, deleteDraftMessage]);

  return {
    deleting: isDeleting,
    error,
    deleteDraft,
  };
};
