import React from 'react';

import { Button } from '~/components/blocks';

import { useFinishAppointment } from './use-finish-appointment';

type Props = {
  appointmentId: string;
};

export const ChargedButtons = (props: Props) => {
  const { finishing, handleFinish } = useFinishAppointment(props.appointmentId);

  return (
    <Button use="base" wide="fill" size="l" loading={finishing} onClick={handleFinish}>
      完了
    </Button>
  );
};
