import { useTheme } from '@emotion/react';
import { isBefore } from 'date-fns';
import Router, { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import { Chip, Dropdown, Flex } from '~/components/blocks';
import { NotificationBadge } from '~/components/layouts/SharedAppShell/NotificationBadge';
import { AppHeader } from '~/components/partials';
import {
  GetAccessPermissionQuery,
  GetPractitionerNameQuery,
  PractitionerNameFragment,
  useGetAccessPermissionQuery,
  useGetPractitionerNameQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useFirebase } from '~/hooks/use-firebase';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import { useChannel } from '~/hooks/use-subscriptions';
import { clearJWT } from '~/localstrage/jwt';
import { permissionModalState } from '~/state/layouts/OrganizationAppShell/atoms';
import { logoutState, twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';
import { openWithNoOpener } from '~/utils/window';

import { Plan } from './Plan';
import { useManageNotifications } from './use-manage-notifications';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPractitionerNameFragment = (node: any): node is PractitionerNameFragment => {
  return node?.__typename === 'Practitioner';
};

const getHasAllowedAccess = (data?: GetAccessPermissionQuery) => {
  if (!data) return false;

  const expiredAt = getMe(data)?.operatorAccessPermission?.expiresAt;

  if (!expiredAt) return false;

  const today = new Date();
  const expiredDate = new Date(expiredAt);

  return isBefore(today, expiredDate);
};

const getIsProxy = (data?: GetPractitionerNameQuery) => {
  if (!data) return false;
  if (!isPractitionerNameFragment(data.me)) return false;

  return data.me.isProxy;
};

export const Header = () => {
  const theme = useTheme();
  const router = useRouter();
  const resetTwoFactor = router.asPath.replace(router.pathname, '') === '#reset_two_factor';

  const { data } = useGetPractitionerNameQuery();
  const { auth } = useFirebase();
  const { data: permissionData } = useGetAccessPermissionQuery();
  const hasAllowedAccess = getHasAllowedAccess(permissionData);
  const { verifyRole } = usePractitionerRole();

  const setPermissionModalStateState = useSetRecoilState(permissionModalState);
  const setLogoutState = useSetRecoilState(logoutState);
  const setTwoFactorState = useSetRecoilState(twoFactorAuthModalState);
  const { disconnect } = useChannel();
  const practitionerName = useMemo(() => {
    const me = data?.me;
    if (!isPractitionerNameFragment(me)) return '';

    return `${me.familyName} ${me.givenName}`;
  }, [data?.me]);
  const isProxyLogin = getIsProxy(data);

  const handleClickTerm = useCallback(() => router.push('/term'), [router]);
  const handleClickPolicy = useCallback(
    () => openWithNoOpener('https://www.medley.jp/external-service.html'),
    [],
  );
  const handleOpenAccessPermissionModal = useCallback(
    () => setPermissionModalStateState(() => ({ isOpen: true })),
    [setPermissionModalStateState],
  );
  const handleClickCompany = useCallback(() => Router.push('/company'), []);
  const handleClickTwoFactor = useRecoilCallback(
    ({ set }) =>
      () =>
        set(twoFactorAuthModalState, (_state) => ({
          ..._state,
          isOpen: true,
          currentPanel: 'introduction',
        })),
    [],
  );
  const handleLogout = useCallback(async () => {
    setLogoutState({ isLogout: true });
    await disconnect();
    auth.signOut();
    clearJWT();
    window.location.href = '/login';
  }, [auth, disconnect, setLogoutState]);

  useManageNotifications();

  useEffect(() => {
    if (resetTwoFactor) {
      setTwoFactorState((_state) => ({
        ..._state,
        isOpen: true,
        isReset: true,
        currentPanel: 'introduction',
      }));
      router.push('/');
    }
  }, [resetTwoFactor, router, setTwoFactorState]);

  return (
    <AppHeader
      action={
        <Flex alignItems="center">
          <NotificationBadge />
          <Dropdown text={practitionerName} placement="bottom-end">
            <Dropdown.Menu>
              <Plan />
              <Dropdown.Item onClick={handleClickTerm}>利用規約</Dropdown.Item>
              <Dropdown.Item onClick={handleClickPolicy}>外部送信ポリシー</Dropdown.Item>
              {!isProxyLogin && (
                <Dropdown.Item onClick={handleOpenAccessPermissionModal}>
                  アクセス許可
                  {hasAllowedAccess && (
                    <Chip size="s" color="green" ml={theme.space.l}>
                      許可中
                    </Chip>
                  )}
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={handleClickTwoFactor}>二要素認証</Dropdown.Item>
              {verifyRole('company_admin') && (
                <Dropdown.Item onClick={handleClickCompany}>本部設定</Dropdown.Item>
              )}
              <Dropdown.Item onClick={handleLogout}>ログアウト</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Flex>
      }
    />
  );
};
