import { useEffect } from 'react';

import { useGetDispensingRequestUploadTypeWithDraftAppointmentLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchDispensingRequestUploadTypeWithDraftAppointment = (appointmentId: string) => {
  const [getDispensingRequestUploadTypeWithDraftAppointment, { data }] =
    useGetDispensingRequestUploadTypeWithDraftAppointmentLazyQuery();

  const appointment = getNode(data, 'Appointment');
  const pfDispensingRequestUploadType = appointment?.pfDispensingRequestUploadType;
  const existsDraftAppointment = !!appointment?.draftAppointment?.id;

  useEffect(() => {
    if (appointmentId) {
      getDispensingRequestUploadTypeWithDraftAppointment({ variables: { appointmentId } });
    }
  }, [appointmentId, getDispensingRequestUploadTypeWithDraftAppointment]);

  return { pfDispensingRequestUploadType, existsDraftAppointment };
};
