import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Box, Button, EntryList, Modal } from '~/components/blocks';
import { GetPatientAttachmentsDocument, useDeletePatientAttachmentMutation } from '~/graphql';
import { deleteFileModalState } from '~/state/partials/delete_file_modal/atoms';
import { filePageInfoState } from '~/state/partials/patient_karte/atoms';

import { toPatientFrom } from '../PatientKarte/FilePane/FileList';

export const DeleteFileModal = () => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(deleteFileModalState);
  const { patientId, filter, page, perPage } = useRecoilValue(filePageInfoState);

  const [deleteAttachment, { loading }] = useDeletePatientAttachmentMutation({
    onCompleted: () => setState({ isOpen: false, fileId: null, fileName: null }),
    refetchQueries: [
      {
        query: GetPatientAttachmentsDocument,
        variables: {
          patientId,
          patientFrom: toPatientFrom(filter.sent, filter.received),
          page,
          perPage,
        },
      },
    ],
  });

  const handleClose = useCallback(
    () => setState({ isOpen: false, fileId: null, fileName: null }),
    [setState],
  );
  const handleClick = useCallback(() => {
    if (state.fileId) {
      deleteAttachment({
        variables: {
          input: {
            patientAttachmentId: state.fileId,
          },
        },
      });
    }
  }, [deleteAttachment, state.fileId]);

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>ファイル削除</Modal.Header>
      <Modal.Body>
        ファイルを削除してもよろしいですか。
        <Box marginTop={theme.space.m}>
          <EntryList>
            <EntryList.Head>ファイル名</EntryList.Head>
            <EntryList.Body>{state.fileName}</EntryList.Body>
          </EntryList>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" loading={loading} onClick={handleClick}>
          削除
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
