import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';

import { Box, CloseBtn, Header, Portal } from '~/components/blocks';

type Props = {
  ankerRef: HTMLElement | null;
  title: string;
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const Root = styled('div')(({ theme }) =>
  css({
    width: '330px',
    padding: theme.space.l,
    background: theme.colors.background.default,
    border: theme.borders.default,
    boxShadow: theme.shadows.default,
    borderRadius: theme.radii.default,
    zIndex: theme.zIndices.popup,
  }),
);
export const SettingPopup: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(props.ankerRef, popperElement, {
    placement: 'bottom-end',
  });

  return props.open ? (
    <Portal>
      <Root ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Header action={<CloseBtn onClick={props.onClose} />}>{props.title}</Header>
        <Box mt={theme.space.l}>{props.children}</Box>
      </Root>
    </Portal>
  ) : null;
};
