import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetPatientProfilePatientNameLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { notificationConfirmModalState } from '~/state/partials/patient_profile_drawer/atoms';

export const useFetchPatientName = () => {
  const { patientId } = useRecoilValue(notificationConfirmModalState);
  const [getPatientName, { data, loading }] = useGetPatientProfilePatientNameLazyQuery();

  const patient = getNode(data, 'Patient');
  const patientName = patient ? `${patient.familyName}${patient.givenName}` : '';

  useEffect(() => {
    if (patientId) {
      getPatientName({ variables: { patientId } });
    }
  }, [getPatientName, patientId]);

  return { loading, patientName };
};
