import { useEffect } from 'react';

import { ChargeAppointmentFragment, useGetChargeAppointmentLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchAppointment = (
  appointmentId: string | null,
): ChargeAppointmentFragment | undefined => {
  const [getAppointment, { data }] = useGetChargeAppointmentLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (appointmentId) {
      getAppointment({
        variables: {
          id: appointmentId,
        },
      });
    }
  }, [appointmentId, getAppointment]);

  return getNode(data, 'Appointment');
};
