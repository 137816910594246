import { differenceInMonths, format } from 'date-fns';

import {
  Address,
  MedicationFollowupMessageAttachment,
  PaymentMethod,
  PayoutBankAccount,
  PayoutBankAccountAccount,
  PfAddress,
  Sex,
} from '~/graphql';

import { toHHMM, toHHMMSS, toMMDDja, toWareki, toYYYYMMDDja, toYYYYMMja } from './date';

export const Label = {
  address(address: Partial<Address | PfAddress>, withPostalCode = false) {
    const location = `${address.prefecture}${address.city}${address.line}`;
    if (withPostalCode) {
      return `${address.postalCode}&nbsp${location}`;
    } else {
      return location;
    }
  },
  sex(sex: Sex) {
    switch (sex) {
      case 'female':
        return '女性';
      case 'male':
        return '男性';
      case 'other':
        return 'その他';
      case 'unknown':
        return '不明';
    }
  },
  age(birthDate: string | Date) {
    const monthCount = differenceInMonths(new Date(), new Date(birthDate));
    const years = Math.floor(monthCount / 12);
    const months = monthCount % 12;

    return `${years}歳${months}ヶ月`;
  },
  followupAttachemnt(attachment: MedicationFollowupMessageAttachment) {
    switch (attachment) {
      case 'clinic':
        return '病院・診療所の紹介';
      case 'questionnaire_sheet':
        return '質問票';
    }
  },
  warekiBirthDate(date: string | Date, withBirth?: boolean) {
    const birthDate = new Date(date);
    const year = birthDate.getFullYear();

    if (withBirth || withBirth === undefined) {
      return `${year}年（${toWareki(year)}）${format(birthDate, 'MM月dd日')}生まれ`;
    } else {
      return `${year}年（${toWareki(year)}）${format(birthDate, 'MM月dd日')}`;
    }
  },
  amount(amount: number) {
    return `${amount.toLocaleString()}円`;
  },
  YYYYMMDDja(date: string | Date) {
    return toYYYYMMDDja(date);
  },
  YYYYMMja(date: string | Date) {
    return toYYYYMMja(date);
  },
  MMDDja(date: string | Date) {
    return toMMDDja(date);
  },
  HHMM(date: string | Date, anchorDate?: string | Date) {
    return toHHMM(date, anchorDate);
  },
  HHMMSS(date: string | Date) {
    return toHHMMSS(date);
  },
  paymentMethod(paymentMethod: PaymentMethod) {
    switch (paymentMethod) {
      case 'cash':
        return '窓口決済';
      case 'app':
        return 'アプリ決済';
    }
  },
  bankAccountType(accountType: PayoutBankAccountAccount) {
    switch (accountType) {
      case 'futsu':
        return '普通';
      case 'toza':
        return '当座';
    }
  },
  bankAcount(bankAccount: PayoutBankAccount) {
    const accountType = Label.bankAccountType(bankAccount.accountType);
    return `${bankAccount.bankName} ${bankAccount.branchName} ${accountType} ${bankAccount.accountNumber}`;
  },
  RequestTime(start: string | Date, end: string | Date) {
    return `${Label.YYYYMMDDja(start)} ${Label.HHMM(start)} - ${Label.HHMM(end)}`;
  },
  fileName(name: string) {
    return name.replace(/\.[^/.]+$/, '');
  },
};
