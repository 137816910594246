import { MAX_COUNT } from '~/constants/medication_followup_questionnaire';
import { useGetMedicationFollowupQuestionnairesSelectQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useMedicationFollowupQuestionnaires = () => {
  const { data, loading } = useGetMedicationFollowupQuestionnairesSelectQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      perPage: MAX_COUNT,
    },
  });
  const questionnaires = data
    ? getMe(data)?.organization.medicationFollowupQuestionnaires.nodes
    : [];
  return { questionnaires, loading };
};
