import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Box, Flex, Pagination } from '~/components/blocks';
import { medicineNoteNotificationPanelState } from '~/state/partials/medicine_note_notification_panel/atoms';

export const MedicineNoteNotificationFooter = () => {
  const [state, setState] = useRecoilState(medicineNoteNotificationPanelState);

  const handleChange = useCallback(
    (page: number) => setState((_state) => ({ ..._state, page })),
    [setState],
  );

  if (state.totalCount === 0) {
    return null;
  }

  return (
    <Box textAlign="center">
      <Flex justifyContent="center">
        <Pagination currentPage={state.page} totalPage={state.totalPage} onChange={handleChange} />
      </Flex>
    </Box>
  );
};
