import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useRef } from 'react';

import { Alert, Box, Button, Grid, ScrollBox, Text } from '~/components/blocks';
import { History } from '~/components/partials/TracingReportPanel/History';
import { HistoryPagination } from '~/components/partials/TracingReportPanel/HistoryPagination';
import { PlanFeatureCode, TracingReportFragment } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';

type Props = {
  onCreate: () => void;
  loading: boolean;
  tracingReports: TracingReportFragment[];
  creatable?: boolean;
};

const OuterAlert = styled('div')(({ theme }) =>
  css({
    padding: `${theme.space.m} ${theme.space.l} ${theme.space.l} ${theme.space.l}`,
    borderRadius: theme.radii.default,
    backgroundColor: theme.colors.background.info,
    border: theme.borders.info,
    marginBottom: theme.space.l,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.s,
    },
  }),
);

const InnerAlert = styled(Alert)(({ theme }) =>
  css({
    border: 'none',
    padding: `${theme.space.m} ${theme.space.s}`,
  }),
);

export const TracingReportPanel = (props: Props) => {
  const theme = useTheme();
  const { loading, tracingReports, creatable } = props;
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const { verifyRole } = usePractitionerRole();
  const isVerified = verifyRole('pharmacist');
  const isCreatable = isVerified && creatable;
  const { hasEnabledFeature: hasEnabledTracingReport } = usePlanFeature(
    PlanFeatureCode.TracingReport,
  );

  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      {hasEnabledTracingReport ? (
        <>
          <Box>
            {isCreatable && (
              <OuterAlert>
                <InnerAlert status="info">
                  <Text>
                    店舗内のみでファイルが共有されます
                    <br />
                    患者には送信されません
                  </Text>
                </InnerAlert>
                <Button wide="fill" onClick={props.onCreate}>
                  トレーシングレポート作成
                </Button>
              </OuterAlert>
            )}
          </Box>
          {loading ? (
            <ScrollBox loading={true} />
          ) : tracingReports.length > 0 ? (
            <>
              <ScrollBox ref={scrollRef}>
                <Box>
                  {tracingReports.map((t, idx) => (
                    <History
                      key={idx}
                      createdAt={t.createdAt}
                      tracingReportId={t.id}
                      clinicName={t.data.clinicName}
                    />
                  ))}
                </Box>
              </ScrollBox>
              <HistoryPagination />
            </>
          ) : (
            <Box>
              <Alert status="info" mt={theme.space.l}>
                <Text size="s">トレーシングレポートはありません</Text>
              </Alert>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Alert status="info">この機能は無効化されています</Alert>
        </Box>
      )}
    </Grid>
  );
};

TracingReportPanel.defaultProps = {
  creatable: true,
};
