import 'react-datepicker/dist/react-datepicker.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '~/components/blocks/InputDate/styles.scss';
import '~/components/blocks/InputMonth/styles.scss';

import * as Sentry from '@sentry/nextjs';
import type { NextPage } from 'next';
import NextApp from 'next/app';
import NextHead from 'next/head';
import React from 'react';
import { RecoilRoot } from 'recoil';

import { AppThemeProvider, PageLoadProgress } from '~/components/blocks';
import { GlobalStyle } from '~/components/blocks/GlobalStyle';
import { ClientCertVerification } from '~/components/partials/ClientCertVertification';
import { IntercomProvider } from '~/components/partials/IntercomProvider';
import { loginPageNoindexRegexp, loginPageRegexp } from '~/constants/regexp';
import { withApollo } from '~/graphql/with-apollo';
import { publicPath } from '~/utils/path';

if (process.env.sentryDsn) {
  Sentry.init({
    dsn: process.env.sentryDsn,
    enabled: process.env.NODE_ENV !== 'test',
    release: process.env.sentryRelease,
    ignoreErrors: [/^ApolloError: unauthenticated$/, 'ResizeObserver loop limit exceeded'],
  });
}

class App extends NextApp {
  // 他タブでlocalstorageのjwtトークンが変更されたら強制的にログイン画面に遷移する
  storageEventListener = (e: StorageEvent) => {
    if (e.key === 'jwt' && e.oldValue !== e.newValue) {
      location.href = `/login`;
    }
  };

  componentDidMount() {
    window.addEventListener('storage', this.storageEventListener);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.storageEventListener);
  }

  render(): JSX.Element {
    const { Component, pageProps, router } = this.props;
    const hasNextParameter = loginPageNoindexRegexp.test(router.asPath);
    const isLoginPage = loginPageRegexp.test(router.asPath);
    const title = isLoginPage
      ? 'ログイン｜かかりつけ薬局支援システム Pharms（ファームス）'
      : 'かかりつけ薬局支援システム Pharms（ファームス）';

    return (
      <Sentry.ErrorBoundary>
        <NextHead>
          {hasNextParameter && <meta name="robots" content="noindex,nofollow" />}
          <title>{title}</title>
          <link id="favicon" rel="icon" href={`${publicPath('/favicon.ico')}`}></link>
          <link
            rel="apple-touch-icon"
            href={`${publicPath('/apple-touch-icon-precomposed.png')}`}
          ></link>
          <script src={`${publicPath('/access_blocked.js')}`} async></script>
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0,minimum-scale=1.0"
          ></meta>
          {isLoginPage && (
            <meta name="description" content="Pharmsをご利用中の法人様はこちらからログイン"></meta>
          )}
        </NextHead>

        <RecoilRoot>
          <IntercomProvider>
            <AppThemeProvider>
              <GlobalStyle />
              <PageLoadProgress />
              <ClientCertVerification>
                <Component {...pageProps} />
              </ClientCertVerification>
            </AppThemeProvider>
          </IntercomProvider>
        </RecoilRoot>
      </Sentry.ErrorBoundary>
    );
  }
}

export default withApollo(App as unknown as NextPage);
