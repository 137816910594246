import { useEffect, useMemo } from 'react';

import {
  DirectVisitorDetailDialogProfileFragment,
  GetDirectVisitorMessagesQuery,
  GetPatientMessagesQuery,
  GetWebVisitorMessagesQuery,
  PatientDetailDialogProfileFragment,
  useGetDirectVisitorMessagesLazyQuery,
  useGetPatientMessagesLazyQuery,
  useGetWebVisitorMessagesLazyQuery,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';

type Patient =
  | DirectVisitorDetailDialogProfileFragment
  | WebVisitorDetailDialogProfileFragment
  | PatientDetailDialogProfileFragment
  | undefined;

const patientAllMessages = (data?: GetPatientMessagesQuery) => {
  if (!data || data?.all?.__typename !== 'Patient') {
    return undefined;
  }

  return data.all.medicationFollowupMessages.nodes;
};

const directVisitorAllMessages = (data?: GetDirectVisitorMessagesQuery) => {
  if (!data || data?.all?.__typename !== 'DirectVisitor') {
    return undefined;
  }

  return data.all.medicationFollowupMessages.nodes;
};

const webVisitorAllMessages = (data?: GetWebVisitorMessagesQuery) => {
  if (!data || data?.all?.__typename !== 'WebVisitor') {
    return undefined;
  }

  return data.all.medicationFollowupMessages.nodes;
};

export const useHasMessages = (patient: Patient) => {
  const patientId = patient?.__typename === 'Patient' ? patient.id : undefined;
  const directVisitorId = patient?.__typename === 'DirectVisitor' ? patient?.id : undefined;
  const webVisitorId = patient?.__typename === 'WebVisitor' ? patient.id : undefined;

  const [getPatientMessage, { loading: patientLoading, data: patientData }] =
    useGetPatientMessagesLazyQuery();
  const [getDirectVisitorMessage, { loading: directVisitorLoading, data: directVisitorData }] =
    useGetDirectVisitorMessagesLazyQuery();
  const [getWebVisitorMessage, { loading: webVisitorLoading, data: webVisitorData }] =
    useGetWebVisitorMessagesLazyQuery();

  const hasPatientMessages = useMemo(() => {
    const data = patientAllMessages(patientData);
    return data && data.length > 0;
  }, [patientData]);
  const hasDirectVisitorMessages = useMemo(() => {
    const data = directVisitorAllMessages(directVisitorData);
    return data && data.length > 0;
  }, [directVisitorData]);
  const hasWebVisitorMessages = useMemo(() => {
    const data = webVisitorAllMessages(webVisitorData);
    return data && data.length > 0;
  }, [webVisitorData]);

  const hasMessages = !!(hasPatientMessages || hasDirectVisitorMessages || hasWebVisitorMessages);

  useEffect(() => {
    if (!patientId && directVisitorId && webVisitorId) return;

    if (patientId) {
      getPatientMessage({
        variables: {
          id: patientId,
        },
      });
    }

    if (directVisitorId) {
      getDirectVisitorMessage({
        variables: {
          id: directVisitorId,
        },
      });
    }

    if (webVisitorId) {
      getWebVisitorMessage({
        variables: {
          id: webVisitorId,
        },
      });
    }
  }, [
    directVisitorId,
    getDirectVisitorMessage,
    getPatientMessage,
    getWebVisitorMessage,
    patientId,
    webVisitorId,
  ]);

  return {
    loading: patientLoading || directVisitorLoading || webVisitorLoading,
    hasMessages,
  };
};
