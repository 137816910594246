import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { darken, rgba } from 'polished';
import React, { useCallback } from 'react';

import { List } from '~/components/blocks';

import { HrefProps } from '../types';

const Root = styled(List.Item)<{ active?: boolean }>(({ theme, active }) =>
  css({
    padding: `${theme.space.l} ${theme.space.m}`,
    fontSize: theme.fontSizes.s,
    fontWeight: theme.fontWeights.bold,
    cursor: 'pointer',
    border: `1px solid ${theme.colors.background.default}`,
    '&:hover': {
      backgroundColor: rgba(theme.colors.background.primary, 0.1),
      border: `1px solid ${rgba(theme.colors.border.primary, 0.2)}`,
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
    },
    ...(active && {
      backgroundColor: darken(0.1, theme.colors.background.primary),
      border: `1px solid transparent`,
      color: theme.colors.text.white,
      '&:hover': {
        backgroundColor: darken(0.04, theme.colors.background.primary),
      },
    }),
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.xs,
      padding: `${theme.space.m} ${theme.space.l}`,
    },
  }),
);

type Props = {
  href: [HrefProps, ...HrefProps[]];
  children: React.ReactNode;
};

export const MenuLink: React.FC<Props> = (props) => {
  const router = useRouter();
  const isActive = props.href.some((href) =>
    href.exact ? router.pathname === href.path : router.pathname.indexOf(href.path) === 0,
  );

  const handleClick = useCallback(() => {
    router.push(props.href[0].path);
  }, [router, props.href]);

  return (
    <Root active={isActive} onClick={handleClick}>
      {props.children}
    </Root>
  );
};
