import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { Loader } from '~/components/blocks';
import {
  DirectVisitorDetailDialogProfileFragment,
  PatientDetailDialogProfileFragment,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';

import { ExclusiveLockError } from './ExclusiveLockError';
import { InputPane } from './InputPane';
import { useExclusiveLock } from './use-exclusive_lock';

type Props = {
  patient?:
    | DirectVisitorDetailDialogProfileFragment
    | WebVisitorDetailDialogProfileFragment
    | PatientDetailDialogProfileFragment;
};

export const NewTracingReportPane = (props: Props) => {
  const { isOpen, patientId, type } = useRecoilValue(patientDetailDialogState);
  const { patient } = props;

  const {
    lock,
    unlock,
    status: exclusiveLockStatus,
    error: exclusiveLockError,
    setError: setExclusiveLockError,
  } = useExclusiveLock({
    patientId: patientId,
  });

  useEffect(() => {
    if (isOpen && patientId) {
      lock();
    }
    return () => {
      setExclusiveLockError(null);
      if (exclusiveLockStatus === 'locking') {
        unlock();
      }
    };
  }, [exclusiveLockStatus, isOpen, lock, patientId, unlock, setExclusiveLockError]);

  return (
    <>
      {type === 'tracingreport' &&
        (!exclusiveLockError ? (
          <Loader open inside appearance="white" />
        ) : exclusiveLockStatus === 'locking' ? (
          <InputPane patientType={patient?.__typename} />
        ) : (
          exclusiveLockError && <ExclusiveLockError error={exclusiveLockError} />
        ))}
    </>
  );
};
