import React from 'react';

import { Tabs } from '~/components/blocks';

import { HrefProps } from '../types';
import { MenuLink } from './MenuLink';

const MENU_ROUTES: { [key: string]: [HrefProps, ...HrefProps[]] } = {
  bookmarks: [
    { path: '/company/organizations/usages/bookmarks', exact: false },
    { path: '/company/organizations/usages', exact: true },
    { path: '/company/organizations', exact: true },
    { path: '/company', exact: true },
  ],
  receptions: [{ path: '/company/organizations/usages/receptions' }],
  followup: [{ path: '/company/organizations/usages/medication_followup' }],
  tracingReport: [{ path: '/company/organizations/usages/tracing_report' }],
};

const RootMenu = () => {
  return (
    <Tabs>
      <MenuLink href={MENU_ROUTES.bookmarks}>お気に入り薬局登録</MenuLink>
      <MenuLink href={MENU_ROUTES.receptions}>受付</MenuLink>
      <MenuLink href={MENU_ROUTES.followup}>服薬フォローアップ</MenuLink>
      <MenuLink href={MENU_ROUTES.tracingReport}>トレーシングレポート</MenuLink>
    </Tabs>
  );
};

export { RootMenu };
