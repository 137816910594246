import { useTheme } from '@emotion/react';
import React, { useEffect, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Button, Flex, Loader, Modal, Text } from '~/components/blocks';
import {
  useGetFollowupSettingGuestPatientProfileLazyQuery,
  useGetFollowupSettingPatientProfileLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { followupMessageSettingModalState } from '~/state/partials/followup_message_setting_modal/atoms';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { Panel } from '~/state/partials/message_modal/types';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';

export const FollowupMessageSettingModal = () => {
  const theme = useTheme();

  const { isOpen, patientId, guestPatientId } = useRecoilValue(followupMessageSettingModalState);

  const [getPatientProfile, { data: patientData, loading: patientLoading }] =
    useGetFollowupSettingPatientProfileLazyQuery();
  const [getGuestPatientProfile, { data: guestPatientData, loading: guestPatientLoading }] =
    useGetFollowupSettingGuestPatientProfileLazyQuery();

  const isLoading = patientLoading || guestPatientLoading;
  const patient = useMemo(() => {
    if (patientId) {
      return getNode(patientData, 'Patient');
    }
    if (guestPatientId) {
      if (guestPatientData?.node?.__typename === 'DirectVisitor') {
        return getNode(guestPatientData, 'DirectVisitor');
      }
      if (guestPatientData?.node?.__typename === 'WebVisitor') {
        return getNode(guestPatientData, 'WebVisitor');
      }
    }
  }, [guestPatientData, guestPatientId, patientData, patientId]);
  const patientName = patient ? `${patient.familyName}${patient.givenName}` : undefined;

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(followupMessageSettingModalState),
    [],
  );
  const handleClick = useRecoilCallback(
    ({ reset, set }) =>
      () => {
        if (!patient) return;

        reset(followupMessageSettingModalState);

        // TODO: 旧モーダルをパートナー内に移動する時にstateを分ける
        // 連携画面ではメッセージ送信モーダルのみを、店舗画面では患者詳細ダイアログのみを開くようになっており、両者が一画面上に同時に表示されることはないため良しとしている
        set(messageModalState, (_state) => ({
          ..._state,
          isOpen: true,
          currentPanel: Panel.initial,
          patientId: patient.id,
        }));
        set(patientDetailDialogState, (_state) => ({
          ..._state,
          isOpen: true,
          type: 'message' as TabType,
          patientId: patient.id,
        }));
      },
    [patient],
  );

  useEffect(() => {
    if (patientId) {
      getPatientProfile({
        variables: {
          id: patientId,
        },
      });
    }

    if (guestPatientId) {
      getGuestPatientProfile({
        variables: {
          id: guestPatientId,
        },
      });
    }
  }, [getGuestPatientProfile, getPatientProfile, guestPatientId, patientId]);

  return (
    <Modal open={isOpen} size="xs" onClose={handleClose}>
      {isLoading ? (
        <Modal.Body>
          <Box position="relative" height="240px">
            <Loader open inside />
          </Box>
        </Modal.Body>
      ) : (
        <>
          <Modal.Header>
            <Text paddingRight={theme.space.xxxl}>
              {patientName || 'ー'}様に服薬フォローアップの設定を行いますか？
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Text size="s">
              服薬フォローアップが必要な患者に、メッセージや質問票を送信できます。
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Flex flexDirection="column" justifyContent="center">
              <Button use="base" ml={theme.space.m} onClick={handleClick}>
                設定
              </Button>
            </Flex>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
