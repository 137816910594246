import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { useCallback, useEffect, useState } from 'react';

import { Button, FieldError, Flex, TextField } from '~/components/blocks';
import { FormErrorMessage } from '~/constants/messages';
import { numberOrHyphenRegexp, postalCodeRegexp } from '~/constants/regexp';
import { useSearchPostalCodesLazyQuery } from '~/graphql';
import { toHalfNumberWithHyphen } from '~/utils/convert';

export type Address = {
  postalCode: string;
  prefecture: string;
  city: string;
  town: string;
};

type Props = {
  required?: boolean;
  disabled?: boolean;
  initialPostalCode?: string;
  onChange: (address: Address) => void;
  placeholder?: string;
};

type SearchAddressCandidate = {
  postalCode: string;
  prefecture: string;
  city: string;
  town: string;
};

type SearchAddressCandidates = { [key: string]: SearchAddressCandidate };

const CandidateAddresses = styled('ul')();

const CandidateItem = styled('li')(({ theme }) =>
  css({
    cursor: 'pointer',
    float: 'left',
    marginRight: theme.space.m,
    fontSize: theme.fontSizes.xs,
    textDecoration: 'underline',
  }),
);

const SearchAddressWrap = styled('div', {
  shouldForwardProp,
})();

const formatAddress = (address: SearchAddressCandidate) => {
  return {
    prefecture: address.prefecture,
    city: address.city,
    town: address.town,
  };
};

export const SearchAddress = React.memo((props: Props) => {
  const { required = true, onChange } = props;
  const theme = useTheme();
  const [postalCode, setPostalCode] = useState<string>('');
  const [candidates, setCandidates] = useState<SearchAddressCandidates>({});
  const [searching, setSearching] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [search] = useSearchPostalCodesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      if (result.postalCodes.nodes.length > 1) {
        const results: SearchAddressCandidates = {};
        result.postalCodes.nodes.forEach((node) => {
          const key = `${node.prefecture}${node.city}`;
          if (results[key] === undefined) {
            results[key] = node;
          }
        });
        if (Object.keys(results).length > 1) {
          setCandidates(results);
        } else {
          onChange({ postalCode, ...formatAddress(result.postalCodes.nodes[0]) });
        }
      } else if (result.postalCodes.nodes.length === 1) {
        onChange({ postalCode, ...formatAddress(result.postalCodes.nodes[0]) });
      } else {
        onChange({ postalCode, prefecture: '', city: '', town: '' });
        setError(FormErrorMessage.addressNotFound);
      }
      setSearching(false);
    },
    onError: () => {
      setSearching(false);
    },
  });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (searching) return;

      const _postalCode = e.target.value;

      if (required && !_postalCode) {
        setError(FormErrorMessage.requiredPostalCode);
      } else {
        setError(undefined);
      }

      setCandidates({});
      onChange({
        postalCode: _postalCode,
        prefecture: '',
        city: '',
        town: '',
      });
      setPostalCode(_postalCode);
    },
    [searching, required, onChange],
  );

  const handleClick = useCallback(() => {
    if (searching) return;

    if (!numberOrHyphenRegexp.test(postalCode)) {
      setError(FormErrorMessage.number);
      return;
    }

    const _postalCode = toHalfNumberWithHyphen(postalCode);

    if (_postalCode.match(postalCodeRegexp)) {
      setSearching(true);
      setError(undefined);
      search({
        variables: {
          postalCode: _postalCode,
        },
      });
    } else if (required && !_postalCode) {
      setError(FormErrorMessage.requiredPostalCode);
    } else {
      setError(FormErrorMessage.invalidPostalCode);
    }

    setCandidates({});
    onChange({
      postalCode: _postalCode,
      prefecture: '',
      city: '',
      town: '',
    });
    setPostalCode(_postalCode);
  }, [searching, postalCode, required, onChange, search]);

  useEffect(() => {
    setCandidates({});
    setPostalCode(props.initialPostalCode || '');
    setError(undefined);
  }, [props.initialPostalCode]);

  return (
    <SearchAddressWrap>
      <Flex>
        <TextField
          aria-label="住所検索"
          disabled={props.disabled}
          error={!!error}
          value={postalCode}
          onChange={handleChange}
          placeholder={props.placeholder}
        />
        <Button
          ml={theme.space.m}
          use="base"
          onClick={handleClick}
          disabled={!postalCode || !!error}
        >
          住所検索
        </Button>
      </Flex>
      {error && <FieldError error={error} />}
      {!searching && (
        <CandidateAddresses>
          {Object.entries(candidates).map((value) => {
            const [key, candidate] = value;
            return (
              <CandidateItem
                key={key}
                onClick={() => onChange({ postalCode, ...formatAddress(candidate) })}
              >
                {`${candidate.prefecture}${candidate.city}`}
              </CandidateItem>
            );
          })}
        </CandidateAddresses>
      )}
    </SearchAddressWrap>
  );
});

SearchAddress.displayName = 'SearchAddress';
