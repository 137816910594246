import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Flex, Icon, Text } from '~/components/blocks';
import { KarteMedixsReceptionFragment } from '~/graphql';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';

type Props = {
  medixsReception?: KarteMedixsReceptionFragment | null;
};

const Wrap = styled('div')(({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: theme.colors.background.bg,
    padding: `${theme.space.s} ${theme.space.l}`,
    borderRadius: theme.radii.half,
  }),
);

export const MedixsReceptionId = React.memo((props: Props) => {
  const { enabledMedixs } = useMedixsSetting();
  const receptionId = props.medixsReception?.receptionId;

  if (!enabledMedixs) {
    return null;
  }

  return receptionId ? (
    <Flex alignItems="center">
      <Wrap>
        <Text size="xs" fontWeight="bold">
          受付ID：
        </Text>
        <Text size="m" fontWeight="bold">
          {receptionId}
        </Text>
      </Wrap>
    </Flex>
  ) : (
    <Wrap>
      <Text size="xs" fontWeight="bold">
        受付ID：
      </Text>
      <Icon icon="attention" size="m" />
      <Text color="pink" fontWeight="bold" size="s">
        未連携
      </Text>
    </Wrap>
  );
});

MedixsReceptionId.displayName = 'MedixsReceptionId';
