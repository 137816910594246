import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Dropdown, EntryList, Text } from '~/components/blocks';
import { useContractPlan } from '~/hooks/use-contract_plan';

const Root = styled(Dropdown.Item)(({ theme }) =>
  css({
    cursor: 'default',
    '&:hover': {
      background: theme.colors.background.default,
    },
  }),
);

export const Plan = React.memo(() => {
  const { loading, name } = useContractPlan();

  return (
    <Root>
      <EntryList size="s">
        <EntryList.Head>契約プラン</EntryList.Head>
        <EntryList.Body>
          <Text fontWeight="bold">{!loading ? name : 'ー'}</Text>
        </EntryList.Body>
      </EntryList>
    </Root>
  );
});

Plan.displayName = 'Plan';
