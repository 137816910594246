import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import {
  FollowupMessageEventPatientDetailFragment,
  FollowupQuestionnaireSheetEventOnPatientDetailDialogFragment,
  GuestPatientMedicationFollowupCommentFragment,
  PatientMedicationFollowupCommentFragment,
  TracingReportPatientDetailFragment,
} from '~/graphql';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import { answerState } from '~/state/partials/patient_detail_dialog/atoms';

import { FollowupMessageEvent } from './Events/FollowupMessageEvent';
import { GuestPatientMedicationFollowupComment } from './Events/GuestPatientMedicationFollowupComment';
import { MessageEvent } from './Events/MessageEvent';
import { PatientMedicationFollowupComment } from './Events/PatientMedicationFollowupComment';
import { TracingreportEvent } from './Events/TracingreportEvent';
import { QuestionnaireSheet } from './QuestionnaireSheet';

type Props = {
  event:
    | GuestPatientMedicationFollowupCommentFragment
    | PatientMedicationFollowupCommentFragment
    | FollowupMessageEventPatientDetailFragment
    | FollowupQuestionnaireSheetEventOnPatientDetailDialogFragment
    | TracingReportPatientDetailFragment;
  patientType: 'DirectVisitor' | 'Patient' | 'WebVisitor' | undefined;
  selectedSheetId: string | null;
};

const Root = styled('div')(({ theme }) =>
  css({
    paddingTop: theme.space.l,
    paddingLeft: theme.space.l,
    paddingRight: theme.space.l,
    backgroundColor: theme.colors.colorPallete.grey04,
  }),
);

const Event: React.FC<Props> = ({ event, patientType, selectedSheetId }) => {
  const { verifyRole } = usePractitionerRole();
  const isPharmacist = verifyRole('pharmacist');

  const handleClickSheet = useRecoilCallback(
    ({ set }) =>
      (answer: string) => {
        set(answerState, (_state) => ({ ..._state, answer }));
      },
    [],
  );
  return (
    <Root key={event.id}>
      {event.__typename === 'MedicationFollowupQuestionnaireSheet' ? (
        <QuestionnaireSheet
          selected={event.id === selectedSheetId}
          sheet={event}
          onClick={handleClickSheet}
          readonly={!isPharmacist}
        />
      ) : event.__typename === 'MedicationFollowupMessage' ? (
        event.medicationFollowupQuestionnaireRevision ? (
          <FollowupMessageEvent followupMessage={event} readonly={!isPharmacist} />
        ) : (
          <MessageEvent message={event} readonly={!isPharmacist} />
        )
      ) : event.__typename === 'PatientMedicationFollowupComment' ? (
        <PatientMedicationFollowupComment comment={event} />
      ) : event.__typename === 'GuestPatientMedicationFollowupComment' ? (
        patientType === 'DirectVisitor' || patientType === 'WebVisitor' ? (
          <GuestPatientMedicationFollowupComment patientType={patientType} comment={event} />
        ) : null
      ) : event.__typename === 'TracingReport' ? (
        <TracingreportEvent tracingreport={event} />
      ) : null}
    </Root>
  );
};

export default Event;
