import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Chip, Flex, Icon, List, Text } from '~/components/blocks';
import { Label } from '~/utils/label';

type Props = {
  answered: boolean;
  unread: boolean;
  sendAt: string;
  onClick?: () => void;
};

export const FollowupMessage = React.memo((props: Props) => {
  const { answered, unread } = props;
  const theme = useTheme();

  return (
    <List.Item onClick={props.onClick}>
      <Flex justifyContent="space-between">
        <Flex>
          <Box>
            <Icon icon="followup" color="green" size="l" />
          </Box>
          <Box marginLeft={theme.space.s}>
            <Text size="s">{`${Label.YYYYMMDDja(props.sendAt)} ${Label.HHMM(props.sendAt)}`}</Text>
            {answered && (
              <Box>
                <Text size="s" color="green" fontWeight="bold">
                  質問票：回答あり
                </Text>
              </Box>
            )}
          </Box>
        </Flex>
        {unread && (
          <Box>
            <Chip size="s" color="pink">
              未読
            </Chip>
          </Box>
        )}
      </Flex>
    </List.Item>
  );
});

FollowupMessage.displayName = 'FollowupMessage';
