import { Global } from '@emotion/react';
import React from 'react';

import { EntryList, Portal } from '~/components/blocks';

import { styles } from './styles';
import { Organization, Practitoner } from './types';

type Props = {
  privilege: boolean;
  organization: Organization;
  practitioner: Practitoner;
  practitionerCode: string;
  enabled: boolean;
};

export const PrintPage = React.memo((props: Props) => {
  const { privilege = false, organization, practitioner, practitionerCode } = props;

  return (
    <>
      <Global styles={styles} />
      {props.enabled && (
        <Portal>
          <div className="print">
            <h1>{organization.name}ログイン情報</h1>
            <EntryList>
              <EntryList.Head>店舗名</EntryList.Head>
              <EntryList.Body>{organization.name}</EntryList.Body>
            </EntryList>
            <EntryList>
              <EntryList.Head>{privilege ? '店舗管理者' : 'スタッフ'}名</EntryList.Head>
              <EntryList.Body>
                {`${practitioner?.familyName}${practitioner?.givenName}`}
              </EntryList.Body>
            </EntryList>
            <EntryList>
              <EntryList.Head>店舗コード</EntryList.Head>
              <EntryList.Body>{organization.slug}</EntryList.Body>
            </EntryList>
            <EntryList>
              <EntryList.Head>ユーザーID</EntryList.Head>
              <EntryList.Body>{practitioner.username}</EntryList.Body>
            </EntryList>
            <EntryList>
              <EntryList.Head>仮パスワード</EntryList.Head>
              <EntryList.Body>{practitionerCode}</EntryList.Body>
            </EntryList>
          </div>
        </Portal>
      )}
    </>
  );
});

PrintPage.displayName = 'PrintPage';
