import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  border,
  borderRadius,
  color,
  compose,
  flexbox,
  layout,
  LayoutProps,
  opacity,
  overflow,
  position,
  PositionProps,
  shadow,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { ColorProps } from '~/styles/types';

type Props = LayoutProps & SpaceProps & PositionProps & ColorProps & TypographyProps;

export const Box = styled('div', {
  shouldForwardProp,
})<Props>(() =>
  compose(
    flexbox,
    space,
    border,
    borderRadius,
    color,
    layout,
    opacity,
    overflow,
    shadow,
    typography,
    position,
  ),
);
