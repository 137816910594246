import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { Alert, Box, Button, Flex, Grid, Header, Icon, Text } from '~/components/blocks';
import { SETTING_TITLES } from '~/components/layouts/Settings/RootMenu';
import { AppPreview } from '~/components/partials';
import { MedicationFollowupStatusEditDrawer } from '~/components/partials/MedicationFollowupStatusEditDrawer';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import {
  editMedicationFollowupStatusDialogState,
  medicationFollowupSettingIdState,
} from '~/state/settings_messages/atoms';

import { RootMenu } from './RootMenu';
import { useMedicationFollowupSettingStatus } from './use-medication-followup-setting-status';

type Props = { children?: React.ReactNode };

const MEDICATION_FOLLOWUP_STATUS_SETTING_CLASS_NAME = 'medicationFollowupStatusSetting';

export const MessageSettings: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const { verifyRole } = usePractitionerRole();

  const { className } = useRecoilValue(editMedicationFollowupStatusDialogState);
  const setMedicationFollowupSettingId = useSetRecoilState(medicationFollowupSettingIdState);

  const handleOpenEditDrawer = useRecoilCallback(
    ({ set }) =>
      () => {
        set(editMedicationFollowupStatusDialogState, (state) => ({
          ...state,
          className: state.className ? null : MEDICATION_FOLLOWUP_STATUS_SETTING_CLASS_NAME,
        }));
      },
    [],
  );

  const {
    loading,
    error,
    medicationFollowupSettingId,
    statusManagement,
    refetchMedicationFollowupSettingStatus,
  } = useMedicationFollowupSettingStatus();

  useEffect(() => {
    if (medicationFollowupSettingId) {
      setMedicationFollowupSettingId(medicationFollowupSettingId);
    }
    refetchMedicationFollowupSettingStatus();
  }, [
    medicationFollowupSettingId,
    refetchMedicationFollowupSettingStatus,
    setMedicationFollowupSettingId,
    className,
  ]);

  return (
    <>
      <Grid height="100%" overflow="hidden" gridTemplateRows="min-content min-content 1fr">
        {error ? (
          <Alert>{error.message}</Alert>
        ) : (
          <Flex alignItems="center">
            <Header>{SETTING_TITLES.messages}</Header>
            <Text
              fontWeight="bold"
              size="m"
              whiteSpace="nowrap"
              lineHeight={theme.lineHeights.s}
              ml={theme.space.s}
            >
              フォローアップ管理｜
            </Text>
            <Text
              fontWeight="bold"
              size="m"
              whiteSpace="nowrap"
              lineHeight={theme.lineHeights.s}
              color={statusManagement ? 'green' : 'grey'}
            >
              {statusManagement ? '有効' : '無効'}
            </Text>
            <Button
              className={MEDICATION_FOLLOWUP_STATUS_SETTING_CLASS_NAME}
              marginLeft={theme.space.s}
              onClick={handleOpenEditDrawer}
              disabled={loading || !verifyRole('admin')}
            >
              <Icon icon="edit" size="m" />
              編集
            </Button>
          </Flex>
        )}
        <Box mt={theme.space.s}>
          <RootMenu />
        </Box>
        <Box pt={theme.space.xl} px={[0, theme.space.m]} overflow="auto" height="100%">
          {children}
        </Box>
      </Grid>
      <AppPreview />
      <MedicationFollowupStatusEditDrawer />
    </>
  );
};
