import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { Alert, Box } from '~/components/blocks';
import { PrescriptionFragment } from '~/graphql';
import { medicineNotesContainerState } from '~/state/partials/medicine_notes_container/atoms';

import { ClinicProfile } from './ClinicProfile';
import { DispensingDate } from './DispensingDate';
import { DispensingPharmacy } from './DispensingPharmacy';
import { PrescriptionRecipe } from './PrescriptionRecipe';
import { SpecialNote } from './SpecialNote';

type Props = {
  prescription: PrescriptionFragment;
};

const Root = styled(Box)(({ theme }) =>
  css({
    marginBottom: theme.space.xxxl,
  }),
);

const Wrap = styled('div')(({ theme }) =>
  css({
    border: `1px solid ${theme.colors.border.default}`,
    borderTop: 'none',
    padding: `${theme.space.l} ${theme.space.l}`,
  }),
);

export const Prescription = React.memo((props: Props) => {
  const { prescription } = props;

  const theme = useTheme();

  const { isDetailView } = useRecoilValue(medicineNotesContainerState);

  const dispensingPharmacy = prescription.dispensingPharmacy;
  const dispensingDate = dispensingPharmacy?.dispensingDateRecord?.date;

  const clinicRecord = prescription.clinic?.clinicRecord;
  const prescriptionRecipes = prescription.prescriptionRecipes;

  const prescriptionMemoRecords = prescription.prescriptionMemo?.prescriptionMemoRecords?.map(
    (record) => record,
  );
  const remarkRecords = prescription.remark?.remarkRecords?.map((record) => record);
  const remainingRecords = prescription.remaining?.remainingRecords?.map((record) => record);
  const waringRecords = prescription.warning?.warningRecords?.map((record) => record);
  const precautionRecords = prescription.precaution?.precautionRecords?.map((record) => record);

  const hasSpecialNote =
    (precautionRecords && precautionRecords.length > 0) ||
    (prescriptionMemoRecords && prescriptionMemoRecords.length > 0) ||
    (remainingRecords && remainingRecords.length > 0) ||
    (remarkRecords && remarkRecords.length > 0) ||
    (waringRecords && waringRecords.length > 0);

  return (
    <Root>
      <DispensingDate date={dispensingDate} />

      <Wrap>
        {clinicRecord && (
          <Box>
            <ClinicProfile clinic={clinicRecord} />
          </Box>
        )}
        {prescriptionRecipes ? (
          prescriptionRecipes.map((recipe, idx) => {
            if (recipe) {
              return <PrescriptionRecipe key={idx} prescriptionRecipe={recipe} />;
            }
          })
        ) : (
          <Alert status="info" marginTop={theme.space.l}>
            調剤情報がありません
          </Alert>
        )}
        {hasSpecialNote && isDetailView && (
          <Box marginTop={theme.space.l}>
            <SpecialNote
              precautions={precautionRecords}
              warings={waringRecords}
              remainings={remainingRecords}
              remarks={remarkRecords}
              memos={prescriptionMemoRecords}
            />
          </Box>
        )}
        <Box marginTop={theme.space.l}>
          <DispensingPharmacy pharmacy={dispensingPharmacy} />
        </Box>
      </Wrap>
    </Root>
  );
});

Prescription.displayName = 'Prescription';
