import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Button, Chip, EntryList, Flex, Icon, Name, Text } from '~/components/blocks';
import {
  DirectVisitorDetailDialogProfileFragment,
  PatientDetailDialogProfileFragment,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';
import { patientDetailDialogProfileState } from '~/state/partials/patient_detail_dialog/atoms';
import { Label } from '~/utils/label';
import { getPfTel } from '~/utils/pf_contact_point';

import { ProfileSkeleton } from './ProfileSkeleton';

type Props = {
  icon?: boolean;
  loading?: boolean;
  patient:
    | DirectVisitorDetailDialogProfileFragment
    | WebVisitorDetailDialogProfileFragment
    | PatientDetailDialogProfileFragment;
  visitedAt?: string;
};

const InactiveChip = styled(Chip)(({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: theme.radii.default,
    border: theme.borders.secondary,
    color: theme.colors.text.secondary,
    fontSize: theme.fontSizes.s,
    padding: `${theme.space.s} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.xs,
    },
  }),
);

const ShadedWrapper = styled('div')(() =>
  css({
    display: 'inline-block',
    position: 'relative',
  }),
);

const Shaded = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.secondary,
    top: `calc(${theme.space.l} - ${theme.space.m})`,
    width: theme.space.l,
    height: theme.space.xs,
    position: 'absolute',
    transform: `rotate(-45deg)`,
  }),
);

export const PatientProfile = React.memo((props: Props) => {
  const { loading, patient, visitedAt } = props;
  const theme = useTheme();
  const canEdit = patient.__typename === 'DirectVisitor';

  const tel =
    patient.__typename === 'DirectVisitor' || patient.__typename === 'WebVisitor'
      ? patient.mobilePhone
      : patient.__typename === 'Patient' && getPfTel(patient.pfPatient?.telecoms || []);

  const isDeactivatedPatient = patient.__typename === 'Patient' && !patient.active;

  const handleEdit = useRecoilCallback(
    ({ set }) =>
      () =>
        set(patientDetailDialogProfileState, { type: 'edit' as const }),
    [],
  );
  return !loading ? (
    <Box>
      <Name
        size="l"
        familyName={patient.familyName}
        givenName={patient.givenName}
        phoneticFamilyName={patient.phoneticFamilyName}
        phoneticGivenName={patient.phoneticGivenName}
      />
      <Flex paddingY={theme.space.m} alignItems="center" justifyContent="space-between">
        <Box>{`${Label.age(patient.birthDate)}（${Label.sex(patient.sex)}）`}</Box>
        {!loading && isDeactivatedPatient && (
          <InactiveChip color="lightPink">
            <ShadedWrapper>
              <Icon size="m" icon="smartphone" color="pink" />
              <Shaded />
            </ShadedWrapper>
            退会済み
          </InactiveChip>
        )}
      </Flex>
      <EntryList size="s">
        <EntryList.Head>生年月日</EntryList.Head>
        <EntryList.Body>{Label.warekiBirthDate(patient.birthDate)}</EntryList.Body>
      </EntryList>
      <EntryList size="s">
        <EntryList.Head>電話番号（携帯）</EntryList.Head>
        <EntryList.Body> {tel || '未登録'}</EntryList.Body>
      </EntryList>
      {canEdit && (
        <Button
          wide="fill"
          marginBottom={theme.space.xl}
          marginTop={theme.space.l}
          onClick={handleEdit}
        >
          <Icon icon="edit" size="m" />
          <Text size="xs">編集</Text>
        </Button>
      )}
      {visitedAt && (
        <EntryList size="s">
          <EntryList.Head>最終来局日</EntryList.Head>
          <EntryList.Body>{Label.YYYYMMDDja(visitedAt)}</EntryList.Body>
        </EntryList>
      )}
    </Box>
  ) : (
    <ProfileSkeleton />
  );
});

PatientProfile.displayName = 'PatientProfile';
