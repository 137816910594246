import { useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { Alert, Drawer } from '~/components/blocks';
import {
  AppointmentDeliveryMethod,
  AppointmentStatus,
  PatientProfileFooterAppointmentFragment,
  UberDeliveryStatus,
} from '~/graphql';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';

import { BookedButtons } from './BookedButtons';
import { ChargedButtons } from './ChargedButtons';
import { PendingButtons } from './PendingButtons';
import { SameDayDeliveryButtons } from './SameDayDeliveryButtons';
import { useFetchAppointment } from './use-fetch-appointment';
import { WaitingForChargeButtons } from './WaitingForChargeButtons';

type Props = {
  appointmentId: string;
  patientId: string;
};

export const Footer = React.memo((props: Props) => {
  const theme = useTheme();

  const appointment = useFetchAppointment(props.appointmentId);
  const isSentCongestionNotification = !!appointment?.congestionNotificationSentAt;

  const state = useRecoilValue(profileDrawerState);

  const appointmentStatus = appointment?.status;
  const isVisible = (
    ['pending', 'booked', 'waiting_for_charge', 'charged', 'finished'] as Array<
      PatientProfileFooterAppointmentFragment['status'] | undefined
    >
  ).includes(appointmentStatus);

  const encounter = appointment?.encounter
    ? { id: appointment.encounter.id, status: appointment.encounter.status }
    : undefined;

  if (!appointment || !isVisible) return null;

  if (appointmentStatus === AppointmentStatus.Finished) return null;

  const deliveryMethod = appointment.deliveryMethod;
  // 配達員を待っており、依頼から10分以内の場合はボタンを表示しない
  if (
    appointment.status === AppointmentStatus.WaitingForCharge &&
    deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery &&
    appointment.uberDelivery?.status === UberDeliveryStatus.Pickup &&
    !appointment.uberDelivery.isTimeElapsedNotification
  ) {
    return null;
  }

  // 配達よりも先に会計を終わらせてしまった時は、自動で完了になるので完了ボタンを出さないようにする
  if (
    appointment.status === AppointmentStatus.Charged &&
    deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery &&
    (appointment.uberDelivery?.status === UberDeliveryStatus.Pickup ||
      appointment.uberDelivery?.status === UberDeliveryStatus.Dropoff ||
      appointment.uberDelivery?.status === UberDeliveryStatus.Delivered)
  ) {
    return null;
  }

  return (
    <Drawer.Footer>
      {state.error && (
        <Alert status="error" marginBottom={theme.space.l}>
          {state.error}
        </Alert>
      )}
      {appointmentStatus === AppointmentStatus.Pending ? (
        <PendingButtons appointmentId={appointment.id} patientId={props.patientId} />
      ) : appointmentStatus === AppointmentStatus.Booked ? (
        <BookedButtons
          isSentCongestionNotification={isSentCongestionNotification}
          appointmentId={appointment.id}
          patientId={props.patientId}
          telemedicine={appointment.telemedicine}
          immediate={appointment.immediate}
          encounter={encounter}
        />
      ) : deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery ? (
        <SameDayDeliveryButtons appointment={appointment} />
      ) : appointmentStatus === AppointmentStatus.WaitingForCharge ? (
        <WaitingForChargeButtons
          appointmentId={appointment.id}
          telemedicine={appointment.telemedicine}
        />
      ) : appointmentStatus === AppointmentStatus.Charged ? (
        <ChargedButtons appointmentId={appointment.id} />
      ) : null}
    </Drawer.Footer>
  );
});

Footer.displayName = 'Footer';
