import { atom } from 'recoil';

import { CancelPane } from './types';

export const cancelPane = atom<CancelPane>({
  key: 'partials.cancelPane',
  default: {
    isOpen: false,
  },
});
