import {
  ChargeCompanyFeeSettingFragment,
  ChargeFeeSettingFragment,
  ChargeOrganizationCompanyFeeSettingFragment,
  useGetChargeFeeSettingsQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useFetchFeeSetting = (): (
  | ChargeFeeSettingFragment
  | ChargeCompanyFeeSettingFragment
  | ChargeOrganizationCompanyFeeSettingFragment
)[] => {
  const { data } = useGetChargeFeeSettingsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      page: 1,
      perPage: 100, // 全件取得したいので大きめ数字を設定しておく
    },
  });

  return data ? getMe(data)?.organization.feeSettings.nodes || [] : [];
};
