import React from 'react';

import { Tabs } from '~/components/blocks';
import { HrefProps } from '~/components/layouts/Settings/types';
import { MenuLink } from '~/components/partials/WebBookingsSubMenu/MenuLink';

export const WebBookingsSubMenu = () => {
  const MENU_ROUTES: { [key: string]: [HrefProps, ...HrefProps[]] } = {
    business_hour: [{ path: '/settings/organization/business_hour' }],
    web_bookings_limit: [{ path: '/settings/organization/web_bookings_limit' }],
  };

  return (
    <Tabs>
      <MenuLink href={MENU_ROUTES.business_hour}>受付時間</MenuLink>
      <MenuLink href={MENU_ROUTES.web_bookings_limit}>受付上限</MenuLink>
    </Tabs>
  );
};
