import { useTheme } from '@emotion/react';
import React, { useEffect, useRef } from 'react';

import { Alert, Box, Modal, ScrollBox, Text } from '~/components/blocks';
import { MailSenderForm, PractitionerAuthenticationInformation } from '~/components/partials';
import { Fields } from '~/components/partials/MailSenderForm/types';
import {
  Organization,
  Practitoner,
} from '~/components/partials/PractitionerAuthenticationInformation/types';

type Props = {
  error: string | null;
  privilege?: boolean;
  sending: boolean;
  organization: Organization;
  practitioner: Practitoner;
  practitionerCode: string;
  onSend: (values: Fields) => void;
};

export const CompleteResetPasswordPanel = React.memo((props: Props) => {
  const { error, privilege = false, sending, organization, practitioner, practitionerCode } = props;

  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  useEffect(() => {
    if (error) {
      scrollRef.current?.toTop();
    }
  }, [error]);

  return (
    <>
      <Modal.Header>パスワード再設定完了</Modal.Header>
      <ScrollBox ref={scrollRef}>
        <Modal.Body>
          {error && (
            <Alert status="error" marginBottom={theme.space.xl}>
              {error}
            </Alert>
          )}
          <Text size="s" mb="l">
            スタッフのパスワードをリセットしました。
            <br />
            仮パスワードを控えて、ログインしなおしてください。
          </Text>
          <Text size="s" color="green" fontWeight="bold">
            ログイン情報をメールで送信、または印刷することをお勧めします
          </Text>
          <Box my="m">
            <MailSenderForm
              initialValues={{ email: '' }}
              sending={sending}
              onSubmit={props.onSend}
            />
          </Box>
          <PractitionerAuthenticationInformation
            privilege={privilege}
            disabled={sending}
            organization={organization}
            practitioner={practitioner}
            practitionerCode={practitionerCode}
          />
        </Modal.Body>
      </ScrollBox>
    </>
  );
});

CompleteResetPasswordPanel.displayName = 'CompleteResetPasswordPanel';
