import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Alert, Flex, Icon, Text } from '~/components/blocks';

type Props = {
  text: string;
};

const Root = styled(Alert)(({ theme }) =>
  css({
    padding: theme.space.s,
    marginTop: theme.space.m,
    justifyContent: 'center',
  }),
);

export const ImmediateInfo = (props: Props) => {
  const { text } = props;

  return (
    <Root status="info" withIcon={false}>
      <Flex alignItems="center" margin="0" justifyContent="center">
        <Icon icon="timer" size="m" color="blue" />
        <Text size="s" color="blue" fontWeight="bold">
          {text}
        </Text>
      </Flex>
    </Root>
  );
};
