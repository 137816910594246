import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Icon, Skeleton, Text } from '~/components/blocks';
import { ContactList } from '~/components/partials/PatientProfile/ContactList';
import { PfPatientFragment } from '~/graphql';
import { toYYYYMMDDhyphen } from '~/utils/date';
import { getPfEmail, getPfTel } from '~/utils/pf_contact_point';

import { useFetchPfPatient } from './use-fetch-pf_patient';

type Props = {
  patientId: string | null;
};

const getFamily = (pfPatient: PfPatientFragment) => {
  const parent = pfPatient.parent;
  const family = pfPatient.family;

  if (!parent) {
    return family;
  }

  return [parent, ...family].filter(({ id }) => id !== pfPatient.id);
};

export const ProfileDetailApp = React.memo((props: Props) => {
  const theme = useTheme();

  const { loading, pfPatient, bookmark } = useFetchPfPatient(props.patientId);

  const address = pfPatient?.addresses[0];
  const telecoms = pfPatient?.telecoms || [];
  const email = getPfEmail(telecoms);
  const tel = getPfTel(telecoms);
  const family = pfPatient ? getFamily(pfPatient) : [];
  const bookmarkedDate = bookmark ? toYYYYMMDDhyphen(bookmark?.updatedAt) : '未登録';

  return (
    <>
      <Box
        backgroundColor={theme.colors.background.bg}
        padding={`${theme.space.m} ${theme.space.l}`}
        marginBottom={theme.space.l}
      >
        {loading ? (
          <ContactList>
            <ContactList.Item>
              <Icon size="m" icon="tel" />
              <Skeleton width="120px" />
            </ContactList.Item>
            <ContactList.Item>
              <Icon size="m" icon="mail" />
              <Skeleton width="120px" />
            </ContactList.Item>
            <ContactList.Item>
              <Icon size="m" icon="address" />
              <Skeleton width="120px" />
            </ContactList.Item>
            <ContactList.Item>
              <Icon size="m" icon="people" />
              <Skeleton width="80px" />
            </ContactList.Item>
          </ContactList>
        ) : (
          <>
            <ContactList>
              <ContactList.Item>
                <Icon size="m" icon="tel" />
                {tel || '未登録'}
              </ContactList.Item>
              <ContactList.Item>
                <Icon size="m" icon="mail" />
                {email || '未登録'}
              </ContactList.Item>
              <ContactList.Item>
                <Icon size="m" icon="address" />
                {address ? (
                  <Text>
                    {address.postalCode}
                    <br />
                    {`${address.prefecture}${address.city}${address.line}`}
                  </Text>
                ) : (
                  '未登録'
                )}
              </ContactList.Item>
              <ContactList.Item>
                <Icon size="m" icon="people" />
                {pfPatient?.birthDate || '未登録'}
              </ContactList.Item>
              <ContactList.Item>
                <Icon size="m" color="pink" icon="favorite-fill" />
                {bookmarkedDate}
              </ContactList.Item>
            </ContactList>
            {family && family.length > 0 && (
              <Box bg={theme.colors.background.bg} padding={theme.space.m} mb={theme.space.l}>
                <Text size="xs">家族アカウント</Text>
                <ul>
                  {family.map((f) => (
                    <li key={f.id}>
                      <Text size="s" fontWeight="bold">
                        ・{`${f.familyName}${f.givenName}`}
                      </Text>
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
});

ProfileDetailApp.displayName = 'ProfileDetailApp';
