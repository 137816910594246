import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, variant } from 'styled-system';

type Props = {
  orientation?: 'vertical' | 'holizonal';
  minWidth?: string;
};

export const Tabs = styled('div', {
  shouldForwardProp,
})<Props>(
  () =>
    css({
      display: 'flex',
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'orientation',
        variants: {
          holizonal: css({
            flexDirection: 'row',
            boxShadow: `inset 0 -1px 0 ${theme.colors.border.grey}`,
          }),
          vertical: css({
            flexDirection: 'column',
            borderRight: theme.borders.grey,
          }),
        },
      }),
    ),
);

Tabs.defaultProps = {
  orientation: 'holizonal',
};
