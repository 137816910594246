import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useState } from 'react';
import { compose, space, SpaceProps } from 'styled-system';

import { getMimeType, isCsvFile } from '~/utils/file';

import { Alert } from '../Alert';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Text } from '../Text';

type ACCEPT_TYPE =
  | '.csv'
  | 'application/pdf'
  | 'image/*'
  | 'image/png'
  | 'image/gif'
  | 'image/jpeg';

type Props = SpaceProps & {
  disabled?: boolean;
  label?: string;
  accepts?: ACCEPT_TYPE[];
  value?: File | null;
  placefolder?: string;
  onChange?: (file: File) => void;
};

type FieldProps = SpaceProps & { dragOver: boolean; disabled: boolean };

const Root = styled('div')({});

const Field = styled('div')<FieldProps>(
  ({ theme, dragOver }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontSize: theme.fontSizes.s,
      padding: `${theme.space.xl} ${theme.space.l}`,
      textAlign: 'center',
      backgroundColor: theme.colors.background.bg,
      transitionDuration: theme.transitions.default,
      border: `1px ${dragOver ? 'dashed' : 'dashed'} ${
        dragOver ? `${theme.colors.border.primary}` : `${theme.colors.border.default}`
      }`,
      borderRadius: theme.radii.default,
      minHeight: '184px',
    }),
  ({ disabled }) =>
    disabled &&
    css({
      opacity: 0.7,
      pointerEvents: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
  compose(space),
);

const FileName = styled(Text)(() =>
  css({
    display: 'block',
    width: '80%',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
);

const InputFile = styled('input')(() =>
  css({
    display: 'none',
  }),
);

export const MAX_FILE_SIZE = 10_485_760; // 10MB

const makeAcceptedTypes = (accepts?: ACCEPT_TYPE[]) => {
  let acceptedTypes: string[] = [];
  let acceptedCsv = false;
  if (accepts) {
    for (const accept of accepts) {
      if (accept === 'image/*') {
        acceptedTypes = [...acceptedTypes, 'image/png', 'image/gif', 'image/jpeg'];
      } else if (accept === '.csv') {
        acceptedCsv = true;
      } else {
        acceptedTypes.push(accept);
      }
    }
  } else {
    acceptedTypes = ['image/png', 'image/gif', 'image/jpeg'];
  }
  return { acceptedTypes, acceptedCsv };
};

export const Uploader = (props: Props) => {
  const theme = useTheme();
  const [isDragOver, setIsDragOver] = useState(false);
  const fileName = props.value?.name;
  const [isRefusedType, setIsRefusedType] = useState(false);
  const [isSizeOver, setIsSizeOver] = useState(false);

  const { disabled = false, label, placefolder, onChange, ...fieldProps } = props;

  const accept = props.accepts?.join(',') || 'image/*';

  const handleChange = useCallback(
    async (files: FileList) => {
      if (!files || files.length === 0) {
        return;
      }

      if (files[0].size > MAX_FILE_SIZE) {
        setIsSizeOver(true);
        setIsRefusedType(false);
        return;
      } else {
        setIsSizeOver(false);
      }

      const [mimeType, isCsv] = await Promise.all([getMimeType(files[0]), isCsvFile(files[0])]);
      const { acceptedTypes, acceptedCsv } = makeAcceptedTypes(props.accepts);

      if ((acceptedCsv && isCsv) || acceptedTypes.includes(mimeType)) {
        setIsRefusedType(false);
        onChange && onChange(files[0]);
      } else {
        setIsRefusedType(true);
      }
    },
    [onChange, props.accepts],
  );
  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  }, []);
  const handleDragleave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
  }, []);
  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (disabled) return;

      e.preventDefault();
      setIsDragOver(false);
      handleChange(e.dataTransfer.files);
    },
    [disabled, handleChange],
  );
  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.files) {
        handleChange(e.currentTarget.files);
      }
    },
    [handleChange],
  );

  return (
    <Root>
      <Field
        {...fieldProps}
        disabled={disabled}
        dragOver={isDragOver}
        onDragOver={handleDragOver}
        onDragLeave={handleDragleave}
        onDrop={handleDrop}
      >
        {fileName ? (
          <FileName>{fileName}</FileName>
        ) : (
          <Text whiteSpace="pre-wrap">{label || 'アップロードしてください'}</Text>
        )}
        <Button m={`${theme.space.l} 0`} as="label">
          <InputFile
            type="file"
            accept={accept}
            onChange={handleInput}
            onClick={(e: React.MouseEvent<HTMLInputElement>) => (e.currentTarget.value = '')}
          />
          <Icon size="m" icon="upload" />
          ファイルを選択
        </Button>
        {isRefusedType && (
          <Alert mt={theme.space.m} status="error">
            <Text size="s">無効なファイル形式</Text>
          </Alert>
        )}
        {isSizeOver && (
          <Alert mt={theme.space.m} status="error">
            <Text size="s">{`ファイルサイズが${MAX_FILE_SIZE / 1024 / 1024}MBを超えています`}</Text>
          </Alert>
        )}
        {placefolder && <Text>{placefolder}</Text>}
      </Field>
    </Root>
  );
};
