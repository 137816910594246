import { useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { useActivateOtpSecretMutation } from '~/graphql';
import { twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';

import { Fields } from './types';

export const useActivateOtpSecret = () => {
  const { otpSecretId } = useRecoilValue(twoFactorAuthModalState);

  const [isActivating, setIsActivating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [activate] = useActivateOtpSecretMutation();

  const handleSubmit = useRecoilCallback(
    ({ set }) =>
      async (values: Fields) => {
        if (!otpSecretId) return;

        setIsActivating(true);
        setError(null);

        try {
          await activate({
            variables: {
              input: {
                practitionerOtpSecretId: otpSecretId,
                onetimeCode: `${values.onetimeCode}`,
              },
            },
          });
          set(twoFactorAuthModalState, (_state) => ({ ..._state, currentPanel: 'completed' }));
        } catch (_error) {
          setError(_error?.message || 'エラーが発生しました');
        }
        setIsActivating(false);
      },
    [activate, otpSecretId],
  );

  return {
    activating: isActivating,
    error,
    handleSubmit,
  };
};
