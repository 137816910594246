import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Alert, EntryList, Text } from '~/components/blocks';
import { ClientCertInfoFragment } from '~/graphql';
import { toHHMMSS, toYYYYMMDDja } from '~/utils/date';

const Root = styled('div')<{ size: 's' | 'm' }>(({ theme, size }) =>
  css({
    [`& > ${EntryList} + ${EntryList}`]: {
      marginTop: size === 's' ? `calc(${theme.space.m} + ${theme.space.s})` : undefined,
    },
  }),
);

type Organization = {
  slug: string;
  name: string;
};

type Props = {
  size?: 's' | 'm';
  clientCert: ClientCertInfoFragment;
  organization?: Organization;
};

export const ClientCertInformation = React.memo((props: Props) => {
  const { size = 'm', clientCert, organization } = props;

  const startDate = clientCert.notBefore
    ? `${toYYYYMMDDja(clientCert.notBefore)} ${toHHMMSS(clientCert.notBefore)}`
    : null;
  const endDate = clientCert.notAfter
    ? `${toYYYYMMDDja(clientCert.notAfter)} ${toHHMMSS(clientCert.notAfter)}`
    : null;

  return (
    <Alert status="info" withIcon={false}>
      <Root size={size || 'm'}>
        {organization && (
          <>
            <EntryList>
              <EntryList.Head>店舗コード</EntryList.Head>
              <EntryList.Body>{organization.slug}</EntryList.Body>
            </EntryList>
            <EntryList>
              <EntryList.Head>発行店舗</EntryList.Head>
              <EntryList.Body>{organization.name}</EntryList.Body>
            </EntryList>
          </>
        )}
        <EntryList>
          <EntryList.Head>証明書名</EntryList.Head>
          <EntryList.Body>{clientCert.name}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>説明</EntryList.Head>
          <EntryList.Body>
            <Text wordBreak="break-all" whiteSpace="pre-wrap">
              {clientCert.description}
            </Text>
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>有効期間</EntryList.Head>
          <EntryList.Body>
            {startDate && endDate ? `${startDate} 〜 ${endDate}` : 'ー'}
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>パスワード</EntryList.Head>
          <EntryList.Body>
            <Text fontWeight="bold">{clientCert.password}</Text>
          </EntryList.Body>
        </EntryList>
      </Root>
    </Alert>
  );
});

ClientCertInformation.displayName = 'ClientCertInformation';
