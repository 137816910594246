import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

import { Icon, Text } from '~/components/blocks';

import { HrefProps } from '../types';
import { MenuBack } from './MenuBack';
import { MenuLink } from './MenuLink';

const Root = styled('nav', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    width: '80px',
    minHeight: '100%',
    backgroundColor: theme.colors.background.primary,
    [`@media ${theme.mediaQueries.portrait}`]: {
      width: '100%',
      height: theme.layouts.responsiveMenu.height,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  }),
);

const MENU_ROUTES: { [key: string]: [HrefProps, ...HrefProps[]] } = {
  reception: [{ path: '/reception' }],
  company_organizations: [{ path: '/company/organizations' }, { path: '/company', exact: true }],
  company_charges: [{ path: '/company/charges' }],
  company_contracts: [{ path: '/company/contracts' }],
  company_security: [{ path: '/company/security' }],
};

const RootMenu = () => {
  return (
    <Root>
      <MenuLink href={MENU_ROUTES.company_organizations}>
        <Icon color="white" size="xxxl" icon="company-list" />
        <Text color="white" size="xs">
          店舗管理
        </Text>
      </MenuLink>
      <MenuLink href={MENU_ROUTES.company_charges}>
        <Icon color="white" size="xxxl" icon="company-payout" />
        <Text color="white" size="xs">
          会計管理
        </Text>
      </MenuLink>
      <MenuLink href={MENU_ROUTES.company_contracts}>
        <Icon color="white" size="xxxl" icon="agreement" />
        <Text color="white" size="xs">
          契約管理
        </Text>
      </MenuLink>
      <MenuLink href={MENU_ROUTES.company_security}>
        <Icon color="white" size="xxxl" icon="audit" />
        <Text color="white" size="xs">
          セキュリティ
        </Text>
      </MenuLink>
      <MenuBack href={MENU_ROUTES.reception} />
    </Root>
  );
};

export { RootMenu };
