import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { FormikErrors } from 'formik';
import React, { useCallback } from 'react';

import { FieldError } from '~/components/blocks';
import { numberRegexp } from '~/constants/regexp';
import { LineItemCode } from '~/graphql';
import { toHalfNumber } from '~/utils/convert';

const DEFAULT_WIDTH = '400px';

export type LineItem = {
  code: LineItemCode;
  subject: string;
  amount: number | string;
};

type Props = {
  disabled?: boolean;
  values: LineItem;
  errors?: FormikErrors<LineItem>;
  width?: string;
  onChange: (values: LineItem) => void;
};

const Code = styled('div')(({ theme }) =>
  css({
    padding: `${theme.space.m} ${theme.space.xl} ${theme.space.m} ${theme.space.m}`,
    width: '200px',
    border: 'none',
    borderRadius: `${theme.radii.default} 0 0 ${theme.radii.default}`,
    verticalAlign: 'middle',
  }),
);

const TextField = styled('input', { shouldForwardProp })<{ error: boolean }>(
  ({ theme }) =>
    css({
      flex: 1,
      width: '100%',
      fontSize: theme.fontSizes.l,
      fontWeight: theme.fontWeights.bold,
      padding: `${theme.space.m} ${theme.space.xxl} ${theme.space.m} ${theme.space.l}`,
      border: 'none',
      borderRadius: `$0 {theme.radii.default} ${theme.radii.default} 0`,
      backgroundColor: theme.colors.background.bg,
      color: theme.colors.text.default,
      transition: theme.transitions.default,
      outline: 0,
      textAlign: 'right',
      '&::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '&::placeholder': {
        color: theme.colors.text.placeholder,
      },
      '&:hover': {
        boxShadow: `0 0 0 1px ${theme.colors.colorPallete.green} inset`,
      },
      '&:focus': {
        boxShadow: `0 0 0 1px ${theme.colors.colorPallete.green} inset`,
        backgroundColor: theme.colors.background.default,
      },
    }),
  ({ theme, error }) =>
    error &&
    css({
      backgroundColor: theme.colors.background.alert,
      '&:hover': {
        boxShadow: `none`,
      },
      '&:focus': {
        boxShadow: `1px 0 0 0 ${theme.colors.colorPallete.pink} inset`,
      },
    }),
);

const Field = styled('div', { shouldForwardProp })<{ error: boolean }>(
  ({ theme }) =>
    css({
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: theme.borders.default,
      borderRadius: theme.radii.default,
      color: theme.colors.text.default,
      overflow: 'hidden',
      '&::after': {
        content: '"円"',
        position: 'absolute',
        right: theme.space.m,
        bottom: theme.space.m,
        fontSize: theme.fontSizes.s,
      },
    }),
  ({ theme, error }) =>
    error &&
    css({
      borderColor: theme.colors.colorPallete.pink,
    }),
);

const Root = styled('div')<{ width: string }>(({ theme, width }) =>
  css({
    width,
    '& + &': {
      marginTop: theme.space.m,
    },
  }),
);

export const LineItemField = React.memo((props: Props) => {
  const { onChange } = props;
  const handleChangeAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const amount = e.currentTarget.value;
      onChange({
        ...props.values,
        amount: amount ?? '',
      });
    },
    [onChange, props.values],
  );
  const handleBlurAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!numberRegexp.test(e.currentTarget.value)) return;
      const amount = toHalfNumber(e.currentTarget.value);
      onChange({
        ...props.values,
        amount: amount ?? '',
      });
    },
    [onChange, props.values],
  );

  return (
    <Root width={props.width || DEFAULT_WIDTH}>
      <Field error={!!props.errors}>
        <Code>{props.values.subject}</Code>
        <TextField
          error={!!props.errors}
          disabled={props.disabled}
          inputMode="numeric"
          value={props.values.amount}
          onChange={handleChangeAmount}
          onBlur={handleBlurAmount}
        />
      </Field>
      {!!props.errors && <FieldError error="金額を入力してください" />}
    </Root>
  );
});

LineItemField.displayName = 'LineItemField';
