import { useEffect } from 'react';

import { useGetAppPatientProfileLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchAppPatientProfile = (isExpanded: boolean, patientId?: string) => {
  const [getAppPatientProfile, { called, loading, data }] = useGetAppPatientProfileLazyQuery();

  const appPatient = getNode(data, 'Patient')?.pfPatient;

  useEffect(() => {
    if (isExpanded && patientId) {
      getAppPatientProfile({
        variables: { id: patientId },
      });
    }
  }, [getAppPatientProfile, isExpanded, patientId]);

  return { loading: !called || loading, appPatient };
};
