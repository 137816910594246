import React from 'react';

import { Icon } from '~/components/blocks';
import { fontSizes, space } from '~/styles/theme';

type Font = keyof typeof fontSizes;
type Space = keyof typeof space;

type Props = {
  isPatient?: boolean | false;
  size?: Font;
  mr?: Space;
};

export const PatientTypeIcon = (props: Props) => {
  const { isPatient, size, mr } = props;
  const iconSize = size ?? 'xxxl';
  const marginRight = mr ?? 's';

  return <Icon icon={isPatient ? 'app' : 'sms'} size={iconSize} mr={marginRight} />;
};
