import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

type Props = {
  withBorder?: boolean;
  error?: boolean;
};

const Root = styled('i', {
  shouldForwardProp,
})<{ error?: boolean; border?: boolean }>(({ theme, border, error }) =>
  css({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    whiteSpace: 'nowrap',
    padding: 0,
    marginRight: theme.space.s,
    color: error ? theme.colors.text.secondary : theme.colors.text.primary,
    fontWeight: theme.fontWeights.bold,
    background: theme.colors.background.default,
    border: border ? theme.borders.primary : undefined,
    fontSize: theme.fontSizes.s,
    fontStyle: 'normal',
    borderRadius: theme.radii.circle,
  }),
);

export const QuestionMarkIcon = React.memo((props: Props) => {
  return (
    <Root border={props.withBorder} error={props.error}>
      Q
    </Root>
  );
});

QuestionMarkIcon.displayName = 'QuestionMarkIcon';
