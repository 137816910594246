import { Formik, FormikProps } from 'formik';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { useGetMedicalInstitutionSettingsQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

import { Fields } from '../types';
import { validationSchema } from '../validation';
import { InputPane } from './InputPane';

const PAGE = 1;
const PER_PAGE = 100; // 全件取得したいので大きめ数字を設定しておく

type Props = {
  disabled: boolean;
  isClearForm: boolean;
  initialValues: Fields;
  setIsAutoSaved: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (values: Fields) => void;
  onAutoSave: (values: Fields) => void;
};

export const TracingReportForm = forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const { disabled, isClearForm, initialValues, setIsAutoSaved, onAutoSave } = props;

  const { data: medicalInstitutionSettingData } = useGetMedicalInstitutionSettingsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      page: PAGE,
      perPage: PER_PAGE,
    },
  });

  const [selectedClinicName, setSelectedClinicName] = useState<string>('');
  const practitioner = medicalInstitutionSettingData ? getMe(medicalInstitutionSettingData) : null;
  const medicalInstitutionSettings = useMemo(
    () => (practitioner ? practitioner.organization.medicalInstitutionSettings.nodes || [] : []),
    [practitioner],
  );

  const clinicNames = useMemo(
    () => medicalInstitutionSettings.map((m) => m.medicalInstitutionName),
    [medicalInstitutionSettings],
  );

  const clinicalDepartments = useMemo(() => {
    const setting = medicalInstitutionSettings.find(
      (m) => m.medicalInstitutionName == selectedClinicName,
    );
    return setting ? setting.clinicalDepartments : [];
  }, [medicalInstitutionSettings, selectedClinicName]);

  const doctorNames = useMemo(() => {
    const setting = medicalInstitutionSettings.find(
      (m) => m.medicalInstitutionName == selectedClinicName,
    );
    return setting ? setting.attendingPhysicians : [];
  }, [medicalInstitutionSettings, selectedClinicName]);

  const handleReset = useCallback(() => {
    setSelectedClinicName('');
  }, []);

  useEffect(() => {
    setSelectedClinicName(initialValues.clinicName);
  }, [initialValues]);

  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
      onReset={handleReset}
    >
      {(formik) => (
        <>
          <InputPane
            disabled={disabled}
            isClearForm={isClearForm}
            clinicNames={clinicNames}
            clinicalDepartments={clinicalDepartments}
            doctorNames={doctorNames}
            formik={formik}
            setSelectedClinicName={setSelectedClinicName}
            setIsAutoSaved={setIsAutoSaved}
            onAutoSave={onAutoSave}
          />
        </>
      )}
    </Formik>
  );
});

TracingReportForm.displayName = 'TracingReportForm';
