import { css } from '@styled-system/css';

import { AppTheme } from '~/styles/types';

export const pageStyles = (theme: AppTheme, label?: string): Parameters<typeof css>[0] => ({
  position: 'relative',
  /* A5は148mm x 210mm */
  width: '148mm',
  /* 210mmにする2ページになるので-1mmしている */
  height: '209mm',
  '@media print': {
    border: 'none',
  },
  '@media screen': {
    background: theme.colors.background.default,
    boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.4)',
    margin: `${theme.space.xl} auto`,
  },
  ...(label && {
    '&::before': {
      content: `"${label}"`,
      position: 'absolute',
      top: '4mm',
      left: '4mm',
      display: 'block',
      padding: '0 2mm',
      border: '2px solid red',
      fontWeight: 'bold',
      fontSize: '10pt',
      color: 'red',
      opacity: 0.75,
      zIndex: 10,
      borderRadius: theme.radii.default,
    },
  }),
});
