import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';

import { ThemeProps } from '~/styles/types';

export const CloseBtn = styled('div', {
  shouldForwardProp,
})<ThemeProps>(({ theme }) =>
  css({
    position: 'relative',
    width: '28px',
    height: '28px',
    transform: 'rotate(45deg)',
    backgroundColor: theme.colors.background.default,
    border: theme.borders.default,
    borderRadius: theme.radii.circle,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.default,
    cursor: 'pointer',
    '&:before': css({
      position: 'absolute',
      content: '""',
      width: '12px',
      height: '3px',
      backgroundColor: theme.colors.text.default,
    }),
    '&:after': css({
      position: 'absolute',
      content: '""',
      width: '12px',
      height: '3px',
      backgroundColor: theme.colors.text.default,
      transform: 'rotate(90deg)',
    }),
    '&:hover': css({
      backgroundColor: theme.colors.background.bg,
    }),
  }),
);
