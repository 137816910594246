type PaginationPage = number | null;

export const getRangeArray = (from: number, to: number): PaginationPage[] => {
  return Array(to - from + 1)
    .fill(1)
    .map((_, i) => i + from);
};

/**
 * ページネーション用のページ番号の配列を返す（placeholderを表すnullを含む）
 * 参考-> https://material-ui.com/components/pagination/
 * @param totalPages
 * @param currentPage
 * @param siblingCount currentPageの左右に表示するページ番号の数（0以上）
 * @param boundaryCount ページネーションの左端、右端に表示するページ番号の数（1以上を想定）
 */
export const getPaginationPages = (
  totalPages: number,
  currentPage: number,
  siblingCount = 1,
  boundaryCount = 1,
): PaginationPage[] => {
  // イメージ→ 1,...,firstRightBoundaryPage,...leftSiblingPage,...,currentPage,...,rightSiblingPage,...,lastLeftBoundaryPage,...,lastPage
  const lastPage = totalPages;
  const firstRightBoundaryPage = boundaryCount;
  const lastLeftBoundaryPage = lastPage - boundaryCount + 1;
  const leftSiblingPage = currentPage - siblingCount;
  const rightSiblingPage = currentPage + siblingCount;
  // 最初のセット、最後のセットのはしっこ
  const maxFirstBoundaryPage = boundaryCount + siblingCount * 2 + 2;
  const minLastBoundaryPage = lastPage - maxFirstBoundaryPage + 1;

  // console.log('lastPage', lastPage);
  // console.log('firstRightBoundaryPage', firstRightBoundaryPage);
  // console.log('lastLeftBoundaryPage', lastLeftBoundaryPage);
  // console.log('leftSiblingPage', leftSiblingPage);
  // console.log('rightSiblingPage', rightSiblingPage);
  // console.log('maxFirstBoundaryPage', maxFirstBoundaryPage);
  // console.log('minLastBoundaryPage', minLastBoundaryPage);

  // 2個以上ページが間に無ければ全部表示する
  const isDisplayAll = lastLeftBoundaryPage - maxFirstBoundaryPage < 3;
  if (isDisplayAll) {
    return getRangeArray(1, lastPage);
  }

  let pages: PaginationPage[] = [];
  if (leftSiblingPage - firstRightBoundaryPage < 3) {
    // currentPageが左端のセットに含まれている間
    pages = pages.concat(getRangeArray(1, maxFirstBoundaryPage));
    pages.push(null);
    pages = pages.concat(getRangeArray(lastLeftBoundaryPage, lastPage));
  } else {
    pages = pages.concat(getRangeArray(1, firstRightBoundaryPage));
    pages.push(null);
    if (lastLeftBoundaryPage - rightSiblingPage < 3) {
      // currentPageが右端のセットに含まれる場合
      pages = pages.concat(getRangeArray(minLastBoundaryPage, lastPage));
    } else {
      // currentPageのセットが独立する場合
      pages = pages.concat(getRangeArray(leftSiblingPage, rightSiblingPage));
      pages.push(null);
      pages = pages.concat(getRangeArray(lastLeftBoundaryPage, lastPage));
    }
  }
  return pages;
};
