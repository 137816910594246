import React from 'react';

import { Icon } from '~/components/blocks';
import { fontSizes, space } from '~/styles/theme';

type Font = keyof typeof fontSizes;
type Space = keyof typeof space;

type Props = {
  telemedicine?: boolean | false;
  size?: Font;
  mr?: Space;
};

export const ReceptionTypeIcon = (props: Props) => {
  const { telemedicine, size, mr } = props;
  const iconSize = size ?? 'xl';
  const marginRight = mr ?? 's';

  return telemedicine ? (
    <Icon icon="online" color="green" size={iconSize} mr={marginRight} />
  ) : (
    <Icon icon="patient" color="black" size={iconSize} mr={marginRight} />
  );
};
