import { useEffect, useMemo } from 'react';

import {
  SimplePatientProfilePatientFragment,
  useGetSimplePatientProfilePatientLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { Label } from '~/utils/label';

const toProfile = (patient: SimplePatientProfilePatientFragment) => {
  return {
    active: patient.active,
    familyName: patient.familyName,
    givenName: patient.givenName,
    phoneticFamilyName: patient.phoneticFamilyName,
    phoneticGivenName: patient.phoneticGivenName,
    age: Label.age(patient.birthDate),
    sex: Label.sex(patient.sex),
  };
};

export const useFetchPatientProfile = (patientId: string | null) => {
  const [getPatient, { loading, data }] = useGetSimplePatientProfilePatientLazyQuery();

  const profile = useMemo(() => {
    const patient = getNode(data, 'Patient');

    return patient ? toProfile(patient) : null;
  }, [data]);

  useEffect(() => {
    if (patientId) {
      getPatient({
        variables: {
          patientId,
        },
      });
    }
  }, [getPatient, patientId]);

  return { loading, profile };
};
