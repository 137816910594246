import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box } from '~/components/blocks';
import { PrescriptionFragment } from '~/graphql';

import { Prescription } from './Prescription';

type Props = {
  prescriptions: PrescriptionFragment[];
};

const Root = styled(Box)(({ theme }) =>
  css({
    '& + &': {
      marginTop: theme.space.l,
    },
  }),
);

export const PrescriptionList = React.memo((props: Props) => {
  return (
    <Root>
      {props.prescriptions.map((prescription, idx) => (
        <Prescription key={idx} prescription={prescription} />
      ))}
    </Root>
  );
});

PrescriptionList.displayName = 'PrescriptionList';
