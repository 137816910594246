import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Icon, Tag, Text } from '~/components/blocks';
import { DeliveryMethodLabel } from '~/constants/delivery_method';

const DELIVERY_METHODS = Object.values(DeliveryMethodLabel);
const DELIVERY_REGEX = new RegExp(`[${DELIVERY_METHODS.join('|')}]｜[0-9].\\/[0-9].`);

export const isDeliveryTag = (text: string) => {
  return DELIVERY_REGEX.test(text);
};

const DeliTag = styled(Tag)(({ theme }) =>
  css({
    alignItems: 'center',
    border: theme.borders.transparent,
    background: theme.colors.background.bg,
    display: 'inline-flex',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
  }),
);

type Props = {
  text: string;
  fontSize?: 's' | 'm';
};

export const DeliveryTag = React.memo((props: Props) => {
  return (
    <DeliTag>
      <Icon icon="delivery" color="navy" size="l" />
      <Text size={props.fontSize} color="navy">
        {props.text}
      </Text>
    </DeliTag>
  );
});

DeliveryTag.displayName = 'DeliveryTag';
