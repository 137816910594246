import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Button, Modal, Text } from '~/components/blocks';
import {
  eventPageInfoState,
  patientDetailConfirmBreakawayState,
  patientDetailDialogMessageState,
  patientDetailDialogProfileState,
  patientDetailDialogState,
  patientDetailDialogTracingreportState,
} from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';

export const ConfirmBreakawayModal = () => {
  const { isOpen, action } = useRecoilValue(patientDetailConfirmBreakawayState);
  const { patientId } = useRecoilValue(patientDetailDialogState);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(patientDetailConfirmBreakawayState),
    [],
  );

  const handleConfirm = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        if (action === 'close') {
          reset(patientDetailConfirmBreakawayState);
          reset(patientDetailDialogState);
          reset(patientDetailDialogMessageState);
          reset(patientDetailDialogTracingreportState);
          reset(eventPageInfoState);
          reset(patientDetailDialogProfileState);
          reset(patientDetailConfirmBreakawayState);
        } else if (action == 'toTracingReport') {
          if (patientId) {
            reset(patientDetailConfirmBreakawayState);
            set(patientDetailDialogMessageState, (_state) => ({
              ..._state,
              selectedMessageId: null,
            }));
            set(patientDetailDialogState, (_state) => ({
              ..._state,
              patientId: patientId,
              isOpen: true,
              type: 'tracingreport' as TabType,
            }));
          }
        }
      },
    [action, patientId],
  );

  return (
    <Modal open={isOpen} size="s" onClose={handleClose}>
      <Modal.Header>
        <Text size="l">編集内容を破棄しますか？</Text>
      </Modal.Header>
      <Modal.Body>
        <Text size="m">
          このメッセージは自動保存が無効です。
          <br />
          保存前に画面を移動すると変更内容は破棄されます。
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" onClick={handleConfirm}>
          破棄
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
