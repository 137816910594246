import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useGetOrganizationNotificationSettingQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import {
  notificationSettingsState,
  notificationState,
  telemedicineAppointmentsState,
  uberDeliveryNotificationsState,
} from '~/state/layouts/SharedAppShell/atoms';
import { publicPath } from '~/utils/path';

import { useNotificationSound } from './use-notification-sound';
import { useSubscriptionNotifications } from './use-subscription-notifications';

type Count = {
  followup: number;
  notification: number;
  telemedicine: number;
  uberDelivery: number;
};

const changeFaviconBadge = (count: number) => {
  const canvasLength = 48;
  const favicon = document.querySelector<HTMLLinkElement>('#favicon');
  const image = document.createElement('img');
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context || !favicon || !image) {
    return;
  }

  canvas.width = canvas.height = canvasLength;
  image.crossOrigin = 'Anonymous';
  image.onload = () => {
    const fontSize = 20;
    const text = `${count > 99 ? '99+' : count}`;
    const badgeRadius = 16;
    const badgeCenterPos = canvasLength - badgeRadius;

    context.clearRect(0, 0, 48, 48);
    context.drawImage(image, 0, 0, 48, 48, 0, 0, canvasLength, canvasLength);

    if (count > 0) {
      context.beginPath();
      context.fillStyle = '#ff2020';
      context.arc(badgeCenterPos, badgeCenterPos, badgeRadius, 0, Math.PI * 2, true);
      context.fill();
      context.font = `bold ${fontSize}px Arial, meiryo, sans-serif`;
      context.textAlign = 'center';
      context.fillStyle = '#ffffff';
      context.fillText(text, badgeCenterPos, badgeCenterPos + fontSize / 2 - 2); // -2してるのはうまくセンターにはまらないので微調整したため
    }

    favicon.href = canvas.toDataURL();
  };

  image.src = publicPath('/favicon.ico');
};

const computeNotifications = (
  isLightPlan: boolean,
  settings: { followup: boolean; online: boolean },
  count: Count,
) => {
  let badgeCount = count.notification;
  let unreadCount = count.notification;

  if (settings.online) {
    unreadCount += count.telemedicine;
  }

  if (!isLightPlan) {
    if (settings.followup) {
      unreadCount += count.followup;
    }
    badgeCount = badgeCount + count.followup;
  }

  unreadCount += count.uberDelivery;

  return {
    badgeCount,
    unreadCount,
  };
};

export const useManageNotifications = () => {
  const { appointments } = useRecoilValue(telemedicineAppointmentsState);
  const notificationCountState = useRecoilValue(notificationState);
  const { needPlaySoundNotificationCount } = useRecoilValue(uberDeliveryNotificationsState);
  const [{ isLightPlan, ...settings }, setSettingsState] =
    useRecoilState(notificationSettingsState);
  const setTelemedicineState = useSetRecoilState(telemedicineAppointmentsState);
  const { loading } = useSubscriptionNotifications();
  const { data } = useGetOrganizationNotificationSettingQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (_result) => {
      const isLightPlan = getMe(_result)?.organization.company.contractLightPlan ?? false;
      const settingData = getMe(_result)?.organization.notificationSetting?.data;
      const isFollowupEnabled = !!settingData?.medicationFollowupEnabled;
      const isOnlineEnabled = !!settingData?.onlineEnabled;

      setTelemedicineState((_state) => ({ ..._state, isEnabled: isOnlineEnabled }));
      setSettingsState({ isLightPlan, isFollowupEnabled, isOnlineEnabled });
    },
  });

  const count = useMemo(
    () => ({
      followup: notificationCountState.followupCount,
      notification: notificationCountState.receptionCount,
      telemedicine: appointments.length,
      uberDelivery: needPlaySoundNotificationCount,
    }),
    [
      appointments.length,
      needPlaySoundNotificationCount,
      notificationCountState.followupCount,
      notificationCountState.receptionCount,
    ],
  );

  const sound = data ? getMe(data)?.organization.notificationSetting?.data.sound : undefined;
  const { play } = useNotificationSound(sound);

  const { badgeCount, unreadCount } = useMemo(
    () =>
      computeNotifications(
        isLightPlan,
        {
          followup: settings.isFollowupEnabled,
          online: settings.isOnlineEnabled,
        },
        count,
      ),
    [count, isLightPlan, settings.isFollowupEnabled, settings.isOnlineEnabled],
  );

  useEffect(() => {
    changeFaviconBadge(badgeCount);
  }, [badgeCount]);

  useEffect(() => {
    if (!loading) {
      play(unreadCount);
    }
  }, [loading, play, unreadCount]);
};
