import { useEffect } from 'react';
import { IntercomProps, useIntercom } from 'react-use-intercom';
import { useRecoilValue } from 'recoil';

import { PRACTITIONERS_ROLES } from '~/constants/practitioners';
import { OrganizationContactPoint, useGetPractitionerWithIntercomQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { intercomUnreadCountState } from '~/state/layouts/OrganizationAppShell/atoms';
import { getWorkPhone } from '~/utils/contact_points';

const MIN_WIDTH = 768;
const LEAST_PADDING = 20;
const PADDING = 75; // メニューに重ならないようにするためのパディング

export const useOperateIntercom = () => {
  const { unreadCount } = useRecoilValue(intercomUnreadCountState);
  const { show: openIntercom, update, boot } = useIntercom();
  const existUnderMenu = window.innerWidth <= MIN_WIDTH;

  useGetPractitionerWithIntercomQuery({
    nextFetchPolicy: 'cache-only',
    onCompleted: (data) => {
      const me = getMe(data);
      if (!me) return;

      const {
        intercomFamilyName,
        intercomGivenName,
        intercomUserId,
        intercomUserHash,
        organization,
        roles,
      } = me;
      const companyName = organization?.company.name;
      const organizationTel = getWorkPhone(
        organization?.organizationContactPoints as OrganizationContactPoint[],
      );

      const bootProps: IntercomProps = {
        name: `${intercomFamilyName}${intercomGivenName}`,
        userId: intercomUserId,
        userHash: intercomUserHash ?? undefined,
      };
      if (organization) {
        bootProps.company = {
          companyId: organization.id,
          name: organization.name,
        };
      }
      if (roles || companyName || organizationTel) {
        bootProps.customAttributes = {
          roles: roles.map((roles) => PRACTITIONERS_ROLES[roles]).join(','),
          companyName: companyName,
          organizationTel: organizationTel,
        };
      }
      boot(bootProps);
    },
  });

  useEffect(() => {
    update({
      alignment: existUnderMenu ? 'right' : 'left',
      horizontalPadding: existUnderMenu ? LEAST_PADDING : PADDING,
      verticalPadding: existUnderMenu ? PADDING : LEAST_PADDING,
    });
  }, [update, existUnderMenu]);

  return {
    openIntercom,
    unreadCount,
  };
};
