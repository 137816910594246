import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';

import { Alert, Button, Modal } from '~/components/blocks';
import { toYYYYMMDDja } from '~/utils/date';

type Props = {
  startAt: Date;
  onClosed: () => void;
};

export const AdminPanel = (props: Props) => {
  const router = useRouter();
  const { onClosed } = props;
  const [transitioning, setTransitioning] = useState(false);
  const handleClick = useCallback(async () => {
    setTransitioning(true);
    await router.push('/settings/organization/client_certs');
    onClosed();
  }, [onClosed, router]);

  return (
    <>
      <Modal.Body>
        <Alert status="warning">
          {toYYYYMMDDja(props.startAt)}以降Pharmsをご利用いただくには、
          <br />
          クライアント証明書が必要になります。
          <br />
          証明書を発行し、端末にインストールしてください。
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" onClick={handleClick} loading={transitioning}>
          クライアント証明書発行
        </Button>
      </Modal.Footer>
    </>
  );
};
