import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { compose, space, SpaceProps, variant } from 'styled-system';

type HeaderProps = SpaceProps & {
  as?: React.ElementType;
  attached?: 'paper' | 'box' | 'drawer';
};

type Props = HeaderProps & {
  action?: React.ReactNode;
  children?: React.ReactNode;
};

export const StyledHeader = styled('header', {
  shouldForwardProp,
})<HeaderProps>(
  ({ theme }) =>
    css({
      width: '100%',
      display: 'flex',
      fontSize: theme.fontSizes.l,
      fontWeight: theme.fontWeights.bold,
      alignItems: 'center',
      justifyContent: 'space-between',
      [`@media ${theme.mediaQueries.tablet}`]: {
        fontSize: theme.fontSizes.m,
        flexWrap: 'wrap',
      },
    }),
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'attached',
        variants: {
          paper: {
            padding: `${theme.space.l} ${theme.space.xl}`,
          },
          box: {
            padding: `${theme.space.l} 0`,
          },
          drawer: {
            padding: `${theme.space.l} ${theme.space.xl}`,
            width: 'calc(100% - 45px)',
          },
        },
      }),
    ),
);

const Nav = styled('div')();

const Title = styled('div')(() =>
  css({
    display: 'flex',
    alignItems: 'center',
  }),
);

export const Header: React.FC<Props> = (props) => {
  const { action, children, ...headerProps } = props;

  return (
    <StyledHeader {...headerProps}>
      <Title>{children}</Title>
      {action && <Nav>{action}</Nav>}
    </StyledHeader>
  );
};
