import { useTheme } from '@emotion/react';
import React, { useCallback, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  EntryList,
  Flex,
  Icon,
  Skeleton,
  Text,
  Tooltip,
} from '~/components/blocks';
import {
  GetPatientProfileMedixsPatientDocument,
  GetReceptionPaneAppointmentDocument,
  useUnidentifyMedixsPatientMutation,
} from '~/graphql';
import { Label } from '~/utils/label';

import { useFetchMedixsPatient } from './use-fetch-medixs_patient';

type Props = {
  appointmentId: string | null;
  patientId: string | null;
};

export const ProfileDetailMedixs = React.memo((props: Props) => {
  const { appointmentId, patientId } = props;

  const theme = useTheme();

  const { loading, medixsPatient } = useFetchMedixsPatient(patientId);

  const [error, setError] = useState<Error | null>(null);
  const [unidentifyMedixsPatient, { loading: unidentifying }] = useUnidentifyMedixsPatientMutation({
    refetchQueries: [
      { query: GetReceptionPaneAppointmentDocument, variables: { appointmentId } },
      { query: GetPatientProfileMedixsPatientDocument, variables: { patientId } },
    ],
    onError: (e) => {
      setError(e);
    },
  });
  const handleUnidentify = useCallback(() => {
    if (medixsPatient?.id && appointmentId) {
      unidentifyMedixsPatient({
        variables: {
          input: {
            medixsPatientId: medixsPatient?.id,
          },
        },
      });
    }
  }, [unidentifyMedixsPatient, medixsPatient?.id, appointmentId]);

  return (
    <Box
      backgroundColor={theme.colors.background.bg}
      padding={theme.space.l}
      marginBottom={theme.space.l}
    >
      {loading ? (
        <EntryList size="s">
          <EntryList.Head>レセコン患者コード</EntryList.Head>
          <EntryList.Body>
            <Skeleton width="80px" />
          </EntryList.Body>
          <EntryList.Head>患者名</EntryList.Head>
          <EntryList.Body>
            <Skeleton width="80px" />
          </EntryList.Body>
          <EntryList.Head>生年月日</EntryList.Head>
          <EntryList.Body>
            <Skeleton width="80px" />
          </EntryList.Body>
        </EntryList>
      ) : (
        <>
          {error && (
            <Box padding={theme.space.m}>
              <Alert status="error">連携解除に失敗しました</Alert>
            </Box>
          )}
          <EntryList size="s">
            <EntryList.Head>レセコン患者コード</EntryList.Head>
            <EntryList.Body>
              <Flex alignItems="center" justifyContent="space-between">
                {medixsPatient?.patientName ? (
                  <Text size="l" fontWeight="bold">
                    {medixsPatient.patientCode}
                  </Text>
                ) : (
                  <Text>未連携</Text>
                )}
                {medixsPatient ? (
                  props.appointmentId ? (
                    <Button
                      size="s"
                      marginLeft={theme.space.m}
                      disabled={unidentifying}
                      onClick={handleUnidentify}
                    >
                      <Icon size="m" icon="not-sync" color="blue" />
                      <Text>連携解除</Text>
                    </Button>
                  ) : (
                    <Tooltip content="希望日時確定後に操作できます">
                      <div>
                        <Button size="s" marginLeft={theme.space.m} disabled={true}>
                          <Icon size="m" icon="not-sync" color="blue" />
                          <Text>連携解除</Text>
                        </Button>
                      </div>
                    </Tooltip>
                  )
                ) : null}
              </Flex>
            </EntryList.Body>
          </EntryList>
          <EntryList size="s">
            <EntryList.Head>患者名</EntryList.Head>
            <EntryList.Body>
              {medixsPatient?.patientName ? (
                <>
                  <Text fontWeight="bold">{medixsPatient.patientName}</Text>
                  <Text size="xs" fontWeight="normal">
                    （{medixsPatient.patientNameKana}）
                  </Text>
                </>
              ) : (
                '未連携'
              )}
            </EntryList.Body>
          </EntryList>
          <EntryList size="s">
            <EntryList.Head>生年月日</EntryList.Head>
            <EntryList.Body>
              {medixsPatient?.birthDate ? (
                <Text size="m" fontWeight="bold">
                  {Label.YYYYMMDDja(medixsPatient.birthDate)}
                </Text>
              ) : (
                '未連携'
              )}
            </EntryList.Body>
          </EntryList>
        </>
      )}
    </Box>
  );
});

ProfileDetailMedixs.displayName = 'ProfileDetailMedixs';
