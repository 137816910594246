import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { CSSProperties } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { compose, layout, LayoutProps, variant } from 'styled-system';

import { Size } from '~/styles/types';

import { CloseBtn } from '../CloseBtn';
import { Overlay } from '../Overlay';

const Footer = styled('div')<{ both?: boolean }>(({ theme, both }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: both ? 'space-between' : 'flex-end',
    color: theme.colors.text.white,
    marginTop: theme.space.l,
  }),
);

const Body = styled('div', { shouldForwardProp })<LayoutProps>(
  ({ theme }) =>
    css({
      flex: 1,
      position: 'relative',
      height: '100%',
      marginTop: theme.space.l,
      overflow: 'auto',
    }),
  () => compose(layout),
);

const Header = styled('header')(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
}));

const Close = styled(CloseBtn)(({ theme }) =>
  css({
    position: 'absolute',
    top: theme.space.l,
    right: theme.space.l,
  }),
);

const Root = styled('div', {
  shouldForwardProp,
})<{ size?: Size }>(
  ({ theme }) =>
    css({
      position: 'relative',
      display: 'flex',
      maxHeight: '90vh',
      flexDirection: 'column',
      backgroundColor: theme.colors.background.default,
      padding: theme.space.l,
      borderRadius: theme.radii.modal,
      boxShadow: theme.shadows.default,
      transition: `opacity ${theme.transitions.fast} ease-in-out`,
      opacity: 0,
      overflow: 'auto',
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          xs: css({
            width: theme.modals.xs,
            margin: '0 auto',
          }),
          s: css({
            width: theme.modals.s,
            margin: '0 auto',
          }),
          m: css({
            width: theme.modals.m,
            margin: '0 auto',
          }),
          l: css({
            width: theme.modals.l,
            margin: '0 auto',
          }),
          xl: css({
            width: theme.modals.xl,
            margin: '0 auto',
          }),
        },
      }),
    ),
);

const Wrapper = styled('div')(() =>
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

type Props = {
  open: boolean;
  size?: Size;
  onClose?: () => void;
  children?: React.ReactNode;
};

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};

const RootComponent: React.FC<Props> = (props) => {
  const { onClose } = props;
  return (
    <Transition in={props.open} timeout={0}>
      {(state) => (
        <Overlay open={props.open}>
          <Wrapper>
            <Root
              size={props.size}
              style={{
                ...transitionStyles[state],
              }}
            >
              {props.children} {onClose && <Close onClick={onClose} />}
            </Root>
          </Wrapper>
        </Overlay>
      )}
    </Transition>
  );
};

export const Modal = Object.assign(RootComponent, { Header, Body, Footer });
