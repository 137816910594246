import { useCallback, useState } from 'react';

import {
  GetReceptionPaneAppointmentDocument,
  useUnidentifyMedixsReceptionMutation,
} from '~/graphql';

export const useUnidentifyMedixsReception = (
  appointmentId: string,
  medixsReceptionId: string | undefined,
) => {
  const [isUnidentifying, setIsUnidentifying] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [unidentifyMedixsReception] = useUnidentifyMedixsReceptionMutation({
    refetchQueries: [{ query: GetReceptionPaneAppointmentDocument, variables: { appointmentId } }],
  });

  const handleUnidentify = useCallback(async () => {
    if (medixsReceptionId) {
      try {
        setIsUnidentifying(true);
        await unidentifyMedixsReception({
          variables: {
            input: {
              medixsReceptionId,
            },
          },
        });
      } catch (_error) {
        setError(_error?.message || 'エラーが発生しました');
      } finally {
        setIsUnidentifying(false);
      }
    }
  }, [medixsReceptionId, unidentifyMedixsReception]);

  return { unidentifying: isUnidentifying, error, handleUnidentify };
};
