import { useGetTemplatesQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 100; // 1回のリクエストで取得するため

export const useFetchTemplates = () => {
  const { data } = useGetTemplatesQuery({
    fetchPolicy: 'network-only',
    variables: {
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    },
  });

  const organization = data ? getMe(data)?.organization : undefined;
  const templates = organization?.medicationFollowupMessageTemplates.nodes || [];

  return { templates, organizationName: organization?.name };
};
