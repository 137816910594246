import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { InputActionMeta } from 'react-select';

import {
  Counter,
  EditableSelect,
  EntryList,
  Flex,
  InputDate,
  Text,
  Textarea,
} from '~/components/blocks';
import { OptionType } from '~/components/pages/Company_Security_Logs/OrganizationFilter';

import { MedicineNameForm } from '../MedicineNameForm';
import { ReportField } from '../ReportField';
import { SelectPharmacist } from '../SelectPharmacist';
import { Fields } from '../types';
import { MAX_SUGGESTION_LENGTH } from '../validation';

type Props = {
  disabled: boolean;
  isClearForm: boolean;
  clinicNames: string[];
  clinicalDepartments: string[];
  doctorNames: string[];
  formik: FormikProps<Fields>;
  setSelectedClinicName: React.Dispatch<React.SetStateAction<string>>;
  setIsAutoSaved: React.Dispatch<React.SetStateAction<boolean>>;
  onAutoSave: (values: Fields) => void;
};
const TODAY = new Date();

const DateWrapper = styled(Flex)<{ error?: boolean }>(({ theme, error }) =>
  css({
    background: error ? theme.colors.background.alert : undefined,
  }),
);

const ColumnEntryList = styled(EntryList)<{ fullWidth?: boolean }>(({ theme, fullWidth }) =>
  css({
    width: fullWidth ? '100%' : undefined,
    marginRight: theme.space.xl,
    '& + &': {
      marginTop: 0,
      marginRight: theme.space.xl,
    },
    '&:last-child': {
      marginRight: 0,
    },
  }),
);

export const InputPane = React.memo((props: Props) => {
  const {
    disabled,
    isClearForm,
    formik,
    clinicNames,
    clinicalDepartments,
    doctorNames,
    setSelectedClinicName,
    onAutoSave,
    setIsAutoSaved,
  } = props;
  const theme = useTheme();

  const autoSaveProcessRef = useRef(0);
  const handleAutoSave = useCallback(() => {
    // 本文を1文字入力する度に自動保存APIへのリクエストが発行されるのを防ぐためにクリアする
    clearTimeout(autoSaveProcessRef.current);

    setIsAutoSaved(false);

    autoSaveProcessRef.current = window.setTimeout(() => {
      onAutoSave(formik.values);
      setIsAutoSaved(true);
    }, 500);
  }, [formik.values, onAutoSave, setIsAutoSaved]);

  const handleChangeIssueDate = useCallback((date: Date | null, formik: FormikProps<Fields>) => {
    formik.setFieldValue('prescriptionIssueDate', date);
  }, []);

  const handleChangeDispensingDate = useCallback(
    (date: Date | null, formik: FormikProps<Fields>) => {
      formik.setFieldValue('dispensingDate', date);
    },
    [],
  );

  const handleClinicNameChoice = useCallback(
    (option: OptionType, formik: FormikProps<Fields>) => {
      const clinicName = option?.value;
      setSelectedClinicName(clinicName);
      formik.setFieldValue('clinicName', clinicName);
    },
    [setSelectedClinicName],
  );

  const handleClinicalDepartmentChoice = useCallback(
    (option: OptionType, formik: FormikProps<Fields>) => {
      const clinicalDepartment = option?.value;
      formik.setFieldValue('clinicalDepartment', clinicalDepartment);
    },
    [],
  );

  const handleDoctorNameChoice = useCallback((option: OptionType, formik: FormikProps<Fields>) => {
    const doctorName = option?.value;
    formik.setFieldValue('doctorName', doctorName);
  }, []);

  const handleClinicNameBlur = useCallback(
    (e: React.FocusEvent<HTMLElement>, formik: FormikProps<Fields>) => {
      const clinicName = (e.currentTarget as HTMLInputElement)?.value;
      if (clinicName) {
        setSelectedClinicName(clinicName);
        formik.setFieldValue('clinicName', clinicName);
      }
    },
    [setSelectedClinicName],
  );

  const handleClinicalDepartmentBlur = useCallback(
    (e: React.FocusEvent<HTMLElement>, formik: FormikProps<Fields>) => {
      const clinicalDepartment = (e.currentTarget as HTMLInputElement)?.value;
      if (clinicalDepartment) {
        formik.setFieldValue('clinicalDepartment', clinicalDepartment);
      }
    },
    [],
  );

  const handleDoctorNameBlur = useCallback(
    (e: React.FocusEvent<HTMLElement>, formik: FormikProps<Fields>) => {
      const doctorName = (e.currentTarget as HTMLInputElement)?.value;
      if (doctorName) {
        formik.setFieldValue('doctorName', doctorName);
      }
    },
    [],
  );

  const handleMedicineNameChange = useCallback((values: string[], formik: FormikProps<Fields>) => {
    formik.setFieldValue('prescriptionDrugs', values);
  }, []);

  const handleClinicNameInputChange = useCallback(
    (inputValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta?.action == 'input-change') {
        formik.setFieldValue('clinicName', inputValue);
      }
    },
    [formik],
  );
  const handleClinicalDepartmentInputChange = useCallback(
    (inputValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta?.action == 'input-change') {
        formik.setFieldValue('clinicalDepartment', inputValue);
      }
    },
    [formik],
  );
  const handleDoctorNameInputChange = useCallback(
    (inputValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta?.action == 'input-change') {
        formik.setFieldValue('doctorName', inputValue);
      }
    },
    [formik],
  );

  useEffect(() => {
    if (!formik.dirty) return;
    if (isClearForm) return;

    handleAutoSave();
  }, [formik.dirty, isClearForm, handleAutoSave]);

  return (
    <>
      <EntryList>
        <EntryList.Head>医療機関名</EntryList.Head>
        <EntryList.Body>
          <EditableSelect
            name="clinicName"
            menuPlacement="bottom"
            noOptionsMessage={() => '選択できる項目がありません'}
            formatCreateLabel={(newValue) => newValue}
            placeholder="例：メドレークリニック"
            options={clinicNames.map((v) => ({ value: v, label: v }))}
            onChange={(option) => handleClinicNameChoice(option, formik)}
            onBlur={(e) => handleClinicNameBlur(e, formik)}
            onInputChange={handleClinicNameInputChange}
            isLoading={props.disabled}
            isDisabled={props.disabled}
            value={
              formik.values.clinicName
                ? [
                    {
                      value: formik.values.clinicName,
                      label: formik.values.clinicName,
                    },
                  ]
                : null
            }
            errors={formik.errors.clinicName}
            isClearable
          />
        </EntryList.Body>
      </EntryList>
      <Flex alignItems="flex-start" marginTop={theme.space.m}>
        <ColumnEntryList fullWidth>
          <EntryList.Head>診療科</EntryList.Head>
          <EntryList.Body>
            <EditableSelect
              name="clinicalDepartment"
              menuPlacement="bottom"
              noOptionsMessage={() => '選択できる項目がありません'}
              formatCreateLabel={(newValue) => newValue}
              placeholder="例：外科・消化器科・乳腺外科"
              options={clinicalDepartments.map((v) => ({ value: v, label: v }))}
              onChange={(option) => handleClinicalDepartmentChoice(option, formik)}
              onBlur={(e) => handleClinicalDepartmentBlur(e, formik)}
              onInputChange={handleClinicalDepartmentInputChange}
              isLoading={props.disabled}
              isDisabled={props.disabled}
              value={
                formik.values.clinicalDepartment
                  ? [
                      {
                        value: formik.values.clinicalDepartment,
                        label: formik.values.clinicalDepartment,
                      },
                    ]
                  : null
              }
              isClearable
            />
          </EntryList.Body>
        </ColumnEntryList>
        <ColumnEntryList fullWidth>
          <EntryList.Head>担当医師名</EntryList.Head>
          <EntryList.Body>
            <EditableSelect
              name="doctorName"
              menuPlacement="bottom"
              noOptionsMessage={() => '選択できる項目がありません'}
              formatCreateLabel={(newValue) => newValue}
              placeholder="例：〇〇先生、〇〇御侍史"
              options={doctorNames.map((v) => ({ value: v, label: v }))}
              onChange={(option) => handleDoctorNameChoice(option, formik)}
              onBlur={(e) => handleDoctorNameBlur(e, formik)}
              onInputChange={handleDoctorNameInputChange}
              isLoading={props.disabled}
              isDisabled={props.disabled}
              value={
                formik.values.doctorName
                  ? [
                      {
                        value: formik.values.doctorName,
                        label: formik.values.doctorName,
                      },
                    ]
                  : null
              }
              isClearable
            />
          </EntryList.Body>
        </ColumnEntryList>
      </Flex>
      <Flex alignItems="center" marginY={theme.space.l} flexWrap="wrap">
        <ColumnEntryList>
          <EntryList.Head>処方箋発行日</EntryList.Head>
          <EntryList.Body>
            <InputDate
              clearable={false}
              maxDate={TODAY}
              disabled={disabled}
              value={formik.values.prescriptionIssueDate}
              onChange={(date) => handleChangeIssueDate(date, formik)}
            />
          </EntryList.Body>
        </ColumnEntryList>
        <ColumnEntryList>
          <EntryList.Head>調剤日</EntryList.Head>
          <EntryList.Body>
            <DateWrapper error={!!formik.errors.dispensingDate}>
              <InputDate
                clearable={false}
                maxDate={TODAY}
                disabled={disabled}
                value={formik.values.dispensingDate}
                onChange={(date) => handleChangeDispensingDate(date, formik)}
              />
            </DateWrapper>
          </EntryList.Body>
        </ColumnEntryList>
        <ColumnEntryList>
          <EntryList.Head>担当薬剤師</EntryList.Head>
          <EntryList.Body>
            <SelectPharmacist
              name="pharmacistName"
              disabled={disabled}
              error={formik.errors.pharmacistName}
              value={formik.values.pharmacistName}
              onChange={formik.handleChange}
            />
          </EntryList.Body>
        </ColumnEntryList>
      </Flex>
      <EntryList>
        <EntryList.Head>
          対象薬剤
          <Text marginLeft={theme.space.xl} color="grey">
            ※ 該当薬剤が見つからない場合は、正確な薬剤名を入力してください
          </Text>
        </EntryList.Head>
        <EntryList.Body>
          <MedicineNameForm
            disabled={disabled}
            errors={formik.errors.prescriptionDrugs}
            values={formik.values.prescriptionDrugs ?? []}
            onChange={(values) => handleMedicineNameChange(values, formik)}
          />
        </EntryList.Body>
      </EntryList>
      <EntryList>
        <EntryList.Head>報告事項</EntryList.Head>
        <EntryList.Body>
          <ReportField
            disabled={disabled}
            formik={formik}
            error={formik.errors.report}
            value={formik.values.report}
          />
        </EntryList.Body>
      </EntryList>
      <EntryList>
        <EntryList.Head>提案事項</EntryList.Head>
        <EntryList.Body>
          <Textarea
            rows={5}
            name="suggestion"
            disabled={disabled}
            error={formik.errors.suggestion}
            value={formik.values.suggestion}
            onChange={formik.handleChange}
          />
          <Counter value={formik.values.suggestion || ''} max={MAX_SUGGESTION_LENGTH} />
        </EntryList.Body>
      </EntryList>
    </>
  );
});

InputPane.displayName = 'InputPane';
