import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Button, EntryList, Modal, Text } from '~/components/blocks';
import { notificationCongestionModalState } from '~/state/partials/patient_profile_drawer/atoms';

import { useFetchPatientName } from './use-fetch-patient_name';
import { useSendCongestionNotification } from './use-send-congestion-notification';

const Message = styled('header')(({ theme }) =>
  css({
    whiteSpace: 'pre-wrap',
    background: theme.colors.background.bg,
    padding: theme.space.l,
  }),
);

const content = `現在薬局が混み合っており、お薬の用意に時間がかかっています。申し訳ありませんが、薬局からの連絡を今しばらくお待ちください。`;

export const NotificationCongestionModal = () => {
  const theme = useTheme();
  const state = useRecoilValue(notificationCongestionModalState);

  const { loading, patientName } = useFetchPatientName();
  const { sending, error, handleSend } = useSendCongestionNotification();

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(notificationCongestionModalState);
      },
    [],
  );

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>混雑時通知を送信</Modal.Header>
      <Modal.Body>
        {error && <Alert mb={theme.space.l}>エラーが発生したため送信できませんでした</Alert>}
        <Text>
          メッセージ内容を確認のうえ、「送信」ボタンをクリックしてください。本メッセージは1回まで送信できます。
        </Text>
        <EntryList mt={theme.space.m}>
          <EntryList.Head>送信メッセージ</EntryList.Head>
          <EntryList.Body>
            <Message>
              {patientName}様
              <br />
              <br />
              {content}
            </Message>
          </EntryList.Body>
        </EntryList>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" disabled={loading} loading={sending} onClick={() => handleSend()}>
          送信
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
