import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Button, Flex, Icon, Text } from '~/components/blocks';
import { FollowupQuestionnaireSheetEventOnPatientDetailDialogFragment } from '~/graphql';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';
import { Label } from '~/utils/label';

import { ClipButton } from '../../ClipButton';
import { buildText } from '../sheet';
import { FollowupQuestionnaireSheetEvent } from './Events/FollowupQuestionnaireSheetEvent';

type Props = {
  readonly: boolean;
  selected: boolean;
  sheet: FollowupQuestionnaireSheetEventOnPatientDetailDialogFragment;
  onClick: (answer: string, type?: TabType) => void;
};

export const QuestionnaireSheet = React.memo((props: Props) => {
  const { sheet, onClick } = props;
  const [state, setState] = useRecoilState(patientDetailDialogState);
  const answeredAt = Label.YYYYMMDDja(sheet.createdAt);

  const handleClick = useCallback(() => {
    const answer = buildText(answeredAt, sheet.answers);
    if (state.type === 'tracingreport') {
      onClick(answer);
    } else {
      setState((_state) => ({ ..._state, type: 'tracingreport' }));
    }
  }, [answeredAt, onClick, sheet.answers, state.type, setState]);

  const clipboardText = buildText(
    `${Label.YYYYMMDDja(sheet.createdAt)} ${Label.HHMM(sheet.createdAt)}`,
    sheet.answers,
  );

  return (
    <FollowupQuestionnaireSheetEvent defaultOpen={props.selected} sheet={sheet}>
      {!props.readonly &&
        (state.type === 'tracingreport' ? (
          <Button wide="fill" onClick={handleClick}>
            <Icon icon="edit" size="m" />
            <Text size="xs">トレーシングレポートに反映</Text>
          </Button>
        ) : (
          <Flex flexDirection={'column'}>
            <ClipButton iconSize="m" textSize="xs" wide="fill" text={clipboardText} />
            <Button wide="fill" marginTop="m" onClick={handleClick}>
              <Icon icon="edit" size="m" />
              <Text size="xs">トレーシングレポート作成</Text>
            </Button>
          </Flex>
        ))}
    </FollowupQuestionnaireSheetEvent>
  );
});

QuestionnaireSheet.displayName = 'QuestionnaireSheet';
