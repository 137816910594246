import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Alert, Box, Flex, Icon, Text, Tooltip } from '~/components/blocks';
import { AppointmentStatus, ReceptionPaneMedixsReceptionFragment } from '~/graphql';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';

import { UnidentifyButton } from './UnidentifyButton';
import { useUnidentifyMedixsReception } from './use-unidentify-medixs_reception';

type Props = {
  appointmentId: string;
  appointmentStatus: AppointmentStatus;
  medixsRecpetion?: ReceptionPaneMedixsReceptionFragment | null;
};

const Wrap = styled('div')(({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: theme.colors.background.bg,
    padding: `${theme.space.s} ${theme.space.l}`,
    borderRadius: theme.radii.half,
  }),
);

export const MedixsReceptionId = React.memo((props: Props) => {
  const { appointmentStatus, medixsRecpetion } = props;

  const theme = useTheme();

  const { enabledMedixs } = useMedixsSetting();
  const { unidentifying, error, handleUnidentify } = useUnidentifyMedixsReception(
    props.appointmentId,
    medixsRecpetion?.id,
  );

  const receptionId = medixsRecpetion?.receptionId;
  const rescinded =
    appointmentStatus === AppointmentStatus.Finished ||
    appointmentStatus === AppointmentStatus.Cancelled ||
    appointmentStatus === AppointmentStatus.Noshow;

  if (!enabledMedixs) {
    return null;
  }

  return (
    <>
      {error && (
        <Box padding={theme.space.m}>
          <Alert status="error">連携解除に失敗しました</Alert>
        </Box>
      )}
      {receptionId ? (
        <Flex alignItems="center">
          <Wrap>
            <Text size="xs" fontWeight="bold">
              受付ID：
            </Text>
            <Text size="m" fontWeight="bold">
              {receptionId}
            </Text>
          </Wrap>
          {rescinded ? (
            <UnidentifyButton
              handleUnidentify={handleUnidentify}
              rescinded={rescinded}
              unidentifying={unidentifying}
            />
          ) : (
            <Tooltip content={'完了またはキャンセルするまで解除できません'}>
              <div>
                <UnidentifyButton
                  handleUnidentify={handleUnidentify}
                  rescinded={rescinded}
                  unidentifying={unidentifying}
                />
              </div>
            </Tooltip>
          )}
        </Flex>
      ) : (
        <Wrap>
          <Text size="xs" fontWeight="bold">
            受付ID：
          </Text>
          <Icon icon="attention" size="m" />
          <Text color="pink" fontWeight="bold" size="s">
            未連携
          </Text>
        </Wrap>
      )}
    </>
  );
});

MedixsReceptionId.displayName = 'MedixsReceptionId';
