import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Flex } from '~/components/blocks';
import { theme } from '~/styles/theme';

const ArrowBox1 = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.primary,
    height: '8px',
    width: '5px',
    opacity: '0.5',
  }),
);

const ArrowBox2 = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.primary,
    marginLeft: theme.space.xs,
    height: '8px',
    width: '5px',
  }),
);

const ArrowBox3 = styled('div')(({ theme }) =>
  css({
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.space.xs,
    '&:before': {
      content: '""',
      width: '8px',
      height: '8px',
      background: theme.colors.background.primary,
    },
    '&:after': {
      content: '""',
      border: '8px solid transparent',
      borderLeftColor: theme.colors.background.primary,
    },
  }),
);

export const Arrow = () => {
  return (
    <Flex alignItems="center" marginLeft={theme.space.m}>
      <ArrowBox1 />
      <ArrowBox2 />
      <ArrowBox3 />
    </Flex>
  );
};
