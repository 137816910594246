import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Button, Chip, Flex, Icon, Name } from '~/components/blocks';
import { BasePatientProfileFragment } from '~/graphql';
import { Label } from '~/utils/label';

import { Detail } from './Detail';
import { DetailSkeleton } from './DetailSkeleton';

type Props = {
  expanded: boolean;
  patient?: BasePatientProfileFragment | null;
  // FIXME: 以前はTypeがexportされていたの使用することができたが、消えてしまったのでanyにしているが追加されたら戻す
  // https://github.com/roginfarrer/collapsed/commit/1ee93e8#diff-c54113cf61ec99691748a3890bfbeb00e10efb3f0a76f03a0fd9ec49072e410aL24
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onGetToggleProps: any;
};

const InactiveChip = styled(Chip)(({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    border: theme.borders.secondary,
    color: theme.colors.text.secondary,
    fontSize: theme.fontSizes.s,
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.xs,
    },
  }),
);

const ShadedWrapper = styled('div')(() =>
  css({
    display: 'inline-block',
    position: 'relative',
  }),
);

const Shaded = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.secondary,
    top: `calc(${theme.space.l} - ${theme.space.s})`,
    width: theme.space.l,
    height: theme.space.xs,
    position: 'absolute',
    transform: `rotate(-45deg)`,
  }),
);

export const ProfileDetail = React.memo((props: Props) => {
  const { patient } = props;

  return !patient ? (
    <DetailSkeleton />
  ) : (
    <>
      <Flex justifyContent="space-between">
        <Name
          familyName={patient.familyName || ''}
          givenName={patient.givenName || ''}
          phoneticFamilyName={patient.phoneticFamilyName || ''}
          phoneticGivenName={patient.phoneticGivenName || ''}
          size="l"
        />
      </Flex>
      <Box>
        <Detail>
          <Detail.Item>{Label.age(patient.birthDate)}</Detail.Item>
          <Detail.Item>（{Label.sex(patient.sex)}）</Detail.Item>
          <Detail.Item>
            {!patient.active && (
              <InactiveChip color="lightPink">
                <ShadedWrapper>
                  <Icon size="m" icon="smartphone" color="pink" />
                  <Shaded />
                </ShadedWrapper>
                退会済み
              </InactiveChip>
            )}
            {patient.active && (
              <Button size="ms" {...props.onGetToggleProps()}>
                <Icon size="s" icon="people" />
                {props.expanded ? '閉じる' : '詳細'}
              </Button>
            )}
          </Detail.Item>
        </Detail>
      </Box>
    </>
  );
});

ProfileDetail.displayName = 'ProfileDetail';
