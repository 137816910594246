import { useTheme } from '@emotion/react';
import { Formik, FormikProps } from 'formik';
import React, { forwardRef } from 'react';

import { Box, Button, EntryList, FieldError, Flex, TextField } from '~/components/blocks';

import { Fields } from './types';
import { validationSchema } from './validation';

type Props = {
  initialValues: Fields;
  sending: boolean;
  onSubmit: (values: Fields) => void;
};

export const MailSenderForm = forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const theme = useTheme();

  const { sending } = props;

  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <EntryList>
            <EntryList.Head></EntryList.Head>
            <EntryList.Body>
              <Flex>
                <Box marginRight={theme.space.m} width="100%">
                  <TextField
                    name="email"
                    type="email"
                    disabled={sending}
                    error={!!formik.errors.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    placeholder="例：info@example.com"
                  />
                </Box>
                <Button type="submit" use="base" loading={sending}>
                  送信
                </Button>
              </Flex>
              {formik.errors.email && <FieldError error={formik.errors.email} fontSize="s" />}
            </EntryList.Body>
          </EntryList>
        </form>
      )}
    </Formik>
  );
});

MailSenderForm.displayName = 'MailSenderForm';
