import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, space, SpaceProps, variant } from 'styled-system';

import { publicPath } from '~/utils/path';

type Props = SpaceProps & {
  fill?: boolean;
  error?: string;
};

export const Select = styled('select', {
  shouldForwardProp: (propName: string) => shouldForwardProp(propName) && propName !== 'fill',
})<Props>(
  ({ theme }) =>
    () =>
      css({
        margin: `0 ${theme.space.xs}`,
        padding: `${theme.space.m} ${theme.space.xl} ${theme.space.m} ${theme.space.m}`,
        borderRadius: theme.radii.default,
        border: theme.borders.default,
        verticalAlign: 'middle',
        transitionDuration: theme.transitions.fast,
        cursor: 'pointer',
        appearance: 'none',
        backgroundImage: `url(${publicPath('/icon-arrow-down.svg')})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100%',
        backgroundSize: '16px',
        color: theme.colors.text.default,
        '&:hover': {
          border: theme.borders.primary,
        },
        '&:focus': {
          border: theme.borders.primary,
        },
        '&:disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'none',
          '&:hover': {
            border: theme.borders.default,
          },
        },
      }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          s: css({
            fontSize: theme.fontSizes.s,
          }),
        },
      }),
    ),
  ({ fill }) =>
    fill &&
    css({
      width: '100%',
    }),
  ({ theme, error }) =>
    error &&
    css({
      backgroundColor: theme.colors.background.alert,
      color: theme.colors.text.error,
      '&::placeholder': {
        color: theme.colors.text.placeholder,
      },
      '&:hover': {
        boxShadow: 0,
        border: theme.borders.error,
      },
      '&:focus': {
        boxShadow: 0,
        border: theme.borders.error,
        backgroundColor: theme.colors.background.default,
      },
    }),
  () => compose(space),
);
