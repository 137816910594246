import React, { ComponentProps, useCallback, useMemo } from 'react';

import { getTimeArray } from '~/utils/time';

import { Select } from '../Select';

type Props = Omit<ComponentProps<typeof Select>, 'onChange'> & {
  timeIntervals?: number;
  min?: string;
  max?: string;
  without24Hour?: boolean;
  onChange: (time: string) => void;
};

export const InputTime = React.memo((props: Props) => {
  const { min, max, timeIntervals = 30, without24Hour = false, onChange, ...selectProps } = props;
  const options = useMemo(() => {
    const times = getTimeArray(timeIntervals, without24Hour);

    const _options: { time: string; disabled: boolean }[] = [];

    for (const time of times) {
      _options.push({
        time,
        disabled: (min != null && time < min) || (max != null && max < time),
      });
    }

    return _options;
  }, [max, min, timeIntervals, without24Hour]);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value as string;
      onChange(value);
    },
    [onChange],
  );

  return (
    <Select {...selectProps} onChange={handleChange}>
      <option hidden></option>
      {options.map((option) => (
        <option key={option.time} disabled={option.disabled}>
          {option.time}
        </option>
      ))}
    </Select>
  );
});

InputTime.displayName = 'InputTime';
