import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Button, Flex, Icon, List, Tag, Text } from '~/components/blocks';
import { BASE_DATE } from '~/constants/organization_notification';
import {
  GetAppointmentsNotificationListDocument,
  GetUnreadAppointmentsNotificationsDocument,
  OrganizationNotificationFragment,
  useMarkOrganizationNotificationMutation,
} from '~/graphql';
import { notificationPanelState } from '~/state/layouts/SharedAppShell/atoms';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  guestPatientProfileDrawerState,
  newReceptionDrawerState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { theme } from '~/styles/theme';
import { Label } from '~/utils/label';

type Props = {
  notification: OrganizationNotificationFragment;
};

const New = styled(Tag)(({ theme }) =>
  css({
    color: theme.colors.text.white,
    background: theme.colors.background.secondary,
    border: theme.borders.error,
    padding: `${theme.space.xs} ${theme.space.s}`,
    fontSize: theme.fontSizes.xs,
    lineHeight: 1,
  }),
);

const CustomListItem = styled(List.Item)<{ loading: boolean }>(({ loading }) =>
  loading
    ? css({
        // 素早く連続でクリックすると二重でリクエストを送れてしまうため、既読処理中はクリックを無効化する
        pointerEvents: 'none',
      })
    : css({
        cursor: 'pointer',
      }),
);

export const OrganizationNotification = (props: Props) => {
  const { notification } = props;
  const [markNotification, { loading }] = useMarkOrganizationNotificationMutation({
    refetchQueries: [
      { query: GetAppointmentsNotificationListDocument },
      { query: GetUnreadAppointmentsNotificationsDocument, variables: { from: BASE_DATE } },
    ],
  });

  const handleClick = useCallback(async () => {
    if (!notification.isRead) {
      await markNotification({
        variables: {
          input: {
            organizationNotificationId: notification.id,
          },
        },
      });
    }
  }, [markNotification, notification.id, notification.isRead]);
  const router = useRouter();

  const handleOpenReception = useRecoilCallback(
    ({ set, reset }) =>
      async (e: React.MouseEvent<HTMLButtonElement>) => {
        // 受付ドロアーで既読処理をするため、親要素で既読処理が実行されないようにする
        e.stopPropagation();

        if (router.pathname !== '/reception') {
          await router.push('/reception');
        }

        // 受付通知パネルを閉じる
        set(notificationPanelState, (_state) => ({ ..._state, isOpen: false }));

        if (notification.appointment?.patient) {
          set(profileDrawerState, {
            isOpen: true,
            appointmentId: notification.appointment.id,
            patientId: notification.appointment.patient.id,
            error: null,
          });
          reset(webVisitorProfileDrawerState);
          reset(guestPatientProfileDrawerState);
          reset(newReceptionDrawerState);
          reset(checkinEntryProfileDrawerState);
          reset(draftAppointmentPatientProfileDrawerState);
        }

        if (notification.appointment?.webVisitor) {
          set(webVisitorProfileDrawerState, {
            isOpen: true,
            appointmentId: notification.appointment.id,
            webVisitorId: notification.appointment.webVisitor.id,
          });
          reset(profileDrawerState);
          reset(guestPatientProfileDrawerState);
          reset(newReceptionDrawerState);
          reset(checkinEntryProfileDrawerState);
          reset(draftAppointmentPatientProfileDrawerState);
        }

        if (notification.checkinEntry) {
          set(checkinEntryProfileDrawerState, {
            isOpen: true,
            checkinEntryId: notification.checkinEntry.id,
          });
          reset(profileDrawerState);
          reset(guestPatientProfileDrawerState);
          reset(newReceptionDrawerState);
          reset(webVisitorProfileDrawerState);
          reset(draftAppointmentPatientProfileDrawerState);
        }

        if (notification.draftAppointment) {
          const patientId = notification.draftAppointment.patient.id;
          const draftAppointment = notification.draftAppointment;
          const appointmentId = draftAppointment.appointment?.id;

          // 本予約済みの場合は、本予約のドロアーを表示する
          if (appointmentId) {
            set(profileDrawerState, {
              isOpen: true,
              appointmentId,
              patientId,
              error: null,
            });
            reset(draftAppointmentPatientProfileDrawerState);
          } else {
            set(draftAppointmentPatientProfileDrawerState, {
              isOpen: true,
              draftAppointmentId: draftAppointment.id,
              patientId,
              error: null,
            });
            reset(profileDrawerState);
          }

          reset(webVisitorProfileDrawerState);
          reset(guestPatientProfileDrawerState);
          reset(newReceptionDrawerState);
          reset(checkinEntryProfileDrawerState);
        }
      },
    [
      notification.appointment?.id,
      notification.appointment?.patient,
      notification.appointment?.webVisitor,
      notification.draftAppointment,
      notification.checkinEntry,
      router,
    ],
  );

  return (
    <CustomListItem loading={loading} onClick={handleClick}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text size="xs" color="grey01">
          {`${Label.YYYYMMDDja(notification.updatedAt)} ${Label.HHMM(notification.updatedAt)}`}
        </Text>
        <Flex alignItems="center">
          <Box marginLeft={theme.space.s}>{!notification.isRead && <New size="s">NEW</New>}</Box>
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontWeight="bold" size="s">
          {notification.subject}
        </Text>
        {(notification.appointment ||
          notification.checkinEntry ||
          notification.draftAppointment) && (
          <Button size="xs" marginLeft={theme.space.m} onClick={handleOpenReception}>
            <Icon size="l" icon="insurance" />
            <Text size="xs">表示</Text>
          </Button>
        )}
      </Flex>
    </CustomListItem>
  );
};
