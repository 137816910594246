import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { compose, margin, MarginProps } from 'styled-system';

export const Divider = styled('hr', {
  shouldForwardProp,
})<MarginProps>(
  ({ theme }) =>
    css({
      width: '100%',
      margin: `${theme.space.l} 0`,
      border: 'transparent',
      borderTop: `1px solid ${theme.colors.border.default}`,
    }),
  () => compose(margin),
);
