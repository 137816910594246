import { useTheme } from '@emotion/react';
import React from 'react';

import { publicPath } from '~/utils/path';

import { Box } from '../Box';
import { Flex } from '../Flex';
import { Overlay } from '../Overlay';

type Props = {
  open: boolean;
  appearance?: 'color' | 'white';
  message?: React.ReactNode;
  inside?: boolean;
  transparent?: boolean;
  logoSize?: 'half' | 'full';
};

export const Loader = (props: Props) => {
  const theme = useTheme();
  const logoSrc =
    props.appearance === 'white'
      ? publicPath('/loader_line_grey.svg')
      : publicPath('/loader_logo.svg');
  const width = props.logoSize === 'half' ? 80 : 160;

  return (
    <Overlay
      open={props.open}
      inside={props.inside}
      transparent={props.transparent === undefined ? true : props.transparent}
    >
      <Flex height="100%" flexDirection="column" alignItems="center" justifyContent="center">
        <img src={logoSrc} width={width} height="auto" />
        {props.message && <Box mt={theme.space.l}>{props.message}</Box>}
      </Flex>
    </Overlay>
  );
};
