import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';

import { Box } from '~/components/blocks';
import { PrescriptionRecipeFragment } from '~/graphql';

import { DoctorProfile } from './DoctorProfile';
import { RecipeInformation } from './RecipeInformation';

type Props = {
  prescriptionRecipe: PrescriptionRecipeFragment;
};

export const PrescriptionRecipe = React.memo((props: Props) => {
  const { prescriptionRecipe } = props;

  const theme = useTheme();

  const doctorRecord = prescriptionRecipe.doctor?.doctorRecord;

  const recipeInformation = useMemo(() => {
    if (!prescriptionRecipe.recipeInformation) return [];

    return prescriptionRecipe.recipeInformation.map((info) => {
      const dosage = info.dosage;
      const dosageCaution = info.dosageCaution;

      return {
        dosage: dosage?.dosageRecord || undefined,
        dosagePluses: dosage?.dosagePlusRecords ?? [],
        dosageCautions: dosageCaution?.dosageCautionRecords ?? [],
        medicines: info.medicines ?? [],
      };
    });
  }, [prescriptionRecipe.recipeInformation]);

  return (
    <Box>
      {doctorRecord && (
        <Box marginTop={theme.space.m}>
          <DoctorProfile doctor={doctorRecord} />
        </Box>
      )}
      {recipeInformation.map((info, idx) => {
        return (
          <Box key={idx} marginTop={theme.space.l}>
            <RecipeInformation
              medicines={info.medicines}
              dosage={info.dosage}
              dosagePluses={info.dosagePluses}
              dosageCautions={info.dosageCautions}
            />
          </Box>
        );
      })}
    </Box>
  );
});

PrescriptionRecipe.displayName = 'PrescriptionRecipe';
