import { css } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { useContext } from 'react';
import { compose, layout, LayoutProps } from 'styled-system';

import { DialogContext } from './DialogContext';

type Props = {
  children?: React.ReactNode;
};

const Root = styled('div', { shouldForwardProp })<{ invert?: boolean } & LayoutProps>(
  ({ theme, invert }) =>
    css({
      flex: 1,
      position: 'relative',
      height: '100%',
      overflow: 'auto',
      ...(invert && {
        color: theme.colors.text.white,
        borderBottom: 'none',
      }),
    }),
  () => compose(layout),
);

export const Body: React.FC<Props> = (props) => {
  const { invert } = useContext(DialogContext);

  return <Root invert={invert}>{props.children}</Root>;
};
