import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { useCallback, useState } from 'react';

import { Tabs } from '~/components/blocks';
import { PatientProfileTabMap } from '~/constants/tab';
import { AppPatientProfileFragment } from '~/graphql';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';

import { ProfileDetailApp } from './ProfileDetailApp';
import { ProfileDetailMedixs } from './ProfileDetailMedixs';
import { useFetchMedixsPatient } from './use-fetch-medixs_patient';

type Props = {
  loading: boolean;
  patientId?: string;
  appPatient?: AppPatientProfileFragment | null;
};

type TabProps = {
  active?: boolean;
};

const Tab = styled(Tabs, { shouldForwardProp })(() =>
  css({
    display: 'inline-flex',
  }),
);

const Items = styled(Tabs.Tab, { shouldForwardProp })<TabProps>(({ theme, active }) =>
  css({
    backgroundColor: theme.colors.background.default,
    borderTop: 'none',
    borderBottom: `2px solid ${theme.colors.border.default}`,
    padding: `${theme.space.m} ${theme.space.xxl}`,
    '&:hover': {
      borderTop: 'none',
      backgroundColor: theme.colors.background.bg,
      borderBottom: `2px solid ${theme.colors.border.default}`,
    },
    ...(active && {
      borderTop: 'none',
      borderBottom: `2px solid ${theme.colors.border.blue}`,
    }),
  }),
);

export const ProfileDetailTabs = React.memo((props: Props) => {
  const { loading, patientId, appPatient } = props;

  const { enabledMedixs } = useMedixsSetting();
  const [tab, setTab] = useState(PatientProfileTabMap.app as string);
  const { medixsPatient } = useFetchMedixsPatient(tab === PatientProfileTabMap.medixs, patientId);

  const handleChangeTab = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.target as HTMLButtonElement;
      const newTab = element.dataset['tab'] || PatientProfileTabMap.app;
      setTab(newTab);
    },
    [setTab],
  );

  return enabledMedixs ? (
    <>
      <Tab>
        <Items
          attached
          active={tab === PatientProfileTabMap.app}
          data-tab={PatientProfileTabMap.app}
          onClick={handleChangeTab}
          size="xs"
        >
          アプリ
        </Items>
        <Items
          attached
          active={tab === PatientProfileTabMap.medixs}
          data-tab={PatientProfileTabMap.medixs}
          onClick={handleChangeTab}
          size="xs"
        >
          連携元
        </Items>
      </Tab>
      {tab === PatientProfileTabMap.app && appPatient && (
        <ProfileDetailApp loading={loading} appPatient={appPatient} />
      )}
      {tab === PatientProfileTabMap.medixs && <ProfileDetailMedixs medixsPatient={medixsPatient} />}
    </>
  ) : (
    <ProfileDetailApp loading={loading} appPatient={appPatient} />
  );
});

ProfileDetailTabs.displayName = 'ProfileDetailTabs';
