import { PartnerPatientFragment, PartnerWebPatientFragment } from '~/graphql';

export const Panel = {
  initial: 'initial',
  input: 'input',
} as const;
export type PanelType = (typeof Panel)[keyof typeof Panel];

export type MessageEventPageInfo = {
  page: number;
  perPage: number;
  totalPage: number;
};

export type MessagePane = {
  currentPanel: PanelType;
  messageId: string | null;
  draftMessageId: string | null;
  patient: PartnerPatientFragment | null;
  webPatient: PartnerWebPatientFragment | null;
};

export type SearchPageInfo = {
  word: string | null;
  page: number;
  totalPage: number;
  perPage: number;
};
