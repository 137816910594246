import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { Formik, FormikProps } from 'formik';
import React, { useCallback, useMemo, useRef } from 'react';

import { CreatableSelect, Tag, TagGroup } from '~/components/blocks';
import { OptionType } from '~/components/pages/Company_Security_Logs/OrganizationFilter';

import { DeliveryTag, isDeliveryTag } from '../DeliveryTag';
import { isMedicatoinFollowupTag } from '../MedicationFollowupTag';
import { MemoFields } from '../PatientKarte/ReceptionPane/Appointment/types';

const initialMemoValue: MemoFields = { memo: '' };

type Props = {
  disabled: boolean;
  value: string;
  onChange: (memo: string) => void;
};

const Form = styled('form')(({ theme }) =>
  css({
    marginTop: theme.space.m,
  }),
);

const TEMPLATE_MEMO = ['原本到着まち', '疑義照会', '要患者連絡', '薬剤在庫なし'];

export const MemoField = React.memo((props: Props) => {
  const { onChange } = props;
  const memos = useMemo(() => props.value.split(',').filter(Boolean) as string[], [props.value]);
  const formikRef = useRef<FormikProps<MemoFields>>(null);
  const templateMemos = useMemo(() => TEMPLATE_MEMO.filter((tm) => !memos.includes(tm)), [memos]);
  const handleChoice = useCallback(
    (value: OptionType) => {
      if (value?.value) {
        onChange([...memos, value?.value].join(','));
        formikRef.current?.resetForm();
      }
    },
    [memos, onChange],
  );
  const handleSubmit = useCallback(
    (values: MemoFields) => {
      const memo = values.memo;

      if (memo) {
        onChange([...memos, memo].join(','));
        formikRef.current?.resetForm();
      }
    },
    [memos, onChange],
  );
  const handleDeleteMemo = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const memoElem = e.target as HTMLDivElement;
      const memo = memoElem.dataset['memo'];

      const newMemo = memos.filter((m) => m !== memo);

      onChange(newMemo.join(','));
    },
    [memos, onChange],
  );

  return (
    <>
      <TagGroup>
        {memos.map((memo, idx) =>
          isDeliveryTag(memo) ? (
            <DeliveryTag key={idx} text={memo} />
          ) : isMedicatoinFollowupTag(memo) ? null : (
            <Tag key={idx} status="edit" data-memo={memo} onClick={handleDeleteMemo}>
              {memo}
            </Tag>
          ),
        )}
      </TagGroup>
      <Formik innerRef={formikRef} initialValues={initialMemoValue} onSubmit={handleSubmit}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} onBlur={formik.handleSubmit}>
            <CreatableSelect
              name="memo"
              menuPlacement="top"
              defaultInputValue=""
              noOptionsMessage={() => '選択できる項目がありません'}
              value={null} // 選択後の項目が表示されないようにnullを設定
              formatCreateLabel={(newMemo) => newMemo}
              placeholder="メモ入力"
              onChange={(value) => handleChoice(value as OptionType)}
              onInputChange={(newValue) => {
                formik.setFieldValue('memo', newValue);
              }}
              options={templateMemos.map((tm) => ({ value: tm, label: tm }))}
              isLoading={props.disabled}
              isDisabled={props.disabled}
            />
          </Form>
        )}
      </Formik>
    </>
  );
});

MemoField.displayName = 'MemoField';
