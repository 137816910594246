import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { darken, rgba } from 'polished';
import { compose, space, SpaceProps, variant } from 'styled-system';

import { Align, Size } from '~/styles/types';
import { publicPath } from '~/utils/path';

type Props = {
  as?: React.ElementType;
  loading?: boolean;
  size?: Size | 'ms';
  use?: 'default' | 'primary' | 'secondary' | 'base' | 'white' | 'grey';
  sharp?: 'default' | 'circle';
  wide?: 'half' | 'fill';
  align?: Align;
  icon?: boolean;
  responsive?: boolean;
};

export const Button = styled('button', {
  shouldForwardProp: (propName: string) =>
    shouldForwardProp(propName) && !['loading', 'icon'].includes(propName),
})<Props & SpaceProps>(
  ({ theme }) =>
    css({
      position: 'relative',
      border: theme.borders.default,
      cursor: 'pointer',
      outline: 0,
      fontWeight: theme.fontWeights.bold,
      transition: theme.transitions.default,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      userSelect: 'none',
      '&:hover': {
        boxShadow: theme.shadows.default,
      },
      '&:active': {
        background: theme.colors.background.bg,
      },
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
        pointerEvents: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    }),
  ({ theme, icon }) =>
    !icon && {
      [`svg:first-of-type`]: {
        marginRight: theme.space.s,
      },
    },
  ({ loading, theme }) =>
    loading && {
      opacity: 0.4,
      cursor: 'wait',
      pointerEvents: 'none',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${publicPath('/loader_logo_white.svg')})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '64% 64%',
        backgroundColor: rgba(theme.colors.background.black, 0.6),
        borderRadius: theme.space.s,
      },
    },
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'size',
        variants: {
          xs: css({
            padding: `${theme.space.s}`,
          }),
          s: css({
            padding: `${theme.space.s} ${theme.space.l}`,
            fontSize: theme.fontSizes.xs,
          }),
          ms: css({
            padding: `${theme.space.s} ${theme.space.l}`,
            fontSize: theme.fontSizes.s,
            [`@media ${theme.mediaQueries.tablet}`]: {
              padding: `${theme.space.s} ${theme.space.l}`,
              fontSize: theme.fontSizes.xs,
            },
          }),
          m: css({
            padding: `${theme.space.m} ${theme.space.l}`,
            fontSize: theme.fontSizes.s,
          }),
          l: css({
            padding: `${theme.space.m} ${theme.space.xl}`,
            fontSize: theme.fontSizes.l,
            [`@media ${theme.mediaQueries.tablet}`]: {
              fontSize: theme.fontSizes.m,
            },
          }),
        },
      }),
      variant({
        prop: 'wide',
        variants: {
          fill: css({
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0,
          }),
          half: css({
            width: '49%',
            paddingLeft: '0',
            paddingRight: '0',
          }),
        },
      }),
      variant({
        prop: 'use',
        variants: {
          default: css({
            color: theme.colors.text.default,
            background: theme.colors.background.default,
            '&:disabled': {
              backgroundColor: theme.colors.background.bg,
              borderColor: 'transparent',
            },
          }),
          primary: css({
            color: theme.colors.text.white,
            background: theme.colors.background.primary,
            border: theme.borders.transparent,
            '&:active': {
              background: darken(0.05, theme.colors.background.primary),
            },
          }),
          secondary: css({
            color: theme.colors.text.white,
            background: theme.colors.background.secondary,
            border: theme.borders.transparent,
            '&:active': {
              background: darken(0.05, theme.colors.background.secondary),
            },
          }),
          base: css({
            color: theme.colors.text.white,
            background: theme.colors.background.black,
            border: theme.borders.transparent,
            '&:active': {
              background: darken(0.05, theme.colors.background.black),
            },
          }),
          white: css({
            color: theme.colors.text.default,
            background: theme.colors.background.transparent,
            border: theme.borders.transparent,
            '&:hover': {
              boxShadow: 'none',
              background: theme.colors.background.bg,
            },
            '&:active': {
              boxShadow: 'none',
            },
          }),
          grey: css({
            color: theme.colors.text.white,
            background: theme.colors.background.grey,
            border: theme.borders.transparent,
            '&:active': {
              background: darken(0.05, theme.colors.background.grey),
            },
          }),
        },
      }),
      variant({
        prop: 'sharp',
        variants: {
          default: css({
            borderRadius: theme.space.s,
          }),
          circle: css({
            borderRadius: '50%',
          }),
        },
      }),
      variant({
        prop: 'align',
        variants: {
          left: css({
            textAlign: 'left',
          }),
          center: css({
            textAlign: 'center',
          }),
          right: css({
            textAlign: 'right',
          }),
        },
      }),
    ),
);

Button.defaultProps = {
  size: 'm',
  use: 'default',
  sharp: 'default',
};
