import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Flex, Grid, Header, Icon, Loader, Text } from '~/components/blocks';
import {
  DirectVisitorDetailDialogProfileFragment,
  PatientDetailDialogProfileFragment,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';
import {
  patientDetailDialogMessageState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';

import { useHasMessages } from '../../EventPane/use-has-messages';
import { MessageForm } from '../MessageForm';
import { Fields } from '../MessageForm/InputPane/types';
import { PaneType, Patient } from '../MessageForm/types';
import { useDefaultValues } from '../MessageForm/use-default_values';
import { useFetchOthersDraftMessages } from './use-fetch-others-draft-messages';
import { useSendMessage } from './use-send-message';
import { useUpsertDraftMessage } from './use-upsert-draft_message';

type Props = {
  patient?:
    | DirectVisitorDetailDialogProfileFragment
    | WebVisitorDetailDialogProfileFragment
    | PatientDetailDialogProfileFragment;
};

const Wrap = styled(Grid)<{ currentPane: string }>(({ theme, currentPane }) =>
  css({
    height: '100%',
    overflow: currentPane === 'input' ? 'auto' : 'hidden',
    padding: `${theme.space.xl} ${theme.space.xl}`,
    borderLeft: `1px solid ${theme.colors.border.grey}`,
  }),
);

const InfoAlert = styled(Alert)(({ theme }) =>
  css({
    marginTop: theme.space.m,
    padding: `${theme.space.m} ${theme.space.l}`,
    fontSize: theme.fontSizes.m,
  }),
);

const EditAlert = styled(Alert)(({ theme }) =>
  css({
    marginTop: theme.space.m,
    padding: `${theme.space.m} ${theme.space.l}`,
    fontSize: theme.fontSizes.m,
  }),
);

export const InputPanel = (props: Props) => {
  const theme = useTheme();
  const [currentPane, setCurrentPane] = useState<PaneType>('input');
  const { patientId } = useRecoilValue(patientDetailDialogState);
  const { selectedMessageId, draftMessageId, profile, messageInfoStatus } = useRecoilValue(
    patientDetailDialogMessageState,
  );

  const isEditable = !!selectedMessageId;

  const { sending, error, send } = useSendMessage();
  const { upserting, error: upsertError, upsert } = useUpsertDraftMessage();
  const { othersDraftMessages } = useFetchOthersDraftMessages();

  const editingByOther = othersDraftMessages.length > 0;
  const practitionerNames = othersDraftMessages.map(
    (m) => `${m.practitioner.familyName}${m.practitioner.givenName}`,
  );

  const patient: Patient = useMemo(
    () => ({
      type: profile?.type,
      name: profile ? `${profile.familyName}${profile.givenName}` : '',
      id: patientId,
    }),
    [patientId, profile],
  );
  const isDeactivatedPatient = props.patient?.__typename === 'Patient' && !props.patient.active;

  const { loading: loadingMessages, hasMessages } = useHasMessages(props.patient);
  const { loadingMessage, loadingDraftMessage } = useDefaultValues(
    selectedMessageId,
    patient,
    !!selectedMessageId,
  );

  const handleBackInitial = useRecoilCallback(
    ({ set }) =>
      () =>
        set(patientDetailDialogMessageState, (_state) => ({
          ..._state,
          selectedMessageId: null,
          draftMessageId: null,
        })),
    [],
  );
  const handleSubmit = useCallback(
    async (values: Fields, isFailed: boolean) => {
      try {
        await send(values, selectedMessageId, isFailed);
      } catch {
        // 何もしない
      }
    },
    [selectedMessageId, send],
  );
  const handleAutoSave = useCallback(
    async (values: Fields) => {
      try {
        await upsert(values, draftMessageId);
      } catch {
        // 何もしない
      }
    },
    [draftMessageId, upsert],
  );

  return (
    <Wrap currentPane={currentPane}>
      {sending || loadingMessage || loadingDraftMessage || loadingMessages ? (
        <Loader open inside appearance="white" />
      ) : (
        <Flex flexDirection="column">
          <Header>{`メッセージ${
            isEditable ? '編集' : currentPane === 'confirm' ? '確認' : '作成'
          }`}</Header>
          {editingByOther && (
            <EditAlert status="success" withIcon={false}>
              <Icon icon="edit" color="green" size="l" />
              <Text marginLeft={theme.space.m} fontWeight={theme.fontWeights.bold} size="s">
                {`${practitionerNames.join('さん、 ')}さんがメッセージを作成中です`}
              </Text>
            </EditAlert>
          )}
          {currentPane === 'input' && messageInfoStatus === 'new' && !hasMessages && (
            <InfoAlert status="info">
              服薬フォローアップが必要な患者に、メッセージや質問票を送信できます。
            </InfoAlert>
          )}
          {currentPane === 'input' && messageInfoStatus === 'created' && (
            <InfoAlert status="info">メッセージを送信しました。</InfoAlert>
          )}
          {currentPane === 'input' && messageInfoStatus === 'sendAt' && (
            <InfoAlert status="info">送信予定メッセージを作成しました。</InfoAlert>
          )}
          {currentPane === 'input' && isDeactivatedPatient && (
            <InfoAlert status="error">退会済みの患者です。メッセージを送信できません</InfoAlert>
          )}
          {!isDeactivatedPatient && (
            <MessageForm
              disabled={sending}
              error={error}
              upsertError={upsertError}
              upserting={upserting}
              selectMessageId={selectedMessageId}
              patient={patient}
              onBackInitial={handleBackInitial}
              onSubmit={handleSubmit}
              onAutoSave={handleAutoSave}
              currentPane={currentPane}
              setCurrentPane={setCurrentPane}
            />
          )}
        </Flex>
      )}
    </Wrap>
  );
};
