import { boolean, date, object, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';

export const AFTER_MAX_SEND_DATE = 120;

export const validationSchema = (maxMessageLength: number) => {
  return object().shape({
    attachment: string().label('添付項目'),
    // FIXME: 実装上うまく制御できなかったため、attachmentがclinicの場合、csClinicIdを送信前にチェックすること
    csClinicId: string().label('病院・診療所').default('').nullable(),
    medicationFollowupQuestionnaireId: string()
      .label('質問票')
      .default('')
      .nullable()
      .when('attachment', {
        is: (attachment: string) => attachment === 'questionnaire_sheet',
        then: (schema) => schema.label('質問票').required(FormErrorMessage.required),
      }),
    fixed: boolean().label('送信日時指定').required(FormErrorMessage.required),
    sendDate: date()
      .default(null)
      .nullable()
      .label('送信日')
      .when('fixed', {
        is: (fixed: boolean) => fixed,
        then: (schema) =>
          schema
            .label('送信日時')
            // https://github.com/jquense/yup/issues/601
            .nullable()
            .required(FormErrorMessage.required),
      }),
    sendTime: string()
      .default('')
      .label('送信時間')
      .when('fixed', {
        is: (fixed: boolean) => fixed,
        then: (schema) => schema.label('送信日時').required(FormErrorMessage.required),
      }),
    message: string()
      .label('送信内容')
      .trim()
      .required(FormErrorMessage.required)
      .max(maxMessageLength, FormErrorMessage.maxLengthRequired),
    isEnablesAutoReplySetting: boolean().label('自動返信メッセージ'),
    autoReplyMessage: string()
      .label('メッセージ')
      .trim()
      .when('isEnabledAutoReplySetting', {
        is: (isEnabledAutoReplySetting: boolean) => isEnabledAutoReplySetting,
        then: (schema) =>
          schema
            .required(FormErrorMessage.required)
            .max(maxMessageLength, FormErrorMessage.maxLengthRequired),
      }),
  });
};
