import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Modal } from '~/components/blocks';
import { renewClientCertModalState } from '~/state/layouts/SharedAppShell/atoms';
import { RenewableTarget } from '~/state/layouts/SharedAppShell/types';

import { AdminPanel } from './AdminPanel';
import { PractitionerPanel } from './PractitionerPanel';
import { useFetchRenewableClientCert } from './use-fetch-renewable_client_cert';

export const RenewClientCertModal = () => {
  useFetchRenewableClientCert();
  const { isOpen, isClosed, isAdmin, isCompanyAdmin, renewableTarget, clientCert, practitionerId } =
    useRecoilValue(renewClientCertModalState);

  const handleClose = useRecoilCallback(
    ({ set }) =>
      () => {
        set(renewClientCertModalState, (_state) => ({
          ..._state,
          isOpen: false,
          isClosed: true,
        }));
      },
    [],
  );

  return (
    <Modal open={isOpen && !isClosed} onClose={handleClose} size="s">
      <Modal.Header>証明書の有効期限切れが近づいています</Modal.Header>
      {renewableTarget == RenewableTarget.practitioner && clientCert && (
        <PractitionerPanel
          onClosed={handleClose}
          clientCert={clientCert}
          isAdmin={isAdmin}
          isCompanyAdmin={isCompanyAdmin}
        />
      )}
      {(renewableTarget == RenewableTarget.organization ||
        renewableTarget == RenewableTarget.company) && (
        <AdminPanel
          onClosed={handleClose}
          isCompanyAdmin={isCompanyAdmin}
          practitionerId={practitionerId}
        />
      )}
    </Modal>
  );
};
