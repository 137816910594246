import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Flex, Snackbar } from '~/components/blocks';

const DURATION = 1000;

type Props = {
  open: boolean;
  children: React.ReactNode;
  isNotification: boolean;
  onClick: () => void;
  onClose: () => void;
  onExited: () => void;
};

const Notification = styled('div')<{ disabled: boolean }>(({ disabled }) =>
  css({
    display: 'flex',
    cursor: disabled ? 'none' : 'pointer',
  }),
);

const Action = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    padding: `calc(${theme.space.m} + ${theme.space.s})`,
    width: '280px',
  }),
);

const Close = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    borderLeft: `1px ${theme.colors.colorPallete.grey05} solid`,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    padding: `calc(${theme.space.m} + ${theme.space.s})`,
  }),
);

export const ActionSnackbar: React.FC<Props> = (props) => {
  return (
    <Snackbar open={props.open} timeout={DURATION} onExited={props.onExited}>
      <Notification disabled={props.isNotification}>
        <Action onClick={props.onClick}>{props.children}</Action>
        <Close onClick={props.onClose}>閉じる</Close>
      </Notification>
    </Snackbar>
  );
};
