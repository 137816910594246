import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Chip, Flex, Text } from '~/components/blocks';

type Props = {
  // YYYY年MM月DD日
  date: string;
};

const CustomFlex = styled(Flex)(({ theme }) =>
  css({
    paddingTop: theme.space.l,
    marginBottom: '0px',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    [`&:before`]: {
      content: '""',
      position: 'absolute',
      width: '100%',
      borderTop: theme.borders.default,
    },
  }),
);
const CustomChip = styled(Chip)(() =>
  css({
    border: '1px solid #D2D7E1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const EventDate: React.FC<Props> = (props) => {
  const theme = useTheme();
  const thisYear: number = new Date().getFullYear();

  return (
    <CustomFlex justifyContent="center" marginBottom={theme.space.m}>
      <CustomChip color="white">
        <Text textAlign="center" size="xs">
          {thisYear === Number(props.date.substring(0, 4)) ? props.date.substring(5) : props.date}
        </Text>
      </CustomChip>
    </CustomFlex>
  );
};

EventDate.displayName = 'EventDate';
