import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';

import { Box, Flex, Text, Tooltip } from '~/components/blocks';
import { FollowupQuestionnairePreview } from '~/components/partials/FollowupQuestionnairePreview';
import { FollowupQuestionnaireSelect } from '~/components/partials/FollowupQuestionnaireSelect';
import { MedicationFollowupQuestionnairesSelectFragment } from '~/graphql';
import { useMedicationFollowupQuestionnaires } from '~/hooks/use-medication-followup-questionnaires';

type Props = {
  value: string | null;
  onChange: (questionnaire: MedicationFollowupQuestionnairesSelectFragment) => void;
};

const Root = styled(Flex)(({ theme }) =>
  css({
    background: theme.colors.background.bg,
    borderRadius: theme.radii.default,
    marginTop: theme.space.m,
    padding: theme.space.m,
    alignItems: 'center',
  }),
);

const SelectBox = styled(Box)(() =>
  css({
    width: '300px',
  }),
);

const PreviewText = styled('div')(({ theme }) =>
  css({
    textDecoration: 'underline',
    fontSize: theme.fontSizes.xs,
    cursor: 'default',
    marginLeft: theme.space.m,
  }),
);

export const SelectFollowupQuestionnaireSheet = (props: Props) => {
  const { questionnaires } = useMedicationFollowupQuestionnaires();
  const { value, onChange } = props;

  const currentQuestionnaire = useMemo(() => {
    return questionnaires?.find((q) => q.id === value) || null;
  }, [questionnaires, value]);

  const handleChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const medicationFollowupQuestionnaireId = e.currentTarget.value;
      const selectedQuestionnaire = questionnaires?.find(
        (q) => q.id === medicationFollowupQuestionnaireId,
      );

      if (selectedQuestionnaire) {
        onChange(selectedQuestionnaire);
      }
    },
    [onChange, questionnaires],
  );

  return (
    <Root>
      <SelectBox>
        <FollowupQuestionnaireSelect
          value={value || ''}
          questionnaires={questionnaires}
          onChange={handleChangeSelect}
        />
      </SelectBox>
      <Tooltip
        content={
          <FollowupQuestionnairePreview
            content={
              <Text size="xs" fontWeight="bold" color="white">
                メッセージと合わせて、
                <br />
                以下の質問内容が患者に送信されます。
              </Text>
            }
            questionnaire={currentQuestionnaire}
          />
        }
        placement="top"
      >
        <PreviewText>質問内容を確認</PreviewText>
      </Tooltip>
    </Root>
  );
};
