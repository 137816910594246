import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Flex, Radio, Table, Text } from '~/components/blocks';
import { MedixsModalMedixsReceptionFragment } from '~/graphql';
import { medixsReceptionIdentificationModalState } from '~/state/partials/medixs_reception_identification_modal/atoms';
import { Label } from '~/utils/label';

type Props = {
  medixsReception: MedixsModalMedixsReceptionFragment;
};

export const MedixsReception = (props: Props) => {
  const [state, setState] = useRecoilState(medixsReceptionIdentificationModalState);
  const handleChange = useCallback(() => {
    setState((_state) => {
      return {
        ..._state,
        selectedMedixsReception: props.medixsReception,
      };
    });
  }, [setState, props.medixsReception]);
  return (
    <Table.Tr key={props.medixsReception.receptionId}>
      <Table.Td nowrap>
        <Flex alignItems="center">
          <Radio
            label=""
            name="selected"
            value="true"
            checked={
              !!(
                state.selectedMedixsReception &&
                state.selectedMedixsReception.id === props.medixsReception.id
              )
            }
            onChange={handleChange}
          />
        </Flex>
      </Table.Td>
      <Table.Td>{props.medixsReception.receptionId}</Table.Td>
      <Table.Td>
        <Text fontWeight="bold" size="s">
          {props.medixsReception.medixsPatient.patientName}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text size="s">{props.medixsReception.medixsPatient.patientNameKana}</Text>
      </Table.Td>
      <Table.Td nowrap>
        <Text size="s">{Label.sex(props.medixsReception.medixsPatient.gender)}</Text>
      </Table.Td>
      <Table.Td>
        <Text size="s">{Label.YYYYMMDDja(props.medixsReception.medixsPatient.birthDate)}</Text>
      </Table.Td>
    </Table.Tr>
  );
};
