import React from 'react';

import { Alert, Modal } from '~/components/blocks';
import { toYYYYMMDDja } from '~/utils/date';

type Props = {
  startAt: Date;
};

export const GeneralPanel = (props: Props) => {
  return (
    <Modal.Body>
      <Alert status="warning">
        {toYYYYMMDDja(props.startAt)}以降Pharmsをご利用いただくには、
        <br />
        クライアント証明書が必要になります。
        <br />
        店舗管理者にクライアント証明書の発行をご依頼ください
      </Alert>
    </Modal.Body>
  );
};
