import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import {
  DirectVisitorDetailDialogProfileFragment,
  FollowupMessageEventPatientDetailFragment,
  FollowupQuestionnaireSheetEventOnPatientDetailDialogFragment,
  GuestPatientMedicationFollowupCommentFragment,
  PatientDetailDialogProfileFragment,
  PatientMedicationFollowupCommentFragment,
  TracingReportPatientDetailFragment,
  useGetPatientEventsLazyQuery,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { eventPageInfoState } from '~/state/partials/patient_detail_dialog/atoms';
import { groupBy } from '~/utils/group_by';
import { Label } from '~/utils/label';

type Patient =
  | DirectVisitorDetailDialogProfileFragment
  | WebVisitorDetailDialogProfileFragment
  | PatientDetailDialogProfileFragment;

type Event =
  | GuestPatientMedicationFollowupCommentFragment
  | PatientMedicationFollowupCommentFragment
  | FollowupMessageEventPatientDetailFragment
  | FollowupQuestionnaireSheetEventOnPatientDetailDialogFragment
  | TracingReportPatientDetailFragment;

export const useFetchEvents = (patient?: Patient) => {
  const [{ isResetEvent, page, perPage }, setEventPageInfoState] =
    useRecoilState(eventPageInfoState);

  const [medicationFollowupEvent, clearMedicationFollowupEvent] = useSubscriptions(
    'MedicationFollowupQuestionnaireSheet',
    ['created'],
  );

  const [events, setEvents] = useState<Event[]>([]);
  const [getEvents, { data, loading }] = useGetPatientEventsLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (_result) => {
      const list: Event[] = [...events];
      if ((events.length === 0 && page === 1) || (events.length > 0 && page > 1)) {
        _result.events?.nodes.map((event) => {
          list.push(event);
        });
        setEvents(list);
      }
    },
  });

  const guestPatientId = patient?.__typename !== 'Patient' ? patient?.id : undefined;
  const patientId = patient?.__typename === 'Patient' ? patient.id : undefined;

  const totalPage = data?.events?.pagesCount || 1;
  const hasNextPage = data?.events?.hasNextPage || false;

  const dateGroups = groupBy(events, (event) => {
    if (event.__typename === 'MedicationFollowupMessage') {
      return Label.YYYYMMDDja(event.sendAt);
    } else if (
      event.__typename === 'MedicationFollowupQuestionnaireSheet' ||
      event.__typename === 'PatientMedicationFollowupComment' ||
      event.__typename === 'GuestPatientMedicationFollowupComment' ||
      event.__typename === 'TracingReport'
    ) {
      return Label.YYYYMMDDja(event.createdAt);
    }
  });

  const eventsWithDate = dateGroups
    .map((group) => {
      const date = group[0];
      const events = group[1];
      return events.map((event, idx) => {
        return {
          event,
          date: events.length === idx + 1 ? date : null,
        };
      });
    })
    .flat();

  useEffect(() => {
    getEvents({
      variables: {
        guestPatientId,
        patientId,
        page,
        perPage,
      },
    });
    setEventPageInfoState((_state) => ({ ..._state, isResetEvent: false }));
  }, [
    getEvents,
    guestPatientId,
    patientId,
    page,
    perPage,
    clearMedicationFollowupEvent,
    isResetEvent,
    setEventPageInfoState,
  ]);

  useEffect(() => {
    if (isResetEvent) {
      // TODO: リセットしたいタイミングを正しくしないと、無駄に処理が走りそうなので後に修正する
      setEvents([]);
    }
  }, [isResetEvent]);

  useEffect(() => {
    if (!medicationFollowupEvent) {
      return;
    }

    if (['created', 'updated'].includes(medicationFollowupEvent.actionName)) {
      setEvents([]);
      getEvents({
        variables: {
          guestPatientId,
          patientId,
          page: 1,
          perPage: 20,
        },
      });
    }
  }, [getEvents, guestPatientId, medicationFollowupEvent, patientId]);

  return {
    loading,
    events,
    totalPage,
    hasNextPage,
    eventsWithDate,
  };
};
