import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import React, { useRef } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Flex, Grid, Icon, Pagination, ScrollBox, Text } from '~/components/blocks';
import { messageModalState } from '~/state/partials/message_modal/atoms';

import { FollowupMessage } from './FollowupMessage';
import { Message } from './Message';
import { QuestionnaireSheet } from './QuestionnaireSheet';
import { useFetchMessageEvents } from './use-fetch-message_events';
import { useFetchOthersDraftMessages } from './use-fetch-others-draft-messages';

export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE = 10;

type Props = {
  readonly?: boolean;
};

const Event = styled('div')(({ theme }) =>
  css({
    '& + &': {
      marginTop: theme.space.m,
    },
  }),
);

const EditingMessage = styled(Flex)(({ theme }) =>
  css({
    border: theme.borders.primary,
    background: rgba(theme.colors.background.primary, 0.1),
    borderRadius: '6px',
    boxShadow: `2px 4px 8px ${rgba(theme.colors.background.black, 0.05)}`,
    padding: theme.space.m,
    marginBottom: theme.space.m,
    alignItems: 'center',
  }),
);

const EditIconRoot = styled('div')<Props>(({ theme }) =>
  css({
    alignItems: 'center',
    border: `1px ${theme.colors.border.default} solid`,
    background: theme.colors.background.default,
    borderRadius: theme.radii.circle,
    display: 'flex',
    justifyContent: 'center',
    minWidth: '30px',
    height: '30px',
    width: '30px',
  }),
);

export const MessageEventList = React.memo((props: Props) => {
  const { readonly } = props;
  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const { selectedMessageId, page, totalPage } = useRecoilValue(messageModalState);
  const { loading, events } = useFetchMessageEvents();
  const { othersDraftMessages } = useFetchOthersDraftMessages();

  const editingByOther = othersDraftMessages.length > 0;
  const practitionerNames = othersDraftMessages.map(
    (m) => `${m.practitioner.familyName}${m.practitioner.givenName}`,
  );

  const handleChange = useRecoilCallback(
    ({ set }) =>
      (page: number) => {
        set(messageModalState, (_state) => ({ ..._state, page }));
      },
    [],
  );

  return (
    <Grid
      height="100%"
      minWidth="235px"
      overflow="auto"
      gridTemplateRows={editingByOther ? 'min-content 1fr min-content' : '1fr min-content'}
      paddingTop={theme.space.m}
    >
      {editingByOther && (
        <EditingMessage>
          <EditIconRoot>
            <Icon icon="edit" size="s" />
          </EditIconRoot>
          <Text marginLeft={theme.space.m} fontWeight={theme.fontWeights.bold} size="s">
            {`${practitionerNames.join('さん、 ')}さんがメッセージを作成中です`}
          </Text>
        </EditingMessage>
      )}
      <ScrollBox ref={scrollRef} loading={loading}>
        {events.map((event, idx) => {
          const isEditing = readonly ? false : event.id === selectedMessageId;

          return (
            <Event key={idx}>
              {event.__typename === 'MedicationFollowupQuestionnaireSheet' ? (
                <QuestionnaireSheet sheet={event} />
              ) : event.__typename === 'MedicationFollowupMessage' ? (
                event.medicationFollowupQuestionnaireRevision ? (
                  <FollowupMessage
                    readonly={readonly}
                    editing={isEditing}
                    followupMessage={event}
                  />
                ) : (
                  <Message readonly={readonly} editing={isEditing} message={event} />
                )
              ) : null}
            </Event>
          );
        })}
        {!loading && events.length === 0 && (
          <Box marginTop={theme.space.xxxl} textAlign="center">
            <Text size="s" color="grey">
              メッセージはありません
            </Text>
          </Box>
        )}
      </ScrollBox>
      {totalPage > 1 && (
        <Box
          marginLeft="auto"
          marginRight="auto"
          marginTop={`calc(${theme.space.l} + ${theme.space.xs})`}
          marginBottom={theme.space.s}
        >
          <Pagination size="s" currentPage={page} totalPage={totalPage} onChange={handleChange} />
        </Box>
      )}
    </Grid>
  );
});

MessageEventList.displayName = 'MessageEventList';
