import React from 'react';

import { Select } from '~/components/blocks';
import { MedicationFollowupQuestionnairesSelectFragment } from '~/graphql';

type Props = {
  value: string;
  questionnaires?: MedicationFollowupQuestionnairesSelectFragment[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const FollowupQuestionnaireSelect = (props: Props) => {
  const { value, questionnaires, onChange } = props;

  return (
    <Select value={value} onChange={onChange} fill>
      {questionnaires?.map((questionnaire, idx) => (
        <option key={idx} value={questionnaire.id}>
          {questionnaire.revision.subject}
        </option>
      ))}
    </Select>
  );
};
