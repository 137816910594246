import { PlanFeatureCode, useGetPlanFeaturesQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const usePlanFeature = (feature: PlanFeatureCode) => {
  const { data, loading } = useGetPlanFeaturesQuery();
  const features = data ? getMe(data)?.planFeatureCodes || [] : [];
  const hasEnabledFeature = features.includes(feature);

  return { loading, hasEnabledFeature };
};
