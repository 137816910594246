import { useEffect, useMemo } from 'react';

import {
  ReceptionPaneDraftAppointmentFragment,
  useGetReceptionPaneDraftAppointmentLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { useSubscriptions } from '~/hooks/use-subscriptions';

type DraftAppointment = {
  loading: boolean;
  draftAppointment?: ReceptionPaneDraftAppointmentFragment;
};

export const useFetchDraftAppointment = (draftAppointmentId: string): DraftAppointment => {
  const [event, clear] = useSubscriptions('DraftAppointment', ['updated']);

  const [getDraftAppointment, { loading, data }] = useGetReceptionPaneDraftAppointmentLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (event) {
        clear();
      }
    },
  });

  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const draftAppointment = useMemo(() => {
    const _draftAppointment = getNode(currentData, 'DraftAppointment');

    if (_draftAppointment?.id === draftAppointmentId) {
      return _draftAppointment;
    }
  }, [draftAppointmentId, currentData]);

  useEffect(() => {
    if (draftAppointmentId) {
      getDraftAppointment({ variables: { draftAppointmentId } });
    }
  }, [draftAppointmentId, getDraftAppointment]);

  useEffect(() => {
    if (event?.actionName === 'updated' && event.typeId === draftAppointmentId) {
      getDraftAppointment({ variables: { draftAppointmentId } });
    }
  }, [draftAppointmentId, event?.actionName, event?.typeId, getDraftAppointment]);

  return { loading, draftAppointment };
};
