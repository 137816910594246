import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Box, Button, Flex, Grid, Icon, Loader, Text } from '~/components/blocks';
import {
  DirectVisitorDetailDialogProfileFragment,
  PatientDetailDialogProfileFragment,
  WebVisitorDetailDialogProfileFragment,
} from '~/graphql';
import {
  eventPageInfoState,
  patientDetailDialogPatientCommentState,
  patientDetailDialogTracingreportState,
} from '~/state/partials/patient_detail_dialog/atoms';

import { useFetchEvents } from '../use-fetch-events';
import Event from './Event';
import { EventDate } from './Events/EventDate';
import { FollowupCommentForm } from './FollowupCommentForm';
import { Fields } from './FollowupCommentForm/types';
import { useCreateComment } from './FollowupCommentForm/use-create-comment';
import { useUpdateStatus } from './FollowupCommentForm/use-update-status';
import { useHasMessages } from './use-has-messages';

type Props = {
  patient?:
    | DirectVisitorDetailDialogProfileFragment
    | WebVisitorDetailDialogProfileFragment
    | PatientDetailDialogProfileFragment;
  statusManagement: boolean;
  width?: string;
  isPharmacist: boolean;
};

const StatusButton = styled(Button)(({ theme }) =>
  css({
    border: theme.borders.default,
  }),
);

const InfiniteBox = styled(Box)(({ theme }) =>
  css({
    overflowY: 'auto',
    backgroundColor: theme.colors.colorPallete.grey04,
    height: '100%',
  }),
);

const EventFlex = styled(Flex)(() =>
  css({
    flexDirection: 'column-reverse',
  }),
);

const EventBox = styled(Box)(({ theme }) =>
  css({
    '&:first-of-type': {
      backgroundColor: theme.colors.colorPallete.grey04,
      paddingBottom: theme.space.l,
    },
  }),
);

const DEFAULT_PAGE = 1;

export const EventPane = React.memo((props: Props) => {
  const theme = useTheme();
  const infiniteRef = useRef<HTMLDivElement>(null);
  const eventFlexRef = useRef<HTMLDivElement>(null);
  const { isResetEvent, page } = useRecoilValue(eventPageInfoState);

  const [commentStatus, setCommentStatus] = useState<null | 'in_progress' | 'finished'>(null);
  const [comment, setComment] = useState('');
  const { selectedSheetId } = useRecoilValue(patientDetailDialogTracingreportState);
  const { selectedCommentId } = useRecoilValue(patientDetailDialogPatientCommentState);
  const followupStatus = props.patient?.medicationFollowupStatus;

  const { loading, eventsWithDate, hasNextPage } = useFetchEvents(props.patient);
  const { loading: loadingMessages, hasMessages } = useHasMessages(props.patient);

  const { isCreating, error, create, formRef } = useCreateComment(props.patient?.__typename);
  const { isUpdating, updateError, update } = useUpdateStatus(props.patient?.__typename);

  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        await create(values, commentStatus);
      } catch {
        // 何もしない
      }
      formRef.current?.resetForm();
      setCommentStatus(null);
    },
    [create, formRef, commentStatus],
  );

  const handleCompleteSend = useCallback(() => {
    setCommentStatus('finished');
    formRef.current?.submitForm();
    setComment('');
  }, [formRef]);
  const handleReFollowupSend = useCallback(() => {
    setCommentStatus('in_progress');
    formRef.current?.submitForm();
    setComment('');
  }, [formRef]);
  const handleSend = useCallback(() => {
    setCommentStatus(null);
    formRef.current?.submitForm();
    setComment('');
  }, [formRef]);
  const handleCompleteFollowupStatus = useCallback(async () => {
    setCommentStatus(null);
    try {
      await update('finished');
    } catch {
      // 何もしない
    }
    formRef.current?.resetForm();
    setComment('');
  }, [formRef, update]);
  const handleReFollowupStatus = useCallback(async () => {
    setCommentStatus(null);
    try {
      await update('in_progress');
    } catch {
      // 何もしない
    }
    formRef.current?.resetForm();
    setComment('');
  }, [formRef, update]);

  const loadMore = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          if (!loading) {
            // pageを変更することで、useFetchEventsのuseEffectを発火させる
            set(eventPageInfoState, (_state) => ({
              ..._state,
              page: page + 1,
            }));
          }
        } catch {
          // 何もしない
        }
      },

    [page, loading],
  );

  useEffect(() => {
    // 最初だけデフォルトの位置を下部にする
    if ((page === DEFAULT_PAGE || isResetEvent) && !loading) {
      // 少し待たないとスクロール位置が変更されないので、setTimeoutを使う
      setTimeout(() => {
        eventFlexRef.current?.scrollIntoView(false);
      }, 100);
    }
  }, [page, eventFlexRef, loading, isResetEvent]);

  return (
    <Grid
      height="100%"
      width={props.width || '100%'}
      gridTemplateRows="1fr min-content"
      overflow={'auto'}
    >
      <InfiniteBox ref={infiniteRef}>
        <InfiniteScroll
          isReverse={true}
          initialLoad={false}
          hasMore={hasNextPage}
          loadMore={loadMore}
          useWindow={false}
        >
          {loading && page === DEFAULT_PAGE ? (
            <Loader open inside appearance="white" />
          ) : (
            <EventFlex ref={eventFlexRef}>
              {eventsWithDate.map(({ event, date }, idx) => (
                <EventBox key={idx}>
                  {date && <EventDate date={date} />}
                  <Event
                    event={event}
                    selectedSheetId={selectedSheetId}
                    patientType={props.patient?.__typename}
                  />
                </EventBox>
              ))}
            </EventFlex>
          )}
        </InfiniteScroll>
        {!loading && eventsWithDate.length === 0 && (
          <Box textAlign="start" paddingTop={theme.space.l} paddingLeft={theme.space.xl}>
            <Text size="m">メッセージはありません</Text>
          </Box>
        )}
      </InfiniteBox>

      <Flex flexDirection="column" margin={theme.space.l}>
        {error && <Alert marginBottom={theme.space.l}>{error}</Alert>}
        {updateError && <Alert marginBottom={theme.space.l}>{updateError}</Alert>}
        <Flex alignItems="flex-start">
          <Icon icon="info" size="l" />
          <Text marginLeft={theme.space.s} size="s">
            店舗内のみで共有されます。患者には送信されません
          </Text>
        </Flex>
        <FollowupCommentForm
          ref={formRef}
          hasMessages={hasMessages}
          disabled={!hasMessages || isCreating || isUpdating || !!selectedCommentId}
          initialValues={{ comment: '' }}
          onSubmit={handleSubmit}
          setComment={setComment}
        />
        <Flex justifyContent="flex-end">
          {props.statusManagement &&
            hasMessages &&
            props.isPharmacist &&
            (comment.length > 0 ? (
              followupStatus === 'in_progress' ? (
                <StatusButton
                  use="white"
                  disabled={isCreating || isUpdating || loadingMessages}
                  onClick={handleCompleteSend}
                >
                  <Icon icon="check" size="m" color="green" />
                  対応完了 + 保存
                </StatusButton>
              ) : (
                <StatusButton
                  use="white"
                  disabled={isCreating || isUpdating || loadingMessages}
                  onClick={handleReFollowupSend}
                >
                  <Icon icon="sync" size="m" color="blue" />
                  対応再開 + 保存
                </StatusButton>
              )
            ) : followupStatus === 'in_progress' ? (
              <StatusButton
                use="white"
                disabled={isCreating || isUpdating}
                onClick={handleCompleteFollowupStatus}
              >
                <Icon icon="check" size="m" color="green" />
                対応完了
              </StatusButton>
            ) : (
              <StatusButton
                use="white"
                disabled={isCreating || isUpdating}
                onClick={handleReFollowupStatus}
              >
                <Icon icon="sync" size="m" color="blue" />
                対応再開
              </StatusButton>
            ))}
          <Button
            use="base"
            marginLeft={theme.space.s}
            disabled={!hasMessages || isCreating || !!selectedCommentId || loadingMessages}
            onClick={handleSend}
          >
            保存
          </Button>
        </Flex>
      </Flex>
    </Grid>
  );
});

EventPane.displayName = 'EventPane';
