import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { ComponentProps, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { compose, layout, LayoutProps, position, PositionProps } from 'styled-system';

import { useScrollToTop } from '~/hooks/use-scroll-to-top';

import { Loader } from '../Loader';

type Props = {
  children?: React.ReactNode;
  loading?: boolean;
  height?: string;
  maxHeight?: string;
  loaderLogoSize?: ComponentProps<typeof Loader>['logoSize'];
  top?: string;
};

export type ScrollBoxAttr = {
  toTop: () => void;
  refresh: () => void;
};

const MAX_SHADOW_HEIGHT = 24;
const makeTopShadow = (shadowHeight: number) =>
  `linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2)) 0 0/100% ${shadowHeight}px`;
const makeBottomShadow = (shadowHeight: number) =>
  `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2)) 0 100%/100% ${shadowHeight}px`;
const setShadowStyles = (targetElem: HTMLElement, shadowElem: HTMLElement) => {
  const scrollTop = targetElem.scrollTop;
  const scrollBottom = targetElem.scrollHeight - scrollTop - targetElem.clientHeight;

  if (targetElem.scrollHeight <= targetElem.clientHeight) {
    shadowElem.style['background'] = 'none';
  } else if (scrollTop < MAX_SHADOW_HEIGHT * 4) {
    shadowElem.style['background'] = `${makeTopShadow(scrollTop / 4)}, ${makeBottomShadow(
      MAX_SHADOW_HEIGHT,
    )}`;
  } else if (scrollBottom < MAX_SHADOW_HEIGHT * 4) {
    shadowElem.style['background'] = makeTopShadow(MAX_SHADOW_HEIGHT);
    shadowElem.style['background'] = `${makeTopShadow(MAX_SHADOW_HEIGHT)}, ${makeBottomShadow(
      scrollBottom / 4,
    )}`;
  } else {
    shadowElem.style['background'] = `${makeTopShadow(MAX_SHADOW_HEIGHT)}, ${makeBottomShadow(
      MAX_SHADOW_HEIGHT,
    )}`;
  }

  shadowElem.style['backgroundRepeat'] = 'no-repeat';
  shadowElem.style['backgroundAttachment'] = 'scroll';
};

const Root = styled('div', {
  shouldForwardProp,
})<LayoutProps & PositionProps>(
  () =>
    css({
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'auto',
    }),
  () => compose(layout, position),
);

const ScrollWrapper = styled('div')<LayoutProps & PositionProps>(
  () =>
    css({
      width: '100%',
      height: '100%',
      overflow: 'auto',
    }),
  () => compose(layout, position),
);

const ShadowContent = styled('div')<{ top?: string }>(({ top }) =>
  css({
    position: 'absolute',
    top: top ? `${top}` : '0',
    right: '0',
    bottom: '0',
    left: '0',
    pointerEvents: 'none',
  }),
);

export const ScrollBox = React.forwardRef<ScrollBoxAttr, Props>((props, ref) => {
  const shadowRef = useRef<HTMLDivElement | null>(null);
  const [containerRef, scrollToTop] = useScrollToTop<HTMLDivElement>();
  const refresh = useCallback(() => {
    const targetElem = containerRef.current;
    const shadowElem = shadowRef.current;

    if (targetElem && shadowElem) {
      setShadowStyles(targetElem, shadowElem);
    }
  }, [containerRef]);

  useEffect(() => {
    const targetElem = containerRef.current;
    const shadowElem = shadowRef.current;

    const handleScroll = (e: Event) => {
      const _elem = e.currentTarget as HTMLDivElement;

      if (shadowElem) {
        setShadowStyles(_elem, shadowElem);
      }
    };

    if (targetElem && shadowElem) {
      setShadowStyles(targetElem, shadowElem);
      targetElem.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (targetElem) {
        targetElem.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef]);

  useEffect(() => {
    const target = containerRef.current;

    if (!target) return;

    const observer = new MutationObserver(() => {
      refresh();
    });

    observer.observe(target, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [containerRef, refresh]);

  useImperativeHandle(ref, () => ({
    toTop: () => {
      scrollToTop();
      refresh();
    },
    refresh,
  }));

  return (
    <Root height={props.height} maxHeight={props.maxHeight}>
      <ScrollWrapper ref={containerRef}>{props.children}</ScrollWrapper>
      <ShadowContent ref={shadowRef} top={props.top} />
      {props.loading && <Loader open inside appearance="white" logoSize={props.loaderLogoSize} />}
    </Root>
  );
});

ScrollBox.displayName = 'ScrollBox';
