import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Box, Text } from '~/components/blocks';
import { OtcDrugDosageRecordFragment, OtcDrugIngredientRecordFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  otcDrug: OtcDrugDosageRecordFragment;
  otcIngredients?: OtcDrugIngredientRecordFragment[];
};

const Root = styled(Box)(({ theme }) =>
  css({
    padding: `${theme.space.m} ${theme.space.l}`,
    border: `${theme.colors.colorPallete.grey03} 1px solid`,
    borderLeft: `${theme.colors.colorPallete.grey03} 5px solid`,
    '& + &': {
      borderTop: 'none',
    },
  }),
);

const MedicationPeriod = styled('dl')(({ theme }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes.xs,
  }),
);

const DateLabel = styled('dt')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.xs,
    '&::after': {
      content: '"："',
    },
  }),
);

const Date = styled('dd')();

const Splitter = styled(Text)(({ theme }) =>
  css({
    margin: `auto ${theme.space.s}`,
    '&::after': {
      content: '"〜"',
    },
  }),
);

const OtcDrugIngredient = styled('dl')(({ theme }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes.xs,
  }),
);

const IngredientLabel = styled('dt')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.xs,
    '&::after': {
      content: '"："',
    },
  }),
);

const IngredientNames = styled('dd')();

export const OtcDrug = React.memo((props: Props) => {
  const { otcDrug, otcIngredients } = props;

  return (
    <Root>
      <Text fontWeight="bold" size="s">
        {otcDrug.name}
      </Text>
      {otcIngredients && otcIngredients.length > 0 && (
        <OtcDrugIngredient>
          <IngredientLabel>成分名</IngredientLabel>
          <IngredientNames>
            {otcIngredients?.map((ingredient) => ingredient.name).join('、')}
          </IngredientNames>
        </OtcDrugIngredient>
      )}
      <MedicationPeriod>
        <DateLabel>服用期間</DateLabel>
        <Date>{otcDrug.start ? Label.YYYYMMDDja(otcDrug.start) : '不明'}</Date>
        <Splitter />
        {otcDrug.end && (
          <>
            <Date>{Label.YYYYMMDDja(otcDrug.end)}</Date>
          </>
        )}
      </MedicationPeriod>
    </Root>
  );
});

OtcDrug.displayName = 'OtcDrug';
