import React from 'react';
import { useRecoilValue } from 'recoil';

import { uberDeliveryNotificationsState } from '~/state/layouts/SharedAppShell/atoms';

import { UberDeliveryNotification } from './UberDeliveryNotification';
import { useSubscriptionUberDeliveryNotifications } from './use-subscription-uber_delivery_notifications';

export const UberDeliveryNotifications = () => {
  const { notifications } = useRecoilValue(uberDeliveryNotificationsState);
  const { handleClose } = useSubscriptionUberDeliveryNotifications();

  return (
    <>
      {notifications.map((notification) => (
        <UberDeliveryNotification
          key={notification.id}
          notification={notification}
          onClose={handleClose}
        />
      ))}
    </>
  );
};
