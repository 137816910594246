import { MedicationFollowupQuestionnaireRevisionSectionFragment } from '~/graphql';

type AllAnswer = {
  title: string;
  values: string[];
};

export const buildAllChoices = (
  sections: Array<MedicationFollowupQuestionnaireRevisionSectionFragment>,
): AllAnswer[] => {
  const answers: AllAnswer[] = [];

  sections.map((section) => {
    section.questions.map((question) => {
      if (question.questionType !== 'text') {
        answers.push({
          title: question.title,
          values: question.choices?.map((choice) => choice.value) ?? [],
        });
      }
    });
  });

  return answers;
};
