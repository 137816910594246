import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Box, Button, Flex, Loader, Modal, Text } from '~/components/blocks';
import { twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';

import { useCreateQrCode } from './use-create-otp_secret';

export const ScanQrCodePanel = () => {
  const theme = useTheme();

  const { qrCodeData } = useRecoilValue(twoFactorAuthModalState);
  const { error, creating, create } = useCreateQrCode();

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () =>
        set(twoFactorAuthModalState, (_state) => ({ ..._state, currentPanel: 'send' })),
    [],
  );

  useEffect(() => {
    if (!qrCodeData) {
      create();
    }
  }, [create, qrCodeData]);

  return (
    <>
      <Modal.Body>
        {error && <Alert marginBottom={theme.space.l}>{error}</Alert>}
        <Text>
          「<strong>Google Authenticator</strong>」で下のQRコードをスキャン後、
          <br />
          6ケタの数字が表示されたことを確認して<strong>次へ</strong>を押してください
        </Text>
        <Flex justifyContent="center" marginTop={theme.space.m}>
          {creating ? (
            <Box width="142px" height="142px" position="relative">
              <Loader open inside logoSize="half" />
            </Box>
          ) : qrCodeData ? (
            <img src={qrCodeData} alt="認証コードアプリで読み込んでください" />
          ) : null}
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" onClick={handleClick}>
          次へ
        </Button>
      </Modal.Footer>
    </>
  );
};
