import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import React from 'react';

import { Box, EntryList, Flex, Text } from '~/components/blocks';
import { CsClinic, MessageIcon } from '~/components/partials';
import { MessageEventFragment } from '~/graphql';
import { Label } from '~/utils/label';
import { removeLastUrl } from '~/utils/medication_followup_message';

type Props = {
  className?: string;
  message: MessageEventFragment;
  children?: React.ReactNode;
  onClick?: () => void;
};

const Root = styled(Box)<{ error?: boolean }>(({ theme, error, onClick }) =>
  css({
    border: error ? theme.borders.secondary : theme.borders.default,
    background: error ? theme.colors.background.alert : theme.colors.background.default,
    borderRadius: '6px',
    boxShadow: `2px 4px 8px ${rgba(theme.colors.background.black, 0.05)}`,
    padding: theme.space.m,
    '& + &': {
      marginTop: theme.space.l,
    },
    '&:hover': {
      cursor: 'default',
    },
    ...(onClick && {
      border: theme.borders.default,
      '&:hover': {
        cursor: 'pointer',
      },
    }),
  }),
);

export const MessageEvent: React.FC<Props> = (props) => {
  const { message } = props;
  const theme = useTheme();

  const deliverResult = message.messageDeliverResult;
  const isError = deliverResult?.status === 'failed';
  const isScheduled = message.status === 'scheduled';

  return (
    <Root className={props.className} error={isError} onClick={props.onClick}>
      <Flex alignItems="center">
        <MessageIcon size="l" color={isError ? 'pink' : undefined} />
        <EntryList size="s" marginLeft={theme.space.m}>
          <EntryList.Head>
            {isError ? (
              <Text fontWeight="bold" color="pink">
                送信エラー
              </Text>
            ) : isScheduled ? (
              <Text fontWeight="bold" color="grey01">
                送信予定
              </Text>
            ) : (
              '送信日時'
            )}
          </EntryList.Head>
          <EntryList.Body>
            <Text fontWeight="bold" whiteSpace="nowrap">
              {deliverResult
                ? `${Label.YYYYMMDDja(deliverResult.updatedAt)} ${Label.HHMM(
                    deliverResult.updatedAt,
                  )}`
                : `${Label.YYYYMMDDja(message.sendAt)} ${Label.HHMM(message.sendAt)}`}
            </Text>
          </EntryList.Body>
        </EntryList>
      </Flex>
      <Box marginTop={theme.space.s}>
        <Text size="s" wordBreak="break-word" whiteSpace="pre-line">
          {message.csClinic ? removeLastUrl(message.message) : message.message}
        </Text>
        {message.csClinic && (
          <Box>
            <Text size="xs" color="grey">
              紹介した病院・診療所
            </Text>
            <CsClinic size="s" clinic={message.csClinic} />
          </Box>
        )}
      </Box>
      {props.children}
    </Root>
  );
};

MessageEvent.displayName = 'MessageEvent';
