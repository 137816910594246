import { useRecoilState } from 'recoil';

import { useGetActivatedOtpSecretQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';

export const useFetchOtpSecret = () => {
  const [{ isOpen }, setState] = useRecoilState(twoFactorAuthModalState);

  const { loading } = useGetActivatedOtpSecretQuery({
    skip: !isOpen,
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (_result) => {
      if (getMe(_result)?.otpSecret) {
        setState((_state) => ({ ..._state, currentPanel: 'activating' }));
      }
    },
  });

  return { loading };
};
