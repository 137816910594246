import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Button, Flex, Modal } from '~/components/blocks';

type Props = {
  isOpen: boolean;
  deleteError: string | null;
  onClick: () => void;
  onClose: () => void;
};

export const TracingReportRecreateModal = (props: Props) => {
  const theme = useTheme();

  return (
    <>
      <Modal open={props.isOpen} onClose={props.onClose}>
        {props.deleteError && <Alert marginBottom={theme.space.m}>{props.deleteError}</Alert>}
        <Modal.Header>トレーシングレポート作成</Modal.Header>
        <Modal.Body height="183px" minWidth="462px">
          新規作成すると、自動保存の内容は削除されます
          <Flex justifyContent="flex-end" marginTop={theme.space.xl}>
            <Button use="base" onClick={props.onClick}>
              削除して新規作成
            </Button>
          </Flex>
        </Modal.Body>
      </Modal>
    </>
  );
};
