import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { addDays, format, parseISO } from 'date-fns';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Counter,
  FieldError,
  Flex,
  Grid,
  Header,
  Icon,
  InputDate,
  ScrollBox,
  Text,
  Textarea,
  Tooltip,
} from '~/components/blocks';
import { CsClinic, SelectTime } from '~/components/partials';
import {
  BASE_DEFAULT_AUTO_REPLY_MESSAGE,
  DEFAULT_AUTO_REPLY_MESSAGE,
  DEFAULT_AUTO_REPLY_MESSAGE_WITH_CONTACT_FORM,
} from '~/constants/medication_followup_message_template';
import {
  CsClinicItemFragment,
  MedicationFollowupQuestionnairesSelectFragment,
  TemplateFragment,
} from '~/graphql';
import { useMedicationFollowupQuestionnaires } from '~/hooks/use-medication-followup-questionnaires';
import { Label } from '~/utils/label';

import { AutoReplyFollowupQuestionnairePreview } from '../../AutoReplyFollowupQuestionnairePreview';
import { Arrow } from './Arrow';
import { AutoReplySettingHint } from './AutoReplySettingHint';
import { RecommendationHint } from './RecommendationHint';
import { SelectFollowupQuestionnaireSheet } from './SelectFollowupQuestionnaireSheet';
import { SelectMessageTemplate } from './SelectMessageTemplate';
import { Fields } from './types';
import { AFTER_MAX_SEND_DATE } from './validation';

const TODAY = new Date();
const MAX_DATE = addDays(TODAY, AFTER_MAX_SEND_DATE);

type Props = {
  failed?: boolean;
  disabled?: boolean;
  deleting: boolean;
  editable: boolean;
  clearable: boolean;
  error: string | null;
  upsertError: string | null;
  upserting: boolean;
  loadingDraftMessage: boolean;
  formik: FormikProps<Fields>;
  clinic: CsClinicItemFragment | null;
  maxLength: number;
  textRows?: number;
  patientName: string;
  encounteredAt?: string;
  onBack: () => void;
  onRemoveClinic: () => void;
  onRecommendClinic: () => void;
  onSelectClinic: (csClinic: CsClinicItemFragment) => void;
  onSelectQuestionnaire: (questionnaire: MedicationFollowupQuestionnairesSelectFragment) => void;
  onAutoSave: (values: Fields) => void;
  onClickClear: (formik: FormikProps<Fields>) => void;
};

const Clinic = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.bg,
    borderRadius: theme.radii.default,
    display: 'block',
    marginTop: theme.space.m,
    padding: theme.space.m,
  }),
);

const DaySort = styled(Flex)<{ error?: boolean }>(({ theme, error }) =>
  css({
    alignItems: 'center',
    background: error ? theme.colors.background.alert : theme.colors.background.bg,
    borderRadius: theme.radii.default,
    paddingLeft: theme.space.m,
    '& .react-datepicker__close-icon': {
      marginTop: theme.space.xs,
    },
    '& .react-datepicker__input-container input': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      transitionDuration: theme.transitions.default,
      fontSize: theme.fontSizes.s,
      fontWeight: theme.fontWeights.bold,
      maxWidth: '144px',
      padding: theme.space.m,
      '&:focus': {
        backgroundColor: theme.colors.background.default,
        borderColor: theme.colors.border.primary,
      },
    },
  }),
);

const CheckboxesWrapper = styled('div')(({ theme }) =>
  css({
    borderTop: theme.borders.default,
    paddingTop: `${theme.space.l}`,
    marginTop: `${theme.space.l}`,
    whiteSpace: 'nowrap',
  }),
);

const SetAutoReplyQuestionnaireWrapper = styled(Flex)(({ theme }) =>
  css({
    background: theme.colors.background.bg,
    borderRadius: theme.radii.default,
    alignItems: 'center',
    padding: theme.space.m,
    marginTop: theme.space.m,
    width: 'fit-content',
  }),
);

const AutoSaveStatusText = styled(Text)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    marginRight: theme.space.m,
    marginLeft: theme.space.s,
    verticalAlign: 'middle',
  }),
);

export const InputPane = React.memo((props: Props) => {
  const {
    disabled,
    deleting,
    editable,
    clearable,
    error,
    upsertError,
    upserting,
    loadingDraftMessage,
    formik,
    encounteredAt,
    onRemoveClinic,
    onSelectClinic,
    onSelectQuestionnaire,
    onAutoSave,
    onClickClear,
  } = props;
  const theme = useTheme();
  const { questionnaires } = useMedicationFollowupQuestionnaires();
  const defaultContactFormQuestionnaire = useMemo(() => {
    return questionnaires?.find((q) => q.revision.subject === '連絡フォーム') || null;
  }, [questionnaires]);
  const defaultQuestionnaire = questionnaires ? questionnaires[0] : null;
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const [isAutoSaved, setIsAutoSaved] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const [selectedTemplateAutoReplyMessage, setSelectedTemplateAutoReplyMessage] = useState<
    string | null
  >(null);

  const handleAutoSave = useDebouncedCallback(() => {
    onAutoSave(formik.values);
    setIsAutoSaved(true);
  }, 500);

  const handleChangeAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, formik: FormikProps<Fields>) => {
      const targetValue = e.currentTarget.value;

      if (formik.values.attachment !== targetValue) {
        formik.setFieldValue('attachment', targetValue);
      } else {
        formik.setFieldValue('attachment', '');

        if (targetValue === 'clinic' && formik.values.csClinicId.length > 0) {
          formik.setFieldValue('csClinicId', '');
          onRemoveClinic();
        }
      }
    },
    [onRemoveClinic],
  );
  const handleChangeDate = useCallback(
    (date: Date | null) => formik.setFieldValue('sendDate', date),
    [formik],
  );
  const handleChangeTemplate = useCallback(
    (message: string, template?: TemplateFragment) => {
      if (!template || !encounteredAt) return;

      setSelectedTemplateId(template.id);
      setSelectedTemplateAutoReplyMessage(template.autoReplySettingTemplate?.message || '');

      const newValues = {
        ...formik.values,
        message,
        attachment: template.medicationFollowupQuestionnaire?.id
          ? 'questionnaire_sheet'
          : template.withRecommendation
            ? 'clinic'
            : '',
        csClinicId: template.csClinic?.id ?? '',
        medicationFollowupQuestionnaireId:
          template.medicationFollowupQuestionnaire?.id || defaultQuestionnaire?.id || '',
        isEnabledAutoReplySetting: !!template.autoReplySettingTemplate,
        autoReplyMessage: template.autoReplySettingTemplate?.message || DEFAULT_AUTO_REPLY_MESSAGE,
        withAutoReplyQuestionnaire: !!template.autoReplySettingTemplate?.questionnaireRevision,
      };

      if (typeof template.sendTiming === 'number' && template.sendTime) {
        newValues.fixed = true;
        newValues.sendDate = addDays(new Date(encounteredAt), template.sendTiming);
        newValues.sendTime = format(parseISO(template.sendTime), 'HH');
      } else {
        newValues.fixed = false;
      }

      if (template.csClinic) {
        onSelectClinic(template.csClinic);
      } else {
        onRemoveClinic();
      }

      if (template.medicationFollowupQuestionnaire) {
        onSelectQuestionnaire(template.medicationFollowupQuestionnaire);
      }

      formik.setValues(newValues);
    },
    [
      defaultQuestionnaire?.id,
      encounteredAt,
      formik,
      onRemoveClinic,
      onSelectClinic,
      onSelectQuestionnaire,
    ],
  );
  const handleClickOn = useCallback(() => formik.setFieldValue('fixed', false), [formik]);
  const handleClickOff = useCallback(() => formik.setFieldValue('fixed', true), [formik]);
  const handleChangeQuestionnaire = useCallback(
    (questionnaire: MedicationFollowupQuestionnairesSelectFragment) => {
      formik.setFieldValue('medicationFollowupQuestionnaireId', questionnaire.id);
      onSelectQuestionnaire(questionnaire);
    },
    [formik, onSelectQuestionnaire],
  );
  const handleChangeWithAutoReplyQuestionnaire = useCallback(() => {
    const setValue = !formik.values.withAutoReplyQuestionnaire;
    formik.setFieldValue('withAutoReplyQuestionnaire', setValue);

    // 設定がない時
    if (!selectedTemplateAutoReplyMessage) {
      if (setValue) {
        formik.setFieldValue('autoReplyMessage', DEFAULT_AUTO_REPLY_MESSAGE_WITH_CONTACT_FORM);
      } else {
        formik.setFieldValue('autoReplyMessage', DEFAULT_AUTO_REPLY_MESSAGE);
      }
      // 設定に自動返信のデフォルトが含まれる時
    } else if (selectedTemplateAutoReplyMessage.includes(BASE_DEFAULT_AUTO_REPLY_MESSAGE)) {
      if (setValue) {
        formik.setFieldValue('autoReplyMessage', selectedTemplateAutoReplyMessage);
      } else {
        formik.setFieldValue('autoReplyMessage', DEFAULT_AUTO_REPLY_MESSAGE);
      }
    } else {
      // FIXME：他の文言が設定されている時はどうしようもないのでsetValueの値によらずそのまま表示する
      formik.setFieldValue('autoReplyMessage', selectedTemplateAutoReplyMessage);
    }
  }, [formik, selectedTemplateAutoReplyMessage]);
  const handleClickClear = useCallback(() => {
    onClickClear(formik);
    setIsAutoSaved(false);
    setSelectedTemplateId(null);
  }, [formik, onClickClear]);

  useEffect(() => {
    if (editable) return;
    if (!formik.dirty) return;

    setIsAutoSaved(false);
    handleAutoSave();
  }, [editable, formik.dirty, formik.values, handleAutoSave]);

  return (
    <Grid gridTemplateRows="1fr min-content">
      <Flex marginBottom={theme.space.m}>
        <Button size="xs" use="white" onClick={props.onBack}>
          <Icon icon="back-line" size="l" />
        </Button>
        <Header>{`メッセージ${editable ? '編集' : '作成'}`}</Header>
      </Flex>
      <ScrollBox ref={scrollRef} height="528px" loading={loadingDraftMessage}>
        {error && (
          <Alert marginTop={theme.space.l} status="error">
            {error}
          </Alert>
        )}
        <Flex marginTop={theme.space.l} alignItems="center">
          <SelectMessageTemplate
            disabled={disabled}
            patientName={props.patientName}
            onChange={handleChangeTemplate}
            selectedTemplateId={selectedTemplateId || ''}
          />
          {formik.values.fixed ? (
            <Button
              use="primary"
              marginLeft={theme.space.m}
              disabled={disabled}
              onClick={handleClickOn}
            >
              <Icon color="white" icon="timer" size="l" />
              日時指定ON
            </Button>
          ) : (
            <Button marginLeft={theme.space.m} disabled={disabled} onClick={handleClickOff}>
              <Icon icon="timer" size="l" />
              日時指定OFF
            </Button>
          )}
        </Flex>
        {formik.values.fixed && (
          <Flex marginTop={theme.space.m} alignItems="center">
            <Box>
              <Text block size="xs">
                服薬指導実施日
              </Text>
              <Text size="s" fontWeight="bold" whiteSpace="nowrap" marginTop={theme.space.m}>
                {encounteredAt ? Label.YYYYMMDDja(encounteredAt) : 'ー'}
              </Text>
            </Box>
            <Box paddingTop={theme.space.xl}>
              <Arrow />
            </Box>
            <Box>
              <Text size="xs" fontWeight="bold">
                送信日時
              </Text>
              <Flex alignItems="center">
                <DaySort error={!!formik.errors.sendDate}>
                  <InputDate
                    calendarIcon
                    placement="bottom"
                    disabled={disabled}
                    minDate={TODAY}
                    maxDate={MAX_DATE}
                    value={formik.values.sendDate}
                    onChange={handleChangeDate}
                  />
                </DaySort>
                <SelectTime
                  name="sendTime"
                  disabled={disabled}
                  error={formik.errors.sendTime}
                  value={formik.values.sendTime}
                  onChange={formik.handleChange}
                />
              </Flex>
              {(formik.errors.sendDate || formik.errors.sendTime) && (
                <FieldError
                  error={
                    formik.errors.sendDate || formik.errors.sendTime || '送信日時を入力してください'
                  }
                />
              )}
            </Box>
          </Flex>
        )}
        {props.failed && (
          <Alert status="error" marginTop={theme.space.m}>
            <Text size="s">
              このメッセージを送信できませんでした。
              <br />
              再度送信しても届かない場合は患者に直接ご連絡ください。
            </Text>
          </Alert>
        )}
        <Textarea
          name="message"
          disabled={props.disabled}
          rows={props.textRows ? props.textRows : formik.values.fixed ? 5 : 8}
          marginTop={theme.space.m}
          placeholder="例：&#13;&#10;薬局太郎様&#13;&#10;いつもお世話になっております。メドレー薬局 六本木一丁目店です。"
          error={formik.errors.message}
          value={formik.values.message}
          onChange={formik.handleChange}
        />
        <Counter value={formik.values.message} max={props.maxLength} />
        <Box>
          <Text size="xs">添付項目</Text>
          <CheckboxGroup>
            <Flex alignItems="center">
              <Checkbox
                name="attachment"
                label="質問票"
                value="questionnaire_sheet"
                checked={formik.values.attachment === 'questionnaire_sheet'}
                disabled={formik.values.attachment === 'clinic'}
                onChange={(e) => handleChangeAttachment(e, formik)}
              />
            </Flex>
            <Flex alignItems="center" marginLeft={theme.space.l}>
              <Checkbox
                name="attachment"
                value="clinic"
                label="病院・診療所の紹介"
                checked={formik.values.attachment === 'clinic'}
                disabled={formik.values.attachment === 'questionnaire_sheet'}
                onChange={(e) => handleChangeAttachment(e, formik)}
              />
              <RecommendationHint />
            </Flex>
          </CheckboxGroup>
          {formik.values.csClinicId && props.clinic && (
            <Clinic>
              <CsClinic size="s" clinic={props.clinic} />
              <Button size="s" onClick={props.onRecommendClinic}>
                紹介先を変更する
              </Button>
            </Clinic>
          )}
          {formik.values.attachment === 'questionnaire_sheet' && (
            <SelectFollowupQuestionnaireSheet
              value={formik.values.medicationFollowupQuestionnaireId}
              onChange={handleChangeQuestionnaire}
            />
          )}
        </Box>
        <Box>
          {formik.values.attachment === 'questionnaire_sheet' && (
            <CheckboxesWrapper>
              <Flex alignItems="center" marginTop={theme.space.m}>
                <Checkbox
                  name="isEnabledAutoReplySetting"
                  label="自動返信メッセージを送信"
                  checked={formik.values.isEnabledAutoReplySetting}
                  onChange={formik.handleChange}
                />
                <AutoReplySettingHint />
              </Flex>
              {formik.values.isEnabledAutoReplySetting && (
                <SetAutoReplyQuestionnaireWrapper>
                  <Checkbox
                    name="withAutoReplyQuestionnaire"
                    label="連絡フォームあり"
                    checked={formik.values.withAutoReplyQuestionnaire}
                    onChange={handleChangeWithAutoReplyQuestionnaire}
                  />
                  <Tooltip
                    content={
                      <AutoReplyFollowupQuestionnairePreview
                        questionnaire={defaultContactFormQuestionnaire}
                      />
                    }
                  >
                    <Flex>
                      <Icon icon="hint" size="l" color="black" />
                    </Flex>
                  </Tooltip>
                </SetAutoReplyQuestionnaireWrapper>
              )}
            </CheckboxesWrapper>
          )}
          {formik.values.attachment === 'questionnaire_sheet' &&
            formik.values.isEnabledAutoReplySetting && (
              <>
                <Textarea
                  name="autoReplyMessage"
                  disabled={props.disabled}
                  rows={5}
                  marginTop={theme.space.l}
                  error={formik.errors.autoReplyMessage}
                  value={formik.values.autoReplyMessage}
                  onChange={formik.handleChange}
                />
                <Counter value={formik.values.autoReplyMessage} max={props.maxLength} />
              </>
            )}
        </Box>
      </ScrollBox>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Box paddingTop={theme.space.l}>
          <Button disabled={editable || !clearable || !deleting} onClick={handleClickClear}>
            入力内容をクリア
          </Button>
        </Box>
        <Box paddingTop={theme.space.l}>
          {editable ? (
            <AutoSaveStatusText>自動保存は無効です</AutoSaveStatusText>
          ) : isAutoSaved && !upserting && !upsertError ? (
            <>
              <Icon color="green" size="l" icon="check" />
              <AutoSaveStatusText>変更を保存しました</AutoSaveStatusText>
            </>
          ) : null}
          <Button use="base" disabled={props.disabled} onClick={formik.submitForm}>
            確認
          </Button>
        </Box>
      </Flex>
    </Grid>
  );
});

InputPane.displayName = 'InputPane';
