import { useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useDeleteFollowupDraftMessageMutation } from '~/graphql';
import { messageModalState } from '~/state/partials/message_modal/atoms';

export const useDeleteDraftMessage = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [{ profile, patientId }] = useRecoilState(messageModalState);
  const setMessageModalState = useSetRecoilState(messageModalState);
  const [deleteDraftMessage] = useDeleteFollowupDraftMessageMutation({
    onCompleted: () => {
      setMessageModalState((state) => ({
        ...state,
        draftMessageId: null,
      }));
    },
  });

  const deleteDraft = useCallback(async () => {
    setIsDeleting(true);
    setError(null);

    try {
      await deleteDraftMessage({
        variables: {
          input: {
            patientId: profile?.type === 'Patient' ? patientId : null,
            guestPatientId:
              profile?.type === 'DirectVisitor' || profile?.type === 'WebVisitor'
                ? patientId
                : null,
          },
        },
      });
      setIsDeleting(false);
    } catch (_error) {
      setError(_error?.message || 'エラーが発生しました');
      setIsDeleting(false);
      throw new Error();
    }
  }, [patientId, profile?.type, deleteDraftMessage]);

  return {
    deleting: isDeleting,
    error,
    deleteDraft,
  };
};
