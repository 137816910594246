import { useGetOnlineEncouterQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchEncounter = (sessionId: string) => {
  const { data, loading: isInitializing } = useGetOnlineEncouterQuery({
    variables: { encounterId: sessionId },
  });
  const encounter = getNode(data, 'Encounter');

  return {
    isInitializing,
    encounter,
  };
};
