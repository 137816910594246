import { useCallback, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
  GetPatientFollowupCommentsDocument,
  useUpdatePatientFollowupCommentMutation,
} from '~/graphql';
import {
  patientDetailDialogPatientCommentState,
  patientDetailDialogState,
} from '~/state/partials/patient_detail_dialog/atoms';

import { FollowupCommentForm } from '.';
import { Fields } from './types';

export const useUpdatePatientComment = () => {
  const formRef = useRef<RefAttributeType<typeof FollowupCommentForm> | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const patientDetailCommentState = useRecoilValue(patientDetailDialogPatientCommentState);
  const patientDetailState = useRecoilValue(patientDetailDialogState);

  const [updatePatientComment] = useUpdatePatientFollowupCommentMutation({
    refetchQueries: [
      {
        query: GetPatientFollowupCommentsDocument,
        variables: {
          id: patientDetailState.patientId,
        },
      },
    ],
  });
  const update = useCallback(
    async (values: Fields) => {
      if (patientDetailCommentState.selectedCommentId === null) return;
      setIsUpdating(true);

      try {
        const res = await updatePatientComment({
          variables: {
            input: {
              patientMedicationFollowupCommentId: patientDetailCommentState.selectedCommentId,
              comment: values.comment,
              medicationFollowupStatus: null,
            },
          },
        });
        setError(null);
        setIsUpdating(false);

        return res.data?.updatePatientFollowupComment?.patientMedicationFollowupComment;
      } catch (_error) {
        setError(_error?.message || 'エラーが発生しました');
        setIsUpdating(false);
        throw new Error();
      }
    },
    [patientDetailCommentState.selectedCommentId, updatePatientComment],
  );

  return {
    update,
    formRef,
    error,
    isUpdating,
  };
};
