import { AppointmentDeliveryMethod, ChargeAppointmentFragment } from '~/graphql';
import { useB2cloud } from '~/hooks/use-b2cloud';

/**
 * 配送方法も増えていくかもしれないのでデフォルト配送方法をこのHookいまとめる
 */

export const useDefaultDelivery = (appointment: ChargeAppointmentFragment | null) => {
  const { defaultInvoiceType: b2cloudDefault, enabledB2cloud } = useB2cloud();

  if (!appointment?.telemedicine) {
    return AppointmentDeliveryMethod.Hand;
  }

  if (appointment?.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery) {
    return appointment.deliveryMethod;
  }

  if (appointment?.deliveryMethod === AppointmentDeliveryMethod.Hand) {
    return appointment.deliveryMethod;
  }

  if (enabledB2cloud) {
    switch (b2cloudDefault) {
      case 'cash_on_delivery':
        return AppointmentDeliveryMethod.B2cloudCashOnDelivery;
      case 'collect':
        return AppointmentDeliveryMethod.B2cloudCollect;
      case 'compact':
        return AppointmentDeliveryMethod.B2cloudCompact;
      case 'compact_collect':
        return AppointmentDeliveryMethod.B2cloudCompactCollect;
      case 'dm':
        return AppointmentDeliveryMethod.B2cloudDm;
      case 'nekopos':
        return AppointmentDeliveryMethod.B2cloudNekopos;
      case 'prepayment':
        return AppointmentDeliveryMethod.B2cloudPrepayment;
      case 'time':
        return AppointmentDeliveryMethod.B2cloudTime;
      default:
        break;
    }
  }

  return AppointmentDeliveryMethod.Parcel;
};
