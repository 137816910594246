import { useTheme } from '@emotion/react';
import { Formik, FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';

import { Alert, Box, Button, Flex, Modal, Text, TextField } from '~/components/blocks';
import { numberRegexp } from '~/constants/regexp';
import { twoFactorAuthModalState } from '~/state/layouts/SharedAppShell/atoms';
import { toStringHalfNumber } from '~/utils/convert';
import { publicPath } from '~/utils/path';

import { Fields } from './types';
import { useActivateOtpSecret } from './use-activate-otp_secret';
import { useDefaultValues } from './use-default_values';
import { validationSchema } from './validations';

export const SendCodePanel = () => {
  const theme = useTheme();
  const defaultValues = useDefaultValues();
  const { activating, error, handleSubmit } = useActivateOtpSecret();

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () =>
        set(twoFactorAuthModalState, (_state) => ({ ..._state, currentPanel: 'scan' })),
    [],
  );
  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, formik: FormikProps<Fields>) => {
      if (!numberRegexp.test(e.currentTarget.value)) return;

      formik.setFieldValue('onetimeCode', `${toStringHalfNumber(e.currentTarget.value)}`);
    },
    [],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <>
          <Modal.Body>
            {error && <Alert marginBottom={theme.space.l}>{error}</Alert>}
            <Flex alignItems="center">
              <img src={publicPath('/img/img-authenticator.svg')} alt="二要素認証" />
              <Box marginLeft={theme.space.l}>
                <Text>
                  「<strong>Google Authenticator</strong>」に表示されている
                  <br />
                  6ケタのコードを入力後、<strong>コード送信</strong>を押してください。
                </Text>
                <TextField
                  autoFocus
                  type="text"
                  name="onetimeCode"
                  marginTop={theme.space.l}
                  disabled={activating}
                  error={formik.errors.onetimeCode}
                  value={formik.values.onetimeCode}
                  onBlur={(event) => handleBlur(event, formik)}
                  onChange={formik.handleChange}
                />
              </Box>
            </Flex>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={activating} onClick={handleClick}>
              QRコードの再確認
            </Button>
            <Button
              use="base"
              marginLeft={theme.space.m}
              loading={activating}
              onClick={formik.submitForm}
            >
              コード送信
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
