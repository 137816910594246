import { useCallback } from 'react';

import { useUpdateDraftAppointmentDescriptionMutation } from '~/graphql';

export const useUpdateMemo = (draftAppointmentId: string) => {
  const [updateMemo, { loading }] = useUpdateDraftAppointmentDescriptionMutation();

  const handleChangeMemo = useCallback(
    async (memo: string) => {
      try {
        await updateMemo({ variables: { input: { draftAppointmentId, description: memo } } });
      } catch {
        // 何もしない
      }
    },
    [draftAppointmentId, updateMemo],
  );

  return {
    handleChangeMemo,
    updating: loading,
  };
};
