import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';

import { Box, Button, Flex, Icon, Loader, Text } from '~/components/blocks';
import { useRecreateTracingReport } from '~/components/partials/TracingReportPanel/use-recreate-tracing-report';
import { Label } from '~/utils/label';

type Props = {
  tracingReportId: string;
  clinicName: string;
  createdAt: string;
};

const HistoryButton = styled(Button)(({ theme }) =>
  css({
    fontWeight: '600',
    lineHeight: 1,
    width: '100%',
    border: 'none',
    borderRadius: 0,
    textAlign: 'left',
    justifyContent: 'left',
    outline: 0,
    boxShadow: 'none',
    padding: theme.space.s,
    borderBottom: theme.borders.default,
    paddingY: theme.space.xs,
    ['&:hover']: {
      background: theme.colors.background.bg,
      boxShadow: 'none',
    },
  }),
);

export const History = (props: Props) => {
  const theme = useTheme();
  const { createdAt, tracingReportId, clinicName } = props;
  const date = `${Label.YYYYMMDDja(createdAt)} ${Label.HHMM(createdAt)}`;
  const { isRecreating, recreate } = useRecreateTracingReport();

  const handleClick = useCallback(async () => {
    try {
      await recreate(tracingReportId);
    } catch {
      // 何もしない
    }
  }, [recreate, tracingReportId]);

  return (
    <>
      <Loader open={isRecreating} transparent={false} />
      <HistoryButton onClick={handleClick}>
        <Flex justifyContent="space-between" width="100%" alignItems="center">
          <Flex flexDirection="column">
            <Box marginBottom={theme.space.m}>
              <Text size="s" color="grey01">
                {date}
              </Text>
            </Box>
            <Box marginBottom={theme.space.m}>
              <Text size="s" block>
                トレーシングレポート
              </Text>
              <Text size="xxs" marginTop={theme.space.m}>
                {clinicName}
              </Text>
            </Box>
          </Flex>
          <Icon color="grey" icon="blank" size="l" />
        </Flex>
      </HistoryButton>
    </>
  );
};
