import { atom } from 'recoil';

import { DeleteMedicationFollowupMessageModal } from './types';

export const deleteMedicationFollowupMessageModalState = atom<DeleteMedicationFollowupMessageModal>(
  {
    key: 'partials.deleteMedicationFollowupMessageModal2',
    default: {
      isOpen: false,
      medicationFollowupMessageId: null,
    },
  },
);
