import { formatISO } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import {
  MedixsModalMedixsReceptionFragment,
  useFetchMedixsReceptionMutation,
  useGetMedixsAppointmentLazyQuery,
  useGetMedixsReceptionsLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { medixsReceptionIdentificationModalState } from '~/state/partials/medixs_reception_identification_modal/atoms';
import { Label } from '~/utils/label';

type MedixsReceptions = {
  loading: boolean;
  medixsReceptions: MedixsModalMedixsReceptionFragment[];
};

export const useFetchMedixsReceptions = (): MedixsReceptions => {
  const [{ isOpen, appointmentId, medixsSettingId, patientId, selectedDate }, setState] =
    useRecoilState(medixsReceptionIdentificationModalState);
  const [fetchMedixsReception, { loading: fetchMedixsReceptionLoading }] =
    useFetchMedixsReceptionMutation({
      onError: (e) => {
        console.log(e);
      },
    });

  const [getAppointment, { data: appointmentData, loading: appointmentLoading }] =
    useGetMedixsAppointmentLazyQuery({
      fetchPolicy: 'network-only',
      onError: (e) => {
        setState((_state) => ({
          ..._state,
          error: e.message,
        }));
      },
    });
  const [getMedixsReceptions, { data, loading }] = useGetMedixsReceptionsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const fetchMedixsReceptions = useCallback(
    async (patientId: string, createdAt: string) => {
      const date = selectedDate ? formatISO(selectedDate, { representation: 'date' }) : undefined;

      if (!date) return;

      await fetchMedixsReception({
        variables: {
          input: {
            date,
            startAt: Label.HHMM(createdAt),
            endAt: '23:59', // 必ず指定日の最終時分までを検索範囲にする。アクシスに怒られたら変えるかも
          },
        },
      });

      if (medixsSettingId) {
        // fetchが終わってから受付リスト取得
        getMedixsReceptions({
          variables: {
            medixsSettingId,
            patientId,
            date,
          },
        });
      }
    },
    [fetchMedixsReception, getMedixsReceptions, medixsSettingId, selectedDate],
  );

  const medixsReceptions = getNode(data, 'MedixsSetting')?.medixsReceptions.nodes || [];
  const appointment = getNode(appointmentData, 'Appointment');
  const createdAt = appointment?.createdAt.split('T')[0];

  useEffect(() => {
    if (createdAt)
      setState((_state) => ({
        ..._state,
        selectedDate: new Date(createdAt),
      }));
  }, [createdAt, setState]);

  useEffect(() => {
    if (isOpen && appointmentId) {
      getAppointment({
        variables: {
          appointmentId,
        },
      });
    }
  }, [appointmentId, getAppointment, isOpen]);

  useEffect(() => {
    if (patientId && appointment?.createdAt) {
      fetchMedixsReceptions(patientId, appointment.createdAt);
    }
  }, [appointment, fetchMedixsReceptions, patientId]);

  return {
    loading: appointmentLoading || fetchMedixsReceptionLoading || loading,
    medixsReceptions,
  };
};
