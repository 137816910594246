import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Formik, FormikProps } from 'formik';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { compose, variant } from 'styled-system';

import { Box, Button, CloseBtn, Header, Radio, RadioGroup } from '~/components/blocks';
import { CancelAppointmentReason } from '~/graphql';
import { cancelPane } from '~/state/partials/cancel_pane/atoms';

import { Fields } from './types';
import { useDefaultValues } from './use-default_values';
import { validationSchema } from './validation';

type Props = {
  align: 'center' | 'left' | 'right';
  canceling: boolean;
  onCancel: (cancelReason: CancelAppointmentReason) => Promise<void>;
};

const Root = styled('div', {
  shouldForwardProp,
})(
  ({ theme }) =>
    css({
      position: 'absolute',
      bottom: 0,
      marginX: theme.space.l,
      transform: 'translateY(100%)',
      transition: `transform ${theme.transitions.default} ease-in-out`,
      zIndex: 2,
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'align',
        variants: {
          center: css({
            left: 0,
            right: 0,
          }),
          left: css({
            left: theme.space.l,
          }),
        },
      }),
    ),
);

const Content = styled('div')(({ theme }) =>
  css({
    position: 'relative',
    margin: '0 auto',
    padding: theme.space.l,
    background: theme.colors.background.default,
    border: theme.borders.default,
    borderBottom: 0,
    borderTopLeftRadius: theme.radii.default,
    borderTopRightRadius: theme.radii.default,
    boxShadow: `0 2px 8px rgba(0,0,0, 0.3)`,
  }),
);

const Close = styled(CloseBtn)(({ theme }) =>
  css({
    position: 'absolute',
    top: theme.space.l,
    right: theme.space.l,
  }),
);

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: { transform: 'translateY(100%)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(0)' },
  exited: { transform: 'translateY(100%)' },
};

export const CancelWaitingForChargePane = React.memo((props: Props) => {
  const { canceling, onCancel, ...styles } = props;

  const theme = useTheme();
  const formikRef = useRef<FormikProps<Fields>>(null);

  const defaultValues = useDefaultValues();

  const { isOpen } = useRecoilValue(cancelPane);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(cancelPane),
    [],
  );
  const handleSubmit = useRecoilCallback(
    ({ reset }) =>
      async ({ cancelReason }: Fields) => {
        if (!cancelReason) return;

        await onCancel(cancelReason);
        reset(cancelPane);
      },
    [onCancel],
  );

  useEffect(() => {
    if (!isOpen) {
      formikRef.current?.resetForm();
    }
  }, [isOpen]);

  return (
    <Transition in={isOpen} timeout={0}>
      {(state) => (
        <Root
          {...styles}
          style={{
            ...transitionStyles[state],
          }}
        >
          <Content>
            <Header>キャンセルしますか？</Header>
            <Formik
              innerRef={formikRef}
              initialValues={defaultValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <>
                  <Box mt={theme.space.l}>
                    <RadioGroup orientation="vertical">
                      <Radio
                        name="cancelReason"
                        label="患者都合でのキャンセル"
                        value="noshow"
                        checked={formik.values.cancelReason === 'noshow'}
                        onChange={formik.handleChange}
                      />
                    </RadioGroup>
                  </Box>
                  <Box mt={theme.space.xl}>
                    <Button
                      wide="fill"
                      use="secondary"
                      disabled={!formik.values.cancelReason}
                      loading={canceling}
                      onClick={formik.submitForm}
                    >
                      キャンセル
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
            <Close onClick={handleClose} />
          </Content>
        </Root>
      )}
    </Transition>
  );
});

CancelWaitingForChargePane.displayName = 'CancelWaitingForChargePane';
