import { formatISO } from 'date-fns';
import { useCallback, useEffect } from 'react';

import {
  AppointmentStatus,
  GetPatientProfileMedixsReceptionAppointmentDocument,
  MedixsReceptionAppointmentFragment,
  useFetchMedixsReceptionMutation,
  useGetPatientProfileMedixsReceptionAppointmentLazyQuery,
  useIdentifyMedixsReceptionMutation,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';
import { Label } from '~/utils/label';

export const useFetchAndIdentifyMedixsReception = (appointmentId: string | null | undefined) => {
  const { enabledMedixs } = useMedixsSetting();

  const [fetchMedixsReception, { loading: isFetching }] = useFetchMedixsReceptionMutation({
    onError: (e) => {
      console.log(e);
    },
  });
  const [identifyMedixsReception, { loading: isIdentifying }] = useIdentifyMedixsReceptionMutation({
    refetchQueries: [
      { query: GetPatientProfileMedixsReceptionAppointmentDocument, variables: { appointmentId } },
    ],
    onCompleted: () => {
      console.log('自動連携OK');
    },
    onError: () => {
      console.log('自動連携NG');
    },
  });
  const fetchAndIdentifyMedixsReception = useCallback(
    (appointment: MedixsReceptionAppointmentFragment) => {
      if (isFetching || isIdentifying) return;
      const medixsPatient = appointment.patient?.medixsPatient;

      // appointmentが完了・キャンセル状態でなく、
      // かつ、medixs_patient連携済みで、
      // かつ、medixs_reception連携前のときのみ、自動連携処理を実行する
      if (
        appointment.status !== AppointmentStatus.Finished &&
        appointment.status !== AppointmentStatus.Cancelled &&
        appointment.status !== AppointmentStatus.Noshow &&
        enabledMedixs &&
        appointment.createdAt &&
        medixsPatient &&
        !appointment?.medixsReception
      ) {
        console.log('自動連携開始');
        fetchMedixsReception({
          variables: {
            input: {
              date: formatISO(new Date(appointment.createdAt), { representation: 'date' }),
              startAt: Label.HHMM(appointment.createdAt),
              endAt: '23:59',
            },
          },
        }).then(() => {
          if (appointment) {
            identifyMedixsReception({
              variables: {
                input: {
                  appointmentId: appointment.id,
                },
              },
            });
          }
        });
      }
    },
    [enabledMedixs, fetchMedixsReception, identifyMedixsReception, isFetching, isIdentifying],
  );
  const [getAppointment] = useGetPatientProfileMedixsReceptionAppointmentLazyQuery({
    onCompleted: (result) => {
      const appointment = getNode(result, 'Appointment');

      if (appointment) {
        fetchAndIdentifyMedixsReception(appointment);
      }
    },
  });

  useEffect(() => {
    if (appointmentId && enabledMedixs) {
      getAppointment({
        variables: {
          appointmentId,
        },
      });
    }
  }, [appointmentId, enabledMedixs, getAppointment]);
};
