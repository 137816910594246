import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';

import { ChargeModal } from '..';
import { DeleteMedicationFollowupMessageModal } from '../DeleteMedicationFollowupMessageModal';
import { FinishConfirmModal } from './FinishConfirmModal';
import { MedicationFollowupModal } from './MedicationFollowupModal';
import { NotificationConfirmModal } from './NotificationConfirmModal';
import { NotificationCongestionModal } from './NotificationCongestionModal';
import { ProfileDrawer } from './ProfileDrawer';

type DrawerProps = {
  isOpen: boolean;
  appointmentId?: string | null;
  patientId?: string | null;
};

export const PatientProfileDrawer = () => {
  const [state, setState] = useRecoilState(profileDrawerState);
  const [currentProps, setCurrentProps] = useState<DrawerProps>({
    isOpen: state.isOpen,
    appointmentId: state.appointmentId,
    patientId: state.patientId,
  });
  const handleClose = useCallback(() => {
    setCurrentProps((_state) => ({ ..._state, isOpen: false }));
    setState({ ...state, isOpen: false });
  }, [setState, state]);
  const handleTransitionEnd = useCallback(() => {
    setCurrentProps({
      isOpen: state.isOpen,
      appointmentId: state.appointmentId,
      patientId: state.patientId,
    });
  }, [state.appointmentId, state.isOpen, state.patientId]);

  useEffect(() => {
    setCurrentProps((_state) => ({
      ..._state,
      isOpen: false,
    }));
  }, [state.appointmentId, state.patientId]);

  useEffect(() => {
    if (state.isOpen) {
      setCurrentProps({
        isOpen: state.isOpen,
        appointmentId: state.appointmentId,
        patientId: state.patientId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isOpen]);

  if (!currentProps.appointmentId) return null;

  return (
    <>
      <ProfileDrawer
        open={currentProps.isOpen}
        appointmentId={currentProps.appointmentId}
        patientId={currentProps.patientId}
        onClose={handleClose}
        onTransitionEnd={handleTransitionEnd}
      />
      <FinishConfirmModal />
      <ChargeModal />
      <DeleteMedicationFollowupMessageModal />
      <NotificationConfirmModal appointmentId={currentProps.appointmentId} />
      <NotificationCongestionModal />
      <MedicationFollowupModal />
    </>
  );
};
