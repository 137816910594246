import React, { useCallback, useState } from 'react';

import { Box, Grid, Tabs } from '~/components/blocks';
import { TabMap } from '~/constants/tab';
import { KarteAppointmentFragment } from '~/graphql';

import { FilePane } from './FilePane';
import { HistoryPane } from './HistoryPane';
import { QuestionnairePane } from './QuestionnairePane';
import { ReceptionPane } from './ReceptionPane';

type Props = {
  partner: boolean;
  appointment?: KarteAppointmentFragment | null;
  isPartnerActionsPage: boolean;
};

export type TabType = (typeof TabMap)[keyof typeof TabMap];

export const Karte = React.memo((props: Props) => {
  const [tab, setTab] = useState<TabType>('reception');

  const patientId = props.appointment?.patient?.id || undefined;

  const handleChangeTab = useCallback((newTab: TabType) => setTab(newTab), []);
  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.target as HTMLButtonElement;
      const newTab = element.dataset['tab'] as TabType;
      handleChangeTab(newTab);
    },
    [handleChangeTab],
  );

  return (
    <Grid height="100%" overflow="auto" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      <Tabs>
        <Tabs.Tab
          attached
          active={tab === TabMap.reception}
          data-tab={TabMap.reception}
          onClick={handleChange}
          size="xs"
        >
          受付
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab === TabMap.history}
          data-tab={TabMap.history}
          onClick={handleChange}
          size="xs"
        >
          履歴
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab === TabMap.questionnaire}
          data-tab={TabMap.questionnaire}
          onClick={handleChange}
          size="xs"
        >
          問診
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab === TabMap.file}
          data-tab={TabMap.file}
          onClick={handleChange}
          size="xs"
        >
          ファイル
        </Tabs.Tab>
      </Tabs>
      <Box overflow="auto">
        {tab === TabMap.reception && (
          <ReceptionPane
            partner={props.partner}
            appointment={props.appointment}
            onChangeTab={handleChangeTab}
            isPartnerActionsPage={props.isPartnerActionsPage}
          />
        )}
        {tab === TabMap.history && <HistoryPane patientId={patientId} />}
        {tab === TabMap.questionnaire && <QuestionnairePane patientId={patientId} />}
        {tab === TabMap.file && <FilePane patientId={patientId} />}
      </Box>
    </Grid>
  );
});

Karte.displayName = 'Karte';
