import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Flex } from '~/components/blocks';

import { InputDate } from '../../blocks/InputDate';

type Props = {
  disabled?: boolean;
  clearable?: boolean;
  value: Date | null;
  maxDate?: Date | null;
  minDate?: Date | null;
  filterDate?: (date: Date) => boolean;
  onChange: (date: Date | null) => void;
};

const Root = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    borderRadius: theme.radii.default,
    paddingLeft: theme.space.m,
    border: theme.borders.default,
    fontWeight: theme.fontWeights.bold,
    transitionDuration: theme.transitions.default,
    backgroundColor: theme.colors.background.default,
    '&:focus-within': {
      borderColor: theme.colors.border.primary,
    },
    '& .react-datepicker__input-container input': {
      border: 'none',
      maxWidth: '160px',
      lineHeight: '28px',
      [`@media ${theme.mediaQueries.tablet}`]: {
        fontSize: theme.fontSizes.s,
        lineHeight: '24px',
      },
    },
  }),
);

export const SelectDate = React.memo((props: Props) => {
  const { clearable = false, value, onChange, disabled, maxDate, minDate, filterDate } = props;

  return (
    <Root>
      <InputDate
        calendarIcon
        clearable={clearable}
        disabled={disabled}
        value={value}
        onChange={onChange}
        filterDate={filterDate}
        maxDate={maxDate}
        minDate={minDate}
      />
    </Root>
  );
});

SelectDate.displayName = 'SelectDate';
