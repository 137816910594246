import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import Router from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

const animation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const Component = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'hidden',
})<{ hidden: boolean }>(({ theme, hidden }) =>
  css({
    position: 'fixed',
    top: 0,
    left: 0,
    display: hidden ? 'none' : 'block',
    width: '100%',
    height: '2px',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(97deg, transparent, ${theme.colors.colorPallete.green}, transparent)`,
      transform: 'translateX(-100%)',
      animation: `${animation} 1.5s linear infinite`,
    },
  }),
);

export const PageLoadProgress = () => {
  const [loading, setLoading] = useState(false);
  const handleStart = useCallback(() => {
    setLoading(true);
  }, []);
  const handleComplete = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleStart);
    Router.events.on('routeChangeComplete', handleComplete);

    return () => {
      Router.events.off('routeChangeStart', handleStart);
      Router.events.off('routeChangeComplete', handleComplete);
    };
  }, [handleComplete, handleStart]);

  return <Component hidden={!loading} />;
};
