import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ComponentProps } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Box, Chip, Icon, Text } from '~/components/blocks';
import { CheckinEntryStatus } from '~/graphql';

type Props = {
  size?: ComponentProps<typeof Chip>['size'];
  status: CheckinEntryStatus;
};

const NotificationStatus = styled(Box)(({ theme }) =>
  css({
    marginLeft: `calc(${theme.space.m} + ${theme.space.s})`,
    position: 'relative',
    top: '3px',
  }),
);

export const CheckinEntryStatusLabel = (props: Props) => {
  const { status, ...componentProps } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery({ query: theme.mediaQueries.tablet });

  const chipProps: {
    color: ComponentProps<typeof Chip>['color'];
    textColor: ComponentProps<typeof Chip>['textColor'];
    label: string;
  } = {
    color: 'blue',
    label: '指導待ち',
    textColor: 'white',
  };

  const notificationProps: {
    iconColor: ComponentProps<typeof Icon>['color'];
    color: ComponentProps<typeof Text>['color'];
    label: string;
  } = {
    iconColor: 'pink',
    color: 'pink',
    label: '未通知',
  };

  switch (status) {
    case CheckinEntryStatus.Entered:
      chipProps.color = 'blue';
      chipProps.label = '指導待ち';
      break;
    case CheckinEntryStatus.Notified:
      chipProps.color = 'blue';
      chipProps.label = '指導待ち';
      notificationProps.iconColor = 'green';
      notificationProps.color = 'green';
      notificationProps.label = '通知済';
      break;
    case CheckinEntryStatus.Finished:
      chipProps.color = 'grey03';
      chipProps.label = '完了';
      chipProps.textColor = 'black';
      break;
  }

  return (
    <>
      <Box>
        <Chip
          size="fixed"
          {...componentProps}
          color={chipProps.color}
          textColor={chipProps.textColor}
        >
          {chipProps.label}
        </Chip>
      </Box>
      {status !== 'finished' && (
        <NotificationStatus>
          <Icon
            icon="notification"
            size={isTablet ? 's' : 'm'}
            color={notificationProps.iconColor}
          />
          <Text color={notificationProps.color} fontWeight="bold" size={isTablet ? 'xs' : 's'}>
            {notificationProps.label}
          </Text>
        </NotificationStatus>
      )}
    </>
  );
};
