import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Loader } from '~/components/blocks';

const Root = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    height: '200px',
    position: 'relative',
  }),
);

export const DispensingRequestLoader = () => {
  return (
    <Root>
      <Loader open inside appearance="white" logoSize="half" />
    </Root>
  );
};
