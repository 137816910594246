export const ONLY_PERSON = [1, 2];

export const toCreator = (type: number) => {
  switch (type) {
    case 1:
      return '医療関係者';
    case 2:
      return '患者等';
    case 8:
      return 'その他';
    case 9:
      return '不明';
    default:
      return 'ー';
  }
};
