import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Button, Icon } from '~/components/blocks';
import { appPreviewState } from '~/state/partials/app_preview/atoms';

export const Preview = () => {
  const setAppPreview = useSetRecoilState(appPreviewState);

  const handleClick = useCallback(() => setAppPreview({ isOpen: true }), [setAppPreview]);

  return (
    <Button size="m" onClick={handleClick}>
      <Icon size="xl" icon="smartphone" />
      プレビュー
    </Button>
  );
};
