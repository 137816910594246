import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';

import { Box, Collapse as DefaultCollapse } from '~/components/blocks';
import { OtcDrugDosageRecordFragment, OtcDrugIngredientRecordFragment } from '~/graphql';

import { OtcDrug } from './OtcDrug';

type Props = {
  otcDrugs: OtcDrugDosageRecordFragment[];
  otcIngredients: OtcDrugIngredientRecordFragment[];
};

const Collapse = styled(DefaultCollapse)(() =>
  css({
    '& > dt': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      borderBottom: 'none',
      '& > i': {
        marginLeft: 0,
      },
    },
  }),
);

export const OtcDrugList = React.memo((props: Props) => {
  const { otcDrugs, otcIngredients } = props;
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => setIsOpen((_open) => !_open), []);

  return (
    <Collapse open={isOpen} label="一般薬表示" onClick={handleClick}>
      <Box marginTop={theme.space.l}>
        {otcDrugs.map((otcDrug, idx) => {
          const filteredOtcIngredients = otcIngredients.filter(
            (otcIngredient) => otcIngredient.dosageRecordNumber === otcDrug.dosageRecordNumber,
          );
          return <OtcDrug key={idx} otcDrug={otcDrug} otcIngredients={filteredOtcIngredients} />;
        })}
      </Box>
    </Collapse>
  );
});

OtcDrugList.displayName = 'OtcDrugList';
