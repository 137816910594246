import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { marked } from 'marked';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { Box, Flex, Header, Modal, Text } from '~/components/blocks';
import {
  AnnouncementFragment,
  GetAnnouncementQuery,
  useGetAnnouncementLazyQuery,
  useMarkAnnouncementMutation,
} from '~/graphql';
import { announcementModalState } from '~/state/layouts/announcement_modal/atoms';
import { Label } from '~/utils/label';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAnnouncementFragment = (node: any): node is AnnouncementFragment => {
  return node.__typename === 'Announcement';
};

const formatAnnouncement = (data: GetAnnouncementQuery) => {
  if (!isAnnouncementFragment(data.node)) return null;

  const announcement = data.node;
  return {
    id: announcement.id,
    isRead: announcement.isRead,
    isImportant: announcement.type === 'important',
    date: `${Label.YYYYMMDDja(announcement.updatedAt)} ${Label.HHMM(announcement.updatedAt)}`,
    subject: announcement.subject || '',
    content: announcement.content || '',
  };
};

const Content = styled('div')(() =>
  css({
    wordBreak: 'break-all',
    '& ul, ol': {
      margin: 'revert',
      padding: 'revert',
      listStyle: 'revert',
    },
  }),
);

marked.setOptions({
  breaks: true,
});

export const AnnouncementModal = () => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(announcementModalState);

  const [getAnnouncement, { data }] = useGetAnnouncementLazyQuery();
  const [markAnnouncement] = useMarkAnnouncementMutation();

  const announcement = useMemo(() => {
    return data ? formatAnnouncement(data) : null;
  }, [data]);

  useEffect(() => {
    if (state.announcementId) {
      getAnnouncement({ variables: { announcementId: state.announcementId } });
    }
  }, [getAnnouncement, state.announcementId]);

  useEffect(() => {
    if (announcement && !announcement.isRead) {
      markAnnouncement({
        variables: {
          input: {
            announcementId: announcement.id,
          },
        },
      });
    }
  }, [announcement, markAnnouncement]);
  const handleClose = useCallback(
    () =>
      setState((state) => ({
        ...state,
        isOpen: false,
        announcementId: null,
      })),
    [setState],
  );

  return (
    <>
      <Modal open={state.isOpen} onClose={handleClose}>
        <Modal.Header>重要なお知らせ</Modal.Header>
        <Modal.Body minHeight="500px" width="640px">
          <Box margin={theme.space.m} overflow="auto">
            {announcement && (
              <>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text size="xs" color="grey01">
                    {announcement.date}
                  </Text>
                </Flex>
                <Header>{announcement.subject}</Header>
                <Box marginTop={theme.space.l}>
                  <Content dangerouslySetInnerHTML={{ __html: marked(announcement.content) }} />
                </Box>
              </>
            )}
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
};
