import { useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Grid } from '~/components/blocks';
import { medicineNoteNotificationPanelState } from '~/state/partials/medicine_note_notification_panel/atoms';

import { MedicineNoteNotificationFooter } from '../MedicineNoteNotificationFooter';
import { MedicineNoteNotificationList } from '../MedicineNoteNotificationList';
import { NotificationPanel } from '../NotificationPanel';
import { OnetimeCode } from '../OnetimeCode';
import { QrCodeReaderDialog } from '../QrCodeReaderDialog';

export const MedicineNoteNotificationPanel = () => {
  const { isOpen } = useRecoilValue(medicineNoteNotificationPanelState);
  const theme = useTheme();

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(medicineNoteNotificationPanelState),
    [],
  );

  return (
    <>
      <NotificationPanel open={isOpen} onClose={handleClose}>
        <NotificationPanel.Header>開示許可されたお薬手帳</NotificationPanel.Header>
        <NotificationPanel.Content>
          <Grid
            overflow="auto"
            height="100%"
            marginX={theme.space.m}
            gridTemplateRows="min-content 1fr"
          >
            <OnetimeCode />
            <MedicineNoteNotificationList />
          </Grid>
        </NotificationPanel.Content>
        <NotificationPanel.Footer>
          <MedicineNoteNotificationFooter />
        </NotificationPanel.Footer>
      </NotificationPanel>
      <QrCodeReaderDialog />
    </>
  );
};
