import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { Icon, Text } from '~/components/blocks';
import { OrganizationNotificationFragment, OrganizationNotificationType } from '~/graphql';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';

import { ActionSnackbar } from '../ActionSnackbar';

type Props = {
  notification: OrganizationNotificationFragment;
  onClose: (notificationId: string) => void;
};

export const UberDeliveryNotification = React.memo((props: Props) => {
  const { notification, onClose } = props;
  const theme = useTheme();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openProfileDrawer = useRecoilCallback(
    ({ set }) =>
      () => {
        if (router.pathname !== '/reception') {
          router.push('/reception');
          // unmountが完了する前にページ遷移が完了すると通知が再表示されてしまう
          // 通知を管理してる大元のリストから削除し、通知が再表示されないようにする
          onClose(notification.id);
        } else {
          handleClose();
        }

        const appointmentId = notification.appointment?.id;
        const patientId = notification.appointment?.patient?.id;
        if (!appointmentId) return;
        if (!patientId) return;

        set(profileDrawerState, (state) => ({
          ...state,
          isOpen: true,
          patientId,
          appointmentId,
        }));
      },
    [
      router,
      notification.appointment?.patient?.id,
      notification.appointment?.id,
      notification.id,
      onClose,
      handleClose,
    ],
  );

  const handleClick = useCallback(() => {
    openProfileDrawer();
  }, [openProfileDrawer]);

  const handleExited = useCallback(() => {
    onClose(notification.id);
  }, [notification.id, onClose]);

  //スナックバーが画面横から出てくるアニメーションを適応させるためuseEffectで通知を開く
  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <ActionSnackbar
      open={isOpen}
      onExited={handleExited}
      isNotification={false}
      onClick={handleClick}
      onClose={handleClose}
    >
      <Icon
        color="white"
        icon={
          notification.type === OrganizationNotificationType.TimeElapsedUberDelivery ||
          notification.type === OrganizationNotificationType.CanceledUberDelivery ||
          notification.type === OrganizationNotificationType.UnfulfilledUberDelivery ||
          notification.type === OrganizationNotificationType.ReassignedUberDelivery
            ? 'uber-alert'
            : 'uber'
        }
        size="xxl"
      />
      <Text color="white" size="xs" fontWeight="bold" marginLeft={theme.space.m}>
        {notification.content}
      </Text>
    </ActionSnackbar>
  );
});

UberDeliveryNotification.displayName = 'UberDeliveryNotification';
