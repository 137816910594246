import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Button, Modal, Text } from '~/components/blocks';
import { SimplePatientProfile } from '~/components/partials';
import { useFinishEncounterMutation } from '~/graphql';
import { finishConfirmModalState } from '~/state/partials/patient_profile_drawer/atoms';

export const FinishConfirmModal = () => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(finishConfirmModalState);
  const close = useCallback(
    () => setState(() => ({ isOpen: false, encounterId: null, patientId: null, error: null })),
    [setState],
  );

  // 指導待ち -> 未会計 （telemedicine: true）
  const [finishEncounter, { loading: encounterFinishing }] = useFinishEncounterMutation({
    onCompleted: () => close(),
    onError: (error) => setState((_state) => ({ ..._state, error: error.message })),
  });
  const handleEncouterFinish = useCallback(async () => {
    if (state.encounterId) {
      finishEncounter({
        variables: { input: { encounterId: state.encounterId } },
      });
    }
  }, [finishEncounter, state.encounterId]);

  return (
    <Modal open={state.isOpen} size="s" onClose={close}>
      <Modal.Header>
        <Text size="l">服薬指導を完了します</Text>
      </Modal.Header>
      <Modal.Body>
        {state.error && <Alert mb={theme.space.l}>{state.error}</Alert>}
        <Text color="pink" fontWeight="bold" block>
          完了後は、オンライン服薬指導ができなくなります。
        </Text>
        <Box
          mt={theme.space.l}
          backgroundColor={theme.colors.background.bg}
          padding={`${theme.space.l} ${theme.space.l} ${theme.space.s}`}
        >
          <SimplePatientProfile patientId={state.patientId} />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" loading={encounterFinishing} onClick={handleEncouterFinish}>
          完了
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
