import { useEffect } from 'react';

import { useGetMedixPatientProfileLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useFetchMedixsPatient = (isSelected: boolean, patientId?: string) => {
  const [getMedixsPatient, { called, loading, data }] = useGetMedixPatientProfileLazyQuery();
  const medixsPatient = getNode(data, 'Patient')?.medixsPatient;

  useEffect(() => {
    if (isSelected && patientId) {
      getMedixsPatient({
        variables: {
          id: patientId,
        },
      });
    }
  }, [getMedixsPatient, isSelected, patientId]);

  return {
    loading: !called || loading,
    medixsPatient,
  };
};
