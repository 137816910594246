import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';

export const Footer = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    padding: theme.space.l,
    borderTop: theme.borders.default,
    boxShadow: `0 0 8px ${theme.colors.background.bg}`,
    [`@media ${theme.mediaQueries.portrait}`]: {
      padding: `${theme.space.m} ${theme.space.l}`,
    },
  }),
);
