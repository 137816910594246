import { atom } from 'recoil';

import { ChargeModal } from './types';

export const chargeModalState = atom<ChargeModal>({
  key: 'partials.charge.modal',
  default: {
    isOpen: false,
    appointmentId: null,
  },
});
