import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Dialog } from '~/components/blocks';
import { qrCodeReaderDialogState } from '~/state/partials/medicine_note_notification_panel/atoms';

const QrCodeReader = dynamic(() => import('./QrCodeReader'), { ssr: false });

const Message = styled(Box)(({ theme }) =>
  css({
    textAlign: 'center',
    fontSize: theme.fontSizes.m,
    fontWeight: theme.fontWeights.bold,
    borderRadius: theme.radii.default,
    padding: theme.space.m,
    color: theme.colors.text.white,
  }),
);

export const QrCodeReaderDialog = () => {
  const theme = useTheme();

  const { isOpen } = useRecoilValue(qrCodeReaderDialogState);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(qrCodeReaderDialogState);
      },
    [],
  );

  return (
    <Dialog invert open={isOpen} onClose={handleClose}>
      <Dialog.Header>お薬手帳QRコードの読み取り</Dialog.Header>
      <Dialog.Body>
        <Box width="100%">
          <Message>
            赤枠に収まるようにお薬手帳の
            <br />
            QRコードを配置してください
          </Message>
          <Box marginTop={theme.space.m}>
            <QrCodeReader onClose={handleClose} />
          </Box>
        </Box>
      </Dialog.Body>
    </Dialog>
  );
};
