import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
  GetPatientDetailDialogDocument,
  useUpdateGuestPatientFollowupStatusMutation,
  useUpdatePatientFollowupStatusMutation,
} from '~/graphql';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';

export const useUpdateStatus = (
  patientType: 'Patient' | 'DirectVisitor' | 'WebVisitor' | undefined,
) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const patientDetailState = useRecoilValue(patientDetailDialogState);

  const patientId = patientType === 'Patient' ? patientDetailState.patientId : null;
  const guestPatientId =
    patientType === 'DirectVisitor' || patientType === 'WebVisitor'
      ? patientDetailState.patientId
      : null;

  const [updatePatientStatus] = useUpdatePatientFollowupStatusMutation({
    refetchQueries: [
      {
        query: GetPatientDetailDialogDocument,
        variables: {
          id: patientId,
        },
      },
    ],
  });
  const [updateGuestPatientStatus] = useUpdateGuestPatientFollowupStatusMutation({
    refetchQueries: [
      {
        query: GetPatientDetailDialogDocument,
        variables: {
          id: guestPatientId,
        },
      },
    ],
  });

  const update = useCallback(
    async (medicationFollowupStatus: 'in_progress' | 'finished') => {
      setIsUpdating(true);

      try {
        if (patientId) {
          await updatePatientStatus({
            variables: {
              input: {
                patientId,
                medicationFollowupStatus,
              },
            },
          });
        } else if (guestPatientId) {
          await updateGuestPatientStatus({
            variables: {
              input: {
                guestPatientId,
                medicationFollowupStatus,
              },
            },
          });
        }
        setError(null);
        setIsUpdating(false);
      } catch (_error) {
        setError(_error?.message || 'エラーが発生しました');
        setIsUpdating(false);
        throw new Error();
      }
    },
    [patientId, guestPatientId, updatePatientStatus, updateGuestPatientStatus],
  );

  return {
    update,
    updateError: error,
    isUpdating,
  };
};
