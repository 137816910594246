import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { rgba } from 'polished';
import React, { useCallback, useRef, useState } from 'react';
import { PopperChildrenProps, usePopper } from 'react-popper';
import { compose, padding, PaddingProps } from 'styled-system';

import { Portal } from '../Portal';

// FIXME: 全体的に型がよくわからなかったので無理矢理感があるので違ったら修正お願いします

type Props = {
  placement?: PopperChildrenProps['placement'];
  content: React.ReactNode;
  popperStyle?: PaddingProps;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.DetailedHTMLProps<any, any>;
};

type Ref<T extends React.HTMLAttributes<HTMLElement> | null = React.HTMLAttributes<HTMLElement>> =
  | React.RefCallback<T>
  | React.MutableRefObject<T>
  | null;

const setRef = <T extends React.HTMLAttributes<HTMLElement>>(ref: Ref<T>, value: T): void => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

const Popper = styled('div', { shouldForwardProp })(
  ({ theme }) =>
    css({
      background: rgba(theme.colors.background.black, 0.9),
      fontSize: theme.fontSizes.s,
      fontWeight: theme.fontWeights.bold,
      borderRadius: theme.radii.default,
      padding: theme.space.m,
      color: theme.colors.text.white,
      zIndex: theme.zIndices.tooltip,
    }),
  () => compose(padding),
);

export const Tooltip = React.memo((props: Props) => {
  const { onMouseOver, onMouseLeave, ...childProps } = props.children
    .props as React.DOMAttributes<HTMLElement>;
  const [isOpen, setIsOpen] = useState(false);
  const referenceRef = useRef<React.HTMLAttributes<HTMLElement>>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceRef.current as Element, popperElement, {
    placement: props.placement || 'top',
  });
  const handleRef = useCallback(
    (refValue: React.HTMLAttributes<HTMLElement>) => {
      setRef(props.children.ref, refValue);
      setRef(referenceRef as Ref, refValue);
    },
    [props.children.ref, referenceRef],
  );
  const handleEnter = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onMouseOver) {
        onMouseOver(e);
      }
      setIsOpen(true);
    },
    [onMouseOver],
  );
  const handleLeave = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onMouseLeave) {
        onMouseLeave(e);
      }
      setIsOpen(false);
    },
    [onMouseLeave],
  );

  return (
    <>
      {React.cloneElement(props.children as React.ReactElement, {
        ...childProps,
        ref: handleRef,
        onMouseOver: handleEnter,
        onMouseLeave: handleLeave,
      })}
      {isOpen && (
        <Portal>
          <Popper
            ref={setPopperElement}
            style={styles.popper}
            {...props.popperStyle}
            {...attributes.popper}
          >
            {props.content}
          </Popper>
        </Portal>
      )}
    </>
  );
});

Tooltip.displayName = 'Tooltip';
