import { isPast, isSameDay } from 'date-fns';
import { mixed, object, ObjectSchema, Schema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';
import { katakanaRegexp, mobilePhoneRegexp } from '~/constants/regexp';
import { Sex } from '~/graphql';

import { Fields } from './types';

const TODAY = new Date();

export const validationSchema: ObjectSchema<Fields> = object({
  givenName: string().label('氏名').trim().required(FormErrorMessage.required),
  familyName: string().label('氏名').trim().required(FormErrorMessage.required),
  phoneticFamilyName: string()
    .label('氏名（カナ）')
    .trim()
    .required(FormErrorMessage.required)
    .matches(katakanaRegexp, FormErrorMessage.katakana),
  phoneticGivenName: string()
    .label('氏名（カナ）')
    .trim()
    .required(FormErrorMessage.required)
    .matches(katakanaRegexp, FormErrorMessage.katakana),
  birthDate: string()
    .label('生年月日')
    .required(FormErrorMessage.required)
    .test('birthDate', FormErrorMessage.invalidBithDate, (birthDate) => {
      if (!birthDate) return false;

      const date = new Date(birthDate);
      return isPast(date) || isSameDay(date, TODAY);
    }),
  sex: mixed()
    .label('性別')
    .oneOf(Object.values(Sex))
    .required(FormErrorMessage.required) as Schema<Sex>,
  mobilePhone: string()
    .label('電話番号（携帯）')
    .matches(mobilePhoneRegexp, FormErrorMessage.invalid)
    .required(FormErrorMessage.required),
});
