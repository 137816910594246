import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { CSSProperties, useEffect, useMemo } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useRecoilValue } from 'recoil';

import { Box, Portal } from '~/components/blocks';
import {
  GetOrganizationSettingsQuery,
  OrganizationAddressFragment,
  OrganizationContactPoint,
  OrganizationWebSettingFragment,
  useGetOrganizationSettingsLazyQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { appPreviewState } from '~/state/partials/app_preview/atoms';
import { getWorkPhone } from '~/utils/contact_points';

import { Footer } from './Footer';
import { Header } from './Header';
import { Menu } from './Menu';
import { OrganizationDetail } from './OrganizationDetail';

export const WIDTH = '335px';
const HEIGHT = '667px';

const Root = styled('div')(({ theme }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: `calc(${HEIGHT} + ${theme.space.xl})`,
    width: `calc(${WIDTH} + ${theme.space.xl})`,
    zIndex: theme.zIndices.drawer + 1,
    position: 'fixed',
    backgroundColor: theme.colors.background.black,
    borderRadius: '24px',
    top: `calc(${theme.layouts.appHeader.height} + ${theme.space.l})`,
    right: `calc((${WIDTH} + ${theme.space.xl}) * -1)`,
    transition: `right ${theme.transitions.default} ease-out`,
    boxShadow: theme.shadows.secondary,
    [`@media ${theme.mediaQueries.tablet}`]: {
      top: 0,
      right: 0,
      transform: 'scale(0.8)',
    },
    '&:before': {
      content: `""`,
      width: '40%',
      height: '26px',
      backgroundColor: theme.colors.background.black,
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      margin: '0 auto',
      zIndex: 1,
      borderRadius: '0 0 6px 6px',
    },
  }),
);

const Inner = styled('div')(({ theme }) =>
  css({
    color: theme.colors.colorPallete.navy,
    fontSize: theme.fontSizes.xs,
    backgroundColor: theme.colors.background.bg,
    borderRadius: '20px',
    overflow: 'scroll',
    width: WIDTH,
    height: HEIGHT,
  }),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isOrganizationWebSetting = (node: any): node is OrganizationWebSettingFragment => {
  return node && node.__typename === 'WebBookingsSetting';
};

const formatOrganizationName = (data: GetOrganizationSettingsQuery) => {
  return getMe(data)?.organization.name || null;
};

const formatAddress = (data: GetOrganizationSettingsQuery) => {
  const me = getMe(data);

  if (!me) return null;
  const address =
    me.organization.organizationAddresses.length > 0
      ? (me.organization.organizationAddresses[0] as OrganizationAddressFragment).address
      : null;

  return address ? `${address.prefecture}${address.city}${address.line}` : null;
};

const formatGeocoding = (data: GetOrganizationSettingsQuery) => {
  const me = getMe(data);

  if (!me) return null;

  const address =
    me.organization.organizationAddresses.length > 0
      ? (me.organization.organizationAddresses[0] as OrganizationAddressFragment).address
      : null;

  if (!address) return null;

  return address.latitude && address.longitude
    ? { lat: address.latitude, lng: address.longitude }
    : null;
};

const getPhoneNumber = (data: GetOrganizationSettingsQuery) => {
  const me = getMe(data);

  if (!me) return null;

  return me.organization.organizationContactPoints.length > 0
    ? getWorkPhone(me.organization.organizationContactPoints as OrganizationContactPoint[])
    : null;
};

const formatWebBookingsSetting = (data: GetOrganizationSettingsQuery) => {
  const me = getMe(data);

  if (!me) return null;
  if (!isOrganizationWebSetting(me.organization.webBookingsSetting)) return null;

  return {
    ...me.organization.webBookingsSetting,
    logo: me.organization.webBookingsSetting.logo
      ? me.organization.webBookingsSetting.logo.url
      : process.env.defaultLogoPath,
  };
};

export const AppPreview = () => {
  const theme = useTheme();
  const appPreview = useRecoilValue(appPreviewState);

  const [getOrganizationSettings, { data }] = useGetOrganizationSettingsLazyQuery();
  const organizationName = useMemo(() => (data ? formatOrganizationName(data) : null), [data]);
  const setting = useMemo(() => (data ? formatWebBookingsSetting(data) : null), [data]);
  const address = useMemo(() => (data ? formatAddress(data) : null), [data]);
  const geocoding = useMemo(() => (data ? formatGeocoding(data) : null), [data]);
  const phoneNumber = useMemo(() => (data ? getPhoneNumber(data) : null), [data]);

  const transitionStyles = useMemo<Partial<Record<TransitionStatus, CSSProperties>>>(
    () => ({
      entering: { right: `-450px` },
      entered: { right: theme.space.xl },
      exiting: { right: theme.space.xl },
      exited: { right: `-450px` },
    }),
    [theme.space.xl],
  );

  useEffect(() => {
    if (appPreview.isOpen) {
      getOrganizationSettings();
    }
  }, [appPreview.isOpen, getOrganizationSettings]);

  return (
    <Portal>
      <Transition in={appPreview.isOpen} timeout={0}>
        {(state) => (
          <Root style={{ ...transitionStyles[state] }}>
            <Inner style={{ overflowX: 'hidden' }}>
              <Header name={organizationName} />
              <Box>
                <OrganizationDetail
                  address={address}
                  name={organizationName}
                  description={setting?.description}
                  logo={setting?.logo}
                />
                <Menu facetoface={setting?.facetoface} telemedicine={setting?.telemedicine} />
              </Box>
              <Footer
                address={address}
                phoneNumber={phoneNumber}
                openingHours={setting?.openingHours}
                website={setting?.website}
                geocoding={geocoding}
              />
            </Inner>
          </Root>
        )}
      </Transition>
    </Portal>
  );
};
