import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { useCallback, useMemo } from 'react';
import { compose, space, variant } from 'styled-system';

import { Button } from '../Button';
import { Grid } from '../Grid';
import { Icon } from '../Icon';
import { getPaginationPages } from './pagination';

type SizeProps = {
  size?: 's' | 'm';
};

type Props = {
  totalPage: number;
  currentPage: number;
  siblingCount?: number;
  onChange: (page: number) => void;
} & SizeProps;

const ArrowButton = styled(Button)(({ theme }) =>
  css({
    width: '40px',
    background: theme.colors.background.default,
    [`svg:first-of-type`]: {
      marginRight: 0,
    },
    '&:disabled': {
      background: theme.colors.background.disabled,
      color: theme.colors.colorPallete.grey03,
      borderColor: 'transparent',
      opacity: '0.5',
    },
    [`@media ${theme.mediaQueries.tablet}`]: {
      width: '20px',
      height: '34px',
      paddingLeft: theme.space.m,
      paddingRight: theme.space.m,
      fontSize: theme.fontSizes.xxs,
    },
  }),
);

const Dot = styled('div')(({ theme }) =>
  css({
    border: theme.borders.default,
    padding: `${theme.space.m} ${theme.space.l}`,
    width: '40px',
    fontSize: theme.fontSizes.s,
    fontWeight: theme.fontWeights.bold,
    background: theme.colors.background.default,
    borderRadius: theme.radii.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media ${theme.mediaQueries.tablet}`]: {
      width: '24px',
      fontSize: theme.fontSizes.xxs,
    },
  }),
);

const PageNumber = styled(Button)(({ theme }) =>
  css({
    width: '40px',
    [`@media ${theme.mediaQueries.tablet}`]: {
      width: '24px',
      fontSize: theme.fontSizes.xxs,
    },
  }),
);

const Root = styled(Grid, {
  shouldForwardProp,
})<SizeProps>(
  () =>
    css({
      alignItems: 'center',
      gridAutoColumns: 'min-content',
      gridAutoFlow: 'column',
      justifyContent: 'flex-start',
    }),
  ({ theme }) =>
    compose(
      space,
      variant({
        prop: 'size',
        variants: {
          s: css({
            [`& > ${ArrowButton}`]: {
              width: '20px',
              height: '34px',
              paddingLeft: theme.space.m,
              paddingRight: theme.space.m,
              fontSize: theme.fontSizes.xxs,
            },
            [`& > ${Dot}`]: {
              width: '24px',
              fontSize: theme.fontSizes.xxs,
            },
            [`& > ${PageNumber}`]: {
              width: '24px',
              fontSize: theme.fontSizes.xxs,
            },
          }),
        },
      }),
    ),
);

export const Pagination = (props: Props) => {
  const { size = 'm', onChange } = props;

  const theme = useTheme();

  const isFirst = props.currentPage === 1;
  const isLast = props.totalPage === props.currentPage;
  const displayPages = useMemo(() => {
    return getPaginationPages(props.totalPage, props.currentPage, props.siblingCount || 0, 1);
  }, [props.totalPage, props.currentPage, props.siblingCount]);

  const handlePrevClick = useCallback(
    () => onChange(props.currentPage - 1),
    [onChange, props.currentPage],
  );
  const handleNextClick = useCallback(
    () => onChange(props.currentPage + 1),
    [onChange, props.currentPage],
  );
  const handlePageClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const pageBtn = e.target as HTMLButtonElement;
      const page = +(pageBtn.dataset['page'] || 0);

      if (!page || page === props.currentPage) return;

      onChange(+page);
    },
    [onChange, props.currentPage],
  );

  return (
    <Root gridColumnGap={theme.space.s} size={size}>
      <ArrowButton disabled={isFirst} onClick={handlePrevClick}>
        <Icon icon="arrow-left" size="l" />
      </ArrowButton>
      {displayPages.map((page, i) => {
        if (!page) {
          return <Dot key={`pagination-placeholder-${i}`}>…</Dot>;
        }
        const isCurrnetPage = page === props.currentPage;

        return (
          <PageNumber
            key={page}
            use={isCurrnetPage ? 'base' : 'default'}
            data-page={page}
            onClick={handlePageClick}
          >
            {page}
          </PageNumber>
        );
      })}
      <ArrowButton disabled={isLast} onClick={handleNextClick}>
        <Icon icon="arrow-right" size="l" />
      </ArrowButton>
    </Root>
  );
};
