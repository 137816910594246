import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Flex, Pagination } from '~/components/blocks';
import { tracingReportPageInfoState } from '~/state/partials/tracingreport_panel/atoms';

export const HistoryPagination = () => {
  const theme = useTheme();

  const [pageInfo, setPageInfo] = useRecoilState(tracingReportPageInfoState);
  const handleChangePage = useCallback(
    (page: number) => setPageInfo((_state) => ({ ..._state, page })),
    [setPageInfo],
  );

  return (
    <Flex justifyContent="center" marginTop={theme.space.l}>
      <Pagination
        currentPage={pageInfo.page}
        totalPage={pageInfo.totalPage}
        onChange={handleChangePage}
      />
    </Flex>
  );
};
