import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { compose, variant } from 'styled-system';

import { CsClinicItemFragment } from '~/graphql';

type Props = {
  size?: 's';
  clinic: CsClinicItemFragment;
  onSelect?: (clinic: CsClinicItemFragment) => void;
};

const Description = styled('dl')(({ theme }) =>
  css({
    marginLeft: theme.space.l,
  }),
);

const Name = styled('dt')(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    fontWeight: theme.fontWeights.bold,
  }),
);

const Contact = styled('dd')(({ theme }) =>
  css({
    marginTop: theme.space.xs,
    color: theme.colors.colorPallete.grey,
    fontSize: theme.fontSizes.xs,
  }),
);

const Category = styled('dd')(({ theme }) =>
  css({
    color: theme.colors.colorPallete.grey,
    fontSize: theme.fontSizes.xs,
  }),
);

const Logo = styled('img')(() =>
  css({
    width: '58px',
    height: '58px',
  }),
);

const Root = styled('div')<{ clickable: boolean; size: 's' | 'm' }>(
  ({ theme, clickable }) =>
    css({
      alignItems: 'center',
      cursor: clickable ? 'pointer' : 'inherit',
      display: 'flex',
      padding: `${theme.space.s} ${theme.space.m}`,
      '& + &': {
        borderTop: theme.borders.grey,
      },
    }),
  ({ theme }) =>
    compose(
      variant({
        prop: 'size',
        variants: {
          s: css({
            [`& > ${Logo}`]: {
              width: '48px',
              height: '48px',
            },
            [`& > ${Description} > ${Name}`]: {
              fontSize: theme.fontSizes.xs,
            },
            [`& > ${Description} > ${Category}`]: {
              fontSize: theme.fontSizes.xxs,
            },
            [`& > ${Description} > ${Contact}`]: {
              fontSize: theme.fontSizes.xxs,
            },
          }),
        },
      }),
    ),
);

export const CsClinic = (props: Props) => {
  const { clinic, size = 'm' } = props;

  return (
    <Root
      clickable={!!props.onSelect}
      size={size}
      onClick={() => (props.onSelect ? props.onSelect(clinic) : undefined)}
    >
      <Logo src={clinic.logoImageUrl} />
      <Description>
        <Name>{clinic.name}</Name>
        <Contact>{`${clinic.address}・${clinic.tel}`}</Contact>
        <Category>
          {clinic.subCategories.length > 0
            ? clinic.subCategories.map(({ name }) => name).join(' / ')
            : 'ー'}
        </Category>
      </Description>
    </Root>
  );
};
