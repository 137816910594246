import { useTheme } from '@emotion/react';
import React, { useCallback, useRef } from 'react';

import { Alert, Box, Grid, List, ScrollBox, Text } from '~/components/blocks';
import {
  DirectVisitorFollowupMessageFragment,
  PatientFollowupMessageFragment,
  WebVisitorFollowupMessageFragment,
} from '~/graphql';
import { Panel, PanelType } from '~/state/partials/message_modal/types';

import { FollowupMessage } from './FollowupMessage';
import { Message } from './Message';

type Props = {
  loading: boolean;
  messages:
    | DirectVisitorFollowupMessageFragment[]
    | PatientFollowupMessageFragment[]
    | WebVisitorFollowupMessageFragment[];
  onClick?: (panel: PanelType) => void;
};

export const MessageList = React.memo((props: Props) => {
  const { loading, messages, onClick } = props;
  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const handleClick = useCallback(() => {
    onClick && onClick(Panel.initial);
  }, [onClick]);

  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr" gridTemplateColumns="1fr">
      <Text block color="grey01" fontWeight="bold" size="s">
        メッセージ履歴（最新10件）
      </Text>
      <ScrollBox ref={scrollRef} loading={loading}>
        <Box height="100%" overflow="auto">
          {messages.length > 0 && (
            <List selectable={!!onClick}>
              {messages.map((message, idx) => {
                const sendAt = message.messageDeliverResult?.updatedAt || message.sendAt;
                if (message.medicationFollowupQuestionnaireRevision) {
                  return (
                    <FollowupMessage
                      key={idx}
                      answered={!!message.medicationFollowupQuestionnaireSheet?.id}
                      unread={!message.isRead && !!message.medicationFollowupQuestionnaireSheet?.id}
                      sendAt={sendAt}
                      onClick={onClick ? handleClick : undefined}
                    />
                  );
                } else {
                  return (
                    <Message
                      key={idx}
                      sendAt={sendAt}
                      onClick={onClick ? handleClick : undefined}
                    />
                  );
                }
              })}
            </List>
          )}
          {!loading && messages.length === 0 && (
            <Alert status="info" marginTop={theme.space.l}>
              メッセージはありません
            </Alert>
          )}
        </Box>
      </ScrollBox>
    </Grid>
  );
});

MessageList.displayName = 'MessageList';
