import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';

import { Button, Flex, Icon, Text } from '~/components/blocks';
import { ScheduledDirectVisitorMessageFragment } from '~/graphql';
import { deleteMedicationFollowupMessageModalState } from '~/state/partials/delete_medication_followup_message_modal2/atoms';
import { Panel, PanelType } from '~/state/partials/message_modal/types';
import { Label } from '~/utils/label';

type Props = {
  clickable: boolean;
  message: ScheduledDirectVisitorMessageFragment;
  onClick?: (panel: PanelType, messageId: string) => void;
};

const Root = styled(Flex)<{ clickable: boolean }>(({ theme, clickable }) =>
  css({
    border: theme.borders.default,
    borderRadius: theme.radii.default,
    justifyContent: 'space-between',
    transitionDuration: theme.transitions.default,
    ...(clickable && {
      '&:hover': {
        borderColor: theme.colors.border.primary,
        cursor: 'pointer',
      },
    }),
    '& + &': {
      marginTop: theme.space.m,
    },
  }),
);

const ButtonWrapper = styled('div')(({ theme }) =>
  css({
    borderLeft: `1px solid ${theme.colors.border.default}`,
    display: 'flex',
  }),
);

export const ScheduledMessage = React.memo((props: Props) => {
  const { clickable, message, onClick } = props;
  const theme = useTheme();

  const handleOpenMessageModal = useCallback(() => {
    const panel = Panel.input;
    onClick && onClick(panel, message.id);
  }, [message.id, onClick]);
  const handleOpenDeleteModal = useRecoilCallback(
    ({ set }) =>
      () => {
        set(deleteMedicationFollowupMessageModalState, {
          isOpen: true,
          medicationFollowupMessageId: message.id,
        });
      },
    [message.id],
  );

  return (
    <Root clickable={clickable}>
      <Flex
        width="100%"
        alignItems="center"
        padding={theme.space.m}
        paddingLeft={theme.space.l}
        onClick={handleOpenMessageModal}
      >
        <Text block>{`${Label.YYYYMMDDja(message.sendAt)} ${Label.HHMM(message.sendAt)}`}</Text>
      </Flex>
      {clickable && (
        <ButtonWrapper>
          <Button use="white" onClick={handleOpenDeleteModal}>
            <Icon size="m" icon="trash" />
          </Button>
        </ButtonWrapper>
      )}
    </Root>
  );
});

ScheduledMessage.displayName = 'ScheduledFollowupMessage';
