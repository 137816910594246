import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';

import { Button, Text } from '~/components/blocks';
import { Notice } from '~/components/layouts';

type Props = {
  message: string;
  linkText: string;
  link: string;
};

export const Reentry: React.FC<Props> = (props) => {
  const { message, link, linkText } = props;
  const theme = useTheme();

  const handleClick = useCallback(() => {
    window.location.href = link;
  }, [link]);

  return (
    <Notice>
      <Text mb={theme.space.xl}>{message}</Text>
      {link && linkText && (
        <Button use="base" size="l" align="center" onClick={handleClick}>
          {linkText}
        </Button>
      )}
    </Notice>
  );
};
