import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import generatedIntrospection from '~/graphql/fragment';
import { clearJWT, getJWT } from '~/localstrage/jwt';
import { isIpad } from '~/utils/user_agent';

export const createApolloClient = () => {
  const httpLink = new HttpLink({
    uri: `${process.env.apiBaseUrl}/graphql`,
  });

  const authLink = new ApolloLink((operation, forward) => {
    const jwt = getJWT();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation.setContext(({ headers }: Record<string, any>) => {
      const contextHeader = {
        headers: {
          ...headers,
          credentials: 'include',
          Authorization: jwt ? `Bearer ${jwt}` : '',
        },
      };

      if (isIpad()) {
        // kinesissToBigQueryでdevice判定してる処理を将来的にフロントに移す予定
        // iPadOSだけはdevice判定できないため、先に移した
        contextHeader['headers']['X-Device-Type'] = 'tablet';
      }

      return contextHeader;
    });

    return forward(operation);
  });

  let hasRedirected = false;
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    let isAuthError = false;

    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        // app/graphql/pharms_schema.rb
        isAuthError = isAuthError || extensions?.code === 'UNAUTHENTICATED';
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      isAuthError = isAuthError || (networkError as ServerError).statusCode === 401;
    }

    if (isAuthError && !hasRedirected) {
      hasRedirected = true;

      const hasExistJwt = !!getJWT();

      clearJWT();

      window.location.href = hasExistJwt
        ? `/login?next=${encodeURIComponent(window.location.href)}`
        : '/login';
    }
  });

  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      WebBookingsSetting: {
        fields: {
          webBookingsExceptionBusinessHours: {
            merge: (_, incoming) => {
              return incoming;
            },
          },
        },
      },
      WebBookingsBusinessHour: {
        fields: {
          times: {
            merge: (_, incoming) => {
              return incoming;
            },
          },
        },
      },
      WebBookingsHolidayBusinessHour: {
        fields: {
          times: {
            merge: (_, incoming) => {
              return incoming;
            },
          },
        },
      },
      WebBookingsExceptionBusinessHour: {
        fields: {
          times: {
            merge: (_, incoming) => {
              return incoming;
            },
          },
        },
      },
      Mutation: {
        fields: {
          activateOtpSecret: {
            keyArgs: () => 'FILTERED',
          },
          upsertOrganization: {
            keyArgs: () => 'FILTERED',
          },
          sendPractitionerCodeMail: {
            keyArgs: () => 'FILTERED',
          },
          updatePractitionerAuthentication: {
            keyArgs: () => 'FILTERED',
          },
        },
      },
    },
  });

  const client = new ApolloClient({
    ssrMode: false,
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache,
    headers: {},
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
    connectToDevTools: true,
  });

  return client;
};
