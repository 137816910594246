import { object, ObjectSchema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';
import { twoFactorOnetimeCodeRegexp } from '~/constants/regexp';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  onetimeCode: string()
    .label('コード')
    .required(FormErrorMessage.required)
    .trim()
    .length(6, FormErrorMessage.length)
    .matches(twoFactorOnetimeCodeRegexp, FormErrorMessage.number),
});
