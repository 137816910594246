import { PureQueryOptions } from '@apollo/client';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  GetDirectVisitorMessagesDocument,
  GetEventsDocument,
  GetMessagesDocument,
  GetPatientMessagesDocument,
  GetWebVisitorMessagesDocument,
} from '~/graphql';
import { messagePageInfoState } from '~/state/message/atoms';
import { messageModalState } from '~/state/partials/message_modal/atoms';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  guestPatientProfileDrawerState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { formatISOString } from '~/utils/date';

export const useBuildRefetchQueries = () => {
  const messageState = useRecoilValue(messagePageInfoState);
  const modalState = useRecoilValue(messageModalState);
  const directVisitorDrawerState = useRecoilValue(guestPatientProfileDrawerState);
  const patientDrawerState = useRecoilValue(profileDrawerState);
  const webVisitorDrawerState = useRecoilValue(webVisitorProfileDrawerState);

  const refetchQueries: PureQueryOptions[] = useMemo(() => {
    const queries: PureQueryOptions[] = [
      {
        query: GetMessagesDocument,
        variables: {
          ...messageState,
          date: formatISOString(messageState.date),
        },
      },
    ];

    if (!modalState.profile) return queries;

    const guestPatientId =
      modalState.profile?.type !== 'Patient' ? modalState.patientId : undefined;
    const patientId = modalState.profile?.type === 'Patient' ? modalState.patientId : undefined;

    queries.push({
      query: GetEventsDocument,
      variables: {
        patientId,
        guestPatientId,
        page: modalState.page,
        perPage: modalState.perPage,
      },
    });

    if (directVisitorDrawerState.isOpen && directVisitorDrawerState.guestPatientId) {
      queries.push({
        query: GetDirectVisitorMessagesDocument,
        variables: { id: directVisitorDrawerState.guestPatientId },
      });
    }

    if (webVisitorDrawerState.isOpen && webVisitorDrawerState.webVisitorId) {
      queries.push({
        query: GetWebVisitorMessagesDocument,
        variables: { id: webVisitorDrawerState.webVisitorId },
      });
    }

    if (patientDrawerState.isOpen && patientDrawerState.patientId) {
      queries.push({
        query: GetPatientMessagesDocument,
        variables: { id: patientDrawerState.patientId },
      });
    }

    return queries;
  }, [
    directVisitorDrawerState.guestPatientId,
    directVisitorDrawerState.isOpen,
    messageState,
    modalState.page,
    modalState.patientId,
    modalState.perPage,
    modalState.profile,
    patientDrawerState.isOpen,
    patientDrawerState.patientId,
    webVisitorDrawerState.isOpen,
    webVisitorDrawerState.webVisitorId,
  ]);

  return refetchQueries;
};
