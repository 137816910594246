import { useEffect } from 'react';

import { useGetPatientStatusLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useConfirmPatientStatus = (patientId: string) => {
  const [getPatient, { data, loading }] = useGetPatientStatusLazyQuery();
  const isActivePatient = !!getNode(data, 'Patient')?.active;

  useEffect(() => {
    if (patientId) {
      getPatient({ variables: { patientId } });
    }
  }, [getPatient, patientId]);

  return { isActivePatient, loading };
};
