import React, { useCallback } from 'react';

import { Button, Icon, Tooltip } from '~/components/blocks';
import { DEFAULT_WINDOW } from '~/components/partials';
import {
  AppointmentStatus,
  KarteHiccupAppointmentPermissionFragment,
  KartePatientFragment,
} from '~/graphql';
import { openWithNoOpener } from '~/utils/window';

type Props = {
  patient?: KartePatientFragment | null;
  permission?: KarteHiccupAppointmentPermissionFragment | null;
  status: AppointmentStatus;
};

export const MedicineNoteButton = React.memo((props: Props) => {
  const { patient, permission } = props;

  const patientId = patient?.id;

  const handleClick = useCallback(() => {
    if (patientId) {
      openWithNoOpener(`/medicinenote/patients/${patientId}`, {
        target: patientId,
        width: DEFAULT_WINDOW.width,
        height: DEFAULT_WINDOW.height,
      });
    }
  }, [patientId]);

  if (!patient?.active) {
    return (
      <Tooltip
        content={
          <>
            退会済のため、
            <br />
            お薬手帳の閲覧はできません
          </>
        }
      >
        <div>
          <Button wide="fill" disabled>
            <Icon icon="medicalnote" size="xxl" />
            お薬手帳
          </Button>
        </div>
      </Tooltip>
    );
  }

  return permission ? (
    <Tooltip
      content={
        <>
          会計完了または2週間経過した
          <br />
          お薬手帳は閲覧できません
        </>
      }
    >
      <div>
        <Button
          wide="fill"
          disabled={!permission.active}
          onClick={permission.active ? handleClick : undefined}
        >
          <Icon icon="medicalnote" size="xxl" />
          お薬手帳
        </Button>
      </div>
    </Tooltip>
  ) : (
    <Tooltip
      content={
        <>
          患者のCLINICSアプリにて、
          <br />
          お薬手帳の閲覧が許可されていないため
          <br />
          表示できません
        </>
      }
    >
      <div>
        <Button wide="fill" disabled>
          <Icon icon="medicalnote" size="xxl" />
          お薬手帳
        </Button>
      </div>
    </Tooltip>
  );
});

MedicineNoteButton.displayName = 'MedicineNoteButton';
