import { atom } from 'recoil';

import { OrganizationNotificationDrawer } from './types';

export const organizationNotificationDrawerState = atom<OrganizationNotificationDrawer>({
  key: 'partials.organizationNotificationDrawer',
  default: {
    page: 1,
    perPage: 6,
    totalPage: 1,
    totalCount: 0,
  },
});
