import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { NextPage } from 'next';
import React from 'react';

import { createApolloClient } from './apollo_client';

// https://github.com/vercel/next.js/discussions/11957#discussioncomment-7190

export let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const initApolloClient = () => {
  if (!apolloClient) {
    apolloClient = createApolloClient();
  }

  return apolloClient;
};

export const withApollo = (PageComponent: NextPage) => {
  const WithApollo = ({ ...pageProps }) => {
    // HERE WE USE THE PASSED CACHE
    const client = initApolloClient();
    // and here we have the initialized client 🙂
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    );
  };

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';

    WithApollo.displayName = `withApollo(${displayName})`;
  }
  return WithApollo;
};
