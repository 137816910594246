import styled from '@emotion/styled';
import { css } from '@styled-system/css';

const Item = styled('li')(({ theme }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    ['svg']: {
      marginRight: theme.space.s,
    },
    [`@media ${theme.mediaQueries.tablet}`]: {
      fontSize: theme.fontSizes.s,
    },
  }),
);

const Root = styled('ul')(({ theme }) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: `${theme.space.m} 0`,
    [`& > ${Item} + ${Item}`]: {
      marginLeft: theme.space.m,
    },
  }),
);
export const ProfileDetail = Object.assign(Root, { Item });
