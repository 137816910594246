import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Flex, Text } from '~/components/blocks';

type Props = {
  error: Error;
};

export const ExclusiveLockError = (props: Props) => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" justifyContent="center" height="100%">
      <Alert status="error" m={theme.space.xxl}>
        <Text block textAlign="left" size="m" fontWeight="bold">
          {props.error.message}
        </Text>
        <Text block textAlign="left">
          別画面のメッセージ作成を停止後、再度お試しください
        </Text>
      </Alert>
    </Flex>
  );
};
