import styled from '@emotion/styled';
import { Options } from '@popperjs/core';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { useCallback } from 'react';
import { usePopper } from 'react-popper';
import { compose, width, WidthProps } from 'styled-system';

import { Portal } from '../Portal';

type ComponentProps = {
  className?: string;
  stopPropagation?: boolean;
  anckerEl: HTMLElement;
  placement?: Options['placement'];
  children?: React.ReactNode;
};

type Props = WidthProps &
  ComponentProps & {
    open: boolean;
    inside?: boolean;
  };

const Component: React.FC<ComponentProps> = (props) => {
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      props.stopPropagation && e.stopPropagation();
    },
    [props.stopPropagation],
  );
  const { styles, attributes } = usePopper(props.anckerEl, popperElement, {
    placement: props.placement || 'auto',
  });

  return (
    <div
      ref={setPopperElement}
      className={props.className}
      style={styles.popper}
      {...attributes.popper}
      onClick={handleClick}
    >
      {props.children}
    </div>
  );
};

const StyledComponent = styled(Component, {
  shouldForwardProp: (propName: string) =>
    shouldForwardProp(propName) ||
    ['className', 'stopPropagation', 'anckerEl', 'placement'].includes(propName),
})<WidthProps>(
  ({ theme }) =>
    css({
      background: theme.colors.background.default,
      boxShadow: theme.shadows.default,
      borderRadius: theme.radii.default,
      zIndex: theme.zIndices.tooltip,
    }),
  () => compose(width),
);

export const Popover: React.FC<Props> = (props) => {
  if (!props.open) {
    return null;
  }

  return props.inside ? (
    <StyledComponent {...props} />
  ) : (
    <Portal>
      <StyledComponent {...props} />
    </Portal>
  );
};
