import React from 'react';

import { Tabs } from '~/components/blocks';

import { HrefProps } from '../types';
import { MenuLink } from './MenuLink';

const MENU_ROUTES: { [key: string]: [HrefProps, ...HrefProps[]] } = {
  messages: [
    { path: '/settings/messages/message_templates', exact: true },
    { path: '/settings/messages', exact: true },
  ],
  medicationFollowupQuestionnaires: [
    { path: '/settings/messages/medication_followup_questionnaires' },
  ],
};

export const RootMenu = () => {
  return (
    <Tabs>
      <MenuLink href={MENU_ROUTES.messages}>メッセージ</MenuLink>
      <MenuLink href={MENU_ROUTES.medicationFollowupQuestionnaires}>質問票</MenuLink>
    </Tabs>
  );
};
