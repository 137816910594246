import React from 'react';

import { Flex, Icon, Text } from '~/components/blocks';
import { Size } from '~/styles/types';

type Props = {
  error: string;
  fontSize?: Size;
};

export const FieldError = React.memo((props: Props) => {
  const { error, fontSize = 'm' } = props;

  return (
    <Flex alignItems="center">
      <Icon size="l" icon="attention" />
      <Text whiteSpace="break-spaces" color="pink" size={fontSize}>
        {error}
      </Text>
    </Flex>
  );
});

FieldError.displayName = 'FieldError';
