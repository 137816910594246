import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { AppointmentStatus, useGetPreparedTelemedicineAppointmentLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { telemedicineAppointmentsState } from '~/state/layouts/SharedAppShell/atoms';

type AppointmentEvent = {
  appointmentId: string;
  type: string;
};

export const useSubscriptionTelemedicineAppointment = () => {
  const [appointmentEvents, setAppointmentEvents] = useState<AppointmentEvent[]>([]);
  const [{ isEnabled, appointments }, setState] = useRecoilState(telemedicineAppointmentsState);
  const [event] = useSubscriptions('Appointment', ['prepared_telemedicine', 'updated']);

  const [getAppointment] = useGetPreparedTelemedicineAppointmentLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: (_result) => {
      const _appointment = getNode(_result, 'Appointment');
      if (!_appointment) return;

      const currentEvent = appointmentEvents.find(
        ({ appointmentId }) => appointmentId === _appointment.id,
      );
      setAppointmentEvents((_state) =>
        _state.filter(({ appointmentId }) => appointmentId !== _appointment.id),
      );

      if (currentEvent?.type === 'prepared_telemedicine') {
        setState((_state) => ({
          ..._state,
          appointments: [..._state.appointments, _appointment],
        }));
      }

      if (
        currentEvent?.type === 'updated' &&
        _appointment.status !== AppointmentStatus.Pending &&
        _appointment.status !== AppointmentStatus.Booked
      ) {
        // 服薬指導が完了したら、準備完了通知を消す
        setState((_state) => ({
          ..._state,
          appointments: _state.appointments.filter(({ id }) => id !== _appointment.id),
        }));
      }
    },
  });

  const handleDelete = useCallback(
    (appointmentId: string) => {
      const newAppointments = appointments.filter(({ id }) => id !== appointmentId);
      setState((_state) => ({ ..._state, appointments: newAppointments }));
    },
    [appointments, setState],
  );

  const fetch = useCallback(
    async (id: string) => {
      // FIXME: 連続でクエリ実行すると自動でクエリがcancelされるため、
      // 間隔を開けて実行する
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          getAppointment({
            variables: {
              id,
            },
          });
          resolve();
        }, 500);
      });
    },
    [getAppointment],
  );

  useEffect(() => {
    if (isEnabled && event) {
      setAppointmentEvents((_state) => [
        ..._state,
        { appointmentId: event.typeId, type: event.actionName },
      ]);
    }
  }, [event, getAppointment, isEnabled]);

  useEffect(() => {
    const fetchData = async () => {
      for (const event of appointmentEvents) {
        await fetch(event.appointmentId);
      }
    };
    fetchData();
  }, [appointmentEvents, fetch]);

  return {
    handleDelete,
  };
};
