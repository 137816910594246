import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Label } from '~/utils/label';

import { LineItem, OptionalLineItem } from '..';

type Props = {
  lineItems: Array<LineItem | OptionalLineItem>;
  uberDeliveryFee?: number;
};

const Root = styled('dl')(
  ({ theme }) =>
    () =>
      css({
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-end',
        marginTop: theme.space.m,
        padding: `0 ${theme.space.l}`,
        '& > dt': {
          fontSize: theme.fontSizes.m,
        },
        '& > dd': {
          marginLeft: theme.space.l,
          fontSize: theme.fontSizes.xxl,
          fontWeight: theme.fontWeights.bold,
        },
      }),
);

export const TotalAmount = React.memo((props: Props) => {
  const totalAmount = props.lineItems.reduce((result, { amount = 0 }) => {
    if (+amount > 0) {
      return result + +amount;
    } else {
      return result;
    }
  }, 0);

  return (
    <Root>
      <dt>合計</dt>
      <dd>{Label.amount(totalAmount || 0)}</dd>
    </Root>
  );
});

TotalAmount.displayName = 'TotalAmount';
