import React from 'react';
import { useRecoilValue } from 'recoil';

import { agentNotificationsState } from '~/state/layouts/SharedAppShell/atoms';

import { AgentNotification } from './AgentNotification';
import { useSubscriptionAgentNotifications } from './use-subscription-agent-notifications';

export const AgentNotifications = () => {
  const { notifications } = useRecoilValue(agentNotificationsState);
  const { handleClose } = useSubscriptionAgentNotifications();

  return (
    <>
      {notifications.map((notification) => (
        <AgentNotification
          key={notification.id}
          notification={notification}
          onClose={handleClose}
        />
      ))}
    </>
  );
};
