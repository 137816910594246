import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Button, Text } from '~/components/blocks';

type Props = {
  children: React.ReactNode;
  description: React.ReactNode;
  onClick: () => void;
};

const Description = styled(Text)(() =>
  css({
    display: 'block',
  }),
);

const Root = styled(Button)(({ theme }) =>
  css({
    display: 'block',
    padding: theme.space.l,
    '& + &': {
      marginTop: theme.space.xl,
    },
  }),
);

export const CreateButton: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Root wide="fill" onClick={props.onClick}>
      {props.children}
      <Description size="xs" fontWeight="default" marginTop={theme.space.l}>
        {props.description}
      </Description>
    </Root>
  );
};

CreateButton.displayName = 'CreateButton';
