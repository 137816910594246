import { atom } from 'recoil';

import { EditMedicationFollowupStatusDialog } from './types';

export const editMedicationFollowupStatusDialogState = atom<EditMedicationFollowupStatusDialog>({
  key: 'settings.message.editMedicationFollowupStatusDialog',
  default: {
    className: null,
  },
});

export const medicationFollowupSettingIdState = atom<string | null>({
  key: 'settings.message.medicationFollowupSettingId',
  default: null,
});
