import { useCallback, useRef } from 'react';

const TRANSITION_DURATION = 200;
const FPS = 60;

const scrollAnimation = (containerElem: HTMLElement, moveY: number) => {
  const scrollTop = containerElem.scrollTop - moveY;

  if (scrollTop > 0) {
    containerElem.scrollTo(0, scrollTop);
    window.requestAnimationFrame(scrollAnimation.bind(null, containerElem, moveY));
  } else {
    containerElem.scrollTo(0, 0);
  }
};

export const useScrollToTop = <T extends HTMLElement>() => {
  const containerRef = useRef<T | null>(null);
  const scrollToTop = useCallback(() => {
    if (!containerRef.current) return;

    const moveY = containerRef.current.scrollTop / (TRANSITION_DURATION / (1000 / FPS));

    scrollAnimation(containerRef.current, moveY);
  }, []);

  return [containerRef, scrollToTop] as const;
};
