import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { EntryList, Flex, Text } from '~/components/blocks';
import { MedixsPatientProfileFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  medixsPatient?: MedixsPatientProfileFragment | null;
};

const Root = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    padding: theme.space.l,
  }),
);

export const ProfileDetailMedixs = React.memo((props: Props) => {
  const { medixsPatient } = props;

  return (
    <Root>
      <EntryList size="s">
        <EntryList.Head>レセコン患者コード</EntryList.Head>
        <EntryList.Body>
          <Flex alignItems="center" justifyContent="space-between">
            {medixsPatient?.patientName ? (
              <Text size="l" fontWeight="bold">
                {medixsPatient.patientCode}
              </Text>
            ) : (
              <Text>未連携</Text>
            )}
          </Flex>
        </EntryList.Body>
      </EntryList>
      <EntryList size="s">
        <EntryList.Head>患者名</EntryList.Head>
        <EntryList.Body>
          {medixsPatient?.patientName ? (
            <>
              <Text fontWeight="bold">{medixsPatient.patientName}</Text>
              <Text size="xs" fontWeight="normal">
                （{medixsPatient.patientNameKana}）
              </Text>
            </>
          ) : (
            '未連携'
          )}
        </EntryList.Body>
      </EntryList>
      <EntryList size="s">
        <EntryList.Head>生年月日</EntryList.Head>
        <EntryList.Body>
          {medixsPatient?.birthDate ? (
            <Text size="m" fontWeight="bold">
              {Label.YYYYMMDDja(medixsPatient.birthDate)}
            </Text>
          ) : (
            '未連携'
          )}
        </EntryList.Body>
      </EntryList>
    </Root>
  );
});

ProfileDetailMedixs.displayName = 'ProfileDetailMedixs';
