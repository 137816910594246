import { useGetMedicationFollowupStatusSettingQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

export const useMedicationFollowupSettingStatus = () => {
  const { data, loading, error, refetch } = useGetMedicationFollowupStatusSettingQuery();
  const me = data ? getMe(data) : null;
  const medicationFollowupSettingId = me?.organization.medicationFollowupSetting?.id || null;
  const statusManagement = !!me?.organization.medicationFollowupSetting?.statusManagement;

  return {
    loading,
    error,
    medicationFollowupSettingId,
    statusManagement,
    refetchMedicationFollowupSettingStatus: refetch,
  };
};
