import { css, Global, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { differenceInSeconds } from 'date-fns';
import Head from 'next/head';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Checkbox, Flex, Grid } from '~/components/blocks';
import { PrescriptionList } from '~/components/partials/MedicineNotesContainer/PrescriptionList';
import { SelectPeriod } from '~/components/partials/MedicineNotesContainer/SelectPeriod';
import {
  MedicineNoteFragment,
  NotebookMemoRecordFragment,
  OtcDrugDosageRecordFragment,
  OtcDrugIngredientRecordFragment,
  PatientReportRecordFragment,
  PrimaryPharmacistRecordFragment,
} from '~/graphql';
import { medicineNotesContainerState } from '~/state/partials/medicine_notes_container/atoms';
import { Label } from '~/utils/label';

import { OtcDrugList } from './OtcDrugList';
import { PatientDetailProfile } from './PatientDetailProfile';
import { PatientProfile } from './PatientProfile';
import { styles } from './styles';
import { Patient, Period } from './types';

export const DEFAULT_WINDOW = {
  width: 842,
  height: 800,
} as const;

type Props = {
  error?: string;
  patientProfile?: Patient;
  notebookMemos?: NotebookMemoRecordFragment[];
  patientReports?: PatientReportRecordFragment[];
  primaryPharmacists?: PrimaryPharmacistRecordFragment[];
  medicineNotes: MedicineNoteFragment[];
  period: Period;
  expiresAt?: string;
  onetimeCode?: boolean;
  onChangePeriod?: (to: Date, from?: Date) => void;
};

const Root = styled(Grid)(({ theme }) =>
  css({
    padding: theme.space.xxxl,
    width: '100%',
    maxWidth: '842px',
    minHeight: '100%',
    margin: '0 auto',
    gridTemplateRows: 'min-content 1fr',
    background: theme.colors.background.default,
  }),
);

const DisplayPeriod = styled(Box)(({ theme }) =>
  css({
    flexWrap: 'wrap',
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    padding: theme.space.s,
  }),
);

const PeriodTitle = styled(Box)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.grey01,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
    padding: theme.space.s,
  }),
);

const PeriodNote = styled(Box)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.grey01,
    fontSize: theme.fontSizes.xs,
    marginLeft: theme.space.l,
    marginTop: theme.space.m,
    marginBottom: theme.space.m,
    position: 'relative',
    '&:before': {
      content: '"※"',
      position: 'absolute',
      left: -10,
      top: -1,
    },
  }),
);

const DisplayDetailBox = styled(Box)(({ theme }) =>
  css({
    float: 'right',
    marginTop: theme.space.m,
  }),
);

export const MedicineNotesContainer = React.memo((props: Props) => {
  const theme = useTheme();
  const { error, medicineNotes, period, expiresAt, onChangePeriod } = props;

  const remainingSeconds = expiresAt
    ? differenceInSeconds(new Date(expiresAt), new Date()).toString()
    : '3600';

  const [{ isDetailView }, setState] = useRecoilState(medicineNotesContainerState);

  const data = useMemo(() => {
    const otcDrugRecords: { [key: string]: OtcDrugDosageRecordFragment } = {};
    const otcDrugIngredientRecords: { [key: string]: OtcDrugIngredientRecordFragment } = {};
    const memoRecords: { [key: string]: NotebookMemoRecordFragment } = {};
    const patientReportRecords: { [key: string]: PatientReportRecordFragment } = {};
    const primaryPharmacistRecords: { [key: string]: PrimaryPharmacistRecordFragment } = {};

    for (const note of medicineNotes) {
      if (note.otcDrugDosage?.otcDrugDosagesRecords) {
        for (const otc of note.otcDrugDosage.otcDrugDosagesRecords) {
          const _otcKey = Object.values(otc).join();
          otcDrugRecords[_otcKey] = otc;
        }
      }
      if (note.otcDrugDosage?.otcDrugIngredientRecords) {
        for (const ingredient of note.otcDrugDosage.otcDrugIngredientRecords) {
          const _ingredientKey = Object.values(ingredient).join();
          otcDrugIngredientRecords[_ingredientKey] = ingredient;
        }
      }
      if (note.notebookMemo?.notebookMemoRecords) {
        for (const memo of note.notebookMemo.notebookMemoRecords) {
          const _memoKey = Object.values(memo).join();
          memoRecords[_memoKey] = memo;
        }
      }
      if (note.patient?.patientReportRecords) {
        for (const report of note.patient.patientReportRecords) {
          const _reportKey = Object.values(report).join();
          patientReportRecords[_reportKey] = report;
        }
      }
    }

    // 最新のお薬手帳データのかかりつけ薬剤師の情報を表示する
    const latestNote = medicineNotes[0];
    if (latestNote?.primaryPharmacist?.primaryPharmacistRecords) {
      for (const pharmacist of latestNote.primaryPharmacist.primaryPharmacistRecords) {
        const _pharmacistKey = Object.values(pharmacist).join();
        primaryPharmacistRecords[_pharmacistKey] = pharmacist;
      }
    }

    return {
      memoRecords: Object.values(memoRecords),
      otcDrugRecords: Object.values(otcDrugRecords),
      otcDrugIngredientRecords: Object.values(otcDrugIngredientRecords),
      patientReportRecords: Object.values(patientReportRecords),
      primaryPharmacistRecords: Object.values(primaryPharmacistRecords),
    };
  }, [medicineNotes]);

  const memoRecords =
    props.notebookMemos && props.notebookMemos.length > 0 ? props.notebookMemos : data.memoRecords;

  const patientReportRecords =
    props.patientReports && props.patientReports.length > 0
      ? props.patientReports
      : data.patientReportRecords;

  const primaryPharmacistRecords =
    props.primaryPharmacists && props.primaryPharmacists.length > 0
      ? props.primaryPharmacists
      : data.primaryPharmacistRecords;

  const hasOtcDrugs = data.otcDrugRecords.length > 0;
  const hasPrescriptions = medicineNotes.some(({ prescriptions }) => prescriptions);
  const hasPatientDetail =
    memoRecords.length > 0 ||
    patientReportRecords.length > 0 ||
    primaryPharmacistRecords.length > 0;

  const hasPrescriptionDetail = useMemo(() => {
    return medicineNotes.some(({ prescriptions }) => {
      if (!prescriptions) return false;

      return prescriptions.some((prescription) => {
        const memos = prescription.prescriptionMemo?.prescriptionMemoRecords || [];
        const warnings = prescription.warning?.warningRecords || [];
        const remainings = prescription.remaining?.remainingRecords || [];
        const remarks = prescription.remark?.remarkRecords || [];
        const hasSpecialNote =
          memos.length > 0 || warnings.length > 0 || remainings.length > 0 || remarks.length > 0;

        const hasDispensingDetail = !!prescription.prescriptionRecipes?.some(
          ({ recipeInformation }) => {
            if (!recipeInformation) return false;

            const hasDosageDetail = recipeInformation.some((info) => {
              const dosagePlusRecords = info.dosage?.dosagePlusRecords || [];

              return dosagePlusRecords.length > 0;
            });
            const hasMedicineDetail = recipeInformation.some((info) => {
              if (!info.medicines) return false;

              return info.medicines.some((medicine) => {
                const cautionRecords = medicine.medicineCautionRecords || [];
                const plusRecords = medicine.medicinePlusRecords || [];

                return cautionRecords.length > 0 || plusRecords.length > 0;
              });
            });

            return hasDosageDetail || hasMedicineDetail;
          },
        );

        return hasSpecialNote || hasDispensingDetail;
      });
    });
  }, [medicineNotes]);

  const hasDetail = hasPatientDetail || hasPrescriptionDetail;

  const handleChange = useCallback(() => {
    setState((_state) => ({ ..._state, isDetailView: !_state.isDetailView }));
  }, [setState]);

  const handlePeriodChange = useCallback(
    (to: Date, from?: Date) => {
      if (onChangePeriod) onChangePeriod(to, from);
    },
    [onChangePeriod],
  );

  return (
    <>
      <Head>
        {/*
         - 有効期限があれば残り時間経過後に、なければ1時間後にブラウザをリロードさせる
         - リロードさせる理由
           - お薬手帳ウィンドウを開いたままにしておくと有効期限が切れても内容を閲覧できてしまうので、一定時間経過後に自動でブラウザをリロードし閲覧できないようにする
         - リロード時の挙動
           - 有効期限あり(ワンタイムコード開示、直接開示): 有効期限が切れてる場合、お薬手帳情報がサーバから取得できない
           - 有効期限なし(応募開示): 会計完了または2週間経過してる場合、お薬手帳情報がサーバから取得できない
         */}
        <meta httpEquiv="refresh" content={remainingSeconds} />
      </Head>
      <Global styles={styles} />
      <Root>
        {error ? (
          <Alert>{error}</Alert>
        ) : (
          <>
            <Box>
              <Flex justifyContent="space-between" alignItems="flex-start">
                <PatientProfile patient={props.patientProfile} />
                <Box>
                  <PeriodTitle>お薬手帳の表示期間</PeriodTitle>
                  {!props.onetimeCode && (
                    <SelectPeriod period={period} onChange={handlePeriodChange} />
                  )}
                  <DisplayPeriod>{`${period.from ? Label.YYYYMMDDja(period.from) : ''}〜${
                    period.to ? Label.YYYYMMDDja(period.to) : ''
                  }`}</DisplayPeriod>
                  {props.onetimeCode && (
                    <PeriodNote>
                      ワンタイムコードの場合、1年以上<br></br>前のデータは閲覧できません
                    </PeriodNote>
                  )}
                  <DisplayDetailBox>
                    <Checkbox
                      label="詳細情報の表示"
                      checked={isDetailView}
                      onChange={handleChange}
                    />
                  </DisplayDetailBox>
                </Box>
              </Flex>
              {isDetailView && !hasDetail && (
                <Alert status="info" marginTop={theme.space.l}>
                  詳細情報はありません
                </Alert>
              )}
              {isDetailView && hasPatientDetail && (
                <Box marginTop={theme.space.l}>
                  <PatientDetailProfile
                    memos={memoRecords}
                    patientReports={patientReportRecords}
                    primaryPharmacists={primaryPharmacistRecords}
                  />
                </Box>
              )}
            </Box>
            <Box marginTop={theme.space.l}>
              {hasOtcDrugs && (
                <OtcDrugList
                  otcDrugs={data.otcDrugRecords}
                  otcIngredients={data.otcDrugIngredientRecords}
                />
              )}

              {medicineNotes.length > 0 ? (
                <Box marginTop={theme.space.l}>
                  {hasPrescriptions ? (
                    medicineNotes.map((medicineNote, idx) => {
                      if (medicineNote.prescriptions) {
                        return (
                          <PrescriptionList key={idx} prescriptions={medicineNote.prescriptions} />
                        );
                      }
                    })
                  ) : (
                    <Alert status="info">調剤情報はありません</Alert>
                  )}
                </Box>
              ) : (
                <Alert status="info">該当期間にお薬手帳データはありません</Alert>
              )}
            </Box>
          </>
        )}
      </Root>
    </>
  );
});

MedicineNotesContainer.displayName = 'MedicineNoteContainer';
