import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Grid, Paper } from '~/components/blocks';

import { RootMenu } from './RootMenu';

type Props = { children?: React.ReactNode };

const Root = styled(Paper)(({ theme }) =>
  css({
    boxShadow: theme.shadows.secondary,
    height: '100%',
  }),
);

export const Settings: React.FC<Props> = ({ children }) => {
  const theme = useTheme();

  return (
    <Root>
      <Grid
        gridTemplateColumns="auto 1fr"
        gridTemplateRows="1fr"
        width="100%"
        height="100%"
        overflow="auto"
      >
        <RootMenu />
        <Box
          py={[theme.space.l, theme.space.l, theme.space.xl]}
          px={[theme.space.l, theme.space.l, theme.space.xxl]}
          overflow="auto"
          height="100%"
        >
          {children}
        </Box>
      </Grid>
    </Root>
  );
};
