import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useIntercom } from 'react-use-intercom';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import { Dialog, Grid } from '~/components/blocks';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';
import {
  eventPageInfoState,
  patientDetailConfirmBreakawayState,
  patientDetailDialogMessageState,
  patientDetailDialogProfileState,
  patientDetailDialogState,
  patientDetailDialogTracingreportState,
} from '~/state/partials/patient_detail_dialog/atoms';
import { PatientDetailDiaLogActionType } from '~/state/partials/patient_detail_dialog/types';

import { ConfirmBreakawayModal } from './ConfirmBreakawayModal';
import { DeleteFollowupCommentModal } from './DeleteFollowupCommentModal';
import { DeleteMedicationFollowupMessageModal } from './DeleteMedicationFollowupMessageModal';
import { DialogHeder } from './DialogHeder';
import { EventPane } from './EventPane';
import { NewMedicationFollowupMessagePane } from './NewMedicationFollowupMessagePane';
import { useFetchPatientProfile } from './NewMedicationFollowupMessagePane/use-fetch-patient';
import { NewTracingReportPane } from './NewTracingReportPane';
import { PatientPane } from './PatientPane';
import { useFetchPatient } from './use-fetch-patient';
import { useMedicationFollowupSettingStatus } from './use-medication-followup-setting-status';

export const PatientDetailDialog = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery({ query: theme.mediaQueries.tablet });
  const { isOpen } = useRecoilValue(patientDetailDialogState);
  const [{ selectedMessageId }, setProfileState] = useRecoilState(patientDetailDialogMessageState);
  const { patient } = useFetchPatient();
  const { profile } = useFetchPatientProfile();
  const { statusManagement } = useMedicationFollowupSettingStatus();
  const { hide: closeIntercom } = useIntercom();

  const { verifyRole } = usePractitionerRole();
  const isVerified = verifyRole('pharmacist');

  const handleClose = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        if (selectedMessageId) {
          set(patientDetailConfirmBreakawayState, (_state) => ({
            ..._state,
            isOpen: true,
            action: 'close' as PatientDetailDiaLogActionType,
          }));
        } else {
          reset(patientDetailDialogState);
          reset(patientDetailDialogMessageState);
          reset(patientDetailDialogTracingreportState);
          reset(eventPageInfoState);
          reset(patientDetailDialogProfileState);
          reset(patientDetailConfirmBreakawayState);
        }
      },
    [selectedMessageId],
  );

  useEffect(() => {
    if (profile) {
      setProfileState((_state) => ({ ..._state, profile }));
    }
    if (isOpen) {
      closeIntercom();
    }
  }, [closeIntercom, isOpen, profile, setProfileState]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogHeder patient={patient} statusManagement={statusManagement} />
      <Dialog.Body>
        <Grid
          height="100%"
          gridTemplateColumns={`${
            isVerified ? 'min-content auto min-content' : 'auto min-content'
          }`}
          overflow="hidden"
        >
          {isVerified ? (
            <>
              <EventPane
                patient={patient}
                statusManagement={statusManagement}
                width={isTablet ? '320px' : '420px'}
                isPharmacist={isVerified}
              />
              <NewTracingReportPane patient={patient} />
              <NewMedicationFollowupMessagePane patient={patient} />
            </>
          ) : (
            <EventPane
              patient={patient}
              statusManagement={statusManagement}
              isPharmacist={isVerified}
            />
          )}
          <PatientPane
            patient={patient}
            width={isTablet ? '300px' : '360px'}
            isPharmacist={isVerified}
          />
        </Grid>
      </Dialog.Body>
      <DeleteFollowupCommentModal patient={patient} />
      <ConfirmBreakawayModal />
      <DeleteMedicationFollowupMessageModal />
    </Dialog>
  );
};
