import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { useRouter } from 'next/router';
import React from 'react';

import { Link } from '~/components/blocks';

import { MenuItem } from './MenuItem';
import { HrefProps } from './types';

/**
 * [0]はルーティング文字列、以降はオプショナルでメニューをどの時アクティブにするかの文字列
 */
type Props = {
  href: [HrefProps, ...HrefProps[]];
  children?: React.ReactNode;
};

const Root = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    width: '100%',
    [`@media ${theme.mediaQueries.portrait}`]: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }),
    '&:hover': {
      color: theme.colors.text.primary,
      fill: theme.colors.text.primary,
    },
  }),
);

const NavLink = styled(Link)(({ theme }) =>
  css({
    [`@media ${theme.mediaQueries.portrait}`]: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export const MenuLink: React.FC<Props> = (props) => {
  const router = useRouter();
  const isActive = props.href.some((href) =>
    href.exact ? router.pathname === href.path : router.pathname.indexOf(href.path) === 0,
  );

  return (
    <Root>
      <NavLink href={props.href[0].path}>
        <MenuItem active={isActive}>{props.children}</MenuItem>
      </NavLink>
    </Root>
  );
};
