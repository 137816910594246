import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';

import { Box } from '~/components/blocks';
import { useGetQuestionnaireSheetLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

import { QuestionnaireSheet } from '../../QuestionnaireSheet';

type Props = {
  patientId: string;
};

export const QuestionnairePane = React.memo((props: Props) => {
  const theme = useTheme();
  const [getQuestionnaires, { data, loading }] = useGetQuestionnaireSheetLazyQuery();

  const sheet = getNode(data, 'Patient')?.questionnaireSheet;

  useEffect(() => {
    getQuestionnaires({ variables: { patientId: props.patientId } });
  }, [getQuestionnaires, props.patientId]);

  return (
    <Box padding={theme.space.l}>
      <QuestionnaireSheet copyable loading={loading} sheet={sheet || undefined} />
    </Box>
  );
});

QuestionnairePane.displayName = 'QuestionnairePane';
