import { useCallback } from 'react';

import { GetPractitionerRoleQuery, PractitionerRole, useGetPractitionerRoleQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';

const getRole = (data?: GetPractitionerRoleQuery): PractitionerRole[] => {
  if (!data) return [];

  return getMe(data)?.roles || [];
};

export const usePractitionerRole = () => {
  const { data, loading } = useGetPractitionerRoleQuery();
  const roles = getRole(data);
  const verifyRole = useCallback(
    (role: PractitionerRole | PractitionerRole[]) => {
      if (Array.isArray(role)) {
        return role.some((r) => roles.includes(r));
      } else {
        return roles.includes(role);
      }
    },
    [roles],
  );

  return {
    loadingRole: loading,
    roles,
    verifyRole,
  };
};
