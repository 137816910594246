import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Text } from '~/components/blocks';
import { publicPath } from '~/utils/path';

const Root = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.bg,
    borderRadius: theme.radii.default,
    fontSize: theme.fontSizes.s,
    marginTop: theme.space.m,
    padding: theme.space.l,
  }),
);

const Apps = styled('div')(({ theme }) =>
  css({
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.space.l,
  }),
);

const App = styled('dl')(() =>
  css({
    alignItems: 'end',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateColumns: 'min-content 1fr',
  }),
);

const Os = styled('dt')(({ theme }) =>
  css({
    fontWeight: theme.fontWeights.bold,
    gridRow: 1,
    gridColumn: 1,
  }),
);

const AppBanner = styled('dt')(() =>
  css({
    gridRow: 2,
    gridColumn: 1,
  }),
);

const QRCode = styled('dt')(({ theme }) =>
  css({
    gridRow: '1 / 3',
    gridColumn: 2,
    marginLeft: theme.space.s,
  }),
);

export const AppDownload = () => {
  return (
    <Root>
      <Text>
        二要素認証を設定するには、お手持ちのスマートフォンにGoogle認証システム
        <br />「<strong>Google Authenticator</strong>
        」アプリをインストールする必要があります。
      </Text>
      <Apps>
        <App>
          <Os>iOS版</Os>
          <AppBanner>
            <a
              href="https://itunes.apple.com/jp/app/google-authenticator/id388497605?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={publicPath('/img/img-appstore.svg')} height="48" width="auto" alt="iOS" />
            </a>
          </AppBanner>
          <QRCode>
            <img
              height="80px"
              width="80px"
              src={publicPath('/img/img-appstore-qr.png')}
              alt="iOS QRコード"
            />
          </QRCode>
        </App>
        <App>
          <Os>Android版</Os>
          <AppBanner>
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={publicPath('/img/img-google-play.svg')}
                height="48"
                width="auto"
                alt="Android"
              />
            </a>
          </AppBanner>
          <QRCode>
            <img
              height="80px"
              width="80px"
              src={publicPath('/img/img-google-play-qr.png')}
              alt="iOS QRコード"
            />
          </QRCode>
        </App>
      </Apps>
    </Root>
  );
};
