import { useEffect, useMemo } from 'react';

import { SOUNDS } from '~/constants/sounds';
import { NotificationSoundSettingFragment } from '~/graphql';

type SoundSetting = {
  name: keyof typeof SOUNDS;
  interval: number | null;
};

const ONE_MINUTE = 60 * 1000;

const SoundManager = (() => {
  let isInitialized = false;
  let timerId: number;
  let unreadCount = 0;
  let setting: SoundSetting = {
    name: 'sound1',
    interval: null,
  };
  const playSound = () => {
    return new Promise<number>((resolve) => {
      SOUNDS[setting.name]?.sound.once('end', resolve);
      SOUNDS[setting.name]?.sound.play();
    });
  };
  const snooze = () => {
    clearTimeout(timerId);

    if (!setting.interval) return;

    timerId = window.setTimeout(async () => {
      if (unreadCount > 0 && setting.name && setting.interval) {
        await playSound();
        snooze();
      }
    }, setting.interval * ONE_MINUTE);
  };
  const play = async (currentUnreadCount: number) => {
    if (setting.name && currentUnreadCount > unreadCount) {
      unreadCount = currentUnreadCount;

      clearTimeout(timerId);
      await playSound();

      snooze();
    } else {
      unreadCount = currentUnreadCount;
    }
  };

  return {
    initialize: (_setting: SoundSetting) => {
      // Next.jsだと再レンダリングされてしまうので一回初期化したらデータは上書きしない
      if (isInitialized) return;
      isInitialized = true;
      setting = _setting;
    },
    play,
    setSound: (soundName: keyof typeof SOUNDS) => (setting.name = soundName),
    resetSnooze: (interval: number | null) => {
      if (setting.interval !== interval) {
        setting.interval = interval;
        snooze();
      }
    },
  };
})();

export const useNotificationSound = (setting?: NotificationSoundSettingFragment) => {
  const soundSetting: SoundSetting = useMemo(() => {
    if (setting) {
      return {
        name: setting.name as keyof typeof SOUNDS,
        interval: setting.interval ?? null,
      };
    } else {
      return {
        name: 'sound1',
        interval: null,
      };
    }
  }, [setting]);

  SoundManager.initialize(soundSetting);

  useEffect(() => {
    SoundManager.resetSnooze(soundSetting.interval);
  }, [soundSetting.interval]);

  useEffect(() => {
    SoundManager.setSound(soundSetting.name);
  }, [soundSetting.name]);

  return {
    play: SoundManager.play,
  };
};
